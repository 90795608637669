import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {Product} from "@spartacus/core";
import {CurrentProductService} from "@spartacus/storefront";

@Component({
  selector: 'app-custom-product-labels',
  templateUrl: './custom-product-labels.component.html'
})
export class CustomProductLabelsComponent {

  product$: Observable<Product>;

  constructor(protected currentProductService: CurrentProductService) {
    this.product$ = currentProductService.getProduct();
  }
}
