<div class="container">
  <div class="row content">
    <div class="col-lg-6 col-xl-4">
      <ng-container *ngIf="data$ | async as data">

        <ng-container *ngIf="data.title">
          <h3 class="title" [innerHTML]=data.title></h3>
        </ng-container>

        <ng-container *ngIf="data.subtitle">
          <h2 class="subtitle" [innerHTML]=data.subtitle></h2>
        </ng-container>

        <ng-container *ngIf="data.paragraph">
          <cx-paragraph [innerHTML]="data.paragraph"></cx-paragraph>
        </ng-container>

        <ng-container *ngIf="buttons$ | async as buttons">
          <ng-container *ngFor="let button of buttons">
            <div
              *ngIf="button"
              class="d-block"
            >
              <cx-generic-link
                *ngIf="button.url && button.url.length"
                [url]="button.url"
                [target]="getTarget(button)"
                class="btn btn-send"
              >
                {{button.linkName}}
              </cx-generic-link>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="col-lg-6 col-xl-8">
      <ng-container *ngIf="news$ | async as news">
        <cx-carousel
          [items]="news$ | async"
          [template]="carouselItem"
          itemWidth="50%"
          [hideIndicators]="true"
        >
        </cx-carousel>
      </ng-container>

      <ng-template #carouselItem let-item="item">
        <ng-container *ngIf="item">
          <cx-media
            class="item-media"
            [container]="item.coverImage"
            [alt]="item.newsTitle"
          ></cx-media>
          <div class="news-date">{{item.publishDate | cxDate: "mediumDate" }}</div>
          <h3 class="news-title">
            <a [routerLink]="item.label" class="item-link">
              {{item.newsTitle}}
            </a>
          </h3>
          <p class="news-thumbnail-description">{{item.thumbnailDescription}}</p>
          <a [routerLink]="item.label" class="btn btn-link btn-read-more-alt">{{"news.readMore" | cxTranslate}}</a>
        </ng-container>
      </ng-template>

    </div>
  </div>
</div>
