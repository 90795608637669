import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomColumnsWithFilesContainerComponent } from './custom-columns-with-files-container.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomColumnsWithFileListGroupModule } from './custom-columns-with-file-list-group/custom-columns-with-file-list-group.module';
import { CustomFilesInfoModule } from './custom-columns-with-file-list-group/custom-files-info/custom-files-info.module';


@NgModule({
  declarations: [CustomColumnsWithFilesContainerComponent],
  exports: [
    CustomColumnsWithFilesContainerComponent,
  ],
  entryComponents: [
    CustomColumnsWithFilesContainerComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomColumnsWithFilesContainer: {
          component: CustomColumnsWithFilesContainerComponent,
        },
      },
    } as CmsConfig),
    CustomColumnsWithFileListGroupModule,
    CustomFilesInfoModule,
  ],
})
export class CustomColumnsWithFilesContainerModule {}
