import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {isNotNullable, Product,ProductScope} from '@spartacus/core';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {CustomCurrentProductService} from "../../../core/product/services/custom-current-product.service";
import {ProductUnitPrice} from "../../../../../model/unit-price";

@Component({
  selector: 'cx-add-to-cart',
  templateUrl: './custom-add-to-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAddToCartComponent implements OnInit, OnDestroy {
  @HostBinding('class') styleClasses = '';
  @Input() productCode: string;
  @Input() showQuantity = true;
  @Input() hideStockInfo = false;
  @Input() showNostockText = false;
  @Input() displayDefaultUnitOnly = false;
  @Input() showAddToCartText = true;
  @Output() unitChanged: EventEmitter<ProductUnitPrice> = new EventEmitter<ProductUnitPrice>();
  /**
   * As long as we do not support #5026, we require product input, as we need
   *  a reference to the product model to fetch the stock data.
   */
  @Input() product: Product;

  hasStock = false;

  subscription: Subscription;

  isPDP = false;

  constructor(
    protected currentProductService: CustomCurrentProductService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    if (this.displayDefaultUnitOnly) {
      this.styleClasses = 'default-unit-only';
    }
    if (this.product) {
      this.productCode = this.product.code;
      this.setStockInfo(this.product);
      this.cd.markForCheck();
    } else if (this.productCode) {
      this.hasStock = true;
      this.styleClasses += ' has-stock';
      this.cd.markForCheck();
    } else {
      this.subscription = this.currentProductService
        .getProduct([ProductScope.PRICES, ProductScope.VARIANTS])
        .pipe(filter(isNotNullable))
        .subscribe((product) => {
            this.productCode = product.code;
            this.product = product;
            this.setStockInfo(product);
            this.isPDP = true;
            this.cd.markForCheck();
        });
    }
  }

  handleUnitChange(productUnit: ProductUnitPrice){
    this.unitChanged.emit(productUnit)
  }

  private setStockInfo(product: Product): void {
    this.hasStock = product.stock && product.stock.stockLevelStatus !== 'outOfStock';
    this.styleClasses += this.hasStock ? ' has-stock' : '';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
