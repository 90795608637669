import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CancelOrReturnItemsComponent } from '@spartacus/order/components';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { CustomOrderEntry } from 'src/app/spartacus/custom/feature-libs/cart/root/models/cart.model';

@Component({
  selector: 'cx-amend-order-items',
  templateUrl: './custom-amend-order-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCancelOrReturnItemsComponent extends CancelOrReturnItemsComponent implements OnInit, OnDestroy {
  private packEntriesSubscriptions = new Subscription();
  hasPackGroup = false;
  form$: Observable<FormGroup> = this.orderAmendService.getForm()
    .pipe(
      distinctUntilChanged(),
      tap(form => {
        if (form && !this.isConfirmation) {
          this.entries.forEach(entry => {
            this.bindPackChangeQtyByStep(form, entry);
          });
        }
      })
    );

  ngOnInit(): void {
    var entries: CustomOrderEntry[] = this.entries;
    this.hasPackGroup = entries.some(entry => Boolean(entry.packGroup));
  }

  getProductStep(entry: CustomOrderEntry): number {
    return entry.packProductReferenceQuantity ? entry.packProductReferenceQuantity : 1;
  }

  private bindPackChangeQtyByStep(form: FormGroup, entry: CustomOrderEntry): void {
    if (entry.packGroup) {
      this.packEntriesSubscriptions.add(
        this.getControl(form, entry).valueChanges
          .subscribe((totalQty) => {
            const qty = totalQty / entry.packProductReferenceQuantity;
            this.getOtherPackEntries(entry).forEach(otherPackEntry => {
              this.getControl(form, otherPackEntry).setValue(qty * otherPackEntry.packProductReferenceQuantity, { emitEvent: false });
            });
          })
      );
    }
  }

  private getOtherPackEntries(packEntry: CustomOrderEntry): CustomOrderEntry[] {
    return this.entries.filter((entry: CustomOrderEntry) =>
      entry.packGroup === packEntry.packGroup && entry.entryNumber !== packEntry.entryNumber
    );
  }

  ngOnDestroy(): void {
    this.packEntriesSubscriptions?.unsubscribe();
  }
}
