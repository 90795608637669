import { Component, HostBinding } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsCustomDoubleImageInfoComponent } from './custom-double-image-info.model';
@Component({
  selector: 'app-custom-double-image-info',
  templateUrl: './custom-double-image-info.component.html',
})
export class CustomDoubleImageInfoComponent {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;

  data$: Observable<CmsCustomDoubleImageInfoComponent> = this.component.data$.pipe(
    tap((data) => this.initComponent(data))
  );

  constructor(
    protected component: CmsComponentData<CmsCustomDoubleImageInfoComponent>,
  ) {}

  initComponent(item: CmsCustomDoubleImageInfoComponent): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
    this.backgroundColor = item?.backgroundColorCode ?? '';
  }
}
