<div class="cx-page" *ngIf="model$ | async as model">
  <section class="cx-page-section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-12" *ngIf="viewMode$ | async as viewMode">
          <ng-container *ngIf="model.pagination?.totalResults || !model.freeTextSearch || filterTextStarted; else emptySearch">
            <div class="cx-sorting top">
              <div class="form-group cx-sort-dropdown">
                <cx-sorting
                  [sortOptions]="model.sorts"
                  (sortListEvent)="sortList($event)"
                  [selectedOption]="model.pagination.sort"
                  placeholder="{{
                    'productList.sortByRelevance' | cxTranslate
                  }}"
                ></cx-sorting>
              </div>
              <div class="product-grid-results">
                {{ model.pagination.totalResults }}
                {{ 'pageMetaResolver.search.title' | cxTranslate }}
              </div>
              <div *ngIf="!isInfiniteScroll" class="col-auto">
                <div
                  class="cx-pagination"
                  aria-label="product search pagination"
                >
                  <cx-pagination
                    [pagination]="model.pagination"
                    queryParam="currentPage"
                    [defaultPage]="0"
                  ></cx-pagination>
                </div>
              </div>
              <label class="custom-product-list-input">
                <cx-icon
                  [type]="iconTypes.SEARCH"
                  aria-label="search"
                  class="search"
                ></cx-icon>
                <cx-icon
                  [type]="iconTypes.RESET"
                  aria-label="reset"
                  class="reset"
                  (mousedown)="removeSearchText()"
                  (keydown.enter)="removeSearchText()"
                  [class.visible]="filtersForm.get('filterText').value"
                  tabindex="0"
                ></cx-icon>
                <input
                  [focus]="filterTextStarted"
                  [placeholder]="'searchBox.placeholder' | cxTranslate"
                  aria-label="search"
                  class="form-control"
                  type="text"
                  [formControl]="filtersForm.get('filterText')"
                />
              </label>
              <cx-product-view
                (modeChange)="setViewMode($event)"
                [mode]="viewMode"
              ></cx-product-view>
            </div>
            <div class="cx-product-container">
              <p class="search-empty" *ngIf="!model.pagination?.totalResults && model.freeTextSearch">
                {{ 'pageMetaResolver.search.empty' | cxTranslate : { freeTextSearch: model.freeTextSearch } }}
              </p>
              <!-- Product list when using pagination -->
              <ng-container *ngIf="!isInfiniteScroll; else infiniteScroll">
                <ng-container *ngIf="viewMode === ViewModes.Grid">
                  <div class="row">
                    <cx-product-grid-item
                      *ngFor="let product of model?.products; let i = index"
                      [product]="product"
                      [index]="i"
                      [category]="model?.breadcrumbs[0]?.facetValueName"
                      class="col-12 col-sm-6 col-md-4"
                    ></cx-product-grid-item>
                  </div>
                </ng-container>

                <ng-container *ngIf="viewMode === ViewModes.List">
                  <cx-product-list-item
                    *ngFor="let product of model?.products; let i = index"
                    [product]="product"
                    [index]="i"
                    [category]="model?.breadcrumbs[0]?.facetValueName"
                    class="cx-product-search-list"
                  ></cx-product-list-item>
                </ng-container>
              </ng-container>

              <!-- Product list when using infinite scroll -->
              <ng-template #infiniteScroll>
                <cx-product-scroll
                  [scrollConfig]="scrollConfig"
                  [model]="model"
                  [inputViewMode]="viewMode"
                ></cx-product-scroll>
              </ng-template>
            </div>
            <div class="cx-sorting bottom">
              <div class="form-group cx-sort-dropdown">
                <cx-sorting
                  [sortOptions]="model.sorts"
                  (sortListEvent)="sortList($event)"
                  [selectedOption]="model.pagination.sort"
                  placeholder="{{
                    'productList.sortByRelevance' | cxTranslate
                  }}"
                ></cx-sorting>
              </div>
              <div
                *ngIf="!isInfiniteScroll"
                class="col-auto"
                aria-label="product search pagination"
              >
                <div class="cx-pagination">
                  <cx-pagination
                    [pagination]="model.pagination"
                    queryParam="currentPage"
                    [defaultPage]="0"
                  ></cx-pagination>
                </div>
              </div>
              <cx-product-view
                (modeChange)="setViewMode($event)"
                [mode]="viewMode"
              ></cx-product-view>
            </div>
          </ng-container>
            <ng-template #emptySearch>
              <p class="search-empty">
                {{ 'pageMetaResolver.search.empty' | cxTranslate : { freeTextSearch: model.freeTextSearch } }}
              </p>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>
