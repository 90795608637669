import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ProcessSelectors, RoutingService, StateWithProcess, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CsTicketCause, CsTicketConfiguration, CsTicketContactForm } from 'src/app/model/contact-form.model';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { ContactFormActions } from './store/actions';
import { CUSTOM_SEND_CONTACT_FORM_PROCESS_ID, StateWithCustomContactForm } from './store/custom-contact-form-state';
import { CustomContactFormSelectors } from './store/selector';

@Injectable({
  providedIn: 'root',
})
export class CustomContactFormService {
  constructor(
    protected store: Store<StateWithCustomContactForm | StateWithProcess<void>>,
    protected userIdService: UserIdService,
    protected routingService: RoutingService
  ) { }

  form: FormGroup = new FormGroup({
    orderCode: new FormControl(
      {
        disabled: true,
        value: '',
      },
      Validators.required
    ),
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(60),
      CustomValidators.emailValidator,
    ]),
    message: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    subject: new FormControl('', Validators.required),
    templateCode: new FormControl(null),
    causeCode: new FormControl(null),
    accept: new FormControl(false, Validators.requiredTrue),
    qualityProblem: new FormControl(
      {
        disabled: true,
        value: null,
      },
      Validators.required
    ),
    requiredAction: new FormControl(
      {
        disabled: true,
        value: '',
      },
      Validators.maxLength(255)
    ),
    productsAffected: new FormArray([
      this.createProductAffectedFormGroup()
    ]
    ),
  });

  createProductAffectedFormGroup(): FormGroup {
    return new FormGroup({
      productDescription: new FormControl('', Validators.required),
      quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
    });
  }

  addProductAffected(): void {
    this.productsAffectedFormArray.push(this.createProductAffectedFormGroup());
  }

  removeProductAffected(index: number): void {
    this.productsAffectedFormArray.removeAt(index);
  }

  /**
   * Reset form fields except email
   */
  resetForm(): void {
    this.form.reset({
      email: this.form.get('email').value,
    });
    this.productsAffectedFormArray.clear();
    this.addProductAffected();
  }

  private toggleControls(enable: boolean, paths: string[]): void {
    paths.forEach((path: string) => {
      if (enable) {
        this.form.get(path).enable();
      } else {
        this.form.get(path).disable();
      }
      this.form.get(path).updateValueAndValidity();
    });
  }

  toggleTemplateControls(ticketConfiguration: CsTicketConfiguration): void {
    const toggleablePaths = [
      'orderCode',
      'qualityProblem',
      'requiredAction',
      'productsAffected',
    ];
    let enablePaths: string[];
    switch (ticketConfiguration) {
      case CsTicketConfiguration.WITH_ORDER_CODE:
        enablePaths = [
          'orderCode',
        ];
        break;
      case CsTicketConfiguration.RETURN_FOR_QUALITY:
        enablePaths = [
          'orderCode',
          'qualityProblem',
          'requiredAction',
          'productsAffected',
        ];
        break;
      default:
        enablePaths = [];
        break;
    }
    this.toggleControls(false, toggleablePaths.filter(path => !enablePaths.includes(path)));
    this.toggleControls(true, enablePaths);
  }

  get productsAffectedFormArray(): FormArray {
    return this.form.get('productsAffected') as FormArray;
  }

  /**
   * Returns Custom Contact Form
   */
  getCustomContactFormList(templateConfigurations: string): Observable<CsTicketCause[]> {
    return this.store.pipe(
      select(CustomContactFormSelectors.getCustomContactFormLoaderState),
      tap((customContactFormListState: any) => {
        const attemptedLoad =
          customContactFormListState.loading ||
          customContactFormListState.success ||
          customContactFormListState.error;
        if (!attemptedLoad) {
          this.loadCustomContactFormList(templateConfigurations);
        }
      }),
      map((customContactFormListState: any) => customContactFormListState.value)
    );
  }

  /**
   * Returns a loaded flag for Custom Contact Form
   */
  getCustomContactFormLoaded(): Observable<boolean> {
    return this.store.pipe(select(CustomContactFormSelectors.getCustomContactFormLoaded));
  }

  /**
   * Retrieves Custom Contact Form/CsTicketCauseList
   */
  loadCustomContactFormList(templateConfigurations: string): void {
    this.store.dispatch(
      new ContactFormActions.LoadCustomContactForm(templateConfigurations)
    );
  }

  /**
   * Cleaning Custom Contact Form
   */
  clearCustomContactFormList(): void {
    this.store.dispatch(
      new ContactFormActions.ClearCustomContactForm()
    );
  }

  /**
   * Send Contact Form.
   */
   sendContactForm(form: CsTicketContactForm, templateConfiguration: string): void {
    this.store.dispatch(
      new ContactFormActions.CustomSendContactForm({form, templateConfiguration})
    );
  }

  /**
   * Returns the Send Contact Form loading flag
   */
   getSendContactFormLoading(): Observable<boolean> {
    return this.store.pipe(
      select(ProcessSelectors.getProcessLoadingFactory(CUSTOM_SEND_CONTACT_FORM_PROCESS_ID))
    );
  }

  /**
   * Returns the Send Contact Form success flag
   */
  getSendContactFormSuccess(): Observable<boolean> {
    return this.store.pipe(
      select(ProcessSelectors.getProcessSuccessFactory(CUSTOM_SEND_CONTACT_FORM_PROCESS_ID))
    );
  }

  /**
   * Returns the Send Contact Form state
   */
  getSendContactFormState(): Observable<any> {
    return this.store.pipe(
      select(ProcessSelectors.getProcessStateFactory(CUSTOM_SEND_CONTACT_FORM_PROCESS_ID))
    );
  }

  /**
   * Returns the Send Contact Form error flag
   */
  getSendContactFormError(): Observable<boolean> {
    return this.store.pipe(
      select(ProcessSelectors.getProcessErrorFactory(CUSTOM_SEND_CONTACT_FORM_PROCESS_ID))
    );
  }

  /**
   * Clears the process state of performing a newsletter subscription
   */
  clearSendContactForm(): void {
    this.store.dispatch(new ContactFormActions.ClearCustomSendContactForm());
  }
}
