import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCMSLinkComponent } from './custom-cms-link.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { MediaModule } from '@spartacus/storefront';
import { CustomGenericLinkModule } from '../../../shared/components/custom-generic-link/custom-generic-link.module';



@NgModule({
  declarations: [CustomCMSLinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    CustomGenericLinkModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSLinkComponent: {
          component: CustomCMSLinkComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomCMSLinkComponentModule { }
