import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {WindowRef} from '@spartacus/core';

@Injectable()
export class BaseStoreInterceptor implements HttpInterceptor {

  constructor(protected winRef?: WindowRef,) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let newRequest = request;
    const baseStore = this.winRef?.localStorage?.getItem('currentbasestore');
    if (baseStore) {
      const json = JSON.parse(baseStore);
      if (json.code &&  json.code !== '') {
        let headers = request.headers.set('currentbasestore', json.code);
        if (json.selectedCountry &&  json.selectedCountry !== ''
            && json.selectedCountry.isocode &&  json.selectedCountry.isocode !== '') {
          headers = headers.set('currentcountry', json.selectedCountry.isocode);
        }
        newRequest = request.clone({headers});
      }
    }
    return next.handle(newRequest);
  }
}
