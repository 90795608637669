<ng-container *ngIf="returnRequest$ | async as returnRequest">
  <div class="container">
    <div class="row justify-content-end">
      <div class="cx-summary col-sm-12 col-md-7 col-lg-6">
        <h4>{{ 'returnRequest.summary' | cxTranslate }}</h4>
        <div class="cx-summary-row">
          <div class="col-6 cx-summary-label">
            {{ 'returnRequest.subtotal' | cxTranslate }}
          </div>
          <div class="col-6 cx-summary-amount">
            {{ returnRequest.subTotal?.formattedValue }}
          </div>
        </div>
        <div
          *ngIf="returnRequest.deliveryCost?.value > 0"
          class="cx-summary-row"
        >
          <div class="col-6 cx-summary-label">
            {{ 'returnRequest.deliveryCode' | cxTranslate }}
          </div>
          <div class="col-6 cx-summary-amount">
            {{ returnRequest.deliveryCost?.formattedValue }}
          </div>
        </div>
        <div class="cx-summary-row cx-summary-total">
          <div class="col-6 cx-summary-label">
            {{ 'returnRequest.estimatedRefund' | cxTranslate }}
          </div>
          <div class="col-6 cx-summary-amount">
            {{ returnRequest.totalPrice?.formattedValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cx-nav">
    <button
      *ngIf="returnRequest.cancellable"
      class="btn btn-action"
      (click)="cancelReturn(returnRequest.rma)"
      [disabled]="isCancelling$ | async"
    >
      {{ 'returnRequest.cancel' | cxTranslate }}
    </button>
    <a
      [routerLink]="{ cxRoute: 'orderReturns' } | cxUrl"
      class="btn btn-action"
    >
      {{ 'common.back' | cxTranslate }}
    </a>
  </div>
</ng-container>
