import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {UserIdService, WindowRef} from '@spartacus/core';
import { Order } from '@spartacus/order/root';
import { CustomOrderDetailsService } from 'src/app/spartacus/custom/cms-components/myaccount/order/custom-order-details/custom-order-details.service';
import { filter, take } from 'rxjs/operators';
import { CustomOrderTicketDownloadService } from './custom-order-ticket-download.service';

@Component({
  selector: 'app-custom-order-ticket-download',
  templateUrl: './custom-order-ticket-download.component.html',
})
export class CustomOrderTicketDownloadComponent implements OnInit, OnDestroy {
  @Input() order$: Observable<Order>;
  subscriptions = new Subscription();
  downloadUrl: string;
  loading$ = new BehaviorSubject<boolean>(false);
  visible = false;

  constructor(
    protected userIdService: UserIdService,
    protected orderDetailsService: CustomOrderDetailsService,
    protected customOrderTicketDownloadService: CustomOrderTicketDownloadService,
    protected winRef?: WindowRef,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.order$
        .pipe(
          filter((order) => Boolean(order?.code)),
        )
        .subscribe((order) => {
          this.visible = order?.printTicket;
        })
    );
  }

  handleDownloadUrl(order: Order): void {
    this.loading$.next(true);
    const orderId = this.orderDetailsService.getOrderId(order);
    let userId: string;
    this.userIdService.getUserId()
      .subscribe((occUserId) => userId = occUserId)
      .unsubscribe();
    this.subscriptions.add(
      this.customOrderTicketDownloadService.getOrderTicketDownloadUrl(userId, orderId)
        .pipe(
          take(1),
        )
        .subscribe((downloadUrl) => {
          this.downloadUrl = downloadUrl;
          this.loading$.next(false);
          this.openDownloadUrl();
        })
    );
  }

  openDownloadUrl(): void {
    if (this.downloadUrl) {
      this.winRef.location.href = this.downloadUrl;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
