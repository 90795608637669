import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CurrencyService,
  GlobalMessageService,
  GlobalMessageType,
  InterceptorUtil,
  OccEndpointsService,
  RoutingService,
  ScriptLoader,
  StateWithProcess,
  UserIdService,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomMultiCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-multi-cart.service';
import { PurgeFriendsCheckoutSuccess } from 'src/app/spartacus/custom/core/custom-friends/custom-friends-checkout/store/actions/custom-friends-checkout.action';
import { StateWithFriends } from 'src/app/spartacus/custom/core/custom-friends/store/custom-friends-checkout.state';
import { environment } from 'src/environments/environment';
import { CustomPopupShippingService } from '../../../user/popup-shipping/custom-popup-shipping.service';
import {
  CustomCheckoutPaymentService
} from "../../../../../spartacus/custom/core/checkout/facade/custom-checkout-payment.service";
import { OrderHistoryFacade } from '@spartacus/order/root';

declare var paypal: any;

@Injectable({
  providedIn: 'root',
})
export class CustomPaypalService {

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected scriptLoader: ScriptLoader,
    protected currencyService: CurrencyService,
    protected userIdService: UserIdService,
    protected activeCartService: CustomActiveCartService,
    protected occEndpoints: OccEndpointsService,
    protected ngZone: NgZone,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected activatedRoute?: ActivatedRoute,
    protected globalMessageService?: GlobalMessageService,
    protected customPopupShippingService?: CustomPopupShippingService,
    protected http?: HttpClient,
    protected routingService?: RoutingService,
    protected multiCartService?: CustomMultiCartService,
    protected checkoutService?: CustomCheckoutPaymentService,
    protected friendsStore?: Store<StateWithFriends | StateWithProcess<void>>,
  ) { }

  loadButtons(): void {
    this.includePaypalSDK();
  }

  protected includePaypalSDK(): void {
    // (DRF) OE-1312, 20220919 - REMOVE OLD SCRIPT IF EXIST IN DOM, PAYAPAL BUTTON IS MASTER OF SCRIPT.
    const oldScript = this.document.querySelector('script[src^="' + environment.paypal.src + '"]');
    if (oldScript) {
      oldScript?.parentElement.removeChild(oldScript);
    }
    let currency: string;
    this.currencyService.getActive()
      .subscribe(activeCurrency => currency = activeCurrency)
      .unsubscribe();
    if (environment.paypal.id) {
      this.scriptLoader.embedScript({
        src: environment.paypal.src,
        params: {
          'client-id': environment.paypal.id,
          currency,
          'components': "messages,buttons",
        },
        callback: () => {
          this.addButtons();
        },
        errorCallback: () => {
          this.handleLoadSDKError();
        }
      });
    }
  }

  private addButtons(): void {
    let orderId: string;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    let userId;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    let cartId;
    this.activeCartService
      .getActiveCartId()
      .subscribe((activeCartId) => (cartId = activeCartId))
      .unsubscribe();
    paypal.Buttons({
      fundingSource: paypal.FUNDING.PAYPAL,
      // Call server to set up the transaction
      createOrder: async (data: any, actions: any) => {
        const paypalOrderCreateUrl = this.occEndpoints.buildUrl('paypalOrderCreate', {
          urlParams: { userId, cartId }
        });
        return await this.http.post(paypalOrderCreateUrl, { headers })
          .toPromise()
          .then((res: { id: string }) => {
            orderId = res?.id;
            return orderId;
          })
          .catch(error => this.handleError(error?.error?.errors[0]?.message))
          .finally(() => '');
      },
      // Call server to finalize the transaction
      onApprove: async (data: any, actions: { restart: () => any; }) => {
        const paypalOrderCaptureUrl = this.occEndpoints.buildUrl('paypalOrderCapture', {
          urlParams: { userId, cartId }
        });
        const orderData = await this.http.post(paypalOrderCaptureUrl, { headers })
          .toPromise()
          .then((res: { details: any[], links: any[], debug_id: string, purchase_units: { payments: { captures: any[]; }; }[] }) => res)
          .catch(error => this.handleError(error?.error?.errors[0]?.message))
          .finally(() => false);
        if (!orderData) {
          this.handleError();
          return;
        }
        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show confirmation or thank you
        // This example reads a v2/checkout/orders capture response, propagated from the server
        // You could use a different API or structure for your 'orderData'
        const errorDetail = Array.isArray(orderData.details) && orderData.details[0];
        if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
          return actions.restart(); // Recoverable state, per:
          // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
        }
        if (errorDetail) {
          let msg = '';
          if (errorDetail.description) {
            msg = errorDetail.description;
          }
          if (!environment.production && orderData.debug_id) {
            msg += ' (' + orderData.debug_id + ')';
          }
          // Show failure message
          this.handleError({
            key: 'paypal.error.errorDetail',
            params: { msg },
          });
          return;
        }
        // Successful capture!
        this.onSuccess(
          cartId,
          orderData.links?.find(link => link?.rel === 'order')?.href
        );
      },
      onInit: (data: any, actions: any) => {
        // Enable or disable buttons if terms and conditions checkbox is un/checked
        const termsAndConditionsInput = this.document.getElementById('termsAndConditions');
        this.toggleActions((termsAndConditionsInput as HTMLInputElement), actions);
        // Listen for changes to the checkbox
        termsAndConditionsInput.addEventListener('change', (event) => {
          this.toggleActions((event.target as HTMLInputElement), actions);
        });
      },
      onError: (error: any) => {
        if (!environment.production) {
          console.log('Paypal Error', error);
        }
        this.handleError();
      },
    }).render('#paypal-button-container');
  }

  private toggleActions(input: HTMLInputElement, actions: any): void {
    if (input.checked) {
      actions.enable();
    } else {
      actions.disable();
    }
  }

  protected handleLoadSDKError(): void {
    // Remove script to be able to load it again later
    const failedScript = this.document.querySelector('script[src^="' + environment.paypal.src + '"]');
    if (failedScript) {
      failedScript?.parentElement.removeChild(failedScript);
    }
    this.handleError();
  }

  private handleError(error?: any): void {
    this.globalMessageService.add(
      error ?? { key: 'paypal.error.default' },
      GlobalMessageType.MSG_TYPE_ERROR
    );
  }

  private onSuccess(cartId: string, code: string): void {
    this.orderHistoryFacade.loadOrderDetails(code)
    this.multiCartService.removeCart(cartId);
    this.friendsStore.dispatch(new PurgeFriendsCheckoutSuccess());
    this.checkoutService.clearCheckoutData();
    this.ngZone.run(() => this.routingService.go('order-confirmation/' + code));
  }
}
