import { Injectable } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LOAD_CHECKOUT_STEP, LoadCheckoutStep, LoadCheckoutStepSuccess } from '../actions/custom-checkout.action';
import { Cart } from '@spartacus/cart/base/root';
import { Actions, createEffect, ofType } from '@ngrx/effects';

@Injectable()
export class CustomCheckoutEffects {

  constructor(
    private actions$: Actions,
    protected translation: TranslationService,
  ) {
  }

  loadCheckoutStep$: Observable<LoadCheckoutStep
    | LoadCheckoutStepSuccess> = createEffect(() => this.actions$.pipe(
    ofType(LOAD_CHECKOUT_STEP),
    map((action: LoadCheckoutStep) => action.payload),
    map(({ cart, step }) => {
        const entries = cart?.entries;
        const optionInformation = this.getCheckoutOptionInformation(step, cart);
        return new LoadCheckoutStepSuccess({ step, entries, optionInformation, giftCard: cart?.giftCardProduct  });
      },
    ),
  ));

  getCheckoutOptionInformation(step: number, cart: Cart): any {
    switch (step) {
      case 1: {
        return cart?.deliveryAddress?.formattedAddress;
      }

      case 2: {
        return cart?.deliveryMode?.code;
      }

      case 3: {
        return cart?.paymentMode?.code;
      }

      default:
        return '';
    }
  }
}
