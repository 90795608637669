import { StateUtils } from '@spartacus/core';
import { CustomNewsDetailsModel, CustomNewsListModel, NewsFilter } from '../custom-news.model';

export const NEWS_DETAILS = '[News] News Details';
export const LOAD_NEWS_DETAILS = '[News] Load News Details';
export const LOAD_NEWS_DETAILS_SUCCESS = '[News] Load News Details Success';
export const LOAD_NEWS_DETAILS_FAIL = '[News] Load News Details Fail';
export const CLEAR_NEWS_DETAILS = '[News] Load News Details Fail';

export class LoadNewsDetails extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_NEWS_DETAILS;

  constructor(public payload: string) {
    super(NEWS_DETAILS);
  }
}

export class LoadNewsDetailsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_NEWS_DETAILS_SUCCESS;

  constructor(public payload: CustomNewsDetailsModel) {
    super(NEWS_DETAILS);
  }
}

export class LoadNewsDetailsFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_NEWS_DETAILS_FAIL;

  constructor(public payload: any) {
    super(NEWS_DETAILS, payload);
  }
}

export class ClearNewsDetails extends StateUtils.LoaderResetAction {
  readonly type = CLEAR_NEWS_DETAILS;

  constructor() {
    super(NEWS_DETAILS);
  }
}
export type CustomNewsDetailsActions =
  | LoadNewsDetails
  | LoadNewsDetailsSuccess
  | LoadNewsDetailsFail
  | ClearNewsDetails;
