import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { CustomFriendsService } from 'src/app/spartacus/custom/core/custom-friends/custom-friends-users/facade/custom-friends.service';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { Friend } from '../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { InvitationStatus } from '../../../enum/custom-friends.enum';
import { BusinessFriends } from '../../../enum/business-friends.enum';
import { CustomUserAddressService } from 'src/app/spartacus/custom/core/user/facade/custom-user-address.service';
import { FRIENDS_PAGE_SIZE } from '../../custom-my-friends-list/custom-my-friends-list.component';
import { User } from '@spartacus/user/account/root';

@Component({
  selector: 'app-custom-my-account-edit-form',
  templateUrl: './custom-my-account-edit-form.component.html',
  styleUrls: ['./custom-my-account-edit-form.component.scss'],
})
export class CustomMyAccountEditFormComponent implements OnInit, OnDestroy {
  @Output() readonly active: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isEditMode: boolean;
  @Input() friend: Friend;
  @Input() userLogged: User;
  iconTypes = ICON_TYPE;
  businessFriends = BusinessFriends;
  countries$: Observable<Array<Country>>;
  addFriendForm: FormGroup;
  subscription: Subscription = new Subscription();
  hideUidControl = false;
  formDisabled = false;

  constructor(
    protected userAddressService: CustomUserAddressService,
    protected friendsService: CustomFriendsService,
    protected cd: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.userAddressService.clearUserMiscData();
    this.initForm();
    if (this.friend?.uid && this.isEditMode) {
      this.friendsService.purgeFriend();
      this.friendsService.getFriend(this.friend.uid);
      this.subscription.add(
        this.friendsService
          .loadFriend()
          .pipe(filter((user) => !!user))
          .subscribe((user) => {
            this.friend = user;
            this.addFriendForm.patchValue(user);
            if (user?.inviteStatus === InvitationStatus.UNKNOWN_EMAIL) {
              this.addFriendForm.addControl(
                'newUid',
                new FormControl('', [Validators.required, CustomValidators.emailValidator]),
              );
              this.hideUidControl = true;
            }
          }),
      );
    }
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountriesAll();
        }
      }),
    );
    this.subscription.add(
      this.friendsService
        .getStatus()
        .pipe(
          filter((value) => !!value),
          take(1),
        )
        .subscribe((value) => {
          this.addFriendForm.reset();
          this.friendsService.purgeFriend();
          this.active.emit(!value);
        }),
    );
  }

  initForm(): void {
    const isBusiness = this.userLogged?.exclusiveRole === this.businessFriends.SHAREHOLDER;
    const formConfig = this.getEssentialForm();

    if (isBusiness) {
      Object.assign(formConfig, this.businessFormControls());
    } else if (this.isEditMode) {
      Object.assign(formConfig, this.fullFormControls());
    } else {
      Object.assign(formConfig, this.basicFormControls());
    }
    this.addFriendForm = new FormGroup(formConfig);

    const isFormDisabled = this.friend?.inviteStatus === InvitationStatus.REGISTERED;
    this.formDisabled = isFormDisabled;
    if (isFormDisabled) {
      this.addFriendForm.disable();
    }
    this.cd.detectChanges();
  }

  goBack(): void {
    this.active.emit(false);
  }

  onChangeCountry(): void {
    this.addFriendForm['controls'].defaultPaymentAddress['controls'].postalCode?.updateValueAndValidity();
    this.addFriendForm['controls'].documentIdentifier?.updateValueAndValidity();
  }

  updateFriend(): void {
    if (!this.addFriendForm.valid) {
      this.addFriendForm.markAllAsTouched();
    } else {
      let friend = {
        ...this.addFriendForm.value,
        iban: this.addFriendForm.get('iban')?.value?.replace(/ /g, ''),
      };
      if (this.addFriendForm['controls'].defaultPaymentAddress?.value) {
        friend = {
          ...friend,
          defaultPaymentAddress: {
            ...this.addFriendForm.get('defaultPaymentAddress').value,
            firstName: this.addFriendForm.get('firstNameLastName').value,
            lastName: '',
          },
        };
      }
      this.friendsService.updateFriend(
        friend,
      );
    }
  }

  saveFriend(): void {
    if (!this.addFriendForm.valid) {
      this.addFriendForm.markAllAsTouched();
    } else {
      /*Cuando se crea una invitación hay que añadir el supportedOnBehalfOfUserGroup del usuario al rol del invitado*/
      let friend = {
        ...this.addFriendForm.value,
        exclusiveRole: this.userLogged.supportedOnBehalfOfExclusiveRole,
        iban: this.addFriendForm.get('iban')?.value?.replace(/ /g, ''),
      };
      if (this.addFriendForm['controls'].defaultPaymentAddress?.value) {
        friend = {
          ...friend,
          defaultPaymentAddress: {
            ...this.addFriendForm.get('defaultPaymentAddress').value,
            firstName: this.addFriendForm.get('firstNameLastName').value,
            lastName: '',
          },
        };
      }
      this.friendsService.createFriend(
        friend,
        FRIENDS_PAGE_SIZE,
      );
    }
  }
  private getEssentialForm() {
    return {
      firstName: new FormControl(''),
      firstNameLastName: new FormControl(
        this.addFriendForm?.get('firstNameLastName').value,
        [Validators.required, Validators.maxLength(35)],
      ),
      uid: new FormControl(this.addFriendForm?.get('uid').value, [
        Validators.required,
        Validators.maxLength(60),
        CustomValidators.emailValidator,
      ]),
      sendInvitation: new FormControl(true),
      emailComments: new FormControl('', Validators.maxLength(240)),
    }
  }

  private businessFormControls() {
    return {
      defaultPaymentAddress: new FormControl(null),
      role: new FormControl(''),
      supportedOnBehalfOfUserGroup: new FormControl(''),
    };
  }

  private basicFormControls() {
    return {
      defaultPaymentAddress: new FormControl(null),
      exclusiveRole: new FormControl(''),
      supportedOnBehalfOfExclusiveRole: new FormControl(''),
    };
  }
  private fullFormControls() {
    return {
      documentIdentifier: new FormControl('',
        [
          Validators.required,
          CustomValidators.documentIdentifier(this.isEditMode ? 'exclusiveRole' : this.userLogged?.supportedOnBehalfOfExclusiveRole, 'defaultPaymentAddress.country.isocode'),
        ]),
      iban: new FormControl('', [Validators.required, CustomValidators.iban]),
      exclusiveRole: new FormControl(''),
      supportedOnBehalfOfExclusiveRole: new FormControl(''),
      defaultPaymentAddress: new FormGroup({
        phone: new FormControl('', [Validators.required, CustomValidators.phoneValidator]),
        line1: new FormControl('', [Validators.required, Validators.maxLength(35)]),
        line2: new FormControl('', Validators.maxLength(35)),
        town: new FormControl('', [Validators.required, Validators.maxLength(25)]),
        postalCode: new FormControl('', [Validators.required, Validators.maxLength(12), CustomValidators.postalCode('country.isocode')]),
        country: new FormGroup({
          isocode: new FormControl('', Validators.required),
        }),
      }),
    }
  }
}
