import { HttpErrorResponse, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isCartError, isCartNotFoundError } from "@spartacus/cart/base/core";
import { ErrorModel, GlobalMessageType, HttpErrorHandler, HttpResponseStatus, Priority } from '@spartacus/core';

@Injectable({
    providedIn: 'root',
  })
export class CustomBadCartRequestHandler extends HttpErrorHandler{
    responseStatus = HttpResponseStatus.BAD_REQUEST;

    getPriority(): Priority {
      return Priority.HIGH;
    }

    hasMatch(errorResponse: HttpErrorResponse): boolean {
        return (
          super.hasMatch(errorResponse) &&
          this.getErrors(errorResponse).some(isCartError)
        );
      }
    
    handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
        this.handleCartError(request, response);
    }
    
    protected handleCartError(
        _request: HttpRequest<any>,
        response: HttpErrorResponse
      ): void {
        this.getErrors(response)
          .filter((e) => e.type === 'CartError')
          .forEach((error) => {
            if (error.message && !error.message.includes('Cannot simulate order')
            && !error.message.includes('Cart not found.') 
            && !error.message.includes('Carrito no encontrado.')
            && !error.message.includes('No cart created yet.')
            && !error.message.startsWith('Order with guid')) {
                this.globalMessageService.add(
                error.message,
                GlobalMessageType.MSG_TYPE_ERROR
              );
            }
          });
      }

    protected getErrors(response: HttpErrorResponse): ErrorModel[] {
        return (response.error?.errors || []).filter(
          (error: any) => error.type !== 'JaloObjectNoLongerValidError'
        );
    }
}