import { ModuleWithProviders, NgModule } from '@angular/core';
import { CustomBaseStoreStoreModule } from './store/custom-base-store-store.module';
import { provideConfig } from '@spartacus/core';
import { customShippingDialogModalLayoutConfig } from './custom-base-store.config';

@NgModule({
  imports: [CustomBaseStoreStoreModule],
  providers: [
    provideConfig(customShippingDialogModalLayoutConfig)
  ]
  
})

export class CustomBaseModule {
  static forRoot(): ModuleWithProviders<CustomBaseStoreStoreModule> {
    return {
      ngModule: CustomBaseStoreStoreModule,
    };
  }
}
