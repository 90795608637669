import {Injectable} from '@angular/core';
import {
  createFrom,
  EventService,
  FeatureConfigService,
  ProductSearchService,
  ProductService,
  StateEventService,
} from '@spartacus/core';
import {EMPTY, Observable} from 'rxjs';
import {filter, map, skip, switchMap, take, tap} from 'rxjs/operators';
import {
  CustomCreateOrderReturnFailEvent,
  CustomProductDetailsPageEvent,
  SearchPageResultsEvent,
} from './custom-product-page.events';
import {NavigationEvent, PageEvent} from '@spartacus/storefront';
import {EcommerceType} from "../tag-manager-feature.model";
import {CustomCleanEcommerceEvent} from "../common/custom-common.events";
import {getProductDetailSelectedUnit} from "./custom-product-detail.utils";
import { OrderActions } from '@spartacus/order/core';

@Injectable({
  providedIn: 'root',
})
export class CustomProductPageEventBuilder {
  constructor(
    protected eventService: EventService,
    protected productService: ProductService,
    protected productSearchService: ProductSearchService,
    protected stateEventService?: StateEventService,
    // TODO: #10896 - remove this
    /** @deprecated @since 3.1 - this will be removed in 4.0 */
    protected featureConfigService?: FeatureConfigService,
  ) {
    this.register();
  }

  protected register(): void {
    this.eventService.register(
      SearchPageResultsEvent,
      this.buildSearchPageResultsEvent(),
    );

    // this.eventService.register(
    //   CustomProductDetailsPageEvent,
    //   this.buildProductDetailsPageEvent()
    // );

    this.stateEventService.register({
      action: OrderActions.CREATE_ORDER_RETURN_REQUEST_FAIL,
      event: CustomCreateOrderReturnFailEvent,
      factory: (action: OrderActions.CreateOrderReturnRequestFail) =>
        createFrom(CustomCreateOrderReturnFailEvent, {
          ...action.payload,
        }),
    });
  }

  protected buildProductDetailsPageEvent(): Observable<CustomProductDetailsPageEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      filter((navigationEvent) => navigationEvent.semanticRoute === 'product'),
      switchMap((navigationEvent) =>
        this.productService.get(navigationEvent.context.id).pipe(
          filter((product) => Boolean(product)),
          take(1),
          tap(() => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
          map((product) => {
              return createFrom(CustomProductDetailsPageEvent, {
                event: EcommerceType.VIEW_ITEM,
                ecommerce: {
                  items: [{
                    item_name: product.name || product.code,
                    item_id: product.code,
                    price: product.price?.value.toString() || '',
                    item_brand: product.brands ? product.brands[0].name : '',
                    item_category: product.categories ? product.categories[0]?.name || '' : '',
                    item_category2: product.categories ? product.categories[1]?.name || '' : '',
                    item_category3: product.categories ? product.categories[2]?.name || '' : '',
                    item_variant: getProductDetailSelectedUnit(product),
                    quantity: 1
                  }]
                }
              });
            }
          )
        )
      )
    );
  }

  protected buildSearchPageResultsEvent(): Observable<SearchPageResultsEvent> {
    const searchResults$ = this.productSearchService.getResults().pipe(
      // skipping the initial value, and preventing emission of the previous search state
      skip(1),
    );

    return this.eventService.get(NavigationEvent).pipe(
      switchMap((navigationEvent) => {
        if (navigationEvent?.semanticRoute !== 'search') {
          return EMPTY;
        }

        return searchResults$.pipe(
          map((searchResults) =>
            createFrom(SearchPageResultsEvent, {
              ...this.createDeprecatedPageEvent(navigationEvent),
              navigation: { ...navigationEvent },
              ...{
                searchTerm: searchResults?.freeTextSearch,
                numberOfResults: searchResults?.pagination?.totalResults,
              },
            }),
          ),
        );
      }),
    );
  }

  // TODO: #10896 - remove this method
  private createDeprecatedPageEvent(
    navigation: NavigationEvent
  ): PageEvent | undefined {
    if (
      !this.featureConfigService ||
      this.featureConfigService.isLevel('!3.1')
    ) {
      return { navigation };
    }
    return undefined;
  }
}
