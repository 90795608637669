<span class="label">{{ 'asm.agentSessionTimer.label' | cxTranslate }}:</span>
<span class="time"
  >{{ timeLeft | formatTimer }}
  {{ 'asm.agentSessionTimer.minutes' | cxTranslate }}</span
>
<button
  class="reset"
  title="{{ 'asm.agentSessionTimer.reset' | cxTranslate }}"
  (click)="resetTimer()"
></button>
