import { Friend } from 'src/app/spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { Component, OnInit } from '@angular/core';
import {LaunchDialogService} from "@spartacus/storefront";
import { filter, map, take } from 'rxjs/operators';
import { CustomFriendsService } from 'src/app/spartacus/custom/core/custom-friends/custom-friends-users/facade/custom-friends.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-custom-my-friends-dialog',
    templateUrl: './custom-my-friends-dialog.component.html',
    styleUrls: ['./custom-my-friends-dialog.component.scss'],
})
export class CustomMyFriendsDialogComponent implements OnInit {
  constructor(private launchDialogService: LaunchDialogService,
    protected customFriendService: CustomFriendsService,
    ) {}
  friend$: Observable<any> = this.launchDialogService.data$.pipe(
    filter(data => !!data),
    take(1),
    map((data: any) => {
      return data
    })
  );

  ngOnInit(): void {}
handleRemoveFriend(friend: Friend): void {
    try {
      this.customFriendService.removeFriend(friend.uid);
      this.launchDialogService.closeDialog(true);

    } catch (error) {
      console.log(error);
    }

}
  closeModal(value: boolean): void {

    this.launchDialogService.closeDialog(value);
  }
}


