import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageActions, GlobalMessageType, SiteContextActions, normalizeHttpError, withdrawOn } from '@spartacus/core';
import { CustomCartEntryConnector } from '../../conectors/entry/custom-cart-entry.connector';
import { CartActions } from '@spartacus/cart/base/core';
import { CartModification } from '@spartacus/cart/base/root';
import { Observable, from } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';
import { CART_ADD_ENTRY_UNIT, CartAddEntryUnit } from '../actions/custom-cart-entry.action';

@Injectable()
export class CustomCartEntryEffects {
  private contextChange$ = this.actions$.pipe(
    ofType(
      SiteContextActions.CURRENCY_CHANGE,
      SiteContextActions.LANGUAGE_CHANGE
    )
  );

  addUnitEntry$: Observable<
    | CartActions.CartAddEntrySuccess
    | CartActions.CartAddEntryFail
    | CartActions.LoadCart
    | GlobalMessageActions.AddMessage
  > = createEffect(() => this.actions$.pipe(
    ofType(CART_ADD_ENTRY_UNIT),
    map((action: CartAddEntryUnit) => action.payload),
    concatMap((payload) => {
      return this.cartEntryConnector
        .addUnit(
          payload.userId,
          payload.cartId,
          payload.productCode,
          payload.quantity,
          payload.unitCode,
        )
        .pipe(
          map(
            (cartModification: CartModification) =>
              new CartActions.CartAddEntrySuccess({
                ...payload,
                ...(cartModification as Required<CartModification>),
              })
          ),
          catchError((error) => {
          const actions: Array<
          | CartActions.CartAddEntryFail
          | CartActions.LoadCart
          | GlobalMessageActions.AddMessage
          > = [
            new CartActions.CartAddEntryFail({
              ...payload,
              error: normalizeHttpError(error),
            }),
            new CartActions.LoadCart({
              cartId: payload.cartId,
              userId: payload.userId,
            }),
          ];
          error?.error?.errors?.forEach((err) => {
            if (err.message) {
              actions.push(
                new GlobalMessageActions.AddMessage({
                  text: { raw: err.message },
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                  timeout: 5000,
                })
              );
            }
          });
          return from(actions);
        }
          )
        );
    }),
    withdrawOn(this.contextChange$)
  ));

  constructor(
    private actions$: Actions,
    private cartEntryConnector: CustomCartEntryConnector
  ) {}
}
