import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FooterNavigationComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-footer-navigation',
  templateUrl: './custom-footer-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFooterNavigationComponent extends FooterNavigationComponent {
}
