<a (click)="handleMiniCartClick()">
  <i class="icon-cart1"></i>
  <ng-container *ngIf="quantity$ | async as quantity">
    <span class="count" *ngIf="quantity !== 0">
      {{ "miniCart.count" | cxTranslate: { count: quantity } }}
    </span>
  </ng-container>
</a>
<app-custom-mini-cart-preview
  *ngIf="displayPreview"
  (closePreview)="togglePreview($event)"
></app-custom-mini-cart-preview>
