import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CmsSearchBoxComponent, FacetValue, PageType, RoutingService, UrlPipe, WindowRef } from '@spartacus/core';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CustomProductsSearchBoxComponentService } from './custom-products-search-box-component.service';
import { CmsComponentData, ICON_TYPE, SearchBoxConfig, SearchResults } from '@spartacus/storefront';
import { Router } from '@angular/router';
import { FacetService } from '../../../../../../../spartacus/custom/cms-components/product/product-list';

const DEFAULT_SEARCH_BOX_CONFIG: SearchBoxConfig = {
  minCharactersBeforeRequest: 1,
  displayProducts: true,
  displaySuggestions: false,
  maxProducts: 5,
  maxSuggestions: 5,
  displayProductImages: true,
};

@Component({
  selector: 'app-custom-products-searchbox',
  templateUrl: './custom-products-search-box.component.html',
  styleUrls: ['./custom-products-search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomProductSearchBoxComponent implements OnInit, OnDestroy {
  @Input() config: SearchBoxConfig;
  @Output() readonly appendProduct: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Sets the search box input field
   */
  @Input('queryProductText')
  set queryText(value: string) {
    if (value) {
      this.search(value);
    }
  }

  iconTypes = ICON_TYPE;

  /**
   * In some occasions we need to ignore the close event,
   * for example when we click inside the search result section.
   */
  private ignoreCloseEvent = false;
  chosenWord = '';
  public subscription: Subscription;

  constructor(
    protected searchBoxComponentService: CustomProductsSearchBoxComponentService,
    protected componentData: CmsComponentData<CmsSearchBoxComponent>,
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    protected urlPipe: UrlPipe,
    private router: Router,
    protected facetService: FacetService,
  ) {}

  /**
   * Returns the SearchBox configuration. The configuration is driven by multiple
   * layers: default configuration, (optional) backend configuration and (optional)
   * input configuration.
   */
  protected config$: Observable<SearchBoxConfig> = (
    this.componentData?.data$ || of({} as any)
  ).pipe(
    map((config) => {
      const isBool = (obj: SearchBoxConfig, prop: string): boolean =>
        obj?.[prop] !== 'false' && obj?.[prop] !== false;

      return {
        ...DEFAULT_SEARCH_BOX_CONFIG,
        ...config,
        displayProducts: isBool(config, 'displayProducts'),
        displayProductImages: isBool(config, 'displayProductImages'),
        displaySuggestions: isBool(config, 'displaySuggestions'),
        // we're merging the (optional) input of this component, but write the merged
        // result back to the input property, as the view logic depends on it.
        ...this.config,
      };
    }),
    tap((config) => (this.config = config)),
  );

  results$: Observable<SearchResults> = this.config$.pipe(
    switchMap((config) => this.searchBoxComponentService.getResults(config)),
  );

  ngOnInit(): void {
    this.subscription = this.routingService
      .getRouterState()
      .pipe(filter((data) => !data.nextState))
      .subscribe((data) => {
        if (
          !(
            data.state.context?.id === 'search' &&
            data.state.context?.type === PageType.CONTENT_PAGE
          )
        ) {
          this.chosenWord = '';
        }
      });
  }

  /**
   * Closes the searchBox and opens the search result page.
   */
  search(query: string): void {
    this.searchBoxComponentService.search(query, this.config);
  }

  /**
   * Dispatch UI events for Suggestion selected
   *
   * @param eventData the data for the event
   */
  dispatchSuggestionEvent(eventData: any): void {
    const linkParams = this.getLinkParams(eventData.selectedSuggestion);
    let query = '';
    let param = '';
    Object.keys(linkParams).forEach(key => {
        param = linkParams[key].split(':')[0] + '?';
        query += `${ key }=${ linkParams[key] }`;
      },
    );
    const url = decodeURIComponent(this.searchBoxComponentService.appendSearchQuery(param + query));
    this.appendProduct.emit({ text: eventData.selectedSuggestion.name, url });
  }

  /**
   * Dispatch UI events for Product selected
   *
   * @param eventData the data for the event
   */
  dispatchProductEvent(eventData: any): void {
    const url = this.searchBoxComponentService.appendProductQuery(eventData.product);
    // TODO: Implement for GTM to track emailed products
    // const productData = {
    //   product: eventData.product,
    //   url,
    // };
    // this.searchBoxComponentService.dispatchProductSelectedEvent({
    //   freeText: productData.freeText,
    //   productCode: productData.productCode,
    // });
    this.appendProduct.emit({ text: eventData.product.name, url });
  }

  /**
   * Closes the type-ahead searchBox.
   */
  close(event: UIEvent, force?: boolean): void {
    // Use timeout to detect changes
    setTimeout(() => {
      if ((!this.ignoreCloseEvent && !this.isSearchBoxFocused()) || force) {
        this.blurSearchBox(event);
      }
    });
  }

  protected blurSearchBox(event: UIEvent): void {
    if (event && event.target) {
      (event.target as HTMLElement).blur();
    }
  }

  // Check if focus is on searchbox or result list elements
  private isSearchBoxFocused(): boolean {
    return (
      this.getResultElements().includes(this.getFocusedElement()) ||
      this.winRef.document.querySelector('input[aria-label="search"]') ===
      this.getFocusedElement()
    );
  }

  /**
   * Especially in mobile we do not want the search icon
   * to focus the input again when it's already open.
   */
  avoidReopen(event: UIEvent): void {
    if (this.searchBoxComponentService.hasBodyClass('searchbox-is-active')) {
      this.close(event);
      event.preventDefault();
    }
  }

  // Return result list as HTMLElement array
  private getResultElements(): HTMLElement[] {
    return Array.from(
      this.winRef.document.querySelectorAll('.products > a, .suggestions > a'),
    );
  }

  // Return focused element as HTMLElement
  private getFocusedElement(): HTMLElement {
    return this.winRef.document.activeElement as HTMLElement;
  }

  updateChosenWord(chosenWord: string): void {
    this.chosenWord = chosenWord;
  }

  private getFocusedIndex(): number {
    return this.getResultElements().indexOf(this.getFocusedElement());
  }

  // Focus on previous item in results list
  focusPreviousChild(event: UIEvent): void {
    event.preventDefault(); // Negate normal keyscroll
    const [results, focusedIndex] = [
      this.getResultElements(),
      this.getFocusedIndex(),
    ];
    // Focus on last index moving to first
    if (results.length) {
      if (focusedIndex < 1) {
        results[results.length - 1].focus();
      } else {
        results[focusedIndex - 1].focus();
      }
    }
  }

  // Focus on next item in results list
  focusNextChild(event: UIEvent): void {
    event.preventDefault(); // Negate normal keyscroll
    const [results, focusedIndex] = [
      this.getResultElements(),
      this.getFocusedIndex(),
    ];
    // Focus on first index moving to last
    if (results.length) {
      if (focusedIndex >= results.length - 1) {
        results[0].focus();
      } else {
        results[focusedIndex + 1].focus();
      }
    }
  }

  /**
   * Disables closing the search result list.
   */
  disableClose(): void {
    this.ignoreCloseEvent = true;
  }

  preventDefault(ev: UIEvent): void {
    ev.preventDefault();
  }

  /**
   * Clears the search box input field
   */
  clear(el: HTMLInputElement): void {
    this.disableClose();
    el.value = '';
    this.searchBoxComponentService.clearResults();

    // Use Timeout to run after blur event to prevent the searchbox from closing on mobile
    setTimeout(() => {
      // Retain focus on input lost by clicking on icon
      el.focus();
      this.ignoreCloseEvent = false;
    });
  }

  getLinkParams(value: FacetValue): { [p: string]: string } {
    return this.facetService.getLinkParams(value.query?.query.value);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
