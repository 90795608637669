import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMyAccountFriendEditFormComponent } from './components/custom-my-account-friend-edit/custom-my-account-friend-edit.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { CustomMyFriendsListComponent } from './components/custom-my-friends-list/custom-my-friends-list.component';
import { CustomMyFriendsFilterComponent } from './components/custom-my-friends-filter/custom-my-friends-filter.component';
import { CustomIconsModule } from '../../../../spartacus/custom/cms-components/misc/icon/custom-icons.module';
import { CardModule, FormErrorsModule, IconModule, ListNavigationModule, SpinnerModule } from '@spartacus/storefront';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomMyAccountEditFormComponent } from './components/custom-my-account-friend-edit/custom-my-account-edit-form/custom-my-account-edit-form.component';
import { RouterModule } from '@angular/router';
import { CustomMyFriendsDialogComponent } from './components/custom-my-friends-dialog/custom-my-friends-dialog.component';
import { CustomRegisterFriendFormComponent } from './components/custom-register-friend-form/custom-register-friend-form.component';
import { CustomFriendsGuard } from './guard/custom-friends-guard';
import { CustomFriendEmailFormComponent } from './components/custom-friend-email-form/custom-friend-email-form.component';
import { CustomProductsSearchBoxModule } from './components/custom-friend-email-form/custom-products-search-box/custom-products-search-box.module';
import { QuillModule } from 'ngx-quill';
import { CustomMyFriendlyDialogConfig } from './components/custom-my-friends-list/custom-my-friends-config';
@NgModule({
  declarations: [
    CustomMyAccountFriendEditFormComponent,
    CustomMyFriendsListComponent,
    CustomMyFriendsFilterComponent,
    CustomMyAccountEditFormComponent,
    CustomMyFriendsDialogComponent,
    CustomRegisterFriendFormComponent,
    CustomFriendEmailFormComponent,
  ],
  exports: [CustomMyAccountFriendEditFormComponent],
  providers: [
    provideConfig(CustomMyFriendlyDialogConfig)
  ],

  imports: [
    FormsModule,
    ListNavigationModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    CardModule,
    I18nModule,
    CustomIconsModule,
    SpinnerModule,
    FormErrorsModule,
    UrlModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomMyFriendsListComponent: {
          component: CustomMyAccountFriendEditFormComponent,
          guards: [CustomFriendsGuard],
        },
        MyFriendRegisterFormComponent: {
          component: CustomRegisterFriendFormComponent,
        },
        MyFriendsEmailsComponent: {
          component: CustomFriendEmailFormComponent,
        },
        MyFriendsEmailsProductSearchBoxComponent: {
          component: CustomFriendEmailFormComponent,
        },
      },
    } as CmsConfig),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ font: ['Arial'] }],
          ['clean'], // remove formatting button
          ['link'],  // link and image, video
        ],
      },
    }),
    IconModule,
    CustomProductsSearchBoxModule,
  ],
})
export class CustomMyAccountFriendEditFormModule {}
