<ng-container *ngIf="product$ | async as product">  
  <ul class="brands" *ngIf="product?.brands">
    <li *ngFor="let brand of product?.brands">
      <h3>
        <a
          [routerLink]="{ cxRoute: 'category', params: brand } | cxUrl"
          class="btn-link brand"
        >
          {{ brand?.name }}
        </a>
      </h3>
    </li>
  </ul>
  <ng-template
    [cxOutlet]="outlets.SUMMARY"
    [cxOutletContext]="{ product: product }"
  >
    <p [innerHTML]="product?.summary" class="summary"></p>
  </ng-template>

  <!-- @TODO: Temp. Comment out share link while not in use by CMS -->
  <!-- <ng-container *cxOutlet="outlets.SHARE">
        <div>
          <a href="#" class="share btn-link">
            {{ 'productSummary.share' | cxTranslate }}
          </a>
        </div>
      </ng-container> -->
</ng-container>
