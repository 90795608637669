import {Injectable} from "@angular/core";
import {CustomActiveCartService} from "../../../../../custom/core/cart/facade/custom-active-cart.service";
import {Observable} from "rxjs";
import {NavigationEvent} from "@spartacus/storefront";
import {filter, map, pairwise, startWith, tap, withLatestFrom} from "rxjs/operators";
import {CustomCleanEcommerceEvent} from "../../common/custom-common.events";
import {EcommerceType, ProductItem} from "../../tag-manager-feature.model";
import {CustomNavigateToCheckoutEvent} from "./custom-checkout-navigation.events";
import { Cart } from "@spartacus/cart/base/root";
import { EventService, createFrom } from "@spartacus/core";
import { CustomOrderEntry } from "src/app/spartacus/custom/feature-libs/cart/root/models/cart.model";

@Injectable({providedIn: 'root'})
export class CustomCheckoutNavigationEventBuilder {
  constructor(
    protected eventService: EventService,
    protected activeCartService: CustomActiveCartService
  ) {
    this.register();
  }

  protected register(): void {
    this.registerNavigateToCheckoutEvents();
  }

  protected registerNavigateToCheckoutEvents(): void {
    this.eventService.register(
      CustomNavigateToCheckoutEvent,
      this.registerNavigateToCheckout(),
    );
  }

  protected registerNavigateToCheckout(): Observable<CustomNavigateToCheckoutEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      startWith(<NavigationEvent>null),
      pairwise(),
      withLatestFrom(this.activeCartService.getActive())
    ).pipe(
      filter(([[prev, curr]]) => (!prev?.url.includes('checkout') && curr.url.includes('checkout'))),
      tap(() => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      map(([, cart]) => {
        return createFrom(CustomNavigateToCheckoutEvent, {
          event: EcommerceType.BEGIN_CHECKOUT,
          ecommerce: {
            items: this.getCartProductItems(cart) || [],
          }
        })
      }
      )
    );
  }

  private getCartProductItems(cart: Cart): ProductItem[] {
    return cart.entries.map((entry: CustomOrderEntry): ProductItem => {
      return {
        item_name: entry.product.name || entry.product.code,
        item_id: entry.product.code,
        price: entry.basePrice.value.toString(),
        item_brand: entry.product.brands ? entry.product.brands[0].name : '',
        item_category: entry.product.categories ? entry.product.categories[0]?.name || '' : '',
        item_category2: entry.product.categories ? entry.product.categories[1]?.name || '' : '',
        item_category3: entry.product.categories ? entry.product.categories[2]?.name || '' : '',
        item_variant: entry.unit?.name ? entry.unit.name : '',
        quantity: entry.quantity
      };
    });
  }
}
