import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  GlobalMessageActions,
  GlobalMessageType,
  normalizeHttpError,
  SiteContextActions,
  TranslationService,
  withdrawOn,
} from '@spartacus/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CustomCheckoutConnector } from '../../connectors/custom-checkout.connector';
import { CustomCheckoutActions } from '../actions';
import {
  LoadSimulateCartFail,
  LoadSimulateCartSuccess,
  LoadValidateCart,
  LoadValidateCartFail,
  LoadValidateCartSuccess,
  ValidateCartActionTypes,
} from '../actions/custom-validate-cart.action';
import { SeverityEnum } from 'src/app/model/severity.model';
import { CartActions } from '@spartacus/cart/base/core';
import { LoadCart, LoadCartSuccess } from '@spartacus/cart/base/core/store/actions/cart.action';
import { CartModification } from '@spartacus/cart/base/root';
import { CustomValidateCartService } from 'src/app/services/cart/validate-cart.service';

@Injectable()
export class CustomCheckoutEffects {

  constructor(
    private actions$: Actions,
    private checkoutConnector: CustomCheckoutConnector,
    protected translation: TranslationService,
    protected customValidateCartService: CustomValidateCartService,
  ) {
  }

  private contextChange$ = this.actions$.pipe(
    ofType(
      SiteContextActions.CURRENCY_CHANGE,
      SiteContextActions.LANGUAGE_CHANGE,
    ),
  );

  loadResysPayment$: Observable<| CustomCheckoutActions.LoadRedsysPaymentSuccess
    | GlobalMessageActions.AddMessage
    | CustomCheckoutActions.LoadRedsysPaymentFail> = createEffect(() => this.actions$.pipe(
    ofType(CustomCheckoutActions.LOAD_REDSYS_PAYMENT),
    map((action: any) => action.payload),
    mergeMap(({ userId, cartId, saveCard }) => {
      return this.checkoutConnector
        .loadRedsysPayment(userId, cartId, saveCard)
        .pipe(
          switchMap((data) => [
            new CustomCheckoutActions.LoadRedsysPaymentSuccess(data),
          ]),
          catchError((error) =>
            of(new CustomCheckoutActions.LoadRedsysPaymentFail(normalizeHttpError(error))),
          ),
        );
    }),
    withdrawOn(this.contextChange$),
  ));

  loadValidateCart$: Observable<LoadValidateCartFail
    | LoadValidateCartSuccess
    | LoadCart
    | GlobalMessageActions.AddMessage
    | CartActions.DeleteCart
  > = createEffect(() => this.actions$.pipe(
    ofType(ValidateCartActionTypes.LOAD_VALIDATE_CART),
    map((action: LoadValidateCart) => action.payload),
    mergeMap((payload) =>
      this.checkoutConnector
        .validateCart(payload.userId, payload.cartId)
        .pipe(
          switchMap((data) => {
              const actions: Array<
                | CartActions.DeleteCart
                | CartActions.LoadCart
                | LoadValidateCartSuccess
                | GlobalMessageActions.AddMessage
              > = [
                new LoadValidateCartSuccess(data),
              ];
              let hasFatalError = false;
              if (data?.cartModifications?.length) {
                const cartModificationMessage = {};
                data.cartModifications.forEach((cartModification: CartModification) => {
                  if (cartModification.severity === SeverityEnum.FATAL) {
                    hasFatalError = true;
                  }
                  if (cartModification.statusMessage && !cartModificationMessage?.[cartModification.statusCode]) {
                    cartModificationMessage[cartModification.statusCode] = {
                      statusMessage: cartModification.statusMessage,
                      severity: cartModification.severity || '',
                    };
                  } else {
                    if (!cartModificationMessage?.[cartModification.statusCode]) {
                      cartModificationMessage[cartModification.statusCode] = {
                        information: '',
                        entries: [],
                      };
                    }
                    this.translation.translate(`httpHandlers.cartModification.${ cartModification.statusCode }.label`)
                      .subscribe(
                        (translatedMessage) => cartModificationMessage[cartModification.statusCode].information = translatedMessage
                      );
                    const product = cartModification.entry?.product?.name ?? '';
                    const quantity = cartModification.quantityAdded;
                    const oldQuantity = cartModification.quantity;
                    this.translation.translate(`httpHandlers.cartModification.${ cartModification.statusCode }.entries`,
                      {
                        product,
                        oldQuantity,
                        quantity,
                      },
                    ).subscribe((modifiedLists: string) => {
                      cartModificationMessage[cartModification.statusCode].entries = [
                        ...cartModificationMessage[cartModification.statusCode].entries,
                        modifiedLists,
                      ];
                    }).unsubscribe();
                  }
                });
                if (hasFatalError) {
                    actions.push(
                      new CartActions.DeleteCart({
                        userId: payload.userId,
                        cartId: payload.cartId,
                      })
                    );
                } else {
                  actions.push(
                    new CartActions.LoadCart({
                      cartId: payload.cartId,
                      userId: payload.userId,
                      extraData: {
                        active: true,
                      },
                    })
                  );
                }
                Object.values(cartModificationMessage)
                .map((messages: { information: string, entries: Array<string>, statusMessage: string, severity: SeverityEnum }) => {
                  actions.push(
                    new GlobalMessageActions.AddMessage({
                      text: {
                        raw: messages?.statusMessage ?? `${ messages?.information } ${ messages?.entries.join(' ') }`,
                      },
                      type: this.getMessageType(messages?.severity),
                      timeout: 10000,
                    })
                  );
                });
              }
              return actions;
            },
          ),
          catchError((error) =>
            from([
              new LoadValidateCartFail(normalizeHttpError(error)),
              new CartActions.LoadCart({
                cartId: payload.cartId,
                userId: payload.userId,
              }),
              new GlobalMessageActions.AddMessage({
                text: error?.error?.errors[0]?.type ? { key: error?.error?.errors[0]?.type } : { raw: error?.error?.errors[0]?.type },
                type: GlobalMessageType.MSG_TYPE_ERROR,
              }),
            ]),
          ),
        ),
    ),
  ));

  loadSimulateCart$: Observable<LoadSimulateCartFail
    | LoadSimulateCartSuccess
    | LoadCart
    | LoadCartSuccess
    | GlobalMessageActions.AddMessage> = createEffect(() => this.actions$.pipe(
    ofType(ValidateCartActionTypes.LOAD_SIMULATE_CART),
    map((action: any) => action.payload),
    mergeMap((payload: { userId: string; cartId: string; validate: boolean }) =>
      this.checkoutConnector
        .simulateOrder(payload.userId, payload.cartId)
        .pipe(
          switchMap((data) => {
            const successResponse = [
              new LoadSimulateCartSuccess(this.customValidateCartService.validateCartData(data)),
              new CartActions.LoadCartSuccess({
                cart: data,
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ];
            if (payload.validate) {
              if (this.customValidateCartService.validateCartData(data)) {
                return successResponse;
              } else {
                return this.simulateCartFailed(payload);
              }
            } else {
              return successResponse;
            }
          }),
          catchError(() => {
            return this.simulateCartFailed(payload);
            },
          ),
        ),
    ),
    withdrawOn(this.contextChange$),
  ));

  createBillingAddress$: Observable<
    | CustomCheckoutActions.CreateBillingAddressSuccess
    | CustomCheckoutActions.CreateBillingAddressFail
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomCheckoutActions.CREATE_BILLING_ADDRESS),
    map((action: any) => action.payload),
    mergeMap((payload) => {
      return this.checkoutConnector
        .createBillingAddress(payload.userId, payload.cartId, payload.billingAddress)
        .pipe(
          switchMap(
            () => [
              new CustomCheckoutActions.CreateBillingAddressSuccess(
                payload.billingAddress
              ),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ]
          ),
          catchError((error) =>
            of(
              new CustomCheckoutActions.CreateBillingAddressFail(
                normalizeHttpError(error)
              )
            )
          )
        );
    }),
    withdrawOn(this.contextChange$)
  ));

  setBillingAddress$: Observable<
    | CustomCheckoutActions.SetBillingAddressSuccess
    | CustomCheckoutActions.SetBillingAddressFail
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomCheckoutActions.SET_BILLING_ADDRESS),
    map((action: any) => action.payload),
    mergeMap((payload) => {
      return this.checkoutConnector
        .setBillingAddress(payload.userId, payload.cartId, payload.billingAddressId)
        .pipe(
          switchMap(
            () => [
              new CustomCheckoutActions.SetBillingAddressSuccess(
                payload.billingAddress
              ),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ]
          ),
          catchError((error) =>
            of(
              new CustomCheckoutActions.SetBillingAddressFail(
                normalizeHttpError(error)
              )
            )
          )
        );
    }),
    withdrawOn(this.contextChange$)
  ));

  unsetBillingAddress$: Observable<
    | CustomCheckoutActions.UnsetBillingAddressSuccess
    | CustomCheckoutActions.UnsetBillingAddressFail
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomCheckoutActions.UNSET_BILLING_ADDRESS),
    map((action: any) => action.payload),
    mergeMap((payload) => {
      return this.checkoutConnector
        .unsetBillingAddress(payload.userId, payload.cartId)
        .pipe(
          switchMap(
            () => [
              new CustomCheckoutActions.UnsetBillingAddressSuccess(),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ]
          ),
          catchError((error) =>
            of(
              new CustomCheckoutActions.UnsetBillingAddressFail(
                normalizeHttpError(error)
              )
            )
          )
        );
    }),
    withdrawOn(this.contextChange$)
  ));

  private getMessageType(severity: SeverityEnum = SeverityEnum.ERROR): GlobalMessageType {
    switch (severity) {
      case SeverityEnum.INFO:
        return GlobalMessageType.MSG_TYPE_INFO;
      case SeverityEnum.WARN:
        return GlobalMessageType.MSG_TYPE_WARNING;
      case SeverityEnum.ERROR:
      default:
        return GlobalMessageType.MSG_TYPE_ERROR;
    }
  }

  simulateCartFailed(payload): Observable<LoadSimulateCartFail
    | GlobalMessageActions.AddMessage
    | CartActions.LoadCart>{
    this.customValidateCartService.redirectToCartPage();
    return from([
      new GlobalMessageActions.AddMessage({
        text: { key: 'cartDetails.missingInformation' },
        type: GlobalMessageType.MSG_TYPE_WARNING,
        timeout: 10000
      }),
      new CartActions.LoadCart({
        userId: payload.userId,
        cartId: payload.cartId,
      }),
    ]);
  }
}
