import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError, SiteContextActions } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BillHistoryList } from 'src/app/model/bill.model';
import { CustomUserBillConnector } from '../../connectors/custom-user-bill.connector';
import { UserBillsActions } from '../actions';

@Injectable()
export class CustomUserBillsEffect {
  constructor(
    private actions$: Actions,
    private billConnector: CustomUserBillConnector,
  ) { }

  loadUserBills$: Observable<UserBillsActions.UserBillsAction> = createEffect(() => this.actions$.pipe(
    ofType(UserBillsActions.LOAD_USER_BILLS),
    map((action: UserBillsActions.LoadUserBills) => action.payload),
    switchMap((payload) => {
      return this.billConnector.getHistory(
        payload.userId,
        payload.pageSize,
        payload.currentPage,
        payload.sort,
        payload.billsFilter,
      )
        .pipe(
          map((bills: BillHistoryList) => {
            return new UserBillsActions.LoadUserBillsSuccess(bills);
          }),
          catchError((error) =>
            of(new UserBillsActions.LoadUserBillsFail(normalizeHttpError(error)))
          )
        );
    })
  ));

  resetUserBills$: Observable<UserBillsActions.ClearUserBills> = createEffect(() => this.actions$.pipe(
    ofType(SiteContextActions.LANGUAGE_CHANGE),
    map(() => {
      return new UserBillsActions.ClearUserBills();
    })
  ));
}
