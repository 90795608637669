import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomNewsletterAdapter } from './custom-newsletter.adapter';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable()
export class CustomOccNewsletterAdapter implements CustomNewsletterAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) { }

  public updateNewsletter(
    email: string,
    consentTemplateId: string,
    consentTemplateVersion: string,
    accept: boolean,
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl('consents');
    const params: HttpParams = new HttpParams()
      .set('email', email)
      .set('consentTemplateId', consentTemplateId)
      .set('consentTemplateVersion', consentTemplateVersion)
      .set('accept', String(accept));
    return this.http.post(url, {}, { headers, params });
  }
}
