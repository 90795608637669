import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomNewsSliderComponent } from './custom-news-slider.component';
import { RouterModule } from '@angular/router';
import { CarouselModule, MediaModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomParagraphModule } from '../../../../../../spartacus/custom/cms-components/content/custom-paragraph/custom-paragraph.module';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';


@NgModule({
  declarations: [CustomNewsSliderComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    CarouselModule,
    I18nModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomNewsSliderContentComponent: {
          component: CustomNewsSliderComponent,
        },
        CmsCustomFilteredNewsSliderContentComponent: {
          component: CustomNewsSliderComponent,
        },
      },
    } as CmsConfig),
    CustomParagraphModule,
  ],
  exports: [CustomNewsSliderComponent],
  entryComponents: [CustomNewsSliderComponent],
})
export class CustomNewsSliderModule {}
