

<!-- FORM -->
<ng-container *ngIf="!loading; else spinner">
    <form (ngSubmit)="next()" [formGroup]="paymentForm">
      <div class="row">
        <div class="col-md-12 col-xl-10">
          <div class="form-group" formGroupName="cardType">
            <ng-container *ngIf="cardTypes$ | async as cardTypes">
              <div *ngIf="cardTypes.length !== 0">
                <label aria-required="true">
                  <span class="label-content required">{{
                    'paymentForm.paymentType' | cxTranslate
                  }}</span>
                  <ng-select
                    [searchable]="true"
                    [clearable]="false"
                    [items]="cardTypes"
                    bindLabel="name"
                    value="visa"
                    bindValue="code"
                    placeholder="{{ 'paymentForm.selectOne' | cxTranslate }}"
                    formControlName="code"
                  >
                  </ng-select>
                  <cx-form-errors
                    [control]="paymentForm.get('cardType.code')"
                  ></cx-form-errors>
                </label>
              </div>
            </ng-container>
          </div>
  
          <div class="form-group">
            <label>
              <span class="label-content">{{
                'paymentForm.accountHolderName.label' | cxTranslate
              }}</span>
              <input
                class="form-control"
                type="text"
                placeholder="{{
                  'paymentForm.accountHolderName.placeholder' | cxTranslate
                }}"
                formControlName="accountHolderName"
              />
              <cx-form-errors
                [control]="paymentForm.get('accountHolderName')"
              ></cx-form-errors>
            </label>
          </div>
  
          <div class="form-group">
            <label>
              <span class="label-content">{{
                'paymentForm.cardNumber' | cxTranslate
              }}</span>
              <input
                type="text"
                value="4548812049400004"
                class="form-control"
                formControlName="cardNumber"
              />
              <cx-form-errors
                [control]="paymentForm.get('cardNumber')"
              ></cx-form-errors>
            </label>
          </div>
  
          <div class="row">
            <div class="form-group col-md-8">
              <label>
                <span class="label-content">{{
                  'paymentForm.expirationDate' | cxTranslate
                }}</span>
                <div class="cx-payment-form-exp-date">
                  <div class="cx-payment-form-exp-date-wrapper">
                    <ng-select
                      [searchable]="true"
                      [clearable]="false"
                      [items]="months"
                      value="12"
                      placeholder="{{ 'paymentForm.monthMask' | cxTranslate }}"
                      formControlName="expiryMonth"
                    >
                    </ng-select>
                    <cx-form-errors
                      [control]="paymentForm.get('expiryMonth')"
                    ></cx-form-errors>
                  </div>
                  <div class="cx-payment-form-exp-date-wrapper">
                    <ng-select
                      [searchable]="true"
                      [clearable]="false"
                      [items]="years"
                      value="2030"
                      placeholder="{{ 'paymentForm.yearMask' | cxTranslate }}"
                      formControlName="expiryYear"
                    >
                    </ng-select>
                    <cx-form-errors
                      [control]="paymentForm.get('expiryYear')"
                    ></cx-form-errors>
                  </div>
                </div>
              </label>
            </div>
  
            <div class="form-group col-md-4">
              <label>
                <span class="label-content">
                  {{ 'paymentForm.securityCode' | cxTranslate }}
                  <cx-icon
                    [type]="iconTypes.INFO"
                    class="cx-payment-form-tooltip"
                    placement="right"
                    title="{{ 'paymentForm.securityCodeTitle' | cxTranslate }}"
                    alt=""
                  ></cx-icon>
                </span>
                <input
                  type="text"
                  class="form-control"
                  id="cVVNumber" value="123"
                  formControlName="cvn"
                />
                <cx-form-errors
                  [control]="paymentForm.get('cvn')"
                ></cx-form-errors>
              </label>
            </div>
          </div>
        
        </div>
      </div>
    
      <!-- BUTTON SECTION -->
      <div class="cx-checkout-btns row">
        <div class="col-md-12 col-lg-3">
          <button
            *ngIf="paymentMethodsCount === 0"
            class="btn btn-block btn-default"
            (click)="back()"
          >
            {{ 'common.back' | cxTranslate }}
          </button>
          <button
            *ngIf="paymentMethodsCount > 0"
            class="btn btn-block btn-default"
            (click)="close()"
          >
            {{ 'paymentForm.changePayment' | cxTranslate }}
          </button>
        </div>
        <div class="col-md-12 col-lg-4">
          <button class="btn btn-block btn-action" type="submit">
            {{ 'common.continue' | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </ng-container>
  
  <ng-template #spinner>
    <cx-spinner></cx-spinner>
  </ng-template>
  