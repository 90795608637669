import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthGuard,
  CmsConfig,
  FeaturesConfigModule,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import { CmsPageGuard, MediaModule, PageLayoutComponent } from '@spartacus/storefront';
import { CustomReturnRequestOverviewComponent } from './custom-return-request-overview/custom-return-request-overview.component';
import { CustomReturnRequestItemsComponent } from './custom-return-request-items/custom-return-request-items.component';
import { CustomReturnRequestTotalsComponent } from './custom-return-request-totals/custom-return-request-totals.component';

const components = [
  CustomReturnRequestOverviewComponent,
  CustomReturnRequestItemsComponent,
  CustomReturnRequestTotalsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'returnRequestDetails' },
      },
    ]),
    RouterModule,
    UrlModule,
    I18nModule,
    MediaModule,
    FeaturesConfigModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        ReturnRequestOverviewComponent: {
          component: CustomReturnRequestOverviewComponent,
        },
        ReturnRequestItemsComponent: {
          component: CustomReturnRequestItemsComponent,
        },
        ReturnRequestTotalsComponent: {
          component: CustomReturnRequestTotalsComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [...components],
  exports: [...components],
  entryComponents: [...components],
})
export class CustomReturnRequestDetailModule {}
