/*
 * Copyright (C) Seidor SA - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import { Component, Input, OnInit } from '@angular/core';
import { CustomPaypalInstallmentsService } from "./services/custom-paypal-installments.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-custom-paypal-installments',
  templateUrl: './custom-paypal-installments.component.html',
})
export class CustomPaypalInstallmentsComponent implements OnInit {

  @Input() installmentsAmount: number;
  showInstallments: boolean;
  thresholdPrice: number;
  src: string;

  constructor(
    protected paypalInstallments: CustomPaypalInstallmentsService,
  ) {}

  ngOnInit(): void {
    this.showInstallments = environment.paypal.id && environment.paypal.installments.active;
    this.thresholdPrice = environment.paypal.installments.threshold;
    if (this.showInstallments) {
      this.paypalInstallments.loadInstallments();
    }
  }

}
