<div class="cx-promotions" *ngIf="promotions">
  <ng-container *ngIf="promotions.length === 1">
    <p>{{ promotions[0].description }}</p>
  </ng-container>
  <ng-container *ngIf="promotions.length > 1">
    <ul>
      <li *ngFor="let promotion of promotions" [ngClass]="{'d-none': shouldHideDescription(promotion.description)}">
        {{ promotion.description }}
      </li>
    </ul>
  </ng-container>
</div>
