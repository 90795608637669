import {Component, Inject, OnInit} from '@angular/core';
import { CustomAgeStatusService } from './custom-age-status.service';
import {CmsService} from '@spartacus/core';
import { Observable } from 'rxjs';
import {CookieService} from "../../../config/services/cookie.service";

@Component({
  selector: 'app-custom-age-status',
  templateUrl: './custom-age-status.component.html',
})
export class CustomAgeStatusComponent implements OnInit {

  siteLogo$: Observable<any>;
  showDisabledPageMessage = false;
  constructor(
    protected customAgeStatusService: CustomAgeStatusService,
    protected cmsService: CmsService,
    private cookieService: CookieService
  ) {
  }

  ngOnInit(): void {
    this.siteLogo$ = this.cmsService.getComponentData('SiteLogoComponent');
    const ageStatusCookieValue = +this.cookieService.get('agestatus');
    if (!!ageStatusCookieValue && ageStatusCookieValue >= 0){
      this.customAgeStatusService.updateAgeStatus(ageStatusCookieValue);
    }
  }

  overEighteen(): void {
    this.customAgeStatusService.updateAgeStatus(1);
  }

  denyAccess(): void {
    this.customAgeStatusService.updateAgeStatus(0);
    this.showDisabledPageMessage = true;
  }
}
