import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { ErrorModel, GlobalMessageType, HttpErrorModel } from '@spartacus/core';

@Injectable()
export class CustomResetPasswordComponentService extends ResetPasswordComponentService {
  form: FormGroup = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        CustomValidators.passwordValidator,
      ]),
      passwordConfirm: new FormControl('', Validators.required),
    },
    {
      validators: CustomValidators.passwordsMustMatch(
        'password',
        'passwordConfirm',
      ),
    },
  );

  protected onError(error: Error): void {
    this.busy$.next(false);
    ((error as HttpErrorModel)?.details ?? []).forEach((err: ErrorModel) => {
      if (err.type) {
        this.globalMessage.add(
          { key: `forgottenPassword.${ err.type }` },
          GlobalMessageType.MSG_TYPE_ERROR,
        );
      }
    });
  }

}
