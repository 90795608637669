import { Cart } from '@spartacus/cart/base/root';
import { OrderHistoryList } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { OrdersFilter } from 'src/app/model/filters.model';

export abstract class CustomOrderActionsAdapter {

  abstract orderCancellation({userId, orderCode}): Observable<any>;

  abstract orderRestoration({userId, orderCode}): Observable<Cart>;

  abstract orderRestoreOnly({userId, orderCode}): Observable<Cart>;

  abstract loadHistoryFilter(userId: string,
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    ordersFilter?: OrdersFilter): Observable<OrderHistoryList>;

}
