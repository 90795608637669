export enum ProductReferenceTypeEnum {
  REQUIRED_FOR_GIFT_BOX = 'REQUIRED_FOR_GIFT_BOX',
  BOX_FOR_GIFT_BOX = 'BOX_FOR_GIFT_BOX',
}

export interface ProductLabel {
  backgroundColor: string;
  fontColor: string;
  text: string;
}
