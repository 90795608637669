
<ng-container *ngIf="data$ | async as data">
    <ng-container *ngIf="data.title">
        <div class="title">
          {{data.title}}
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="items$ | async as components">
    <div class="info-wrapper container">
        <ng-container *ngFor="let component of components; let i = index">
            <ng-container *ngIf="component">
                <div [class]="component.styleClasses">
                    <app-info-item [item]="component"></app-info-item>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
