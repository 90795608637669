import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { CustomCartActions } from '../store/actions';
import { EntryGroup } from '../../../../../model/entry-groups.model';
import { CustomMultiCartSelectors } from '../store/selectors';
import { User } from '@spartacus/user/account/root';
import { CartActions, MultiCartSelectors, MultiCartService, StateWithMultiCart } from '@spartacus/cart/base/core';
import { CustomOrderEntry } from '../../../feature-libs/cart/root/models/cart.model';
import { Command, CommandService, CommandStrategy, UserIdService } from '@spartacus/core';
import { CustomCartEntryConnector } from '../conectors/entry/custom-cart-entry.connector';
import { CustomCartConnector } from '../conectors/cart/custom-cart.connector';

@Injectable({
  providedIn: 'root',
})
export class CustomMultiCartService extends MultiCartService {

  constructor(
    protected store: Store<StateWithMultiCart>,
    protected userIdService: UserIdService,
    protected commandService: CommandService,
    protected cartEntryConnector: CustomCartEntryConnector,
    private customCartConnector: CustomCartConnector
  ) {
    super(store, userIdService);
  }

  protected addEntryUnitCommand: Command<
    { cartId: string, productCode: string, quantity: number,  unitCode: string},
    unknown
  > = this.commandService.create<{
    cartId: string,
    productCode: string,
    quantity: number,
    unitCode: string
    }>(
    ({ cartId, productCode, quantity,  unitCode }) =>
     this.cartPreconditions().pipe(
        switchMap(([userId]) =>
        this.cartEntryConnector
          .addUnit(
            userId,
            cartId,
            productCode,
            quantity,
            unitCode,
          )
        )
      ),
    {
      strategy: CommandStrategy.CancelPrevious,
    }
  );

  protected cartPreconditions(): Observable<[string]> {
    return combineLatest([
      this.userIdService.takeUserId(),
    ]).pipe(
      take(1),
      map(([userId]) => {
        return [userId];
      })
    );
  }

  /**
   * Add entry to cart with added unit
   *
   * @param cartId Cart ID
   * @param productCode Product code
   * @param quantity Quantity
   * @param unitCode Unit code
   */
  addEntryUnit(
    cartId: string,
    userId: string,
    productCode: string,
    quantity: number,
    unitCode: string,
  ): void {
    this.store.dispatch(
      new CustomCartActions.CartAddEntryUnit({
        cartId,
        userId,
        productCode,
        quantity,
        unitCode,
      }),
    );
  }

  /**
   * Get last entry for specific product code and unit code from cart.
   * Needed to cover processes where multiple entries can share the same product code
   * (e.g. promotions or configurable products)
   *
   * @param cartId Cart ID
   * @param productCode Product code
   * @param unitCode Unit code
   */
  getLastEntryUnit(
    cartId: string,
    productCode: string,
    unitCode: string,
  ): Observable<CustomOrderEntry | null> {

    let filteredEntries;
    this.store.pipe(
      select(MultiCartSelectors.getCartEntriesSelectorFactory(cartId)),
      map((entries) => {
        filteredEntries = entries.filter(
          (entry: CustomOrderEntry) => entry.product.code === productCode && entry.unit?.code === unitCode,
        );
        return filteredEntries
          ? filteredEntries[filteredEntries.length - 1]
          : undefined;
      }),
    ).subscribe();

    return filteredEntries;
  }

  /**
   * Assign extra data to the cart
   *
   * @param cartId cart ID
   * @param userId User ID
   * @param extraData cart Extra data
   */
  assignExtraData(cartId: string, userId: string, extraData: {}): void {
    this.store.dispatch(
      new CustomCartActions.AddExtraDataToCart({
        userId,
        cartId,
        extraData,
      }),
    );
  }

  /**
   * Set document identifier to customer
   *
   * @param cartId cart ID
   * @param userId User ID
   * @param user User data
   */
  assignDocumentIdentifier(cartId: string, userId: string, user: User): Observable<any> {
    return this.customCartConnector.setDocumentIdentifier(userId, cartId, user);
  }

  /**
   * Get cart group entries as an observable
   * @param cartId cart ID
   */
  getEntryGroups(cartId: string): Observable<EntryGroup[]> {
    return this.store.pipe(
      select(CustomMultiCartSelectors.getCartEntryGroupsSelectorFactory(cartId)),
    );
  }

  /**
   * Set active cart Id to empty
   */
   setActiveCartIdEmpty(): void {
    this.setActiveCartId('');
  }

  /**
   * Set active cart Id
   */
   setActiveCartId(cartId: string): void {
    this.store.dispatch(new CartActions.SetActiveCartId(cartId));
  }

  /**
   * Add entry to cart with added unit
   *
   * @param userId User ID
   * @param cartId Cart ID
   * @param productCode Product code
   */
   addGiftbox(
    userId: string,
    cartId: string,
    giftBoxProductCode: string,
  ): void {
    this.store.dispatch(
      new CustomCartActions.AddGiftboxToCart({
        userId,
        cartId,
        giftBoxProductCode,
      }),
    );
  }

  /**
   * Remove cart
   *
   * @param cartId
   */
  removeCart(cartId: string): void {
    this.store.dispatch(
      new CartActions.RemoveCart({
        cartId,
      })
    );
  }

  validateDocIdentifierAndTotalAmount(cartId: string, userId: string, user: User): Observable<any> {
    return this.customCartConnector.validateDocIdentifierAndTotalAmount(userId, cartId, user);
  }
}
