import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { CustomNewsCategoriesModel } from '../custom-news.model';
import { CustomNewsState, NEWS_FEATURE, StateWithNews } from '../custom-news.state';

export const getNewsCategoriesState: MemoizedSelector<StateWithNews, CustomNewsState> =
  createFeatureSelector<CustomNewsState>(NEWS_FEATURE);

export const getNewsCategoriesLoaderState: MemoizedSelector<StateWithNews, LoaderState<CustomNewsCategoriesModel>> =
  createSelector(getNewsCategoriesState, (state) => state.categories);

export const getNewsCategories: MemoizedSelector<StateWithNews, CustomNewsCategoriesModel> =
  createSelector(getNewsCategoriesLoaderState, (state) => StateUtils.loaderValueSelector(state));

export const getNewsCategoriesLoading: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsCategoriesLoaderState, (state) => StateUtils.loaderLoadingSelector(state));

export const getNewsCategoriesError: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsCategoriesLoaderState, (state) => StateUtils.loaderErrorSelector(state));

export const getNewsCategoriesSuccess: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsCategoriesLoaderState, (state) => StateUtils.loaderSuccessSelector(state));
