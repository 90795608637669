<ng-container *ngIf="data$ | async as data">
  <div class="container">
    <ng-container *ngIf="data?.title">
      <h3 [innerHTML]="data.title" class="title"></h3>
    </ng-container>
    <ng-container *ngIf="data?.description?.content">
      <cx-paragraph
        [componentData]="data.description"
      ></cx-paragraph>
    </ng-container>

    <ng-container *ngIf="data?.columnInfo">
      <ng-container *ngIf="columnInfoList$ | async as columnInfo">
        <ng-container *ngIf="columnInfo?.length">
          <div class="content">
            <div class="row">
              <ng-container *ngFor="let column of columnInfo">
                <ng-container *ngIf="column | async as expandableColumn">
                  <div [class]="'mb-4 col-12 col-md-'+columns.md+' col-lg-'+ columns.lg">
                    <app-custom-paragraph-column-with-expandable-info
                      [column]="expandableColumn">
                    </app-custom-paragraph-column-with-expandable-info>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
