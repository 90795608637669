<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <ng-container *ngFor="let entry of entries">
      <div
        *ngIf="entry.mandatory === ProductReferenceTypeEnum.BOX_FOR_GIFT_BOX"
        class="giftbox-cart-header"
      >
        <cx-media
          [container]="entry.product?.images?.PRIMARY ? entry.product?.images?.PRIMARY : entry.product?.images"
          format="product"
        ></cx-media>
        <div class="giftbox-cart-item-info">
          <span class="giftbox-cart-item-info-name">
            {{ entry.product.name }}
          </span>
          <span>
            {{ 'giftbox.cartTooltip.price' | cxTranslate }}: {{ entry.basePrice.formattedValue}}
          </span>
        </div>
      </div>
    </ng-container>
    <div class="giftbox-cart-items">
      <ng-container *ngFor="let entry of entries">
        <ng-container *ngIf="entry.mandatory !== ProductReferenceTypeEnum.BOX_FOR_GIFT_BOX"> 
          <ng-container *ngFor='let in of counter(entry.quantity); let i = index'>         
            <div
              class="giftbox-cart-item"
              [class.animate]="itemsAdded"
              [attr.style]="entry.numberOfSlots > 1 ? 'grid-column: span ' + entry.numberOfSlots : ''"
            >
              <div class="giftbox-cart-item-image">
                <cx-media
                  [container]="entry.product?.images?.PRIMARY ? entry.product?.images?.PRIMARY : entry.product?.images"
                  format="product"
                ></cx-media>
              </div>
              <button 
                *ngIf="entry.mandatory !== ProductReferenceTypeEnum.REQUIRED_FOR_GIFT_BOX || i >= entry.requiredQuantity"
                class="btn btn-icon"
                [disabled]="isRemovingItem"
                (click)="removeItem(entry.entryNumber, entry.quantity)"
              >
                <i class="icon-trash"></i>
              </button>
              <div class="giftbox-cart-item-info">
                <span class="giftbox-cart-item-info-name">
                  {{ entry.product.name }}
                </span>
                <span>
                  {{ 'giftbox.cartTooltip.price' | cxTranslate }}: {{ entry.basePrice.formattedValue}}
                </span>
              </div>
            </div>
          </ng-container> 
        </ng-container>
      </ng-container>
      <ng-container *ngFor='let in of counter(cart.maxNumberOfSlots - cart.filledNumberOfSlots); let i = index'>
        <div class="giftbox-cart-item empty">
          <div class="giftbox-cart-item-image">
              <div class="giftbox-cart-item-no-media"></div>
          </div>
          <div class="giftbox-cart-item-empty">
            {{cart.filledNumberOfSlots + i + 1 }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
