import { PaginationModel } from '@spartacus/core';
import { CustomFriendsCheckoutState } from '../custom-friends-checkout/store/custom-friends-checkout.state';
import { Friend } from '../custom-friends-users/facade/friends.model';

export const FRIENDS_FEATURE = 'friends';
export const FRIENDS = '[Friends] Friends';

export interface CustomFriendsState {
  checkout: CustomFriendsCheckoutState;
  user: CustomFriendsUsersState;
}

export interface StateWithFriends {
  [FRIENDS_FEATURE]: CustomFriendsState;
}

export interface CustomFriendsUsersState {
    members: Friend[];
    pagination?: PaginationModel;
    selected: Friend;
    status: boolean;
}
