<div class="register">
  <!-- <div class="register-reset-password">
    <a
      [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"
      aria-controls="reset-password"
      class="btn-link"
    >
      {{ (showExtendedLoginInfo ? 'loginForm.forgotPasswordLong' : 'loginForm.forgotPassword') | cxTranslate }}
    </a>
  </div> -->
  <div
    *ngIf="isEnabledCustomerRegister"
    class="register-new"
  >
    <div *ngIf="showExtendedLoginInfo">
      <h2>
        {{ 'loginForm.registerTitle' | cxTranslate }}
      </h2>
      <p>
        {{ 'loginForm.registerInfo' | cxTranslate }}
      </p>
    </div>
    <a
      [routerLink]="{ cxRoute: 'register' } | cxUrl"
    >
      {{ (showExtendedLoginInfo ? 'loginForm.registerLong' : 'loginForm.register') | cxTranslate }}
    </a>
  </div>
  <div
    *ngIf="loginAsGuest"
    class="register-guest-checkout"
  >
    <cx-checkout-login></cx-checkout-login>
    <!-- <a
      [routerLink]="{ cxRoute: 'checkoutLogin' } | cxUrl"
    >
      {{ 'loginForm.guestCheckout' | cxTranslate }}
    </a> -->
  </div>
</div>
