import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OCC_USER_ID_ANONYMOUS, RoutingService, StateWithProcess, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BillHistoryList, BillsFilter } from 'src/app/model/bill.model';
import { UserBillsActions } from '../store/actions';
import { UsersBillsSelectors } from '../store/selector';
import { StateWithBills } from '../store/selector/custom-user-bills.selectors';

@Injectable({
  providedIn: 'root',
})
export class CustomUserBillService {
  constructor(
    protected store: Store<StateWithBills | StateWithProcess<void>>,
    protected userIdService: UserIdService,
    protected routingService: RoutingService
  ) { }

  /**
   * Returns bill history list
   */
  getBillHistoryList(pageSize: number): Observable<BillHistoryList> {
    return this.store.pipe(
      select(UsersBillsSelectors.getBillsLoaderState),
      tap((billListState) => {
        const attemptedLoad =
          billListState.loading ||
          billListState.success ||
          billListState.error;
        if (!attemptedLoad) {
          this.loadBillList(pageSize);
        }
      }),
      map((billListState) => billListState.value)
    );
  }

  /**
   * Returns a loaded flag for bill history list
   */
  getBillHistoryListLoaded(): Observable<boolean> {
    return this.store.pipe(select(UsersBillsSelectors.getBillsLoaded));
  }

  /**
   * Retrieves a bill list
   * @param pageSize page size
   * @param currentPage current page
   * @param sort sort
   * @param billsFilter bills filters
   */
  loadBillList(pageSize: number, currentPage?: number, sort?: string, billsFilter?: BillsFilter): void {
    
    this.userIdService
        .takeUserId()
        .subscribe((userId) => {
          if (userId !== OCC_USER_ID_ANONYMOUS) {
            this.store.dispatch(
              new UserBillsActions.LoadUserBills({
                userId,
                pageSize,
                currentPage,
                sort,
                billsFilter,
              })
            );
          }
        })
        .unsubscribe();
  }

  /**
   * Cleaning bill list
   */
  clearBillList(): void {
    this.store.dispatch(new UserBillsActions.ClearUserBills());
  }
  // check CustomUserOrderService
}
