import { DIALOG_TYPE, LayoutConfig, SpinnerComponent } from "@spartacus/storefront";


export const defaultSimulateCartSpinnerLayoutConfig: LayoutConfig = {
  launch: {
    SIMULATE_CART_SPINNER: {
      inline: true,
      component: SpinnerComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
    },
  },
};

