import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomParagraphColumnInfoComponent } from './custom-paragraph-column-info.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import {
  CustomParagraphColumnWithExpandableInfoModule
} from '../custom-paragraph-column-with-expandable-info/custom-paragraph-column-with-expandable-info.module';
import { CustomParagraphModule } from '../../../../spartacus/custom/cms-components/content/custom-paragraph/custom-paragraph.module';


@NgModule({
  declarations: [CustomParagraphColumnInfoComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomParagraphColumnsInfoComponent: {
          component: CustomParagraphColumnInfoComponent,
        },
      },
    } as CmsConfig),
    CustomParagraphColumnWithExpandableInfoModule,
    CustomParagraphModule,
  ],
  exports: [CustomParagraphColumnInfoComponent],
  entryComponents: [CustomParagraphColumnInfoComponent]
})
export class CustomParagraphColumnInfoModule {}
