import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutConfigService } from '@spartacus/checkout/base/components';
import { AuthService, GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Order } from '@spartacus/order/root';
import { UserRegisterFacade } from '@spartacus/user/profile/root';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';

@Component({
  selector: 'cx-guest-register-form',
  templateUrl: './custom-guest-register-form.component.html',
})
export class CustomGuestRegisterFormComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  isGuestCustomer: boolean = false;
  guid: string;
  email: string;

  subscription: Subscription;
  guestRegisterForm: FormGroup = this.fb.group(
    {
      password: [
        '',
        [Validators.required, CustomValidators.passwordValidator],
      ],
      passwordconf: ['', Validators.required],
    },
    {
      validators: CustomValidators.passwordsMustMatch(
        'password',
        'passwordconf'
      ),
    }
  );

  constructor(
    protected globalMessageService: GlobalMessageService,
    protected userRegisterFacade: UserRegisterFacade,
    protected routingService: RoutingService,
    private checkoutConfigService: CheckoutConfigService,
    protected orderDetailsService: OrderDetailsService,
    protected authService: AuthService,
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.guid) {
      this.order$ = this.orderDetailsService.getOrderDetails().pipe(
        tap((order) => {
          if (order && Object.keys(order).length) {
            this.initOrderInfo(order);
          }
        })
      );
    }
  }

  submit(): void {
    if (this.guestRegisterForm.valid) {
      this.userRegisterFacade.registerGuest(
        this.guid,
        this.guestRegisterForm.value.password).subscribe(()=>{
          this.globalMessageService.add(
            { key: 'checkoutOrderConfirmation.createAccountSuccess' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
          this.isGuestCustomer = false;
          this.cd.detectChanges();
        }, (error) => {
          this.globalMessageService.add(
            error?.errors[0]?.message,
            GlobalMessageType.MSG_TYPE_ERROR
          );
        });

    } else {
      this.guestRegisterForm.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private initOrderInfo(order: Order): void {
    this.isGuestCustomer = this.checkoutConfigService.isGuestCheckout() && order.guestCustomer;
    this.guid = order.guid;
    this.email = order.user?.uid?.split('|')[1] ?? '';
  }
}
