import { Observable } from 'rxjs';
import {
  CustomNewsCategoriesModel,
  CustomNewsDetailsModel,
  CustomNewsListModel,
  CustomNewsPaginationParams,
} from '../store/custom-news.model';

export abstract class CustomNewsAdapter {
  /**
   * Abstract method used to load News list.
   * @param pageSize Page size
   * @param currentPage Current page
   * @param sort Sorting method
   * @param newsFilter news filters
   */
  abstract loadNewsList(params: CustomNewsPaginationParams): Observable<CustomNewsListModel>;

  abstract loadNewsDetails(
    newsId: string,
  ): Observable<CustomNewsDetailsModel>;

  abstract loadNewsCategories(): Observable<CustomNewsCategoriesModel>;
}
