import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['../../../../../styles/custom/components/content/custom-modal-component/custom-modal.component.scss'],
})
export class CustomModalComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() styleClasses: string;
  @Input() showButtonClose: boolean;
  @Input() showButtonCancel: boolean;
  @Input() showButtonAccept: boolean;
  @HostBinding('class') style = '';
  iconTypes = ICON_TYPE;

  constructor(
    public modalService: LaunchDialogService,
  ) { }

  ngOnInit(): void {
    this.style = this.styleClasses;
  }

  dismissModal(reason?: any): void {
    this.modalService.closeDialog(reason);
  }

}
