import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { CustomUserOrderService } from 'src/app/spartacus/custom/core/user/facade/custom-user-order.service';
import {CustomCheckoutPaymentService} from "../../../../core/checkout/facade/custom-checkout-payment.service";
import { CheckoutConfigService } from '@spartacus/checkout/base/components';
import { ORDER_TYPE, Order, OrderFacade } from '@spartacus/order/root';
import { OrderDetailsService } from '@spartacus/order/components';
import { CheckoutReplenishmentFormService } from '@spartacus/checkout/scheduled-replenishment/components';

@Component({
  selector: 'cx-order-confirmation-thank-you-message',
  templateUrl: './custom-order-confirmation-thank-you-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderConfirmationThankYouMessageComponent
  implements OnInit, OnDestroy {
  order$: Observable<any>;
  displayOrderbyCode: boolean;
  isReplenishmentOrderType$: Observable<boolean>;
  isGuestCustomer: boolean = false;
  orderCode$: any;

  socialMediaUrls = {
    facebook: 'https://www.facebook.com/OsborneExperience?ref=ts&fref=ts',
    instagram: 'https://www.instagram.com/osborne_oficial',
    twitter: 'https://twitter.com/osborne_es',
    youtube: 'https://www.youtube.com/user/SalaPrensaOsborne',
    linkedin: 'https://es.linkedin.com/company/grupo-osborne',
  };

  constructor(
    protected orderFacade: OrderFacade,
    protected checkoutService: CustomCheckoutPaymentService,
    private userOrderService: CustomUserOrderService,
    private checkoutConfigService: CheckoutConfigService,
    protected checkoutReplenishmentFormService: CheckoutReplenishmentFormService,
    protected orderDetailsService: OrderDetailsService,
    protected routingService: RoutingService
  ) {
    this.orderCode$ = this.routingService.getRouterState().pipe(
      map((routingData) => {
        this.displayOrderbyCode = true;
        return routingData.state.params.orderCode
      }),
      distinctUntilChanged()
    );
  }

  ngOnInit(): void {
    if (this.displayOrderbyCode) {
      combineLatest([
        this.orderFacade.getOrderDetails(),
        this.userOrderService.getOrderDetailsLoading(),
      ]).pipe(
        filter(([_, loading]) => !loading),
        tap(([order]) => {
          if (order && Object.keys(order).length) {
            this.initOrderInfo(order);
            this.checkoutService.setPlaceOrderSuccess(order);
          } else {
            this.routingService.go({ cxRoute: 'orders' });
          }
        }),
        map(([order]) => order)
      ).subscribe();
    } else {
      this.order$ = this.orderDetailsService.getOrderDetails().pipe(
        tap((order) => {
          if (order && Object.keys(order).length) {
            this.initOrderInfo(order);
          }
        })
      );
    }

    this.isReplenishmentOrderType$ = this.checkoutReplenishmentFormService
      .getOrderType()
      .pipe(
        map(
          (orderType) => ORDER_TYPE.SCHEDULE_REPLENISHMENT_ORDER === orderType
        )
      );
  }

  private initOrderInfo(order: Order): void {
    this.isGuestCustomer = this.checkoutConfigService.isGuestCheckout() && order.guestCustomer;
  }

  getSocialMediaUrl(socialMedia: string): string {
    return this.socialMediaUrls[socialMedia];
  }

  get socialMediaBtnClass(): string {
    return 'btn btn-icon btn-icon-fade';
  }

  get socialMediaBtnTarget(): string {
    return '_blank';
  }

  ngOnDestroy(): void {
    if (this.displayOrderbyCode) {
      this.userOrderService.clearOrderDetails();
    } else {
      this.checkoutService.clearCheckoutData();
    }
  }
}
