import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { CustomNewsListModel } from '../custom-news.model';
import { CustomNewsState, NEWS_FEATURE, StateWithNews } from '../custom-news.state';

export const getNewsListState: MemoizedSelector<StateWithNews, CustomNewsState> =
  createFeatureSelector<CustomNewsState>(NEWS_FEATURE);

export const getNewsListLoaderState: MemoizedSelector<StateWithNews, LoaderState<CustomNewsListModel>> =
  createSelector(getNewsListState, (state) => state.list);

export const getNewsList: MemoizedSelector<StateWithNews, CustomNewsListModel> =
  createSelector(getNewsListLoaderState, (state) => StateUtils.loaderValueSelector(state));

export const getNewsListLoading: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsListLoaderState, (state) => StateUtils.loaderLoadingSelector(state));

export const getNewsListError: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsListLoaderState, (state) => StateUtils.loaderErrorSelector(state));

export const getNewsListSuccess: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsListLoaderState, (state) => StateUtils.loaderSuccessSelector(state));
