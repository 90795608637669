<form
  (ngSubmit)="submitForm()"
  class="cx-place-order-form form-check"
  [formGroup]="checkoutSubmitForm"
>
  <div class="form-group">
    <label>
      <input
        id="termsAndConditions"
        formControlName="termsAndConditions"
        class="scaled-input form-check-input"
        type="checkbox"
        (click)='disableInputs()'

      />
      <span class="form-check-label">
        {{ 'checkoutReview.confirmThatRead' | cxTranslate }}
        <a
          [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
          class="cx-tc-link"
          target="_blank"
        >
          {{ 'checkoutReview.termsAndConditions' | cxTranslate }}
        </a>
      </span>
    </label>
  </div>
  <div class='row cx-checkout-btns place-order-button'>

    <ng-container *ngIf="(isPayPal$ | async) === true">
      <div class='col-md-12 col-lg-5 order-button'>
        <app-custom-paypal-button id='custom-paypal-custom' [class.disabled]="paypalButtonDisabled || (daysOfWeekNotChecked$ | async) || isDisabled"></app-custom-paypal-button>
      </div>

    </ng-container>
    <div *ngIf="!isPaypalPaymentSelected" class='col-md-12 col-lg-4 order-button'>
      <button *ngIf="!isPaypalPaymentSelected"
              type="submit"
              class="btn btn-action credit-button"
              (click)='next()'
              [disabled]="termsAndConditionInvalid || (daysOfWeekNotChecked$ | async) || isDisabled"
      >
        {{ 'checkoutReview.placeOrder' | cxTranslate }}
      </button>
    </div>

  </div>
</form>
