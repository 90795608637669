<ng-container *ngIf="data$ | async as data">
    <ng-template #genericLink>
        <cx-generic-link *ngIf="data.url && data.url.length" [url]="data.url" [target]="getTarget(data.target)">
          <h3>
            {{ data.linkName }}
          </h3>
        </cx-generic-link>
    </ng-template>
    <cx-media [container]="data.media"></cx-media>

    <ng-container *ngIf="!data.modalContent?.content; else modalContent">
      <ng-container *ngIf="data.description; else linkOnly">
        <div class="wrapper-link">
          <ng-container *ngTemplateOutlet="genericLink; context: data"></ng-container>
        </div>
        <ng-container *ngIf="data.description">
          <div class="wrapper-text">
            <p>{{data.description}}</p>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #linkOnly>
        <ng-container *ngTemplateOutlet="genericLink; context: data"></ng-container>
      </ng-template>
    </ng-container>

  <ng-template #modalContent>
    <button (click)="openModal()">
      <h3>
        {{ data.linkName }}
      </h3>
    </button>
  </ng-template>
</ng-container>
