import { StateUtils } from '@spartacus/core';
import { CustomNewsDetailsModel, CustomNewsListModel, NewsFilter } from '../custom-news.model';
export const NEWS_SLIDER = '[News] News Slider';
export const LOAD_NEWS_SLIDER = '[News] Load News Slider';
export const LOAD_NEWS_SLIDER_SUCCESS = '[News] Load News Slider Success';
export const LOAD_NEWS_SLIDER_FAIL = '[News] Load News Slider Fail';
export const CLEAR_NEWS_SLIDER = '[News] Load News Slider Fail';

export class LoadNewsSlider extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_NEWS_SLIDER;

  constructor(public payload: {
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    newsFilter?: NewsFilter
  }) {
    super(NEWS_SLIDER);
  }
}

export class LoadNewsSliderSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_NEWS_SLIDER_SUCCESS;

  constructor(public payload: CustomNewsListModel) {
    super(NEWS_SLIDER);
  }
}

export class LoadNewsSliderFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_NEWS_SLIDER_FAIL;

  constructor(public payload: any) {
    super(NEWS_SLIDER, payload);
  }
}

export class ClearNewsSlider extends StateUtils.LoaderResetAction {
  readonly type = CLEAR_NEWS_SLIDER;

  constructor() {
    super(NEWS_SLIDER);
  }
}
export type CustomNewsSliderActions =
  | LoadNewsSlider
  | LoadNewsSliderSuccess
  | LoadNewsSliderFail
  | ClearNewsSlider;
