import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CardModule, FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { CustomIconsModule } from '../../../../misc/icon/custom-icons.module';
import { CustomPlaceOrderModule } from '../../custom-place-order/custom-place-order.module';
import { CustomCheckoutPaymentTypeComponent } from './custom-payment-type.component';
import { CartCouponModule } from '@spartacus/cart/base/components';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { OccCheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/occ';

@NgModule({
  declarations: [CustomCheckoutPaymentTypeComponent],
  exports: [CustomCheckoutPaymentTypeComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    UrlModule,
    IconModule,
    FormErrorsModule,
    BrowserAnimationsModule,
    CartCouponModule,
    CustomPlaceOrderModule,
    CustomIconsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutPaymentType: {
          component: CustomCheckoutPaymentTypeComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig),
  ],
  providers: [
    OccCheckoutPaymentTypeAdapter
  ]
})
export class CustomPaymentTypeModule { }
