import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Bill, BillHistoryList, BillsFilter } from 'src/app/model/bill.model';
import { CustomUserBillAdapter } from './custom-user-bill.adapter';

@Injectable({
  providedIn: 'root',
})
export class CustomUserBillConnector {
  constructor(protected adapter: CustomUserBillAdapter) { }

  public get(userId: string, invoiceCode: string): Observable<Bill> {
    return this.adapter.load(userId, invoiceCode);
  }

  public getHistory(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    billsFilter?: BillsFilter,
  ): Observable<BillHistoryList> {
    return this.adapter.loadHistory(userId, pageSize, currentPage, sort, billsFilter);
  }
}
