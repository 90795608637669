import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TOKEN_STATISTICS } from './store/selectors/custom-statistics-selectors';
import { EffectsModule } from '@ngrx/effects';
import { reducerProviderStatistics, reducerTokenStatistics } from './store/reducers/custom-statistics-reducers';
import { CustomStatisticsEffects } from './store/effects/custom-statistics.effects';
import { CustomStatisticsComponent } from './custom-statistics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CustomStatisticsComponent],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomStatisticsComponent: {
          component: CustomStatisticsComponent,
        },
      },
    } as CmsConfig),
    StoreModule.forFeature(TOKEN_STATISTICS, reducerTokenStatistics),
    EffectsModule.forFeature([CustomStatisticsEffects]),
    NgxChartsModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [CustomStatisticsComponent],
  providers: [reducerProviderStatistics],
})
export class CustomStatisticsModule {}
