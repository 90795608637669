import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomGiftCardAdapter } from './custom-gift-card.adapters';
import { GiftCard } from '../model/custom-gift-card.model';


@Injectable({
  providedIn: 'root',
})
export class CustomGiftCardConnector {
  constructor(protected adapter: CustomGiftCardAdapter) {}

  getAll(userId: string, cartId: string): Observable<GiftCard[]> {
    return this.adapter.loadAll(userId, cartId);
  }

  set(
    userId: string,
    cartId: string,
    giftCardCode: string, 
    giftCardsMessage:string
  ): Observable<GiftCard> {
      return this.adapter.set(userId, cartId, giftCardCode,giftCardsMessage);
  }

  get(userId: string, cartId: string): Observable<GiftCard> {
    return this.adapter.load(userId, cartId);
  }

  delete(userId: string, cartId: string): Observable<{}>{
    return this.adapter.delete(userId, cartId);
  }

}
