import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CsTicketCauseList, CsTicketContactForm } from 'src/app/model/contact-form.model';
import { CustomContactFormAdapter } from './custom-contact-form.adapter';

@Injectable({
  providedIn: 'root',
})
export class CustomContactFormConnector {
  constructor(protected adapter: CustomContactFormAdapter) { }

  public loadCsTicketCauses(
    templateConfigurations: string,
  ): Observable<CsTicketCauseList> {
    return this.adapter.loadCsTicketCauses(templateConfigurations);
  }

  public sendContactForm(
    form: CsTicketContactForm,
    templateConfiguration: string,
  ): Observable<any> {
    return this.adapter.sendContactForm(form, templateConfiguration);
  }
}
