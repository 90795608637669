import { Injectable } from '@angular/core';
import { Product, ProductService } from '@spartacus/core';
import { prettyfyTextForUrl } from '../../../../../custom/util/filter-text';

@Injectable({
  providedIn: 'root',
})
export class CustomProductService extends ProductService {
  getSlugByLang(product: Product, language: string): string {
    return prettyfyTextForUrl(product?.names?.entry?.filter((entry) => entry.key === language)[0]?.value) ?? '';
  }
}
