<div class="cx-order-items container" *ngIf="order$ | async as order">
  <h4 class="cx-order-items-header">
    {{ 'checkoutOrderConfirmation.orderItems' | cxTranslate }}
  </h4>

  <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
    <!-- <cx-promotions class="cx-promotions-applied" [promotions]="orderPromotions"></cx-promotions> -->
    <app-custom-promotions class="cx-promotions-applied" [promotions]="orderPromotions"></app-custom-promotions>
  </ng-container>

  <ng-container *ngIf="order.entryGroups?.length">
    <cx-order-consigned-entries [order]="order" [entryGroups]="order.entryGroups"></cx-order-consigned-entries>
  </ng-container>
</div>


