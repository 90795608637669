import { CustomBaseStoreAdapter } from './custom-base-store.adapter';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomOcc } from '../../../../../spartacus/custom/core/occ/occ-models/custom-occ.models';
import { CustomBaseStoreModel } from '../custom-base-store.model';

@Injectable({
  providedIn: 'root',
})

export class CustomBaseStoreConnector {
  constructor(protected adapter: CustomBaseStoreAdapter) { }

  getAll({ userId }): Observable<CustomOcc.CustomBaseStoreOCCModel> {
    return this.adapter.loadAll({ userId });
  }

  get(baseStoreId: string): Observable<CustomBaseStoreModel> {
    return this.adapter.load(baseStoreId);
  }
}
