import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductSummaryComponent } from './custom-product-summary.component';
import { OutletModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomProductSummaryComponent],
  imports: [
    CommonModule,
    OutletModule,
    I18nModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductSummaryComponent: {
          component: CustomProductSummaryComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomProductSummaryModule { }
