<ng-container *ngIf="(data$ | async) as data">
  <div class="container">
    <ng-container *ngIf="data?.title">
      <h3 [innerHTML]="data.title" class="title"></h3>
    </ng-container>
    <ng-container *ngIf="data?.description?.content">
      <cx-paragraph
       [componentData]="data.description"
      >
      </cx-paragraph>
    </ng-container>
    <ng-container *ngIf="data?.expandableContent?.content">
      <div class="d-block">
        <button
          class="btn btn-link btn-read-more"
          (click)="toggleExpandableInfo()"
        >
          {{"common.readMore" | cxTranslate}}
          <cx-icon [type]="!showExpandableInfo ? iconType.CARET_DOWN : iconType.CARET_UP"></cx-icon>
        </button>
      </div>
      <ng-container *ngIf="showExpandableInfo">
        <cx-paragraph
          @fadeIn
          @fadeOut
          class="expandable-info"
          [componentData]="data.expandableContent"
        ></cx-paragraph>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
