import { Injectable } from '@angular/core';
import { CategoryPageMetaResolver, CmsService, ProductSearchPage, ProductSearchService, TranslationService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { CustomBasePageMetaResolver } from '../../cms/page/custom-base-page-meta.resolver';
import { PageHreflangResolver } from '../../cms/page/custom-page.resolvers';
import { HrefLangMeta } from '../../cms/page/model/custom-page.model';
import { BreadcrumbMeta } from '@spartacus/core/src/cms/model/page.model';
import { map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

/**
 * Resolves the page data for the Product Listing Page.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class CustomCategoryPageMetaResolver extends CategoryPageMetaResolver implements PageHreflangResolver {

  constructor(
    protected productSearchService: ProductSearchService,
    protected cmsService: CmsService,
    protected translation: TranslationService,
    protected basePageMetaResolver: CustomBasePageMetaResolver,
  ) {
    super(productSearchService, cmsService, translation, basePageMetaResolver);
  }

  /**
   * Resolves the page title for the Product Listing Page. If categoryMetaTitle is not provided then
   * the page title is resolved with total results and first breadcrumb facet value name.
   * The page title used by the browser (history, tabs) and crawlers.
   */
  resolveTitle(): Observable<string> {
    return (this.searchPage$ as Observable<ProductSearchPage>).pipe(
      filter((page: ProductSearchPage) => !!page.categoryMetaTitle || !!page.pagination),
      switchMap((p: ProductSearchPage) => {
        if (p.categoryMetaTitle) {
          return of(p.categoryMetaTitle);
        }
        return this.translation.translate('pageMetaResolver.category.title', {
          count: p.pagination?.totalResults,
          query: p.breadcrumbs?.length
            ? p.breadcrumbs[0].facetValueName
            : undefined,
          });
      })
    );
  }

  /**
   * Resolves the page description for the Product Listing Page. If categoryMetaDescription is not provided then
   * the description is based on the `page.categoryDescription`.
   */
  resolveDescription(): Observable<string | undefined> {
    return (this.searchPage$ as Observable<ProductSearchPage>).pipe(
      switchMap((p: ProductSearchPage) =>
        of(p.categoryMetaDescription ?? p.categoryDescription)
      )
    );
  }

  resolveHrefLangs(): Observable<HrefLangMeta[]> {
    return this.basePageMetaResolver?.resolveHrefLangs() ?? of();
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      (<Observable<ProductSearchPage>>this.searchPage$).pipe(),
      this.translation.translate('common.home'),
      this.translation.translate('common.tiendaOnline'),
    ]).pipe(
      map(([page, label, tiendaOnline]: [ProductSearchPage, string, string]) =>
        page.breadcrumbs
          ? this.resolveCustomBreadcrumbData(<ProductSearchPage>page, label, tiendaOnline)
          : []
      )
    );
  }
  protected resolveCustomBreadcrumbData(
    page: ProductSearchPage,
    label: string,
    tiendaOnline: string,
  ): BreadcrumbMeta[] {
    const breadcrumbs: BreadcrumbMeta[] = [];
    breadcrumbs.push({ label: label, link: '/' });
    for (const br of page.breadcrumbs ?? []) {
      if (br.facetValueName) {
        if (br.facetCode === 'category' || br.facetCode === 'allCategories') {
          breadcrumbs.push({
            label: br.facetValueName,
            link: `/${tiendaOnline}/category/${br.facetValueCode}`,
          });
        }
        if (br.facetCode === 'brand') {
          breadcrumbs.push({
            label: br.facetValueName,
            link: `/Brands/${br.facetValueName}/${tiendaOnline}/c/${br.facetValueCode}`,
          });
        }
      }
    }
    return breadcrumbs;
  }
}
