<ng-container *ngIf="showOOTBBanner">
  <ng-container *ngIf="bannerVisible$ | async as bannerVisible">
    <div
      [ngClass]="{ 'anonymous-consent-banner-hidden': !bannerVisible }"
      class="anonymous-consent-banner"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-xs-12">
            <div class="cx-banner-title">
              {{ 'anonymousConsents.banner.title' | cxTranslate }}
            </div>
            <div class="cx-banner-description">
              {{ 'anonymousConsents.banner.description' | cxTranslate }}
            </div>
          </div>

          <div class="col-lg-5 col-xs-12 cx-banner-buttons">
            <a href="{{cookieUrl}}" class="btn btn-action" target="_blank">
              {{ 'anonymousConsents.banner.viewDetails' | cxTranslate }}
            </a>
            <button class="btn btn-primary" (click)="allowAll()">
              {{ 'anonymousConsents.banner.allowAll' | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
