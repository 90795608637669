import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { CmsComponentData, ICON_TYPE, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { CmsCustomNewsletterComponent } from '../custom-newsletters.model';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-custom-newsletter-dialog',
    templateUrl: './custom-newsletter-dialog.component.html',
})
export class CustomNewsletterDialogComponent implements OnInit {
  @HostBinding('style.background') backgroundColor: string;
  component: CmsComponentData<CmsCustomNewsletterComponent>;
  iconTypes = ICON_TYPE;
  isModalVisible: boolean = true;

  Data$: Observable<any> = this.launchDialogService.data$.pipe(
    filter(data => !!data), // Make sure order isn't undefined
    take(1), // make sure first result is accepted
    map((data: any) => {
      return data
    })
  );
  
  constructor(
    private launchDialogService: LaunchDialogService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason)
  }
  
}