import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LoadCheckoutStep } from './store/actions/custom-checkout.action';
import { getCartSelectorFactory } from '../../../../../custom/core/cart/store/selectors/custom-multi-cart.selectors';
import { StateWithMultiCart, ActiveCartService } from '@spartacus/cart/base/core';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutStepsEventService {
  constructor(
    protected store: Store<StateWithMultiCart>,
    protected activeCartService: ActiveCartService,
  ) {}

  getCheckoutStep(currentStep): void {
    let cartId;
    this.activeCartService
      .getActiveCartId()
      .subscribe((activeCartId) => (cartId = activeCartId)).unsubscribe();

    this.store.pipe(select(getCartSelectorFactory(cartId)))
      .subscribe((cart) => {
          return this.store.dispatch(new LoadCheckoutStep({ step: currentStep, cart }));
        },
      ).unsubscribe();
  }
}
