import { Component } from '@angular/core';
import { LinkComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-generic-link',
  templateUrl: './custom-cms-link.component.html',
  styleUrls: ['./custom-cms-link.component.scss']
})

export class CustomCMSLinkComponent extends LinkComponent {
  
}

