<div *ngIf="flyout && node?.children.length > 1" class="back is-open" (click)="back()">
  <h5>
    <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
    {{ 'common.back' | cxTranslate }}
  </h5>
</div>

<ng-container *ngFor="let child of node?.children; let i = index ">
  <ng-container
    *ngTemplateOutlet="nav; context: { node: child, depth: 0, column: 1, row: 1 };">
  </ng-container>
</ng-container>

<!-- we generate links in a recursive manner -->
<ng-template #nav let-node="node" let-depth="depth" let-column="column" let-row="row" let-maxRows="maxRows" let-childLength="childLength">
  <nav
    (click)="toggleOpen($event)"
    (mouseenter)="onMouseEnter($event)"
    (keydown.space)="toggleOpen($event)"
    (keydown.esc)="back()"
    [class]="(depth && column && row) ? ('grid-col-'+column+' grid-row-start-'+row + ' grid-row-end-'+(childLength === row ?  maxRows + 1: row) ) : ''">
    <cx-generic-link
      *ngIf="node.url && (!node.children || node.children?.length === 0); else heading"
      [url]="node.url"
      [target]="node.target"
      [style]="node.styleAttributes"
      [class]="node.styleClasses"
    >
      <span class="ellipsis">{{ node.title }}</span>
      <cx-icon *ngIf="flyout && node.children?.length > 0" [type]="iconType.CARET_DOWN"></cx-icon>
    </cx-generic-link>

    <ng-template #heading>
      <ng-container *ngIf="isUpBreakpointLg() | async; else headingTitle">
        <cx-generic-link [url]="node.url" [target]="node.target" [style]="node.styleAttributes" [class]="node.styleClasses">
          <ng-container *ngTemplateOutlet="nodeHeading; context: { link: node }">
          </ng-container>
        </cx-generic-link>
      </ng-container>
      <ng-template #headingTitle>
        <ng-container *ngTemplateOutlet="nodeHeading; context: { link: node }">
        </ng-container>
      </ng-template>
    </ng-template>
    <ng-template #nodeHeading let-link="node">
      <h5 [attr.aria-label]="node.title" [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1">
        <span class="ellipsis">{{ node.title }}</span>
        <cx-icon *ngIf="flyout && node.children?.length > 0" [type]="iconType.CARET_DOWN"></cx-icon>
      </h5>
    </ng-template>
    <!-- we add a wrapper to allow for better layout handling in CSS -->
    <div class="wrapper" *ngIf="node.group?.length > 0; else children">
      <cx-generic-link
        *ngIf="node.url"
        [url]="node.url"
        [target]="node.target"
        class="all"
      >
        {{ 'navigation.shopAll' | cxTranslate: {navNode: node.title} }}
      </cx-generic-link>
      <div
        class="childs"
        [attr.depth]="getTotalDepth(node)"
        [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
        [attr.columns]="getColumnCount(node.children?.length)"
      >
        <ng-container *ngFor="let group of node.group; let columnIndex = index">
          <ng-container *ngFor="let child of group.children; let rowIndex = index">
            <ng-container
              *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1, column: columnIndex + 1, row: rowIndex + 1, maxRows: node.maxRows, childLength: group?.children?.length }">
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <ng-template #children>
      <div class="wrapper" *ngIf="node.children?.length > 0">
        <cx-generic-link
          *ngIf="node.url"
          [url]="node.url"
          [target]="node.target"
          class="all"
        >
          {{ 'navigation.shopAll' | cxTranslate: {navNode: node.title} }}
        </cx-generic-link>
        <div
          class="childs"
          [attr.depth]="getTotalDepth(node)"
          [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
          [attr.columns]="getColumnCount(node.children?.length)"
        >
          <ng-container *ngFor="let child of node.children; let childIndex = index">
            <ng-container
              *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1, column: column, row: row, maxRows: maxRows, childLength: childLength }">
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </nav>
</ng-template>
