import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomNewsletterAdapter } from './custom-newsletter.adapter';

@Injectable({
  providedIn: 'root',
})
export class CustomNewsletterConnector {
  constructor(protected adapter: CustomNewsletterAdapter) { }

  public updateNewsletter(
    email: string,
    consentTemplateId: string,
    consentTemplateVersion: string,
    accept: boolean,
  ): Observable<any> {
    return this.adapter.updateNewsletter(email, consentTemplateId, consentTemplateVersion, accept);
  }
}
