<ng-container *ngIf="components$ | async as components">
    <ng-container *ngFor="let component of components; let i = index">
      <ng-container *ngIf="component">
        <button
          [class.active]="i === activeTabNum"
          (click)="select(i, $event)"
          [ngSwitch]="isTitleHeader3(component)"
        >
          <h3 *ngSwitchCase="true">
            <ng-container *ngTemplateOutlet="headerTitle"></ng-container>
          </h3>
          <h2 *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="headerTitle"></ng-container>
          </h2>
        </button>
        <ng-template #headerTitle>
          {{
            component.title | cxTranslate: { param: tabTitleParams[i] | async }
          }}
        </ng-template>
        <div [class.active]="i === activeTabNum">
            <ng-template [cxOutlet]="component.flexType" [cxOutletContext]="{}">
            <ng-container [cxComponentWrapper]="component"></ng-container>
            </ng-template>
        </div>
    </ng-container>
</ng-container>
