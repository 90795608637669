<div *ngIf="form$ | async as form">
  <div
    *ngIf="hasPackGroup && !isConfirmation"
    class="alert alert-info"
  >
    {{ 'orderDetails.cancellationAndReturn.hasPackGroup' | cxTranslate }}
  </div>
  <div class="d-none d-md-block cx-item-list-header">
    <div class="row">
      <div class="text-left col-6">
        {{ 'orderDetails.cancellationAndReturn.item' | cxTranslate }}
      </div>
      <div class="text-center col-2">
        {{ 'orderDetails.cancellationAndReturn.itemPrice' | cxTranslate }}
      </div>
      <div *ngIf="!isConfirmation" class="text-center col-1">
        {{ 'orderDetails.cancellationAndReturn.quantity' | cxTranslate }}
      </div>
      <div
        class="cx-item-list-qty col-3"
        [class.text-right]="!isConfirmation"
      >
        {{
          (isCancellation()
            ? 'orderDetails.cancellationAndReturn.cancelQty'
            : 'orderDetails.cancellationAndReturn.returnQty'
          ) | cxTranslate
        }}
      </div>
      <div *ngIf="isConfirmation" class="cx-item-list-total col-1">
        {{ 'orderDetails.cancellationAndReturn.totalPrice' | cxTranslate }}
      </div>
    </div>
  </div>
  <div class="cx-item-list-row" *ngFor="let item of entries; let i = index">
    <div class="row cx-item-list-items">
      <!-- Item Image -->
      <cx-media
        class="col-3 col-md-2"
        [container]="item.product.images?.PRIMARY"
        format="thumbnail"
      ></cx-media>

      <!-- Item Information -->
      <div class="cx-info col-9 col-md-10">
        <div class="cx-info-container row">
          <!-- Item Description -->
          <div class="col-md-4 col-xl-5 cx-list-item-desc">
            <div *ngIf="item.product.name" class="cx-name">
              {{ item.product.name }}
            </div>
            <span
              *ngIf="item.packProduct"
              class="badge badge-background"
            >
              {{ item.packProduct.name }}
            </span>
            <!-- Variants -->
            <ng-container *ngIf="item.product.baseOptions?.length">
              <div
                *ngFor="
                  let variant of item.product.baseOptions[0]?.selected
                    ?.variantOptionQualifiers
                "
                class="cx-property"
              >
                <div class="cx-label" *ngIf="variant.name">
                  {{ variant.name }}:
                </div>
                <div class="cx-value" *ngIf="variant.value">
                  {{ variant.value }}
                </div>
              </div>
            </ng-container>
          </div>
          <!-- Price -->
          <div
            *ngIf="item.basePrice"
            class="cx-price col-md-3 col-xl-2"
          >
            <div *ngIf="item.basePrice" class="cx-value">
              {{ item.basePrice?.formattedValue }}
              <span class="d-md-none d-lg-none d-xl-none">{{ 'addToCart.eachUnit' | cxTranslate }}</span>
            </div>
          </div>
          <!-- item Quantity -->
          <div *ngIf="!isConfirmation" class="cx-request-qty col-md-2">
            <div
              class="cx-label d-block d-md-none d-lg-none d-xl-none"
              placement="left"
              title="{{ 'cartItems.quantityTitle' | cxTranslate }}"
            >
              {{ 'orderDetails.cancellationAndReturn.quantity' | cxTranslate }}
            </div>
            <div class="cx-value">
              {{ getMaxAmendQuantity(item) }}
            </div>
          </div>
          <!-- Amended Quantity -->
          <div class="cx-quantity-container col-md-3">
            <div class="cx-label d-block d-md-none d-lg-none d-xl-none">
              {{
                (isCancellation()
                  ? 'orderDetails.cancellationAndReturn.cancelQty'
                  : 'orderDetails.cancellationAndReturn.returnQty'
                ) | cxTranslate
              }}:
            </div>
            <div
              *ngIf="isConfirmation"
              class="w-100 text-center cx-order-quantity-value"
            >
              {{ getControl(form, item).value }}
          </div>
            <div class="cx-quantity">
              <cx-item-counter
                *ngIf="!isConfirmation"
                [min]="0"
                [max]="getMaxAmendQuantity(item)"
                [control]="getControl(form, item)"
                [step]="getProductStep(item)"
                [readonly]="true"
              >
              </cx-item-counter>
            </div>
          </div>
          <!-- Total Price -->
          <div *ngIf="isConfirmation" class="cx-total col-md-2">
            <div class="cx-label d-block d-md-none">
              {{
                'orderDetails.cancellationAndReturn.totalPrice' | cxTranslate
              }}
            </div>
            <div class="cx-value">
              {{ getItemPrice(item)?.formattedValue }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    *ngIf="!isConfirmation"
    class="btn btn-link cx-action-link"
    (click)="setAll(form)"
  >
    {{ 'orderDetails.cancellationAndReturn.setAll' | cxTranslate }}
  </button>
</div>
