<cx-carousel
  *ngIf="getItems() | async as items"
  [items]="items"
  [template]="template"
  [hideButtons]="true"
  [autoPlay]="true"
  [delay]="5000"
  itemWidth="100%"
  class="inline-navigation"
></cx-carousel>

<ng-template #template let-item="item">
  <app-custom-carousel-banner
    [item]="item"
    [slotName]="componentSlotName"
    [displayTitleAsHeader]="displayTitleAsHeader(item)"
  ></app-custom-carousel-banner>
</ng-template>
