import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomIconsModule } from 'src/app/spartacus/custom/cms-components/misc/icon/custom-icons.module';
import { CustomOrderTicketDownloadComponent } from './custom-order-ticket-download.component';

@NgModule({
  declarations: [CustomOrderTicketDownloadComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomOrderTicketDownloadComponent: {
          component: CustomOrderTicketDownloadComponent,
        },
      },
    } as CmsConfig),
    UrlModule,
    I18nModule,
    IconModule,
    CustomIconsModule,
  ],
  exports: [CustomOrderTicketDownloadComponent],
})
export class CustomOrderTicketDownloadModule { }
