import { StateUtils } from '@spartacus/core';
import { Statistics, StatisticsProduct } from '../../model/custom-statistics-model';

export enum StatisticsActionType {
  STATISTICS = '[Statistics] Statistics',
  LOAD_STATISTICS = '[Statistics] Load Statistics ',
  LOAD_STATISTICS_SUCCESS = '[Statistics] Load Statistics Success',
  LOAD_STATISTICS_FAIL = '[Statistics] Load Statistics Fail',
  LOAD_STATISTICS_RESET = '[Statistics] Load Statistics Fail',

  LOAD_STATISTICS_PRODUCTS = '[Statistics Products] Load Statistics ',
  LOAD_STATISTICS_PRODUCTS_SUCCESS = '[Statistics Products] Load Statistics Success',
  LOAD_STATISTICS_PRODUCTS_FAIL = '[Statistics Products] Load Statistics Fail',
  LOAD_STATISTICS_PRODUCTS_RESET = '[Statistics Products] Load Statistics Fail',
}

export class LoadStatistics extends StateUtils.LoaderLoadAction {
  readonly type = StatisticsActionType.LOAD_STATISTICS;

  constructor(public payload: { userId: string; years: Array<number> }) {
    super(StatisticsActionType.STATISTICS);
  }
}

export class LoadStatisticsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = StatisticsActionType.LOAD_STATISTICS_SUCCESS;

  constructor(public payload: Statistics[]) {
    super(StatisticsActionType.STATISTICS);
  }
}

export class LoadStatisticsFail extends StateUtils.LoaderFailAction {
  readonly type = StatisticsActionType.LOAD_STATISTICS_FAIL;

  constructor(public payload: any) {
    super(StatisticsActionType.STATISTICS, payload);
  }
}

export class ResetStatistics extends StateUtils.LoaderResetAction {
  readonly type = StatisticsActionType.LOAD_STATISTICS_RESET;

  constructor(public payload: any) {
    super(StatisticsActionType.STATISTICS);
  }
}

export class LoadStatisticsProducts extends StateUtils.LoaderLoadAction {
  readonly type = StatisticsActionType.LOAD_STATISTICS_PRODUCTS;

  constructor(public payload: { userId: string; years: Array<number> }) {
    super(StatisticsActionType.STATISTICS);
  }
}

export class LoadStatisticsProductsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = StatisticsActionType.LOAD_STATISTICS_PRODUCTS_SUCCESS;

  constructor(public payload: StatisticsProduct[]) {
    super(StatisticsActionType.STATISTICS);
  }
}

export class LoadStatisticsProductsFail extends StateUtils.LoaderFailAction {
  readonly type = StatisticsActionType.LOAD_STATISTICS_PRODUCTS_FAIL;

  constructor(public payload: any) {
    super(StatisticsActionType.STATISTICS, payload);
  }
}

export class ResetStatisticsProducts extends StateUtils.LoaderResetAction {
  readonly type = StatisticsActionType.LOAD_STATISTICS_PRODUCTS_RESET;

  constructor(public payload: any) {
    super(StatisticsActionType.STATISTICS);
  }
}

export type StatisticsActions =
  LoadStatistics
  | LoadStatisticsSuccess
  | LoadStatisticsFail
  | ResetStatistics;

export type StatisticsProductActions =
  | LoadStatisticsProducts
  | LoadStatisticsProductsSuccess
  | LoadStatisticsProductsFail;
