import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {CmsCustomCarouselBannerComponent} from './custom-carousel-banner.model';
import {
  CustomCmsEventService
} from "../../../../spartacus/features/tracking/custom-events/cms/custom-cms-event.service";
import {
  CustomPromotionComponentModel
} from "../../../../spartacus/features/tracking/custom-events/cms/custom-cms.model";

@Component({
  selector: 'app-custom-carousel-banner',
  templateUrl: './custom-carousel-banner.component.html',
  styleUrls: ['./custom-carousel-banner.component.scss']
})
export class CustomCarouselBannerComponent implements OnInit, AfterViewInit {

  private promotionEventEmitted: boolean = false;

  @Input() item: CmsCustomCarouselBannerComponent;
  @Input() displayTitleAsHeader: boolean;
  @Input() slotName?: string;

  constructor(protected customCmsEventService: CustomCmsEventService) {
  }

  ngOnInit(): void {
  }

  getTarget(target: string | boolean): string | null {
    return target === 'true' || target === true ? '_blank' : null;
  }

  get btnClass(): string {
    return 'btn btn-primary mt-4';
  }

  ngAfterViewInit(): void {
    if (this.getPromotional(this.item)) {
      this.customCmsEventService.dispatchViewPromotionEvent(<CustomPromotionComponentModel>{
        name: this.item.uid,
        slot: this.slotName || '',
        promotion: this.item.name
      });
    }
  }

  handleBtnClick(): void {
    this.dispatchSelectPromotionEvent();
  }

  private dispatchSelectPromotionEvent(): void {
    if (this.getPromotional(this.item) && !this.promotionEventEmitted) {
      this.customCmsEventService.dispatchSelectPromotionEvent(<CustomPromotionComponentModel>{
        name: this.item.uid,
        slot: this.slotName || '',
        promotion: this.item.name
      });
      this.promotionEventEmitted = true;
    }
  }

  getPromotional(item: CmsCustomCarouselBannerComponent): boolean {
    return (item.promotional === true || item.promotional === 'true')
  }
}
