import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OCC_USER_ID_ANONYMOUS, RoutingService, StateWithProcess, StateWithUser, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { OrdersFilter } from 'src/app/model/filters.model';
import { CustomUserActions } from '../store/actions';
import { CustomOrderDetailsSelectors } from '../store/selectors';
import { OrderHistoryService, StateWithOrder } from '@spartacus/order/core';

@Injectable({
  providedIn: 'root',
})
export class CustomUserOrderService extends OrderHistoryService {
  constructor(
    protected store: Store<StateWithOrder>,
    protected userIdService: UserIdService,
    protected routingService: RoutingService,
    protected processStateStore: Store<StateWithProcess<void>>
  ) {
    super(store, processStateStore, userIdService, routingService);
  }

  /**
   * Retrieves an order list
   * @param pageSize page size
   * @param currentPage current page
   * @param sort sort
   * @param ordersFilters orders filters
   */
  loadOrderList(pageSize: number, currentPage?: number, sort?: string, ordersFilter?: OrdersFilter): void {

    this.userIdService
      .takeUserId()
      .subscribe((userId) => {
        if (userId !== OCC_USER_ID_ANONYMOUS) {
          let replenishmentOrderCode: string;

          this.routingService
            .getRouterState()
            .pipe(take(1))
            .subscribe((data) => {
              replenishmentOrderCode =
                data?.state?.params?.replenishmentOrderCode;
            })
            .unsubscribe();

          this.store.dispatch(
            new CustomUserActions.CustomLoadUserOrders({
              userId,
              pageSize,
              currentPage,
              sort,
              replenishmentOrderCode,
              ordersFilter
            })
          );
        }
      }).unsubscribe();
  }

  /**
   * Get status of order details loading
   */
  getOrderDetailsLoading(): Observable<boolean> {
    return this.store.pipe(
      select(CustomOrderDetailsSelectors.getOrderDetailsLoading)
    );
  }
}
