import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { StateWishlist, WishlistList } from 'src/app/model/wishlist.model';

export const USER_WISHLIST_FEATURE = 'wishlist';

export interface StateWithWishlist {
  [USER_WISHLIST_FEATURE]: StateWishlist;
}

export const getWishlistState: MemoizedSelector<StateWithWishlist, StateWishlist> =
  createFeatureSelector<StateWishlist>(USER_WISHLIST_FEATURE);

export const getWishlistLoaderState: MemoizedSelector<
  StateWithWishlist,
  LoaderState<WishlistList>
> = createSelector(getWishlistState, (state: StateWishlist) => state.wishlist);

export const getWishlist: MemoizedSelector<
  StateWithWishlist,
  WishlistList
> = createSelector(getWishlistLoaderState, (state: LoaderState<WishlistList>) =>
  StateUtils.loaderValueSelector(state)
);

export const getWishlistLoading: MemoizedSelector<
  StateWithWishlist,
  boolean
> = createSelector(getWishlistLoaderState, (state: LoaderState<WishlistList>) =>
  StateUtils.loaderLoadingSelector(state)
);

export const getWishlistLoaded: MemoizedSelector<
  StateWithWishlist,
  boolean
> = createSelector(getWishlistLoaderState, (state: LoaderState<WishlistList>) =>
  StateUtils.loaderSuccessSelector(state)
);
