import { UpdateAgeStatus } from './../../../../../../../custom/cms-components/header/age-status/store/actions/custom-age-status.actions';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Country, Region, User} from '@spartacus/core';
import {UpdateProfileComponent, UpdateProfileComponentService} from '@spartacus/user/profile/components';
import {BehaviorSubject, Observable} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';
import {CustomUserAddressService} from 'src/app/spartacus/custom/core/user/facade/custom-user-address.service';
import {CustomUpdateProfileComponentService} from './custom-update-profile-component.service';
import {BusinessFriends} from "../../../../../../../custom/cms-components/myaccount/custom-friends/enum/business-friends.enum";
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';


@Component({
  selector: 'cx-update-profile',
  templateUrl: './custom-update-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'user-form'},
})
export class CustomUpdateProfileComponent extends UpdateProfileComponent implements OnInit {
  [x: string]: any;
  countries$: Observable<Country[]>;
  selectedCountry$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  regions$: Observable<Region[]>;
  hasDocumentIdentifier$ = this.service.hasDocumentIdentifier$;
  exclusiveRole$ = this.service.exclusiveRole$;
  isFormEditable: Boolean
  setInputOff: boolean = false;
  controlReload : boolean = false
  isButtonDisable : boolean = false;



  constructor(
    protected service: CustomUpdateProfileComponentService,
    protected userAddressService: CustomUserAddressService,
    protected userAccountFacade: UserAccountFacade,
    protected activeCartService: CustomActiveCartService,
  ) {
    super(service);
  }

  ngOnInit(): void {

    this.exclusiveRole$.subscribe(
      value => {
        this.isFormEditable =
          value === BusinessFriends.FRIENDS ||
          value === BusinessFriends.PUBLIC ||
          value === BusinessFriends.RIOFRIO
      },
    );
    this.userAddressService.clearUserMiscData();
    // Fetching countries
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountriesAll();
        }
      })
    );

    // Fetching regions
    this.regions$ = this.selectedCountry$.pipe(
      switchMap((country) => this.userAddressService.getRegions(country)),
      tap((regions: Region[]) => {
      }),
    );


      this.service.applyValues$.pipe(
        take(1)
      ).subscribe(
        (data) => {
          if (this.form.valid) {
           this.isButtonDisable = true;
           this.isFormEditable = false;
           const country: string = this.form['controls'].defaultAddress['controls'].country['controls'].isocode.value;
          this.selectedCountry$.next(country);
          }
          sessionStorage.setItem('dCode', '');
        },
        (error) => {
          this.setInputOff = false;
          this.form.get('documentIdentifier')?.disabled;
        }
      );

    //apply values from $user to form


  }
  onSubmit(): void {
    this.service.newUpdateProfile(()=>{
      this.isButtonDisable = true;
    });
  }



  countrySelected(country: Country): void {
    this.form['controls'].defaultAddress['controls'].country['controls'].isocode.setValue(
      country.isocode
    );
    this.selectedCountry$.next(country.isocode);
    this.form['controls'].defaultAddress['controls'].postalCode?.updateValueAndValidity();
    this.form['controls'].documentIdentifier?.updateValueAndValidity();
  }



  regionSelected(region: Region): void {
    this.form['controls'].defaultAddress['controls'].region['controls'].isocode.setValue(
      region.isocode
    );
  }

}
