import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {CmsComponentData, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import { CmsCustomNewsletterComponent } from './custom-newsletters.model';
import { CustomNewslettersService } from './custom-newsletters.service';
import { Subscription, of } from 'rxjs';

@Component({
  selector: 'app-custom-newsletters',
  templateUrl: './custom-newsletters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNewslettersComponent implements OnInit, OnDestroy {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  componentData: CmsComponentData<CmsCustomNewsletterComponent>;
  @ViewChild('open') element: ElementRef;
  modalRef;
  subscriptions = new Subscription();

  constructor(
    private component: CmsComponentData<CmsCustomNewsletterComponent>,
    protected modalService: LaunchDialogService,
    protected newslettersService: CustomNewslettersService,
    protected vcr: ViewContainerRef,
    
  ) {
    this.componentData = this.component;
  }

  ngOnInit(): void {
    this.componentData.data$.subscribe(
      (data) => {
        this.styleClasses = data.styleClasses;
        if (data?.backgroundColorCode) {
          this.backgroundColor = data.backgroundColorCode;
        }
        if (this.displayPopup(data?.displayPopup) && this.newslettersService.displayPopup) {
          this.openModal(data);
        }
      })
      .unsubscribe();
  }

  private displayPopup(display: boolean | string): boolean {
    return display === 'true' || display === true;
  }

  private openModal(data: any): void {
    //CustomNewsletterDialogComponent
    const data$ = of(data);

    const dialog = this.modalService.openDialog(
      LAUNCH_CALLER.NEWSLETTERS,
      undefined,
      this.vcr,
      {
        data$
      }
      );

      if (dialog) {
        this.subscriptions.add(dialog.subscribe());
      }
    this.newslettersService.setPopupAsDisplayed();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
