<div class="modal-dialog modal-dialog-centered modal-lg" >
    <div class="modal-content">
        <div class="modal-body">
            <ng-container *ngIf="Data$ | async as data">
                <!--  Modal Body -->
                <div class="cx-dialog-body">
                    <app-custom-newsletter [component]="data">
                    </app-custom-newsletter>
                </div>
                <div class="cx-dialog-buttons">
                    <div class="row p-4">
                        <div class="cx-dialog-actions offset-sm-9 col-sm-3">
                            <button 
                                type="button" 
                                class="btn btn-block btn-send" 
                                (click)="closeModal('Close')"
                                >
                                {{ 'newsletter.popup.close' | cxTranslate }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>