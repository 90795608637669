import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import {
  CmsLinkComponent,
  CmsProductCarouselComponent as model,
  Product,
  ProductScope,
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CustomProductService } from 'src/app/spartacus/custom/core/product/facade/custom-product.service';

@Component({
  selector: 'cx-product-carousel',
  templateUrl: './custom-product-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomProductCarouselComponent {
  protected readonly PRODUCT_SCOPE = ProductScope.LIST;

  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  addContainer = false;


  data$: Observable<model> = this.componentData.data$.pipe(
    tap(component => {
      this.initComponent(component);
    }),
  );

  private componentData$: Observable<model> = this.componentData.data$.pipe(
    filter(Boolean),
    tap(component => {
      this.initComponent(component);
    }),
  );

  /**
   * returns an Observable string for the title.
   */
  title$: Observable<string> = this.componentData$.pipe(
    map((data: model) => data.title)
  );
  bgColor$: Observable<string> = this.componentData$.pipe(
    map((data: model) => data.fullPageWidth.toString() === 'false'? data.backgroundColorCode : '')
  );

  /**
   * Observable that holds an Array of Observables. This is done, so that
   * the component UI could consider to lazy load the UI components when they're
   * in the viewpoint.
   */
  items$: Observable<Observable<Product>[]> = this.componentData$.pipe(
    map((data: model) => data.productCodes?.trim().split(' ') ?? []),
    map((codes: string[]) =>
      codes.map((code) => this.productService.get(code, this.PRODUCT_SCOPE))
    )
  );

  constructor(
    protected componentData: CmsComponentData<model>,
    protected productService: CustomProductService,
  ) {}

  link$: Observable<CmsLinkComponent> = this.componentData$.pipe(
    map((data: model) => data.link)
  );

  get itemBtnClass(): string {
    return 'btn btn-send';
  }

  initComponent(item: model): void {
    this.styleClasses = item?.styleClasses ?? '';
    if (item?.fullPageWidth.toString() === 'true') {
      this.styleClasses += ' full-page-width';
      this.addContainer = true;
      this.backgroundColor = item.backgroundColorCode ?? '';
    }
  }

}
