import { CustomFriendsCheckoutState } from '../custom-friends-checkout.state';
import { CustomFriendsCheckoutActions } from '../actions/custom-friends-checkout.action';
import { ActionTypes } from '../actions/custom-friends-checkout.action';

export const initialState: CustomFriendsCheckoutState = {
  members: [],
  selected: null
};

export function reducer(
  state = initialState,
  action: CustomFriendsCheckoutActions
): CustomFriendsCheckoutState {

  switch (action.type) {

    case ActionTypes.LOAD_FRIENDS_CHECKOUT_CANDIDATES_FAIL: {
      return initialState;
    }

    case ActionTypes.LOAD_FRIENDS_CHECKOUT_CANDIDATES_SUCCESS: {
      return {
        ...state,
        members: action.payload ? action.payload : initialState.members
      };
    }

    case ActionTypes.LOAD_FRIENDS_CHECKOUT_FAIL: {
      return {
        ...state,
        selected: initialState.selected
      };
    }

    case ActionTypes.LOAD_FRIENDS_CHECKOUT_SUCCESS: {
      return {
        ...state,
        selected: action.payload ? action.payload : initialState.selected
      };
    }

    case ActionTypes.SELECT_FRIENDS_CHECKOUT_FAIL: {
      return {
        ...state,
        selected: initialState.selected
      };
    }

    case ActionTypes.SELECT_FRIENDS_CHECKOUT_SUCCESS: {
      return {
        ...state,
        selected: action.payload ? action.payload : initialState.selected
      };
    }

    case ActionTypes.UPDATE_FRIENDS_CHECKOUT_FAIL: {
      return {
        ...state,
        selected: initialState.selected
      };
    }

    case ActionTypes.UPDATE_FRIENDS_CHECKOUT_SUCCESS: {
      return {
        ...state,
        selected: initialState.selected
      };
    }

    case ActionTypes.REMOVE_FRIENDS_CHECKOUT_FAIL: {
      return state;
    }

    case ActionTypes.REMOVE_FRIENDS_CHECKOUT_SUCCESS: {
      return {
        ...state,
        selected: initialState.selected
      };
    }

    case ActionTypes.PURGE_FRIENDS_CHECKOUT_SUCCESS: {
      return {...initialState};
    }

  }
  return state;
}

