/**
 * Indicates that a user has successfully placed an order
 */
import { CxEvent } from '@spartacus/core';
import {
  CustomCheckoutDeliveryModeCompleteModel,
  CustomCheckoutPaymentModeCompleteModel,
  CustomCheckoutStepsModel
} from './custom-checkout-steps.model';

/**
 * Indicates that a user visited a checkout step page.
 */
export class CustomCheckoutStepsEvent extends CxEvent {
  /** event's type */
  static readonly type = 'CheckoutStepsPageEvent';
  event: string;
  ecommerce: CustomCheckoutStepsModel;
}

/**
 * Indicates that a user change a checkout delivery mode.
 */
export class CustomCheckoutDeliveryModeEvent extends CxEvent {
  /** event's type */
  static readonly type = 'CheckoutDeliveryModeEvent';
  event: string;
  ecommerce: CustomCheckoutStepsModel;
}

/**
 * Indicates that a user completed setting up checkout delivery mode.
 */
export class CustomCheckoutDeliveryModeCompleteEvent extends CxEvent {
  /** event's type */
  static readonly type = 'CustomCheckoutDeliveryModeCompleteEvent';
  event: string;
  ecommerce: CustomCheckoutDeliveryModeCompleteModel;
}

/**
 * Indicates that a user change a payment mode.
 */
export class CustomCheckoutPaymentModeEvent extends CxEvent {
  /** event's type */
  static readonly type = 'CheckoutPaymentModeEvent';
  event: string;
  ecommerce: CustomCheckoutStepsModel;
}

/**
 * Indicates that a user change a payment mode.
 */
export class CustomCheckoutPaymentModeCompleteEvent extends CxEvent {
  /** event's type */
  static readonly type = 'CustomCheckoutPaymentModeCompleteEvent';
  event: string;
  ecommerce: CustomCheckoutPaymentModeCompleteModel;
}

