import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccCheckoutDeliveryAddressAdapter } from '@spartacus/checkout/base/occ';
import {
  Address,
  ADDRESS_NORMALIZER,
  ADDRESS_SERIALIZER,
  ConverterService,
  Occ,
  OccEndpointsService
} from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable()
export class CustomOccCheckoutDeliveryAdapter extends OccCheckoutDeliveryAddressAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
  ) {
    super(http, occEndpoints, converter);
  }

  public createAddress(
    userId: string,
    cartId: string,
    address: Address,
  ): Observable<Address> {
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http
      .post<Occ.Address>(
        this.occEndpoints.buildUrl('cartDeliveryAddress', {
          urlParams: { userId, cartId }
        }),
        address,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
        },
      )
      .pipe(this.converter.pipeable(ADDRESS_NORMALIZER));
  }
}
