import { Action } from '@ngrx/store';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { GET_PAYMENT_TYPES_PROCESS_ID } from '../checkout-state';
import { PaymentType } from '@spartacus/cart/base/root';

export const CUSTOM_SET_PAYMENT_TYPE = '[Custom Checkout] Set Payment Type';
export const CUSTOM_SET_PAYMENT_TYPE_FAIL = '[Custom Checkout] Set Payment Type Fail';
export const CUSTOM_SET_PAYMENT_TYPE_SUCCESS = '[Custom Checkout] Set Payment Type Success';
export const CUSTOM_LOAD_PAYMENT_TYPES_SUCCESS = '[Custom Checkout] Load Payment Types Success';
export const CUSTOM_LOAD_PAYMENT_TYPES_FAIL = '[Custom Checkout] Load Payment Types Fail';
export const CUSTOM_LOAD_PAYMENT_TYPES = '[Custom Checkout] Load Payment Types';

export class SetPaymentType implements Action {
  readonly type = CUSTOM_SET_PAYMENT_TYPE;

  constructor(
    public payload: {
      userId: string;
      cartId: string;
      typeCode: string;
      poNumber?: string;
      paymentDetailsId?: string
    },
  ) {}
}

export class SetPaymentTypeFail implements Action {
  readonly type = CUSTOM_SET_PAYMENT_TYPE_FAIL;

  constructor(public payload: any) {}
}

export class SetPaymentTypeSuccess implements Action {
  readonly type = CUSTOM_SET_PAYMENT_TYPE_SUCCESS;

  constructor(public payload: string) {}
}


export class LoadPaymentTypesSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CUSTOM_LOAD_PAYMENT_TYPES_SUCCESS;

  constructor(public payload: PaymentType[]) {
    super(PROCESS_FEATURE, GET_PAYMENT_TYPES_PROCESS_ID);
  }
}
export class LoadPaymentTypesFail extends StateUtils.EntityFailAction {
  readonly type = CUSTOM_LOAD_PAYMENT_TYPES_FAIL;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, GET_PAYMENT_TYPES_PROCESS_ID);
  }
}

export type CustomPaymentTypesAction =
  | SetPaymentType
  | SetPaymentTypeFail
  | SetPaymentTypeSuccess
  | LoadPaymentTypesSuccess;
