import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomNewsListService } from './custom-news-list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, pairwise, switchMap } from 'rxjs/operators';
import { CustomNewsListModel } from '../../store/custom-news.model';
import { TranslationService } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-news-list',
  templateUrl: './custom-news-list.component.html',
})
export class CustomNewsListComponent implements OnInit, OnDestroy {
  iconTypes = ICON_TYPE;
  subscription = new Subscription();
  sortType: string;
  newsFormFilter: FormGroup;

  currentPage = 0;
  defaultPageSize = 50;

  PAGE_SIZE_OPTIONS: { code: number, name: string }[] = [
    { code: this.defaultPageSize, name: `${this.defaultPageSize}` },
    { code: 100, name: '100' },
    { code: 200, name: '200' },
  ];
  CATEGORY_OPTIONS: any[] = [];

  news$: Observable<CustomNewsListModel>;
  isLoaded$: Observable<boolean> = this.customNewsService.getNewsListLoading();

  constructor(
    protected customNewsService: CustomNewsListService,
    protected translation: TranslationService,
    protected fb: FormBuilder,
  ) {
    this.newsFormFilter = this.fb.group({
      titleSearch: [null],
      pageSize: [this.defaultPageSize, Validators.required],
      categoryCode: [''],
    });
  }

  ngOnInit(): void {
    this.news$ = this.customNewsService
      .getNewsList({
        pageSize: this.defaultPageSize || 0
      })

    this.newsFormFilter.valueChanges.pipe(
      filter(() => this.newsFormFilter.valid),
      pairwise(),
      switchMap(([oldVal, newVal]) => {
        if (oldVal.titleSearch !== newVal.titleSearch) {
          return of(newVal).pipe(debounceTime(300));
        } else {
          return of(newVal);
        }
      })).subscribe(() => this.updateNews());
    this.getCategories();
    this.customNewsService.loadNewsCategories();
  }

  updateNews(currentPage?: number): void {
    const value = this.newsFormFilter.value;
    const newsFilter = {};

    if (value.titleSearch?.trim().length) {
      Object.assign(newsFilter, { titleSearch: value.titleSearch });
    }

    if (typeof value.categoryCode === 'string') {
      Object.assign(newsFilter, { categoryCode: value.categoryCode });
    }
    this.customNewsService.loadNewsList({
      sort: this.sortType,
      currentPage: currentPage ?? 0,
      pageSize: Number.isNaN(value.pageSize) ? this.defaultPageSize : value.pageSize,
      newsFilter: newsFilter,
    });
  }

  removeFilter(filterKey: string): void {
    this.newsFormFilter.get(filterKey)?.reset();
  }

  getCategories(): void {
    combineLatest([
      this.customNewsService.getNewsCategories(),
      this.customNewsService.getNewsCategoriesSuccess(),
      this.customNewsService.getNewsCategoriesError(),
      this.customNewsService.getNewsCategoriesLoading(),
      this.translation.translate(`news.filters.category.ALL`),
      this.translation.translate(`news.filters.category.loading`),
    ]).subscribe(([newsCategories, success, error, loading, allTranslation, loadingTranslation]) => {
      const categories = newsCategories?.categories;
      if (loading) {
        this.CATEGORY_OPTIONS = [
          {
            code: '',
            name: loadingTranslation,
          },
        ];
      }

      if (success || error) {
        this.CATEGORY_OPTIONS = [
          {
            code: '',
            name: allTranslation,
          },
        ];
      }

      if (success && categories) {
        this.CATEGORY_OPTIONS = [
          ...this.CATEGORY_OPTIONS,
          ...categories,
        ];
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
