import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { CmsLinkComponent, CmsService } from '@spartacus/core';
import { CustomContentPagesSliderBannerModel } from './custom-content-pages-slider-banner.model';
import { CmsComponentData } from '@spartacus/storefront';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-content-pages-slider-banner-component',
  templateUrl: './custom-content-pages-slider-banner.component.html',
  styleUrls: ['./custom-content-pages-slider-banner.component.scss'],
})
export class CustomContentPagesSliderBannerComponent implements OnInit {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;

  @Input() isNews: boolean;
  @Input() data: Observable<any>;

  enableSlider = false;

  data$: Observable<CustomContentPagesSliderBannerModel> = this.componentData.data$.pipe(
    tap(component => {
      if (this.isNews) {
        this.enableSlider = true;
      } else {
        this.enableSlider = component?.enableSlider === 'true' || component?.enableSlider === true;
      }
      this.initComponent(component);
    }),
  );

  buttons$: Observable<Array<CmsLinkComponent>> =
    this.data$.pipe(
      switchMap((data: any) =>
        combineLatest(
          (data?.buttons ?? '').trim().split(' ')?.map((codes: any) =>
            this.cmsService.getComponentData(codes)
          ),
        ),
      ),
    );

  contentPage$: Observable<any> =
    this.data$.pipe(
      map((data: any) =>
        data?.contentPages?.trim()?.split(' ')?.map((codes: any) =>
          this.cmsService.getComponentData(codes).pipe(
            map(contentPage => contentPage),
          ),
        ),
      ),
    );

  constructor(
    protected componentData: CmsComponentData<CustomContentPagesSliderBannerModel>,
    protected cmsService: CmsService,
  ) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.data$ = this.data;
    }
  }

  initComponent(item: CustomContentPagesSliderBannerModel): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
    if (item?.invertColumn === true || item?.invertColumn === 'true') {
      this.styleClasses += ' invert';
    }
    this.backgroundColor = item?.backgroundColorCode ?? '';
  }

  getTarget(target: string | boolean): string | null {
    return target === 'true' || target === true ? '_blank' : null;
  }
}
