import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, InterceptorUtil, OccEndpointsService, OCC_USER_ID_ANONYMOUS, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { Bill, BillHistoryList, BillsFilter } from 'src/app/model/bill.model';
import { CustomOcc } from 'src/app/spartacus/custom/core/occ/occ-models/custom-occ.models';
import { BILL_HISTORY_NORMALIZER, BILL_NORMALIZER } from './custom-bill-converters';
import { CustomUserBillAdapter } from './custom-user-bill.adapter';

@Injectable()
export class CustomOccUserBillAdapter implements CustomUserBillAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) { }

  public load(userId: string, invoiceCode: string): Observable<Bill> {
    const url = this.occEndpoints.buildUrl('billDetail', {
      urlParams: { userId, invoiceCode }
    });

    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.http
      .get<CustomOcc.Bill>(url, { headers })
      .pipe(this.converter.pipeable(BILL_NORMALIZER));
  }

  public loadHistory(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    billsFilter?: BillsFilter,
  ): Observable<BillHistoryList> {
    const params = {};
    if (pageSize) {
      params['pageSize'] = pageSize.toString();
    }
    if (currentPage) {
      params['currentPage'] = currentPage.toString();
    }
    if (sort) {
      params['sort'] = sort.toString();
    }
    for (const key in billsFilter) {
      if (Object.prototype.hasOwnProperty.call(billsFilter, key)) {
        const element = billsFilter[key];
        if (element) {
          params[key] = element;
        }
      }
    }

    const url = this.occEndpoints.buildUrl('billHistory', {
      urlParams: { userId }
    });

    return this.http
      .get<CustomOcc.BillHistoryList>(url, { params: params })
      .pipe(this.converter.pipeable(BILL_HISTORY_NORMALIZER));
  }
}
