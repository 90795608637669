import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomVideoInfoComponent } from './custom-video-info.component';
import {CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';

@NgModule({
  declarations: [CustomVideoInfoComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    I18nModule,
    YouTubePlayerModule,
    UrlModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomYoutubeInfoComponent: {
          component: CustomVideoInfoComponent
        },
      }
    } as CmsConfig)
  ]
})
export class CustomVideoInfoModule { }
