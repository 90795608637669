<label *ngIf="(items$ | async)?.length > 1 && (items$ | async) as items">
  <span>{{ label$ | async }}<i class="icon-earth"></i></span>
  <select (change)="active = $event.target.value">
    <option
      *ngFor="let item of items"
      value="{{ item.isocode }}"
      [selected]="(activeItem$ | async) === item.isocode"
    >
      {{ item.label }}
    </option></select
  ><i class="small icon-chevron-down"></i>
</label>
