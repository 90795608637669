import { createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { OrderSelectors, StateWithOrder } from '@spartacus/order/core';
import { ReturnRequest } from '@spartacus/order/root';

export const getCustomOrderReturnRequestError: MemoizedSelector<
  StateWithOrder,
  boolean
> = createSelector(
  OrderSelectors.getOrderReturnRequestState,
  (state: LoaderState<ReturnRequest>) =>
    StateUtils.loaderErrorSelector(state) &&
    !StateUtils.loaderLoadingSelector(state)
);
