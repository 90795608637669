import { Component, HostBinding } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsCustomDividerComponent } from './custom-divider.model';

@Component({
  selector: 'app-custom-divider',
  templateUrl: './custom-divider.component.html'
})
export class CustomDividerComponent{
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background-color') backgroundColor: string;
  bottomColor: string;

  data$: Observable<CmsCustomDividerComponent> = this.component.data$.pipe(
    tap((data: CmsCustomDividerComponent) => (this.initComponent(data)))
  );

  constructor(protected component: CmsComponentData<CmsCustomDividerComponent>) {}

  initComponent(item: CmsCustomDividerComponent): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
    this.backgroundColor = item?.topColor ?? 'transparent';
    this.bottomColor = item?.bottomColor ?? 'transparent';
  }
}
