import { Injectable } from '@angular/core';
import { Address } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomRedsysPayment } from '../models/custom-checkout.model';
import { CustomCheckoutAdapter } from './custom-checkout.adapter';
import { CheckoutConnector } from '@spartacus/checkout/base/core';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutConnector extends CheckoutConnector {
  constructor(protected adapter: CustomCheckoutAdapter) {
    super(adapter);
  }

  loadRedsysPayment(
    userId: string,
    cartId: string,
    saveCard: boolean
  ): Observable<CustomRedsysPayment> {
    return this.adapter.loadRedsysPayment(userId, cartId, saveCard);
  }

  public simulateOrder(
    userId: string,
    cartId: string,
  ): Observable<any> {
    return this.adapter.simulateOrder(userId, cartId);
  }

  public validateCart(
    userId: string,
    cartId: string,
  ): Observable<any> {
    return this.adapter.validateCart(userId, cartId);
  }

  public createBillingAddress(
    userId: string,
    cartId: string,
    billingAddress: Address,
  ): Observable<any> {
    return this.adapter.createBillingAddress(userId, cartId, billingAddress);
  }

  public setBillingAddress(
    userId: string,
    cartId: string,
    billingAddressId: string,
  ): Observable<any> {
    return this.adapter.setBillingAddress(userId, cartId, billingAddressId);
  }

  public unsetBillingAddress(
    userId: string,
    cartId: string,
  ): Observable<any> {
    return this.adapter.unsetBillingAddress(userId, cartId);
  }
}
