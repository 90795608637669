import { HttpHeaders } from '@angular/common/http';
import { User } from '@spartacus/user/account/root';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomCartAdapter } from '../../../cart/conectors/cart/custom-cart.adapter';
import { OccCartAdapter } from '@spartacus/cart/base/occ';

@Injectable()
export class CustomOccCartAdapter extends OccCartAdapter implements CustomCartAdapter {
  setDocumentIdentifier(userId: string, cartId: string, user: User): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpointsService.buildUrl('documentIdentifier', {
      urlParams: { userId, cartId }
    });
    return this.http.put(url, user, { headers });
  }

  addExtraData(userId: string, cartId: string, extraData: {}): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpointsService.buildUrl('cart', {
      urlParams: { userId, cartId }
    });
    return this.http.patch(url, extraData, { headers });
  }

  addGiftbox(userId: string, giftBoxProductCode: string): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpointsService.buildUrl('addGiftbox', {
      urlParams: { userId }
    });
    return this.http.post(url, {}, { headers, params: { giftBoxProductCode } });
  }

  validateDocIdentifierAndTotalAmount(userId: string, cartId: string, user: User): Observable<{}> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpointsService.buildUrl('validateDocIdentifierAndTotalAmount', {
      urlParams: { userId, cartId }
    });
    return this.http.post(url, user, { headers });
  }
}
