<div class="container">
  <cx-media
    [container]="item.banner?.media?.widescreen ? item.banner.media : item.media"
  ></cx-media>

  <div class="content">
    <cx-media
      *ngIf="item.secondaryBanner?.media"
      [container]="item.secondaryBanner.media"
    ></cx-media>
    <ng-container *ngIf="item.title">
      <ng-container [ngSwitch]="displayTitleAsHeader">
        <ng-container *ngSwitchCase="true">
          <h1 class="title" [innerHTML]="item.title"></h1>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="title" [innerHTML]="item.title"></div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="item.description">
      <p class="description" [innerHTML]="item.description"></p>
    </ng-container>

    <cx-generic-link
      *ngIf="item.linkName !== '' && item.url"
      [url]="item.url"
      [target]="getTarget(item.target)"
      [class]="btnClass"
      (click)="handleBtnClick()"
    >
      {{item.linkName}}
    </cx-generic-link>
  </div>
</div>
