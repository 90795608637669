import { Component, HostBinding, OnInit } from '@angular/core';
import { take} from 'rxjs/operators';
import { BREAKPOINT, TabParagraphContainerComponent } from '@spartacus/storefront';
import { CMSTabParagraphContainer } from '@spartacus/core';

@Component({
  selector: 'app-custom-tab-paragraph-container',
  templateUrl: './custom-tab-paragraph-container.component.html'
})
export class CustomTabParagraphContainerComponent extends TabParagraphContainerComponent implements OnInit {
  @HostBinding('class') styleClasses: string;
  private isAccordion: boolean;
  breakpointService: any;

  ngOnInit(): void {
    super.ngOnInit();
    this.componentData.data$.subscribe(
      (data: CMSTabParagraphContainer) => {
          this.isAccordion = (data?.showAsAccordion === 'true' || data?.showAsAccordion === true) ? true : false;
          this.styleClasses = this.isAccordion ? 'accordion' : '';
      }
    );
  }

  select(tabNum: number, event?: MouseEvent): void {
    if (this.isAccordion) {
      this.handleTabChange(tabNum, event);
    } else {
      this.breakpointService
        ?.isDown(BREAKPOINT.sm)
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this.handleTabChange(tabNum, event);
          } else {
            this.activeTabNum = tabNum;
          }
        });
    }
  }

  isTitleHeader3(component: any): boolean {
    return component?.flexType === 'ProductReviewsTabComponent';
  }

  private handleTabChange(tabNum: number, event: MouseEvent): void {
    this.activeTabNum = this.activeTabNum === tabNum ? -1 : tabNum;
    if (event && event?.target) {
      const target = event.target as HTMLElement;
      const parentNode = target.parentNode as HTMLElement;
      this.winRef?.nativeWindow?.scrollTo(0, parentNode.offsetTop);
    }
  }
}
