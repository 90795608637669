import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { RecaptchaAdapters } from '../connector/recaptcha-adapters';

@Injectable()
export class OccRecaptchaAdapter implements RecaptchaAdapters{
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  validateRecaptcha(token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpoints.buildUrl('validateRecaptcha', {
      urlParams: { gRecaptchaResponse: token }
    });
    return this.http.get(url, { headers, observe: 'response' });
  }
}
