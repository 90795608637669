import { Action } from '@ngrx/store';

export const CUSTOM_LOAD_DELIVERY_COUNTRIES_ALL = '[User] Custom Load Delivery Countries All';

export class CustomLoadDeliveryCountriesAll implements Action {
  readonly type = CUSTOM_LOAD_DELIVERY_COUNTRIES_ALL;
  constructor() {}
}

export type CustomDeliveryCountriesAction =
  | CustomLoadDeliveryCountriesAll;
