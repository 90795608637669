import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CustomGiftCardConnector } from '../../conectors';
import { GiftCard } from '../../model/custom-gift-card.model';
import * as CustomGiftCardsListActions from '../actions/custom-gift-cards.action';

@Injectable()
export class CustomGiftCardsEffects {
  constructor(
    private actions$: Actions,
    private giftCardConnector: CustomGiftCardConnector,
  ) {}

  loadGiftCards$: Observable<
   | CustomGiftCardsListActions.LoadGiftCardsSuccess
   | CustomGiftCardsListActions.LoadGiftCardsFail
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomGiftCardsListActions.CustomGiftCardsActionTypes.LOAD_GIFT_CARDS),
    map((action: CustomGiftCardsListActions.LoadGiftCards) => action.payload),
    mergeMap((payload: { userId: string; cartId: string }) =>
      this.giftCardConnector.getAll(payload.userId, payload.cartId).pipe(
        map((GiftCards: GiftCard[]) => {
          return new CustomGiftCardsListActions.LoadGiftCardsSuccess(GiftCards);
        }),
        catchError((error) =>
          of(new CustomGiftCardsListActions.LoadGiftCardsFail(error))
        )
      )
    ),
  ));

  loadGiftCard$: Observable<
   | CustomGiftCardsListActions.LoadGiftCardSuccess
   | CustomGiftCardsListActions.LoadGiftCardFail
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomGiftCardsListActions.CustomGiftCardsActionTypes.LOAD_GIFT_CARD),
    map((action: CustomGiftCardsListActions.LoadGiftCard) => {
      return action.payload;
    }),
    mergeMap((payload: { userId: string; cartId: string }) =>
      this.giftCardConnector.get(payload.userId, payload.cartId).pipe(
        map((giftCard: GiftCard) => {
          return new CustomGiftCardsListActions.LoadGiftCardSuccess(giftCard);
        }),
        catchError((error) =>
          of(new CustomGiftCardsListActions.LoadGiftCardFail(error))
        )
      )
    ),
  ));

  updateGiftCard$: Observable<
    | CustomGiftCardsListActions.SelectGiftCard
    | CustomGiftCardsListActions.UpdateGiftCardSuccess
    | CustomGiftCardsListActions.UpdateGiftCardFail
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomGiftCardsListActions.CustomGiftCardsActionTypes.UPDATE_GIFT_CARD),
    map((action: CustomGiftCardsListActions.UpdateGiftCard) => action.payload),
    switchMap((payload: { userId: string; cartId: string; giftCardCode: string; giftCardsMessage: string }) =>
      this.giftCardConnector
        .set(payload.userId, payload.cartId, payload.giftCardCode, payload.giftCardsMessage)
        .pipe(
          switchMap((response: GiftCard) => {
            return [
              new CustomGiftCardsListActions.SelectGiftCard({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
              new CustomGiftCardsListActions.UpdateGiftCardSuccess(response),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ];
          }),
          catchError((error) =>
            of(new CustomGiftCardsListActions.UpdateGiftCardFail(error))
          )
        )
    ),
  ));

  selectGiftCard$: Observable<
    |CustomGiftCardsListActions.SelectGiftCardSuccess
    | CustomGiftCardsListActions.SelectGiftCardFail
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomGiftCardsListActions.CustomGiftCardsActionTypes.SELECT_GIFT_CARD),
    map((action: CustomGiftCardsListActions.SelectGiftCard) => action.payload),
    mergeMap((payload: { userId: string; cartId: string; giftCardCode: string; giftCardsMessage }) =>
      this.giftCardConnector.get(payload.userId, payload.cartId).pipe(
        map((giftCard: GiftCard) => {
          return new CustomGiftCardsListActions.SelectGiftCardSuccess(giftCard);
        }),
        catchError((error) =>
          of(new CustomGiftCardsListActions.SelectGiftCardFail(error))
        ),
      )
    ),
  ));

  removeGiftCard$: Observable<
    | CustomGiftCardsListActions.RemoveGiftCardSuccess
    | CustomGiftCardsListActions.SelectGiftCard
    | CustomGiftCardsListActions.RemoveGiftCardFail
    | CustomGiftCardsListActions.LoadGiftCard
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomGiftCardsListActions.CustomGiftCardsActionTypes.REMOVE_GIFT_CARD),
    map((action: CustomGiftCardsListActions.RemoveGiftCard) => action.payload),
    mergeMap((payload: { userId: string; cartId: string }) =>
      this.giftCardConnector.delete(payload.userId, payload.cartId).pipe(
        switchMap((giftCard) => {
          return [
            new CustomGiftCardsListActions.RemoveGiftCardSuccess(giftCard),
            new CustomGiftCardsListActions.SelectGiftCard({
              userId: payload.userId,
              cartId: payload.cartId,
            }),
            new CartActions.LoadCart({
              userId: payload.userId,
              cartId: payload.cartId,
            }),
            new CustomGiftCardsListActions.LoadGiftCard({
              userId: payload.userId,
              cartId: payload.cartId,
            }),
          ];
        }),
        catchError((error) =>
          of(new CustomGiftCardsListActions.RemoveGiftCardFail(error))
        ),
      )
    ),
  ));
}

export const effectsGiftCard: any[] = [CustomGiftCardsEffects];
