import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RecaptchaAdapters } from './recaptcha-adapters';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaConnector {
  constructor(protected adapter: RecaptchaAdapters) {
  }

  validateRecaptcha(token: string): Observable<any> {
    return this.adapter.validateRecaptcha(token);
  }
}
