import { Observable } from 'rxjs';
import { CustomFriendsCheckoutModel } from '../facade/custom-friends-checkout.model';

export abstract class CustomFriendsCheckoutAdapter {

  abstract loadAll(userId: string, cartId: string): Observable<CustomFriendsCheckoutModel[]>;

  abstract load(userId: string, cartId: string): Observable<CustomFriendsCheckoutModel>;

  abstract add(userid: string, cartId: string, onBehalfOfUid: string): Observable<CustomFriendsCheckoutModel>;

  abstract delete(userId: string, cartId: string): Observable<{}>;

}
