import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { BillHistoryList, StateBills } from 'src/app/model/bill.model';
import { UserBillsActions } from '../actions';
import { USER_BILLS } from '../actions/custom-user-bills.action';

export const initialState: BillHistoryList = {
  invoiceList: [],
  pagination: {},
  sorts: [],
};

export function reducer(
  state = initialState,
  action: UserBillsActions.UserBillsAction
): BillHistoryList {
  switch (action.type) {
    case UserBillsActions.LOAD_USER_BILLS_SUCCESS: {
      return action.payload ? action.payload : initialState;
    }
    case UserBillsActions.LOAD_USER_BILLS_FAIL: {
      return initialState;
    }
  }

  return state;
}

export function getReducers(): ActionReducerMap<StateBills> {
  return {
    bills: StateUtils.loaderReducer<BillHistoryList>(USER_BILLS, reducer),
  };
}

export const reducerBillsToken: InjectionToken<ActionReducerMap<StateBills>> = new InjectionToken<
  ActionReducerMap<StateBills>
>('BillsReducers');

export const reducerBillsHistoryProvider = {
  provide: reducerBillsToken,
  useFactory: getReducers,
};
