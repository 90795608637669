<div role="document" class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <!-- <div #dialog> -->
      <!-- Modal Header -->
      <ng-container *ngIf="(loaded$ | async); else loading">
        <ng-container *ngIf="entry$ | async as entry; else notadded">
          <div class="cx-dialog-header modal-header">
            <div class="cx-dialog-title modal-title">
              {{
              ((addedEntryWasMerged$ | async)
              ? 'addToCart.itemsIncrementedInYourCart'
              : 'addToCart.itemsAddedToYourCart'
              ) | cxTranslate
              }}
            </div>
            <button type="button" class="close" aria-label="Close" (click)="dismissModal('Close')">
              <span aria-hidden="true">
                <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
              </span>
            </button>
          </div>
          <!-- Modal Body -->
          <div class="cx-dialog-body modal-body">
            <div class="cx-dialog-row">
              <div class="cx-dialog-item col-sm-12 col-md-6">
                <cx-cart-item [item]="entry" [compact]="true" [quantityControl]="getQuantityControl() | async"
                  [promotionLocation]="promotionLocation" [displayPromotion]="'modal'"></cx-cart-item>
              </div>
              <!-- Separator -->
              <div class="cx-dialog-separator col-sm-12 d-xs-block d-sm-block d-md-none"></div>
              <!-- Total container -->
              <div class="cx-dialog-actions col-sm-12 col-md-6">
                <div class="cx-dialog-total" *ngIf="cart$ | async as cart">
                  <div>
                    {{
                    'cartItems.cartTotal'
                    | cxTranslate: { count: cart.totalItems }
                    }}
                  </div>
                  <div>{{ cart.subTotal?.formattedValue }}</div>
                </div>
                <!-- Promotions -->
                <div class="cx-dialog-promotions" *ngIf="orderPromotions$ | async as orderPromotions">
                  <app-custom-promotions [promotions]="orderPromotions"></app-custom-promotions>
                </div>
                <div class="cx-dialog-promotions" *ngIf="orderAppliedPromotions$ | async as orderAppliedPromotions">
                  <app-custom-promotions class="cx-promotions-applied" [promotions]="orderAppliedPromotions"></app-custom-promotions>
                </div>
                <!-- Actions -->
                <div class="cx-dialog-buttons">
                  <a
                    [class.disabled]="form.dirty" 
                    [routerLink]="{ cxRoute: 'cart' } | cxUrl" 
                    cxModal="dismiss"
                    cxModalReason="View Cart click" 
                    class="btn btn-action mb-2" 
                    autofocus>{{ 'addToCart.viewCart' | cxTranslate }}
                  </a>
                  <a
                    [class.disabled]="form.dirty" 
                    [routerLink]="{ cxRoute: 'checkout' } | cxUrl" 
                    cxModal="dismiss"
                    cxModalReason="Proceed To Checkout click" 
                    class="btn btn-action">{{ 'addToCart.proceedToCheckout' | cxTranslate }}
                  </a>
                  <button [class.disabled]="form.dirty" (click)="dismissModal('Continue Shopping')"
                    class="btn btn-action mt-2">
                    {{ 'addToCart.continueShopping' | cxTranslate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #notadded>
          <div class="cx-dialog-header modal-header">
            <div class="cx-dialog-title modal-title">
              {{ 'addToCart.itemsNotAddedToYourCart' | cxTranslate }}
            </div>
            <button type="button" class="close" aria-label="Close" (click)="dismissModal('itemsNotAddedToYourCart')">
              <span aria-hidden="true">
                <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
              </span>
            </button>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #loading>
        <div class="cx-dialog-header modal-header">
          <div class="cx-dialog-title modal-title">
            {{ 'addToCart.updatingCart' | cxTranslate }}
          </div>
          <button type="button" class="close" aria-label="Close" (click)="dismissModal('updatingCart')">
            <span aria-hidden="true">
              <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
            </span>
          </button>
        </div>
        <!-- Modal Body -->
        <div class="cx-dialog-body modal-body">
          <div class="cx-dialog-row">
            <div class="col-sm-12"><cx-spinner></cx-spinner></div>
          </div>
        </div>
      </ng-template>
    <!-- </div> -->
  </div>
</div>