import { Observable } from 'rxjs';
import {
  CustomNewsCategoriesModel,
  CustomNewsDetailsModel,
  CustomNewsListModel,
  CustomNewsPaginationParams,
} from '../store/custom-news.model';
import { CustomNewsAdapter } from './custom-news.adapter';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomNewsConnector {
  constructor(protected adapter: CustomNewsAdapter) {
  }

  public getNewsList(params: CustomNewsPaginationParams): Observable<CustomNewsListModel> {
    return this.adapter.loadNewsList(params);
  }

  public getNewsDetails(
    newsId: string,
  ): Observable<CustomNewsDetailsModel> {
    return this.adapter.loadNewsDetails(newsId);
  }

  public getNewsCategories(): Observable<CustomNewsCategoriesModel> {
    return this.adapter.loadNewsCategories();
  }
}
