import { Injectable } from '@angular/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import {
  BaseSiteService,
  LanguageService,
  ProductService,
  RouterState,
  RoutingService,
  ScriptLoader,
  WindowRef,
  isNotUndefined,
} from '@spartacus/core';
import { Observable, Subject, Subscription, merge, of } from 'rxjs';
import {
  auditTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomOrderEntry } from 'src/app/spartacus/spartacus-features.module';
import { CustomPopupShippingService } from '../cms-components/user/popup-shipping/custom-popup-shipping.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Order, OrderFacade } from '@spartacus/order/root';
import {
  clearQueriesAndFragments,
  discountList,
  getBrandOrCategoryName,
  getExperienceName,
  getRioFrioBrands,
  mapAvailabilityZone,
  mapCartItems,
  mapPurchase,
  shouldTriggerPurchase,
} from './emarsys.utils';
import { ActionsSubject } from '@ngrx/store';
import { OrderDetailsService } from '@spartacus/order/components';
import { CustomBaseStoreModel } from '../cms-components/header/base-store/custom-base-store.model';

// The details for each command can be found on the file shared on OA-104

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let ScarabQueue: any;

@Injectable({
  providedIn: 'root',
})
export class EmarsysService {
  private subscription = new Subscription();
  private recommendedTrigger$ = new Subject<void>();
  /**
   * Emarsys minimal calm down time.
   * Need to reduce `Multiple calls of go command` error
   */
  private throttleTime = 1000;

  userEmail$ = this.userService.get().pipe(
    map((user) => user?.displayUid),
    distinctUntilChanged()
  );

  pageQuery$ = this.routingService.getRouterState().pipe(shareReplay(1));

  constructor(
    private winRef: WindowRef,
    private scriptService: ScriptLoader,
    private routingService: RoutingService,
    private languageService: LanguageService,
    private userService: UserAccountFacade,
    protected cartService: ActiveCartFacade,
    protected baseSiteService: BaseSiteService,
    private customPopupShippingService: CustomPopupShippingService,
    protected orderFacade: OrderFacade,
    private productService: ProductService,
    protected actions: ActionsSubject,
    protected orderDetailsService: OrderDetailsService

  ) {
    this.init();
  }

  init(): void {
    // Verify if window and other browser globals are available.
    if (!this.winRef.isBrowser()) {
      return;
    }

    // Embed the script.
    this.scriptService.embedScript({
      src: `//cdn.scarabresearch.com/js/${environment.emarsysConfig.merchantId}/scarab-v2.js`,
      attributes: {
        id: 'scarab-js-api',
      },
    });

    // Get current availability zone.
    const store$ = this.customPopupShippingService.getSelectedBaseStore().pipe(filter((store) => !!store));

    // Get cart entries.
    const cartEntries$ = this.getCartEntries().pipe(
      filter(isNotUndefined),
      distinctUntilChanged((prev, curr) => {
        const isSame = JSON.stringify(prev) === JSON.stringify(curr);
        return isSame;
      })
    );

    // Get Confirmed Order
    const orderConfirm$ = this.orderDetailsService.getOrderDetails().pipe(
      filter((order) => !!order))

    this.subscription.add(
      merge(cartEntries$, this.recommendedTrigger$, store$, this.pageQuery$, orderConfirm$)
        .pipe(
          auditTime(this.throttleTime),
          withLatestFrom(this.pageQuery$, store$, this.userEmail$,  cartEntries$, orderConfirm$, this.languageService.getActive())
        )
        .subscribe(([_, pageQuery, store, userEmail,  cartEntries, order, activeLanguage] : [unknown, RouterState, CustomBaseStoreModel, string, CustomOrderEntry[], Order, string]) => {
          // Only send test mode command if not in prod.
          if (environment.emarsysConfig.testMode) {
            this.pushTestMode();
          }
          
          // Push commands
          this.pushCart(cartEntries);
          this.pushLanguage(activeLanguage);
          this.pushAvailabilityZone(store?.code);

          if (userEmail) {
            this.pushEmail(userEmail);
          }
        
          if (pageQuery.state.params.productCode) {
            this.getProduct(pageQuery.state.params.productCode);
          } else if (pageQuery.state.params.query) {
            this.pushSearch(pageQuery.state.params.query);
          }
          
          const pageUrl = clearQueriesAndFragments(pageQuery.state.url)
          if (pageUrl.includes('category')) {
            const category = getBrandOrCategoryName(pageUrl, true);
            this.pushCategory(category);
          }
          
          if (store?.code?.includes('riofrio') && pageUrl) {
            // riofrio brand pages are content pages
            const riofrioBrand = getRioFrioBrands(pageUrl);
            if (riofrioBrand) {
              this.pushBrand(riofrioBrand, store?.code);
            }
          }
          // This should not be an else if, if the url has the path category and also is a brand should trigger both events.
          if (/\b(brand[_-]|marca[_-])/.test(pageUrl)) {
            const brand = getBrandOrCategoryName(pageUrl, false);
            if (brand) {
              this.pushBrand(brand, store?.code);
            }
          }
          
          if (pageUrl.includes('experiencia') || pageUrl.includes('visitas')) {
            const experience = getExperienceName(pageUrl);
            if (experience) {
              this.pushExperience(experience, store?.code);
            }
          }
          
          if(order && Object.keys(order).length > 0 && pageUrl.includes('order-confirmation')) {
            // Verify if the order code for the current store is saved in LS.
            // If it is, do not send the purchase code again
            // Workaround for avoid sending repeated purchase commands for the same order on refreshing the page
            const name = `${mapAvailabilityZone(store?.code)}-oid`
            if(shouldTriggerPurchase(name, order.code)) {
              this.pushPurchase(order);
              localStorage.setItem(name, order.code)
            }
          }

          this.go();
        })
    );
  }

  private getCartEntries(): Observable<CustomOrderEntry[] | undefined> {
    return this.routingService.getPageContext().pipe(
      switchMap((pageContext) => {
        if (!pageContext || !pageContext?.id) {
          return of(undefined);
        }
        return this.cartService.getActive().pipe(
          map((cart: Cart) => {
            return <CustomOrderEntry[]>cart?.entries || [];
          })
        );
      })
    );
  }

  private pushToSqarabQueue(item: any): void {
    if (typeof ScarabQueue !== 'undefined') {
      ScarabQueue?.push(item);
    }
  }

  private pushCart(entries: CustomOrderEntry[]): void {    
    if (entries?.length > 0) {
      this.pushToSqarabQueue(['cart', mapCartItems(entries)]);
    } else {
      this.pushToSqarabQueue(['cart', []]);
    }
  }

  private go(): void {
    this.pushToSqarabQueue(['go']);
  }

  private pushTestMode(): void {
    this.pushToSqarabQueue(['testMode']);
  }

  private pushLanguage(lang: string): void {
    this.pushToSqarabQueue(['language', lang]);
  }

  private pushAvailabilityZone(store: string): void {
    this.pushToSqarabQueue(['availabilityZone', mapAvailabilityZone(store)]);
  }

  private pushCategory(categoryId: string): void {
    this.pushToSqarabQueue(['category', categoryId]);
  }

  private pushBrand(categoryId: string, store: string): void {
    this.pushToSqarabQueue([
      'tag',
      'custom_marca',
      {
        NombreMarca: categoryId,
        Entorno: mapAvailabilityZone(store),
      },
    ]);
  }

  private getProduct(productCode: string): void {
    this.productService
      .get(productCode)
      .pipe(take(1))
      .subscribe((res) => {
        this.pushToSqarabQueue(['view', res.variantCode ?? res.code]);
      });
  }

  private pushSearch(searchTerm: string): void {
    this.pushToSqarabQueue(['searchTerm', searchTerm]);
    this.pushToSqarabQueue([
      'tag',
      'custom_busqueda',
      {
        TextoBusqueda: searchTerm,
      },
    ]);
  }

  private pushExperience(experience: string, store: string): void {
    this.pushToSqarabQueue([
      'tag',
      'custom_experiencias',
      {
        NombreExperiencia: experience,
        Entorno: mapAvailabilityZone(store),
      },
    ]);
  }

  private pushEmail(email: string): void {
    this.pushToSqarabQueue(['setEmail', email]);
  }

  private pushPurchase(order: Order): void {
    // Log data in non productive environments
    if (environment.emarsysConfig.testMode) {
      console.log('Purchase Push: ', ['purchase', mapPurchase(order)])
      console.log('Purchase Push: ', [
        'tag',
        'custom_compra',
        {
          CodigoCupon: discountList(order),
          DescuentoCupon: order.totalDiscounts.value,
          PrecioTotal: order.totalPrice.value,
        },
      ])
    }
    this.pushToSqarabQueue(['purchase', mapPurchase(order)]);
    this.pushToSqarabQueue([
      'tag',
      'custom_compra',
      {
        CodigoCupon: discountList(order),
        DescuentoCupon: order.totalDiscounts.value,
        PrecioTotal: order.totalPrice.value,
      },
    ]);
  }
}
