<div class="cx-review">
  <!-- TITLE -->
  <h3 class="cx-checkout-title d-none d-lg-block d-xl-block mb-5">
    {{ 'checkoutProgress.reviewOrder' | cxTranslate }}
  </h3>

  <div class="cx-review-summary row">
    <ng-container *ngIf="(steps$ | async).slice(0, -1) as steps">
      <div class="col-md-12 col-lg-6 col-xl-6 cx-review-payment-col">
        <ng-container *ngFor="let step of shippingSteps(steps)">
          <ng-container [ngSwitch]="step.type[0]">
            <ng-container *ngSwitchCase="checkoutStepType.SHIPPING_ADDRESS">
              <ng-container *ngTemplateOutlet="shippingAddress"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-6 cx-review-shipping-col">
        <ng-container *ngFor="let step of shippingSteps(steps)">
          <ng-container [ngSwitch]="step.type[0]">
            <ng-container *ngSwitchCase="checkoutStepType.DELIVERY_MODE">
              <ng-container *ngTemplateOutlet="deliveryMode"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let step of paymentSteps(steps)">
          <ng-container [ngSwitch]="step.type[0]">
            <ng-container *ngSwitchCase="checkoutStepType.PAYMENT_TYPE">
              <ng-container *ngTemplateOutlet="poNumber"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="checkoutStepType.PAYMENT_TYPE">
              <ng-container *ngTemplateOutlet="paymentType"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="checkoutStepType.PAYMENT_DETAILS">
              <ng-container *ngTemplateOutlet="paymentMethod"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="checkoutStepType.SHIPPING_ADDRESS">
              <ng-container *ngTemplateOutlet="costCenter"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>


  <!-- PAYMENT TYPE SECTION -->
  <ng-template #paymentType>
    <div class="cx-review-summary-card">
      <cx-card
        [content]="getPaymentTypeCard(paymentType$ | async) | async"
      ></cx-card>
    </div>
  </ng-template>

  <!-- COST CENTER SECTION -->
  <ng-template #costCenter>
    <ng-container *ngIf="isAccountPayment$ | async">
      <div class="cx-review-summary-card">
        <cx-card
          [content]="getCostCenterCard(costCenter$ | async) | async"
        ></cx-card>
      </div>
    </ng-container>
  </ng-template>

  <!-- SHIPPING ADDRESS SECTION -->
  <ng-template #shippingAddress>
    <div class="cx-review-summary-card cx-review-card-address">
      <cx-card
        *ngIf="deliveryAddress$ | async as deliveryAddress"
        [content]="
            getShippingAddressCard(deliveryAddress, countryName$ | async) | async
          "
      ></cx-card>
    </div>
  </ng-template>

  <!-- DELIVERY MODE SECTION -->
  <ng-template #deliveryMode>
    <div class="cx-review-summary-card cx-review-card-shipping">
      <cx-card
        *ngIf="deliveryMode$ | async as deliveryMode"
        [content]="getDeliveryModeCard(deliveryMode) | async"
      ></cx-card>
    </div>
  </ng-template>

  <!-- PAYMENT METHOD SECTION -->
  <ng-template #paymentMethod>
    <div class="cx-review-summary-card cx-review-card-payment">
      <div>
        <cx-card
          *ngIf="paymentDetails$ | async as paymentDetails"
          [content]="getPaymentMethodCard(paymentDetails) | async"
        ></cx-card>
      </div>
    </div>
  </ng-template>

  <!-- CART ITEM SECTION -->
  <ng-container *ngIf="cart$ | async as cart">
    <!-- ENTRY GROUP ITEM SECTION -->
    <ng-container *ngIf="orderEntryGroups$ | async as orderEntryGroups;">
      <ng-container *ngIf="orderEntryGroups.length > 1; else showEntries">
        <ng-container *ngFor="let group of orderEntryGroups; let indexEntry = index">
          <ng-container *ngIf="group?.entries">
            <div class="cart-details-wrapper">
              <h4 class="cx-review-cart-heading d-block">
                {{ 'checkoutReview.group' | cxTranslate: { current: (indexEntry + 1), total: orderEntryGroups.length } }}
              </h4>
              <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
                <!-- <cx-promotions [promotions]="orderPromotions"></cx-promotions> -->
                <app-custom-promotions [promotions]="orderPromotions"></app-custom-promotions>
              </ng-container>
              <ng-container *ngIf="orderAppliedPromotions$ | async as orderAppliedPromotions">
                <!-- <cx-promotions class="cx-promotions-applied" [promotions]="orderAppliedPromotions"></cx-promotions> -->
                <app-custom-promotions class="cx-promotions-applied" [promotions]="orderAppliedPromotions"></app-custom-promotions>
              </ng-container>
              <div class="cx-review-cart-item col-md-12">
                <cx-cart-item-list
                  [items]="group.entries"
                  [readonly]="false"
                  [promotionLocation]="promotionLocation"
                  [remove]="false"
                  [displayUnitSlim]="true"
                ></cx-cart-item-list>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #showEntries>
      <h4 class="cx-review-cart-heading d-block">
        {{ 'checkoutReview.products' | cxTranslate }}
      </h4>
      <div class="cx-review-cart-item col-md-12" *ngIf="entries$ | async as entries">
        <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
          <!-- <cx-promotions [promotions]="orderPromotions"></cx-promotions> -->
          <app-custom-promotions [promotions]="orderPromotions"></app-custom-promotions>
        </ng-container>
        <ng-container *ngIf="orderAppliedPromotions$ | async as orderAppliedPromotions">
          <!-- <cx-promotions class="cx-promotions-applied" [promotions]="orderAppliedPromotions"></cx-promotions> -->
          <app-custom-promotions class="cx-promotions-applied" [promotions]="orderAppliedPromotions"></app-custom-promotions>
        </ng-container>
        <cx-cart-item-list
          [items]="entries"
          [readonly]="false"
          [promotionLocation]="promotionLocation"
          [remove]="false"
          [displayUnitSlim]="true"
        ></cx-cart-item-list>
      </div>
    </ng-template>

    <app-custom-gift-cards-details></app-custom-gift-cards-details>
    <cx-place-order></cx-place-order>
  </ng-container>
</div>
