import { NgModule } from '@angular/core';
import { CommonModule  } from '@angular/common';
import { CustomMiniCartComponent } from './custom-mini-cart.component';
import { RouterModule } from '@angular/router';
import { ConfigModule, UrlModule} from '@spartacus/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CmsConfig } from '@spartacus/core';
import { CustomMiniCartPreviewModule } from 'src/app/custom/cms-components/cart/custom-mini-cart-preview/custom-mini-cart-preview.module';



@NgModule({
  declarations: [CustomMiniCartComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    CustomMiniCartPreviewModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: CustomMiniCartComponent,
       }
      }
    } as CmsConfig)

  ]
})
export class CustomMiniCartModule { }
