import { Injectable } from '@angular/core';
import {
  BaseSiteService,
  CanonicalUrlOptions,
  isNotUndefined,
  LanguageService,
  Product,
  ProductPageMetaResolver,
  ProductScope,
  ProductService,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CustomSemanticPathService } from 'src/app/custom/custom-localized-routing/custom-semantic-path.service';
import { CustomBasePageMetaResolver } from '../../cms/page/custom-base-page-meta.resolver';
import { PageHreflangResolver } from '../../cms/page/custom-page.resolvers';
import { HrefLangMeta } from '../../cms/page/model/custom-page.model';
import { CustomPageLinkService } from '../../cms/page/routing/custom-page-link.service';
import { CustomProductService } from '../facade/custom-product.service';

/**
 * Resolves the page data for the Product Detail Page
 * based on the `PageType.PRODUCT_PAGE`.
 *
 * The page title, heading, description, breadcrumbs and
 * first GALLERY image are resolved if available in the data.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomProductPageMetaResolver extends ProductPageMetaResolver implements PageHreflangResolver {
  producItem?;

  protected product$: Observable<Product> = this.routingService
    .getRouterState()
    .pipe(
      tap(state => {
        if (state.state.queryParams['item']) {
          this.producItem = state.state.queryParams['item'];
        } else {
          this.producItem = undefined;
        }
      }),
      map((state) => state.state.params['productCode']),
      filter((code) => !!code),
      switchMap((code) => this.productService.get(code, ProductScope.DETAILS)),
      filter(isNotUndefined)
    );

  constructor(
    routingService: RoutingService,
    productService: ProductService,
    translation: TranslationService,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    protected basePageMetaResolver: CustomBasePageMetaResolver,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    protected pageLinkService: CustomPageLinkService,
    protected languageService?: LanguageService,
    protected baseSiteService?: BaseSiteService,
    protected customProductService?: CustomProductService,
    protected customSemanticPathService?: CustomSemanticPathService,
  ) {
    super(
      routingService,
      productService,
      translation,
      basePageMetaResolver,
      pageLinkService,
    );
  };
  /**
   * Resolves the page title for the Product Detail Page. If metaTitle is not provided then
   * the page title is resolved with the product name, the first category and the manufacturer.
   * The page title used by the browser (history, tabs) and crawlers.
   */
  resolveTitle(): Observable<string> {
    return this.product$.pipe(
      switchMap((product) => {
        if (product.metaTitle) {
          return of(product.metaTitle);
        }
        let title = product.name;
        title += this.resolveFirstCategory(product);
        title += this.resolveManufacturer(product);
        return this.translation.translate('pageMetaResolver.product.title', {
          title,
        });
      })
    );
  }

  /**
   * Resolves the page description for the Product Detail Page. If metaDescription is not provided then
   * the description is based on the `product.summary`.
   */
  resolveDescription(): Observable<string> {
    return this.product$.pipe(
      switchMap((product) =>
        this.translation.translate('pageMetaResolver.product.description', {
          description: product.metaDescription ?? product.summary,
        })
      )
    );
  }

  resolveHrefLangs(options?: CanonicalUrlOptions): Observable<HrefLangMeta[]> {
    return this.product$.pipe(
      switchMap((product) => this.findBaseProduct(product)),
      map((product) => {
        const hrefLanguageInfo = this.basePageMetaResolver.getHrefLanguageInfo();
        let hrefLangMetas = [];
        hrefLanguageInfo.langs.forEach(lang => {
          let variantSlug = '';
          if (this.producItem || product.variantCode !== undefined && product.code !== product.variantCode) {
            variantSlug = this.producItem ? product.slug + `?item=${this.producItem}` :  product.slug
          } else {
            variantSlug = this.customProductService.getSlugByLang(product, lang.isocode)
          }
          const params = {
            ...product,
            slug: variantSlug,
          };
          this.basePageMetaResolver.addHrefLang(hrefLangMetas, params, lang.isocode, hrefLanguageInfo.activeLanguage, 'product');
          if (hrefLanguageInfo.defaultLang.isocode === lang.isocode) {
            this.basePageMetaResolver.addHrefLang(hrefLangMetas, this.producItem ? params : product, lang.isocode, hrefLanguageInfo.activeLanguage, 'product', true);
          }
        });
        if (this.producItem) {
          hrefLangMetas = hrefLangMetas.map((hrefLangMeta: HrefLangMeta) => {
            return {
              href: hrefLangMeta.href.replace('%3D', '=').replace('%3F', '?'),
              lang: hrefLangMeta.lang
            }
          })
        }
        return this.pageLinkService?.getHrefLangUrls({removeQueryParams: false}, hrefLangMetas);
      })
    );
  }
}
