import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  NotAuthGuard,
  UrlModule,
} from '@spartacus/core';
import { FormErrorsModule, PageSlotModule, SpinnerModule } from '@spartacus/storefront';
import { CustomEnabledCustomerRegisterGuard } from 'src/app/custom/config/guards/custom-enabled-customer-register.guard';
import { CustomRegisterComponent } from './custom-register.component';
import { RecaptchaModule } from 'src/app/spartacus/features/recaptcha/recaptcha.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    SpinnerModule,
    FormErrorsModule,
    PageSlotModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RegisterCustomerComponent: {
          component: CustomRegisterComponent,
          guards: [CustomEnabledCustomerRegisterGuard, NotAuthGuard],
        },
      },
    } as CmsConfig),
    RecaptchaModule,
  ],
  declarations: [CustomRegisterComponent],
})
export class CustomRegisterComponentModule {}
