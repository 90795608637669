import { Component, OnDestroy, ViewContainerRef } from '@angular/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Store } from '@ngrx/store';
import { RoutingService, StateWithUser, UserIdService } from '@spartacus/core';
import { StateWithProcess } from '@spartacus/core/src/process/store/process-state';
import { OrderCancellation } from '../store/actions/custom-order.actions';
import { filter, map, take } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { CustomOrderActionsService } from '../custom-order-actions.service';
import { Order } from '@spartacus/order/root';

@Component({
  selector: 'app-custom-order-cancellation',
  templateUrl: './custom-order-cancellation.component.html',
})
export class CustomOrderCancellationComponent implements OnDestroy {

  subscriptions = new Subscription();

  iconTypes = ICON_TYPE;
  loaded = true;
  order$: Observable<Order>;
  order: Order;
  redirectUrl: string;

  constructor(
    private modalService: LaunchDialogService,
    protected store: Store<StateWithUser | StateWithProcess<void>>,
    protected userService: UserIdService,
    protected customOrderActionsService: CustomOrderActionsService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected routingService: RoutingService,
  ) {
    this.subscriptions.add(
      this.modalService.data$.subscribe((data) => {
          this.order = data.order;
          this.order$ = of(data.order);
          this.redirectUrl = data.redirectUrl;
      })
    );
  }

  get isLoading$(): Observable<boolean> {
    return this.customOrderActionsService.getOrderActionsLoading();
  }

  dismissModal(reason: string): void {
    this.modalService.closeDialog(reason);
  }

  cancelOrder(data: any): void {
    this.subscriptions.add(
      this.userService
        .takeUserId()
        .pipe(take(1))
        .subscribe((userId: string) => {
          this.store.dispatch(new OrderCancellation({ userId, orderCode: data.code }));
        }),
    );
    this.dismissModal('cancelOrder');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
