import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomForgottenPasswordComponent } from './custom-forgotten-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nModule, CmsConfig, UrlModule } from '@spartacus/core';
import { FormErrorsModule, SpinnerModule} from '@spartacus/storefront';



@NgModule({
  declarations: [CustomForgottenPasswordComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ForgotPasswordComponent: {
          component: CustomForgottenPasswordComponent,
       }
      }
    } as CmsConfig)
  ]
})
export class CustomForgottenPasswordModule { }
