import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomCartEntryAdapter } from './custom-cart-entry.adapter';
import { CartEntryConnector } from '@spartacus/cart/base/core';
import { CartModification } from '@spartacus/cart/base/root';

@Injectable({
  providedIn: 'root',
})
export class CustomCartEntryConnector extends CartEntryConnector {
  constructor(protected adapter: CustomCartEntryAdapter) {
    super(adapter);
  }

  public addUnit(
    userId: string,
    cartId: string,
    productCode: string,
    quantity?: number,
    unitCode?: string,
  ): Observable<CartModification> {
    return this.adapter.addUnit(userId, cartId, productCode, quantity, unitCode);
  }
}
