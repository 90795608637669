import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { CustomOrderReturnService } from './custom-order-return.service';
import { OrderReturnGuard } from '@spartacus/order/components';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderReturnGuard extends OrderReturnGuard implements CanActivate {
  constructor(
    protected orderAmendService: CustomOrderReturnService,
    protected semanticPathService: SemanticPathService,
    protected router: Router,
  ) {
    super(orderAmendService, semanticPathService, router);
  }
}
