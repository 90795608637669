import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BadRequestHandler, GlobalMessageType } from '@spartacus/core';

const OAUTH_ENDPOINT = '/authorizationserver/oauth/token';

@Injectable({
  providedIn: 'root',
})
export class CustomBadRequestHandler extends BadRequestHandler {

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    super.handleError(request, response);
    this.handleSimulateCartError(request, response);
  }

  protected handleVoucherOperationError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter(
        (e) =>
          ['coupon.invalid.code.provided', 'coupon.not.active.expired'].includes(e.message) &&
          e.type === 'VoucherOperationError'
      )
      .forEach((e) => {
        this.globalMessageService.add(
          { key: e.message === 'coupon.invalid.code.provided' ? 'httpHandlers.invalidCodeProvided' : 'httpHandlers.notActiveCodeProvided' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
  }

  protected handleValidationError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => e.type === 'ValidationError')
      .forEach((error) => {
        if (error.reason !== 'invalid' && error.subject !== 'code') {
          this.globalMessageService.add(
            {
              key: `httpHandlers.validationErrors.${error.reason}.${error.subject}`,
            },
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      });
  }

  protected handleUnknownIdentifierError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => e.type === 'UnknownIdentifierError' || e.type === 'ValidationError')
      .forEach((error) => {
        if (error.message && !error.message.startsWith('Product with code') &&
          !error.message.includes('no es un valor válido para')
          && !error.message.includes('is not valid value for')
        ) {
          if (error.message.endsWith('is not a valid e-mail address!')) {
            this.globalMessageService.add({ key: 'common.invalidEmailErrorMessage' }, GlobalMessageType.MSG_TYPE_ERROR);
          } else if (error.subject === 'documentIdentifier' && response.url.includes('validateDocIdentifierAndTotalAmount')) {
            this.globalMessageService.add(
              { key: 'common.validateDocIdentifierAndTotalAmount' },
              GlobalMessageType.MSG_TYPE_ERROR
            );
          } 
          else {
            this.globalMessageService.add(
              error.message,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }
        }
      });
  }

  protected handleSimulateCartError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse): void {
      this.getErrors(response)
      .filter((e) => e.type === 'CartError')
      .forEach((error) => {
        if (error.message && !error.message.includes('Cannot simulate order') && !error.message.includes('Cart not found.')) {
          this.globalMessageService.add(
            error.message,
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      });
  }
}
