import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StateWithNews } from '../../store/custom-news.state';
import { CustomNewsListModel, CustomNewsModel, CustomNewsPaginationParams, NewsFilter } from '../../store/custom-news.model';
import { Observable } from 'rxjs';
import { getNewsSliderLoading, getNewsSlides } from '../../store/selectors/custom-news-slider.selector';
import { LoadNewsSlider } from '../../store/actions/custom-news-slider.actions';
import { CustomNewsConnector } from '../../connectors/custom-news.connector';

@Injectable({
  providedIn: 'root',
})
export class CustomNewsSliderService {

  constructor(
    protected store: Store<StateWithNews>,
    protected newsConnector?: CustomNewsConnector,
  ) {
  }

  getNewsSlides(): Observable<CustomNewsModel[]> {
    return this.store.pipe(select(getNewsSlides));
  }

  getNewsSliderLoading(): Observable<boolean> {
    return this.store.pipe(select(getNewsSliderLoading));
  }

  fetchNewsSlider(params: CustomNewsPaginationParams): void {
    this.store.dispatch(new LoadNewsSlider(params));
  }

  loadFilteredNews(params: CustomNewsPaginationParams): Observable<CustomNewsListModel> {
    return this.newsConnector.getNewsList(params);
  }
}
