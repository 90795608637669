import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Friend, FriendsFilter, MyFriendsList } from '../facade/friends.model';
import { CustomFriendsAdapter } from './friends.adapter';
import { CustomOcc } from '../../../occ/occ-models/custom-occ.models';
import CustomFriendsUserList = CustomOcc.CustomFriendsUserList;
import { EmailInformationModel } from '../../../../../../custom/cms-components/myaccount/custom-friends/components/custom-friend-email-form/CustomerEmail.model';

@Injectable({
  providedIn: 'root',
})
export class CustomFriendsConnector {
  constructor(protected adapter: CustomFriendsAdapter) {
  }

  getAll(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    friendsFilter?: FriendsFilter,
  ): Observable<MyFriendsList> {
    return this.adapter.loadAll(userId, pageSize, currentPage, friendsFilter);
  }

  add(userId: string, friend: Friend): Observable<Friend> {
    return this.adapter.add(userId, friend);
  }

  get(userId: string, uid: string): Observable<Friend> {
    return this.adapter.load(userId, uid);
  }

  addGuest(friendSignUp: Friend, token: string): Observable<Friend> {
    return this.adapter.addGuest(friendSignUp, token);
  }

  getGuest(token: string): Observable<any> {
    return this.adapter.getGuest(token);
  }


  update(userId: string, friend: Friend): Observable<{}> {
    return this.adapter.update(userId, friend);
  }

  invitedFriend(userId: string, friend: Friend): Observable<{}> {
    return this.adapter.invited(userId, friend);
  }

  delete(userId: string, uid: string): Observable<{}> {
    return this.adapter.delete(userId, uid);
  }

  sendEmail(userId: string, emailInformation: EmailInformationModel): Observable<any> {
    return this.adapter.sendEmail(userId, emailInformation);
  }
}
