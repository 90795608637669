import {
  CustomNewsCategoriesModel,
  CustomNewsDetailsModel,
  CustomNewsListModel,
  CustomNewsSliderModel,
} from './custom-news.model';
import { StateUtils } from '@spartacus/core';

export const NEWS_FEATURE = 'news';

export interface CustomNewsState {
  list: StateUtils.LoaderState<CustomNewsListModel>;
  categories: StateUtils.LoaderState<CustomNewsCategoriesModel>;
  details: StateUtils.LoaderState<CustomNewsDetailsModel>;
  slider: StateUtils.LoaderState<CustomNewsSliderModel>;
}

export interface StateWithNews {
  [NEWS_FEATURE]: CustomNewsState;
}
