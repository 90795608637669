import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UserIdService, WindowRef } from '@spartacus/core';
import { select, Store } from '@ngrx/store';
import { StateWithFriendsEmails } from './store/custom-friends-email.state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getAllSelectedFriendEmailsSuccess, getAllSelectedFriendEmailsValue } from './store/selector/custom-friends-email.selector';
import { Subscription } from 'rxjs';
import { ICON_TYPE } from '@spartacus/storefront';
import { SelectFriendEmails, SendFriendEmails } from './store/actions/custom-friends-email.action';
import { CustomerEmailModel } from './CustomerEmail.model';

@Component({
  selector: 'app-custom-friend-email-form',
  templateUrl: './custom-friend-email-form.component.html',
  styleUrls: ['./custom-friend-email-form.component.scss'],
})
export class CustomFriendEmailFormComponent implements OnInit, OnDestroy {
  @Output() readonly email: EventEmitter<boolean> = new EventEmitter<boolean>();
  appendProduct = null;
  emailForm: FormGroup;
  friendsList: Array<CustomerEmailModel>;
  subscription = new Subscription();
  iconTypes = ICON_TYPE;
  textInputCursorPosition = 1;
  clickProductAppend = 'string';
  editor: any;
  @ViewChild('Editor', { static: false })
  editorElementRef: ElementRef;

  constructor(
    protected fb: FormBuilder,
    protected store: Store<StateWithFriendsEmails>,
    protected userIdService: UserIdService,
    protected winRef: WindowRef,
  ) {
    this.emailForm = this.fb.group({
      toCustomersUid: ['', Validators.required],
      subject: ['', Validators.required],
      body: ['', Validators.required],
    });

    this.subscription.add(
      this.store.pipe(select(getAllSelectedFriendEmailsValue)).subscribe(CustomersEmailList => {
        const emailList = [];
        this.friendsList = CustomersEmailList;
        CustomersEmailList.map(CustomerEmailData => {
          emailList.push(CustomerEmailData.email);
        });
        this.emailForm.get('toCustomersUid').patchValue(emailList.join(','));
      }),
    );

    this.subscription.add(
      this.store.pipe(select(getAllSelectedFriendEmailsSuccess)).subscribe(success => {
        if (!this.friendsList?.length && success) {
          this.emailForm.reset();
          this.goBack();
        }
      }),
    );
  }

  removeEmail(index): void {
    const updatedFriendsList = [...this.friendsList];
    updatedFriendsList.splice(index, 1);
    this.store.dispatch(new SelectFriendEmails(updatedFriendsList));
  }

  ngOnInit(): void {}

  goBack(): void {
    this.email.emit(false);
  }

  sendEmails(): void {
    if (this.emailForm.valid) {
      this.emailForm.patchValue({ body: this.editor.root.innerHTML });
      this.userIdService
        .takeUserId()
        .subscribe((userId) => {
          const emailData = {
            userId, emailInformation: {
              ...this.emailForm.value,
              toCustomersUid: this.emailForm.value.toCustomersUid.split(','),
            },
          };
          this.store.dispatch(new SendFriendEmails(emailData));
        })
        .unsubscribe();
    } else {
      this.emailForm.markAllAsTouched();
    }
  }

  setAppendProduct({ text, url }): void {
    this.clickProductAppend = text;
    const delta = {
      ops: [],
    };
    if (!this.textInputCursorPosition) {
      delta.ops = [
        { insert: text, attributes: { link: url } },
        { insert: ' ' },
      ];
    } else {
      delta.ops = [
        { retain: this.textInputCursorPosition },
        { insert: ' ' },
        { insert: text, attributes: { link: url } },
        { insert: ' ' },
      ];
    }

    this.editor.updateContents(delta);
  }

  setEditor(event): void {
    this.editor = event;
  }

  updatePosition(event): void {
    if (event?.event === 'text-change' && this.clickProductAppend.length) {
      // add 2 to include after and before text's space
      this.textInputCursorPosition = this.textInputCursorPosition + this.clickProductAppend.length + 2;
      this.editor.setSelection(this.textInputCursorPosition);
      this.clickProductAppend = '';
    } else if (event?.eventName === 'selection-change' && event?.args.length && event.args?.[0]) {
      this.textInputCursorPosition = event.args[0].index;
    } else if (event?.range) {
      this.textInputCursorPosition = event?.range.index;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
