<ng-container *ngIf="cart$ | async as cart">
  <h2 class="page-title" [innerHTML]="cart.giftBoxProduct?.name">
      &nbsp;
  </h2>
  <p class="page-description" *ngIf="cart.giftBoxProduct?.description" [innerHTML]="cart.giftBoxProduct.description">
  </p>
  <button
    class="btn btn-send btn-remove-cart"
    (click)="deleteCart($event)"
  >
    {{ 'giftbox.header.btnDeleteCart' | cxTranslate }}
  </button>
</ng-container>
