import { DIALOG_TYPE, LayoutConfig, SpinnerComponent } from '@spartacus/storefront';

export const customOrderActionsSpinnerLayoutConfig: LayoutConfig = {
  launch: {
    ORDER_ACTIONS_SPINNER: {
      inlineRoot: true,
      component: SpinnerComponent,
      dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
    },
  },
};


