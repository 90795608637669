import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { CmsCustomNewsletterComponent } from '../custom-newsletters.model';
import { CustomNewslettersService } from '../custom-newsletters.service';

@Component({
  selector: 'app-custom-newsletter',
  templateUrl: './custom-newsletter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNewsletterComponent implements OnInit, OnDestroy {
  @Input() component: any;
  private subscriptions = new Subscription();
  protected busy$ = new BehaviorSubject(false);
  data$: Observable<CmsCustomNewsletterComponent>;
  isSubscribed = false;
  isUpdating$ = this.busy$;
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.maxLength(60), CustomValidators.emailValidator]),
    consentTemplateId: new FormControl(''),
    consentTemplateVersion: new FormControl(''),
    accept: new FormControl(false, Validators.requiredTrue),
  });
  enabled = true;
  widgetId: any;

  constructor(
    protected newsletterService: CustomNewslettersService,
    protected launchDialogService: LaunchDialogService
  ) {
  }

  ngOnInit(): void {   
    this.data$ = this.component.data$.pipe(
      tap((data: any) => {
        if (data && data.consentTemplate) {
          this.form.patchValue({
            consentTemplateId: data.consentTemplate.id,
            consentTemplateVersion: data.consentTemplate.version.toString()
          });
        }
      })
    );

    this.subscriptions.add(
      this.newsletterService
      .getUpdateNewsletterSuccess()
      .subscribe((success) => {
        if (success) {
          this.onSuccess();
        }
      }),
    );
    this.subscriptions.add(
      this.newsletterService.getUpdateNewsletterError()
      .subscribe((error) => {
        if (error) {
          this.onError();
        }
      }),
    );
  }

  onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.busy$.next(true);
    this.newsletterService.updateNewsletter(
      this.form.get('email').value,
      this.form.get('consentTemplateId').value,
      this.form.get('consentTemplateVersion').value,
      this.form.get('accept').value,
    );

    this.form.reset();

    this.ngOnInit();

    this.launchDialogService.closeDialog("Submit form")
  }

  protected onSuccess(): void {
    this.isSubscribed = true;
    this.busy$.next(false);
  }

  protected onError(): void {
    this.busy$.next(false);
    this.newsletterService.clearUpdateNewsletter();
  }

  getWidgetItemId(value): void {
    this.widgetId = value;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.newsletterService.clearUpdateNewsletter();
  }
}
