import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, Inject,
  Input, OnDestroy, PLATFORM_ID,
  ViewChild,
  HostBinding,
} from '@angular/core';
import { CarouselComponent, CarouselService } from '@spartacus/storefront';
import {interval, Subscription} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';
import {isPlatformBrowser} from "@angular/common";
import { CmsLinkComponent, OccEndpointsService } from '@spartacus/core';

@Component({
  selector: 'cx-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomCarouselComponent extends CarouselComponent implements AfterViewInit, OnDestroy {
  @Input() hideButtons = false;
  @Input() autoPlay = false;
  @Input() delay = 5000;
  @Input() loop = false;
  @Input() link: CmsLinkComponent;
  @Input() bgColor: string;
  itemsSize = 0;
  timer: any;
  @ViewChild('prev') private prev: ElementRef<HTMLElement>;
  @ViewChild('next') private next: ElementRef<HTMLElement>;
  @HostBinding('style.background') backgroundColor: string;
  subscription = new Subscription();
  stopPlaying = false;

  constructor(
    protected el: ElementRef,
    protected service: CarouselService,
    private cd: ChangeDetectorRef,
    protected occEndpointsService: OccEndpointsService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super(el, service);
  }

  ngAfterViewInit(): void {
    if (this.autoPlay) {
      this.loop = true;
      this.startInterval();
    }
    this.backgroundColor = this.bgColor;
  }

  startInterval(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription = this.size$.pipe(
        take(1),
        switchMap((size) =>
          interval(this.delay).pipe(
            tap(() => {
              if (!this.stopPlaying) {
                this.activeSlide =
                  this.activeSlide > this.items.length - size - 1
                    ? 0
                    : this.activeSlide + size;
                this.cd.markForCheck();
              }
            })
          )
        )
      ).subscribe();
    }
  }

  updateActiveSlide(activeSlide): void {
    this.pause();
    if (activeSlide + 1 > this.items.length) {
      this.activeSlide = 0;
    } else {
      this.activeSlide = activeSlide;
    }
  }

  get btnClass(): string {
    return 'btn btn-primary';
  }

  getTarget(target: string | boolean): string | null {
    return target === 'true' || target === true ? '_blank' : null;
  }

  getDownloadUrl(url: string): string {
    return this.occEndpointsService.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    }) + url;
  }

  play(): void {
    this.stopPlaying = false;
  }

  pause(): void {
    this.stopPlaying = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
