import { Component } from '@angular/core';
import { Friend } from '../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { User } from '@spartacus/core';

@Component({
  selector: 'app-custom-add-friend',
  templateUrl: './custom-my-account-friend-edit.component.html',
  styleUrls: ['./custom-my-account-friend-edit.component.scss'],
})
export class CustomMyAccountFriendEditFormComponent {
  isActive = false;
  friend: Friend;
  edit = false;
  user: User;
  email = false;

  setActive(value: boolean): void {
    this.isActive = value;
  }

  setFriend(friend: Friend): void {
    this.friend = friend;
  }

  setEdit(value: boolean): void {
    this.edit = value;
  }

  setUser(value: User): void {
    this.user = value;
  }

  setEmail(value: boolean): void {
    this.email = value;
  }
}
