<ng-container *ngIf="(data$ | async) as data">
  <div class="container">
    <div *ngIf="(data.media || data.banner?.media?.widescreen) && !data.youtubeVideo?.videoId" class="image">
      <cx-media [container]="data.banner?.media?.widescreen ? data.banner.media : data.media"></cx-media>
    </div>
    <div *ngIf="data.youtubeVideo?.videoId" class="image">
      <youtube-player
        #youtubePlayer
        [videoId]="data.youtubeVideo.videoId"
        [playerVars]="{'enablejsapi':1,'controls':0,'modestbranding':1,'playsinline':1,'showinfo':0,'rel':0}"
      ></youtube-player>
    </div>
    <div class="content">
      <h3 *ngIf="data.title" class="title">
        {{ data.title }}
      </h3>
      <h2 *ngIf="data.subtitle" class="subtitle">
        {{ data.subtitle }}
      </h2>
      <p *ngIf="data.description"
         class="description"
         [innerHTML]="data.description | htmlSanitize: 'html'"
      ></p>
      <div
        *ngIf="data.content?.content"
        class="paragraph"
        [class]="data.content.styleClasses"
        [innerHTML]="data.content.content | htmlSanitize: 'html'"
      ></div>
      <div class="custom-image-info-actions">
        <ng-container *ngTemplateOutlet="btn; context: { link: data }">
        </ng-container>
        <ng-container *ngIf="data.secondaryLink?.linkName">
          <ng-container [ngSwitch]="!!data?.secondaryLink?.media?.url">
            <ng-container *ngSwitchCase="true">
              <ng-container *ngTemplateOutlet="downloadMedia; context: { link: data.secondaryLink }">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="btn; context: { link: data.secondaryLink }">
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="buttons$ | async as buttons">
        <div class="row">
          <div class="col-12" *ngFor="let button of buttons">
            <ng-container *ngIf="button">
              <ng-container [ngSwitch]="!!button?.media?.url">
                <ng-container *ngSwitchCase="true">
                  <ng-container *ngTemplateOutlet="downloadMedia; context: { link: button }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <ng-container *ngTemplateOutlet="btn; context: { link: button }"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div class="col-12" *ngIf="data.youtubeVideo?.videoId">
        <div class="row">
          <button type="button" class="btn btn-send mb-2" (click)="playVideo()">
            {{ 'CustomVideoInfoComponent.buttonText' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #downloadMedia let-link="link">
  <ng-container *ngIf="link && link?.media?.url; else btn">
    <cx-generic-link
      [url]="getDownloadUrl(link.media.url)"
      [target]="'_blank'"
      [class]="btnClass"
    >
      {{link.linkName}}
    </cx-generic-link>
  </ng-container>
</ng-template>

<ng-template #btn let-link="link">
  <ng-container *ngIf="link && link.linkName !== ''">
    <cx-generic-link
      *ngIf="link.url"
      [url]="link.url"
      [target]="getTarget(link.target)"
      [class]="btnClass"
    >
      {{link.linkName}}
    </cx-generic-link>
    <cx-generic-link
      *ngIf="!link.url && link.category"
      [routerLink]="{ cxRoute: 'category', params: link.category } | cxUrl"
      [target]="getTarget(link.target)"
    >
      {{link.linkName}}
    </cx-generic-link>
    <cx-generic-link
      *ngIf="!link.url && !link.category && link.product"
      [routerLink]="{ cxRoute: 'product', params: link.product } | cxUrl"
      [target]="getTarget(link.target)"
    >
      {{link.linkName}}
    </cx-generic-link>
  </ng-container>
</ng-template>
