<h4>{{ 'orderCost.orderSummary' | cxTranslate }}</h4>

<div class="cx-summary-partials" *ngIf="cart">
    <div class="cx-summary-row">
        <div class="col-6 cx-summary-label">
            {{ 'orderCost.subtotal' | cxTranslate }}
        </div>
        <div class="col-6 cx-summary-amount">
            {{ cart.subTotal?.formattedValue }}
        </div>
    </div>
    <ng-container *ngIf="cart.giftCardPrice">
        <div class="cx-summary-row">
            <div class="col-6 cx-summary-label">
                {{
                    (cart.giftCardPrice?.formattedValue ? 'orderCost.giftCard' : 'orderCost.giftCard')
                        | cxTranslate
                }}
            </div>
            <div class="col-6 cx-summary-amount">
                {{
                    cart.giftCardPrice?.formattedValue
                        ? cart.giftCardPrice.formattedValue
                        : ('orderCost.toBeDetermined' | cxTranslate)
                }}
            </div>
        </div>
    </ng-container>
    <div class="cx-summary-row">
        <div class="col-6 cx-summary-label">
            {{
                (cart.deliveryCost?.formattedValue ? 'orderCost.shipping' : 'orderCost.estimatedShipping') | cxTranslate
            }}
        </div>
        <div class="col-6 cx-summary-amount">
            {{
                cart.deliveryCost?.formattedValue
                    ? cart.deliveryCost.formattedValue
                    : ('orderCost.toBeDetermined' | cxTranslate)
            }}
        </div>
    </div>
    <div class="cx-summary-row" *ngIf="cart.totalDiscounts?.value > 0">
        <div class="col-6 cx-summary-label">
            {{ 'orderCost.discount' | cxTranslate }}
        </div>
        <div class="col-6 cx-summary-amount">
            {{ cart.totalDiscounts?.formattedValue }}
        </div>
    </div>

  <div class="cx-summary-row" *ngIf="cart.net; else cartWithoutNet">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.salesTax' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.totalTax?.formattedValue }}
    </div>
  </div>

  <ng-template #cartWithoutNet>
    <div class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{
        cart.totalPriceWithTax?.value !== cart.totalPrice?.value
          ? ('orderCost.grossTax' | cxTranslate)
          : ('orderCost.grossIncludeTax' | cxTranslate)
        }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalTax?.formattedValue }}
      </div>
    </div>
  </ng-template>
    <div class="cx-summary-row cx-summary-total">
        <div class="col-6 cx-summary-label">
            {{ 'orderCost.total' | cxTranslate }}
        </div>
        <div class="col-6 cx-summary-amount">
            {{ cart.totalPriceWithTax?.formattedValue }}
        </div>
    </div>

</div>

<!-- <cx-promotions
  *ngIf="cart"
  [promotions]="cart.appliedOrderPromotions"
></cx-promotions>
-->

<ng-container *cxFeatureLevel="'1.3'">
    <cx-applied-coupons [vouchers]="cart?.appliedVouchers" [isReadOnly]="true"></cx-applied-coupons>
</ng-container>
