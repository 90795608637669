import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLoginComponent } from './custom-login.component';
import { ConfigModule, I18nModule, CmsConfig, UrlModule, provideDefaultConfig, DeferLoadingStrategy, provideConfig } from '@spartacus/core';
import { FormErrorsModule, IconModule, PageSlotModule, SpinnerModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomIconsModule } from '../../misc/icon/custom-icons.module';
import { CustomPopupShippingComponent } from '../../../../../custom/cms-components/user/popup-shipping/custom-popup-shipping.component';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import {  CustomAgeStatusModule } from '../../../../../custom/cms-components/header/age-status/custom-age-status.module';


@NgModule({
  declarations: [CustomLoginComponent, CustomPopupShippingComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    I18nModule,
    IconModule,
    RouterModule, UrlModule,
    PageSlotModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    FormErrorsModule,
    CustomIconsModule,
    SpinnerModule,
    // ConfigModule.withConfig({
    //   cmsComponents: {
    //     LoginComponent: {
    //       component: CustomLoginComponent,
    //     },
    //   },
    // } as CmsConfig),
    NgSelectModule,
    CustomAgeStatusModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        LoginComponent: {
          component: CustomLoginComponent,
          deferLoading: DeferLoadingStrategy.INSTANT,
        },
      },
    } as CmsConfig),
  ]
})
export class CustomLoginModule {}
