import {CxEvent} from "@spartacus/core";
import {EcommerceOrderRefund} from "./custom-order.model";

export class CustomOrderRefundEvent extends CxEvent {
  static readonly type = 'CustomOrderRefundEvent';
  event: string;
  ecommerce: EcommerceOrderRefund;
}

export class CustomReorderEvent extends CxEvent {
  static readonly type = 'CustomReorderEvent';
  event: string;
  button: string;
}
