import { Component, HostBinding, Input } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsInfoItem as model } from './info-item.model';
import { CMSBoxLinkImage } from '../custom-box-link-image/custom-box-link-image.model';

@Component({
  selector: 'app-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss'],
})
export class InfoItemComponent {

  @Input() item: model;
  @HostBinding('class') styleClasses: string;

  data$: Observable<model> = this.component.data$.pipe(
    tap((data) => {
      this.initComponent(data);
    }),
  );

  constructor(protected component: CmsComponentData<model>) {
  }

  get btnClass(): string {
    return 'btn btn-primary';
  }

  initComponent(item: model): void {
    this.styleClasses = item.styleClasses ?? '';
  }
}
