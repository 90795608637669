import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { CustomCartSharedModule } from '../cart-shared/custom-cart-shared.module';
import { CustomCartTotalsComponent } from './custom-cart-totals.component';
import {CustomPaypalInstallmentsModule} from "../../../../../custom/cms-components/paypal/custom-paypal-installments/custom-paypal-installments.module";
import { CartCouponModule } from '@spartacus/cart/base/components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    CustomCartSharedModule,
    I18nModule,
    CartCouponModule,
    CustomPaypalInstallmentsModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartTotalsComponent: {
          component: CustomCartTotalsComponent,
        },
      },
    }),
  ],
  declarations: [CustomCartTotalsComponent]
})
export class CustomCartTotalsModule {}
