import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomRowComponent } from './custom-row.component';
import { I18nModule } from '@spartacus/core';
import { IconModule, TruncateTextPopoverModule } from '@spartacus/storefront';
import { CustomIconsModule } from 'src/app/spartacus/custom/cms-components/misc/icon/custom-icons.module';



@NgModule({
  declarations: [CustomRowComponent],
  exports: [CustomRowComponent],
  imports: [
    CommonModule,
    I18nModule, IconModule, TruncateTextPopoverModule,
    CustomIconsModule,
  ]
})
export class CustomRowModule { }
