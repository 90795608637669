<h2>{{ "updatePasswordForm.changePassword" | cxTranslate }}</h2>

<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <label>
    <span class="label-content">{{
      "updatePasswordForm.oldPassword.label" | cxTranslate
    }}</span>
    <input
      class="form-control"
      type="password"
      name="oldPassword"
      formControlName="oldPassword"
    />
    <cx-form-errors [control]="form.get('oldPassword')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      "updatePasswordForm.newPassword.label" | cxTranslate
    }}</span>
    <input
      class="form-control"
      type="password"
      name="newPassword"
      formControlName="newPassword"
    />
    <cx-form-errors [control]="form.get('newPassword')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      "updatePasswordForm.confirmPassword.label" | cxTranslate
    }}</span>
    <input
      class="form-control"
      type="password"
      name="newPasswordConfirm"
      formControlName="newPasswordConfirm"
    />
    <cx-form-errors [control]="form.get('newPasswordConfirm')"></cx-form-errors>
  </label>
  <div class="text-align">
    <button class="btn btn-action" [disabled]="form.disabled">
      {{ "updatePasswordForm.changePassword" | cxTranslate }}
    </button>
  </div>
</form>
