import { Component, Input } from '@angular/core';
import { CmsHoverAwareImageComponent } from './custom-hover-aware-image.model';

@Component({
  selector: 'app-custom-hover-aware-image',
  templateUrl: './custom-hover-aware-image.component.html',
})
export class CustomHoverAwareImageComponent {
  @Input() rolloverImages: CmsHoverAwareImageComponent;

  getTarget(target: string | boolean): string | null {
    return target === 'true' || target === true ? '_blank' : null;
  }
}
