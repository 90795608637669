import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  SpinnerModule
} from '@spartacus/storefront';
import { CustomCardModule } from 'src/app/spartacus/custom/cms-structure/shared/components/custom-card/custom-card.module';
import { CustomIconsModule } from '../../../misc/icon/custom-icons.module';
import { CustomPaymentMethodComponent } from './custom-payment-method.component';
import { CustomPaymentFormModule } from './payment-form/custom-payment-form.module';
import { CustomPaymentTypeModalComponent } from './payment-type/custom-payment-type-modal.component';
import { CustomPaymentTypeModule } from './payment-type/custom-payment-type.module';
import { CartCouponModule } from '@spartacus/cart/base/components';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';


@NgModule({
  declarations: [CustomPaymentMethodComponent, CustomPaymentTypeModalComponent],
  exports: [CustomPaymentMethodComponent, CustomPaymentTypeModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    SpinnerModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    BrowserAnimationsModule,
    CartCouponModule,
    CustomIconsModule,
    CustomPaymentFormModule,
    CustomPaymentTypeModule,
    CustomCardModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutPaymentDetails: {
          component: CustomPaymentMethodComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class CustomPaymentMethodModule { }
