import { StateUtils } from '@spartacus/core';
import { CREDIT_CARD_LIST, CreditCard, Payments } from '../credit-card.state';
import { Action } from '@ngrx/store';

export const LOAD_CREDIT_CARD_LIST = '[Custom Checkout] Load Credit Card List';
export const LOAD_CREDIT_CARD_LIST_SUCCESS = '[Custom Checkout] Load Credit Card List Success';
export const LOAD_CREDIT_CARD_LIST_FAIL = '[Custom Checkout] Load Credit Card List Fail';
export const PURGE_CREDIT_CARD_LIST = '[Custom Checkout] Purge Credit Cart List';

export const SAVE_CREDIT_CARD = '[Custom Checkout] Save Credit Card';
export const SAVE_CREDIT_CARD_SUCCESS = '[Custom Checkout] Save Credit Card Success';
export const SAVE_CREDIT_CARD_FAIL = '[Custom Checkout] Save Credit Card Fail';

export const SELECT_SAVED_CREDIT_CARD = '[Custom Checkout] Select Credit Card';
export const SELECT_SAVED_CREDIT_CARD_SUCCESS = '[Custom Checkout] Select Credit Card Success';
export const SELECT_SAVED_CREDIT_CARD_FAIL = '[Custom Checkout] Select Credit Card Fail';

export const REMOVE_SELECTED_CREDIT_CARD = '[Custom Checkout] Remove Selected Credit Card';
export const REMOVE_SELECTED_CREDIT_CARD_SUCCESS = '[Custom Checkout] Remove Selected Credit Card Success';
export const REMOVE_SELECTED_CREDIT_CARD_FAIL = '[Custom Checkout] Remove Selected Credit Card Fail';

export class LoadCreditCardList extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_CREDIT_CARD_LIST;

  constructor(public payload: string) {
    super(CREDIT_CARD_LIST);
  }
}

export class LoadCreditCardListSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_CREDIT_CARD_LIST_SUCCESS;

  constructor(public payload: Payments) {
    super(CREDIT_CARD_LIST);
  }
}

export class LoadCreditCardListFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_CREDIT_CARD_LIST_FAIL;

  constructor(public payload: any) {
    super(CREDIT_CARD_LIST, payload);
  }
}

export class PurgeCreditCardList extends StateUtils.LoaderResetAction {
  readonly type = PURGE_CREDIT_CARD_LIST;

  constructor() {
    super(CREDIT_CARD_LIST);
  }
}

export class SaveCreditCard implements Action {
  readonly type = SAVE_CREDIT_CARD;

  constructor(public payload: { save: boolean }) {}
}

export class SaveCreditCardSuccess implements Action {
  readonly type = SAVE_CREDIT_CARD_SUCCESS;

  constructor(public payload: boolean) {}
}

export class SaveCreditCardFail implements Action {
  readonly type = SAVE_CREDIT_CARD_FAIL;

  constructor(public payload: boolean) {}
}


export class SelectedSavedCreditCard implements Action {
  readonly type = SELECT_SAVED_CREDIT_CARD;

  constructor(public payload: { userId: string, cartId: string, paymentType: string, paymentDetailsId: string }) {}
}

export class SelectedSavedCreditCardSuccess implements Action {
  readonly type = SELECT_SAVED_CREDIT_CARD_SUCCESS;

  constructor(public payload: CreditCard) {}
}

export class SelectedSavedCreditCardFail implements Action {
  readonly type = SELECT_SAVED_CREDIT_CARD_FAIL;

  constructor(public payload: any) {}
}


export class RemoveSelectedCreditCard extends StateUtils.LoaderLoadAction {
  readonly type = REMOVE_SELECTED_CREDIT_CARD;

  constructor(public payload: { userId: string, cartId: string, paymentType: string, paymentDetailsId: string }) {
    super(CREDIT_CARD_LIST);
  }
}

export class RemoveSelectedCreditCardSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = REMOVE_SELECTED_CREDIT_CARD_SUCCESS;

  constructor(public payload: CreditCard) {
    super(CREDIT_CARD_LIST);
  }
}

export class RemoveSelectedCreditCardFail extends StateUtils.LoaderFailAction {
  readonly type = REMOVE_SELECTED_CREDIT_CARD_FAIL;

  constructor(public payload: any) {
    super(CREDIT_CARD_LIST, payload);
  }
}

export type CustomCreditCardActions =
  | LoadCreditCardList
  | LoadCreditCardListSuccess
  | LoadCreditCardListFail
  | PurgeCreditCardList
  | SaveCreditCard
  | SaveCreditCardSuccess
  | SaveCreditCardFail
  | SelectedSavedCreditCard
  | SelectedSavedCreditCardSuccess
  | SelectedSavedCreditCardFail
  | RemoveSelectedCreditCard
  | RemoveSelectedCreditCardSuccess
  | RemoveSelectedCreditCardFail;
