<ng-container *ngIf="data$ | async as component">
  <div [class]="styleClasses + ' cx-section'">
    <app-custom-my-friends-filter
      (add)="addFriend($event)"
      (addFriend)="activeAddFriend()"
      (openEmailComposer)="showEmailComposer($event)"
      [selectedFriendsEmails]="selectedFriendsEmails"
      [showEmailFeature]="showEmailFeature"
    ></app-custom-my-friends-filter>
    <div class="mt-3">
      <ng-container *ngIf="friendList$ | async as friends">
        <div class="custom-list-filter" *ngIf="displayFilter">
          <div class="form-group">
            <label>
                <span class="label-content">
                  {{ 'myFriends.filterTextSearch' | cxTranslate }}
                </span>
              <input class="form-control"
                     type="text"
                     [formControl]="filtersForm.get('textSearch')" />
            </label>
            <button
              class="btn btn-default"
              (click)="removeFilter(['textSearch'], false)"
              [disabled]="!filtersForm.get('textSearch').value">
              {{ 'myFriends.filterRemove' | cxTranslate }}
            </button>
          </div>
          <div class="form-group filter-specific">
            <label>
                <span class="label-content">
                  {{ 'myFriends.filterInviteStatus' | cxTranslate }}
                </span>
              <cx-sorting
                class="normalize-width"
                [sortOptions]="sortFilterInviteStatus"
                [sortLabels]="getFilterInviteStatusLabels() | async"
                (sortListEvent)="updateSelectFilter('inviteStatus',$event)"
                [control]="filtersForm.get('inviteStatus')"
                selectedOption=""
                placeholder="{{
                    'myFriends.filterInviteStatusPlaceholder' | cxTranslate
                  }}"
              ></cx-sorting>
            </label>
          </div>
        </div>
        <ng-container *ngIf="friends.pagination?.totalResults > 0; else noFriends">
          <form [formGroup]="selectFriendsEmailForm">
            <!-- TABLE -->
            <table class="table table-main">
              <thead class="">
              <th scope="col" *ngIf="showEmailFeature">
                <div class="form-group">
                  <label>
                    <input
                      formControlName="selectAll"
                      class="scaled-input form-check-input"
                      type="checkbox"
                      value="all"
                      (change)="selectAll($event)"
                      [checked]="formArray?.length === totalResults"
                      [indeterminate]="formArray?.length > 0 && formArray?.length < totalResults"
                      [disabled]="!loadingFriends"
                    />
                    <span class="form-check-label"></span>
                  </label>
                </div>
              </th>
              <th scope="col">
                {{ 'myFriends.myFriendsForm.name' | cxTranslate }}
              </th>
              <th scope="col">{{ 'myFriends.myFriendsForm.email' | cxTranslate }}</th>
              <th scope="col">
                {{ 'myFriends.myFriendsForm.invitationStatus' | cxTranslate }}
              </th>

              <th *ngIf="showIBAN()" scope="col">{{ 'myFriends.myFriendsForm.iban' | cxTranslate }}</th>
              <th scope="col"></th>
              </thead>
              <tbody>
              <tr *ngFor="let friend of friends.members; let i = index">
                <td *ngIf="showEmailFeature">
                  <div class="form-group">
                    <label formArrayName="friendsList">
                      <input
                        class="scaled-input form-check-input"
                        type="checkbox"
                        (change)="onCheckChange($event, friend)"
                        [checked]="verifyCheck(friend.uid)"
                        [disabled]="!loadingFriends"
                      />
                      <span class="form-check-label"></span>
                    </label>
                  </div>
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'myFriends.myFriendsForm.name' | cxTranslate }}
                  </div>
                  {{ friend?.firstName }}   {{ friend?.lastName }}
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'myFriends.myFriendsForm.email' | cxTranslate }}
                  </div>
                  {{friend?.inviteStatus === unknownEmail ? '' : friend?.uid }}
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'myFriends.myFriendsForm.invitationStatus' | cxTranslate }}
                  </div>
                  <ng-container *ngIf="friend?.inviteStatus === unknownEmail; else defaultEmail">
                                <span class="text-danger font-weight-bold">
                                  {{ invitationStatus(friend?.inviteStatus) | cxTranslate }}
                                </span>
                  </ng-container>
                  <ng-template #defaultEmail>
                    {{ invitationStatus(friend?.inviteStatus) | cxTranslate }}
                  </ng-template>
                </td>
                <td *ngIf="showIBAN()">
                  <div class="table-main-label">
                    {{ 'myFriends.myFriendsForm.iban' | cxTranslate }}
                  </div>
                  {{ friend?.bankAccountNumber }}
                </td>
                <td class="table-main-actions ">
                  <button
                    *ngIf="activeSendAction(friend)"
                    type="button"
                    class="btn btn-icon"
                    [title]="'myFriends.inviteFriend' | cxTranslate"
                    (click)="sendInvitation(friend)"
                  >
                    <app-custom-icons class="icon-location"></app-custom-icons>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    [title]="'orderHistory.cancelOrder' | cxTranslate"
                    (click)="openDialog(friend)"
                  >
                    <app-custom-icons [type]="iconTypes.TRASH"></app-custom-icons>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </form>
          <div class="table-main-bottom row">
            <div
              *ngIf="friends.pagination.totalPages > 1"
              class="table-main-pagination"
            >
              <cx-pagination
                [pagination]="friends.pagination"
                (viewPageEvent)="pageChange($event,friends.pagination.pageSize)"
              ></cx-pagination>
            </div>
          </div>
        </ng-container>
        <ng-template #noFriends>
          <div
            class="custom-list-filter-no-order"
            *ngIf="friends.pagination?.totalResults === 0"
          >
            {{ 'myFriends.notFound' | cxTranslate }}
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loading>
        <div class="cx-spinner">
          <cx-spinner></cx-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
