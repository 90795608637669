import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { StateWithBaseStore } from '../../header/base-store/store/custom-base-store.state';
import {StateWithProcess, UserIdService, WindowRef} from '@spartacus/core';
import { getBaseStoreList, getBaseStoreSelected } from '../../header/base-store/store/selectors/custom-base-store.selectors';
import { CustomBaseStoreModel } from '../../header/base-store/custom-base-store.model';
import { LoadBaseStoreList, PurgeBaseStoreList, UpdateBaseStore } from '../../header/base-store/store/actions/custom-base-store.actions';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CustomPopupShippingService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private http: HttpClient,
    private store: Store<StateWithBaseStore | StateWithProcess<void>>,
    private userService: UserIdService,
    protected winRef?: WindowRef,
  ) {}

  loadBaseStores(): void {
    this.subscriptions.add(
      this.userService
        .takeUserId()
        .pipe(take(1))
        .subscribe((userId: string) => {
          if (userId) {
            this.store.dispatch(new LoadBaseStoreList({ userId }));
          }
        }),
    );
  }

  getBaseStores(): Observable<CustomBaseStoreModel[]> {
    return this.store.pipe(select(getBaseStoreList));
  }

  getSelectedBaseStore(): Observable<CustomBaseStoreModel> {
    return this.store.pipe(select(getBaseStoreSelected));
  }

  updateSelectedBaseStore(baseStoreData): void {
    this.subscriptions.add(
      this.userService
        .takeUserId()
        .pipe(take(1))
        .subscribe((userId: string) => {
          this.store.dispatch(new UpdateBaseStore(baseStoreData, userId));
        }),
    );
  }

  purgeBaseStores(): void {
    this.store.dispatch(new PurgeBaseStoreList());
    this.winRef?.localStorage?.removeItem('currentbasestore');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
