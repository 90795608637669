<div
  *ngIf="content"
  class="cx-card"
  [class.cx-card-border]="border"
  [class.cx-card-fit-to-container]="fitToContainer"
>

  <div class="cx-card-body" [class.cx-card-delete]="editMode">
    <!-- Edit message -->
    <div *ngIf="editMode" class="cx-card-delete-msg">
      {{ content.deleteMsg }}
    </div>


    <div class="cx-card-container">

      <div class="cx-card-label-container">
        <div *ngIf="content.textBold" class="cx-card-label-bold">
          {{ content.textBold }}
        </div>
        <ng-container *ngFor="let line of content.text">
          <div class="cx-card-label">
            {{ line }}
          </div>
        </ng-container>
        <div *ngIf="content.actions && !editMode" class="cx-card-actions">
          <div *ngFor="let action of content.actions">
            <ng-container *ngIf="isDefault">
              <div [ngSwitch]="action.event">
                <a
                  *ngSwitchCase="'delete'"
                  class="cx-card-link card-link btn-link cx-action-link"
                  (click)="delete()"
                  (keydown.enter)="delete()"
                  tabindex="0"
                  ><span class="action-name">{{ action.name }}</span> <app-custom-icons class="action-icon" [type]="iconTypes.TRASH"></app-custom-icons></a
                >
                <a
                  *ngSwitchCase="'edit'"
                  class="cx-card-link card-link btn-link cx-action-link btn-remove"
                  (click)="edit()"
                  (keydown.enter)="edit()"
                  tabindex="0"
                  ><span class="action-name">{{ action.name }}</span><app-custom-icons class="action-icon" [type]="iconTypes.PENCIL"></app-custom-icons></a
                >
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

  
    <div *ngIf="editMode" class="row cx-card-body-delete">
      <div class="col-md-6">
        <button class="btn btn-block btn-secondary" (click)="cancelEdit()">
          {{ 'common.cancel' | cxTranslate }}
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-block btn-primary" (click)="delete()">
          {{ 'common.delete' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>

