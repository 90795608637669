import { GiftCard } from '../model/custom-gift-card.model';

export const GIFT_CARD_FEATURE = 'giftCard';
export const GIFT_CARD = '[Gift Cards] Gift Cards';

export interface StateWithGiftCards {
  [GIFT_CARD_FEATURE]: CustomGiftCardsListState;
}

export interface CustomGiftCardsListState {
  giftCards?: GiftCard[];
  selected?: GiftCard;
}
