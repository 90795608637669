import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductCategoriesComponent } from './custom-product-categories.component';
import { OutletModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomProductCategoriesComponent],
  imports: [
    CommonModule,
    OutletModule,
    I18nModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomProductCategoriesComponent: {
          component: CustomProductCategoriesComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomProductCategoriesModule { }
