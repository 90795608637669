import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDividerComponent } from './custom-divider.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { MediaModule } from '@spartacus/storefront';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';

@NgModule({
  declarations: [CustomDividerComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    MediaModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomDividerComponent: {
          component: CustomDividerComponent
        },
      }
    } as CmsConfig)
  ]
})
export class CustomDividerModule { }
