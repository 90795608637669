<ng-container *ngIf="data$ | async as data">
  <h2 class="title">
    {{ data?.title }}
  </h2>
  <div
    class="content"
    [class.container]="addContainer"
  >
    <ng-container *ngFor="let item of items$ | async">
      <app-custom-box-link-image-item
        [item]="item"
      ></app-custom-box-link-image-item>
    </ng-container>
  </div>
</ng-container>
