<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="userLoggedIn$ | async; else loginPrompt">
    <ng-container *ngIf="wishListEntries$ | async as entries">
      <div
        *ngIf="getProductInWishList(product, entries) as entry; else addItem"
      >
        <button
          class="btn btn-link"
          (click)="remove(product)"
          [disabled]="!loading$ | async"
        >
          <app-custom-icons [type]="iconTypes.HEART"></app-custom-icons>
          <span class="button-text">
            {{ 'addToWishList.remove' | cxTranslate }}
          </span>
        </button>
      </div>
      <ng-template #addItem>
        <button
          class="btn btn-link"
          (click)="add(product)"
          [disabled]="!loading$ | async"
        >
          <app-custom-icons [type]="iconTypes.EMPTY_HEART"></app-custom-icons>
          <span class="button-text">
            {{ 'addToWishList.add' | cxTranslate }}
          </span>
        </button>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loginPrompt>
  <a
    class="btn btn-link"
    [routerLink]="{ cxRoute: 'login' } | cxUrl"
  >
    <app-custom-icons [type]="iconTypes.EMPTY_HEART"></app-custom-icons>
    <span class="button-text">
      {{ 'addToWishList.anonymous' | cxTranslate }}
    </span>
  </a>
</ng-template>
