import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCarouselComponent } from './custom-carousel.component';
import { IconModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';



@NgModule({
  declarations: [CustomCarouselComponent],
  imports: [
    CommonModule,
    IconModule,
    CustomGenericLinkModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CarouselComponent: {
          component : CustomCarouselComponent
        }
      }
    }as CmsConfig)
  ],
  exports: [CustomCarouselComponent],
  entryComponents: [CustomCarouselComponent]
})
export class CustomCarouselModule { }
