import { Component, Input } from '@angular/core';
import { CardComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss']
})
export class CustomCardComponent extends CardComponent  {
  @Input() showAsRadioOption: boolean;
  @Input() formControlName: string;
  @Input() showActionLabel: string;
  @Input() hideRadioButton: boolean;
}
