import { AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { RoutingService, EventService } from '@spartacus/core';
import { Cart } from '@spartacus/cart/base/root';
import { LaunchDialogService } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ProductReferenceTypeEnum } from 'src/app/model/product.model';
import { CustomValidateCartService } from 'src/app/services/cart/validate-cart.service';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CUSTOM_LAUNCH_CALLER } from 'src/app/spartacus/spartacus-custom.module';
import { CustomDeleteCartSuccessEvent } from 'src/app/spartacus/features/tracking/custom-events/cart/custom-cart.events';
import {CustomGiftBoxService} from "../services/custom-giftbox.service";
import { CustomOrderEntry } from 'src/app/spartacus/custom/feature-libs/cart/root/models/cart.model';

@Component({
  selector: 'app-custom-giftbox-cart',
  templateUrl: './custom-giftbox-cart.component.html',
})
export class CustomGiftboxCartComponent implements OnInit, AfterViewInit, OnDestroy {
  cart$: Observable<Cart>;
  entries$: Observable<CustomOrderEntry[]>;
  ProductReferenceTypeEnum = ProductReferenceTypeEnum;
  lastFilledNumberOfSlots: number;
  itemsAdded = false;
  isRemovingItem = false;
  subscription: Subscription = new Subscription();
  validatingCart: void | Observable<ComponentRef<any>>;

  constructor(
    protected activeCartService: CustomActiveCartService,
    protected customValidateCartService: CustomValidateCartService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected eventService: EventService,
    protected routingService?: RoutingService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.eventService.get(CustomDeleteCartSuccessEvent)
        .subscribe((success) => {
          if (success) {
            this.routingService.go({ cxRoute: 'giftbox' });
          }
        })
    );
    this.cart$ = this.activeCartService.getActive().pipe(
      distinctUntilChanged(),
      filter((cart) => {
          const isGiftBoxProduct = !!cart.giftBoxProduct;
          if (isGiftBoxProduct) {
            this.itemsAdded = this.lastFilledNumberOfSlots !== undefined && cart.filledNumberOfSlots > this.lastFilledNumberOfSlots;
            this.lastFilledNumberOfSlots = cart.filledNumberOfSlots;
            this.isRemovingItem = false;
          }
          return isGiftBoxProduct;
        }
      ),
    );
    this.entries$ = this.activeCartService.getEntries();
  }

  ngAfterViewInit(): void {
    this.customValidateCartService.validateCart();
    this.subscription.add(
      combineLatest([
        this.customValidateCartService.getValidateCartLoading(),
        this.customValidateCartService.getValidateCartSuccess(),
        this.customValidateCartService.getValidateCartError(),
      ]).subscribe(([validateCartLoading, validateCartSuccess, validateCartError]) => {
        if (validateCartLoading) {
          if (!this.validatingCart) {
            this.validatingCart = this.launchDialogService.launch(
              CUSTOM_LAUNCH_CALLER.VALIDATE_CART_SPINNER,
              this.vcr,
            );
          }
        }
        if (validateCartError) {
          this.routingService.go({ cxRoute: 'giftbox' });
        }
        if (validateCartSuccess) {
          this.clearValidateActions();
        }
      }),
    );
  }

  clearValidateActions(): void {
    if (this.validatingCart) {
      this.validatingCart
        .subscribe((component) => {
          this.launchDialogService.clear(
            CUSTOM_LAUNCH_CALLER.VALIDATE_CART_SPINNER,
          );
          component.destroy();
        })
        .unsubscribe();
      this.customValidateCartService.clearValidateCart();
    }
  }

  removeItem(entryNumber: number, quantity: number): void {
    this.isRemovingItem = true;
    this.activeCartService.updateEntry(
      entryNumber,
      quantity - 1
    );
  }

  counter(i: number): number[] {
    return new Array(i >= 0 ? i : 0);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.clearValidateActions();
  }
}
