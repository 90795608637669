import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { FRIENDS_CHECKOUT_ID } from '../custom-friends-checkout.state';
import { CustomFriendsCheckoutModel } from '../../facade/custom-friends-checkout.model';
import { FRIENDS } from '../../../store/custom-friends-checkout.state';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  RESET_LOAD_FRIENDS_CHECKOUT_CANDIDATES = '[Friends Checkout] Reset Friends Checkout Candidates ',
  LOAD_FRIENDS_CHECKOUT_CANDIDATES = '[Friends Checkout] Load Friends Checkout Candidates ',
  LOAD_FRIENDS_CHECKOUT_CANDIDATES_SUCCESS = '[Friends Checkout] Load Friends Checkout Candidates Success',
  LOAD_FRIENDS_CHECKOUT_CANDIDATES_FAIL = '[Friends Checkout] Load Friends Checkout Candidates Fail',

  LOAD_FRIENDS_CHECKOUT = '[Friends Checkout] Load Friends Checkout',
  LOAD_FRIENDS_CHECKOUT_SUCCESS = '[Friends Checkout] Load Friends Checkout Success',
  LOAD_FRIENDS_CHECKOUT_FAIL = '[Friends Checkout] Load Friends Checkout Fail',

  SELECT_FRIENDS_CHECKOUT = '[Friends Checkout] Select Friends Checkout',
  SELECT_FRIENDS_CHECKOUT_FAIL = '[Friends Checkout] Select Friends Checkout Fail',
  SELECT_FRIENDS_CHECKOUT_SUCCESS = '[Friends Checkout] Select Friends Checkout Success',

  UPDATE_FRIENDS_CHECKOUT = '[Friends Checkout] Update Friends Checkout',
  UPDATE_FRIENDS_CHECKOUT_FAIL = '[Friends Checkout] Update Friends Checkout Fail',
  UPDATE_FRIENDS_CHECKOUT_SUCCESS = '[Friends Checkout] Update Friends Checkout Success',

  REMOVE_FRIENDS_CHECKOUT = '[Friends Checkout] Remove Friends Checkout',
  REMOVE_FRIENDS_CHECKOUT_FAIL = '[Friends Checkout] Remove Friends Checkout Fail',
  REMOVE_FRIENDS_CHECKOUT_SUCCESS = '[Friends Checkout] Remove Friends Checkout Success',

  PURGE_FRIENDS_CHECKOUT_SUCCESS = '[Friends Checkout] Purge Friends Checkout Success'
}


export class ResetLoadFriendsCheckoutCandidates extends StateUtils.EntityLoaderResetAction {
  readonly type = ActionTypes.RESET_LOAD_FRIENDS_CHECKOUT_CANDIDATES;
  constructor() {
    super(PROCESS_FEATURE, FRIENDS_CHECKOUT_ID);
  }
}

export class LoadFriendsCheckoutCandidates extends StateUtils.EntityLoadAction {
  readonly type = ActionTypes.LOAD_FRIENDS_CHECKOUT_CANDIDATES;

  constructor(public payload: {userId: string, cartId: string}) {
    super(PROCESS_FEATURE, FRIENDS_CHECKOUT_ID);
  }
}

export class LoadFriendsCheckoutCandidatesFail extends StateUtils.EntityFailAction {
  readonly type = ActionTypes.LOAD_FRIENDS_CHECKOUT_CANDIDATES_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, FRIENDS_CHECKOUT_ID, payload);
  }
}

export class LoadFriendsCheckoutCandidatesSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ActionTypes.LOAD_FRIENDS_CHECKOUT_CANDIDATES_SUCCESS;

  constructor(public payload: CustomFriendsCheckoutModel[]) {
    super(PROCESS_FEATURE, FRIENDS_CHECKOUT_ID, payload);
  }
}

export class LoadFriendsCheckout extends StateUtils.LoaderLoadAction {
  readonly type = ActionTypes.LOAD_FRIENDS_CHECKOUT;

  constructor(public payload: {userId: string, cartId: string}) {
    super(FRIENDS);
  }
}

export class LoadFriendsCheckoutFail extends StateUtils.LoaderFailAction {
  readonly type = ActionTypes.LOAD_FRIENDS_CHECKOUT_FAIL;

  constructor(public payload: any) {
    super(FRIENDS);
  }
}

export class LoadFriendsCheckoutSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ActionTypes.LOAD_FRIENDS_CHECKOUT_SUCCESS;

  constructor(public payload: CustomFriendsCheckoutModel) {
    super(FRIENDS);
  }
}

export class SelectFriendsCheckout extends StateUtils.LoaderLoadAction {
  readonly type = ActionTypes.SELECT_FRIENDS_CHECKOUT;

  constructor(public payload: {userId: string, cartId: string}) {
    super(FRIENDS);
  }
}

export class SelectFriendsCheckoutFail extends StateUtils.LoaderFailAction {
  readonly type = ActionTypes.SELECT_FRIENDS_CHECKOUT_FAIL;

  constructor(public payload: any) {
    super(FRIENDS);
  }
}

export class SelectFriendsCheckoutSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ActionTypes.SELECT_FRIENDS_CHECKOUT_SUCCESS;

  constructor(public payload: CustomFriendsCheckoutModel) {
    super(FRIENDS);
  }
}

export class UpdateFriendsCheckout extends StateUtils.LoaderLoadAction {
  readonly type = ActionTypes.UPDATE_FRIENDS_CHECKOUT;

  constructor(public payload: {userId: string, cartId: string, friendsCheckoutUid: string}) {
    super(FRIENDS);
  }
}

export class UpdateFriendsCheckoutFail extends StateUtils.LoaderFailAction {
  readonly type = ActionTypes.UPDATE_FRIENDS_CHECKOUT_FAIL;

  constructor(public payload: any) {
    super(FRIENDS);
  }
}

export class UpdateFriendsCheckoutSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ActionTypes.UPDATE_FRIENDS_CHECKOUT_SUCCESS;

  constructor(public payload: CustomFriendsCheckoutModel) {
    super(FRIENDS);
  }
}

export class RemoveFriendsCheckout extends StateUtils.LoaderLoadAction {
  readonly type = ActionTypes.REMOVE_FRIENDS_CHECKOUT;

  constructor(public payload: {userId: string, cartId: string}) {
    super(FRIENDS);
  }
}

export class RemoveFriendsCheckoutFail extends StateUtils.LoaderFailAction {
  readonly type = ActionTypes.REMOVE_FRIENDS_CHECKOUT_FAIL;

  constructor(public payload: any) {
    super(FRIENDS);
  }
}

export class RemoveFriendsCheckoutSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ActionTypes.REMOVE_FRIENDS_CHECKOUT_SUCCESS;

  constructor(public payload: any) {
    super(FRIENDS);
  }
}

export class PurgeFriendsCheckoutSuccess implements Action {
  readonly type = ActionTypes.PURGE_FRIENDS_CHECKOUT_SUCCESS;
}

// action typesCustomValidateCartAction
export type CustomFriendsCheckoutActions =
  | LoadFriendsCheckoutCandidates
  | LoadFriendsCheckoutCandidatesFail
  | LoadFriendsCheckoutCandidatesSuccess

  | LoadFriendsCheckout
  | LoadFriendsCheckoutFail
  | LoadFriendsCheckoutSuccess

  | SelectFriendsCheckout
  | SelectFriendsCheckoutFail
  | SelectFriendsCheckoutSuccess

  | UpdateFriendsCheckout
  | UpdateFriendsCheckoutFail
  | UpdateFriendsCheckoutSuccess

  | RemoveFriendsCheckout
  | RemoveFriendsCheckoutFail
  | RemoveFriendsCheckoutSuccess

  |PurgeFriendsCheckoutSuccess;

