import { createSelector, MemoizedSelector } from '@ngrx/store';
import { CustomGiftCardsListState, StateWithGiftCards } from '../custom-gift-card-state';
import { GiftCard } from '../../model/custom-gift-card.model';
import { getGiftCardListState } from './feature-selector';

export const getGiftCardsListState: MemoizedSelector<
  StateWithGiftCards,
  GiftCard[]
> = createSelector(
  getGiftCardListState,
  (state: CustomGiftCardsListState) => state?.giftCards
);



export const getSelectedGiftCard: MemoizedSelector<
  StateWithGiftCards,
  GiftCard
> = createSelector(
  getGiftCardListState,
  (state: CustomGiftCardsListState) => state.selected
);
