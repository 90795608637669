import { defaultPageMetaConfig, PageMetaConfig } from '@spartacus/core';

export const customDefaultPageMetaConfig: PageMetaConfig = {
  pageMeta: {
    resolvers: [
      ...defaultPageMetaConfig?.pageMeta?.resolvers,
      // next will be enabled with next major to avoid a current breaking change in 3.x version
      {
        property: 'description',
        method: 'resolveDescription',
        disabledInCsr: false,
      },
      {
        property: 'robots',
        method: 'resolveRobots',
        disabledInCsr: false,
      },

      {
        property: 'canonicalUrl',
        method: 'resolveCanonicalUrl',
        disabledInCsr: false,
      },
      {
        property: 'hrefLangs',
        method: 'resolveHrefLangs',
        disabledInCsr: false,
      },
    ],
    canonicalUrl: {
      forceHttps: true,
      forceWww: false,
      removeQueryParams: true,
      forceTrailingSlash: false,
    },
  },
};
