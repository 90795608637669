import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomUpdatePasswordComponent } from './update-password/custom-update-password.component';
import { AuthGuard, CmsConfig, ConfigModule, GlobalMessageService, I18nModule, RoutingService, UrlModule } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { UpdatePasswordComponentService } from '@spartacus/user/profile/components';
import { CustomUpdatePasswordComponentService } from './update-password/custom-update-password-component.service';

@NgModule({
  declarations: [CustomUpdatePasswordComponent],
  exports: [CustomUpdatePasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    UrlModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        UpdatePasswordComponent: {
          component: CustomUpdatePasswordComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: UpdatePasswordComponentService,
              useClass: CustomUpdatePasswordComponentService,
              deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
            },
          ],
        },
      },
    } as CmsConfig),
    IconModule,
  ],
})
export class CustomUpdatePasswordModule {}
