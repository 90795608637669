import {CxEvent} from "@spartacus/core";
import {EcommercePurchase} from "./custom-checkout-events.model";

/**
 * Indicates that a user has successfully placed an order
 */
export class CustomOrderPlacedEvent extends CxEvent {
  /**
   * Event's type
   */
  static readonly type = "CustomOrderPlacedEvent";
  event: string;
  ecommerce: EcommercePurchase;
}
