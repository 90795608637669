import {NgModule} from "@angular/core";
import {CustomUnitSelectorComponent} from "./custom-unit-selector.component";
import {CommonModule} from "@angular/common";
import {CustomUnitDisplayModule} from "../custom-unit-display/custom-unit-display.module";

@NgModule({
  imports: [
    CommonModule,
    CustomUnitDisplayModule
  ],
  declarations: [CustomUnitSelectorComponent],
  exports: [CustomUnitSelectorComponent]
})
export class CustomUnitSelectorModule {
}
