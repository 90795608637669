import { Component, HostBinding } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsBannerImageTextComponent } from './banner-image-text.model';

@Component({
  selector: 'app-banner-image-text',
  templateUrl: './banner-image-text.component.html',
  styleUrls: ['./banner-image-text.component.scss']
})
export class BannerImageTextComponent{

  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;

  data$: Observable<CmsBannerImageTextComponent> = this.component.data$.pipe(
    tap((data) => (this.initComponent(data)))
  );

  constructor(protected component: CmsComponentData<CmsBannerImageTextComponent>) {}

  initComponent(item: CmsBannerImageTextComponent): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
    this.backgroundColor = item?.backgroundColorCode ?? '';
  }

}
