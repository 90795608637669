import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CUSTOM_PREFERENCE_CENTER_FEATURE } from '../../../custom/cms-components/preference-center/root/custom-feature-name';
import { CustomPreferenceCenterRootModule } from '../../../custom/cms-components/preference-center/root/custom-preference-center-root.module';
@NgModule({
  declarations: [],
  imports: [CustomPreferenceCenterRootModule],
  // providers: [
  //   provideConfig(<CmsConfig>{
  //     featureModules: {
  //       [CUSTOM_PREFERENCE_CENTER_FEATURE]: {
  //         module: () =>
  //           import('../../../../app/custom/cms-components/preference-center/custom-preference-center.module').then(
  //             (m) => m.CustomPreferenceCenterModule
  //           ),
  //       },
  //     },
  //   }),
  // ],
})
export class CustomPreferenceCenterFeatureModule {}
