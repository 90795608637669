import { Observable } from 'rxjs';
import { Friend, FriendsFilter, MyFriendsList } from '../facade/friends.model';
import { CustomOcc } from '../../../occ/occ-models/custom-occ.models';
import CustomFriendsUserList = CustomOcc.CustomFriendsUserList;
import { EmailInformationModel } from '../../../../../../custom/cms-components/myaccount/custom-friends/components/custom-friend-email-form/CustomerEmail.model';

export abstract class CustomFriendsAdapter {
    abstract loadAll(
      userId: string,
      pageSize: number,
      currentPage: number,
      friendsFilter: FriendsFilter
    ): Observable<MyFriendsList>;

    abstract load(userId: string, uid: string): Observable<Friend>;

    abstract add(userid: string, friend: Friend): Observable<Friend>;

  abstract getGuest(token: string): Observable<Friend>;

    abstract addGuest(friendSignUp: Friend, token: string): Observable<Friend>;

    abstract delete(userId: string, uid: string): Observable<{}>;

    abstract update(userid: string, friend: Friend): Observable<Friend>;

    abstract invited(userid: string, friend: Friend): Observable<Friend>;

    abstract sendEmail(userId: string, emailInformation: EmailInformationModel): Observable<any>;
}
