<label class="searchbox custom-product-list-input" [class.dirty]="!!productSearchInput.value">
  <cx-icon
    [type]="iconTypes.SEARCH"
    aria-label="search"
    class="search prepend"
    (mousedown)="avoidReopen($event)"
    (keydown.enter)="avoidReopen($event)"
    tabindex="0"
  ></cx-icon>

  <input
    #productSearchInput
    type="text"
    class="form-control"
    [placeholder]="'searchBox.placeholder' | cxTranslate"
    aria-label="search"
    (input)="search(productSearchInput.value)"
    (blur)="close($event)"
    (keydown.escape)="close($event)"
    (keydown.enter)="
    close($event, true);
    updateChosenWord(productSearchInput.value)
    "
    (keydown.arrowup)="focusPreviousChild($event)"
    (keydown.arrowdown)="focusNextChild($event)"
    value="{{ chosenWord }}"
  />

  <cx-icon
    [hidden]="!productSearchInput.value"
    [type]="iconTypes.RESET"
    aria-label="reset"
    (mousedown)="clear(productSearchInput)"
    (keydown.enter)="clear(productSearchInput)"
    class="reset append"
    tabindex="0"
  ></cx-icon>
</label>

<div
  *ngIf="results$ | async as result"
  class="products-results"
  (click)="close($event, true)"
>
  <div
    *ngIf="result.message"
    class="message"
    [innerHTML]="result.message"
  ></div>
  <ng-container *ngIf="result.facets">
    <div class="facets">
      <ng-container *ngFor="let facets of result.facets">
        <h4 class="divider">{{facets.name}}</h4>
        <ng-container *ngFor="let facet of facets.values; let i = index">
          <ng-container *ngIf="!facet.query.query.value.includes('_root') &&  i < config.maxSuggestions ">
            <div class="result" [innerHTML]="facet.name | cxHighlight: productSearchInput.value"
               (keydown.arrowup)="focusPreviousChild($event)"
               (keydown.arrowdown)="focusNextChild($event)"
               (keydown.enter)="close($event, true)"
               (keydown.escape)="close($event, true)"
               (blur)="close($event)"
               (mousedown)="preventDefault($event)"
               (click)="dispatchSuggestionEvent({
                 freeText: productSearchInput.value,
                 selectedSuggestion: facet,
                 searchSuggestions: facet
               })"
            >
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>


  <div class="products" *ngIf="result.products">
    <div class="result"
         *ngFor="let product of result.products"
         [class.has-media]="config.displayProductImages"
         (keydown.arrowup)="focusPreviousChild($event)"
         (keydown.arrowdown)="focusNextChild($event)"
         (keydown.enter)="close($event, true)"
         (keydown.escape)="close($event, true)"
         (blur)="close($event)"
         (mousedown)="preventDefault($event)"
         (click)="
          dispatchProductEvent({
            freeText: productSearchInput.value,
            productCode: product.code,
            product: product
          })
        ">
      <cx-media *ngIf="config.displayProductImages"
                [container]="product.images?.PRIMARY"
                format="thumbnail"
                [alt]="product.summary"
      ></cx-media>
      <h4 class="name" [innerHTML]="product.nameHtml"></h4>
      <span class="price">{{ product.price?.formattedValue }}</span>
    </div>
  </div>
</div>
