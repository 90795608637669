import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomHeaderTablesComponent } from './custom-header-tables.component';



@NgModule({
  declarations: [CustomHeaderTablesComponent],
  exports: [CustomHeaderTablesComponent],
  imports: [
    CommonModule
  ]
})
export class CustomHeaderTablesModule { }
