import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomGiftboxCartComponent } from './custom-giftbox-cart.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomGiftboxCartComponent],
  imports: [
    CommonModule,
    MediaModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CustomGiftboxCartComponent: {
          component: CustomGiftboxCartComponent
        },
      }
    } as CmsConfig)
  ],
  entryComponents: [CustomGiftboxCartComponent],
  exports: [CustomGiftboxCartComponent],
})
export class CustomGiftboxCartModule { }
