import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckoutPaymentFormComponent } from '@spartacus/checkout/base/components';

@Component({
  selector: 'app-custom-payment-form',
  templateUrl: './custom-payment-form.component.html',
  styleUrls: ['./custom-payment-form.component.scss']
})
export class CustomPaymentFormComponent extends CheckoutPaymentFormComponent {

  paymentForm: FormGroup = this.fb.group({
    cardType: this.fb.group({
      code: [null],
    }),
    accountHolderName: [''],
    cardNumber: [''],
    expiryMonth: [null],
    expiryYear: [null],
    cvn: [''],
    defaultPayment: [false],
  });

}
