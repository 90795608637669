import { NgModule } from '@angular/core';
import { CmsConfig, provideDefaultConfigFactory } from '@spartacus/core';
import { CUSTOM_PREFERENCE_CENTER_CORE_FEATURE, CUSTOM_PREFERENCE_CENTER_FEATURE } from './custom-feature-name';

export function customDefaultPreferenceCenterComponentsConfig(): CmsConfig {
  const config: CmsConfig = {
    featureModules: {
      [CUSTOM_PREFERENCE_CENTER_FEATURE]: {
        cmsComponents: ['CustomPreferenceCenterFormComponent'],
      },
      [CUSTOM_PREFERENCE_CENTER_CORE_FEATURE]: CUSTOM_PREFERENCE_CENTER_FEATURE,
    },
  };
  return config;
}

@NgModule({
  providers: [provideDefaultConfigFactory(customDefaultPreferenceCenterComponentsConfig)],
})
export class CustomPreferenceCenterRootModule {}
