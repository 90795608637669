import { Component, OnInit } from '@angular/core';
import { NavigationComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-navigation',
  templateUrl: './custom-navigation.component.html',
  styleUrls: ['./custom-navigation.component.scss']
})
export class CustomNavigationComponent extends  NavigationComponent{

}
