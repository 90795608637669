<ng-container *ngIf="returnRequests$ | async as returnRequests">
  <div class="container">
    <!-- BODY -->
    <div class="cx-order-history-body">
      <!-- HEADER -->
      <div class="cx-order-history-header">
        <h2 class="title">
          {{ 'returnRequestList.pageTitle' | cxTranslate }}
        </h2>
      </div>
      <ng-container *ngIf="returnRequests.pagination.totalResults > 0; else noReturns">
        <!-- Select Form and Pagination Top -->
        <div class="cx-order-history-sort top row">
          <div
            class="cx-order-history-form-group form-group col-xs-12 col-lg-3"
          >
            <span class="label-content">
              {{ 'returnRequestList.sortByTitle' | cxTranslate }}
            </span>
            <cx-sorting
              [sortOptions]="returnRequests.sorts"
              [sortLabels]="getSortLabels() | async"
              (sortListEvent)="changeSortCode($event)"
              [selectedOption]="returnRequests.pagination.sort"
              placeholder="{{
                'returnRequestList.sortByMostRecent' | cxTranslate
              }}"
            ></cx-sorting>
          </div>
        </div>
        <!-- TABLE -->
        <table class="table table-main">
          <thead>
            <th scope="col">
              {{ 'returnRequestList.returnRequestId' | cxTranslate }}
            </th>
            <th scope="col">
              {{ 'returnRequestList.orderId' | cxTranslate }}
            </th>
            <th
              *ngIf="displayReturnOrder"
              scope="col"
            >
              {{ 'returnRequestList.orderReturnId' | cxTranslate }}
            </th>
            <th scope="col">
              {{ 'returnRequestList.date' | cxTranslate }}
            </th>
            <th scope="col">
              {{ 'returnRequestList.status' | cxTranslate }}
            </th>
            <th scope="col" class="table-main-cell-right">
              {{ 'returnRequestList.total' | cxTranslate }}
            </th>
          </thead>
          <tbody>
            <tr *ngFor="let return of returnRequests.returnRequests">
              <td class="cx-order-history-code">
                <div class="table-main-label">
                  {{ 'returnRequestList.returnRequestId' | cxTranslate }}
                </div>
                <a
                  [routerLink]="
                    {
                      cxRoute: 'returnRequestDetails',
                      params: return
                    } | cxUrl
                  "
                  class="cx-order-history-value"
                >
                  {{ return?.rma }}
                </a>
              </td>
              <td class="cx-order-history-code">
                <div class="table-main-label">
                  {{ 'returnRequestList.orderId' | cxTranslate }}
                </div>
                <a
                  [routerLink]="
                    {
                      cxRoute: 'orderDetails',
                      params: return?.order
                    } | cxUrl
                  "
                  class="cx-order-history-value"
                >
                  {{ return?.order?.code }}
                </a>
              </td>
              <td
                *ngIf="displayReturnOrder"
                [class.cx-order-history-code]="return?.returnOrder !== undefined"
              >
                <div class="table-main-label">
                  {{ 'returnRequestList.orderReturnId' | cxTranslate }}
                </div>
                <ng-container [ngSwitch]="return?.returnOrder">
                  <ng-container *ngSwitchCase="undefined">
                    -
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <a
                      [routerLink]="
                        {
                          cxRoute: 'orderDetails',
                          params: return?.returnOrder
                        } | cxUrl
                      "
                      class="cx-order-history-value"
                    >
                      {{ return?.returnOrder?.code }}
                    </a>
                  </ng-container>
                </ng-container>
              </td>
              <td class="cx-order-history-placed">
                <div class="table-main-label">
                  {{ 'returnRequestList.date' | cxTranslate }}
                </div>
                {{ return?.creationTime | cxDate: 'd/MM/yyyy' }}
              </td>
              <td class="cx-order-history-status">
                <div class="table-main-label">
                  {{ 'returnRequestList.status' | cxTranslate }}
                </div>
                {{
                  'returnRequestList.statusDisplay'
                    | cxTranslate: { context: return?.status }
                }}
              </td>
              <td class="table-main-cell-right cx-order-history-total">
                <div class="table-main-label">
                  {{ 'returnRequestList.total' | cxTranslate }}
                </div>
                  {{ return?.totalPrice.formattedValue }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Select Form and Pagination Bottom -->
        <div class="cx-order-history-sort bottom row">
          <div class="cx-order-history-pagination">
            <cx-pagination
              [pagination]="returnRequests.pagination"
              (viewPageEvent)="pageChange($event)"
            ></cx-pagination>
          </div>
        </div>
      </ng-container>

      <!-- NO RETURNS CONTAINER -->
      <ng-template #noReturns>
        <div
          *ngIf="returnRequests.pagination.totalResults === 0; else loading"
          class="cx-order-history-no-order row"
          >
          <div class="col-sm-12">
            <div>
              {{ 'returnRequestList.noReturns' | cxTranslate }}
            </div>
            <a
              [routerLink]="{ cxRoute: 'home' } | cxUrl"
              routerLinkActive="active"
              class="btn btn-primary"
              >
              {{ 'returnRequestList.startShopping' | cxTranslate }}
            </a>
          </div>
        </div>
      </ng-template>
      <ng-template #loading>
        <div class="cx-spinner">
          <cx-spinner></cx-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
