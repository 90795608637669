import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OccEndpointsService, ConverterService, Occ } from '@spartacus/core';
import { CustomOrderActionsAdapter } from '../connectors/custom-order-actions.adapter';
import { OccOrderHistoryAdapter } from '@spartacus/order/occ';
import { Cart } from '@spartacus/cart/base/root';
import { OrdersFilter } from 'src/app/model/filters.model';
import { ORDER_HISTORY_NORMALIZER, OrderHistoryList } from '@spartacus/order/root';

@Injectable()

export class CustomOccOrderActionsAdapter extends OccOrderHistoryAdapter implements CustomOrderActionsAdapter {
  constructor(
    protected http: HttpClient,
    protected converter: ConverterService,
    protected occEndpoints: OccEndpointsService
  ) {
    super(http, occEndpoints, converter);
  }

  orderCancellation({ userId, orderCode }): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpoints.buildUrl('orderCancellation', {
       urlParams: { userId, code: orderCode }
      });
    return this.http.post(url, { headers });
  }

  orderRestoration({ userId, orderCode }): Observable<Cart> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpoints.buildUrl('orderRestoration', {
      urlParams: { userId, code: orderCode }
     });
    return this.http.post(url, { headers });
  }

  orderRestoreOnly({ userId, orderCode }): Observable<Cart> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpoints.buildUrl('orderRestoration', {
      urlParams: { userId, code: orderCode }
     });
    const params: HttpParams = new HttpParams().set('cancel', 'false');
    return this.http.post(url, {}, { headers, params });
  }

 loadHistoryFilter(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    ordersFilter?: OrdersFilter,
  ): Observable<OrderHistoryList> {
    const params = {};
    if (pageSize) {
      params['pageSize'] = pageSize.toString();
    }
    if (currentPage) {
      params['currentPage'] = currentPage.toString();
    }
    if (sort) {
      params['sort'] = sort.toString();
    }
    for (const key in ordersFilter) {
      if (Object.prototype.hasOwnProperty.call(ordersFilter, key)) {
        const element = ordersFilter[key];
        if (element) {
          params[key] = element;
        }
      }
    }

    const url = this.occEndpoints.buildUrl('orderHistoryFilter', {
      urlParams: { userId }
    });

    return this.http
      .get<Occ.OrderHistoryList>(url, { params: params})
      .pipe(this.converter.pipeable(ORDER_HISTORY_NORMALIZER));
  }
}
