import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmarsysService } from './emarsys.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

export class EmarsysModule { 
  constructor(_emarsysService: EmarsysService) {}
}
