import { Component } from '@angular/core';
import { CmsOrderDetailItemsComponent, Promotion, TranslationService } from '@spartacus/core';
import { OrderDetailItemsComponent, OrderDetailsService } from '@spartacus/order/components';
import { CmsComponentData } from '@spartacus/storefront';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'cx-order-details-items',
  templateUrl: './custom-order-detail-items.component.html',
})
export class CustomOrderDetailItemsComponent extends OrderDetailItemsComponent {

  orderPromotions$: Observable<Promotion[]> = this.order$.pipe(
    filter(data => !!data),
    take(1), // make sure first result is accepted
    map((data: any) => {
      if (!data.appliedOrderPromotions || data.appliedOrderPromotions.length === 0) {
        console.error('appliedOrderPromotions não está definido ou é um array vazio');
        return [];
      }
      return data.appliedOrderPromotions;
    })
  );;

  constructor(
    protected orderDetailsService: OrderDetailsService, 
    protected component: CmsComponentData<CmsOrderDetailItemsComponent>,
    protected translation: TranslationService
    ) {
      super(orderDetailsService, component);
    }
}
