import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { AuthActions, CmsComponent, StateUtils } from '@spartacus/core';
import { CustomAgeStatusAction, CustomAgeStatusActionTypes } from '../actions/custom-age-status.actions';
import { AGE_STATUS_ID, CustomAgeStatusState } from '../custom-age.state';
import { CustomNewsState } from '../../../../content/custom-news/store/custom-news.state';
import {
  CustomNewsDetailsModel,
  CustomNewsListModel,
  CustomNewsSliderModel,
} from '../../../../content/custom-news/store/custom-news.model';
import { NEWS_LIST } from '../../../../content/custom-news/store/actions/custom-news-list.actions';
import { reducerNewsList } from '../../../../content/custom-news/store/reducers/custom-news-list.reducer';
import { NEWS_DETAILS } from '../../../../content/custom-news/store/actions/custom-news-details.actions';
import { reducerNewsDetails } from '../../../../content/custom-news/store/reducers/custom-news-details.reducer';
import { NEWS_SLIDER } from '../../../../content/custom-news/store/actions/custom-news-slider.actions';
import { reducerNewsSlider } from '../../../../content/custom-news/store/reducers/custom-news-slider.reducer';
import { Images } from '@spartacus/core/src/model/image.model';
import { BillHistoryList, StateBills } from '../../../../../../model/bill.model';
import { USER_BILLS } from '../../../../myaccount/custom-bills/store/actions/custom-user-bills.action';
import { reducer } from '../../../../myaccount/custom-bills/store/reducer/custom-user-bills.reducer';


export interface CustomAgeStatusModel {
  accepted?: boolean;
}

export const initialState: CustomAgeStatusModel = {
  accepted: false,
};

export function customAgeStatusReducer(
  state = initialState,
  action: CustomAgeStatusAction,
): CustomAgeStatusModel {

  switch (action.type) {

    case CustomAgeStatusActionTypes.LOAD_AGE_STATUS_SUCCESS: {
      return action.payload ? action.payload : initialState;
    }

    case CustomAgeStatusActionTypes.UPDATE_AGE_STATUS_SUCCESS: {
      return action.payload ? action.payload : initialState;
    }

    default:
      return state;
  }
}


export function getReducers(): ActionReducerMap<CustomAgeStatusState> {
  return {
    popup: StateUtils.loaderReducer<CustomAgeStatusModel>(AGE_STATUS_ID, customAgeStatusReducer),
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<CustomAgeStatusState>> =
  new InjectionToken<ActionReducerMap<CustomAgeStatusState>>('AgeStateReducers');

export const customAgeStatusReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearCustomAgeStatusState(reducers: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
      state = [];
    }

    return reducers(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearCustomAgeStatusState];
