import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomGiftboxProductHeaderComponent } from './custom-giftbox-product-header.component';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { CmsConfig } from '@spartacus/core';

@NgModule({
  declarations: [CustomGiftboxProductHeaderComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CustomGiftboxProductHeaderComponent: {
          component: CustomGiftboxProductHeaderComponent
        },
      }
    } as CmsConfig)
  ],
  entryComponents: [CustomGiftboxProductHeaderComponent],
  exports: [CustomGiftboxProductHeaderComponent],
})
export class CustomGiftboxProductHeaderModule { }
