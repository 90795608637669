import { Injectable } from '@angular/core';
import { StateWithNews } from '../../store/custom-news.state';
import { select, Store } from '@ngrx/store';
import { LoadNewsList } from '../../store/actions/custom-news-list.actions';
import { Observable } from 'rxjs';
import { CustomNewsCategoriesModel, CustomNewsListModel, CustomNewsPaginationParams, NewsFilter } from '../../store/custom-news.model';
import {
  getNewsListError,
  getNewsListLoaderState,
  getNewsListLoading,
  getNewsListSuccess,
} from '../../store/selectors/custom-news-list.selector';
import { map, tap } from 'rxjs/operators';
import { LoadNewsCategories } from '../../store/actions/custom-news-categories.actions';
import {
  getNewsCategories,
  getNewsCategoriesError,
  getNewsCategoriesLoading,
  getNewsCategoriesSuccess,
} from '../../store/selectors/custom-news-categories.selector';

@Injectable({
  providedIn: 'root',
})
export class CustomNewsListService {

  constructor(
    protected store: Store<StateWithNews>,
  ) {
  }

  loadNewsList(newsParams: CustomNewsPaginationParams): void {
    this.store.dispatch(new LoadNewsList(newsParams));
  }

  getNewsList({ pageSize }): Observable<CustomNewsListModel> {
    return this.store.pipe(select(getNewsListLoaderState),
      tap((newsState) => {
        const attemptedLoad =
          newsState.loading ||
          newsState.success ||
          newsState.error;
        if (!attemptedLoad) {
          this.loadNewsList({ pageSize });
        }
      }),
      map((newsListState) => newsListState.value),
    );
  }

  getNewsListLoading(): Observable<boolean> {
    return this.store.pipe(select(getNewsListLoading));
  }

  getNewsListError(): Observable<boolean> {
    return this.store.pipe(select(getNewsListError));
  }

  getNewsListSuccess(): Observable<boolean> {
    return this.store.pipe(select(getNewsListSuccess));
  }

  loadNewsCategories(): void {
    this.store.dispatch(new LoadNewsCategories());
  }

  getNewsCategories(): Observable<CustomNewsCategoriesModel> {
    return this.store.pipe(select(getNewsCategories));
  }

  getNewsCategoriesLoading(): Observable<boolean> {
    return this.store.pipe(select(getNewsCategoriesLoading));
  }

  getNewsCategoriesError(): Observable<boolean> {
    return this.store.pipe(select(getNewsCategoriesError));
  }

  getNewsCategoriesSuccess(): Observable<boolean> {
    return this.store.pipe(select(getNewsCategoriesSuccess));
  }
}
