<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="visible">
    <button
      (click)="handleDownloadUrl(order)"
      class="btn btn-primary"
      [disabled]="loading$ | async"
    >
      <span
        *ngIf="loading$ | async"
        class="spinner-border spinner-border-sm"
      ></span>
      {{ 'orderDetails.actions.ticket' | cxTranslate }}
    </button>
  </ng-container>
</ng-container>
