import { Address, PaymentDetails, StateUtils } from '@spartacus/core';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { EntryGroup } from '../../../../../../model/entry-groups.model';
import { CustomFriendsCheckoutModel } from '../../../custom-friends/custom-friends-checkout/facade/custom-friends-checkout.model';
import { StateWithMultiCart, MultiCartState, MULTI_CART_FEATURE } from '@spartacus/cart/base/core';
import { Cart } from '@spartacus/cart/base/root';

export const getMultiCartState: MemoizedSelector<StateWithMultiCart,
  MultiCartState> = createFeatureSelector<MultiCartState>(MULTI_CART_FEATURE);

export const getMultiCartEntities: MemoizedSelector<StateWithMultiCart,
  StateUtils.EntityProcessesLoaderState<Cart>> = createSelector(getMultiCartState, (state: MultiCartState) => state.carts);

export const getCartSelectorFactory = (
  cartId: string,
): MemoizedSelector<StateWithMultiCart, Cart> => {
  return createSelector(
    getMultiCartEntities,
    (state: StateUtils.EntityProcessesLoaderState<Cart>) =>
      StateUtils.entityValueSelector(state, cartId),
  );
};

export const getCartEntryGroupsSelectorFactory = (cartId: string): MemoizedSelector<StateWithMultiCart, EntryGroup[]> => {
  return createSelector(getCartSelectorFactory(cartId), (state: Cart) => {
    return state && state.entryGroups ? state.entryGroups : [];
  });
};

export const getCartOnBehalfOfSelectorFactory = (cartId: string): MemoizedSelector<StateWithMultiCart, CustomFriendsCheckoutModel> => {
  return createSelector(getCartSelectorFactory(cartId), (state: Cart) => {
    return state && state.onBehalfOf ? state.onBehalfOf : null;
  });
};

export const getCartDefaultDeliveryAddressSelector = (cartId: string): MemoizedSelector<StateWithMultiCart, Address> => {
  return createSelector(getCartSelectorFactory(cartId), (state: Cart) => {
    return state && state.deliveryAddress ? state.deliveryAddress : null;
  });
};

export const getCartBillingAddressSelector = (cartId: string): MemoizedSelector<StateWithMultiCart, Address> => {
  return createSelector(getCartSelectorFactory(cartId), (state: Cart) => {
    return state?.paymentAddress ?? null;
  });
};

export const getSavedCreditCardSelector = (cartId: string): MemoizedSelector<StateWithMultiCart, PaymentDetails> => {
  return createSelector(getCartSelectorFactory(cartId), (state: Cart) => {
    return state && state.paymentInfo ? state.paymentInfo : null;
  });
};
