import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { UserIdService } from '@spartacus/core';
import { Observable, of, Subscription } from 'rxjs';
import { GiftCard } from './model/custom-gift-card.model';
import { CustomGiftCardService } from './services/custom-gift-card.service';
import { getGiftCardsListState, getSelectedGiftCard } from './store/selectors/custom-gift-cards.selectors';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Component({
    selector: 'app-custom-gift-card',
    templateUrl: './custom-gift-card.component.html',
    styleUrls: ['./custom-gift-card.component.scss'],
})
export class CustomGiftCardComponent implements OnDestroy {
    giftCards$: Observable<GiftCard[]>;
    cartId: string;
    userId: string;
    subscription: Subscription = new Subscription();
    selectedGiftCardCode: string;
    selectedGiftCard: Observable<GiftCard> = this.store.pipe(select(getSelectedGiftCard));
    loadingGiftCard: boolean;
    requiresMessage: boolean;
    giftCardForm: FormGroup;

    constructor(
        protected giftCardService: CustomGiftCardService,
        private store: Store,
        protected activeCartService: ActiveCartFacade,
        protected userIdService: UserIdService,
        protected fb: FormBuilder,
        protected customGiftCardService: CustomGiftCardService
    ) {
        this.giftCardForm = this.fb.group({
            messageGiftCard: ['', Validators.required],
        });
        this.getUserID();
        this.getCartID();
        this.customGiftCardService.loadGiftCard(this.userId, this.cartId);
        this.customGiftCardService.loadGiftCards(this.userId, this.cartId);
        this.giftCards$ = this.store.pipe(select(getGiftCardsListState));
        this.subscription.add(
            this.selectedGiftCard.subscribe((response) => {
                if (response?.code) {
                    this.giftCardForm.patchValue({ messageGiftCard: response.giftCardMessage });
                    this.requiresMessage = response?.requiresMessage;
                    this.selectedGiftCardCode = response?.code;
                } else {
                    this.selectedGiftCardCode = '';
                    this.requiresMessage = false;
                }
                this.loadingGiftCard = this.loadingGiftCard && !(response === null || !!response?.code);
            })
        );
    }

    getCartID(): void {
        return this.activeCartService
            .getActiveCartId()
            .subscribe((response) => {
                this.cartId = response;
            })
            .unsubscribe();
    }

    getUserID(): void {
        this.userIdService
            .takeUserId()
            .subscribe((userID) => (this.userId = userID))
            .unsubscribe();
    }

    selectGiftCard(code?: string, requiresMessage?: boolean): void {
      if (!this.loadingGiftCard && (code || this.selectedGiftCardCode)){
        this.loadingGiftCard = true;
        if (code === this.selectedGiftCardCode) {
            this.customGiftCardService.removeGiftCard(this.userId, this.cartId);
            this.giftCardForm.get('messageGiftCard').reset();
            this.requiresMessage = false;
        } else {
            const customRequiresMessage = requiresMessage === undefined ? this.requiresMessage : requiresMessage;
            this.customGiftCardService.updateGiftCard(
                this.userId,
                this.cartId,
                customRequiresMessage ? this.giftCardForm.get('messageGiftCard').value : '',
                code ? code : this.selectedGiftCardCode
            );
        }
      }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
