<form (ngSubmit)="onSubmit()" [formGroup]="checkoutLoginForm">
  <h2>
    {{ 'checkoutLogin.title' | cxTranslate }}
  </h2>
  <p>
    {{ 'checkoutLogin.info' | cxTranslate }}
  </p>
  <div class="form-group">
    <label>
      <span class="label-content">
        {{ 'checkoutLogin.emailAddress.label' | cxTranslate }}
      </span>
      <input
        type="email"
        name="email"
        class="form-control"
        formControlName="email"
        placeholder="{{'checkoutLogin.emailAddress.placeholder' | cxTranslate}}"
      />
    </label>
    <cx-form-errors
      [control]="checkoutLoginForm.get('email')"
    ></cx-form-errors>
  </div>
  <div class="form-group">
    <label>
      <span class="label-content">
        {{ 'checkoutLogin.confirmEmail.label' | cxTranslate }}
      </span>
      <input
        type="email"
        name="emailConfirmation"
        class="form-control"
        formControlName="emailConfirmation"
        placeholder="{{'checkoutLogin.confirmEmail.placeholder' | cxTranslate}}"
      />
    </label>
    <cx-form-errors
      [control]="checkoutLoginForm.get('emailConfirmation')"
    ></cx-form-errors>
  </div>
  <button type="submit" class="btn btn-send">
    {{ 'checkoutLogin.continue' | cxTranslate }}
  </button>
</form>
