import { Component } from '@angular/core';
import { ProductSearchPage, ProductSearchService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-custom-category-summary',
  templateUrl: './custom-category-summary.component.html',
})
export class CustomCategorySummaryComponent {
  constructor(
    protected productSearchService: ProductSearchService,
  ) {}

  searchResults$: Observable<ProductSearchPage> = this.productSearchService
    .getResults()
    .pipe(filter((searchResult) => Object.keys(searchResult).length > 0));
}
