import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCartDetailsComponent } from './custom-cart-details.component';
import { CmsConfig, FeaturesConfigModule, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { IconModule, PromotionsModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomCartSharedModule } from '../cart-shared/custom-cart-shared.module';
import { CustomCartNotGiftboxGuard } from '../../../../../custom/cms-components/giftbox/guards/custom-cart-not-giftbox.guard';
import { CartCouponModule } from '@spartacus/cart/base/components';
import { CustomPromotionsModule } from '../../product/promotions/promotions.module';


@NgModule({
  declarations: [CustomCartDetailsComponent],
  imports: [
    CommonModule,
    CartCouponModule,
    RouterModule,
    UrlModule,
    PromotionsModule,
    FeaturesConfigModule,
    CustomCartSharedModule,
    I18nModule,
    CustomPromotionsModule,
    IconModule,
  ],
  providers: [
    provideConfig(<CmsConfig> {
      cmsComponents: {
        CartComponent: {
          component: CustomCartDetailsComponent,
          guards: [CustomCartNotGiftboxGuard],
        },
      }
    })
  ]
})
export class CustomCartDetailsModule {}
