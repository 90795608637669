import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomBoxLinkImageItemModule } from '../custom-box-link-image-item/custom-box-link-image-item.module';
import { OutletModule, PageComponentModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomBoxLinkImageComponent } from './custom-box-link-image.component';



@NgModule({
  declarations: [CustomBoxLinkImageComponent],
  imports: [
    CommonModule,
    CustomBoxLinkImageItemModule,
    PageComponentModule,
    OutletModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BoxLinkImageComponent: {
          component: CustomBoxLinkImageComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomBoxLinkImageModule { }
