import {
  Friend,
  FriendsFilter,
  MyFriendsList,
} from 'src/app/spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { FRIEND_EMAILS } from '../custom-friends-email.state';
import { Action } from '@ngrx/store';
import { EmailInformationModel } from '../../CustomerEmail.model';
import { FRIENDS } from '../../../../../../../../spartacus/custom/core/custom-friends/store/custom-friends-checkout.state';

export enum FriendsEmailsActionTypes {
  RESET_FRIEND_EMAILS = '[Friend Emails] Reset Friend Emails',

  SELECT_FRIEND_EMAILS = '[Friend Emails] Load Friend Emails ',
  SELECT_FRIEND_EMAILS_SUCCESS = '[Friend Emails] Load Friend Emails Success',
  SELECT_FRIEND_EMAILS_FAIL = '[Friend Emails] Load Friend Emails Fail',

  SEND_FRIEND_EMAILS = '[Friend Emails] Create/Edit Friend Emails',
  SEND_FRIEND_EMAILS_FAIL = '[Friend Emails] Create/Edit Friend Emails Of Fail',
  SEND_FRIEND_EMAILS_SUCCESS = '[Friend Emails] Create/Edit Friend Emails Success',

  SELECT_ALL_FRIENDS = '[Friends] Select All Friends ',
  SELECT_ALL_FRIENDS_SUCCESS = '[Friends] Select All Friends Success',
  SELECT_ALL_FRIENDS_FAIL = '[Friends] Select All Friends Fail',
}

export class ResetFriendsEmails extends StateUtils.LoaderResetAction {
  readonly type = FriendsEmailsActionTypes.RESET_FRIEND_EMAILS;

  constructor() {
    super(FRIEND_EMAILS);
  }
}

export class SelectFriendEmails extends StateUtils.LoaderLoadAction {
  readonly type = FriendsEmailsActionTypes.SELECT_FRIEND_EMAILS;

  constructor(public payload: Array<{ name: string, email: string }>) {
    super(FRIEND_EMAILS);
  }
}

export class SelectFriendEmailsFail extends StateUtils.LoaderFailAction {
  readonly type = FriendsEmailsActionTypes.SELECT_FRIEND_EMAILS_FAIL;

  constructor(public payload: any) {
    super(FRIEND_EMAILS, payload);
  }
}

export class SelectFriendEmailsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = FriendsEmailsActionTypes.SELECT_FRIEND_EMAILS_SUCCESS;

  constructor(public payload: Array<{ name: string; email: string }>) {
    super(FRIEND_EMAILS);
  }
}

export class SendFriendEmails extends StateUtils.EntityLoadAction {
  readonly type = FriendsEmailsActionTypes.SEND_FRIEND_EMAILS;

  constructor(public payload: { userId: string, emailInformation: EmailInformationModel }) {
    super(PROCESS_FEATURE, FRIEND_EMAILS);
  }
}

export class SendFriendEmailsFail extends StateUtils.EntityFailAction {
  readonly type = FriendsEmailsActionTypes.SEND_FRIEND_EMAILS_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, FRIEND_EMAILS, payload);
  }
}

export class SendFriendEmailsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = FriendsEmailsActionTypes.SEND_FRIEND_EMAILS_SUCCESS;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, FRIEND_EMAILS);
  }
}

export class SelectAllFriends extends StateUtils.LoaderLoadAction {
  readonly type = FriendsEmailsActionTypes.SELECT_ALL_FRIENDS;

  constructor(public payload: {
    userId: string;
    pageSize?: number;
    currentPage?: number;
    friendsFilter?: FriendsFilter;
  }) {
    super(FRIENDS);
  }
}

export class SelectAllFriendsFail extends StateUtils.LoaderFailAction {
  readonly type = FriendsEmailsActionTypes.SELECT_ALL_FRIENDS_FAIL;

  constructor(public payload: any) {
    super(FRIENDS, payload);
  }
}

export class SelectAllFriendsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = FriendsEmailsActionTypes.SELECT_ALL_FRIENDS_SUCCESS;

  constructor(public payload: any) {
    super(FRIENDS);
  }
}

export type CustomFriendsEmailsActions =
  | ResetFriendsEmails
  | SelectFriendEmails
  | SelectFriendEmailsFail
  | SelectFriendEmailsSuccess
  | SendFriendEmails
  | SendFriendEmailsFail
  | SendFriendEmailsSuccess
  | SelectAllFriends
  | SelectAllFriendsFail
  | SelectAllFriendsSuccess;
