<cx-generic-link
  *ngIf="rolloverImages.link?.url; else media"
  [url]="rolloverImages.link.url"
  [target]="getTarget(rolloverImages.link.target)"
  [class]="rolloverImages.link.styleClasses"
>
  <ng-container *ngTemplateOutlet="media"></ng-container>
</cx-generic-link>
<ng-template #media>
  <cx-media
    *ngIf="rolloverImages.image"
    [container]="rolloverImages.image"
  ></cx-media>
  <cx-media
    *ngIf="rolloverImages.hoverImage"
    [container]="rolloverImages.hoverImage"
    class="image-rollover"
  ></cx-media>  
</ng-template>
