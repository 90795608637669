import { HttpErrorResponse, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isVoucherError, voucherExceededError, voucherInvalidError } from "@spartacus/cart/base/core";
import { HttpErrorHandler, HttpResponseStatus, Priority, GlobalMessageType, ErrorModel } from "@spartacus/core";

@Injectable({
  providedIn: 'root',
})
export class CustomVoucherBadRequestHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.BAD_REQUEST;

  getPriority(): Priority {
    return Priority.HIGH;
  }

  hasMatch(errorResponse: HttpErrorResponse): boolean {
    return (
      super.hasMatch(errorResponse) &&
      this.getErrors(errorResponse).some(isVoucherError)
    );
  }

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handleVoucherExceededError(request, response);
    this.handleVoucherInvalidError(request, response);
  }

  protected handleVoucherExceededError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => voucherExceededError(e) ||
       e.message === 'You have entered too many voucher codes. Please try again later.')
      .forEach((e) => {
        var url = response.url;
        var firstvariable = 'voucherId=';
        var secondvariable = '&lang=es&curr=EUR';
        var tmpStr = url.match(new RegExp(firstvariable + "(.*)" + secondvariable));
        var voucherCode = tmpStr[1];
        this.globalMessageService.add(
          { key: 'httpHandlers.voucherExceeded', params: { voucherCode: voucherCode } },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
  }

  protected handleVoucherInvalidError(
    _request: HttpRequest<any>,
    response: HttpErrorResponse
  ): void {
    this.getErrors(response)
      .filter((e) => voucherInvalidError(e))
      .forEach(() => {
        this.globalMessageService.add(
          { key: 'httpHandlers.invalidCodeProvided' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
  }

  protected getErrors(response: HttpErrorResponse): ErrorModel[] {
    return (response.error?.errors || []).filter(
      (error: ErrorModel) => error.type !== 'JaloObjectNoLongerValidError'
    );
  }
}