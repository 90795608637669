import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { CustomCardModule } from '../../../cms-structure/shared/components/custom-card/custom-card.module';
import { CustomOrderOverviewComponent } from './custom-order-overview.component';

@NgModule({
  imports: [CommonModule, I18nModule, CustomCardModule],
  declarations: [CustomOrderOverviewComponent],
  exports: [CustomOrderOverviewComponent],
})
export class CustomOrderOverviewModule {}
