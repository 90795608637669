import { Injectable } from '@angular/core';
import { CustomUserAddressAdapter } from './custom-user-address.adapter';
import { Observable } from 'rxjs';
import { UserAddressConnector, Address } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomUserAddressConnector extends UserAddressConnector {
  constructor(protected adapter: CustomUserAddressAdapter) {
    super(adapter);
  }

  getAllFromBasestore(userId: string): Observable<Address[]> {
    return this.adapter.loadAllFromBasestore(userId);
  }
}
