import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule, FeaturesConfigModule } from '@spartacus/core';
import { FormErrorsModule, ItemCounterModule, MediaModule } from '@spartacus/storefront';
import { CustomCancelOrReturnItemsComponent } from './custom-amend-order-items.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    MediaModule,
    ItemCounterModule,
    FeaturesConfigModule,
    FormErrorsModule,
  ],
  declarations: [CustomCancelOrReturnItemsComponent],
  exports: [CustomCancelOrReturnItemsComponent],
  entryComponents: [CustomCancelOrReturnItemsComponent],
})
export class CustomAmendOrderItemsModule {}
