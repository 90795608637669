import { NgModule } from '@angular/core';
import { CustomPreferenceCenterCoreModule } from './core/custom-preference-center-core.module';
import { CustomPreferenceCenterFormModule } from './custom-preference-center-form/custom-preference-center-form.module';
import { CustomPreferenceCenterOccModule } from './occ/custom-preference-center-occ.module';

@NgModule({
  imports: [
    CustomPreferenceCenterCoreModule, 
    CustomPreferenceCenterOccModule,
    CustomPreferenceCenterFormModule, 
  ],
})
export class CustomPreferenceCenterModule {}
