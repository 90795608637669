import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDoubleImageInfoComponent } from './custom-double-image-info.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CustomImageInfoModule } from '../custom-image-info/custom-image-info.module';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';

@NgModule({
  declarations: [CustomDoubleImageInfoComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    I18nModule,
    CustomImageInfoModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomDoubleImageInfoComponent: {
          component: CustomDoubleImageInfoComponent
        },
      }
    } as CmsConfig)
  ]
})
export class CustomDoubleImageInfoModule { }
