import { Injectable } from "@angular/core";
import { PageType } from "@spartacus/core";
import { CustomBasePageMetaResolver } from "./custom-base-page-meta.resolver";
import { CustomContentPageMetaResolver } from "./custom-content-page-meta.resolver";

/**
 * Resolves the page data for the Story Page based on the `PageType.NEWS_PAGE` and NewsPageTemplate.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class CustomNewsPageMetaResolver extends CustomContentPageMetaResolver {
  constructor(
    protected basePageMetaResolver: CustomBasePageMetaResolver,
  ) {
    super(basePageMetaResolver);
    this.pageType = PageType.NEWS_PAGE as PageType;
    this.pageTemplate = 'NewsPageTemplate';    
  }
}
