import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StateModule } from '@spartacus/core';
import { FRIENDS_FEATURE } from './custom-friends-checkout.state';
import { FriendsReducerProvider, metaReducers, reducerToken } from './reducers/custom-friends.reducer';
import { CustomFriendsCheckoutEffects } from '../custom-friends-checkout/store/effects/custom-friends-checkout.effect';
import { CustomFriendsEffects } from '../custom-friends-users/store/effects/custom-friend-users.effect';

export const effects: any[] = [CustomFriendsCheckoutEffects, CustomFriendsEffects ];
@NgModule({
    imports: [
        CommonModule,
        StateModule,
        StoreModule.forFeature(FRIENDS_FEATURE, reducerToken, { metaReducers }),
        EffectsModule.forFeature(effects),
        RouterModule,
    ],
    providers: [FriendsReducerProvider],
})
export class CustomFriendsStoreModule {}
