import { Component } from '@angular/core';
import { CheckoutProgressMobileTopComponent } from '@spartacus/checkout/base/components';

@Component({
  selector: 'cx-checkout-progress-mobile-top',
  templateUrl: './custom-checkout-progress-mobile-top.component.html',
})
export class CustomCheckoutProgressMobileTopComponent extends CheckoutProgressMobileTopComponent {

}
