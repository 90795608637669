import { LayoutConfig, DIALOG_TYPE } from "@spartacus/storefront";
import { CustomOrderCancellationComponent } from "./custom-order-actions/custom-order-cancellation/custom-order-cancellation.component";
import { CustomOrderRestorationComponent } from "./custom-order-actions/custom-order-restoration/custom-order-restoration.component";
import { CustomOrderRestoreOnlyComponent } from "./custom-order-actions/custom-order-restore-only/custom-order-restore-only.component";
export const customOrderCancellationDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        CUSTOM_ORDER_CANCELLATION: {
            inlineRoot: true,
            component: CustomOrderCancellationComponent,
            dialogType: DIALOG_TYPE.DIALOG,
            multi: true
        },
    },
};

export const customOrderRestorationDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        CUSTOM_ORDER_RESTORATION: {
            inlineRoot: true,
            component: CustomOrderRestorationComponent,
            dialogType: DIALOG_TYPE.DIALOG,
            multi: true
        },
    },
};

export const customOrderRestoreOnlyDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        CUSTOM_ORDER_RESTORE_ONLY: {
            inlineRoot: true,
            component: CustomOrderRestoreOnlyComponent,
            dialogType: DIALOG_TYPE.DIALOG,
            multi: true
        },
    },
};