import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  Address,
  ADDRESS_NORMALIZER,
  ADDRESS_SERIALIZER, ADDRESS_VALIDATION_NORMALIZER, AddressValidation,
  ConverterService, InterceptorUtil,
  Occ, OCC_USER_ID_ANONYMOUS,
  OccEndpointsService,
  OccUserAddressAdapter, USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { CustomUserAddressAdapter } from '../../../core/user/connectors/address/custom-user-address.adapter';

@Injectable()
export class CustomOccUserAddressAdapter extends OccUserAddressAdapter implements CustomUserAddressAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
  ) {
    super(http, occEndpoints, converter);
  }

  loadAll(userId: string): Observable<Address[]> {
    const url = this.occEndpoints.buildUrl('addresses', {
      urlParams: { userId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .get<Occ.AddressList>(url, { headers })
      .pipe(
        catchError((error: any) => throwError(error)),
        map((addressList) => addressList.addresses),
        this.converter.pipeableMany(ADDRESS_NORMALIZER),
      );
  }

  add(userId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressCustomCreate', {
      urlParams: { userId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http
      .post(url, address, { headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  update(userId: string, addressId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressCustomUpdate', {
      urlParams: { userId, addressId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http
      .put(url, address, { headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  verify(userId: string, address: Address): Observable<AddressValidation> {
    const url = this.occEndpoints.buildUrl('addressVerification', {
      urlParams: { userId }
    });
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http
      .post<AddressValidation>(url, address, { headers })
      .pipe(
        catchError((error: any) => throwError(error)),
        this.converter.pipeable(ADDRESS_VALIDATION_NORMALIZER),
      );
  }

  delete(userId: string, addressId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressDetail', {
      urlParams: { userId, addressId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .delete(url, { headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  loadAllFromBasestore(userId: string): Observable<Address[]> {
    const url = this.occEndpoints.buildUrl('addressesBasestore', {
      urlParams: { userId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .get<Occ.AddressList>(url, { headers })
      .pipe(
        catchError((error: any) => throwError(error)),
        map((addressList) => addressList.addresses),
        this.converter.pipeableMany(ADDRESS_NORMALIZER)
      );
  }
}
