import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotCheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { CustomCheckoutLoginComponent } from './custom-checkout-login.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        GuestCheckoutLoginComponent: {
          component: CustomCheckoutLoginComponent,
          guards: [NotCheckoutAuthGuard],
        }
      }
    } as CmsConfig),
  ],
  declarations: [CustomCheckoutLoginComponent],
  exports: [CustomCheckoutLoginComponent],
  entryComponents: [CustomCheckoutLoginComponent],
})
export class CustomCheckoutLoginModule { }
