import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {CMSComponentConfig, I18nModule, provideConfig, provideDefaultConfig} from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { CustomCardModule } from 'src/app/spartacus/custom/cms-structure/shared/components/custom-card/custom-card.module';
import { CustomIconsModule } from '../../../misc/icon/custom-icons.module';
import { CustomAddressFormModule } from './address-form/custom-address-form.module';
import { CustomShippingAddressComponent } from './custom-shipping-address.component';
import { defaultSimulateCartSpinnerLayoutConfig } from './default-simulate-spinner-layout.config';
import { defaultValidateCartSpinnerLayoutConfig } from './default-validate-spinner-layout.config';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';

@NgModule({
  declarations: [CustomShippingAddressComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomAddressFormModule,
    SpinnerModule,
    I18nModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CustomCardModule,
    CustomIconsModule,
    FormErrorsModule,
  ],
  providers: [
    provideConfig(defaultValidateCartSpinnerLayoutConfig),
    provideConfig(defaultSimulateCartSpinnerLayoutConfig),
    provideConfig({
      cmsComponents: {
        CheckoutShippingAddress: {
          component: CustomShippingAddressComponent,
          guards: [CartNotEmptyGuard],
        },
      } as CMSComponentConfig,
    }),
  ],
  entryComponents: [CustomShippingAddressComponent],
  exports: [CustomShippingAddressComponent],
})
export class CustomShippingAddressModule { }
