import { Component } from '@angular/core';
import { AnonymousConsentOpenDialogComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-anonymous-consent-open-dialog',
  templateUrl: './custom-anonymous-consent-open-dialog.component.html',
})
export class CustomAnonymousConsentOpenDialogComponent extends AnonymousConsentOpenDialogComponent {

}
