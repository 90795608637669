<div
  *ngIf="flyout && node?.children.length > 1"
  class="back is-open"
  (click)="back()"
>
  <h5>
    <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
    {{ 'common.back' | cxTranslate }}
  </h5>
</div>

<ng-container *ngFor="let child of node?.children">
  <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
  </ng-container>
</ng-container>

<!-- we generate links in a recursive manner -->
<ng-template #nav let-node="node" let-depth="depth">
  <nav
    (click)="toggleOpen($event)"
    (mouseenter)="onMouseEnter($event)"
    (keydown.space)="toggleOpen($event)"
    (keydown.esc)="back()"
    class="is-open"
  >

    <cx-generic-link
      *ngIf=" node.url && (!node.children || node.children?.length === 0); else heading "
      [url]="node.url"
      [target]="node.target"
      [style]="node.styleAttributes"
      [class]="node.styleClasses"
    >
      {{ node.title }}
      <cx-icon *ngIf="flyout && node.children?.length > 0" [type]="iconType.CARET_DOWN"></cx-icon>
    </cx-generic-link>

    <ng-template #heading>
      <h5 class="user-menu"
          [attr.aria-label]="node.title"
          [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1"
      >
        <i class="icon-ico-user-color"></i>
         <cx-icon *ngIf="flyout && node.children?.length > 0" [type]="iconType.CARET_DOWN"></cx-icon>
      </h5>
    </ng-template>

    <!-- we add a wrapper to allow for better layout handling in CSS -->
    <div class="wrapper wrapper-menu" *ngIf="node.children?.length > 0">
      <cx-generic-link *ngIf="node.url" [url]="node.url" [target]="node.target" class="all">
        {{ 'navigation.shopAll' | cxTranslate: {navNode: node.title} }}
      </cx-generic-link>
      <div
        class="childs"
        [attr.depth]="getTotalDepth(node)"
        [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
        [attr.columns]="getColumnCount(node.children?.length)"
      >
        <ng-container *ngFor="let child of node.children">
          <ng-container *ngIf="child.url !== this.logoutUrl else logout">
            <ng-container
              *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }"
            >
            </ng-container>
          </ng-container>
          <ng-template #logout>
            <a class="logout-link" (click)="removeBaseStore()">{{child.title}}</a>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </nav>
</ng-template>
