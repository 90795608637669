import { NgModule } from '@angular/core';
import { BaseStorefrontModule } from '@spartacus/storefront';
import { SpartacusFeaturesModule } from 'src/app/spartacus/spartacus-features.module';
import { CustomSharedSpartacusModuleProviders } from 'src/app/spartacus/spartacus.module';
import { SpartacusConfigurationModule } from './spartacus-configuration.module';
import { SpartacusCustomModule } from './spartacus-custom.module';

@NgModule({
  declarations: [],
  providers: CustomSharedSpartacusModuleProviders,
  imports: [
    BaseStorefrontModule,
    SpartacusFeaturesModule,
    SpartacusConfigurationModule,
    SpartacusCustomModule
  ],
  exports: [BaseStorefrontModule]
})
export class SpartacusModule { }
