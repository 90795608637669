<ng-container *ngIf="categories$ | async as categories">
  <ng-container *ngIf="categories?.length">  
    {{ 'productCategories.title' | cxTranslate }}: 
    <ul>
      <li *ngFor="let category of categories">
        <h3>
          <a
            [routerLink]="{ cxRoute: 'category', params: category } | cxUrl"
            class="btn-link"
            [innerHTML]="category?.name"
          ></a>
        </h3>
      </li>
    </ul>
  </ng-container>
</ng-container>
