import { Language, Region } from '@spartacus/core';

export interface EmarsysContactInfoList {
  details?: EmarsysContactInfo;
}

export interface EmarsysContactInfo {
  name?: string;
  surnames?: string;
  birthDate?: string;
  email?: string;
  phoneNumber?: string;
  province?: string;
  language?: string;
  subscribed?: boolean;
  timestamp?: string;
  store?: string;
}

export const mainStoreMap = new Map<string, string>([
  // PROD
  ['www.osborne.es', '3920'],
  ['espacio-privado.osborne.es', '3921'],
  ['www.caviarderiofrio.com', '3923'],
  // QA
  ['s1-www.osborne.es', '3920'],
  ['s1-espacio-privado.osborne.es', '3921'],
  ['s1-www.caviarderiofrio.com', '3923'],
  // LOCAL
  ['l1-www.osborne.es:4300', '3920'],
  ['l1-espacio-privado.osborne.es:4200', '3921'],
  ['l1-www.caviarderiofrio.com:4400', '3923'],
]);

export const storeMap = new Map<string, string>([
  ['/centrodepreferencias', '3920'],
  ['/centrodepreferencias/bodegasmontecillo', '3924'],
  ['/centrodepreferencias/carlosbrandy', '3922'],
  ['/centrodepreferencias/nordesgin', '3925'],
  ['/centrodepreferencias/gingold999', '3926'],
  ['/centrodepreferencias/sanchezromerocarvajal', '3927'],
  ['/centrodepreferencias/11nudos', '3928'],
]);

export const emarsysProvinces: Region[] = [
  {
    'name': 'Álava',
    'isocode': '52',
  },
  {
    'name': 'Albacete',
    'isocode': '1',
  },
  {
    'name': 'Alicante',
    'isocode': '51',
  },
  {
    'name': 'Almería',
    'isocode': '50',
  },
  {
    'name': 'Asturias',
    'isocode': '22',
  },
  {
    'name': 'Ávila',
    'isocode': '49',
  },
  {
    'name': 'Badajoz',
    'isocode': '48',
  },
  {
    'name': 'Baleares',
    'isocode': '2',
  },
  {
    'name': 'Barcelona',
    'isocode': '47',
  },
  {
    'name': 'Burgos',
    'isocode': '46',
  },
  {
    'name': 'Cáceres',
    'isocode': '45',
  },
  {
    'name': 'Cádiz',
    'isocode': '44',
  },
  {
    'name': 'Cantabria',
    'isocode': '18',
  },
  {
    'name': 'Castellón',
    'isocode': '43',
  },
  {
    'name': 'Ceuta',
    'isocode': '6',
  },
  {
    'name': 'Ciudad Real',
    'isocode': '42',
  },
  {
    'name': 'Córdoba',
    'isocode': '41',
  },
  {
    'name': 'Cuenca',
    'isocode': '39',
  },
  {
    'name': 'Gerona',
    'isocode': '38',
  },
  {
    'name': 'Granada',
    'isocode': '37',
  },
  {
    'name': 'Guadalajara',
    'isocode': '36',
  },
  {
    'name': 'Guipúzcoa',
    'isocode': '35',
  },
  {
    'name': 'Huelva',
    'isocode': '34',
  },
  {
    'name': 'Huesca',
    'isocode': '33',
  },
  {
    'name': 'Jaén',
    'isocode': '32',
  },
  {
    'name': 'La Coruña',
    'isocode': '40',
  },
  {
    'name': 'La Rioja',
    'isocode': '29',
  },
  {
    'name': 'Las Palmas',
    'isocode': '4',
  },
  {
    'name': 'León',
    'isocode': '31',
  },
  {
    'name': 'Lérida',
    'isocode': '30',
  },
  {
    'name': 'Lugo',
    'isocode': '28',
  },
  {
    'name': 'Madrid',
    'isocode': '27',
  },
  {
    'name': 'Málaga',
    'isocode': '26',
  },
  {
    'name': 'Melilla',
    'isocode': '5',
  },
  {
    'name': 'Murcia',
    'isocode': '25',
  },
  {
    'name': 'Navarra',
    'isocode': '24',
  },
  {
    'name': 'Orense',
    'isocode': '23',
  },
  {
    'name': 'Palencia',
    'isocode': '21',
  },
  {
    'name': 'Pontevedra',
    'isocode': '20',
  },
  {
    'name': 'Salamanca',
    'isocode': '19',
  },
  {
    'name': 'Segovia',
    'isocode': '17',
  },
  {
    'name': 'Sevilla',
    'isocode': '16',
  },
  {
    'name': 'Soria',
    'isocode': '15',
  },
  {
    'name': 'Sta. Cruz Tenerife',
    'isocode': '3',
  },
  {
    'name': 'Tarragona',
    'isocode': '14',
  },
  {
    'name': 'Teruel',
    'isocode': '13',
  },
  {
    'name': 'Toledo',
    'isocode': '12',
  },
  {
    'name': 'Valencia',
    'isocode': '11',
  },
  {
    'name': 'Valladolid',
    'isocode': '10',
  },
  {
    'name': 'Vizcaya',
    'isocode': '9',
  },
  {
    'name': 'Zamora',
    'isocode': '8',
  },
  {
    'name': 'Zaragoza',
    'isocode': '7',
  }
];

export const languageGroups = [
  // Osborne Pública y Privada, Bodegas Montecillo, Gingold999, Riofrio, 11 Nudos
  { stores: ['3920', '3921', '3924', '3926', '3923', '3928'], isocode: ['es', 'en'] },
  // Carlos I
  { stores: ['3922'], isocode: ['en', 'de', 'es'] },
  // Nordés
  { stores: ['3925'], isocode: ['es', 'es-GT', 'en', 'de', 'it', 'pt'] },
  // Sánchez Romero Carvajal
  { stores: ['3927'], isocode: ['es'] }
];

export const emarsysLanguages: Language[] = [
  {
    'name': 'preferenceCenter.languages.en', 
    'isocode': 'en',
  },
  {
    'name': 'preferenceCenter.languages.fr', 
    'isocode': 'fr',
  },
  {
    'name': 'preferenceCenter.languages.gt', 
    'isocode': 'es-GT',
  },
  {
    'name': 'preferenceCenter.languages.de', 
    'isocode': 'de',
  },
  {
    'name': 'preferenceCenter.languages.it', 
    'isocode': 'it',
  },
  {
    'name': 'preferenceCenter.languages.pt', 
    'isocode': 'pt',
  },
  {
    'name': 'preferenceCenter.languages.es', 
    'isocode': 'es',
  },
]
