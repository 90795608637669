import { ModuleWithProviders, NgModule } from '@angular/core';
import { CustomFriendsUsersStoreModule } from './store/custom-friends-users-store.module';

@NgModule({
    imports: [CustomFriendsUsersStoreModule],
})
export class CustomFriendsUsersModule {
    static forRoot(): ModuleWithProviders<CustomFriendsUsersStoreModule> {
        return {
            ngModule: CustomFriendsUsersStoreModule,
        };
    }
}
