import { Injectable } from '@angular/core';
import {
  Address, CommandService, EventService,
  OCC_USER_ID_ANONYMOUS,
  ProcessSelectors,
  QueryService,
  StateWithProcess,
  StateWithUser,
  UserIdService
} from '@spartacus/core';
import { CustomCheckoutActions } from '../store/actions';
import { REDSYS_LOAD_PAYMENT_PROCESS_ID } from "../store/custom-checkout-state";
import { select, Store } from "@ngrx/store";
import { CheckoutPaymentConnector, CheckoutPaymentService } from '@spartacus/checkout/base/core';
import { Order, OrderPlacedEvent } from '@spartacus/order/root';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutQueryFacade, CheckoutQueryResetEvent } from '@spartacus/checkout/base/root';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { PLACED_ORDER_PROCESS_ID, StateWithCheckout } from '../../cart/store/checkout-state';
import { CustomCheckoutConnector } from '../connectors/custom-checkout.connector';
import { map, take } from 'rxjs/operators';
import { ActiveCartService } from '@spartacus/cart/base/core';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutPaymentService extends CheckoutPaymentService {

  private saveCardData = new BehaviorSubject<boolean>(undefined);

  constructor(
    protected store: Store<StateWithUser | StateWithProcess<void>>,
    protected activeCartFacade: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected queryService: QueryService,
    protected commandService: CommandService,
    protected eventService: EventService,
    protected checkoutPaymentConnector: CheckoutPaymentConnector,
    protected checkoutQueryFacade: CheckoutQueryFacade,
    protected checkoutConnector: CustomCheckoutConnector,
    protected activeCartService: ActiveCartService,
    protected checkoutStore: Store<StateWithCheckout | StateWithProcess<void>>,
  ) {
    super(activeCartFacade, userIdService, queryService, commandService, eventService, checkoutPaymentConnector, checkoutQueryFacade);
  }

  setSaveCardData(saveCard: boolean): void {
    this.saveCardData.next(saveCard);
  }

  getSaveCardData(): Observable<boolean> {
    return this.saveCardData.asObservable();
  }

  createBillingAddress(billingAddress: Address): void {
    if (this.actionAllowed()) {
      let userId;
      this.userIdService
        .getUserId()
        .subscribe((occUserId) => (userId = occUserId))
        .unsubscribe();
      let cartId;
      this.activeCartService
        .getActiveCartId()
        .subscribe((activeCartId) => (cartId = activeCartId))
        .unsubscribe();
      if (userId && cartId) {
        this.checkoutStore.dispatch(
          new CustomCheckoutActions.CreateBillingAddress({
            userId,
            cartId,
            billingAddress,
          })
        );
      }
    }
  }

  /**
   * Set billing address in cart
   * @param billingAddressId : id of billing address to be set
   */
  setBillingAddress(billingAddressId: string): void {
    if (this.actionAllowed()) {
      let userId;
      this.userIdService
        .getUserId()
        .subscribe((occUserId) => (userId = occUserId))
        .unsubscribe();
      let cartId;
      this.activeCartService
        .getActiveCartId()
        .subscribe((activeCartId) => (cartId = activeCartId))
        .unsubscribe();
      if (userId && cartId) {
        this.checkoutStore.dispatch(
          new CustomCheckoutActions.SetBillingAddress({
            userId,
            cartId,
            billingAddressId,
          })
        );
      }
    }
  }

  /**
   * Unset billing address from cart
   */
  unsetBillingAddress(): void {
    if (this.actionAllowed()) {
      let userId;
      this.userIdService
        .getUserId()
        .subscribe((occUserId) => (userId = occUserId))
        .unsubscribe();
      let cartId;
      this.activeCartService
        .getActiveCartId()
        .subscribe((activeCartId) => (cartId = activeCartId))
        .unsubscribe();
      if (userId && cartId) {
        this.checkoutStore.dispatch(
          new CustomCheckoutActions.UnsetBillingAddress({
            userId,
            cartId,
          })
        );
      }
    }
  }

  /**
   * Init Redsys payment
   */
  initRedsysPayment(saveCard: boolean): void {
    if (this.actionAllowed()) {
      combineLatest([
        this.userIdService.getUserId(),
        this.activeCartFacade.takeActiveCartId()
      ]).pipe(
        take(1),
        map(([userId, cartId]) => {
          if (userId && cartId) {
            (<Store<StateWithProcess<void>>>this.store).dispatch(
              new CustomCheckoutActions.LoadRedsysPayment({
                userId,
                cartId,
                saveCard
              })
            );
          }
        })
      ).subscribe();  
    }
  }

  /**
   * Returns the load Redsys payment loading flag
   */
  getRedsysPaymentLoading(): Observable<boolean> {
    return (<Store<StateWithProcess<void>>>this.store).pipe(
      select(
        ProcessSelectors.getProcessLoadingFactory(
          REDSYS_LOAD_PAYMENT_PROCESS_ID
        )
      )
    );
  }

  /**
   * Returns the load Redsys payment success flag
   */
  getRedsysPaymentSuccess(): Observable<boolean> {
    return (<Store<StateWithProcess<void>>>this.store).pipe(
      select(
        ProcessSelectors.getProcessSuccessFactory(
          REDSYS_LOAD_PAYMENT_PROCESS_ID
        )
      )
    );
  }

  /**
   * Returns the load Redsys payment state
   */
  getRedsysPaymentState(): Observable<any> {
    return (<Store<StateWithProcess<void>>>this.store).pipe(
      select(
        ProcessSelectors.getProcessStateFactory(
          REDSYS_LOAD_PAYMENT_PROCESS_ID
        )
      )
    );
  }

  /**
   * Returns the load Redsys payment error flag
   */
  getRedsysPaymentError(): Observable<boolean> {
    return (<Store<StateWithProcess<void>>>this.store).pipe(
      select(
        ProcessSelectors.getProcessErrorFactory(
          REDSYS_LOAD_PAYMENT_PROCESS_ID
        )
      )
    );
  }

  /**
   * Dispatch place order success action,
   * handy for when an order is paid in an external site,
   * and then come back directly to the order confirmation page,
   * this will push order confirmation events
   * @param order : order to confirm
   */
  setPlaceOrderSuccess(order: Order): void {
    this.eventService.dispatch<OrderPlacedEvent>(
      {
        order: order
      },
      OrderPlacedEvent
    );
  }

  actionAllowed(): boolean {
    let userId;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    let isGuestCart;
    this.activeCartFacade
      .isGuestCart()
      .subscribe((guestCart) => (isGuestCart = guestCart))
      .unsubscribe();
    return (userId && userId !== OCC_USER_ID_ANONYMOUS) || isGuestCart;
  }

  /**
   * Clear checkout data
   */
  clearCheckoutData(): void {
    this.eventService.dispatch<CheckoutQueryResetEvent>(
      CheckoutQueryResetEvent
    );
  }

  /**
   * Returns the place or schedule replenishment order's loading flag
   */
  getPlaceOrderLoading(): Observable<boolean> {
    return (<Store<StateWithProcess<void>>>this.store).pipe(
      select(
        ProcessSelectors.getProcessErrorFactory(
          PLACED_ORDER_PROCESS_ID
        )
      )
    );
  }

  /**
   * Returns the place or schedule replenishment order's success flag
   */
  getPlaceOrderSuccess(): Observable<boolean> {
    return (<Store<StateWithProcess<void>>>this.store).pipe(
      select(
        ProcessSelectors.getProcessErrorFactory(
          PLACED_ORDER_PROCESS_ID
        )
      )
    );
  }

  /**
   * Returns the place or schedule replenishment order's error flag
   */
  getPlaceOrderError(): Observable<boolean> {
    return (<Store<StateWithProcess<void>>>this.store).pipe(
      select(
        ProcessSelectors.getProcessErrorFactory(
          PLACED_ORDER_PROCESS_ID
        )
      )
    );
  }
}