import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCarouselBannerSliderComponent } from './custom-carousel-banner-slider.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomCarouselBannerModule } from '../custom-carousel-banner/custom-carousel-banner.module';
import { PageComponentModule } from '@spartacus/storefront';
import { CustomCarouselModule } from '../../../../spartacus/custom/cms-structure/shared/components/custom-carousel/custom-carousel.module';

@NgModule({
  declarations: [CustomCarouselBannerSliderComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomCarouselBannerSliderComponent: {
          component: CustomCarouselBannerSliderComponent,
        },
      },
    } as CmsConfig),
    CustomCarouselBannerModule,
    CustomCarouselModule,
    PageComponentModule,
  ],
})
export class CustomCarouselBannerSliderModule {}
