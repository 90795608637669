import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { BoxItemCategoriesComponent } from './box-item-categories.component';
import { MediaModule, OutletModule, PageComponentModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';



@NgModule({
  declarations: [BoxItemCategoriesComponent],
  imports: [
    CommonModule,
    PageComponentModule,
    OutletModule,
    I18nModule,
    RouterModule,
    UrlModule,
    CustomGenericLinkModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BoxItemCategoriesComponent: {
          component: BoxItemCategoriesComponent
        }
      }
    } as CmsConfig)
  ],
  entryComponents: [BoxItemCategoriesComponent],
  exports: [BoxItemCategoriesComponent],
})
export class BoxItemCategoriesModule { }
