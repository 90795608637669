<div
  class="cx-mini-cart-preview-backdrop"
  (click)="close()"
>
</div>
<div class="cx-mini-cart-preview-content">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">
      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
    </span>
  </button>
  <ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="cart.entries?.length; else emptyCart">
      <div class="cx-mini-cart-preview-body">
        <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
          <app-custom-promotions [promotions]="orderPromotions"></app-custom-promotions>
        </ng-container>
        <ng-container *ngIf="orderAppliedPromotions$ | async as orderAppliedPromotions">
          <app-custom-promotions class="cx-promotions-applied" [promotions]="orderAppliedPromotions"></app-custom-promotions>
        </ng-container>
        <cx-cart-item-list
          [items]="cart.entries"
          [readonly]="true"
          [promotionLocation]="promotionLocation"
          [remove]="false"
          [displayUnitSlim]="true"
        ></cx-cart-item-list>
      </div>
      <div
        class="cx-cart-preview-total"
      >
        {{ cart.subTotal?.formattedValue }}
      </div>
      <a
        [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
        class="btn btn-block btn-action"
        (click)="close()"
        autofocus
      >
        {{ 'miniCart.preview.proceedToCheckout' | cxTranslate }}
      </a>
      <a
        [routerLink]="{ cxRoute: 'cart' } | cxUrl"
        class="btn btn-block btn-default"
        (click)="close()"
      >
        {{ 'miniCart.preview.viewCart' | cxTranslate }}
      </a>
    </ng-container>
    <ng-template #emptyCart>
      <div class="cx-cart-preview-empty">
        {{ 'miniCart.preview.empty' | cxTranslate }}
      </div>
    </ng-template>
  </ng-container>
</div>
