import { LayoutConfig, DIALOG_TYPE } from "@spartacus/storefront";
import { CustomPopupShippingComponent } from "../../user/popup-shipping/custom-popup-shipping.component";

export const customShippingDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        HEADER_SHIPPING: {
          inlineRoot: true,
          component: CustomPopupShippingComponent,
          dialogType: DIALOG_TYPE.DIALOG,
          multi: true
       },
    },
  };