import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {CmsComponentData, LAUNCH_CALLER, LaunchDialogService} from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsCustomBoxLinkImageItemComponent } from './custom-box-link-image-item.model';

@Component({
  selector: 'app-custom-box-link-image-item',
  templateUrl: './custom-box-link-image-item.component.html',
  styleUrls: ['./custom-box-link-image-item.component.scss'],
})
export class CustomBoxLinkImageItemComponent implements OnInit {
  @Input() item: CmsCustomBoxLinkImageItemComponent;
  @HostBinding('class') styleClasses: string;
  @HostBinding('class.alternate') alternate = true;
  data$: Observable<CmsCustomBoxLinkImageItemComponent>;
  @ViewChild('open') element: ElementRef;
  modalRef;

  constructor(
    public component: CmsComponentData<CmsCustomBoxLinkImageItemComponent>,
    public launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.data$ = (this.item ? of(this.item) : this.component.data$).pipe(
      tap((data) => {
        this.initComponent(data);
      }),
    );
  }

  initComponent(component: CmsCustomBoxLinkImageItemComponent): void{
    this.styleClasses = component.styleClasses ?? '';
    this.alternate = component.titleHover === true || component.titleHover === 'true';
  }

  getTarget(target: string | boolean): string | null {
    return target === 'true' || target === true ? '_blank' : null;
  }

  openModal(): void {
    //CustomModalComponent
    this.modalRef = this.launchDialogService.openDialog(
      LAUNCH_CALLER.BOX_LINK_IMAGE_ITEM,
      this.element,
      this.vcr);
  }
}
