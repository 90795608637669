import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { ActionsSubject } from '@ngrx/store';
import {ActivatedRouterStateSnapshot, CmsService, createFrom, EventService} from '@spartacus/core';
import { Observable } from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import { CustomNavigationEvent } from './custom-navigation.event';

/**
 * A builder for the NavigationEvent
 */
@Injectable({
  providedIn: 'root',
})
export class CustomNavigationEventBuilder {
  constructor(
    protected actions: ActionsSubject,
    protected eventService: EventService,
    protected cmsService: CmsService
  ) {
    this.register();
  }

  /**
   * Registers events
   */
  protected register(): void {
    this.eventService.register(CustomNavigationEvent, this.buildNavigationEvent());
  }

  /**
   * Builds the navigation events
   */
  protected buildNavigationEvent(): Observable<CustomNavigationEvent> {
    return this.getNavigatedEvent().pipe(
      withLatestFrom(this.cmsService.getCurrentPage()),
      map(([state, page]) => {
          return createFrom(CustomNavigationEvent, {
            event: 'navigation',
            context: state.context,
            semanticRoute: state.semanticRoute,
            url: state.url,
            params: state.params,
          })
        }
      ),
    );
  }

  private getNavigatedEvent(): Observable<ActivatedRouterStateSnapshot> {
    return this.actions.pipe(
      ofType<RouterNavigatedAction<ActivatedRouterStateSnapshot>>(
        ROUTER_NAVIGATED,
      ),
      map((event) => event.payload.routerState),
    );
  }
}
