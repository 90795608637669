import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlSanitizePipe } from './html-sanitize.pipe';

@NgModule({
  declarations: [HtmlSanitizePipe],
  imports: [
    CommonModule
  ],
  exports: [HtmlSanitizePipe],
})
export class HtmlSanitizeModule { }
