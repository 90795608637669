<div class="">
  <!-- Modal Header -->
  <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
          {{ 'giftbox.popupAddProductToCartWithGiftbox.title' | cxTranslate }}
      </div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="close('Close')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
  </div>
  <!-- Modal Body -->
  <div class="cx-dialog-body modal-body">
    {{ 'giftbox.popupAddProductToCartWithGiftbox.message' | cxTranslate }}
  </div>
  <div class="cx-dialog-buttons">
      <div class="row p-4">
          <div class="cx-dialog-actions offset-md-4 col-md-4">
              <button
              type="button"
              class="btn btn-block btn-send"
              (click)="accept(false)">
                {{ 'giftbox.popupAddProductToCartWithGiftbox.cancel' | cxTranslate }}
              </button>
          </div>
          <div class="cx-dialog-actions col-md-4">
              <button
                cxAutoFocus
                type="button"
                class="btn btn-block btn-send"
                (click)="accept(true)"
              >
                {{ 'giftbox.popupAddProductToCartWithGiftbox.continue' | cxTranslate }}
              </button>
          </div>
      </div>
  </div>
</div>
