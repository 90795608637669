<ng-container *ngIf="giftCardProduct; else giftCardFromCart">
  <h4 class="cx-checkout-title">
    {{ "checkoutShipping.presentCard" | cxTranslate }}
  </h4>
  <div class="wrapper-gift">
    <cx-media [container]="giftCardProduct?.images[0]"></cx-media>
      <div class="details">
        <textarea class="form-control" [value]="giftCardProduct?.giftCardMessage?.length ? giftCardProduct.giftCardMessage : ''" readonly rows="4"></textarea>
      </div>
  </div>
</ng-container>

<ng-template #giftCardFromCart>
  <ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="cart.giftCardProduct">
      <h4 class="cx-checkout-title">
        {{ "checkoutShipping.presentCard" | cxTranslate }}
      </h4>
      <div class="wrapper-gift">
        <cx-media [container]="cart.giftCardProduct?.images[0]"></cx-media>
        <div class="details">
          <span class="uppercase">{{ cart.giftCardProduct?.name }}</span>
          <span>{{ cart.giftCardProduct?.price?.formattedValue }}</span>
          <span>{{cart.giftCardProduct?.giftCardMessage}}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

