import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomBaseStoreComponent } from '../custom-base-store.component';
import { CmsConfig, ConfigModule, I18nModule, StateModule } from '@spartacus/core';
import { CustomBaseStoreEffects } from './effects/custom-base-store.effects';
import { EffectsModule } from '@ngrx/effects';
import { BASE_STORE_FEATURE } from './custom-base-store.state';
import { StoreModule } from '@ngrx/store';
import { customBaseStoreReducer, customBaseStoreReducerProvider, metaReducers, reducerToken } from './reducers/custom-base-store.reducers';


export const effects: any[] = [
  CustomBaseStoreEffects
];

@NgModule({
  declarations: [CustomBaseStoreComponent],
  imports: [
    CommonModule,
    I18nModule,
    StateModule,
    StoreModule.forFeature(BASE_STORE_FEATURE, customBaseStoreReducer, { metaReducers }),
    EffectsModule.forFeature(effects),
    ConfigModule.withConfig({
      cmsComponents: {
        ShippingHeaderComponent: {
          component: CustomBaseStoreComponent,
        },
      },

    } as CmsConfig),
  ],
  providers: [customBaseStoreReducerProvider],
})
export class CustomBaseStoreStoreModule {}

