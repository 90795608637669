import { Injectable } from '@angular/core';
import { Config } from '@spartacus/core';
import { RecaptchaConfig } from 'src/app/spartacus/features/recaptcha/recaptcha.model';

@Injectable({
  providedIn: 'root',
  useExisting: Config,
})
export abstract class CustomExtraAppConfig extends Config {
  customExtraApp?: {
    /**
     * Enable customer register
     */
    enabledCustomerRegister?: boolean;
    /**
     * Allow friends
     */
    allowFriends?: boolean;
    /**
     * Extended login info, enable to display more information in login page.
     */
    extendedLoginInfo?: boolean;
    /**
     * Enable handle billing in first checkout step.
     */
    checkoutBilling?: boolean;
    /**
     * Enable mini cart preview or go to cart instead
     */
    miniCartPreview?: boolean;
    /**
     * Enable show age confirmation popup
     */
    showAgePopup?: boolean;

    /**
     * Enable Google reCAPTCHA plugin.
     * Version is v2 by default if not set
     */
    reCaptcha?: RecaptchaConfig;
  };
}
