import { Component } from '@angular/core';
import { Category } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CustomCurrentProductService
} from "../../../../spartacus/custom/core/product/services/custom-current-product.service";

@Component({
  selector: 'app-custom-product-categories',
  templateUrl: './custom-product-categories.component.html'
})
export class CustomProductCategoriesComponent {
  categories$: Observable<Category[]> = this.currentProductService.getProduct().pipe(
    map(product => product?.categories?.filter(category =>
      category.code !== 'category_root'
    ))
  );

  constructor(protected currentProductService: CustomCurrentProductService) {}
}
