import { Component, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomCheckoutDeliveryService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-delivery.service';
import { CustomGiftCardComponent } from '../../../../../../custom/cms-components/checkout/custom-gift-card/custom-gift-card.component';
import { GiftCard } from '../../../../../../custom/cms-components/checkout/custom-gift-card/model/custom-gift-card.model';
import { CustomGiftCardService } from '../../../../../../custom/cms-components/checkout/custom-gift-card/services/custom-gift-card.service';
import { CheckoutConfigService, CheckoutDeliveryModeComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryModesFacade, CheckoutSupportedDeliveryModesQueryReloadEvent } from '@spartacus/checkout/base/root';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { Subscription } from 'rxjs';
import { CustomCheckoutStepsEventBuilder } from 'src/app/spartacus/features/tracking/custom-events/checkout/checkout-steps/custom-checkout-steps-event.builder';
import { EventService } from '@spartacus/core';

@Component({
  selector: 'app-custom-delivery-mode',
  templateUrl: './custom-delivery-mode.component.html',
})
export class CustomDeliveryModeComponent extends CheckoutDeliveryModeComponent {

  @ViewChild('giftCardComponent') giftCardComponent: CustomGiftCardComponent;
  continueButtonPressed = false;
  selectedGiftCard: GiftCard;

  cartDeliveryNoteObservations: string;

  mode: UntypedFormGroup = this.fb.group({
    deliveryModeId: ['', Validators.required],
    deliveryNoteObservations: ['', Validators.maxLength(120)],
  });

  protected subscriptions = new Subscription();

  constructor(
    protected fb: FormBuilder,
    protected checkoutDeliveryService: CustomCheckoutDeliveryService,
    protected checkoutConfigService: CheckoutConfigService,
    protected activatedRoute: ActivatedRoute,
    protected checkoutStepService: CheckoutStepService,
    protected activeCartService: CustomActiveCartService,
    protected customGiftCardService: CustomGiftCardService,
    protected userAccountFacade: UserAccountFacade,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected activeCartFacade: ActiveCartFacade,
    protected eventService: EventService,
    protected customCheckoutStepsEventBuilder?: CustomCheckoutStepsEventBuilder,
  ) { 
    super(fb, checkoutConfigService, activatedRoute, checkoutStepService, checkoutDeliveryModesFacade, activeCartFacade);
    this.eventService.dispatch(
      {},
      CheckoutSupportedDeliveryModesQueryReloadEvent,
    );
  }

  ngOnInit(): void {

    this.subscriptions.add(
      this.checkoutDeliveryService.getDeliveryNoteObservations()
      .subscribe((deliveryNote) => {
        if (deliveryNote !== undefined) {
          this.mode.controls['deliveryNoteObservations'].setValue(deliveryNote);
          this.cartDeliveryNoteObservations = deliveryNote;
        }
      })
    );

    this.subscriptions.add(
      this.customGiftCardService.getSelectedGiftCard().subscribe((selectedGiftCard: GiftCard) => {
        this.selectedGiftCard = selectedGiftCard;
      })
    );
    this.busy$.next(false);
  }

  next(): void {
    if (this.selectedGiftCard?.requiresMessage) {
      const giftCardForm = this.giftCardComponent.giftCardForm;
      if (giftCardForm.valid) {
        this.submitForm();
      } else {
        giftCardForm.markAllAsTouched();
      }
    } else {
      this.submitForm();
    }
  }

  submitForm(): void {
    if (this.mode.valid && this.mode.value) {
      this.setDeliveryNotes();
      this.continueButtonPressed = true;
      this.checkoutStepService.next(this.activatedRoute);
    }
  }

  back(): void {
    this.setDeliveryNotes();
    this.checkoutStepService.back(this.activatedRoute);
  }

  setDeliveryNotes(): void {
    const deliveryNoteObservationsInputValue = this.mode.controls['deliveryNoteObservations'].value;
    this.cartDeliveryNoteObservations = deliveryNoteObservationsInputValue;
    this.activeCartService.addDeliveryNotes(deliveryNoteObservationsInputValue);
    this.busy$.next(false);
  }

  get deliveryModeInvalid(): boolean {
    return this.mode.controls.deliveryModeId.invalid;
  }

}
