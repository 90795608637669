import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { CustomNewsDetailsModel, CustomNewsListModel, CustomNewsModel } from '../custom-news.model';
import { CustomNewsState, NEWS_FEATURE, StateWithNews } from '../custom-news.state';
import { Observable, of } from 'rxjs';

export const getNewsSliderState: MemoizedSelector<StateWithNews, CustomNewsState> =
  createFeatureSelector<CustomNewsState>(NEWS_FEATURE);

export const getNewsSliderLoaderState: MemoizedSelector<StateWithNews, LoaderState<CustomNewsListModel>> =
  createSelector(getNewsSliderState, (state) => state.slider);

export const getNewsSlider: MemoizedSelector<StateWithNews, CustomNewsListModel> =
  createSelector(getNewsSliderLoaderState, (state) => StateUtils.loaderValueSelector(state));


export const getNewsSlides: MemoizedSelector<StateWithNews, CustomNewsModel[]> =
  createSelector(getNewsSlider, (state) => state.newsList);


export const getNewsSliderLoading: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsSliderLoaderState, (state) => StateUtils.loaderLoadingSelector(state));

export const getNewsSliderError: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsSliderLoaderState, (state) => StateUtils.loaderErrorSelector(state));

export const getNewsSliderSuccess: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsSliderLoaderState, (state) => StateUtils.loaderSuccessSelector(state));
