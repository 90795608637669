import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CREDIT_CARD_FEATURE } from './store/credit-card.state';
import { CreditCardEffects } from './store/effects/credit-card.effects';
import { creditCardReducerToken, metaReducers, reducerCreditCardProvider } from './store/reducers/credit-card.reducer';
import { StateModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

export const effects: any[] = [CreditCardEffects];

@NgModule({
  imports: [
    CommonModule,
    StateModule,
    StoreModule.forFeature(CREDIT_CARD_FEATURE, creditCardReducerToken, { metaReducers }),
    EffectsModule.forFeature(effects),
    RouterModule,
  ],
  providers: [reducerCreditCardProvider],
})
export class CustomCreditCardStoreModule {}
