import {Component, EventEmitter, HostListener, Input, OnChanges, Output} from "@angular/core";
import {ProductUnitPrice} from "../../../../model/unit-price";

@Component({
  selector: 'app-custom-unit-selector',
  templateUrl: 'custom-unit-selector.component.html'
})
export class CustomUnitSelectorComponent implements OnChanges {
  @Input() unitPrices: ProductUnitPrice[];
  @Input() selectedUnitPrice?: ProductUnitPrice;
  @Input() showNostockText: boolean = false;
  @Output() selectionChanged: EventEmitter<ProductUnitPrice> = new EventEmitter<ProductUnitPrice>();

  showOptions: boolean = false;
  selectedOption?: ProductUnitPrice;

  ngOnChanges(): void {
    if (this.selectedUnitPrice) {
      this.selectedOption = this.selectedUnitPrice
    } else {
      this.selectedOption = this.unitPrices.length ? this.unitPrices[0] : undefined;
    }
  }

  selectOption(option: ProductUnitPrice): void {
    this.selectedOption = option;
    this.selectionChanged.emit(option);
  }

  toggleOptions(): void {
    this.showOptions = !this.showOptions;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('app-custom-unit-selector')) {
      this.showOptions = false;
    }
  }
}
