import {Injectable} from "@angular/core";
import {createFrom, StateEventService} from "@spartacus/core";
import {CustomUpdateNewsletterEvent} from "./custom-newsletter.events";
import {CustomNewsletterActions} from "../../../../../custom/cms-components/content/custom-newsletters/store/actions";
import {EcommerceType} from "../tag-manager-feature.model";

@Injectable({ providedIn: 'root' })
export class CustomNewsletterEventBuilder {
  constructor(protected stateEventService: StateEventService) {
    this.register();
  }

  protected register(): void {
    this.registerUpdateNewsletterEvents();
  }

  protected registerUpdateNewsletterEvents(): void {
    this.stateEventService.register({
      action: CustomNewsletterActions.UPDATE_NEWSLETTER_SUCCESS,
      event: CustomUpdateNewsletterEvent,
      factory: () =>
        createFrom(CustomUpdateNewsletterEvent, {
          event: EcommerceType.NEWSLETTER
        })
    });
  }
}
