import { Component, HostBinding, Input } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsBoxItemCategoriesComponent } from './box-item-categories.model';

@Component({
  selector: 'app-box-item-categories',
  templateUrl: './box-item-categories.component.html',
  styleUrls: ['./box-item-categories.component.scss']
})
export class BoxItemCategoriesComponent {
  @Input() item: CmsBoxItemCategoriesComponent;
  @HostBinding('class') styleClasses: string;

  data$: Observable<CmsBoxItemCategoriesComponent> = this.component.data$.pipe(
    tap((data) => (this.styleClasses = data.styleClasses))
  );
  constructor( public component: CmsComponentData<CmsBoxItemCategoriesComponent>){ }

  getTarget(data: CmsBoxItemCategoriesComponent): string | null {
    return data.external === 'true' || data.external === true ? '_blank' : null;
  }
}
