import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  FeatureConfigService,
  UserIdService,
  ConverterService,
  PRODUCT_NORMALIZER,
} from '@spartacus/core';
import { 
  ConsignmentEntry,
  ActiveCartFacade, CartItemComponentOptions,
  PromotionLocation
} from '@spartacus/cart/base/root';
import { MultiCartService, SelectiveCartService } from '@spartacus/cart/base/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomOrderEntry } from 'src/app/spartacus/custom/feature-libs/cart/root/models/cart.model';

@Component({
  selector: 'cx-cart-item-list',
  templateUrl: './custom-cart-item-list.component.html',
  styleUrls: ['./custom-cart-item-list.component.scss']
})

export class CustomCartItemListComponent implements OnInit, OnDestroy {
  @Input() remove = true;
  @Input() displayUnitSlim = false;

  private subscription = new Subscription();
  private userId: string;

  @Input() readonly = false;

  @Input() hasHeader = true;

  @Input() options: CartItemComponentOptions = {
    isSaveForLater: false,
    optionalBtn: null,
  };

  @Input() cartId: string;

  private _items: CustomOrderEntry[] = [];
  form: FormGroup = this.featureConfigService?.isLevel('3.1')
    ? new FormGroup({})
    : undefined;

  @Input('items')
  set items(items: CustomOrderEntry[]) {
    this.resolveItems(items);
    this.createForm();
  }
  get items(): CustomOrderEntry[] {
    return this._items;
  }

  @Input() promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;

  @Input('cartIsLoading') set setLoading(value: boolean) {
    if (!this.readonly) {
      // Whenever the cart is loading, we disable the complete form
      // to avoid any user interaction with the cart.
      value
        ? this.form.disable({ emitEvent: false })
        : this.form.enable({ emitEvent: false });
    }
  }

  @Input() warehousesCount?: number;

  /**
   * @deprecated since version 3.1
   * Use constructor(activeCartService: ActiveCartFacade, selectiveCartService: SelectiveCartService, featureConfigService: FeatureConfigService, userIdService: UserIdService, multiCartService: MultiCartService); instead
   */
  // TODO(#11037): Remove deprecated constructors
  constructor(
    activeCartService: ActiveCartFacade,
    selectiveCartService: SelectiveCartService
  );

  /**
   * @deprecated since version 3.2
   * Use constructor(activeCartService: ActiveCartFacade, selectiveCartService: SelectiveCartService, featureConfigService: FeatureConfigService, userIdService: UserIdService, multiCartService: MultiCartService); instead
   */
  // TODO(#11037): Remove deprecated constructors
  constructor(
    activeCartService: ActiveCartFacade,
    selectiveCartService: SelectiveCartService,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    featureConfigService: FeatureConfigService
  );

  constructor(
    activeCartService: ActiveCartFacade,
    selectiveCartService: SelectiveCartService,
    featureConfigService: FeatureConfigService,
    userIdService: UserIdService,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    multiCartService: MultiCartService
  );

  constructor(
    protected activeCartService: ActiveCartFacade,
    protected selectiveCartService: SelectiveCartService,
    public featureConfigService?: FeatureConfigService,
    protected userIdService?: UserIdService,
    protected multiCartService?: MultiCartService,
    private converterService?: ConverterService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.userIdService
        ?.getUserId()
        .subscribe((userId) => (this.userId = userId))
    );
  }

  /**
   * The items we're getting form the input do not have a consistent model.
   * In case of a `consignmentEntry`, we need to normalize the data from the orderEntry.
   */
  private resolveItems(items: CustomOrderEntry[]): void {
    if (!items) {
      this._items = [];
      return;
    }
    items = this.normalizeProductsInEntries(items);
    if (items.every((item) => item.hasOwnProperty('orderEntry'))) {
      this._items = items.map((consignmentEntry) => {
        const entry = Object.assign(
          {},
          (consignmentEntry as ConsignmentEntry).orderEntry
        );
        entry.quantity = consignmentEntry.quantity;
        return entry;
      });
    } else {
      // We'd like to avoid the unnecessary re-renders of unchanged cart items after the data reload.
      // OCC cart entries don't have any unique identifier that we could use in Angular `trackBy`.
      // So we update each array element to the new object only when it's any different to the previous one.
      if (this.featureConfigService?.isLevel('3.1')) {
        for (let i = 0; i < Math.max(items.length, this._items.length); i++) {
          if (JSON.stringify(this._items?.[i]) !== JSON.stringify(items[i])) {
            if (this._items[i] && this.form) {
              this.form.removeControl(this.getControlName(this._items[i]));
            }
            if (!items[i]) {
              this._items.splice(i, 1);
            } else {
              this._items[i] = items[i];
            }
          }
        }
      } else {
        this._items = items;
      }
    }
  }

  private normalizeProductsInEntries(entries: CustomOrderEntry[]): CustomOrderEntry[] {
    if ((!entries && !entries.length) || entries.some(entry => Boolean(entry.product?.slug))) {
      return entries;
    }
    entries = entries?.map((sourceEntry) => {
      if (!sourceEntry.product && !sourceEntry.packProduct) {
        return sourceEntry;
      }
      const targetEntry = {
        ...sourceEntry
      };
      if (sourceEntry.product) {
        targetEntry.product = this.converterService.convert(sourceEntry.product, PRODUCT_NORMALIZER);
      }
      if (sourceEntry.packProduct) {
        targetEntry.packProduct = this.converterService.convert(sourceEntry.packProduct, PRODUCT_NORMALIZER);
      }
      return targetEntry;
    }
    );
    return entries;
  }

  private createForm(): void {
    if (!this.featureConfigService?.isLevel('3.1')) {
      this.form = new FormGroup({});
    }

    this._items.forEach((item) => {
      const controlName = this.getControlName(item);
      const group = new FormGroup({
        entryNumber: new FormControl(item.entryNumber),
        quantity: new FormControl(item.quantity, { updateOn: 'blur' }),
      });

      this.form.addControl(controlName, group);

      // If we disable form group before adding, disabled status will reset
      // Which forces us to disable control after including to form object
      if (!item.updateable || this.readonly) {
        this.form.controls[controlName].disable();
      }
    });
  }

  protected getControlName(item: CustomOrderEntry): string {
    return item.entryNumber.toString();
  }

  removeEntry(item: CustomOrderEntry): void {
    if (this.selectiveCartService && this.options.isSaveForLater) {
      this.selectiveCartService.removeEntry(item);
    } else if (this.cartId && this.userId) {
      this.multiCartService?.removeEntry(
        this.userId,
        this.cartId,
        item.entryNumber
      );
    } else {
      this.activeCartService.removeEntry(item);
    }
    delete this.form.controls[this.getControlName(item)];
  }

  getControl(item: CustomOrderEntry): Observable<FormGroup> {
    return this.form.get(this.getControlName(item)).valueChanges.pipe(
      // eslint-disable-next-line import/no-deprecated
      startWith(null),
      map((value) => {
        if (item.updateable) {
          if (value && this.selectiveCartService && this.options.isSaveForLater) {
            this.selectiveCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          } else if (value && this.cartId && this.userId) {
            this.multiCartService?.updateEntry(
              this.userId,
              this.cartId,
              value.entryNumber,
              value.quantity
            );
          } else if (value) {
            this.activeCartService.updateEntry(value.entryNumber, value.quantity);
          }
        }
      }),
      map(() => this.form.get(this.getControlName(item)) as FormGroup)
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}