import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { ActiveCartService } from "@spartacus/cart/base/core";
import { Observable } from 'rxjs';
import { CustomOrderEntry } from 'src/app/spartacus/custom/feature-libs/cart/root/models/cart.model';

@Component({
  selector: 'cx-cart-totals',
  templateUrl: './custom-giftbox-cart-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomGiftboxCartTotalsComponent implements OnInit {
  cart$: Observable<Cart>;
  entries$: Observable<CustomOrderEntry[]>;

  constructor(protected activeCartService: ActiveCartService) {}

  ngOnInit(): void {
    this.cart$ = this.activeCartService.getActive();
    this.entries$ = this.activeCartService.getEntries();
  }
}
