import { ModuleWithProviders, NgModule } from '@angular/core';
import { CustomFriendsGuestStoreModule } from '../../../../custom/cms-components/myaccount/custom-friends/components/custom-register-friend-form/store/custom-friends-guest-store.module';
import { CustomFriendsStoreModule } from './store/custom-friends-store-checkout.module';
import { CustomFriendsEmailStoreModule } from '../../../../custom/cms-components/myaccount/custom-friends/components/custom-friend-email-form/store/custom-friends-email-store.module';

@NgModule({
    imports: [CustomFriendsStoreModule, CustomFriendsGuestStoreModule, CustomFriendsEmailStoreModule],
})
export class CustomFriendsModule {
    static forRoot(): ModuleWithProviders<CustomFriendsStoreModule> {
        return {
            ngModule: CustomFriendsStoreModule,
        };
    }
}
