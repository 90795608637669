import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomMultiCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-multi-cart.service';

/**
 * Guards the _restoreCart_ route.
 *
 * Takes care of routing the user to cart page (if code is provided) or redirects to the homepage.
 * If provided code is not the same as activeCartId, first sets it as current active cart id,
 * then services take care of loading this cart.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomRestoreCartGuard implements CanActivate {
  constructor(
    protected semanticPathService: SemanticPathService,
    protected router: Router,
    protected multiCartService: CustomMultiCartService,
    protected activeCartService: CustomActiveCartService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const cartCode = activatedRoute.params?.cartCode;
    if (!cartCode) {
      return of(this.redirectToPage(false));
    }
    return combineLatest([
      this.activeCartService.getActiveCartId(),
      this.activeCartService.isStable(),
    ]).pipe(
      filter(([_, loaded]) => loaded),
      map(([activeCartId]) => {
        if (cartCode !== activeCartId) {
          this.multiCartService.setActiveCartId(cartCode);
        }
        return this.redirectToPage(true);
      })
    );
  }

  /**
   * Redirect to cart if allow, otherwise to home
   */
  protected redirectToPage(allow: boolean): UrlTree {
    return this.router.parseUrl(this.semanticPathService.get(allow ? 'cart' : 'home'));
  }
}
