import { InjectionToken } from '@angular/core';
import { Converter } from '@spartacus/core';
import { Bill, BillHistoryList } from 'src/app/model/bill.model';

export const BILL_NORMALIZER = new InjectionToken<Converter<any, Bill>>(
  'BillNormalizer'
);

export const BILL_HISTORY_NORMALIZER = new InjectionToken<
  Converter<any, BillHistoryList>
>('BillHistoryNormalizer');
