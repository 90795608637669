<ng-container *ngIf="(mediaLinks$ | async) as mediaLinks">
  <div *ngIf="title" class="title">
    {{ title }}
  </div>
  <ul>
    <ng-container *ngFor="let mediaLink of mediaLinks">
      <li>
        <button
            [class]="btnClass"
            [title]="mediaLink.linkName"
            (click)="share(mediaLink.url)"
        >
          <cx-media
            *ngIf="mediaLink.media"
            [container]="mediaLink.media"
          ></cx-media>
      </button>
      </li>
    </ng-container>
  </ul>
</ng-container>
