<span class="info" *ngIf="productCode && !hasStock && !hideStockInfo">
    {{ 'addToCart.outOfStock' | cxTranslate }}
</span>
<ng-container *ngIf="productCode">
    <ng-container *ngIf="product">
      <app-custom-add-to-cart-unit
        [product]="product"
        [isPDP]="isPDP"
        [showNostockText]="showNostockText"
        [showQuantity]="showQuantity && !displayDefaultUnitOnly"
        [displayDefaultUnitOnly]="displayDefaultUnitOnly"
        [showAddToCartText]="showAddToCartText"
        (unitChanged)="handleUnitChange($event)">
      </app-custom-add-to-cart-unit>
    </ng-container>
</ng-container>
