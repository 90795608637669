import { InjectionToken, Provider } from '@angular/core';
import { CustomFriendEmailsState, FRIEND_EMAILS } from '../custom-friends-email.state';
import { CustomFriendsEmailsActions, FriendsEmailsActionTypes } from '../actions/custom-friends-email.action';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { CustomerEmailModel } from '../../CustomerEmail.model';
import { AuthActions, StateUtils } from '@spartacus/core';

export const initialState: CustomerEmailModel[] = [];

export function reducerFriendEmails(
  state = initialState,
  action: CustomFriendsEmailsActions,
): CustomerEmailModel[] {

  switch (action.type) {

    case FriendsEmailsActionTypes.SELECT_FRIEND_EMAILS || FriendsEmailsActionTypes.SELECT_ALL_FRIENDS_FAIL: {
      return action.payload ? action.payload : initialState;
    }

    case FriendsEmailsActionTypes.SELECT_FRIEND_EMAILS_SUCCESS || FriendsEmailsActionTypes.SELECT_ALL_FRIENDS_SUCCESS: {
      return action.payload ? action.payload : initialState;
    }

    case FriendsEmailsActionTypes.SELECT_FRIEND_EMAILS_FAIL || FriendsEmailsActionTypes.SELECT_ALL_FRIENDS: {
      return initialState;
    }
  }
  return state;
}

export function getReducers(): ActionReducerMap<CustomFriendEmailsState> {
  return {
    entities: StateUtils.loaderReducer<CustomerEmailModel[]>(FRIEND_EMAILS, reducerFriendEmails),
  };
}

export const reducerFriendEmailsToken:
  InjectionToken<ActionReducerMap<CustomFriendEmailsState>>
  = new InjectionToken<ActionReducerMap<CustomFriendEmailsState>>('FriendEmailsReducers');


export const reducerFriendsEmailsProvider: Provider = {
  provide: reducerFriendEmailsToken,
  useFactory: getReducers,
};

export function clearCustomFriendsEmailState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
      state = [];
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearCustomFriendsEmailState];



