import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { Observable, of, combineLatest } from 'rxjs';
import { filter, map, switchMap, withLatestFrom, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  CustomCartAddEntrySuccessEvent,
  CustomCartRemoveEntrySuccessEvent,
  CustomCartUpdateEntrySuccessEvent,
  CustomDeleteCartFailEvent,
  CustomDeleteCartSuccessEvent,
  CustomLoadCartFailEvent,
  CustomLoadCartSuccessEvent, CustomNavigateToCartEvent,
  CustomSetDocumentIdentifierFailEvent,
  CustomSetDocumentIdentifierSuccessEvent
} from './custom-cart.events';
import { CustomActiveCartService } from '../../../../custom/core/cart/facade/custom-active-cart.service';
import {
  createFrom,
  EventService,
  StateEventService
} from '@spartacus/core';
import { ProductCartItem, ProductCartItemPromotionData } from './CartEvents.model';
import { TagManagerFeatureService } from '../tag-manager-feature.service';
import { CustomCartActions } from 'src/app/spartacus/custom/core/cart/store/actions';
import { CustomCleanEcommerceEvent } from '../common/custom-common.events';
import { NavigationEvent } from "@spartacus/storefront";
import { EcommerceType } from '../tag-manager-feature.model';
import { CartActions } from '@spartacus/cart/base/core';
import { CartRemoveEntry, CartUpdateEntrySuccess } from '@spartacus/cart/base/core/store/actions/cart-entry.action';
import { Cart } from '@spartacus/cart/base/root';
import { CustomOrderEntry } from 'src/app/spartacus/custom/feature-libs/cart/root/models/cart.model';

/**
 * Registers events for the active cart
 */
@Injectable({ providedIn: 'root' })
export class CustomCartEventBuilder {
  constructor(
    protected actionsSubject: ActionsSubject,
    protected event: EventService,
    protected activeCartService: CustomActiveCartService,
    protected tagManagerFeatureService: TagManagerFeatureService,
    protected stateEventService: StateEventService
  ) {
    this.register();
  }

  /**
   * Registers events for the active cart
   */
  protected register(): void {
    this.registerNavigateToCartEvents();
    this.registerLoadCartEvents();
    this.registerDeleteCartEvents();
    this.registerAddCartEntryEvents();
    this.registerUpdateCartEntryEvents();
    this.registerRemoveCartEntryEvents();
    this.registerSetDocumentIdentifierEvents();
  }

  protected registerNavigateToCartEvents(): void {
    this.event.register(
      CustomNavigateToCartEvent,
      this.registerNavigateToCart(),
    );
  }

  protected registerLoadCartEvents(): void {
    this.stateEventService.register({
      action: CartActions.LOAD_CART_SUCCESS,
      event: CustomLoadCartSuccessEvent,
      factory: (action: CartActions.LoadCartSuccess) =>
        createFrom(CustomLoadCartSuccessEvent, {
          ...action.payload,
          cartCode: action.payload.cartId,
        }),
    });

    this.stateEventService.register({
      action: CartActions.LOAD_CART_FAIL,
      event: CustomLoadCartFailEvent,
      factory: (action: CartActions.LoadCartFail) =>
        createFrom(CustomLoadCartFailEvent, {
          ...action.payload,
          cartCode: action.payload.cartId,
        }),
    });
  }

  protected registerDeleteCartEvents(): void {
    this.stateEventService.register({
      action: CartActions.DELETE_CART_SUCCESS,
      event: CustomDeleteCartSuccessEvent,
      factory: (action: CartActions.DeleteCartSuccess) =>
        createFrom(CustomDeleteCartSuccessEvent, {
          ...action.payload,
          cartCode: action.payload.cartId,
        }),
    });

    this.stateEventService.register({
      action: CartActions.DELETE_CART_FAIL,
      event: CustomDeleteCartFailEvent,
      factory: (action: CartActions.DeleteCartFail) =>
        createFrom(CustomDeleteCartFailEvent, {
          ...action.payload,
          cartCode: action.payload.cartId,
        }),
    });
  }

  protected registerAddCartEntryEvents(): void {
    this.event.register(
      CustomCartAddEntrySuccessEvent,
      this.registerCustomAddEntry(),
    );
  }

  protected registerUpdateCartEntryEvents(): void {
    this.event.register(
      CustomCartAddEntrySuccessEvent,
      this.registerCustomUpdateEntry(),
    );
  }

  protected registerRemoveCartEntryEvents(): void {
    this.event.register(
      CustomCartUpdateEntrySuccessEvent,
      this.registerCustomRemoveEntry(),
    );
  }

  protected registerSetDocumentIdentifierEvents(): void {
    this.stateEventService.register({
      action: CustomCartActions.CUSTOM_SET_DOCUMENT_IDENTIFIER_SUCCESS,
      event: CustomSetDocumentIdentifierSuccessEvent,
      factory: (action: CustomCartActions.SetDocumentIdentifierSuccess) =>
        createFrom(CustomSetDocumentIdentifierSuccessEvent, {
          ...action.payload,
        }),
    });

    this.stateEventService.register({
      action: CustomCartActions.CUSTOM_SET_DOCUMENT_IDENTIFIER_FAIL,
      event: CustomSetDocumentIdentifierFailEvent,
      factory: (action: CustomCartActions.SetDocumentIdentifierFail) =>
        createFrom(CustomSetDocumentIdentifierFailEvent, {
          ...action.payload,
        }),
    });
  }

  /**
   * Register events for adding entry to the active cart
   */
  protected registerCustomAddEntry(): Observable<CustomCartAddEntrySuccessEvent> {
    return this.actionsSubject.pipe(
      ofType(CartActions.CART_ADD_ENTRY_SUCCESS),
      map((action: any) => action.payload),
      tap( () => this.event.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      switchMap(({ entry, quantityAdded }) => {
        return of(createFrom(CustomCartAddEntrySuccessEvent, {
          event: EcommerceType.ADD_TO_CART,
          ecommerce: {
            items: [this.tagManagerFeatureService.cartActions(entry, quantityAdded),]
          },
        }));
      }),
    );
  }

  /**
   * Register events for removing entry to the active cart
   */
  protected registerCustomRemoveEntry(): Observable<CustomCartRemoveEntrySuccessEvent> {
    return this.actionsSubject.pipe(
      ofType(CartActions.CART_REMOVE_ENTRY_SUCCESS),
      switchMap((action: CartRemoveEntry) => {
          return of(action).pipe(
            withLatestFrom(
              this.activeCartService.getActive(),
              this.activeCartService.getActiveCartId(),
            ),
          );
        },
      ),
      // tslint:disable-next-line:variable-name
      filter(([action, _activeCart, activeCartId]) => action.payload.cartId === activeCartId),
      tap( () => this.event.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      map(([action, activeCart]) => {
        const removedEntryNumber = parseInt(action.payload.entryNumber, 10);
        const removedProduct = activeCart.entries.find(entry => entry.entryNumber === removedEntryNumber);
        return createFrom(CustomCartRemoveEntrySuccessEvent, {
          event: EcommerceType.REMOVE_FROM_CART,
          ecommerce: {
            items: [this.tagManagerFeatureService.cartActions(removedProduct, removedProduct.quantity),]
          },
        });
      }),
    );
  }

  /**
   * Register events for removing entry to the active cart
   */
  protected registerCustomUpdateEntry(): Observable<CustomCartUpdateEntrySuccessEvent> {
    return this.actionsSubject.pipe(
      ofType(CartActions.CART_UPDATE_ENTRY_SUCCESS),
      switchMap((action: CartUpdateEntrySuccess) => {
          return of(action).pipe(
            withLatestFrom(
              this.activeCartService.getActive(),
              this.activeCartService.getActiveCartId(),
            ),
          );
        },
      ),
      // tslint:disable-next-line:variable-name
      filter(([action, _activeCart, activeCartId]) => action.payload.cartId === activeCartId),
      tap( () => this.event.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      map(([action, activeCart]) => {
        const modifiedActionData = action.payload;
        const modifiedEntryNumber = parseInt(modifiedActionData.entryNumber, 10);
        const modifiedProduct = activeCart.entries.find(entry => entry.entryNumber === modifiedEntryNumber);
        const modifiedQuantity = modifiedActionData.quantity - modifiedProduct.quantity;
        const eventType = modifiedQuantity > 0 ? EcommerceType.ADD_TO_CART : EcommerceType.REMOVE_FROM_CART;
        const products = [this.tagManagerFeatureService.cartActions(modifiedProduct, Math.abs(modifiedQuantity))];

        return createFrom(CustomCartUpdateEntrySuccessEvent, {
          event: eventType,
          ecommerce: {
            items: products
          },
        });
      }),
    );
  }

  protected registerNavigateToCart(): Observable<CustomNavigateToCartEvent> {
    return combineLatest([
      this.event.get(NavigationEvent),
      this.actionsSubject.pipe(
        ofType(CartActions.LOAD_CART_SUCCESS),
        switchMap((action) => this.activeCartService.getActive())
      )
      ]).pipe(
      distinctUntilChanged(([prev],[curr]) => prev.url === curr.url),
      filter(([navigate]) => navigate.url.includes('cart')),
      tap( () => this.event.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      map(([, cart]) =>
        createFrom(CustomNavigateToCartEvent, {
          event: EcommerceType.VIEW_CART,
          ecommerce: {
            currency: cart.totalPrice.currencyIso.toUpperCase(),
            value: cart.totalPrice.value.toString(),
            items: this.getCartProductItems(cart) || [],
          }
        })
      )
    );
  }

  private getCartProductItems(cart: Cart): ProductCartItem[] {
    return cart.entries.map((entry: CustomOrderEntry, index: number): ProductCartItem => {
      const promotionData: ProductCartItemPromotionData | undefined = this.getCartProductItemPromotionData(cart, entry);
      return {
        item_id: entry.product.code,
        item_name: entry.product.name || entry.product.code,
        coupon: promotionData?.coupon || '',
        currency: entry.basePrice.currencyIso.toUpperCase(),
        discount: promotionData?.discount || 0,
        index: index + 1,
        item_brand: entry.product.brands ? entry.product.brands[0].name : '',
        price: entry.basePrice.value.toString(),
        item_category: entry.product.categories ? entry.product.categories[0]?.name || '' : '',
        item_category2: entry.product.categories ? entry.product.categories[1]?.name || '' : '',
        item_category3: entry.product.categories ? entry.product.categories[2]?.name || '' : '',
        item_variant: entry.unit?.name ? entry.unit.name : '',
        quantity: entry.quantity
      };
    });
  }

  private getCartProductItemPromotionData(cart: Cart, orderEntry: CustomOrderEntry): ProductCartItemPromotionData | undefined {
    if (cart && cart.appliedProductPromotions && cart.appliedProductPromotions.length) {
      return cart.appliedProductPromotions
        .filter(entry => entry.consumedEntries[0].orderEntryNumber === orderEntry.entryNumber)
        .map(promotion => <ProductCartItemPromotionData>{
          coupon: promotion.promotion.code,
          discount: orderEntry.basePrice.value - promotion.consumedEntries[0].adjustedUnitPrice
        })[0];
    }
    return undefined;
  }
}
