<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <cx-order-summary [cart]="cart"></cx-order-summary>
    <div
      *ngIf="cart.filledNumberOfSlots < cart.maxNumberOfSlots"
      class="cx-cart-slots-remain"
    >
      <ng-container [ngSwitch]="cart.filledNumberOfSlots < cart.minNumberOfSlots">
        <ng-container *ngSwitchCase="true">
          {{ 'cartDetails.remainingSlotsToOrder' | cxTranslate: { count: (cart.minNumberOfSlots - cart.filledNumberOfSlots) } }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ 'cartDetails.remainingSlots' | cxTranslate: { count: (cart.maxNumberOfSlots - cart.filledNumberOfSlots) } }}
        </ng-container>
      </ng-container>
    </div>
    <app-custom-paypal-installments [installmentsAmount]="cart.totalPrice?.value"></app-custom-paypal-installments>
    <button
      [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
      [disabled]="cart.filledNumberOfSlots < cart.minNumberOfSlots"
      class="btn btn-primary btn-block"
      type="button"
    >
      {{ 'cartDetails.proceedToCheckout' | cxTranslate }}
    </button>
  </ng-container>
</ng-container>
