import { LayoutConfig, DIALOG_TYPE } from "@spartacus/storefront";
import { CustomMyFriendsDialogComponent } from "../custom-my-friends-dialog/custom-my-friends-dialog.component";
export const CustomMyFriendlyDialogConfig: LayoutConfig = {
    launch: {
        FRIENDLY: {
          inlineRoot: true,
          component: CustomMyFriendsDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
       },
    },
  };
