import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { CsTicketCause } from 'src/app/model/contact-form.model';
import { CUSTOM_CONTACT_FORM_FEATURE, StateCustomContactForm, StateWithCustomContactForm } from '../custom-contact-form-state';

export const getCustomContactFormState: MemoizedSelector<StateWithCustomContactForm, StateCustomContactForm> =
  createFeatureSelector<StateCustomContactForm>(CUSTOM_CONTACT_FORM_FEATURE);

export const getCustomContactFormLoaderState: MemoizedSelector<
  StateWithCustomContactForm,
  LoaderState<CsTicketCause[]>
> = createSelector(getCustomContactFormState, (state: StateCustomContactForm) => state.causes);

export const getCustomContactForm: MemoizedSelector<
  StateWithCustomContactForm,
  CsTicketCause[]
> = createSelector(getCustomContactFormLoaderState, (state: LoaderState<CsTicketCause[]>) =>
  StateUtils.loaderValueSelector(state)
);

export const getCustomContactFormLoading: MemoizedSelector<
  StateWithCustomContactForm,
  boolean
> = createSelector(getCustomContactFormLoaderState, (state: LoaderState<CsTicketCause[]>) =>
  StateUtils.loaderLoadingSelector(state)
);

export const getCustomContactFormLoaded: MemoizedSelector<
  StateWithCustomContactForm,
  boolean
> = createSelector(getCustomContactFormLoaderState, (state: LoaderState<CsTicketCause[]>) =>
  StateUtils.loaderSuccessSelector(state)
);
