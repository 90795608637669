import { Injectable } from '@angular/core';
import { RoutingConfigService } from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class CustomRoutingConfigService extends RoutingConfigService {
  /**
   * Returns if has all routes protected by default, if true is a private site.
   */
  isRoutingProtected(): boolean {
    return this.config?.routing?.protected ?? false;
  }
}
