import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { CustomValidateCart } from '../../models/custom-checkout.model';
import { SIMULATE_CART_LOAD_ID, VALIDATE_CART_LOAD_ID } from '../custom-checkout-state';

export enum ValidateCartActionTypes {
  RESET_VALIDATE_CART = '[Checkout] Reset Validate Cart',
  LOAD_VALIDATE_CART = '[Checkout] Load Validate Cart',
  LOAD_VALIDATE_CART_FAIL = '[Checkout] Load Validate Cart Fail',
  LOAD_VALIDATE_CART_SUCCESS = '[Checkout] Load Validate Cart Success',

  RESET_SIMULATE_CART = '[Checkout] Reset Simulate Cart',
  LOAD_SIMULATE_CART = '[Checkout] Load Simulate Cart',
  LOAD_SIMULATE_CART_FAIL = '[Checkout] Load Simulate Cart Fail',
  LOAD_SIMULATE_CART_SUCCESS = '[Checkout] Load Simulate Cart Success',
}

export class ResetValidateCart extends StateUtils.EntityLoaderResetAction {
  readonly type = ValidateCartActionTypes.RESET_VALIDATE_CART;

  constructor() {
    super(PROCESS_FEATURE, VALIDATE_CART_LOAD_ID);
  }
}

export class LoadValidateCart extends StateUtils.EntityLoadAction {
  readonly type = ValidateCartActionTypes.LOAD_VALIDATE_CART;

  constructor(public payload: { userId: string; cartId: string }) {
    super(PROCESS_FEATURE, VALIDATE_CART_LOAD_ID);
  }
}

export class LoadValidateCartFail extends StateUtils.EntityFailAction {
  readonly type = ValidateCartActionTypes.LOAD_VALIDATE_CART_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, VALIDATE_CART_LOAD_ID, payload);
  }
}

export class LoadValidateCartSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ValidateCartActionTypes.LOAD_VALIDATE_CART_SUCCESS;

  constructor(public payload: CustomValidateCart) {
    super(PROCESS_FEATURE, VALIDATE_CART_LOAD_ID, payload);
  }
}

export class ResetSimulateCart extends StateUtils.EntityLoaderResetAction {
  readonly type = ValidateCartActionTypes.RESET_SIMULATE_CART;

  constructor() {
    super(PROCESS_FEATURE, SIMULATE_CART_LOAD_ID);
  }
}

export class LoadSimulateCart extends StateUtils.EntityLoadAction {
  readonly type = ValidateCartActionTypes.LOAD_SIMULATE_CART;

  constructor(public payload: { userId: string; cartId: string; validate: boolean }) {
    super(PROCESS_FEATURE, SIMULATE_CART_LOAD_ID);
  }
}

export class LoadSimulateCartFail extends StateUtils.EntityFailAction {
  readonly type = ValidateCartActionTypes.LOAD_SIMULATE_CART_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, SIMULATE_CART_LOAD_ID, payload);
  }
}

export class LoadSimulateCartSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ValidateCartActionTypes.LOAD_SIMULATE_CART_SUCCESS;

  constructor(public payload: boolean) {
    super(PROCESS_FEATURE, SIMULATE_CART_LOAD_ID, payload);
  }
}



export type CustomValidateCartAction =
  | LoadValidateCart
  | LoadValidateCartFail
  | LoadValidateCartSuccess
  | ResetValidateCart

  | LoadSimulateCart
  | LoadSimulateCartFail
  | LoadSimulateCartSuccess
  | ResetSimulateCart;
