import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReturnOrderConfirmationComponent } from '@spartacus/order/components';

@Component({
  selector: 'cx-return-order-confirmation',
  templateUrl: './custom-return-order-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReturnOrderConfirmationComponent extends ReturnOrderConfirmationComponent {
}
