import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  LanguageService,
  ParamsMapping,
  RouterState,
  RoutingConfigService,
  RoutingService,
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { CustomProductService } from 'src/app/spartacus/custom/core/product/facade/custom-product.service';

@Injectable({
  providedIn: 'root',
})
export class CustomUrlLocalizedService {
  language$ = this.languageService.getActive();

  constructor(
    private languageService: LanguageService,
    private router: RoutingService,
    private routingConfigService: RoutingConfigService,
    private currentProductService?: CurrentProductService,
    private customProductService?: CustomProductService,
  ) {}

  initialize(): void {
    this.language$.subscribe((language) => {
      this.router
        .getRouterState()
        .subscribe((state) => {
          const route = state.state.semanticRoute;
          if (route) {
            this.navigateToTranslatedURL(route, this.handleRouteParams(state, language));
          }
        })
        .unsubscribe();
    });
  }

  private handleRouteParams(state: RouterState, language: string): Params {
    const params = state.state.params;
    if (!params) {
      return params;
    }
    let newParams: Params;
    if (state.state.semanticRoute === 'product') {
      this.currentProductService.getProduct()
        .subscribe((product) => {
          newParams = { ...params };
          newParams.slug = this.customProductService.getSlugByLang(product, language);
        })
        .unsubscribe();
    }
    return newParams ?? params;
  }

  private navigateToTranslatedURL(route: string, params: Params): void {
    if (params) {
      params = this.getMappedParams(
        route,
        params,
      );
    }
    this.router.go({
      cxRoute: route,
      params,
    });
  }

  // find mapped params values for actual state
  getMappedParams(
    route: string,
    params: Params
  ): Params {
    const paramsMapping = this.routingConfigService.getRouteConfig(route).paramsMapping;
    const result = {};
    Object.keys(params).forEach((paramKey) => {
      const mappedKey = paramsMapping ? paramsMapping[paramKey] : undefined;
      result[mappedKey ?? paramKey] = params[paramKey];
    });
    return result;
  }
}
