import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { CustomerEmailModel } from '../custom-friend-email-form/CustomerEmail.model';

@Component({
  selector: 'app-custom-my-friends-filter',
  templateUrl: './custom-my-friends-filter.component.html',
  styleUrls: ['./custom-my-friends-filter.component.scss'],
})
export class CustomMyFriendsFilterComponent implements OnInit {
  @Output() readonly addFriend: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly add: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly openEmailComposer: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedFriendsEmails: Array<CustomerEmailModel>;
  @Input() showEmailFeature: boolean;
  iconTypes = ICON_TYPE;

  constructor() {
  }

  ngOnInit(): void {
  }

  addNewFriend(): void {
    this.openEmailComposer.emit(false);
    this.addFriend.emit(true);
    this.add.emit(true);
  }

  openEmailDialog(): void{
    this.addFriend.emit(false);
    this.add.emit(false);
    this.openEmailComposer.emit(true);
  }
}
