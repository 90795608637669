import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Inject, Injectable, NgZone} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CurrencyService,
  GlobalMessageService,
  OccEndpointsService,
  RoutingService,
  ScriptLoader,
  StateWithProcess,
  UserIdService,
} from '@spartacus/core';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomMultiCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-multi-cart.service';
import { StateWithFriends } from 'src/app/spartacus/custom/core/custom-friends/store/custom-friends-checkout.state';
import { environment } from 'src/environments/environment';
import { CustomPopupShippingService } from '../../../user/popup-shipping/custom-popup-shipping.service';
import {CustomPaypalService} from "../../../checkout/custom-paypal-button/services/custom-paypal.service";
import {
  CustomCheckoutPaymentService
} from "../../../../../spartacus/custom/core/checkout/facade/custom-checkout-payment.service";
import { OrderDetailsService } from '@spartacus/order/components';
import { OrderHistoryFacade } from '@spartacus/order/root';

@Injectable({
  providedIn: 'root',
})
export class CustomPaypalInstallmentsService extends CustomPaypalService {

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected scriptLoader: ScriptLoader,
    protected currencyService: CurrencyService,
    protected userIdService: UserIdService,
    protected activeCartService: CustomActiveCartService,
    protected occEndpoints: OccEndpointsService,
    protected ngZone: NgZone,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected activatedRoute?: ActivatedRoute,
    protected globalMessageService?: GlobalMessageService,
    protected customPopupShippingService?: CustomPopupShippingService,
    protected http?: HttpClient,
    protected routingService?: RoutingService,
    protected multiCartService?: CustomMultiCartService,
    protected checkoutService?: CustomCheckoutPaymentService,
    protected friendsStore?: Store<StateWithFriends | StateWithProcess<void>>,
  ) { super(document, scriptLoader, currencyService, userIdService, activeCartService, occEndpoints,
    ngZone, orderHistoryFacade, activatedRoute,
    globalMessageService, customPopupShippingService, http, routingService, multiCartService, checkoutService, friendsStore) }

  loadInstallments(): void {
    this.includePaypalSDK();
  }

  protected includePaypalSDK(): void {
    // (DRF) OE-1312, 20220919 - ONLY ADD SCRIPT IF NOT EXIST IN DOM, PAYAPAL BUTTON IS MASTER OF SCRIPT
    const existScript = this.document.querySelector('script[src^="' + environment.paypal.src + '"]');
    if (!existScript) {
      let currency: string;
      this.currencyService.getActive()
        .subscribe(activeCurrency => currency = activeCurrency)
        .unsubscribe();
      this.scriptLoader.embedScript({
        src: environment.paypal.src,
        params: {
          'client-id': environment.paypal.id,
          currency,
          'components': "messages",
        },
        errorCallback: () => {
          this.handleLoadSDKError();
        }
      });
    }
  }

}
