import { StateUtils } from '@spartacus/core';
import { CustomAgeStatusModel } from './reducers/custom-age-status.reducer';

export const AGE_STATUS_FEATURE = 'ageStatus';
export const AGE_STATUS = '[Base Store] Base Store';
export const AGE_STATUS_ID = 'ageStatus';

export interface CustomAgeStatusState {
  popup: StateUtils.LoaderState<CustomAgeStatusModel>;
}

export interface StateWithAgeStatus {
  [AGE_STATUS_FEATURE]: CustomAgeStatusState;
}
