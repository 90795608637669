<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="order.entryGroups?.length">
    <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
      <!-- <cx-promotions class="cx-promotions-applied" [promotions]="orderPromotions"></cx-promotions> -->
      <app-custom-promotions class="cx-promotions-applied" [promotions]="orderPromotions"></app-custom-promotions>
    </ng-container>
    <div class="cx-list">
      <ng-container *ngIf="order.entryGroups?.length">
        <cx-order-consigned-entries [order]="order" [entryGroups]="order.entryGroups"></cx-order-consigned-entries>
      </ng-container>
    </div>

      <ng-container *ngIf="order?.giftCardProduct">
        <app-custom-gift-cards-details [giftCardProduct]="order?.giftCardProduct"></app-custom-gift-cards-details>
      </ng-container>
  </ng-container>
</ng-container>

