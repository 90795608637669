import { Injectable } from '@angular/core';
import {
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorModel,
  RoutingService,
} from '@spartacus/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { CustomOrderReturnRequestService } from 'src/app/spartacus/custom/core/user/facade/custom-order-return-request.service';
import { CustomOrderDetailsService } from '../../custom-order-details/custom-order-details.service';
import { CustomCreateOrderReturnFailEvent } from 'src/app/spartacus/features/tracking/custom-events/product/custom-product-page.events';
import { OrderReturnService } from '@spartacus/order/components';
import { CancelOrReturnRequestEntryInput } from '@spartacus/order/root';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderReturnService extends OrderReturnService {
  constructor(
    protected orderDetailsService: CustomOrderDetailsService,
    protected returnRequestService: CustomOrderReturnRequestService,
    protected routing: RoutingService,
    protected globalMessageService: GlobalMessageService,
    protected eventService?: EventService,
  ) {
    super(orderDetailsService, returnRequestService, routing, globalMessageService);
  }

  save(): void {
    const orderCode = this.form.value.orderCode;
    const entries = this.form.value.entries;
    const inputs: CancelOrReturnRequestEntryInput[] = Object.keys(entries)
      .filter((entryNumber) => (entries[entryNumber] as number) > 0)
      .map(
        (entryNumber) =>
          ({
            orderEntryNumber: Number(entryNumber),
            quantity: entries[entryNumber] as number,
          } as CancelOrReturnRequestEntryInput)
      );

    this.returnRequestService.createOrderReturnRequest({
      orderCode,
      returnRequestEntryInputs: inputs,
    });

    let errorSubscription: Subscription;
    const successSubscription = this.returnRequestService
      .getReturnRequestSuccess()
      .pipe(first(Boolean))
      .subscribe(() => {
        errorSubscription?.unsubscribe();
        this.form.reset();
        this.afterSaves();
      });

    errorSubscription = this.eventService
      .get(CustomCreateOrderReturnFailEvent)
      .pipe(first(Boolean))
      .subscribe((error: HttpErrorModel) => {
        const isPackError = error?.details?.[0]?.type === 'IllegalArgumentError';
        successSubscription?.unsubscribe();
        this.form.enable();
        this.globalMessageService.add(
          {
            key: isPackError ? 'orderDetails.cancellationAndReturn.returnPackError' : 'orderDetails.cancellationAndReturn.returnError',
          },
          GlobalMessageType.MSG_TYPE_ERROR
        );
        this.routing.go({
          cxRoute: 'orderReturn',
          params: { code: this.form.value.orderCode },
        });
      });
  }

  private afterSaves(): void {
    this.returnRequestService
      .getOrderReturnRequest()
      .pipe(first((r) => !!r))
      .subscribe((returnRequest) => {
        const rma = returnRequest.rma;
        this.globalMessageService.add(
          {
            key: 'orderDetails.cancellationAndReturn.returnSuccess',
            params: { rma },
          },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
        this.routing.go({
          cxRoute: 'returnRequestDetails',
          params: { rma },
        });
      });
  }
}
