import { StateUtils, USER_ADDRESSES } from '@spartacus/core';

export type CustomUserAddressesAction =
  | CustomLoadUserAddressBasestore;

export const CUSTOM_LOAD_USER_ADDRESSES_BASESTORE = '[User] Custom Load User Addresses Basestore';

export class CustomLoadUserAddressBasestore extends StateUtils.LoaderLoadAction {
  readonly type = CUSTOM_LOAD_USER_ADDRESSES_BASESTORE;
  constructor(public payload: string) {
    super(USER_ADDRESSES);
  }
}
