import { Component } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'cx-order-details-shipping',
  templateUrl: './custom-order-detail-shipping.component.html',
})
export class CustomOrderDetailShippingComponent {
  constructor(protected orderDetailsService: OrderDetailsService) {}

  order$: Observable<any>;
  isOrderLoading$: Observable<boolean>;

  ngOnInit() {
    this.order$ = this.orderDetailsService.getOrderDetails();

    this.isOrderLoading$ =
      typeof this.orderDetailsService.isOrderDetailsLoading === 'function'
        ? this.orderDetailsService.isOrderDetailsLoading()
        : of(false);
  }
}
