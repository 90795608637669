import { AnonymousConsentDialogComponent, DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

export const customDefaultAnonymousConsentLayoutConfig: LayoutConfig = {
  launch: {
    ANONYMOUS_CONSENT: {
      inline: true,
      component: AnonymousConsentDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
