import { Injectable } from '@angular/core';
import { PageMetaLinkService } from '@spartacus/storefront';
import { HrefLangMeta } from '../../core/cms/page/model/custom-page.model';

@Injectable({
  providedIn: 'root',
})
export class CustomPageMetaLinkService extends PageMetaLinkService {
  /**
   * Handle alternate link elements in the document head.
   *
   */
  setHrefLangLinks(hrefLangs: HrefLangMeta[] | undefined): void {
    const links: NodeListOf<HTMLLinkElement> = this.winRef.document.querySelectorAll(
      'link[rel="alternate"]'
    );

    links.forEach((link: HTMLLinkElement) => {
      link?.remove();
    });

    const documentHead = this.winRef.document.head;
    hrefLangs?.forEach((hrefLang: HrefLangMeta) => {
      const link = this.renderer.createElement('link');
      link.rel = 'alternate';
      link.href = hrefLang.href;
      link.hreflang = hrefLang.lang;
      this.renderer.appendChild(documentHead, link);
    });
  }
}
