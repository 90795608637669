<ng-container *ngIf="newsDetail$ | async as news">
  <ng-container *ngIf="(loadingDetail$ | async); else showContent">
    <cx-spinner></cx-spinner>
  </ng-container>

  <ng-template #showContent>
      <h1 class="page-title">{{ news.newsTitle }}</h1>
      <p class="news-date">{{ news.publishDate | cxDate:  'mediumDate'}}</p>
    <ng-container *ngIf="news.coverImage">
      <cx-media [container]="news.coverImage" class="news-image"></cx-media>
    </ng-container>
  </ng-template>
</ng-container>


