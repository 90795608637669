import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthGuard,
  CmsConfig,
  FeaturesConfig,
  FeaturesConfigModule,
  I18nModule, provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent, PromotionsModule, SpinnerModule } from '@spartacus/storefront';
import { CustomCardModule } from 'src/app/spartacus/custom/cms-structure/shared/components/custom-card/custom-card.module';
import { CustomOrderOverviewModule } from 'src/app/spartacus/custom/shared/components/order-overview/custom-order-overview.module';
import { CustomCartSharedModule } from '../../../cart/cart-shared/custom-cart-shared.module';
import { CustomOrderDetailActionsComponent } from './custom-order-detail-actions/order-detail-actions.component';
import { CustomConsignmentTrackingComponent } from './custom-order-detail-items/custom-consignment-tracking/custom-consignment-tracking.component';
import { CustomTrackingEventsComponent } from './custom-order-detail-items/custom-consignment-tracking/custom-tracking-events/custom-tracking-events.component';
import { CustomOrderConsignedEntriesComponent } from './custom-order-detail-items/custom-order-consigned-entries/custom-order-consigned-entries.component';
import { CustomOrderDetailItemsComponent } from './custom-order-detail-items/custom-order-detail-items.component';
import { CustomOrderDetailShippingComponent } from './custom-order-detail-shipping/custom-order-detail-shipping.component';
import { CustomOrderDetailTotalsComponent } from './custom-order-detail-totals/custom-order-detail-totals.component';
import { CustomOrderDetailsService } from './custom-order-details.service';
import { CustomGiftCardsDetailsModule } from '../../../../../../custom/cms-components/cart/custom-gift-cards-details/custom-gift-cards-details.module';
import { CustomOrderRestorationComponent } from '../custom-order-actions/custom-order-restoration/custom-order-restoration.component';
import { CustomOrderCancellationComponent } from '../custom-order-actions/custom-order-cancellation/custom-order-cancellation.component';
import { customOrderActionsSpinnerLayoutConfig } from '../custom-order-actions/custom-order-actions-spinner-layout.config';
import { CustomOrderRestoreOnlyComponent } from '../custom-order-actions/custom-order-restore-only/custom-order-restore-only.component';
import { CustomOrderTicketDownloadModule } from 'src/app/custom/cms-components/myaccount/custom-order-ticket-download/custom-order-ticket-download.module';
import { customOrderCancellationDialogModalLayoutConfig, customOrderRestorationDialogModalLayoutConfig, customOrderRestoreOnlyDialogModalLayoutConfig } from '../custom-order.config';
import { CustomPromotionsModule } from '../../../product/promotions/promotions.module';

const moduleComponents = [
  CustomOrderDetailActionsComponent,
  CustomOrderDetailItemsComponent,
  CustomOrderDetailTotalsComponent,
  CustomOrderDetailShippingComponent,
  CustomTrackingEventsComponent,
  CustomConsignmentTrackingComponent,
  CustomOrderConsignedEntriesComponent,
  CustomOrderRestorationComponent,
  CustomOrderCancellationComponent,
  CustomOrderRestoreOnlyComponent,
];

@NgModule({
  imports: [
    CustomCartSharedModule,
    CustomCardModule,
    CommonModule,
    I18nModule,
    FeaturesConfigModule,
    PromotionsModule,
    CustomOrderOverviewModule,
    CustomOrderTicketDownloadModule,
    UrlModule,
    SpinnerModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: { pageLabel: 'order', cxRoute: 'orderGuest' },
      },
      {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'orderDetails' },
      },
    ]),
    CustomGiftCardsDetailsModule,
    CustomPromotionsModule,
  ],
  providers: [
    provideConfig(customOrderActionsSpinnerLayoutConfig),
    provideConfig(customOrderCancellationDialogModalLayoutConfig),
    provideConfig(customOrderRestorationDialogModalLayoutConfig),
    provideConfig(customOrderRestoreOnlyDialogModalLayoutConfig),
    provideConfig({
      cmsComponents: {
        CustomOrderCancellationComponent: {
          component: CustomOrderCancellationComponent
        },
        AccountOrderDetailsActionsComponent: {
          component: CustomOrderDetailActionsComponent,
        },
        AccountOrderDetailsItemsComponent: {
          component: CustomOrderDetailItemsComponent,
        },
        AccountOrderDetailsTotalsComponent: {
          component: CustomOrderDetailTotalsComponent,
        },
        AccountOrderDetailsShippingComponent: {
          component: CustomOrderDetailShippingComponent,
        },
      },
      features: {
        consignmentTracking: '1.2',
      },
    } as CmsConfig | FeaturesConfig),
    CustomOrderDetailsService,
  ],
  declarations: [...moduleComponents],
  exports: [...moduleComponents],
  entryComponents: [...moduleComponents],
})
export class CustomOrderDetailsModule {}
