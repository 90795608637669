import { CustomBaseStoreModel } from '../custom-base-store.model';

export const BASE_STORE_FEATURE = 'baseStore';
export const BASE_STORE = '[Base Store] Base Store';
export const BASE_STORE_ID = 'baseStore';

export interface CustomBaseStoreState {
  baseStoreList: CustomBaseStoreModel[];
  selected: CustomBaseStoreModel;
}

export interface StateWithBaseStore {
  [BASE_STORE_FEATURE]: CustomBaseStoreState;
}
