import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  getStatistics,
  getStatisticsLoading, getStatisticsProductLoading, getStatisticsProducts,
  StateWithStatistics,
} from './store/selectors/custom-statistics-selectors';
import { StateWithProcess } from '@spartacus/core/src/process/store/process-state';
import { Statistics, StatisticsProduct } from './model/custom-statistics-model';
import { LoadStatistics, LoadStatisticsProducts } from './store/actions/custom-statistics-actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserIdService } from '@spartacus/core';

export const monthHeaderArray = ['february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december', 'january'];

@Component({
  selector: 'app-custom-statistics',
  templateUrl: './custom-statistics.component.html',
  styleUrls: ['./custom-statistics.component.scss'],
})
export class CustomStatisticsComponent implements OnInit {
  // [700, 500];
  view: any[] = undefined;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  colorScheme = {
    domain: ['#FFE6CD', '#ffccb8', '#AAAAAA'],
  };
  showDataLabel = true;
  currentMonth = new Date().getMonth();
  currentYear = this.currentMonth === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();
  previousYear = this.currentYear - 1;
  compareYears = [this.currentYear, this.previousYear];
  monthHeaderArray = monthHeaderArray;
  showCompareInformation: boolean;

  statisticsCompareForm: FormGroup;
  getStatistics$: Observable<Statistics[]> = this.store.pipe(select(getStatistics));
  getStatisticsLoading$: Observable<boolean> = this.store.pipe(select(getStatisticsLoading));
  getStatisticsProducts$: Observable<StatisticsProduct[]> = this.store.pipe(select(getStatisticsProducts));
  getStatisticsProductLoading$: Observable<boolean> = this.store.pipe(select(getStatisticsProductLoading));

  constructor(
    private store: Store<StateWithStatistics | StateWithProcess<void>>,
    private formBuilder: FormBuilder,
    protected userIdService: UserIdService,
  ) {
    this.statisticsCompareForm = this.formBuilder.group({
      years: [this.compareYears, []],
    });
    this.statisticsComparePreviousYear();
  }

  statisticsComparePreviousYear(): void {
    const years = this.statisticsCompareForm.get('years').value;
    let userId;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    this.store.dispatch(new LoadStatisticsProducts({ userId, years }));
    this.store.dispatch(new LoadStatistics({ userId, years }));
    this.showCompareInformation = years === this.compareYears;
  }

  ngOnInit(): void {}

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
