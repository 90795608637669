<ng-container *ngIf="data$ | async as data">
  <div class="wrapper-text">
    <h1>{{ data.title }}</h1>
    <p>{{ data.description }}</p>
  </div>
  <ng-container *ngIf="data.image">
    <cx-media [container]="data.image"></cx-media>
  </ng-container>

</ng-container>
