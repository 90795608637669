import { CustomFriendsActions, FriendsActionTypes } from '../actions/custom-friends-users.action';
import { CustomFriendsUsersState } from '../../../store/custom-friends-checkout.state';

export const initialState: CustomFriendsUsersState = {
  members: [],
  pagination: {},
  selected: null,
  status: null
};

export function customFriendsReducer(state = initialState, action: CustomFriendsActions): CustomFriendsUsersState {
  switch (action.type) {
    case FriendsActionTypes.LOAD_FRIENDS_FAIL: {
      return initialState;
    }

    case FriendsActionTypes.LOAD_FRIENDS_SUCCESS: {
      return { ...state,
        members: action.payload ? action.payload.members : initialState.members,
        pagination: action.payload ? action.payload.pagination : initialState.pagination
      };
    }

    case FriendsActionTypes.LOAD_FRIEND_FAIL: {
      return { ...state, selected: initialState.selected };
    }

    case FriendsActionTypes.PURGE_FRIEND: {
      return initialState;
    }

    case FriendsActionTypes.LOAD_FRIEND_SUCCESS: {
      return { ...state, selected: action.payload ? action.payload : initialState.selected };
    }

    case FriendsActionTypes.CREATE_FRIEND_SUCCESS: {
      return { ...state, status: action.payload ? action.payload.status : initialState.status };
    }

    case FriendsActionTypes.UPDATE_FRIEND_SUCCESS: {
      return { ...state, status: action.payload ? action.payload.status : initialState.status };
    }
  }
  return state;
}
