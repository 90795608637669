import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReturnOrderComponent } from '@spartacus/order/components';

@Component({
  selector: 'cx-return-order',
  templateUrl: './custom-return-order.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReturnOrderComponent extends ReturnOrderComponent {
}
