<div role="document" class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-body" *ngIf="order$ | async as order">
      <div class="cx-address-deck" *ngIf="loaded;else loading">
        <div class="header-default h3 text-center mt-3 mb-5">
          {{ "orderDetails.actions.contentRestoration" | cxTranslate }}
        </div>
        <div class="row buttons-actions">
          <div class="col-md-12 text-right">
            <button class="btn btn-default ml-2 mr-2" (click)="dismissModal('')">
              {{ "orderDetails.actions.back" | cxTranslate }}
            </button>
            <button class="btn btn-action ml-2 mr-2" (click)="restoreOrder(order)">
              {{ "orderDetails.actions.accept" | cxTranslate }}
            </button>
          </div>
        </div>
      </div>

      <ng-template #loading>
        <div class="col-md-12 cx-address-spinner">
          <cx-spinner></cx-spinner>
        </div>
      </ng-template>

    </div>
  </div>
</div>