import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { CsTicketCause } from 'src/app/model/contact-form.model';
import { ContactFormActions } from '../actions';
import { CUSTOM_CONTACT_FORM } from '../actions/custom-contact-form.action';
import { StateCustomContactForm } from '../custom-contact-form-state';

export const initialState: CsTicketCause[] = [];

export function reducer(
  state = initialState,
  action: ContactFormActions.ContactFormAction
): CsTicketCause[] {
  switch (action.type) {
    case ContactFormActions.LOAD_CUSTOM_CONTACT_FORM_SUCCESS: {
      return action.payload.causes ? action.payload.causes : initialState;
    }
    case ContactFormActions.LOAD_CUSTOM_CONTACT_FORM_FAIL: {
      return initialState;
    }
  }

  return state;
}

export function getReducers(): ActionReducerMap<StateCustomContactForm> {
  return {
    causes: StateUtils.loaderReducer<CsTicketCause[]>(CUSTOM_CONTACT_FORM, reducer),
  };
}

export const reducerCustomContactFormToken: InjectionToken<ActionReducerMap<StateCustomContactForm>> = new InjectionToken<
  ActionReducerMap<StateCustomContactForm>
>('CustomContactFormReducers');

export const reducerCustomContactFormProvider = {
  provide: reducerCustomContactFormToken,
  useFactory: getReducers,
};
