import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CartTotalsComponent } from '@spartacus/cart/base/components';
import { RoutingService } from '@spartacus/core';
import { CustomValidateCartService } from 'src/app/services/cart/validate-cart.service';

@Component({
  selector: 'cx-cart-totals',
  templateUrl: './custom-cart-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCartTotalsComponent extends CartTotalsComponent {
  customValidateCartService = inject(CustomValidateCartService);
  routingService = inject(RoutingService);
  

  goToCheckout() {
    this.customValidateCartService.simulateOrder(false);
    this.routingService.go({ cxRoute: 'checkout' });
  }
}
