<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>
<form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="data$ | async as data">
  <h2 class="title">
    {{ data.title }}
  </h2>
  <div class="custom-newsletter-body">
    <div class="input-with-btn">
      <label aria-label="{{ data.title }}">
        <input
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="{{
            'newsletter.email.placeholder' | cxTranslate
          }}"
          formControlName="email"
          [attr.disabled]="(isUpdating$ | async) ? '' : null"
        />
        <cx-form-errors [control]="form.get('email')"></cx-form-errors>
      </label>
      <button
        class="btn btn-primary newsletter-btn-action"
        [disabled]="(isUpdating$ | async)"
      >
        {{ ((isUpdating$ | async) ? 'newsletter.email.submitSuccess' : 'newsletter.email.submit') | cxTranslate }}
      </button>
    </div>

    <recaptcha
      (currentWidgetId)="getWidgetItemId($event)"
    ></recaptcha>

    <div class="form-check">
      <label>
        <input
          formControlName="accept"
          class="scaled-input form-check-input"
          type="checkbox"
          [attr.disabled]="(isUpdating$ | async) ? '' : null"
          [checked]="(isUpdating$ | async)"
        />
        <span class="form-check-label">
          {{ 'newsletter.accept.label' | cxTranslate }}
        </span>
      </label>
    </div>
    <cx-form-errors [control]="form.get('accept')"></cx-form-errors>
    <div
      [class]="'custom-newsletter-content ' + data.content.styleClasses"
      *ngIf="data?.content?.content"
      [innerHTML]="data.content.content | htmlSanitize: 'html'"
    ></div>
  </div>
</form>
