import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCardComponent } from './custom-card.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule, TruncateTextPopoverModule } from '@spartacus/storefront';



@NgModule({
  declarations: [CustomCardComponent],
  exports: [CustomCardComponent],
  imports: [
    CommonModule,
    I18nModule, IconModule, TruncateTextPopoverModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CardComponent: {
          component: CustomCardComponent,
        },
      },
    } as CmsConfig)
  ]
})
export class CustomCardModule { }
