import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AsmSessionTimerComponent } from '@spartacus/asm/components';

@Component({
  selector: 'cx-asm-session-timer',
  templateUrl: './custom-asm-session-timer.component.html',
  styleUrls: ['./custom-asm-session-timer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAsmSessionTimerComponent extends AsmSessionTimerComponent {
}
