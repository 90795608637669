import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {ProcessSelectors, StateWithProcess, WindowRef} from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomNewsletterActions } from './store/actions';
import { UPDATE_NEWSLETTER_PROCESS_ID } from './store/custom-newsletter-state';
import { NEWSLETTER_DISPLAYED_POPUP_LOCAL_STORAGE_KEY } from './custom-newsletter-constants';

@Injectable({
  providedIn: 'root',
})
export class CustomNewslettersService {
  constructor(
    protected store: Store<StateWithProcess<void>>,
    protected winRef?: WindowRef,
  ) {}

  /**
   * Subscribe email to newsletter.
   */
  updateNewsletter(email: string, consentTemplateId: string, consentTemplateVersion: string, accept: boolean): void {
    this.store.dispatch(
      new CustomNewsletterActions.UpdateNewsletter({
        email,
        consentTemplateId,
        consentTemplateVersion,
        accept,
      })
    );
  }

  /**
   * Returns the Update Newsletter loading flag
   */
   getUpdateNewsletterLoading(): Observable<boolean> {
    return this.store.pipe(
      select(ProcessSelectors.getProcessLoadingFactory(UPDATE_NEWSLETTER_PROCESS_ID))
    );
  }

  /**
   * Returns the Update Newsletter success flag
   */
  getUpdateNewsletterSuccess(): Observable<boolean> {
    return this.store.pipe(
      select(ProcessSelectors.getProcessSuccessFactory(UPDATE_NEWSLETTER_PROCESS_ID))
    );
  }

  /**
   * Returns the Update Newsletter state
   */
  geUpdateNewsletterState(): Observable<any> {
    return this.store.pipe(
      select(ProcessSelectors.getProcessStateFactory(UPDATE_NEWSLETTER_PROCESS_ID))
    );
  }

  /**
   * Returns the Update Newsletter error flag
   */
  getUpdateNewsletterError(): Observable<boolean> {
    return this.store.pipe(
      select(ProcessSelectors.getProcessErrorFactory(UPDATE_NEWSLETTER_PROCESS_ID))
    );
  }

  /**
   * Clears the process state of performing a newsletter subscription
   */
  clearUpdateNewsletter(): void {
    this.store.dispatch(new CustomNewsletterActions.ClearUpdateNewsletter());
  }

  /**
   * Returns whether the popup must be displayed or not
   */
  get displayPopup(): boolean {
    return this.winRef?.localStorage?.getItem(NEWSLETTER_DISPLAYED_POPUP_LOCAL_STORAGE_KEY) !== 'true' && Boolean(this.winRef?.localStorage?.getItem('currentbasestore'));
  }

  setPopupAsDisplayed(): void {
    this.winRef?.localStorage?.setItem(NEWSLETTER_DISPLAYED_POPUP_LOCAL_STORAGE_KEY, 'true');
  }
}
