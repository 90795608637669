import { CmsPageGuard } from '@spartacus/storefront';
/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideDefaultConfig,
  provideConfig,
  UrlModule,
} from '@spartacus/core';

import { ListNavigationModule } from '@spartacus/storefront';
import { PageLayoutComponent } from '@spartacus/storefront';
import { SpinnerModule } from '@spartacus/storefront';
import { MyInterestsComponent } from './custom-my-interests.component';
import { AtMessageModule } from '@spartacus/storefront';
import { MediaModule } from '@spartacus/storefront';

@NgModule({
  declarations: [MyInterestsComponent],
  imports: [
    AtMessageModule,
    CommonModule,
    I18nModule,
    ListNavigationModule,
    I18nModule,
    UrlModule,
    MediaModule,
    SpinnerModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard,CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'myInterests' },
      },
    ]),
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        MyInterestsComponent: {
          component: MyInterestsComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  exports: [MyInterestsComponent],
})
export class CustomMyInterestsModule {}
