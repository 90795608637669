<div class="row">
  <div class="col-12 col-md-3">
    <ng-container *ngIf="siblingProduct || product as currentProduct">
      <app-custom-product-labels-items-view
        [labels]="currentProduct.labelProduct">
      </app-custom-product-labels-items-view>
      <a
        (click)="dispatchSelectEvent()"
        [routerLink]="{ cxRoute: 'product', params: this.product} | cxUrl"
        [queryParams]="getQueryParams()"
        [state]="(navigationExtras$ | async).state"
        class="cx-product-image-container"
        tabindex="-1"
      >
      <div class="cx-product-image-slots">
        <cx-media
          class="cx-product-image"
          [container]="currentProduct.images?.PRIMARY ? currentProduct.images?.PRIMARY : currentProduct.images"
          format="product"
          [alt]="currentProduct.summary"
        ></cx-media>
        <ng-container *ngIf="isGiftboxWizard$ | async">
          <span
            *ngIf="currentProduct.numberOfSlots"
            class="cx-product-slots"
          >
            {{ 'productDetails.slots' | cxTranslate: { count: currentProduct.numberOfSlots } }}
          </span>
        </ng-container>
      </div>
      </a>
    </ng-container>
  </div>
  <div class="col-12 col-md-9">
    <ng-container *ngIf="siblingProduct || product as currentProduct">
      <a
        (click)="dispatchSelectEvent()"
        [routerLink]="{ cxRoute: 'product', params: {code: this.product.code} } | cxUrl"
        [queryParams]="getQueryParams()"
        [state]="(navigationExtras$ | async).state"
        class="cx-product-name"
      >
        <h2
          class="cx-product-name"
          [innerHtml]="currentProduct.nameHtml ? currentProduct.nameHtml : currentProduct.name">
        </h2>
      </a>
      <cx-star-rating
        *ngIf="currentProduct.averageRating"
        [rating]="currentProduct?.averageRating"
      ></cx-star-rating>
    <!--<div *ngIf="!product.averageRating" class="cx-product-no-review">
      {{ 'productDetails.noReviews' | cxTranslate }}
    </div>
    <div class="cx-product-price" aria-label="Product price">
      {{ product.price?.formattedValue }}
    </div>

    <cx-variant-style-icons
      *ngIf="product.variantOptions"
      [variants]="product.variantOptions"
    ></cx-variant-style-icons>-->

      <p class="cx-product-summary" [innerHtml]="currentProduct.summary">
        {{ product.summary }}
      </p>
    </ng-container>
    <ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
      <cx-add-to-cart
        [showQuantity]="true"
        [product]="product"
        [hideStockInfo]="true"
        [showNostockText]="true"
        [showAddToCartText]="false"
        [displayDefaultUnitOnly]="isGiftboxWizard$ | async"
        (unitChanged)="handleUnitChange($event)"
      ></cx-add-to-cart>
    </ng-template>
  </div>
</div>
