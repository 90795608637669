import { PaymentType } from "@spartacus/cart/base/root";
import { CheckoutPaymentTypeAdapter } from "@spartacus/checkout/b2b/core";
import { CheckoutState } from "@spartacus/checkout/base/root";
import { Observable } from "rxjs";
import { Payments, CreditCard } from "src/app/spartacus/custom/cms-components/checkout/components/payment-method/custom-credit-card/store/credit-card.state";

export abstract class CustomCheckoutPaymentTypeAdapter extends CheckoutPaymentTypeAdapter {

    /**
   * Abstract method to assign an extra data to the cart.
   * @param userId: User Id
   */
  abstract creditCardList(
    userId: string,
  ): Observable<Payments>;

  abstract getSelectedCreditCard(
    userId: string,
    creditCardDetailId: string,
  ): Observable<CreditCard>;

  abstract setSelectedCreditCard(
    userId: string,
    cartId: string,
    paymentDetailsId: string,
  ): Observable<CreditCard>;

  abstract removeSelectedCreditCard(
    userId: string,
    paymentDetailsId: string,
  ): Observable<CreditCard>;

  /**
   * Abstract method used to get available payment types
   */
  abstract getPaymentTypes(): Observable<PaymentType[]>;

  /**
   * Abstract method used to get checkout details
   *
   * @param userId
   * @param cartId
   */
  abstract getCheckoutDetails(
    userId: string,
    cartId: string
  ): Observable<CheckoutState>;

}