import { Observable } from 'rxjs';
import { Bill, BillHistoryList, BillsFilter } from 'src/app/model/bill.model';

export abstract class CustomUserBillAdapter {
  /**
   * Abstract method used to load bill data.
   *
   * @param userId The `userId` for given user
   * @param invoiceCode The `invoiceCode` for given bill
   */
  abstract load(userId: string, invoiceCode: string): Observable<Bill>;

  /**
   * Abstract method used to load bill history for an user.
   *
   * @param userId The `userId` for given user
   * @param pageSize
   * @param currentPage
   * @param sort Sorting method
   * @param billsFilter bills filters
   */
  abstract loadHistory(
    userId: string,
    pageSize: number,
    currentPage: number,
    sort: string,
    billsFilter: BillsFilter
  ): Observable<BillHistoryList>;
}
