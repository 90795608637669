import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCarouselBannerComponent } from './custom-carousel-banner.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';



@NgModule({
  declarations: [CustomCarouselBannerComponent],
  imports: [
    CommonModule,
    CustomGenericLinkModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomCarouselBannerComponent: {
          component: CustomCarouselBannerComponent,
        },
      },
    } as CmsConfig),
    MediaModule,
    RouterModule,
  ],
  exports: [CustomCarouselBannerComponent],
  entryComponents: [CustomCarouselBannerComponent]
})
export class CustomCarouselBannerModule { }
