import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CmsComponentData, ICON_TYPE } from '@spartacus/storefront';
import { CmsService } from '@spartacus/core';
import { CustomParagraphColumnWithExpandableInfoModel } from './custom-paragraph-column-with-expanded-info';
import { fadeIn, fadeOut } from '../../../custom-animations';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-custom-paragraph-column-with-expandable-info',
  templateUrl: './custom-paragraph-column-with-expandable-info.component.html',
  styleUrls: ['./custom-paragraph-column-with-expandable-info.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class CustomParagraphColumnWithExpandableInfoComponent implements OnInit {

  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  @Input() column: CustomParagraphColumnWithExpandableInfoModel;
  showExpandableInfo = false;
  iconType = ICON_TYPE;

  data$: Observable<CustomParagraphColumnWithExpandableInfoModel> = this.componentData.data$.pipe(
    tap(component => {
        return this.initComponent(component);
      },
    ),
  );

  constructor(
    protected componentData: CmsComponentData<CustomParagraphColumnWithExpandableInfoModel>,
    protected cmsService: CmsService,
  ) {
  }

  ngOnInit(): void {
    if (this.column) {
      this.initComponent(this.column);
      this.data$ = of(this.column);
    }
  }

  initComponent(item: CustomParagraphColumnWithExpandableInfoModel): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth.toString() === 'true') {
      this.styleClasses += ' full-page-width';
    }

    if (item.backgroundColorCode) {
      this.backgroundColor = item.backgroundColorCode ?? '';
    }
  }

  toggleExpandableInfo(): void {
    this.showExpandableInfo = !this.showExpandableInfo;
  }
}
