import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Statistics, StateStatistics, StatisticsProduct } from '../../model/custom-statistics-model';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { StateUtils } from '@spartacus/core';

export const TOKEN_STATISTICS = 'statistics';

export interface StateWithStatistics {
  [TOKEN_STATISTICS]: StateStatistics;
}

export const getStatisticsState: MemoizedSelector<StateWithStatistics, StateStatistics> =
  createFeatureSelector<StateStatistics>(TOKEN_STATISTICS);

export const getStatisticsLoaderState: MemoizedSelector<
  StateWithStatistics,
  LoaderState<Statistics[]>
> = createSelector(getStatisticsState, (state: StateStatistics) => state.statistics);

export const getStatistics: MemoizedSelector<StateWithStatistics, Statistics[]> = createSelector(
  getStatisticsLoaderState,
  (state: LoaderState<Statistics[]>) => StateUtils.loaderValueSelector(state)
);

export const getStatisticsLoading: MemoizedSelector<StateWithStatistics, boolean> = createSelector(
  getStatisticsLoaderState,
  (state: LoaderState<Statistics[]>) => StateUtils.loaderLoadingSelector(state)
);

export const getStatisticsLoadedSuccess: MemoizedSelector<StateWithStatistics, boolean> = createSelector(
  getStatisticsLoaderState,
  (state: LoaderState<Statistics[]>) =>
    StateUtils.loaderSuccessSelector(state) && !StateUtils.loaderLoadingSelector(state)
);


export const getStatisticsProductLoaderState: MemoizedSelector<
  StateWithStatistics,
  LoaderState<StatisticsProduct[]>
  > = createSelector(getStatisticsState, (state: StateStatistics) => state.products);


export const getStatisticsProducts: MemoizedSelector<StateWithStatistics, StatisticsProduct[]> = createSelector(
  getStatisticsProductLoaderState,
  (state: LoaderState<StatisticsProduct[]>) => StateUtils.loaderValueSelector(state)
);

export const getStatisticsProductLoading: MemoizedSelector<StateWithStatistics, boolean> = createSelector(
  getStatisticsProductLoaderState,
  (state: LoaderState<StatisticsProduct[]>) => StateUtils.loaderLoadingSelector(state)
);
