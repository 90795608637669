import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ConverterService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GiftCard, GiftCardOcc } from 'src/app/custom/cms-components/checkout/custom-gift-card/model/custom-gift-card.model';
import { CustomGiftCardAdapter } from '../conectors';




@Injectable()
export class CustomOccGiftCardAdapter implements CustomGiftCardAdapter {

  private userId: string;
  private cartId: string;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected userIdService: UserIdService,
  ) {}

  loadAll(userId: string, cartId: string): Observable<GiftCard[]>{
    const url = this.occEndpoints.buildUrl('giftCards', {
      urlParams: { userId, cartId }
    });
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    return this.http.get<GiftCardOcc>(url, { headers })
    .pipe(
        catchError((error: any) => throwError(error)),
        map((giftCards) => {
            return giftCards.products;
          }
        )
    );
  }

  load(userId: string, cartId: string): Observable<GiftCard> {
    const url = this.occEndpoints.buildUrl('giftCard', {
      urlParams: { userId, cartId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<GiftCard>(url, { headers })
      .pipe(
        catchError((error: any) => throwError(error)),
        map((giftCard) => {
          return giftCard;
        })
      );
  }

  set(userId: string, cartId: string, giftCardCode: string, giftCardsMessage: string): Observable<any> {
    const url = this.occEndpoints.buildUrl('giftCard', {
      urlParams: { userId, cartId }
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put<GiftCard>(url, {code: giftCardCode, message: giftCardsMessage}, { headers }
     )
      .pipe(
        catchError((error: any) => throwError(error)),
        map((setGiftCard: GiftCard) => setGiftCard)
      );
  }

  delete(userId: string, cartId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('giftCard', {
      urlParams: { userId, cartId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .delete(url, { headers })
      .pipe(catchError((error: any) => throwError(error)));
  }
}
