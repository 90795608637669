import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { GiftCard } from '../../model/custom-gift-card.model';
import { CustomGiftCardsActionTypes, CustomGiftCardsListActions } from '../actions/custom-gift-cards.action';
import { CustomGiftCardsListState } from '../custom-gift-card-state';

export const initialState: GiftCard[] = [];

export const initialGiftCardState: GiftCard = {};

export function GiftCardsReducer(
  state = initialState,
  action: CustomGiftCardsListActions,
): GiftCard[] {
  switch (action.type) {
    case CustomGiftCardsActionTypes.LOAD_GIFT_CARDS_FAIL: {
      return initialState;
    }
    case CustomGiftCardsActionTypes.LOAD_GIFT_CARDS_SUCCESS: {
      return action.payload ? action.payload : initialState;
    }
  }
  return state;
}

export function selectedGiftCardReducer(
  state = initialGiftCardState,
  action: CustomGiftCardsListActions,
): GiftCard {
  switch (action.type) {
    case CustomGiftCardsActionTypes.LOAD_GIFT_CARD_FAIL: {
      return initialGiftCardState;
    }

    case CustomGiftCardsActionTypes.LOAD_GIFT_CARD_SUCCESS: {
      return action.payload ? action.payload : initialGiftCardState;
    }

    case CustomGiftCardsActionTypes.SELECT_GIFT_CARD_FAIL: {
      return initialGiftCardState;
    }

    case CustomGiftCardsActionTypes.SELECT_GIFT_CARD_SUCCESS: {
      return action.payload;
    }

    case CustomGiftCardsActionTypes.UPDATE_GIFT_CARD_FAIL: {
      return initialGiftCardState;
    }

    case CustomGiftCardsActionTypes.UPDATE_GIFT_CARD_SUCCESS: {
      return action.payload ? action.payload : initialGiftCardState;
    }

    case CustomGiftCardsActionTypes.REMOVE_GIFT_CARD_FAIL: {
      return initialGiftCardState;
    }

    case CustomGiftCardsActionTypes.REMOVE_GIFT_CARD_SUCCESS: {
      return action.payload ? action.payload : initialGiftCardState;
    }
  }
  return state;
}

export function getGiftCardReducers(): ActionReducerMap<CustomGiftCardsListState> {
  return {
    giftCards: GiftCardsReducer,
    selected: selectedGiftCardReducer,
  };
}

export const reducerGiftCardToken: InjectionToken<ActionReducerMap<CustomGiftCardsListState>> = new InjectionToken<ActionReducerMap<CustomGiftCardsListState>>(
  'GiftCardsReducers',
);

export const reducerGiftCardProvider: Provider = {
  provide: reducerGiftCardToken,
  useFactory: getGiftCardReducers,
};
