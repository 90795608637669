import { Component, HostBinding } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CmsService } from '@spartacus/core';
import { CmsCustomMediaLinksComponent } from './custom-media-links.model';

@Component({
  selector: 'app-custom-media-links',
  templateUrl: './custom-media-links.component.html',
})
export class CustomMediaLinksComponent {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  title: string;

  mediaLinks$: Observable<any[]> = this.component.data$.pipe(
    tap((data) => {
      this.title = data?.title ?? '';
      this.styleClasses = data?.styleClasses ?? '';
      if (data?.showBackgroundColorCode && (data?.showBackgroundColor === 'true' || data?.showBackgroundColor === true)) {
        this.backgroundColor = data.showBackgroundColorCode;
      }
    }),
    switchMap(data =>
      combineLatest(
        data?.mediaLinks.trim().split(' ').map((component: any) =>
          this.cmsService.getComponentData<any>(component)
        )
      )
    )
  );

  constructor(
    private component: CmsComponentData<CmsCustomMediaLinksComponent>,
    private cmsService: CmsService,
  ) {}

  getTarget(target: string | boolean): string | null {
    return target === 'true' || target === true ? '_blank' : null;
  }

  private get btnClass(): string {
    return 'btn btn-icon btn-icon-fade';
  }
}
