import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import { CustomNewsListModel } from '../custom-news.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GlobalMessageActions, GlobalMessageType, normalizeHttpError } from '@spartacus/core';
import { CustomNewsConnector } from '../../connectors/custom-news.connector';
import { AddMessage } from '@spartacus/core/src/global-message/store/actions/global-message.actions';
import {
  LOAD_NEWS_SLIDER,
  LoadNewsSlider,
  LoadNewsSliderFail,
  LoadNewsSliderSuccess,
} from '../actions/custom-news-slider.actions';

@Injectable()
export class CustomNewsSliderEffects {

  loadNewsSlider$: Observable<LoadNewsSliderSuccess | LoadNewsSliderFail | AddMessage> =
    createEffect(() => this.actions$.pipe(
      ofType(LOAD_NEWS_SLIDER),
      map((action: LoadNewsSlider) => action.payload),
      switchMap((newsParams) =>
        this.newsConnector.getNewsList(newsParams)
          .pipe(
            map((response: CustomNewsListModel) => {
              return new LoadNewsSliderSuccess(response);
            }),
            catchError((error) => {
              const normalizedResponse = normalizeHttpError(error);
              const message = normalizedResponse.details[0].message;
              const text = {
                key: message,
                params: null,
              };

              return from([
                new LoadNewsSliderFail(normalizedResponse),
                new GlobalMessageActions.AddMessage({
                  text,
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                }),
              ]);
            }),
          ),
      ),
    ));

  constructor(
    private actions$: Actions,
    private newsConnector: CustomNewsConnector,
  ) {
  }
}
