import { CxEvent } from '@spartacus/core';
import { Params } from '@angular/router';
import { NavigationEvent } from '@spartacus/storefront';

/**
 * Indicates that a user navigated to an arbitrary page.
 */
export class CustomNavigationEvent extends NavigationEvent {

  /**
   * GTM Structure
   */
  event: string;
}
