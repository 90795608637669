<ng-container *ngIf="product$ | async as product">
    <h1 id="product-title" class="title">
      {{ product.name }}
    </h1>
    <div class="d-flex align-items-center justify-content-between">
        <ng-template
        [cxOutlet]="outlets.PRICE"
        [cxOutletContext]="{ product: product }"
        >
        <div class="price" aria-label="new item price">
        {{ product?.price?.formattedValue }}
        </div>
        </ng-template>
        <div class="rating">
            <button class="btn btn-link cx-action-link" (click)="showReviews()">
              <ng-container *ngIf="product.averageRating">
                  <cx-star-rating
                  [rating]="product?.averageRating"
                  [disabled]="true"
                  ></cx-star-rating>
                  <span class="count">
                      ({{ product?.numberOfReviews }})
                  </span>
              </ng-container>
              <ng-container *ngIf="!product.averageRating">
                  {{ 'productDetails.noReviews' | cxTranslate }}
              </ng-container>
            </button>
        </div>
    </div>
    <app-custom-paypal-installments [installmentsAmount]="product?.price?.value"></app-custom-paypal-installments>
    <ng-container *ngIf="product?.potentialPromotions">
        <!-- <cx-promotions [promotions]="product?.potentialPromotions"></cx-promotions> -->
        <div class="promotions-container">
            <app-custom-promotions [promotions]="product?.potentialPromotions"></app-custom-promotions>
        </div>
    </ng-container>
    <!-- <div class="code">
        {{ 'productSummary.id' | cxTranslate }} {{ product?.code }}
    </div> -->
</ng-container>
