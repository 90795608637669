import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-header-tables',
  templateUrl: './custom-header-tables.component.html',
  styleUrls: ['./custom-header-tables.component.scss']
})
export class CustomHeaderTablesComponent implements OnInit {

 
  @Input()
  content: Array<string>;



  constructor() { }

  ngOnInit(): void {
  }

}
