import { CustomOrderEntry } from 'src/app/spartacus/spartacus-features.module';
import { Order } from '@spartacus/order/root';
import { CustomOrder, EmarsysCartItem, EmarsysPurchase } from './emarsys.model';

export function mapAvailabilityZone(store: string): string {
  switch (store) {
    case 'osbornePublicPeninsula':
      return 'oe';
    case 'osbornePublicCanarias':
      return 'oc';
    case 'osbornePublicEuropa':
      return 'oeu';
    case 'osbornePeninsula':
      return 'ope';
    case 'osborneCanarias':
      return 'opc';
    case 'osborneEuropa':
      return 'opi';
    case 'riofrioPeninsula':
      return 'ce';
    case 'riofrioCanarias':
      return 'cc';
    default:
      return 'oe';
  }
}

export function getExperienceName(experienceRaw: string) {
  // Remove anything before experiencia- or visitas (In the case of visitas it returns it)
  // Then remove anything after ? or # (like a query or a fragment)
  // Also lowercase the string just in case.
  const experience = experienceRaw
    .toLowerCase()
    .replace(/.*?experiencias?-/g, '')
    .replace(/.*(visitas)/, '$1')
    .replace(/[?#].*/, '');
  // Then replace the - or _ to spaces
  return experience.replace(/[-_]/g, ' ');
}

export function getBrandOrCategoryName(value: string, isCategory: boolean) {
  // Remove anything before nav_ (if category is true) marca_ or brand_
  // and anything after ? or # (like a query or a fragment)
  // Also lowercase the string just in case.
  const regex = isCategory ? /.*?(nav[_-]|marca[_-]|brand[_-]|riofrio[_-])/g : /.*?(marca[_-]|brand[_-])/g;
  const finalValue = value
    .toLowerCase()
    .replace(regex, '')
    .replace(/[?#].*/, '');

  // Replace - _ to spaces
  return finalValue.replace(/[-_]/g, ' ');
}

// Riofrio brands are content pages, this workaround is for recognize them.
export function getRioFrioBrands(value: string) {
  const urlPattern = /^\/?(es|en|fr)\/(esturion|nazzarii|caviar)([?#].*)?$/;
  const match = urlPattern.exec(value);
  return match ? match[2] : undefined;
}

export function mapPurchase(order: Order): EmarsysPurchase | undefined {
  if (!order?.entries) {
    return;
  }
  return { orderId: order.code, items: mapPurchaseItems(order) };
}

// Return the list of discount codes separated by ;
export function discountList(order: Order): string {
  let discountList = [];
  order?.appliedOrderPromotions?.forEach((promotion) => {
    discountList.push(promotion.promotion.code);
  });
  order?.appliedProductPromotions?.forEach((promotion) => {
    discountList.push(promotion.promotion.code);
  });
  order.appliedVouchers?.forEach((voucher) => {
    discountList.push(voucher.code);
  });

  return discountList.join(';');
}

function mapPurchaseItems(order: CustomOrder): EmarsysCartItem[] {
  const cartDiscountPercentage: number = getCartDiscountPercentageForEntry(order);

  const items = order.entries.map((entry: CustomOrderEntry) => {
    return {
      item: entry?.product?.code ?? '',
      price: getPriceWithDiscounts(entry, cartDiscountPercentage),
      quantity: getQuantity(entry.unit.conversion, entry?.quantity),
    };
  });

  // Add delivery cost as a dummy product
  const deliveryItem = {
    item: getDeliveryType(order.deliveryMode.code),
    price: order.deliveryMode.deliveryCost.value,
    quantity: 1,
  }

  // Add giftCard as a dummy product
  if(order.giftCardProduct) {
    const corporateCards = {
      item: order.giftCardProduct.code,
      price: order.giftCardProduct.price.value,
      quantity: 1,
    }
    items.push(corporateCards)
  }
  items.push(deliveryItem)
  return items;
}

function getCartDiscountPercentageForEntry(order: Order): number {
  const totalCartDiscounts: number = (order?.totalDiscounts?.value ?? 0) - (order?.productDiscounts?.value ?? 0);
  const totalOrderWithoutCartDiscounts: number = (order.subTotal?.value ?? 0) + totalCartDiscounts;
  return (totalCartDiscounts * 100) / totalOrderWithoutCartDiscounts;
}

function getPriceWithDiscounts(entry: CustomOrderEntry, discountPercentage: number = 0): number {
  const entryTotalPrice: number = entry?.totalPrice?.value ?? 0;
  const discountedPrice: number = entryTotalPrice - (entryTotalPrice * (discountPercentage / 100));
  return parseFloat(discountedPrice.toFixed(2));
}

function getDeliveryType(deliveryMode: string) {
  if (deliveryMode.includes('express')) {
    return 'GEexpress'
  }
  return 'GEestandar'
}

export function mapCartItems(entries: CustomOrderEntry[]): EmarsysCartItem[] {
  return entries.map((entry) => {
    return {
      item: entry?.product?.code ?? '',
      price: entry?.totalPrice?.value ?? 0,
      quantity: getQuantity(entry.unit.conversion, entry?.quantity),
    };
  });
}

export function getQuantity(unitConversion: number, quantity: number) {
  if(unitConversion && quantity && unitConversion > 0 && quantity > 0) {
    // In case is a kit and has possibly be bought more than once.
    return unitConversion * quantity;
  }
  return unitConversion ?? quantity ?? 0
}

export function clearQueriesAndFragments(value: string): string {
  return value.replace(/[?#].*$/, '')
}

export function shouldTriggerPurchase(name: string, orderCode: string) {
  const orderCodeLS = localStorage.getItem(name);

  return !orderCodeLS || orderCodeLS !== orderCode;
}
