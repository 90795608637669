import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Classification, OccEndpointsService, Product, ProductScope } from '@spartacus/core';
import { ProductAttributesComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomCurrentProductService } from "../../../../core/product/services/custom-current-product.service";

@Component({
  selector: 'app-custom-product-attributes',
  templateUrl: './custom-product-attributes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomProductAttributesComponent extends ProductAttributesComponent {

  // classification classes available to display
  readonly availableClassifications = ['iberian', 'caviar', 'conservas', 'accessories', 'drinks'];

  product$: Observable<Product> = this.currentProductService.getProduct(
    [ProductScope.ATTRIBUTES, ProductScope.SPECIFICATIONS]
  );

  classifications$: Observable<Classification[]> = this.product$.pipe(
    map(product =>
      product.classifications.filter(c => this.availableClassifications.includes(c.code))
    ),
  )

  constructor(private occEndpointsService: OccEndpointsService,
    protected currentProductService: CustomCurrentProductService) {
    super(currentProductService);
  }

  getDownloadUrl(url: string): string {
    return this.occEndpointsService.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    }) + url;
  }
}
