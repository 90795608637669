import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule, provideDefaultConfig } from '@spartacus/core';
import {BoxCategoriesComponent} from './box-categories.component';
import { MediaModule, OutletModule, PageComponentModule } from '@spartacus/storefront';
import { BoxItemCategoriesModule } from '../box-item-categories/box-item-categories.module';

@NgModule({
  declarations: [BoxCategoriesComponent],
  imports: [
    CommonModule,
    BoxItemCategoriesModule,
    PageComponentModule,
    OutletModule,
    MediaModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BoxCategoriesComponent: {
          component: BoxCategoriesComponent
        }
      }
    } as CmsConfig)
  ],
})
export class BoxCategoriesModule { }
