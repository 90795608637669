<h2>{{ "addressBook.titleAddressBook" | cxTranslate }}</h2>

<div class="cx-section">
  <ng-container
    *ngIf="
      (addresses$ | async).length &&
      !(showAddAddressForm || showEditAddressForm)
    "
  >
    <div class="header-default">
      {{ "addressBook.defaultAddressBook" | cxTranslate }}
    </div>
    <div
      class="cx-address-deck"
      *ngIf="!(addressesStateLoading$ | async); else loading"
    >
    <app-custom-header-tables
        [content]="[
          'addressBook.addressBookAlias' | cxTranslate,
          'addressBook.addressBookName' | cxTranslate,
          'addressBook.addressBookDirection' | cxTranslate,
          'addressBook.addressBookPhone' | cxTranslate,
          'addressBook.addressBookObservations' | cxTranslate,
          ''
        ]"
      ></app-custom-header-tables>
      <ng-container *ngFor="let address of addresses$ | async">
        <ng-container *ngIf="address.defaultAddress">
          <div class="cx-address-card defaultAddress">
            <app-custom-row
              [border]="true"
              [fitToContainer]="true"
              [content]="getRowContent(address, false) | async"
              (editCard)="editAddressButtonHandle(address)"
              (setDefaultCard)="setAddressAsDefault(address.id)"
              (deleteCard)="setEdit(address.id)"
              [editMode]="address.id === editCard"
              (cancelCard)="cancelCard()"
            ></app-custom-row>
          </div>
        </ng-container>
      </ng-container>
      <div class="row buttons-actions">
        <div class="col-md-12">
          <button class="btn btn-action" (click)="addAddressButtonHandle()">
            {{ "addressBook.addNewAddress" | cxTranslate }}
          </button>
        </div>
      </div>
      <!-- address book -->
      <ng-container *ngIf="(addresses$ | async)?.length > 1">
        <div class="header-default">
          {{ "addressBook.addressBooklList" | cxTranslate }}
        </div>
        <div *ngIf="(addresses$ | async).length > 2" class="custom-list-filter">
          <div class="form-group">
            <label>
              <span class="label-content">
                {{ 'addressBook.filter' | cxTranslate }}
              </span>
              <input class="form-control"
                type="text"
                [formControl]="filter"
              />
            </label>
            <button
              class="btn btn-default"
              (click)="removeFilter()"
              [disabled]="!filter.value">
              {{ 'addressBook.filterRemove' | cxTranslate }}
            </button>
          </div>
        </div>
        <div class="address-list-book">
          <app-custom-header-tables
            *ngIf="(filteredAddresses$ | async)?.length"
            [content]="[
              'addressBook.addressBookAlias' | cxTranslate,
              'addressBook.addressBookName' | cxTranslate,
              'addressBook.addressBookDirection' | cxTranslate,
              'addressBook.addressBookPhone' | cxTranslate,
              'addressBook.addressBookObservations' | cxTranslate,
              ''
            ]"
          ></app-custom-header-tables>
          <div *ngIf="(filteredAddresses$ | async)?.length === 0" class="custom-list-filter-no-order">
            {{ 'addressBook.filterNoAddresses' | cxTranslate }}
          </div>
          <ng-container *ngFor="let address of filteredAddresses$ | async">
            <ng-container *ngIf="!address.defaultAddress">
              <div class="notDefaultAddress cx-address-card">
                <app-custom-row
                  [border]="true"
                  [fitToContainer]="true"
                  [content]="getRowContent(address) | async"
                  (editCard)="editAddressButtonHandle(address)"
                  (setDefaultCard)="setAddressAsDefault(address.id)"
                  (deleteCard)="setEdit(address.id)"
                  [editMode]="address.id === editCard"
                  (cancelCard)="cancelCard()"
                  [isDefault]="isFormEditable || address.visibleInAddressBook"
                ></app-custom-row>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="(!(addressesStateLoading$ | async) && !(addresses$ | async).length) || showAddAddressForm">
    <section>
      <p class="cx-section-msg">
        {{ "addressBook.addNewShippingAddress" | cxTranslate }}
      </p>
      <app-custom-address-form
        class="cx-form"
        [showTitleCode]="false"
        [showCancelBtn]="!((addresses$ | async).length === 0)"
        actionBtnLabel="{{ 'addressBook.addAddress' | cxTranslate }}"
        cancelBtnLabel="{{ 'addressBook.backToAddressList' | cxTranslate }}"
        [setAsDefaultField]="!((addresses$ | async).length === 0)"
        (submitAddress)="addAddressSubmit($event)"
        (backToAddress)="addAddressCancel()"
        (cancelCard)="cancelCard()"
        [loadAllCountries]="true"
      ></app-custom-address-form>
    </section>
  </ng-container>

  <ng-container *ngIf="showEditAddressForm">
    <section>
      <p class="cx-section-msg">
        {{ "addressBook.editShippingAddress" | cxTranslate }}
      </p>
      <app-custom-address-form
        [showTitleCode]="false"
        actionBtnLabel="{{ 'addressBook.updateAddress' | cxTranslate }}"
        cancelBtnLabel="{{ 'addressBook.backToAddressList' | cxTranslate }}"
        [addressData]="currentAddress"
        (submitAddress)="editAddressSubmit($event)"
        (backToAddress)="editAddressCancel()"
      ></app-custom-address-form>
    </section>
  </ng-container>
</div>

<ng-template #loading>
  <div class="col-md-12 cx-address-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
