import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID } from '../custom-cart-state';
import { User } from '@spartacus/user/account/root';
import { MULTI_CART_DATA } from '@spartacus/cart/base/core';

export const CUSTOM_SET_DOCUMENT_IDENTIFIER = '[Cart] Custom Set Document Identifier';
export const CUSTOM_SET_DOCUMENT_IDENTIFIER_FAIL = '[Cart] Custom Set Document Identifier Fail';
export const CUSTOM_SET_DOCUMENT_IDENTIFIER_SUCCESS = '[Cart] Custom Set Document Identifier Success';
export const CUSTOM_ADD_EXTRA_DATA_TO_CART = '[Cart] Custom Add Extra Data to Cart';
export const CUSTOM_ADD_EXTRA_DATA_TO_CART_FAIL = '[Cart] Custom Add Extra Data to Cart Fail';
export const CUSTOM_ADD_EXTRA_DATA_TO_CART_SUCCESS = '[Cart] Custom Add Extra Data to Cart Success';
export const CUSTOM_ADD_GIFTBOX_TO_CART = '[Cart] Custom Add Gift Box to Cart';
export const CUSTOM_ADD_GIFTBOX_TO_CART_FAIL = '[Cart] Custom Add Gift Box to Cart Fail';
export const CUSTOM_ADD_GIFTBOX_TO_CART_SUCCESS = '[Cart] Custom Add Gift Box to Cart Success';
export const CUSTOM_CLEAR_ADD_GIFTBOX_TO_CART = '[Cart] Custom Clear Add Gift Box to Cart';

export class SetDocumentIdentifier extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CUSTOM_SET_DOCUMENT_IDENTIFIER;
  constructor(
    public payload: { userId: string; cartId: string; user: User }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class SetDocumentIdentifierFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CUSTOM_SET_DOCUMENT_IDENTIFIER_FAIL;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      error: any;
      user: User;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class SetDocumentIdentifierSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CUSTOM_SET_DOCUMENT_IDENTIFIER_SUCCESS;
  constructor(
    public payload: { userId: string; cartId: string; user: User }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}


export class AddExtraDataToCart extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CUSTOM_ADD_EXTRA_DATA_TO_CART;
  constructor(
    public payload: { userId: string; cartId: string; extraData: {} }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class AddExtraDataToCartFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CUSTOM_ADD_EXTRA_DATA_TO_CART_FAIL;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      error: any;
      extraData: {};
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class AddExtraDataToCartSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CUSTOM_ADD_EXTRA_DATA_TO_CART_SUCCESS;
  constructor(
    public payload: { userId: string; cartId: string; extraData: {} }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class AddGiftboxToCart extends StateUtils.EntityLoadAction {
  readonly type = CUSTOM_ADD_GIFTBOX_TO_CART;
  constructor(
    public payload: { userId: string; cartId: string; giftBoxProductCode: string }
  ) {
    super(PROCESS_FEATURE, CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID);
  }
}

export class AddGiftboxToCartFail extends StateUtils.EntityFailAction {
  readonly type = CUSTOM_ADD_GIFTBOX_TO_CART_FAIL;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      error: any;
      giftBoxProductCode: string;
    }
  ) {
    super(PROCESS_FEATURE, CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID, payload.error);
  }
}

export class AddGiftboxToCartSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CUSTOM_ADD_GIFTBOX_TO_CART_SUCCESS;
  constructor(
    public payload: { userId: string; cartId: string; giftBoxProductCode: string }
  ) {
    super(PROCESS_FEATURE, CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID);
  }
}

export class ClearAddGiftboxToCart extends StateUtils.EntityLoaderResetAction {
  readonly type = CUSTOM_CLEAR_ADD_GIFTBOX_TO_CART;
  constructor() {
    super(PROCESS_FEATURE, CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID);
  }
}

export type CustomCartAction =
  | SetDocumentIdentifier
  | SetDocumentIdentifierFail
  | SetDocumentIdentifierSuccess
  | AddExtraDataToCart
  | AddExtraDataToCartFail
  | AddExtraDataToCartSuccess
  | AddGiftboxToCart
  | AddGiftboxToCartFail
  | AddGiftboxToCartSuccess;
