import { NgModule } from '@angular/core';
import { CommonModule  } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, UrlModule} from '@spartacus/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomMiniCartPreviewComponent } from './custom-mini-cart-preview.component';
import { CustomCartSharedModule } from 'src/app/spartacus/custom/cms-components/cart/cart-shared/custom-cart-shared.module';
import { CustomPromotionsModule } from 'src/app/spartacus/custom/cms-components/product/promotions/promotions.module';



@NgModule({
  declarations: [CustomMiniCartPreviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    CustomCartSharedModule,
    CustomPromotionsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomMiniCartPreviewComponent: {
          component: CustomMiniCartPreviewComponent,
       }
      }
    } as CmsConfig),
  ],
  exports: [CustomMiniCartPreviewComponent],
  entryComponents: [CustomMiniCartPreviewComponent],
})
export class CustomMiniCartPreviewModule { }
