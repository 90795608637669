<ng-container *ngIf="unitPrices && unitPrices.length">
  <ng-container *ngIf="unitPrices.length > 1; else singleUnitPrice">
    <div class="unit-selector-control" (click)="toggleOptions()">
      <div class="options-wrapper">
        <div class="options-container" [class.collapsed]="!showOptions">
          <div class="option"
               *ngFor="let option of unitPrices"
               [class.selected]="option.unitPrice.unit.code === selectedOption.unitPrice.unit.code &&
                                 option.product.code === selectedOption.product.code"
               (click)="option.unitPrice.unit.code !== selectedOption.unitPrice.unit.code ||
                        option.product.code !== selectedOption.product.code ?
                        selectOption(option) : null"
          >
            <app-custom-unit-display
              [unitPrice]="option.unitPrice"
              [showNostockText]="showNostockText && option.product.stock.stockLevelStatus === 'outOfStock'">
            </app-custom-unit-display>
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block" type="button">
        <i class="icon-chevron-down"></i>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #singleUnitPrice>
  <app-custom-unit-display
    [unitPrice]="unitPrices[0].unitPrice"
    [showNostockText]="showNostockText && unitPrices[0].product.stock.stockLevelStatus === 'outOfStock'">
  </app-custom-unit-display>
</ng-template>
