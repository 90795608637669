import { NgModule } from '@angular/core';
import {
  JsonLdProductReviewBuilder,
  JSONLD_PRODUCT_BUILDER,
  ProductSchemaBuilder,
  SCHEMA_BUILDER
} from '@spartacus/storefront';
import { CustomNewsArticleSchemaBuilder } from './news-article/custom-news-article-schema.builder';
import { CustomJsonLdBaseProductBuilder } from './product/custom-jsonld-base-product.builder';
import { CustomJsonLdProductOfferBuilder } from './product/custom-jsonld-product-offer.builder';

/**
 * Provides several standard json-ld builders that contribute
 * to collecting and building json-ld data.
 */
@NgModule({
  providers: [
    {
      provide: SCHEMA_BUILDER,
      useExisting: ProductSchemaBuilder,
      multi: true,
    },
    // OOTB breadcrumb schema builder has been removed
    {
      provide: SCHEMA_BUILDER,
      useExisting: CustomNewsArticleSchemaBuilder,
      multi: true,
    },
    // lower level json-ld builder classes offering fine-grained control
    // for product related schemas
    {
      provide: JSONLD_PRODUCT_BUILDER,
      useExisting: CustomJsonLdBaseProductBuilder,
      multi: true,
    },
    {
      provide: JSONLD_PRODUCT_BUILDER,
      useExisting: CustomJsonLdProductOfferBuilder,
      multi: true,
    },
    {
      provide: JSONLD_PRODUCT_BUILDER,
      useExisting: JsonLdProductReviewBuilder,
      multi: true,
    },
  ],
})
export class CustomJsonLdBuilderModule {}
