import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomGiftCardComponent } from './custom-gift-card.component';
import { I18nModule, StateModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { GIFT_CARD_FEATURE } from './store/custom-gift-card-state';
import { reducerGiftCardProvider, reducerGiftCardToken } from './store/reducers/custom-gift-card.reducer';
import { EffectsModule } from '@ngrx/effects';
import { effectsGiftCard } from './store/effects/custom-gift-cards.effect';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule, MediaModule, SpinnerModule } from '@spartacus/storefront';


@NgModule({
  declarations: [CustomGiftCardComponent],
  exports: [CustomGiftCardComponent],
  imports: [
    CommonModule,
    StateModule,
    RouterModule,
    ReactiveFormsModule,
    I18nModule,
    MediaModule,
    UrlModule,
    RouterModule,
    SpinnerModule,
    EffectsModule.forFeature(effectsGiftCard),
    StoreModule.forFeature(GIFT_CARD_FEATURE, reducerGiftCardToken),
    FormErrorsModule,
  ],
  providers: [reducerGiftCardProvider],
})
export class CustomGiftCardModule { }

