<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div>
    <h2 class="title">
      {{ 'updateProfileForm.pageTitle' | cxTranslate }}
    </h2>
    <h3>
      {{ 'updateProfileForm.contactInfo' | cxTranslate }}
    </h3>
  </div>

  <label>
    <span class="label-content">{{
      'updateProfileForm.uid' | cxTranslate
    }}</span>
    <input
      type="text"
      class="form-control"
      name="uid"
      formControlName="uid"
      readonly
    />
    <cx-form-errors [control]="form.get('uid')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content required">{{
      'updateProfileForm.firstName.label' | cxTranslate
    }}</span>
    <input
      type="text"
      class="form-control"
      name="name"
      placeholder="{{
        'updateProfileForm.firstName.placeholder' | cxTranslate
      }}"
      [readonly]="!isFormEditable"
      formControlName="name"
    />
    <cx-form-errors [control]="form.get('name')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content required">{{
      'updateProfileForm.documentIdentifier' | cxTranslate
    }}</span>
    <input
      [readonly]="hasDocumentIdentifier$ | async"
      type="text"
      class="form-control"
      name="documentIdentifier"
      formControlName="documentIdentifier"
    />
    <cx-form-errors [control]="form.get('documentIdentifier')"></cx-form-errors>
  </label>

  <h3>
    {{ 'updateProfileForm.addressInfo' | cxTranslate }}
  </h3>

  <label formGroupName="defaultAddress">
    <span class="label-content required">
      {{ 'updateProfileForm.phone.label' | cxTranslate }}
      <small>
        {{ 'updateProfileForm.phone.info' | cxTranslate }}
      </small>
    </span>
    <input
      [maxLength]="20"
      type="text"
      class="form-control"
      name="phone"
      formControlName="phone"
      [readonly]="!isFormEditable"
    />
    <cx-form-errors
      [control]="form.get('defaultAddress.phone')"
      [translationParams]="{
        minLength: form.get('defaultAddress.phone')?.errors?.minlength?.requiredLength,
        maxLength: form.get('defaultAddress.phone')?.errors?.maxlengthWithNumber?.requiredLength
      }">
    </cx-form-errors>
  </label>

  <label formGroupName="defaultAddress">
    <span class="label-content required">{{
      'updateProfileForm.address' | cxTranslate
    }}</span>
    <input
      [maxLength]="35"
      type="text"
      class="form-control"
      name="line1"
      formControlName="line1"
      [readonly]="!isFormEditable"
    />
    <cx-form-errors [control]="form.get('defaultAddress.line1')"></cx-form-errors>
    <input
      [maxLength]="35"
      type="text"
      class="form-control"
      name="line2"
      formControlName="line2"
      [readonly]="!isFormEditable"
    />
    <cx-form-errors [control]="form.get('defaultAddress.line2')"></cx-form-errors>
  </label>

  <label formGroupName="defaultAddress">
    <span class="label-content required">{{
      'updateProfileForm.town' | cxTranslate
    }}</span>
    <input
      [maxLength]="25"
      type="text"
      class="form-control"
      name="town"
      formControlName="town"
      [readonly]="!isFormEditable"
    />
    <cx-form-errors [control]="form.get('defaultAddress.town')"></cx-form-errors>
  </label>

  <label formGroupName="defaultAddress">
    <span class="label-content required">{{
      'updateProfileForm.postalCode' | cxTranslate
    }}</span>
    <input
      [maxLength]="12"
      type="text"
      class="form-control"
      name="postalCode"
      formControlName="postalCode"
      [readonly]="!isFormEditable"
    />
    <cx-form-errors [control]="form.get('defaultAddress.postalCode')"></cx-form-errors>
  </label>

  <ng-container *ngIf="countries$ | async as countries">
    <ng-container *ngIf="countries.length !== 0" formGroupName="defaultAddress">
      <label aria-required="true" formGroupName="country">
        <span class="label-content required">{{
          'updateProfileForm.country' | cxTranslate
        }}</span>
        <ng-select
          class="country-select"
          formControlName="isocode"
          [searchable]="true"
          [clearable]="false"
          [items]="countries"
          bindLabel="name"
          bindValue="isocode"
          placeholder="{{ 'updateProfileForm.selectOne' | cxTranslate }}"
          (change)="countrySelected($event)"
          [readonly]="!isFormEditable"
        >
        </ng-select>
        <cx-form-errors
          [control]="form.get('defaultAddress.country.isocode')"
        ></cx-form-errors>
      </label>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="regions$ | async as regions">
    <ng-container *ngIf="regions.length !== 0" formGroupName="defaultAddress">
      <label aria-required="true" formGroupName="region">
        <span class="label-content required">{{
          'updateProfileForm.region' | cxTranslate
          }}</span>
        <ng-select
          class="region-select"
          formControlName="isocode"
          [searchable]="true"
          [clearable]="false"
          [items]="regions"
          bindLabel="name"
          bindValue="isocode"
          placeholder="{{ 'updateProfileForm.selectOne' | cxTranslate }}"
          (change)="regionSelected($event)"
          [readonly]="!isFormEditable"
        >
        </ng-select>
        <cx-form-errors [control]="form.get('defaultAddress.region.isocode')"></cx-form-errors>
      </label>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isFormEditable">
    <div class="user-form-actions">
      <!-- <a
        class="btn btn-block btn-secondary"
        [routerLink]="{ cxRoute: 'home' } | cxUrl"
      >
        {{ 'common.cancel' | cxTranslate }}
      </a> -->
      <button class="btn btn-action" [disabled]="form.disabled" *ngIf="!isButtonDisable">
        {{ 'updateProfileForm.save' | cxTranslate }}
      </button>
    </div>
  </ng-container>
</form>
