<ng-container *ngIf="(data$ | async) as data">
  <div class="container">
    <ng-container *ngIf="data.title">
      <h2 [innerHTML]="data.title" class="title"></h2>
    </ng-container>

    <div class="row">
      <div class="col-12 col-md-6">
        <ng-container *ngIf="data.banner">
          <cx-media [container]="data.banner" class="banner"></cx-media>
        </ng-container>
      </div>
      <div class="col-12 col-md-6">
        <ng-container *ngIf="products$ | async as products">
          <div class="row justify-content-center">
            <ng-container *ngFor="let productData$ of products">
              <ng-container *ngIf="productData$ | async as product">
                <div class="col-sm-6 product-item">

                  <cx-media [container]="product.images?.PRIMARY"></cx-media>
                  <div class="name">{{product.name}}</div>
                  <ng-container *ngIf="product.price">
                    <div class="price">{{product.price.formattedValue}}</div>
                  </ng-container> 
                  <div class="actions">

                    <ng-container *ngIf="product?.code">
                      <a (click)="handleClick()"
                         [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
                         class="btn btn-primary url">
                        <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
                      </a>
                    </ng-container>

                    <ng-container *ngIf="product?.unitPrices && product?.code">
                      <app-custom-add-to-cart-unit
                        (click)="handleClick()"
                        [product]="product"
                        [showQuantity]="false"
                        [showNostockText]="true"
                        [showAddToCartText]="false">
                      </app-custom-add-to-cart-unit>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>


