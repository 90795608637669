import { PROCESS_FEATURE, StateUtils, Address } from '@spartacus/core';
import { CustomRedsysPayment } from '../../models/custom-checkout.model';
import {
  REDSYS_LOAD_PAYMENT_PROCESS_ID,
  CREATE_BILLING_ADDRESS_PROCESS_ID,
  SET_BILLING_ADDRESS_PROCESS_ID,
  UNSET_BILLING_ADDRESS_PROCESS_ID,
} from '../custom-checkout-state';

export const LOAD_REDSYS_PAYMENT = '[Checkout] Load Checkout Redsys Payment';
export const LOAD_REDSYS_PAYMENT_FAIL =
  '[Checkout] Load Checkout Redsys Payment Fail';
export const LOAD_REDSYS_PAYMENT_SUCCESS =
  '[Checkout] Load Checkout Redsys Payment Success';

export const CREATE_BILLING_ADDRESS = '[Checkout] Create Billing Address';
export const CREATE_BILLING_ADDRESS_FAIL = '[Checkout] Create Billing Address Fail';
export const CREATE_BILLING_ADDRESS_SUCCESS =
  '[Checkout] Create Billing Address Success';
export const RESET_CREATE_BILLING_ADDRESS_PROCESS =
  '[Checkout] Reset Create Billing Address Process';

export const SET_BILLING_ADDRESS = '[Checkout] Set Billing Address';
export const SET_BILLING_ADDRESS_FAIL = '[Checkout] Set Billing Address Fail';
export const SET_BILLING_ADDRESS_SUCCESS =
  '[Checkout] Set Billing Address Success';
export const RESET_SET_BILLING_ADDRESS_PROCESS =
  '[Checkout] Reset Set Billing Address Process';

export const UNSET_BILLING_ADDRESS = '[Checkout] Unset Billing Address';
export const UNSET_BILLING_ADDRESS_FAIL = '[Checkout] Unset Billing Address Fail';
export const UNSET_BILLING_ADDRESS_SUCCESS =
  '[Checkout] Unset Billing Address Success';
export const RESET_UNSET_BILLING_ADDRESS_PROCESS =
  '[Checkout] Reset Unset Billing Address Process';

export class LoadRedsysPayment extends StateUtils.EntityLoadAction {
  readonly type = LOAD_REDSYS_PAYMENT;
  constructor(public payload: { userId: string; cartId: string, saveCard: boolean }) {
    super(PROCESS_FEATURE, REDSYS_LOAD_PAYMENT_PROCESS_ID);
  }
}

export class LoadRedsysPaymentFail extends StateUtils.EntityFailAction {
  readonly type = LOAD_REDSYS_PAYMENT_FAIL;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, REDSYS_LOAD_PAYMENT_PROCESS_ID, payload);
  }
}

export class LoadRedsysPaymentSuccess extends StateUtils.EntitySuccessAction {
  readonly type = LOAD_REDSYS_PAYMENT_SUCCESS;
  constructor(public payload: CustomRedsysPayment) {
    super(PROCESS_FEATURE, REDSYS_LOAD_PAYMENT_PROCESS_ID, payload);
  }
}

export class CreateBillingAddress extends StateUtils.EntityLoadAction {
  readonly type = CREATE_BILLING_ADDRESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      billingAddress: Address;
    }
  ) {
    super(PROCESS_FEATURE, CREATE_BILLING_ADDRESS_PROCESS_ID);
  }
}

export class CreateBillingAddressFail extends StateUtils.EntityFailAction {
  readonly type = CREATE_BILLING_ADDRESS_FAIL;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, CREATE_BILLING_ADDRESS_PROCESS_ID, payload);
  }
}

export class CreateBillingAddressSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CREATE_BILLING_ADDRESS_SUCCESS;
  constructor(public payload: Address) {
    super(PROCESS_FEATURE, CREATE_BILLING_ADDRESS_PROCESS_ID);
  }
}

export class ResetCreateBillingAddressProcess extends StateUtils.EntityLoaderResetAction {
  readonly type = RESET_CREATE_BILLING_ADDRESS_PROCESS;
  constructor() {
    super(PROCESS_FEATURE, CREATE_BILLING_ADDRESS_PROCESS_ID);
  }
}

export class SetBillingAddress extends StateUtils.EntityLoadAction {
  readonly type = SET_BILLING_ADDRESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      billingAddressId: string;
    }
  ) {
    super(PROCESS_FEATURE, SET_BILLING_ADDRESS_PROCESS_ID);
  }
}

export class SetBillingAddressFail extends StateUtils.EntityFailAction {
  readonly type = SET_BILLING_ADDRESS_FAIL;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, SET_BILLING_ADDRESS_PROCESS_ID, payload);
  }
}

export class SetBillingAddressSuccess extends StateUtils.EntitySuccessAction {
  readonly type = SET_BILLING_ADDRESS_SUCCESS;
  constructor(public payload: Address) {
    super(PROCESS_FEATURE, SET_BILLING_ADDRESS_PROCESS_ID);
  }
}

export class ResetSetBillingAddressProcess extends StateUtils.EntityLoaderResetAction {
  readonly type = RESET_SET_BILLING_ADDRESS_PROCESS;
  constructor() {
    super(PROCESS_FEATURE, SET_BILLING_ADDRESS_PROCESS_ID);
  }
}
export class UnsetBillingAddress extends StateUtils.EntityLoadAction {
  readonly type = UNSET_BILLING_ADDRESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
    }
  ) {
    super(PROCESS_FEATURE, UNSET_BILLING_ADDRESS_PROCESS_ID);
  }
}

export class UnsetBillingAddressFail extends StateUtils.EntityFailAction {
  readonly type = UNSET_BILLING_ADDRESS_FAIL;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, UNSET_BILLING_ADDRESS_PROCESS_ID, payload);
  }
}

export class UnsetBillingAddressSuccess extends StateUtils.EntitySuccessAction {
  readonly type = UNSET_BILLING_ADDRESS_SUCCESS;
  constructor() {
    super(PROCESS_FEATURE, UNSET_BILLING_ADDRESS_PROCESS_ID);
  }
}

export class ResetUnsetBillingAddressProcess extends StateUtils.EntityLoaderResetAction {
  readonly type = RESET_UNSET_BILLING_ADDRESS_PROCESS;
  constructor() {
    super(PROCESS_FEATURE, UNSET_BILLING_ADDRESS_PROCESS_ID);
  }
}

export type CustomCheckoutAction =
  | LoadRedsysPayment
  | LoadRedsysPaymentFail
  | LoadRedsysPaymentSuccess
  | CreateBillingAddress
  | CreateBillingAddressSuccess
  | CreateBillingAddressFail
  | SetBillingAddress
  | SetBillingAddressSuccess
  | SetBillingAddressFail
  | UnsetBillingAddress
  | UnsetBillingAddressSuccess
  | UnsetBillingAddressFail;
