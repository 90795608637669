import { Component } from '@angular/core';
import { AnonymousConsentManagementBannerComponent } from '@spartacus/storefront';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'cx-anonymous-consent-management-banner',
  templateUrl: './custom-anonymous-consent-management-banner.component.html',
})
export class CustomAnonymousConsentManagementBannerComponent extends AnonymousConsentManagementBannerComponent {
  cookieUrl = 'https://www.osborne.es/politica-de-cookies';
  showOOTBBanner = false;
}
