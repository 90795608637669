<ng-container *ngIf="items?.length > 0 && (size$ | async) as size">
  <h3 *ngIf="title">{{title}}</h3>
  <div class="carousel-panel" [ngClass]="'size-' + size" (mouseover)="pause()" (mouseleave)="play()">
    <ng-container *ngIf="!hideButtons">
      <button
        #prev
        *ngIf="size < items.length"
        class="previous"
        (click)="updateActiveSlide(activeSlide - size)"
        [disabled]="activeSlide === 0 && !loop"
      >
        <cx-icon [type]="previousIcon"></cx-icon>
      </button>
    </ng-container>
    <div class="slides">
      <ng-container *ngFor="let _ of items; let i = index">
        <div
          class="slide"
          *ngIf="i % size === 0"
          [class.active]="i === activeSlide"
        >
          <ng-container
            *ngFor="let item of items | slice: i:i + size; let j = index"
          >
            <div
              *ngIf="item | async as data"
              class="item"
              [class.active]="i === activeSlide"
            >
              <ng-container
                *ngTemplateOutlet="template; context: { item: data }"
              ></ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!hideButtons">
      <button
        *ngIf="size < items.length"
        class="next"
        (click)="updateActiveSlide(activeSlide + size)"
        tabindex="-1"
        [disabled]="(activeSlide > items.length - size - 1) && !loop"
      >
        <cx-icon [type]="nextIcon"></cx-icon>
      </button>
    </ng-container>
  </div>

  <div *ngIf="!hideIndicators && size < items.length" class="indicators">
    <ng-container *ngFor="let _ of items; let i = index">
      <button
        #next
        *ngIf="i % size === 0"
        (focus)="updateActiveSlide(i)"
        [disabled]="i === activeSlide"
        tabindex="-1"
      >
        <cx-icon [type]="indicatorIcon"></cx-icon>
      </button>
    </ng-container>
  </div>
  <ng-container *ngIf="link?.linkName">
    <ng-container [ngSwitch]="!!data?.secondaryLink?.media?.url">
      <ng-container *ngSwitchCase="true">
        <ng-container *ngTemplateOutlet="downloadMedia; context: { link: link }">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="btn; context: { link: link }">
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #downloadMedia let-link="link">
  <ng-container *ngIf="link && link?.media?.url; else btn">
    <cx-generic-link
      [url]="getDownloadUrl(link.media.url)"
      [target]="'_blank'"
      [class]="btnClass"
    >
      {{link.linkName}}
    </cx-generic-link>
  </ng-container>
</ng-template>

<ng-template #btn let-link="link">
  <ng-container *ngIf="link && link.linkName !== ''">
    <cx-generic-link
      *ngIf="link.url"
      [url]="link.url"
      [target]="getTarget(link.target)"
      [class]="btnClass"
    >
      {{link.linkName}}
    </cx-generic-link>
    <cx-generic-link
      *ngIf="!link.url && link.category"
      [routerLink]="{ cxRoute: 'category', params: link.category } | cxUrl"
      [target]="getTarget(link.target)"
    >
      {{link.linkName}}
    </cx-generic-link>
    <cx-generic-link
      *ngIf="!link.url && !link.category && link.product"
      [routerLink]="{ cxRoute: 'product', params: link.product } | cxUrl"
      [target]="getTarget(link.target)"
    >
      {{link.linkName}}
    </cx-generic-link>
  </ng-container>
</ng-template>
