/**
 * Base cart event. Most cart events should have these properties.
 */
import {CxEvent} from '@spartacus/core';
import {EcommerceAddProduct, EcommerceRemoveProduct, EcommerceViewCart} from './CartEvents.model';
import { CartEvent } from '@spartacus/cart/base/root';

export class CustomCartAddEntrySuccessEvent extends CxEvent {
  /**
   * Event's type
   */
  static readonly type = 'CartAddEntryEvent';
  event: string;
  ecommerce: EcommerceAddProduct;
}

export class CustomCartRemoveEntrySuccessEvent extends CxEvent {
  /**
   * Event's type
   */
  static readonly type = 'CartRemoveEntrySuccessEvent';
  event: string;
  ecommerce: EcommerceRemoveProduct;
}

export class CustomCartUpdateEntrySuccessEvent extends CxEvent {
  /**
   * Event's type
   */
  static readonly type = 'CartUpdateEntrySuccessEvent';
  event: string;
  ecommerce: null | EcommerceAddProduct | EcommerceRemoveProduct;
}

export class CustomDeleteCartSuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static readonly type = 'CustomDeleteCartSuccessEvent';
}

export class CustomDeleteCartFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static readonly type = 'CustomDeleteCartFailEvent';
}

export class CustomSetDocumentIdentifierSuccessEvent extends CxEvent {
  /**
   * Event's type
   */
  static readonly type = 'CustomSetDocumentIdentifierSuccessEvent';
}

export class CustomSetDocumentIdentifierFailEvent extends CxEvent {
  /**
   * Event's type
   */
  static readonly type = 'CustomSetDocumentIdentifierFailEvent';
}

export class CustomLoadCartSuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static readonly type = 'CustomLoadCartSuccessEvent';
}

export class CustomLoadCartFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static readonly type = 'CustomLoadCartFailEvent';
}

export class CustomNavigateToCartEvent extends CxEvent {
  static readonly type = 'CustomNavigateToCartEvent';
  event: string;
  ecommerce: EcommerceViewCart;
}
