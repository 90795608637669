import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ParagraphComponent } from '@spartacus/storefront';
import { CmsParagraphComponent } from '@spartacus/core';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'cx-paragraph',
  templateUrl: './custom-paragraph.component.html',
  styleUrls: ['./custom-paragraph.component.scss'],
})
export class CustomParagraphComponent extends ParagraphComponent implements OnInit {
  @HostBinding('class') styleClasses = '';
  @HostBinding('style.background') backgroundColor: string;
  @Input() componentData: CmsParagraphComponent;

  data$ = this.component.data$.pipe(
    tap(component => {
        return this.initComponent(component);
      },
    ),
  );

  ngOnInit(): void {
    if (this.componentData) {
      this.initComponent(this.componentData);
      this.data$ = of(this.componentData);
    }
  }

  initComponent(item: CmsParagraphComponent): void {
    this.styleClasses = item?.styleClasses ?? '';
    if (item?.backgroundColorCode) {
      this.backgroundColor = item.backgroundColorCode;
    }
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
  }
}
