import { Component } from '@angular/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WishlistEntry } from 'src/app/model/wishlist.model';
import { CustomWishListService } from '../../facade/custom-wish-list.service';

@Component({
  selector: 'cx-wish-list',
  templateUrl: './custom-wish-list.component.html',
})
export class CustomWishListComponent {
  iconTypes = ICON_TYPE;
  loading$: Observable<boolean> = this.wishListService.getWishListLoaded();

  constructor(protected wishListService: CustomWishListService) {}

  wishListEntries$: Observable<
    WishlistEntry[]
  > = this.wishListService.getWishList().pipe(
    filter((wishlist) => Boolean(wishlist)),
    map((wishlist) => wishlist.wishlist?.entries)
  );

  removeEntry(event: MouseEvent, productCode: string): void {
    (event.target as HTMLButtonElement).disabled = true;
    this.wishListService.removeFromWishlist(productCode);
  }
}
