import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { CsTicketCauseList, CsTicketContactForm } from 'src/app/model/contact-form.model';
import { CUSTOM_SEND_CONTACT_FORM_PROCESS_ID } from '../custom-contact-form-state';

export const CUSTOM_CONTACT_FORM = '[Custom Contact Form] Custom Contact Form';
export const LOAD_CUSTOM_CONTACT_FORM = '[Custom Contact Form] Load Custom Contact Form';
export const LOAD_CUSTOM_CONTACT_FORM_FAIL = '[Custom Contact Form] Load Custom Contact Form Fail';
export const LOAD_CUSTOM_CONTACT_FORM_SUCCESS = '[Custom Contact Form] Load Custom Contact Form Success';
export const CLEAR_CUSTOM_CONTACT_FORM = '[Custom Contact Form] Clear Custom Contact Form';

export const CUSTOM_SEND_CONTACT_FORM = '[Custom Contact Form] Custom Send Contact Form';
export const CUSTOM_SEND_CONTACT_FORM_FAIL = '[Custom Contact Form] Custom Send Contact Form Fail';
export const CUSTOM_SEND_CONTACT_FORM_SUCCESS = '[Custom Contact Form] Custom Send Contact Form Success';
export const CLEAR_CUSTOM_SEND_CONTACT_FORM = '[Custom Contact Form] Clear Custom Send Contact Form';

export class LoadCustomContactForm extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_CUSTOM_CONTACT_FORM;
  constructor(public payload: string) {
    super(CUSTOM_CONTACT_FORM);
  }
}

export class LoadCustomContactFormFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_CUSTOM_CONTACT_FORM_FAIL;
  constructor(public payload: any) {
    super(CUSTOM_CONTACT_FORM, payload);
  }
}

export class LoadCustomContactFormSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_CUSTOM_CONTACT_FORM_SUCCESS;
  constructor(public payload: CsTicketCauseList) {
    super(CUSTOM_CONTACT_FORM);
  }
}

export class ClearCustomContactForm extends StateUtils.LoaderResetAction {
  readonly type = CLEAR_CUSTOM_CONTACT_FORM;
  constructor() {
    super(CUSTOM_CONTACT_FORM);
  }
}

export class CustomSendContactForm extends StateUtils.EntityLoadAction {
  readonly type = CUSTOM_SEND_CONTACT_FORM;
  constructor(public payload: { form: CsTicketContactForm, templateConfiguration: string }) {
    super(PROCESS_FEATURE, CUSTOM_SEND_CONTACT_FORM_PROCESS_ID);
  }
}

export class CustomSendContactFormFail extends StateUtils.EntityFailAction {
  readonly type = CUSTOM_SEND_CONTACT_FORM_FAIL;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, CUSTOM_SEND_CONTACT_FORM_PROCESS_ID, payload);
  }
}

export class CustomSendContactFormSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CUSTOM_SEND_CONTACT_FORM_SUCCESS;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, CUSTOM_SEND_CONTACT_FORM_PROCESS_ID, payload);
  }
}

export class ClearCustomSendContactForm extends StateUtils.EntityLoaderResetAction {
  readonly type = CLEAR_CUSTOM_SEND_CONTACT_FORM;
  constructor() {
    super(PROCESS_FEATURE, CUSTOM_SEND_CONTACT_FORM_PROCESS_ID);
  }
}

export type ContactFormAction =
  | LoadCustomContactForm
  | LoadCustomContactFormFail
  | LoadCustomContactFormSuccess
  | ClearCustomContactForm
  | CustomSendContactForm
  | CustomSendContactFormFail
  | CustomSendContactFormSuccess
  | ClearCustomSendContactForm;
