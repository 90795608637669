import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckoutOrderSummaryComponent } from './custom-checkout-order-summary.component';
import { ConfigModule } from '@spartacus/core';
import { CmsConfig } from '@spartacus/core';
import { CustomCartSharedModule } from '../../../cart/cart-shared/custom-cart-shared.module';
import { CustomCartTotalsModule } from '../../../cart/custom-cart-totals/custom-cart-totals.module';




@NgModule({
  declarations: [CustomCheckoutOrderSummaryComponent],
  imports: [
    CommonModule,
    CustomCartSharedModule,
    CustomCartTotalsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutOrderSummary: {
          component: CustomCheckoutOrderSummaryComponent,
        },
      },
    } as CmsConfig),
  ]
})
export class CustomCheckoutOrderSummaryModule { }