import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CsTicketCauseList, CsTicketContactForm } from 'src/app/model/contact-form.model';
import { CustomOcc } from 'src/app/spartacus/custom/core/occ/occ-models/custom-occ.models';
import { CS_TICKET_CAUSES_NORMALIZER } from './custom-contact-form-converters';
import { CustomContactFormAdapter } from './custom-contact-form.adapter';

@Injectable()
export class CustomOccContactFormAdapter implements CustomContactFormAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) { }

  public loadCsTicketCauses(
    templateConfigurations: string,
  ): Observable<CsTicketCauseList> {
    templateConfigurations = templateConfigurations ?? '';
    const url = this.occEndpoints.buildUrl('csTicketCauses', {
      urlParams: { templateConfigurations }
    });
    return this.http
      .get<CustomOcc.CsTicketCauseList>(url)
      .pipe(this.converter.pipeable(CS_TICKET_CAUSES_NORMALIZER));
  }

  public sendContactForm(
    form: CsTicketContactForm,
    templateConfiguration: string,
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl('csTicket', {
      urlParams: { templateConfiguration }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(url, form, { headers });
  }
}
