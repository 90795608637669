import { Component } from '@angular/core';
import { UpdatePasswordComponent } from '@spartacus/user/profile/components';


@Component({
  selector: 'app-custom-update-password',
  templateUrl: './custom-update-password.component.html',
  styleUrls: ['./custom-update-password.component.scss']
})
export class CustomUpdatePasswordComponent extends UpdatePasswordComponent {
}
