<div role="document" class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-body">
      <div class="cx-address-deck">
        <div class="header-default h3 text-center mt-3 mb-5">
          {{ "paymentTypes.creditCardDeletePopup.message" | cxTranslate: {cardId: creditCard.cardNumber} }}
        </div>
        <div class="row buttons-actions">
          <div class="col-md-12 text-right" *ngIf="!(isLoading$ | async);else loading">
            <button class="btn btn-default ml-2 mr-2" (click)="dismissModal('')">
              {{ "paymentTypes.creditCardDeletePopup.buttonCancel" | cxTranslate }}
            </button>
            <button class="btn btn-action ml-2 mr-2" (click)="deleteCard(creditCard.id)">
              {{ "paymentTypes.creditCardDeletePopup.buttonDelete" | cxTranslate }}
            </button>
          </div>

          <ng-template #loading>
            <div class="col-md-12 cx-address-spinner">
              <cx-spinner></cx-spinner>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>