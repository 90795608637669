import { ParamsMapping } from '@spartacus/core';

export abstract class CustomI18nRoutingConfig {
  i18nRouting: {
    [route: string]: {
      [lang: string]: {
        paths: string[],
      },
    }
  };
}
