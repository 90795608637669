import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomOcc } from '../../../occ/occ-models/custom-occ.models';
import { CustomFriendsCheckoutModel } from '../facade/custom-friends-checkout.model';
import { CustomFriendsCheckoutAdapter } from '../connectors/custom-friends-checkout.adapters';


@Injectable()
export class CustomOccFriendsCheckoutAdapter implements CustomFriendsCheckoutAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected userIdService: UserIdService,
  ) {}

  loadAll(userId: string, cartId: string): Observable<CustomFriendsCheckoutModel[]> {
    const url = this.occEndpoints.buildUrl('onBehalfOfCandidates', {
      urlParams: { userId, cartId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get<CustomOcc.CustomFriendsCheckoutList>(url, {headers})
               .pipe(
                 catchError((error: any) => throwError(error)),
                 map((CustomFriendsModelList) => {
                     return CustomFriendsModelList.members;
                   }
                 )
               );
  }

  load(userId: string, cartId: string): Observable<CustomFriendsCheckoutModel> {
    const url = this.occEndpoints.buildUrl('onBehalfOf', {
      urlParams: { userId, cartId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.get<CustomOcc.CustomFriendsCheckout>(url, {headers})
               .pipe(
                 catchError((error: any) => throwError(error)),
                 map((CustomFriendsModel) => {
                   return CustomFriendsModel;
                 })
               );
  }

  add(userId: string, cartId: string, onBehalfOfUid: string): Observable<any> {
    const url = this.occEndpoints.buildUrl('addOnBehalfOf', {
      urlParams: { userId, cartId, onBehalfOfUid }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<CustomOcc.CustomFriendsCheckout>(url, {headers})
               .pipe(
                 catchError((error: any) => throwError(error)),
                 map((newCustomFriendsModel: CustomFriendsCheckoutModel) => newCustomFriendsModel)
               );
  }

  delete(userId: string, cartId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('removeOnBehalfOf', {
      urlParams: { userId, cartId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
               .delete(url, {headers})
               .pipe(catchError((error: any) => throwError(error)));
  }
}
