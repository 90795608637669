<ng-container *ngIf="items$ | async as components">
    <ng-container *ngFor="let component of components; let i = index">
        <ng-container *ngIf="component">
                <app-box-item-categories [item]="component"></app-box-item-categories>
            <!--ng-template [cxOutlet]="component" [cxOutletContext]="{}">
                <ng-container [cxComponentWrapper]="component"></ng-container>
            </ng-template-->
        </ng-container>
    </ng-container>
</ng-container>
