import { StateUtils } from '@spartacus/core';
import { GiftCard } from '../../model/custom-gift-card.model';
import { GIFT_CARD } from '../custom-gift-card-state';

export enum CustomGiftCardsActionTypes {
  LOAD_GIFT_CARDS = '[Gift Cards] Load Gift Cards ',
  LOAD_GIFT_CARDS_SUCCESS = '[Gift Cards] Load Gift Cards Success',
  LOAD_GIFT_CARDS_FAIL = '[Gift Cards] Load Gift Cards Fail',

  LOAD_GIFT_CARD = '[Gift Card] Load Gift Card',
  LOAD_GIFT_CARD_SUCCESS = '[Gift Card] Load Gift Card Success',
  LOAD_GIFT_CARD_FAIL = '[Gift Card] Load Gift Card Fail',

  SELECT_GIFT_CARD = '[Gift Card] Select Gift Card',
  SELECT_GIFT_CARD_FAIL = '[Gift Card] SelectGift Card Fail',
  SELECT_GIFT_CARD_SUCCESS = '[Gift Card] Select Gift Card Success',

  UPDATE_GIFT_CARD = '[Gift Card] Update Gift Card',
  UPDATE_GIFT_CARD_FAIL = '[Gift Card] Update Gift Card Fail',
  UPDATE_GIFT_CARD_SUCCESS = '[Gift Card] Update Gift Card Success',

  REMOVE_GIFT_CARD = '[Gift Card] Remove Gift Card',
  REMOVE_GIFT_CARD_FAIL = '[Gift Card] Remove Gift Card Fail',
  REMOVE_GIFT_CARD_SUCCESS = '[Gift Card] Remove OGift Card Success',
}

export class LoadGiftCards {
  readonly type = CustomGiftCardsActionTypes.LOAD_GIFT_CARDS;
  constructor(
    public payload: {userId: string, cartId: string}
  ) {

  }
}

export class LoadGiftCardsFail extends StateUtils.LoaderFailAction {
  readonly type = CustomGiftCardsActionTypes.LOAD_GIFT_CARDS_FAIL;
  constructor(public payload: any) {
    super(GIFT_CARD);
  }
}

export class LoadGiftCardsSuccess {
  readonly type = CustomGiftCardsActionTypes.LOAD_GIFT_CARDS_SUCCESS;
  constructor(public payload: GiftCard[]) {}
}

export class LoadGiftCard {

  readonly type = CustomGiftCardsActionTypes.LOAD_GIFT_CARD;
  constructor(public payload: { userId: string; cartId: string }) {}
}

export class LoadGiftCardFail {
  readonly type = CustomGiftCardsActionTypes.LOAD_GIFT_CARD_FAIL;
  constructor(public payload: any) {}
}

export class LoadGiftCardSuccess {
  readonly type = CustomGiftCardsActionTypes.LOAD_GIFT_CARD_SUCCESS;
  constructor(public payload: GiftCard) {}
}

export class SelectGiftCard {
  readonly type = CustomGiftCardsActionTypes.SELECT_GIFT_CARD;
  constructor(public payload: { userId: string; cartId: string }) {}
}

export class SelectGiftCardFail {
  readonly type = CustomGiftCardsActionTypes.SELECT_GIFT_CARD_FAIL;
  constructor(public payload: any) {}
}

export class SelectGiftCardSuccess {
  readonly type = CustomGiftCardsActionTypes.SELECT_GIFT_CARD_SUCCESS;
  constructor( public payload: GiftCard
   ) {}
}

export class UpdateGiftCard {
  readonly type = CustomGiftCardsActionTypes.UPDATE_GIFT_CARD;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      giftCardCode: string;
      giftCardsMessage: string;
    }
  ) {}
}

export class UpdateGiftCardFail {
  readonly type = CustomGiftCardsActionTypes.UPDATE_GIFT_CARD_FAIL;
  constructor(public payload: any) {}
}

export class UpdateGiftCardSuccess {
  readonly type = CustomGiftCardsActionTypes.UPDATE_GIFT_CARD_SUCCESS;
  constructor(public payload: GiftCard) {}
}

export class RemoveGiftCard {
  readonly type = CustomGiftCardsActionTypes.REMOVE_GIFT_CARD;
  constructor(public payload: { userId: string; cartId: string }) {}
}

export class RemoveGiftCardFail {
  readonly type = CustomGiftCardsActionTypes.REMOVE_GIFT_CARD_FAIL;
  constructor(public payload: any) {}
}

export class RemoveGiftCardSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CustomGiftCardsActionTypes.REMOVE_GIFT_CARD_SUCCESS;
  constructor(public payload: any) {
    super(GIFT_CARD);
  }
}

// action types
export type CustomGiftCardsListActions =
  | LoadGiftCards
  | LoadGiftCardsFail
  | LoadGiftCardsSuccess
  | LoadGiftCard
  | LoadGiftCardFail
  | LoadGiftCardSuccess
  | SelectGiftCard
  | SelectGiftCardFail
  | SelectGiftCardSuccess
  | UpdateGiftCard
  | UpdateGiftCardFail
  | UpdateGiftCardSuccess
  | RemoveGiftCard
  | RemoveGiftCardFail
  | RemoveGiftCardSuccess;
