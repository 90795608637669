import { Injectable } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService, EventService, GlobalMessageService, WindowRef } from "@spartacus/core";
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { LoginFormComponentService } from "@spartacus/user/account/components";
import { EcommerceType } from "src/app/spartacus/features/tracking/custom-events/tag-manager-feature.model";
import { CustomUserLoginEvent } from "src/app/spartacus/features/tracking/custom-events/user/custom-user.events";

@Injectable({
    providedIn: 'root'
})
export class CsutomLoginFormComponentService extends LoginFormComponentService {
    loading: boolean = true; 
    constructor(
        protected auth: AuthService,
        protected globalMessage: GlobalMessageService,
        protected winRef: WindowRef,
        protected eventService: EventService
    ) {
        super(auth, globalMessage, winRef);
        this.checkAuthState();
    }

    form: UntypedFormGroup = new UntypedFormGroup({
      userId: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.emailValidator,
      ]),
      password: new UntypedFormControl('', Validators.required),
    });

    checkAuthState(): void {
        this.auth.isUserLoggedIn().subscribe((isLoggedIn) => {
          this.loading = false;
          if (isLoggedIn) {
       
            this.onSuccess(true);
          }
        });
      }

    protected onSuccess(isLoggedIn: boolean): void {
        super.onSuccess(isLoggedIn);
        if (isLoggedIn) {
            this.eventService.dispatch<CustomUserLoginEvent>(
                {
                    event: EcommerceType.LOGIN,
                    method: 'email',
                    uid: this.form?.value?.userId?.toLowerCase()
                },
                CustomUserLoginEvent,
            );
        }
    }
}
