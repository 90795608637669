<ng-container *ngIf="paymentTypes$">
  <form [formGroup]="paymentForm" class="row">
    <div class="col-md-12">
      <h3 class="cx-checkout-title">
        {{ 'checkoutProgress.methodOfPayment' | cxTranslate }}
      </h3>
      <div class="col-12 wrapper-payment-types">
        <div class="cx-payment-type-container">
          <ng-container *ngFor="let type of paymentTypes$ | async">
            <div class="form-check">
              <input
                id="paymentType-{{ type.code }}"
                class="form-check-input"
                role="radio"
                type="radio"
                aria-checked="true"
                (change)="changeType(type.code)"
                [value]="type.code"
                formControlName="paymentType"
              />
              <ng-container *ngIf="user$ | async as user">
                <label
                  class="cx-payment-type-label form-check-label form-radio-label"
                  for="paymentType-{{ type.code }}"
                >
                  <span class="cx-payment-type">
                    <ng-container [ngSwitch]="type.code">
                      <ng-container *ngSwitchCase="customPaymentType.CREDIT_CARD_PAYMENT">
                        <img
                          class="payment-icon"
                          src="assets/images/payment/credit-cart.png"
                          [alt]="'paymentTypes.paymentType' | cxTranslate: { context: type.code, display: type?.name, name: onBehalfOf?.name ? onBehalfOf.name : user.name }"
                        />
                      </ng-container>
                      <ng-container *ngSwitchCase="customPaymentType.PAYPAL_PAYMENT">
                        <img
                          class="payment-icon"
                          src="assets/images/payment/paypal.svg"
                          [alt]="'paymentTypes.paymentType' | cxTranslate: { context: type.code, display: type?.name, name: onBehalfOf?.name ? onBehalfOf.name : user.name }"
                        />
                      </ng-container>
                      <ng-container *ngSwitchCase="customPaymentType.BIZUM_PAYMENT">
                        <img
                          class="payment-icon"
                          src="assets/images/payment/bizum.svg"
                          [alt]="'paymentTypes.paymentType' | cxTranslate: { context: type.code, display: type?.name, name: onBehalfOf?.name ? onBehalfOf.name : user.name }"
                        />
                      </ng-container>
                    </ng-container>
                    {{
                    "paymentTypes.paymentType" | cxTranslate: {
                      context: type.code,
                      display: type?.name,
                      name: onBehalfOf?.name ? onBehalfOf.name : user.name
                    }
                    }}
                  </span>
                </label>
              </ng-container>
            </div>
            <ng-container *ngIf="type.code === customPaymentType.CREDIT_CARD_PAYMENT">
              <ng-container *ngIf="paymentsList$ | async as paymentsList">
                <ng-container *ngIf="typeSelected === customPaymentType.CREDIT_CARD_PAYMENT">
                  <div
                    class="form-check ml-5 w-100"
                    [hidden]="disableSaveCreditCard"
                  >
                    <input
                      #saveCreditCard
                      id="saveCreditCard"
                      name="saveCreditCard"
                      class="form-check-input"
                      type="checkbox"
                      (change)="toggleSaveCreditCard()"
                      [checked]="saveCreditCard$ | async"
                      [disabled]="disableSaveCreditCard"
                    />
                    <label class="form-check-label" for="saveCreditCard">
                      {{ 'paymentTypes.saveCreditCard' | cxTranslate }}
                    </label>
                  </div>
                </ng-container>
                <ng-container *ngIf="paymentsList?.payments">
                  <div class="credit-card-holder">
                    <ng-container *ngFor="let creditCard of paymentsList.payments">
                      <ng-container *ngIf="creditCard.saved">
                        <div class="form-group credit-card-item">
                            <div class="form-check">
                              <input
                                id="creditCard-{{ creditCard.id }}"
                                class="form-check-input"
                                role="radio"
                                type="radio"
                                aria-checked="true"
                                (change)="selectCreditCard(creditCard.id)"
                                [value]="creditCard.id"
                                formControlName="paymentType"
                              />
                              <label
                                class="cx-payment-type-label form-check-label form-radio-label"
                                for="creditCard-{{ creditCard.id }}"
                              >
                                <span class="cx-payment-type">
                                  <img
                                    class="payment-icon"
                                    src="assets/images/payment/visa-mastercard-amex.png"
                                    [alt]="'paymentTypes.paymentType' | cxTranslate: { context: creditCard.id}"
                                  />
                                  {{
                                  "paymentTypes.creditCard" | cxTranslate: {
                                    cardNumber: creditCard.cardNumber
                                  }
                                  }}
                                </span>
                              </label>
                            </div>
                          <button
                            class="btn text-decoration-none btn-sm cx-action-link"
                            (click)="openDeleteCardModal(creditCard, creditCardPayment)"
                            tabindex="0"
                          >
                            <span class="action-name">
                              {{ 'common.delete' | cxTranslate }}
                            </span>
                            <app-custom-icons class="action-icon" [type]="iconTypes.TRASH"></app-custom-icons>
                          </button
                          >
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="paymentsLisLoading">
          <div class="cx-spinner col-12">
            <cx-spinner></cx-spinner>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</ng-container>

<div class="row">
  <div class="col-md-12">
    <div class="coupon">
        <a (click)="showCouponApply()"><h4> {{ 'voucher.coupon' | cxTranslate }}<span class="wrapper-icon-rotate"
                                                                                  [@rotatedState]="state"><app-custom-icons
          [type]="iconTypes.CARET_DOWN"></app-custom-icons></span></h4></a>
      <ng-container *ngIf="showCoupon">
        <div @fadeIn @fadeOut class="cart-coupon-wrapper">
          <cx-cart-coupon></cx-cart-coupon>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<cx-place-order [isDisabled]='!typeSelected'></cx-place-order>

