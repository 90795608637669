import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { StateWithAgeStatus } from './store/custom-age.state';
import { StateWithProcess } from '@spartacus/core/src/process/store/process-state';
import { LoadAgeStatus, UpdateAgeStatus } from './store/actions/custom-age-status.actions';
import { getAgeStatus } from './store/selectors/custom-age-status.selector';
import { CustomAgeStatusModel } from './store/reducers/custom-age-status.reducer';

@Injectable({
  providedIn: 'root',
})
export class CustomAgeStatusService {
  ageStatus: number;

  constructor(
    protected store: Store<StateWithAgeStatus | StateWithProcess<any>>,
  ) {
  }

  updateAgeStatus(status: number): void {
    this.store.dispatch(new UpdateAgeStatus(status));
  }

  getAgeStatus(): Observable<CustomAgeStatusModel> {
    return this.store.pipe(select(getAgeStatus));
  }
}
