import { Component, OnInit } from '@angular/core';
import { IconComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-icons',
  templateUrl: './custom-icons.component.html',
  styleUrls: ['./custom-icons.component.scss']
})
export class CustomIconsComponent extends IconComponent {


}
