import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import {
  CustomOrderActionTypes, OrderActionsReset,
  OrderCancellation,
  OrderCancellationFail,
  OrderCancellationSuccess,
  OrderRestoration,
  OrderRestorationFail,
  OrderRestorationSuccess,
  OrderRestoreOnly,
  OrderRestoreOnlyFail,
  OrderRestoreOnlySuccess,
} from '../actions/custom-order.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GlobalMessageActions, GlobalMessageType, RoutingService } from '@spartacus/core';
import { CustomOrderActionsConnector } from '../../connectors/custom-order-actions-connector';
import { CustomLoadUserOrders } from '../../../../../../core/user/store/actions/custom-user-orders.action';
import { CartActions } from '@spartacus/cart/base/core';


@Injectable()
export class CustomOrderActionsEffects {

  constructor(
    private actions$: Actions,
    protected customOrderConnector: CustomOrderActionsConnector,
    protected routingService: RoutingService,
  ) {}

  orderCancellation$:
    Observable<OrderCancellation | OrderCancellationFail
      | OrderCancellationSuccess | CustomLoadUserOrders
      | GlobalMessageActions.AddMessage> =
      createEffect(() => this.actions$.pipe(
      ofType(CustomOrderActionTypes.ORDER_CANCELLATION),
      map((action: OrderCancellation) => action.payload),
      mergeMap((payload: { userId: string; orderCode: string }) => {
        return this.customOrderConnector.orderCancellation(payload).pipe(
          switchMap(() =>
            [
              new OrderCancellationSuccess('orders'),
              new CustomLoadUserOrders({ userId: payload.userId, pageSize: 5 }),
              new GlobalMessageActions.AddMessage({
                text: {
                  key: 'orderDetails.actions.orderCancellationSuccess',
                  params: { orderCode: payload.orderCode },
                },
                type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
                timeout: 60000,
              }),
            ],
          ),
          catchError((error) => from(
            [
              new OrderCancellationFail(error),
              new GlobalMessageActions.AddMessage({
                text: { key: 'orderDetails.actions.orderCancellationFail' },
                type: GlobalMessageType.MSG_TYPE_ERROR,
                timeout: 60000,
              }),
            ]),
          ),
        );
      }),
    ));

  orderRestoration:
    | Observable<OrderRestoration
    | OrderRestorationFail
    | OrderRestorationSuccess
    | OrderActionsReset
    | CartActions.SetActiveCartId
    | CartActions.LoadCartSuccess
    | GlobalMessageActions.AddMessage
  > =
  createEffect(() => this.actions$.pipe(
      ofType(CustomOrderActionTypes.ORDER_RESTORATION),
      map((action: OrderRestoration) => action.payload),
      mergeMap((payload) => {
        return this.customOrderConnector.orderRestoration(payload).pipe(
          switchMap((data) => {
              return [
                new OrderRestorationSuccess('cart'),
                new CartActions.SetActiveCartId(data.code),
                new CartActions.LoadCartSuccess({
                  userId: payload.userId,
                  cartId: data.code,
                  cart: data,
                }),
                new GlobalMessageActions.AddMessage({
                  text: {
                    key: 'orderDetails.actions.orderRestorationSuccess',
                    params: { orderCode: payload.orderCode },
                  },
                  type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
                  timeout: 60000,
                }),
                new OrderActionsReset(),
              ];
            },
          ),
          catchError((error) => from([
            new GlobalMessageActions.AddMessage({
              text: { key: 'orderDetails.actions.orderRestorationFail' },
              type: GlobalMessageType.MSG_TYPE_ERROR,
            }),
            new OrderRestorationFail(error),
            new OrderActionsReset(),
          ])),
        );
      }),
    ));

  orderRestoreOnly:
    Observable<
      | OrderRestoreOnly
      | OrderRestoreOnlyFail
      | OrderRestoreOnlySuccess
      | OrderActionsReset
      | GlobalMessageActions.AddMessage
      | CartActions.LoadCartSuccess
      | CartActions.SetActiveCartId
    > =
    createEffect(() => this.actions$.pipe(
      ofType(CustomOrderActionTypes.ORDER_RESTORE_ONLY),
      map((action: OrderRestoreOnly) => action.payload),
      mergeMap((payload) => {
        return this.customOrderConnector.orderRestoreOnly(payload).pipe(
          switchMap((data) => {
            return [
              new OrderRestoreOnlySuccess('cart'),
              new CartActions.SetActiveCartId(data.code),
              new CartActions.LoadCartSuccess({
                userId: payload.userId,
                cartId: data.code,
                cart: data,
              }),
              new GlobalMessageActions.AddMessage({
                text: {
                  key: 'orderDetails.actions.orderRestoreOnlySuccess',
                  params: { orderCode: payload.orderCode },
                },
                type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              }),
              new OrderActionsReset(),
            ];
          },
          ),
          catchError((error) => from([
            new GlobalMessageActions.AddMessage({
              text: { key: 'orderDetails.actions.orderRestoreOnlyFail' },
              type: GlobalMessageType.MSG_TYPE_ERROR,
            }),
            new OrderRestoreOnlyFail(error),
            new OrderActionsReset(),
          ])),
        );
      }),
    ));
}

