import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomGiftCardsDetailsComponent } from './custom-gift-cards-details.component';
import { MediaModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';



@NgModule({
  declarations: [CustomGiftCardsDetailsComponent],
  exports: [CustomGiftCardsDetailsComponent],
  imports: [
    CommonModule,
    MediaModule,
    I18nModule,
  ]
})
export class CustomGiftCardsDetailsModule { }
