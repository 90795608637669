import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, ADDRESS_SERIALIZER, InterceptorUtil } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomCheckoutAdapter } from '../../../checkout/connectors/custom-checkout.adapter';
import { CustomRedsysPayment } from '../../../checkout/models/custom-checkout.model';
import { CustomOcc } from '../../occ-models/custom-occ.models';
import { OccCheckoutAdapter } from '@spartacus/checkout/base/occ';

@Injectable()
export class CustomOccCheckoutAdapter extends OccCheckoutAdapter implements CustomCheckoutAdapter {

  loadRedsysPayment(
    userId: string,
    cartId: string,
    saveCard: boolean,
  ): Observable<CustomRedsysPayment> {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    headers = InterceptorUtil.createHeader('saveCard', true, headers);
    let url = this.occEndpoints.buildUrl('redsysPayment', {
      urlParams: { userId, cartId, saveCard }
    });
    if (saveCard) {
      url += '?saveCard=' + saveCard;
    }
    return this.http.get<CustomOcc.RedsysPayment>(url, { headers });
  }

  public simulateOrder(
    userId: string,
    cartId: string
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl('simulate', {
      urlParams: { userId, cartId }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(url, { headers });
  }

  public validateCart(
    userId: string,
    cartId: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpoints.buildUrl('validateCart', {
      urlParams: { userId, cartId }
    });
    return this.http.post(url, { headers });
  }

  public createBillingAddress(
    userId: string,
    cartId: string,
    billingAddress: Address,
  ): Observable<any> {
    billingAddress = this.converter.convert(billingAddress, ADDRESS_SERIALIZER);
    const url = this.occEndpoints.buildUrl('billingAddress', {
      urlParams: { userId, cartId }
    });
    return this.http
      .post<any>(
        url,
        billingAddress,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
        },
      );
  }

  public setBillingAddress(
    userId: string,
    cartId: string,
    billingAddressId: string,
  ): Observable<any> {
    const params = new HttpParams().set('addressId', billingAddressId);
    const url = this.occEndpoints.buildUrl('billingAddress', {
      urlParams: { userId, cartId }
    });
    return this.http
      .put(
        url,
        {},
        {
          params,
        },
      );
  }

  public unsetBillingAddress(
    userId: string,
    cartId: string,
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl('billingAddress', {
      urlParams: { userId, cartId }
    });
    return this.http
      .delete<any>(
        url,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
        },
      );
  }
}
