import {CxEvent} from "@spartacus/core";

export class CustomUserLoginEvent extends CxEvent {
  static readonly type = 'CustomUserLoginEvent';
  event: string;
  method: string;
  uid: string;
}

export class CustomUserRegisterEvent extends CxEvent {
  static readonly type = 'CustomUserRegisterEvent';
  event: string;
  method: string;
  uid: string;
}

export class CustomUserAddProductInterestEvent extends CxEvent {
  static readonly type = 'CustomUserAddProductInterestEvent';
  event: string;
  item_name: string;
}

export class CustomGuestFriendRegisterEvent extends CxEvent {
  static readonly type = 'CustomGuestFriendRegisterEvent';
  event: string;
  method: string;
  uid: string;
}
