import { CustomGuestFriendState } from '../custom-friends-guest.state';
import { CustomGuestFriendsActions, GuestFriendsActionTypes } from '../actions/custom-friends-guest.action';
import { InjectionToken } from '@angular/core';

export const initialState: CustomGuestFriendState = {
  guest: null,
};

export function reducerGuest(
  state = initialState,
  action: CustomGuestFriendsActions,
): CustomGuestFriendState {

  switch (action.type) {

    case GuestFriendsActionTypes.LOAD_GUEST_FRIEND_SUCCESS: {
      return {
        ...state,
        guest: action.payload ? action.payload : initialState.guest,
      };
    }

    case GuestFriendsActionTypes.LOAD_GUEST_FRIEND_FAIL: {
      return initialState;

    }
  }
  return state;
}


export const reducerGuestToken = new InjectionToken('GuestFriendReducers');
export const reducerGuestFriendsProvider = {
  provide: reducerGuestToken,
  useFactory: reducerGuest,
};

