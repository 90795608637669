import { Actions } from '@ngrx/effects';
import { GiftCard } from '../../../../../../../../custom/cms-components/checkout/custom-gift-card/model/custom-gift-card.model';
import { Cart } from '@spartacus/cart/base/root';
import { CustomOrderEntry } from 'src/app/spartacus/custom/feature-libs/cart/root/models/cart.model';

export const PLACE_ORDER = '[Checkout] Place Order';
export const LOAD_CHECKOUT_STEP = '[Checkout] Load Checkout Step';
export const LOAD_CHECKOUT_STEP_SUCCESS = '[Checkout] Load Checkout Step Success';
export const SET_DELIVERY_MODE_SUCCESS = '[Checkout] Set Delivery Mode Success'; // Default set delivery mode action

export class LoadCheckoutStep extends Actions {
  readonly type = LOAD_CHECKOUT_STEP;

  constructor(public payload: { step: number, cart: Cart }) {
    super();
  }
}

export class LoadCheckoutStepSuccess extends Actions {
  readonly type = LOAD_CHECKOUT_STEP_SUCCESS;

  constructor(public payload: { step: number, entries: Array<CustomOrderEntry>, optionInformation: any, giftCard: GiftCard }) {
    super();
  }
}

export type CustomCheckoutStepsEventAction =
  | LoadCheckoutStep
  | LoadCheckoutStepSuccess;
