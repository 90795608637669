import {Component, HostBinding, Inject, OnInit, ViewChild} from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import {combineLatest, Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import { CmsCustomYoutubeInfoComponent } from './custom-video-info.model';
import {DOCUMENT} from "@angular/common";
import {CmsLinkComponent, CmsService, OccEndpointsService} from "@spartacus/core";

@Component({
  selector: 'app-custom-video-info',
  templateUrl: './custom-video-info.component.html',
})
export class CustomVideoInfoComponent implements OnInit {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  @ViewChild('player') player: any;

  data$: Observable<CmsCustomYoutubeInfoComponent> = this.component.data$.pipe(
    tap((data) => this.initComponent(data)),
  );

  buttons$: Observable<Array<CmsLinkComponent>>;

  showVideoButton: boolean;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected component: CmsComponentData<CmsCustomYoutubeInfoComponent>,
    private cmsService: CmsService,
    protected occEndpointsService: OccEndpointsService,
  ) {
  }

  ngOnInit(): void {
    const youtubeApiId = 'youtubeIframeApi';
    // This code loads the IFrame Player API code asynchronously, according to the instructions at
    // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
    if (!this.document.getElementById(youtubeApiId)) {
      const tag = this.document.createElement('script');
      tag.id = youtubeApiId;
      tag.src = 'https://www.youtube.com/iframe_api';
      this.document.body.appendChild(tag);
    }
    this.buttons$ = this.data$.pipe(
      switchMap((data: any) =>
        combineLatest(
          (data?.buttons ?? '').trim().split(' ')?.map((codes: any) =>
            this.cmsService.getComponentData(codes)
          ),
        ),
      ),
    );
  }

  playVideo(): void {
    this.player.playVideo();
  }

  initComponent(item: CmsCustomYoutubeInfoComponent): void {
    this.styleClasses = item.styleClasses ?? '';
    if (this.isTrue(item?.fullPageWidth)) {
      this.styleClasses += ' full-page-width';
    }
    this.backgroundColor = item?.backgroundColorCode ?? '';
    this.showVideoButton = this.isTrue(item?.showVideoButton)
      || item.showVideoButton == undefined;
  }

  getDownloadUrl(url: string): string {
    return this.occEndpointsService.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    }) + url;
  }

  getTarget(target: string | boolean): string | null {
    return this.isTrue(target) ? '_blank' : null;
  }

  get btnClass(): string {
    return 'btn btn-send mb-3';
  }

  isTrue(condition: boolean | string): boolean {
    return condition === true || condition === 'true'
  }
}
