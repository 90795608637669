import { Friend } from 'src/app/spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { GUEST_FRIEND_FEATURE } from '../custom-friends-guest.state';
import { Action } from '@ngrx/store';

export enum GuestFriendsActionTypes {
  RESET_GUEST_FRIEND = '[Guest Friend] Reset Guest Friend ',

  LOAD_GUEST_FRIEND = '[Guest Friend] Load Friend ',
  LOAD_GUEST_FRIEND_SUCCESS = '[Guest Friend] Load Friend Success',
  LOAD_GUEST_FRIEND_FAIL = '[Guest Friend] Load Friend Fail',

  CREATE_GUEST_FRIEND = '[Guest Friend] Create/Edit Friend',
  CREATE_GUEST_FRIEND_FAIL = '[Guest Friend] Create/EditFriend Of Fail',
  CREATE_GUEST_FRIEND_SUCCESS = '[Guest Friend] Create/Edit Friend Success',
}

export class ResetGuestFriends extends StateUtils.EntityLoaderResetAction {
  readonly type = GuestFriendsActionTypes.RESET_GUEST_FRIEND;

  constructor() {
    super(PROCESS_FEATURE, GUEST_FRIEND_FEATURE);
  }
}

export class LoadGuestFriend implements Action {
  readonly type = GuestFriendsActionTypes.LOAD_GUEST_FRIEND;

  constructor(public token: string) {
  }
}

export class LoadGuestFriendFail implements Action {
  readonly type = GuestFriendsActionTypes.LOAD_GUEST_FRIEND_FAIL;

  constructor(public payload: any) {

  }
}

export class LoadGuestFriendSuccess implements Action {
  readonly type = GuestFriendsActionTypes.LOAD_GUEST_FRIEND_SUCCESS;

  constructor(public payload: any) {

  }
}

export class CreateGuestFriend extends StateUtils.EntityLoadAction {
  readonly type = GuestFriendsActionTypes.CREATE_GUEST_FRIEND;

  constructor(public payload: { friendSignUp: Friend, token: string }) {
    super(PROCESS_FEATURE, GUEST_FRIEND_FEATURE);
  }
}

export class CreateGuestFriendFail extends StateUtils.EntityFailAction {
  readonly type = GuestFriendsActionTypes.CREATE_GUEST_FRIEND_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, GUEST_FRIEND_FEATURE);
  }
}

export class CreateGuestFriendSuccess extends StateUtils.EntitySuccessAction {
  readonly type = GuestFriendsActionTypes.CREATE_GUEST_FRIEND_SUCCESS;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, GUEST_FRIEND_FEATURE);
  }
}

export type CustomGuestFriendsActions =
  | LoadGuestFriend
  | LoadGuestFriendFail
  | LoadGuestFriendSuccess
  | CreateGuestFriend
  | CreateGuestFriendFail
  | CreateGuestFriendSuccess
  | ResetGuestFriends;
