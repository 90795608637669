import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { headers } from '../../../../../spartacus/custom/core/custom-friends/custom-friends-users/adapter/custom-occ-friends.adapter';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService, TranslationService } from '@spartacus/core';
import { Serie, Statistics, StatisticsProduct } from '../model/custom-statistics-model';
import { monthHeaderArray } from '../custom-statistics.component';

@Injectable({
  providedIn: 'root',
})
export class CustomStatisticsService {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected translation: TranslationService,
  ) {}


  statisticsData: Statistics[];
  statisticsProductData: StatisticsProduct[];
  monthValuesArray: Array<any> = Array(12).fill('');

  getNewIndex(index: number): number {
    return index === (monthHeaderArray.length - 1) ? 1 : (index + 2);
  }

  generateDefaultStatistics(years): void {
    this.statisticsData = monthHeaderArray.map((month, index) => {
        const seriesData = years.map(year => {
          return {
            name: year + ' 0',
            value: 0,
          };
        });

        return {
          name: this.getNewIndex(index),
          series: seriesData,
        };
      },
    );
  }

  generateParams(years: Array<number>): string {
    const joinParam = years.map((year: number, index: number) => {
      return index > 0 ? `&years=${ year }` : `?years=${ year }`;
    });
    return joinParam.join('');
  }

  generateStatisticsData(years: Array<number>, statistics: Statistics[]): void {
    this.generateDefaultStatistics(years);
    statistics.reverse().map((statistic: Statistics) => {
      const currentStatisticSeries = statistic.series;
      currentStatisticSeries.map((serie: Serie) => {
        this.statisticsData.map((statisticData, statisticsIndex) => {
          years.map((year: number, yearIndex: number) => {
            const seriesName = serie.name;
            const serieNameData = seriesName.split(' ');
            const serieStatisticsYear = serieNameData[0].trim();

            switch (true) {
              case (statistic.name === statisticData.name && serieStatisticsYear === year.toString()): {
                this.statisticsData[statisticsIndex].series[yearIndex] = serie;
                break;
              }

              default: {
                const statisticsDataName = this.statisticsData[statisticsIndex].series[yearIndex].name;
                const statisticsDataNameData = statisticsDataName.split(' ');
                const statisticsDataYear = statisticsDataNameData[0].trim();

                if (statisticsDataYear === serieStatisticsYear) {
                  this.statisticsData[statisticsIndex].series[yearIndex].name = seriesName;
                }
              }
            }
          });
        });
      });
    });


    this.statisticsData.map((statistic, statisticsIndex) =>
      this.translation.translate('statistics.table.' + monthHeaderArray[statisticsIndex])
        .subscribe(translation => {
          this.statisticsData[statisticsIndex].name = translation;
        }),
    );
  }

  loadAll(userId: string, years: Array<number> = []): Observable<Statistics[]> {
    const params = this.generateParams(years);
    const url = this.occEndpoints.buildUrl('statisticsValue', {
      urlParams: { userId, params }
    });
    return this.http.get<Statistics[]>(decodeURIComponent(url), { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((statistics: Statistics[]) => {
        this.generateStatisticsData(years, statistics);
        return this.statisticsData;
      }),
    );
  }

  generateDefaultStatisticsProducts(product: StatisticsProduct, year: number): Array<any> {
    return monthHeaderArray.map((month, index) => {
      const newIndex = this.getNewIndex(index);
      return product.years[year]?.[newIndex] ? product.years[year][newIndex] : 0;
    });
  }

  generateStatisticsProductData(years: Array<number>, products: StatisticsProduct[]): void {
    this.statisticsProductData = products.map(product => {
      const productData = {
        code: product.code,
        name: product.name,
        years: [],
      };

      years.map((year, index) => {
        productData.years[index] = [];
        productData.years[index] = { [year]: this.generateDefaultStatisticsProducts(product, year) };
      });
      return productData;
    });
  }

  loadAllProducts(userId: string, years: Array<number>): Observable<StatisticsProduct[]> {
    const params = this.generateParams(years);
    const url = this.occEndpoints.buildUrl('statisticsUnits', {
      urlParams: { userId, params }
    });
    return this.http.get<StatisticsProduct[]>(decodeURIComponent(url), { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((statisticsProducts: StatisticsProduct[]) => {
        this.generateStatisticsProductData(years, statisticsProducts);
        return this.statisticsProductData;
      }),
    );
  }
}

