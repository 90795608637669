import { Component, HostBinding } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CmsInfoComponent } from './info.model';
import { CMSBoxLinkImage } from '../custom-box-link-image/custom-box-link-image.model';
import { CmsInfoItem } from '../info-item/info-item.model';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent  {
  @HostBinding('class') styleClasses = '';
  @HostBinding('style.background') backgroundColor: string;
  data$: Observable<CmsInfoComponent> = this.componentData.data$.pipe(tap(component => this.initComponent(component)));

  items$: Observable<CmsInfoItem[]> = this.componentData.data$.pipe(
    switchMap(data =>
      combineLatest(
        data?.infoItem?.trim()?.split(' ')
        .map((codes: any) => this.cmsService.getComponentData(codes)
        .pipe(map(component => component))
        ))));

    constructor(
      private componentData: CmsComponentData<CmsInfoComponent>,
      private cmsService: CmsService
      ) {}

  initComponent(item: CMSBoxLinkImage): void {
    this.styleClasses = item?.styleClasses ?? '';
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
    this.backgroundColor = item?.backgroundColorCode ?? '';
  }
}
