import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { CustomDeliveryModeComponent } from './custom-delivery-mode.component';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { CustomGiftCardModule } from 'src/app/custom/cms-components/checkout/custom-gift-card/custom-gift-card.module';

@NgModule({
  declarations: [CustomDeliveryModeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    SpinnerModule,
    UrlModule,
    RouterModule,
    CustomGiftCardModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutDeliveryMode: {
          component: CustomDeliveryModeComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class CustomDeliveryModeModule { }
