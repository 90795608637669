import { Component, OnInit } from '@angular/core';
import { UserIdService } from '@spartacus/core';
import { Cart } from '@spartacus/cart/base/root';
import { Observable, Subscription } from 'rxjs';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomMultiCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-multi-cart.service';

@Component({
  selector: 'app-custom-giftbox-product-header',
  templateUrl: './custom-giftbox-product-header.component.html',
})
export class CustomGiftboxProductHeaderComponent implements OnInit {
  subscription = new Subscription();
  cart$: Observable<Cart>;

  constructor(
    protected activeCartService: CustomActiveCartService,
    protected multiCartService: CustomMultiCartService,
    protected userIdService: UserIdService,
  ) { }

  ngOnInit(): void {
    this.cart$ = this.activeCartService.getActive();
  }

  deleteCart(event: MouseEvent): void {
    (event.target as HTMLButtonElement).disabled = true;
    let userId;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    let cartId;
    this.activeCartService
      .getActiveCartId()
      .subscribe((activeCartId) => (cartId = activeCartId))
      .unsubscribe();
    this.multiCartService.deleteCart(cartId, userId);
  }
}
