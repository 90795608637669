import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomUserActions } from '../actions/index';
import { OrderActions, ReplenishmentOrderHistoryConnector } from '@spartacus/order/core';
import { OrderHistoryList } from '@spartacus/order/root';
import { CustomOrderActionsConnector } from 'src/app/spartacus/custom/cms-components/myaccount/order/custom-order-actions/connectors/custom-order-actions-connector';

@Injectable()
export class CustomUserOrdersEffect {
  constructor(
    private actions$: Actions,
    private orderConnector: CustomOrderActionsConnector,
    private replenishmentOrderConnector: ReplenishmentOrderHistoryConnector
  ) {}

  customLoadUserOrders$: Observable<OrderActions.UserOrdersAction> = createEffect(() => this.actions$.pipe(
    ofType(CustomUserActions.CUSTOM_LOAD_USER_ORDERS),
    map((action: CustomUserActions.CustomLoadUserOrders) => action.payload),
    switchMap((payload) => {
      return (Boolean(payload.replenishmentOrderCode)
        ? this.replenishmentOrderConnector.loadReplenishmentDetailsHistory(
            payload.userId,
            payload.replenishmentOrderCode,
            payload.pageSize,
            payload.currentPage,
            payload.sort
          )
        : this.orderConnector.getHistoryFilter(
            payload.userId,
            payload.pageSize,
            payload.currentPage,
            payload.sort,
            payload.ordersFilter,
          )
      ).pipe(
        map((orders: OrderHistoryList) => {
          return new OrderActions.LoadUserOrdersSuccess(orders);
        }),
        catchError((error) =>
          of(new OrderActions.LoadUserOrdersFail(normalizeHttpError(error)))
        )
      );
    })
  ));
}

