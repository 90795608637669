import { Injectable } from '@angular/core';
import { RoutingService, UserIdService, OCC_USER_ID_ANONYMOUS } from '@spartacus/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { Order, OrderHistoryFacade } from '@spartacus/order/root';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderDetailsService extends OrderDetailsService {
  constructor(
      orderHistoryFacade: OrderHistoryFacade,
      routingService: RoutingService,
      protected userIdService: UserIdService,
    ) {
      super(orderHistoryFacade, routingService);
    }

  /**
   * Gets order ID (which can be either its guid or its code).
   * Anonymous use `guid` and for logged users `code` is used.
   * @param {Order} order - Order
   * @returns {string} - Order identifier
   */
    getOrderId(order: Order): string {
    let userId: string;
    this.userIdService.getUserId()
      .subscribe((occUserId) => userId = occUserId)
      .unsubscribe();
    const orderId = userId === OCC_USER_ID_ANONYMOUS ? order.guid : order.code;
    if (!orderId) {
      throw new Error('Order ID is not defined');
    }
    return orderId;
  }
}
