import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomVideoCarouselComponent } from './custom-video-carousel.component';
import { CarouselModule, PageComponentModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomVideoCarouselComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomCarouselYoutubeInfoComponent: {
          component: CustomVideoCarouselComponent,
        },
      },
    } as CmsConfig),
    CarouselModule,
    PageComponentModule,
  ],
  entryComponents: [CustomVideoCarouselComponent],
  exports: [CustomVideoCarouselComponent],
})
export class CustomVideoCarouselModule {}

