import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OccEndpointsService, TranslationService } from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { BillHistoryList } from 'src/app/model/bill.model';
import { BusinessFriends } from '../../custom-friends/enum/business-friends.enum';
import { CustomUserBillService } from '../facade/custom-user-bill.service';

@Component({
  selector: 'app-custom-bill-history',
  templateUrl: './custom-bill-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomBillHistoryComponent implements OnDestroy, OnInit {
  protected subscription = new Subscription();

  private PAGE_SIZE = 20;
  sortType: string;
  sortFilterDateTypes = [
    { code: 'date', selected: true },
    { code: 'dueDate', selected: false },
  ];

  bills$: Observable<BillHistoryList> = this.userBillService
    .getBillHistoryList(this.PAGE_SIZE)
    .pipe(
      tap((bills: BillHistoryList) => {
        if (bills.pagination) {
          this.sortType = bills.pagination.sort;
        }
      })
    );

  isLoaded$: Observable<boolean> = this.userBillService.getBillHistoryListLoaded();

  filtersForm: FormGroup = new FormGroup({
    invoiceCode: new FormControl(''),
    startCreationTime: new FormControl(''),
    endCreationTime: new FormControl(''),
    dateType: new FormControl('date'),
    showFriends: new FormControl(false),
  });

  displayFriends$ = this.userAccountFacade
    .get()
    .pipe(
      map((user: User) => this.canDisplayFriends(user?.exclusiveRole)
    ));

  constructor(
    protected translation: TranslationService,
    protected userBillService: CustomUserBillService,
    protected occEndpointsService: OccEndpointsService,
    protected userAccountFacade: UserAccountFacade,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.filtersForm.controls.invoiceCode.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(() => this.pageChange(0))
    );
  }

  canDisplayFriends(role: string): boolean {
    return role === BusinessFriends.EMPLOYEE || role === BusinessFriends.SHAREHOLDER || role === BusinessFriends.EMPLOYEE_CREDIT_CARD;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.userBillService.clearBillList();
  }

  toggleCheckboxFilter(filterKey: string): void {
    this.filtersForm.get(filterKey)?.patchValue(!this.filtersForm.get(filterKey)?.value);
    this.pageChange(0);
  }

  updateDatepickerFilter(filterKey: string): void {
    this.filtersForm.get(filterKey)?.updateValueAndValidity();
    this.pageChange(0);
  }

  updateSelectFilter(filterKey: string, selectedCode: string): void {
    this.filtersForm.get(filterKey)?.patchValue(selectedCode);
    this.pageChange(0);
  }

  removeFilter(filterKeys: string[], reload: boolean = true): void {
    filterKeys.forEach(filterKey => {
      this.filtersForm.get(filterKey)?.reset();
    });
    if (reload) {
      this.pageChange(0);
    }
  }

  changeSortCode(sortCode: string): void {
    const event: { sortCode: string; currentPage: number } = {
      sortCode,
      currentPage: 0,
    };
    this.sortType = sortCode;
    this.fetchBills(event);
  }

  pageChange(page: number): void {
    const event: { sortCode: string; currentPage: number } = {
      sortCode: this.sortType,
      currentPage: page,
    };
    this.fetchBills(event);
  }

  getDateTypeFilterLabels(): Observable<{ date: string; dueDate: string }> {
    return combineLatest([
      this.translation.translate('sorting.billDate'),
      this.translation.translate('sorting.billDueDate'),
    ]).pipe(
      map(([textByBillDate, textByBillDueDate]) => {
        return {
          date: textByBillDate,
          dueDate: textByBillDueDate,
        };
      })
    );
  }

  getSortLabels(): Observable<{
    byCodeAsc: string;
    byCodeDesc: string;
    byDateAsc: string;
    byDateDesc: string;
    byDueDateAsc: string;
    byDueDateDesc: string;
  }> {
    return combineLatest([
      this.translation.translate('sorting.byCodeAsc'),
      this.translation.translate('sorting.byCodeDesc'),
      this.translation.translate('sorting.byDateAsc'),
      this.translation.translate('sorting.byDateDesc'),
      this.translation.translate('sorting.byDueDateAsc'),
      this.translation.translate('sorting.byDueDateDesc'),
    ]).pipe(
      map(([
        textByCodeAsc,
        textByCodeDesc,
        textByDateAsc,
        textByDateDesc,
        textByDueDateAsc,
        textByDueDateDesc,
      ]) => {
        return {
          byCodeAsc: textByCodeAsc,
          byCodeDesc: textByCodeDesc,
          byDateAsc: textByDateAsc,
          byDateDesc: textByDateDesc,
          byDueDateAsc: textByDueDateAsc,
          byDueDateDesc: textByDueDateDesc,
        };
      })
    );
  }

  getDownloadUrl(url: string): string {
    return this.occEndpointsService.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    }) + url;
  }

  private fetchBills(event: { sortCode: string; currentPage: number }): void {
    this.userBillService.loadBillList(
      this.PAGE_SIZE,
      event.currentPage,
      event.sortCode,
      this.filtersForm.value,
    );
  }
}
