
<div class="modal-dialog modal-dialog-centered modal-lg" >
  <div class="modal-content">
    <div class="cx-dialog-header modal-header d-flex justify-content-center">
        <div  *ngIf="friend$ | async as data" class="cx-dialog-title modal-title">
          <h3>¿Estas que desea eliminar al Amigo, {{ data.friend.firstName }} ?</h3>
        </div>
    </div>
    <div class="cx-dialog-body modal-body d-flex justify-content-center">
        <h3>Una vez eliminado perderá el acceso al sistema.</h3>
    </div>
    <div class="cx-dialog-buttons">
        <div class="row p-4">
            <div class="cx-dialog-actions offset-sm-6 col-sm-3">
                <button cxAutoFocus type="button" class="btn btn-block btn-send" (click)="closeModal(false)">
                    {{ 'myFriends.return' | cxTranslate }}
                </button>
            </div>
            <div *ngIf="friend$ | async as data"class="cx-dialog-actions col-sm-3">
                <button type="button" class="btn btn-block btn-send" (click)="handleRemoveFriend(data.friend)">
                    {{ 'myFriends.deleteFriend' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
  </div>
</div>
