import { Component, ElementRef, Renderer2 } from '@angular/core';
import { HamburgerMenuService, NavigationUIComponent } from '@spartacus/storefront';
import { AuthService, RoutingService, WindowRef } from '@spartacus/core';
import { Router } from '@angular/router';
import { CustomPopupShippingService } from 'src/app/custom/cms-components/user/popup-shipping/custom-popup-shipping.service';

@Component({
  selector: 'app-custom-navigation-ui',
  templateUrl: './custom-navigation-ui.component.html',
  styleUrls: ['./custom-navigation-ui.component.scss'],
})
export class CustomNavigationUiComponent extends NavigationUIComponent {
  logoutUrl = '/logout';

  constructor(
    router: Router,
    renderer: Renderer2,
    elemRef: ElementRef,
    protected hamburgerMenuService: HamburgerMenuService,
    protected winRef: WindowRef,
    protected customPopupShippingService: CustomPopupShippingService,
    protected authService: AuthService,
    protected routingService: RoutingService
  ) {
    super(router, renderer, elemRef, hamburgerMenuService, winRef);
  }

  removeBaseStore(): void {
    this.customPopupShippingService.purgeBaseStores();
    this.authService.coreLogout().then(() => {
      this.routingService.go(
        { cxRoute: 'logout' });
    });
  }
}
