<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <h2>{{
    'forgottenPassword.resetPassword' | cxTranslate
  }}</h2>
  <label>
    <span class="label-content">{{
      'forgottenPassword.emailAddress.label' | cxTranslate
    }}</span>
    <input
      type="email"
      class="form-control"
      formControlName="userEmail"
    />
    <cx-form-errors [control]="form.get('userEmail')"></cx-form-errors>
  </label>

  <button class="btn btn-block btn-login" [disabled]="form.disabled">
    {{ 'forgottenPassword.resetButton' | cxTranslate }}
  </button>
</form>
