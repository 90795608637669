<ng-container *ngIf="order$ | async as order">
  <div class="cx-order-summary">
    <div class="container">
        <ng-container *ngIf="order.replenishmentOrderCode; else otherOrder">
          <cx-card class="cx-order-summary-alt"
            [content]="
              getReplenishmentCodeCardContent(order?.replenishmentOrderCode)
                | async
            "
          ></cx-card>
  
          <ng-container *ngIf="order?.created">
            <cx-card class="cx-order-summary-alt"
              [content]="getOrderCurrentDateCardContent() | async"
            ></cx-card>
          </ng-container>
  
          <cx-card class="cx-order-summary-alt"
            [content]="getReplenishmentActiveCardContent(order?.active) | async"
          ></cx-card>
  
          <cx-card class="cx-order-summary-alt"
            [content]="
              getReplenishmentStartOnCardContent(order?.firstDate) | async
            "
          ></cx-card>
  
          <cx-card class="cx-order-summary-alt"
            [content]="
              getReplenishmentFrequencyCardContent(
                order?.trigger?.displayTimeTable
              ) | async
            "
          ></cx-card>
  
          <cx-card class="cx-order-summary-alt"
            [content]="
              getReplenishmentNextDateCardContent(order?.trigger?.activationTime)
                | async
            "
          ></cx-card>
        </ng-container>
  
        <ng-template #otherOrder>
          <cx-card class="cx-order-summary-alt cx-order-summary-order-number"
            [content]="getOrderCodeCardContent(order?.code) | async"
          ></cx-card>
  
          <cx-card class="cx-order-summary-alt"
            [content]="getOrderCurrentDateCardContent(order?.created | cxDate: 'd/MM/yyyy') | async"
          ></cx-card>
  
          <cx-card class="cx-order-summary-alt"
            [content]="getOrderStatusCardContent(order.statusDisplay) | async"
          ></cx-card>
        </ng-template>
  
        <cx-card class="cx-order-summary-alt"
          [content]="getModeOfPaymentCardContent(order) | async"
        ></cx-card>
  
        <ng-container *ngIf="order.paymentInfo">
          <cx-card
            [content]="
              getBillingAddressCardContent(order?.paymentInfo?.billingAddress)
                | async
            "
          ></cx-card>
        </ng-container>
  
        <ng-container *ngIf="order.deliveryAddress">
          <cx-card
            [content]="getAddressCardContent(order?.deliveryAddress) | async"
          ></cx-card>
        </ng-container>
  
        <ng-container *ngIf="order.deliveryMode">
          <cx-card
            [content]="getDeliveryModeCardContent(order?.deliveryMode) | async"
          ></cx-card>
        </ng-container>
  
        <ng-container
          *ngIf="order.purchaseOrderNumber || order.purchaseOrderNumber === ''"
        >
          <cx-card
            [content]="getPurchaseOrderNumber(order?.purchaseOrderNumber) | async"
          ></cx-card>
  
          <cx-card
            [content]="getMethodOfPaymentCardContent(order.paymentInfo) | async"
          ></cx-card>
  
          <ng-container *ngIf="order.costCenter">
            <cx-card
              [content]="getCostCenterCardContent(order?.costCenter) | async"
            ></cx-card>
          </ng-container>
        </ng-container>
    </div>
  </div>
</ng-container>
