import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { CustomCartAdapter } from './custom-cart.adapter';
import { User } from '@spartacus/user/account/root';
import { CartConnector } from '@spartacus/cart/base/core';
import { Cart } from '@spartacus/cart/base/root';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomCartConnector extends CartConnector {
  constructor(protected adapter: CustomCartAdapter) {
    super(adapter);
  }

  public setDocumentIdentifier(
    userId: string,
    cartId: string,
    user: User
  ): Observable<any> {
    return this.adapter.setDocumentIdentifier(userId, cartId, user).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  public addExtraData(
    userId: string,
    cartId: string,
    extraData: {},
  ): Observable<any> {
    return this.adapter.addExtraData(userId, cartId, extraData);
  }

  public addGiftbox(
    userId: string,
    giftBoxProductCode: string,
  ): Observable<Cart> {
    return this.adapter.addGiftbox(userId, giftBoxProductCode);
  }

  public validateDocIdentifierAndTotalAmount(
    userId: string,
    cartId: string,
    user: User
  ): Observable<any> {
    return this.adapter.validateDocIdentifierAndTotalAmount(userId, cartId, user).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}
