import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomPaypalInstallmentsComponent} from "./custom-paypal-installments.component";

@NgModule({
  declarations: [CustomPaypalInstallmentsComponent],
  exports: [CustomPaypalInstallmentsComponent],
  entryComponents: [CustomPaypalInstallmentsComponent],
  imports: [
    CommonModule
  ],
})
export class CustomPaypalInstallmentsModule { }
