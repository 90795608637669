<form
  *ngIf="form$ | async as form"
  [formGroup]="form"
  (ngSubmit)="submit(form)"
>
  <cx-amend-order-items
    *ngIf="entries$ | async as entries"
    [entries]="entries"
    [isConfirmation]="true"
  >
  </cx-amend-order-items>

  <cx-amend-order-actions
    *ngIf="orderCode"
    [orderCode]="orderCode"
    [amendOrderForm]="form"
    backRoute="orderReturn"
  ></cx-amend-order-actions>
</form>
