import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomNewsTitleComponent } from './custom-news-title.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule, SpinnerModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomNewsTitleComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        NewsTitleComponent: {
          component: CustomNewsTitleComponent,
        },
      },
    } as CmsConfig),
    MediaModule,
    SpinnerModule,
  ],
  exports: [CustomNewsTitleComponent],
  entryComponents: [CustomNewsTitleComponent],
})
export class CustomNewsTitleModule {}
