export const GUEST_FRIEND_FEATURE = 'guest';
export const GUEST_FRIEND = '[Guest Friend] Guest Friend';

export interface CustomGuestFriendState {
  guest: any;
}

export interface StateWithGuest {
  [GUEST_FRIEND_FEATURE]: CustomGuestFriendState;
}

