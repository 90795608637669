
import { MULTI_CART_DATA } from '@spartacus/cart/base/core';
import { StateUtils } from '@spartacus/core';

export const CART_ADD_ENTRY_UNIT = '[Cart-entry] Add Entry Unit';

export class CartAddEntryUnit extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CART_ADD_ENTRY_UNIT;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
      productCode: string;
      quantity: number;
      unitCode: string;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}
