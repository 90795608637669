<ng-container *ngIf="order$ | async as order">
  <div class="cx-page-header">
    <h2 class="cx-page-title">
      {{ 'checkoutOrderConfirmation.confirmationOfOrder' | cxTranslate }}
      {{
        (isReplenishmentOrderType$ | async)
          ? order.replenishmentOrderCode
          : order.code
      }}
    </h2>
  </div>
  <div class="cx-order-confirmation-message">
    <h3>
      {{ 'checkoutOrderConfirmation.thankYou' | cxTranslate }}
    </h3>
    <p>
      {{ 'checkoutOrderConfirmation.thankYou2' | cxTranslate }}
     <br>
      {{ 'checkoutOrderConfirmation.thankYou31' | cxTranslate }}
      <a
        [routerLink]="{ cxRoute: 'bills' } | cxUrl"
      >
        {{ "checkoutOrderConfirmation.bills" | cxTranslate }}
      </a>
      {{ 'checkoutOrderConfirmation.thankYou32' | cxTranslate }}
      <br>
      <ng-container *ngIf="!isGuestCustomer">
        {{ 'checkoutOrderConfirmation.orderDetails' | cxTranslate }}
        <a
          [routerLink]="
            {
              cxRoute: 'orderDetails',
              params: order
            } | cxUrl
          "
        >{{ "checkoutOrderConfirmation.orderDetailsLink" | cxTranslate }}</a>.
    </ng-container>
    </p>
    <div class="follow-social-media">
      <ng-container [cxComponentWrapper]="{
        flexType: 'CmsCustomMediaLinksComponent',
        typeCode: 'CmsCustomMediaLinksComponent',
        uid: 'OrderConfirmationSocialMediaComponent'
        }"></ng-container>
    </div>
<!--    <p>-->
<!--      {{ 'checkoutOrderConfirmation.invoiceHasBeenSentByEmail' | cxTranslate }}-->
<!--    </p>-->
  </div>

  <cx-add-to-home-screen-banner></cx-add-to-home-screen-banner>
</ng-container>
