import { StateUtils, Product } from '@spartacus/core';
import { Wishlist } from 'src/app/model/wishlist.model';

export const USER_WISHLIST = '[Wishlist] User Wishlist';
export const LOAD_USER_WISHLIST = '[Wishlist] Load User Wishlist';
export const LOAD_USER_WISHLIST_FAIL = '[Wishlist] Load User Wishlist Fail';
export const LOAD_USER_WISHLIST_SUCCESS = '[Wishlist] Load User Wishlist Success';
export const CLEAR_USER_WISHLIST = '[Wishlist] Clear User Wishlist';
export const ADD_PRODUCT_TO_WISHLIST = '[Wishlist] Add Product To Wishlist';
export const ADD_PRODUCT_TO_WISHLIST_SUCCESS = '[Wishlist] Add Product To Wishlist Success';
export const ADD_PRODUCT_TO_WISHLIST_FAIL = '[Wishlist] Add Product To Wishlist fail';
export const REMOVE_PRODUCT_FROM_WISHLIST = '[Wishlist] Remove Product From Wishlist';
export const REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS = '[Wishlist] Remove Product From Wishlist Success';
export const REMOVE_PRODUCT_FROM_WISHLIST_FAIL = '[Wishlist] Remove Product From Wishlist fail';

export class LoadUserWishlist extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_USER_WISHLIST;
  constructor(
    public payload: {
      userId: string;
    }
  ) {
    super(USER_WISHLIST);
  }
}

export class LoadUserWishlistFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_USER_WISHLIST_FAIL;
  constructor(public payload: any) {
    super(USER_WISHLIST, payload);
  }
}

export class LoadUserWishlistSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_USER_WISHLIST_SUCCESS;
  constructor(public payload: Wishlist) {
    super(USER_WISHLIST);
  }
}

export class ClearUserWishlist extends StateUtils.LoaderResetAction {
  readonly type = CLEAR_USER_WISHLIST;
  constructor() {
    super(USER_WISHLIST);
  }
}

export class AddProductToWishlist extends StateUtils.EntityProcessesIncrementAction {
  readonly type = ADD_PRODUCT_TO_WISHLIST;
  constructor(
    public payload: {
      userId: string;
      productCode: string;
    }
  ) {
    super(USER_WISHLIST, payload.productCode);
  }
}

export class AddProductToWishlistSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ADD_PRODUCT_TO_WISHLIST_SUCCESS;
  constructor(
    public payload: {
      userId: string;
      productCode: string;
      product: Product;
      addedDate: Date;
    }
  ) {
    super(USER_WISHLIST, payload.productCode);
  }
}

export class AddProductToWishlistFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ADD_PRODUCT_TO_WISHLIST_FAIL;
  constructor(
    public payload: {
      userId: string;
      productCode: string;
      error: any;
    }
  ) {
    super(USER_WISHLIST, payload.productCode);
  }
}

export class RemoveProductFromWishlist extends StateUtils.EntityProcessesIncrementAction {
  readonly type = REMOVE_PRODUCT_FROM_WISHLIST;
  constructor(
    public payload: {
      userId: string;
      productCode: string;
    }
  ) {
    super(USER_WISHLIST, payload.productCode);
  }
}

export class RemoveProductFromWishlistSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS;
  constructor(
    public payload: {
      userId: string;
      productCode: string;
      product: Product;
      addedDate: Date;
    }
  ) {
    super(USER_WISHLIST, payload.productCode);
  }
}

export class RemoveProductFromWishlistFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = REMOVE_PRODUCT_FROM_WISHLIST_FAIL;
  constructor(
    public payload: {
      userId: string;
      productCode: string;
      error: any;
    }
  ) {
    super(USER_WISHLIST, payload.productCode);
  }
}

export type UserWishlistAction =
  | LoadUserWishlist
  | LoadUserWishlistFail
  | LoadUserWishlistSuccess
  | ClearUserWishlist
  | AddProductToWishlist
  | AddProductToWishlistSuccess
  | AddProductToWishlistFail
  | RemoveProductFromWishlist
  | RemoveProductFromWishlistSuccess
  | RemoveProductFromWishlistFail;
