import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { OccEndpointsService, OCC_USER_ID_ANONYMOUS } from '@spartacus/core';
import { CustomBaseStoreAdapter } from '../connectors/custom-base-store.adapter';
import { CustomOcc } from '../../../../../spartacus/custom/core/occ/occ-models/custom-occ.models';

@Injectable()
export class CustomOccBaseStoreAdapter implements CustomBaseStoreAdapter {
  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) { }

  loadAll({ userId }): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let url: string;
    if (!userId || userId === OCC_USER_ID_ANONYMOUS) {
      url = this.occEndpoints.buildUrl('baseStoreAnonymous');
    } else {
      url = this.occEndpoints.buildUrl('baseStore', { urlParams: {userId} });
    }
    return this.http.get<CustomOcc.CustomBaseStoreOCCModel>(url, { headers });
  }

  load(baseStoreUid: FormGroup): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json;');
    const url = this.occEndpoints.buildUrl('currentBaseStore', { urlParams: {baseStoreUid} });
    return this.http.get<CustomOcc.CustomBaseStoreOCCModel>(url, { headers });
  }
}
