import { StateUtils } from '@spartacus/core';
import { BillHistoryList, BillsFilter } from 'src/app/model/bill.model';

export const USER_BILLS = '[Bills] User Bills';
export const LOAD_USER_BILLS = '[Bills] Load User Bills';
export const LOAD_USER_BILLS_FAIL = '[Bills] Load User Bills Fail';
export const LOAD_USER_BILLS_SUCCESS = '[Bills] Load User Bills Success';
export const CLEAR_USER_BILLS = '[Bills] Clear User Bills';

export class LoadUserBills extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_USER_BILLS;
  constructor(
    public payload: {
      userId: string;
      pageSize?: number;
      currentPage?: number;
      sort?: string;
      billsFilter?: BillsFilter;
    }
  ) {
    super(USER_BILLS);
  }
}

export class LoadUserBillsFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_USER_BILLS_FAIL;
  constructor(public payload: any) {
    super(USER_BILLS, payload);
  }
}

export class LoadUserBillsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_USER_BILLS_SUCCESS;
  constructor(public payload: BillHistoryList) {
    super(USER_BILLS);
  }
}

export class ClearUserBills extends StateUtils.LoaderResetAction {
  readonly type = CLEAR_USER_BILLS;
  constructor() {
    super(USER_BILLS);
  }
}

export type UserBillsAction =
  | LoadUserBills
  | LoadUserBillsFail
  | LoadUserBillsSuccess
  | ClearUserBills;
