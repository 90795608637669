import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageMeta, PageMetaService, PageRobotsMeta } from '@spartacus/core';
import { SeoMetaService } from '@spartacus/storefront';
import { HrefLangMeta } from '../../core/cms/page/model/custom-page.model';
import { CustomPageMetaLinkService } from './custom-page-meta-link.service';

@Injectable({
  providedIn: 'root',
})
export class CustomSeoMetaService extends SeoMetaService {
  constructor(
    protected ngTitle: Title,
    protected ngMeta: Meta,
    protected pageMetaService: PageMetaService,
    protected pageMetaLinkService?: CustomPageMetaLinkService,
  ) {
    super(ngTitle, ngMeta, pageMetaService, pageMetaLinkService);
  }

  protected set meta(meta: PageMeta) {
    this.title = meta.title;
    this.description = meta.description;
    this.image = meta.image;
    this.robots = meta.robots || [PageRobotsMeta.INDEX, PageRobotsMeta.FOLLOW];
    this.canonicalUrl = meta.canonicalUrl;
    this.hrefLangs = meta.hrefLangs;
  }

  protected set title(title: string | undefined) {
    this.ngTitle.setTitle(title || '');
    if (title) {
      this.addTag({ name: 'og:title', content: title });
    } else {
      this.ngMeta.removeTag('name="og:title"');
    }
  }

  protected set description(value: string | undefined) {
    if (value) {
      this.addTag({ name: 'description', content: value || '' });
      this.addTag({ name: 'og:description', content: value });
    } else {
      this.ngMeta.removeTag('name="description"');
      this.ngMeta.removeTag('name="og:description"');
    }
  }

  /**
   * Add hreflang links to the head of the page.
   *
   */
  protected set hrefLangs(hrefLangs: HrefLangMeta[] | undefined) {
    this.pageMetaLinkService?.setHrefLangLinks(hrefLangs);
  }
}
