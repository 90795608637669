<ng-container *ngIf="(data$ | async) as data">
  <ng-container *ngIf="(data.pdfFile || data.file) as file">
    <h3 class="file-title">{{ data.title ? data.title : file?.title}}</h3>
    <a [href]="getDownloadUrl(file?.downloadUrl)"
      [download]="file.alt + file.mime.split('/')[1]"
      class="btn-link download"
      target="_blank"
    >
      <span class="file-description">{{ data.fileDescription ? data.fileDescription : file.altText }}</span>
      <span [class]="file?.mime?.includes('pdf') ? 'icon icon-icon-pdf' : 'icon icon-file-empty'"></span>
    </a>
  </ng-container>
</ng-container>
