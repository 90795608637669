import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  GlobalMessageService,
  I18nModule,
  NotAuthGuard,
  RoutingService,
} from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { CustomResetPasswordComponentService } from './custom-reset-password-component.service';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { CustomResetPasswordComponent } from './custom-reset-password.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ResetPasswordComponent: {
          component: CustomResetPasswordComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: ResetPasswordComponentService,
              useClass: CustomResetPasswordComponentService,
              deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
            },
          ],
        },
      }
    } as CmsConfig)
  ],
  declarations: [CustomResetPasswordComponent],
  exports: [CustomResetPasswordComponent],
})
export class CustomResetPasswordModule {}
