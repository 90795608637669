import { StateUtils } from '@spartacus/core';
import { AGE_STATUS_ID } from '../custom-age.state';

export enum CustomAgeStatusActionTypes {
  LOAD_AGE_STATUS = '[Age Status] Load Age Status',
  LOAD_AGE_STATUS_SUCCESS = '[Age Status] Load Age Status Success',
  LOAD_AGE_STATUS_FAIL = '[Age Status] Load Age Status Fail',

  UPDATE_AGE_STATUS = '[Age Status] Update Age Status',
  UPDATE_AGE_STATUS_SUCCESS = '[Age Status] Update Age Status Success',
  UPDATE_AGE_STATUS_FAIL = '[Age Status] Update Age Status Fail',
}


export class LoadAgeStatus extends StateUtils.LoaderLoadAction {
  readonly type = CustomAgeStatusActionTypes.LOAD_AGE_STATUS;

  constructor(public payload: number) {
    super(AGE_STATUS_ID);
  }
}

export class LoadAgeStatusSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CustomAgeStatusActionTypes.LOAD_AGE_STATUS_SUCCESS;

  constructor(public payload: {accepted: boolean}) {
    super(AGE_STATUS_ID);
  }
}

export class LoadAgeStatusFail extends StateUtils.LoaderFailAction {
  readonly type = CustomAgeStatusActionTypes.LOAD_AGE_STATUS_FAIL;

  constructor(public payload: any) {
    super(AGE_STATUS_ID, payload);
  }
}


export class UpdateAgeStatus extends StateUtils.LoaderLoadAction {
  readonly type = CustomAgeStatusActionTypes.UPDATE_AGE_STATUS;

  constructor(public payload: number) {
    super(AGE_STATUS_ID);
  }
}

export class UpdateAgeStatusSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CustomAgeStatusActionTypes.UPDATE_AGE_STATUS_SUCCESS;

  constructor(public payload: {accepted: boolean}) {
    super(AGE_STATUS_ID);
  }
}

export class UpdateAgeStatusFail extends StateUtils.LoaderFailAction {
  readonly type = CustomAgeStatusActionTypes.UPDATE_AGE_STATUS_FAIL;

  constructor(public payload: any) {
    super(AGE_STATUS_ID, payload);
  }
}

export type CustomAgeStatusAction =
  | LoadAgeStatus
  | LoadAgeStatusSuccess
  | LoadAgeStatusFail
  | UpdateAgeStatus
  | UpdateAgeStatusSuccess
  | UpdateAgeStatusFail;
