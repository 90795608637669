import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule, UrlPipe,
} from '@spartacus/core';
import { HighlightPipe } from './highlight.pipe';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { CustomProductSearchBoxComponent } from './custom-products-search-box.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    IconModule,
    UrlModule,
    I18nModule,
  ],
  providers: [
    UrlPipe,
    provideConfig({
      cmsComponents: {
        CustomProductSearchBoxComponent: {
          component: CustomProductSearchBoxComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomProductSearchBoxComponent, HighlightPipe],
  entryComponents: [CustomProductSearchBoxComponent],
  exports: [CustomProductSearchBoxComponent],
})
export class CustomProductsSearchBoxModule {}
