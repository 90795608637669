import { Component, OnDestroy } from '@angular/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Store } from '@ngrx/store';
import { StateWithUser, UserIdService } from '@spartacus/core';
import { StateWithProcess } from '@spartacus/core/src/process/store/process-state';
import { OrderRestoreOnly } from '../store/actions/custom-order.actions';
import { Order } from '@spartacus/order/root';
import { Observable, Subscription, of } from 'rxjs';

@Component({
  selector: 'app-custom-order-restore-only',
  templateUrl: './custom-order-restore-only.component.html',
})
export class CustomOrderRestoreOnlyComponent implements OnDestroy {

  subscriptions = new Subscription();
  iconTypes = ICON_TYPE;
  loaded = true;
  cartId: string;
  order$: Observable<Order>;
  order: Order;

  constructor(
    private modalService: LaunchDialogService,
    protected store: Store<StateWithUser | StateWithProcess<void>>,
    protected userIdService: UserIdService,
  ) {
    this.subscriptions.add(
      this.modalService.data$.subscribe((order) => {
          this.order = order;
          this.order$ = of(order);
      })
    );
  }

  dismissModal(reason: string): void {
    this.modalService.closeDialog(reason);
  }

  restoreOnlyOrder(data: any): void {
    let userId;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    this.store.dispatch(new OrderRestoreOnly({ userId, orderCode: data.order.code }));
    this.dismissModal('restoreOnlyOrder');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
