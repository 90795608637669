export const REDSYS_LOAD_PAYMENT_PROCESS_ID = 'redsysLoadPayment';

export const CREATE_BILLING_ADDRESS_PROCESS_ID = 'createBillingAddress';

export const SET_BILLING_ADDRESS_PROCESS_ID = 'setBillingAddress';

export const UNSET_BILLING_ADDRESS_PROCESS_ID = 'unsetBillingAddress';

export const VALIDATE_CART_LOAD_ID = 'validateCartLoad';

export const SIMULATE_CART_LOAD_ID = 'simulateCartLoad';

