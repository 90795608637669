import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoComponent } from './info.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule, OutletModule, PageComponentModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { InfoItemModule } from '../info-item/info-item.module';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';



@NgModule({
  declarations: [InfoComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    PageComponentModule,
    OutletModule,
    MediaModule,
    I18nModule,
    InfoItemModule,
    ConfigModule.withConfig({
      cmsComponents: {
        InfoComponent: {
          component: InfoComponent
        },
      }
    } as CmsConfig)
  ],
})
export class InfoModule { }
