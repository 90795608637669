import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StateWithNews } from '../../store/custom-news.state';
import { ClearNewsDetails, LoadNewsDetails } from '../../store/actions/custom-news-details.actions';
import { getNewsDetails, getNewsDetailsLoading } from '../../store/selectors/custom-news-details.selector';
import { CustomNewsDetailsModel } from '../../store/custom-news.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomNewsTitleService {
  constructor(
    protected store: Store<StateWithNews>,
  ) {
  }

  fetchNewsDetails(uid): void {
    return this.store.dispatch(new LoadNewsDetails(uid));
  }

  getNewsDetails(): Observable<CustomNewsDetailsModel> {
    return this.store.pipe(select(getNewsDetails));
  }

  getNewsDetailsLoading(): Observable<boolean> {
    return this.store.pipe(select(getNewsDetailsLoading));
  }

  clearNewsDetails(): void {
    this.store.dispatch(new ClearNewsDetails());
  }
}
