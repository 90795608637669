import {createFrom, EventService, StateEventService, UserActions} from "@spartacus/core";
import {Injectable} from "@angular/core";
import {
  CustomGuestFriendRegisterEvent,
  CustomUserAddProductInterestEvent,
  CustomUserRegisterEvent,
} from "./custom-user.events";
import {Observable, of} from "rxjs";
import {ActionsSubject} from "@ngrx/store";
import {ofType} from "@ngrx/effects";
import {map, switchMap, tap} from "rxjs/operators";
import {EcommerceType} from "../tag-manager-feature.model";
import {
  GuestFriendsActionTypes
} from "../../../../../custom/cms-components/myaccount/custom-friends/components/custom-register-friend-form/store/actions/custom-friends-guest.action";
import {CustomCleanEcommerceEvent} from "../common/custom-common.events";

@Injectable({
  providedIn: 'root',
})
export class CustomUserEventBuilder {
  protected currentUid?: string;

  constructor(
    protected actionsSubject: ActionsSubject,
    protected eventService: EventService,
    protected stateEventService: StateEventService
  ) {
    this.register();
  }

  protected register(): void {
    this.registerAddProductInterestEvents();
    this.registerUserRegisterEvents();
    this.registerGuestFriendRegisterEvents();
  }

  protected registerAddProductInterestEvents(): void {
    this.eventService.register(
      CustomUserAddProductInterestEvent,
      this.buildAddProductInterestEvent()
    );
  }

  protected registerUserRegisterEvents(): void {
    this.eventService.register(
      CustomUserRegisterEvent,
      this.registerUserRegister(),
    );
  }

  protected registerGuestFriendRegisterEvents(): void {
    this.eventService.register(
      CustomGuestFriendRegisterEvent,
      this.registerGuestFriendRegister(),
    );
  }

  protected registerUserRegister(): Observable<CustomUserRegisterEvent> {
    return this.actionsSubject.pipe(
      ofType(UserActions.REGISTER_USER_SUCCESS),
      tap( () => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      map(() => createFrom(CustomUserRegisterEvent, {
        event: EcommerceType.SIGN_UP,
        method: 'email',
        uid: this.currentUid
      }))
    );
  }

  protected registerGuestFriendRegister(): Observable<CustomGuestFriendRegisterEvent> {
    return this.actionsSubject.pipe(
      ofType(GuestFriendsActionTypes.CREATE_GUEST_FRIEND_SUCCESS),
      tap( () => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      map((action: any) => createFrom(CustomGuestFriendRegisterEvent, {
        event: EcommerceType.SIGN_UP,
        method: 'email',
        uid: action.payload.uid
      }))
    );
  }

  protected buildAddProductInterestEvent(): Observable<CustomUserAddProductInterestEvent> {
    return this.actionsSubject.pipe(
      ofType(UserActions.ADD_PRODUCT_INTEREST_SUCCESS),
      switchMap((action: any) => of(action.payload.product)),
      map((product) => {
        return createFrom(CustomUserAddProductInterestEvent, {
          event: EcommerceType.OUT_OF_STOCK,
          item_name: product.name
        })
      })
    );
  }

  setCurrentUid(uid: string) {
    this.currentUid = uid;
  }
}
