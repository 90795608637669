import { createSelector, MemoizedSelector } from '@ngrx/store';
import { getFriendsState } from './feature-selector';
import { Friend, MyFriendsList } from '../../facade/friends.model';

import {
  CustomFriendsState, CustomFriendsUsersState,
  StateWithFriends,
} from '../../../store/custom-friends-checkout.state';


const getFriends = (state: CustomFriendsUsersState) => state;
const getFriend = (state: CustomFriendsUsersState) => state.selected;
const getStatusAction = (state: CustomFriendsUsersState) => state.status;

export const getFriendsUsersState: MemoizedSelector<StateWithFriends, CustomFriendsUsersState> = createSelector(
    getFriendsState,
    (state: CustomFriendsState) => state.user
);

export const getFriendsUserList: MemoizedSelector<StateWithFriends, MyFriendsList> = createSelector(
    getFriendsUsersState,
    getFriends
);

export const getFriendSelected: MemoizedSelector<StateWithFriends, Friend> = createSelector(
    getFriendsUsersState,
    getFriend
);

export const getStatus: MemoizedSelector<StateWithFriends, boolean> = createSelector(
    getFriendsUsersState,
    getStatusAction
);
