import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OccEndpointsService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomFriendsAdapter } from '../connectors/friends.adapter';
import { Friend, FriendsFilter, MyFriendsList } from '../facade/friends.model';
import { CustomOcc } from '../../../occ/occ-models/custom-occ.models';
import CustomFriendsUserList = CustomOcc.CustomFriendsUserList;
import { EmailInformationModel } from '../../../../../../custom/cms-components/myaccount/custom-friends/components/custom-friend-email-form/CustomerEmail.model';

export let headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

export const USE_CLIENT_TOKEN = 'cx-use-client-token';

@Injectable()
export class CustomUserFriendsAdapter implements CustomFriendsAdapter {
  constructor(protected http: HttpClient, protected occEndpoints: OccEndpointsService) {
  }

  loadAll(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    friendsFilter?: FriendsFilter,
  ): Observable<MyFriendsList> {
    const params : any = {
      userId : userId,
      pageSize : pageSize ? pageSize.toString() : "20",
      currentPage : currentPage ? currentPage.toString() : (sessionStorage.getItem('friendsCurrentpage') ? sessionStorage.getItem('friendsCurrentpage') : "0"),
      friendsFilter : friendsFilter


    };
  


    if (pageSize) {
      params['pageSize'] = pageSize.toString();
    }
    if (currentPage) {
      params['currentPage'] = currentPage.toString();

    }
    for (const key in friendsFilter) {
      if (Object.prototype.hasOwnProperty.call(friendsFilter, key)) {
        const element = friendsFilter[key];
        if (element) {
          params[key] = element;
        }
      }
    }
    let  url = this.occEndpoints.getBaseUrl() + `/users/${params.userId}/onbehalfofcandidates?pageSize=${params.pageSize}&currentPage=${params.currentPage}`;
    if (params.friendsFilter != undefined) {
      if (params.textSearch != undefined) {
        url += `&textSearch=${params.textSearch}`;
      }
      if (params.inviteStatus != undefined) {
        url += `&inviteStatus=${params.inviteStatus}`;
      }
    }
   
    sessionStorage.removeItem('friendsCurrentpage');

    return this.http.get<CustomFriendsUserList>(url, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((friends) => {
        return friends;
      }),
    );
  }

  load(userId: string, friendId: string): Observable<Friend> {
    const url = this.occEndpoints.buildUrl('friendId', {
      urlParams: { userId, friendId }
    });
    return this.http.get(url, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((friends) => {
        return friends;
      }),
    );
  }

  getGuest(token): Observable<any> {
    const tokenParse = token.replaceAll(' ', '+');
    const url = this.occEndpoints.buildUrl('guestFriendInvitation', {
      urlParams: { token: tokenParse }
    });
    let headersGuest = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    headersGuest = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headersGuest);
    return this.http.get(url, { headers: headersGuest }).pipe(
      catchError((error: any) => throwError(error)),
      map((friends) => {

        return friends;
      }),
    );
  }

  addGuest(friendSignUp: Friend, token): Observable<any> {
    const tokenParse = token.replaceAll(' ', '+');
    const url = this.occEndpoints.buildUrl('guestFriendInvitation', {
      urlParams: { token: tokenParse }
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    return this.http.post(url, { ...friendSignUp }, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((friends: any) => friends),
    );
  }

  add(userId: string, addFriend: Friend): Observable<any> {
    const url = this.occEndpoints.buildUrl('friends', {
      urlParams: { userId }
    });
    return this.http.post<any>(url, addFriend, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((friends: any) => friends),
    );
  }

  delete(userId: string, friendId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('friendId', {
      urlParams: { userId, friendId }
    });
    return this.http.delete(url, { headers }).pipe(catchError((error: any) => throwError(error)));
  }

  update(userId: string, addFriend: Friend): Observable<any> {
    const url = this.occEndpoints.buildUrl('friends', {
      urlParams: { userId }
    });
    return this.http.put<any>(url, addFriend, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((friends: any) => friends),
    );
  }

  invited(userId: string, friend: Friend): Observable<any> {
    const url = this.occEndpoints.buildUrl('invitation', {
      urlParams: { userId, friendId: friend.uid }
    });
    return this.http.post<any>(url, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((friends: any) => friends),
    );
  }

  sendEmail(userId: string, emailInformation: EmailInformationModel): Observable<any> {
    const url = this.occEndpoints.buildUrl('emails', {
      urlParams: { userId }
    });
    return this.http.post<any>(url, emailInformation, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((response: any) => response),
    );
  }
}
