import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { CustomBaseStoreModel } from '../../custom-base-store.model';
import { BASE_STORE_ID } from '../custom-base-store.state';
import { FormGroup } from '@angular/forms';
import { Action } from '@ngrx/store';

export enum CustomBaseStoreActionTypes {
  LOAD_BASE_STORE_LIST = '[Base Store] Load Base Store List',
  LOAD_BASE_STORE_LIST_SUCCESS = '[Base Store] Load Base Store List Success',
  LOAD_BASE_STORE_LIST_FAIL = '[Base Store] Load Base Store List Fail',

  UPDATE_BASE_STORE = '[Base Store] Update Base Store',
  UPDATE_BASE_STORE_SUCCESS = '[Base Store] Update Base Store Success',
  UPDATE_BASE_STORE_FAIL = '[Base Store] Update Base Store Fail',

  PURGE_BASE_STORE = '[Base Store] Purge Base Store'
}

export class LoadBaseStoreList extends StateUtils.EntityLoadAction {
  readonly type = CustomBaseStoreActionTypes.LOAD_BASE_STORE_LIST;

  constructor(public payload: {userId: string}) {
    super(PROCESS_FEATURE, BASE_STORE_ID);
  }
}

export class LoadBaseStoreListSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CustomBaseStoreActionTypes.LOAD_BASE_STORE_LIST_SUCCESS;

  constructor(public payload: CustomBaseStoreModel[]) {
    super(PROCESS_FEATURE, BASE_STORE_ID, payload);
  }
}

export class LoadBaseStoreListFail extends StateUtils.EntityFailAction {
  readonly type = CustomBaseStoreActionTypes.LOAD_BASE_STORE_LIST_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, BASE_STORE_ID, payload);
  }
}


export class UpdateBaseStore implements Action {
  readonly type = CustomBaseStoreActionTypes.UPDATE_BASE_STORE;

  constructor(public baseStore: CustomBaseStoreModel, public userId: string) { }
}

export class UpdateBaseStoreSuccess implements Action {
  readonly type = CustomBaseStoreActionTypes.UPDATE_BASE_STORE_SUCCESS;

  constructor(public payload: CustomBaseStoreModel) { }
}

export class UpdateBaseStoreFail implements Action {
  readonly type = CustomBaseStoreActionTypes.UPDATE_BASE_STORE_FAIL;

  constructor(public payload: any) { }
}

export class PurgeBaseStoreList implements Action {
  readonly type = CustomBaseStoreActionTypes.PURGE_BASE_STORE;
}

export type CustomBaseStoreActions =
  | LoadBaseStoreList
  | LoadBaseStoreListFail
  | LoadBaseStoreListSuccess
  | PurgeBaseStoreList
  | UpdateBaseStore
  | UpdateBaseStoreSuccess
  | UpdateBaseStoreFail;

