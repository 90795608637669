<ng-container>
  <h3 class="cx-checkout-title d-none d-lg-block d-xl-block mb-5">
    {{ 'checkoutAddress.shippingAddress' | cxTranslate }}
  </h3>

  <ng-container *ngIf="(customSelectedAddress$ | async) || (customTableData$ | async) as tableData; else loading">
    <ng-container *ngIf="!forceLoader && !(isUpdating$ | async); else loading2">
      <ng-container *ngIf="isAccountPayment || (tableData?.length && !addressFormOpened); else newAddressForm">
        <ng-container *ngIf="friendsList?.length && !isPickupStore">
          <ng-container *ngIf="friendsList">
            <h4>{{ 'checkoutAddress.onBehalfOf.label' | cxTranslate }}</h4>
            <ng-container *ngIf="(onBehalfOfTypes$ | async)?.length">
              <form [formGroup]="onBehalfForm">
                <div class="form-check" *ngFor="let type of onBehalfOfTypes$ | async">
                  <input
                    class="form-check-input"
                    role="radio"
                    type="radio"
                    id="onBehalfOf-{{ type.code }}"
                    aria-checked="true"
                    (change)="onUserTypeChange(type)"
                    [value]="type.code"
                    formControlName="onBehalfOf"
                  />
                  <label class="cx-delivery-label form-check-label form-radio-label" for="onBehalfOf-{{ type.code }}">
                    {{ type.name | cxTranslate }}
                  </label>
                </div>
                <div class="form-group">
                  <ng-container *ngIf="showFriendList">
                    <ng-select
                      [clearable]="false"
                      formControlName="friend"
                      [items]="friendsList"
                      bindLabel="name"
                      bindValue="uid"
                      placeholder="{{ 'checkoutAddress.onBehalfOf.placeholder' | cxTranslate }}"
                      (change)="getOnBehalfOfAddress($event)"
                    ></ng-select>
                  </ng-container>
                </div>
              </form>
            </ng-container>
          </ng-container>
      </ng-container>
    </ng-container>

      <div class="cx-checkout-body row" *ngIf="!addressFormOpened && tableData?.length">
        <ng-container *ngIf="defaultDeliveryAddress$ | async as defaultCartDeliveryAddress">
          <div class="col-12">
            <h4>{{ "checkoutAddress.onBehalfOf.deliveryAddress" | cxTranslate }}</h4>
            <ng-container *ngFor="let defaultDeliveryAddress of defaultCartDeliveryAddress; let i = index">
            <div class="cx-shipping-address-card">
              <ng-container *ngIf="defaultDeliveryAddress?.address?.id">
                <div class="cx-shipping-address-card-inner">
                  <cx-card [border]="true" [fitToContainer]="true" [content]="defaultDeliveryAddress.card"
                           [showAsRadioOption]="true"
                           [hideRadioButton]="true"
                           (sendCard)="changeAddress(defaultDeliveryAddress.address)"></cx-card>
                </div>
              </ng-container>
            </div>
          </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="!isPickupStore && selectedFriendList$ | async as friendAddresses">
          <div class="col-12">
            <h4 class="mt-4"> {{ 'checkoutAddress.onBehalfOf.addressListTitle' | cxTranslate: { friendName: friendAddresses[0].firstName + (friendAddresses[0].lastName ? ' ' + friendAddresses[0].lastName : '') } }}</h4>
            <table class="table table-main">
              <thead class="">
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.alias' | cxTranslate }}</th>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.name' | cxTranslate }}</th>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.address' | cxTranslate }}</th>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.phone' | cxTranslate }}</th>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.deliveryNote' | cxTranslate }}</th>
              <th scope="col"></th>
              </thead>
              <tbody>
              <ng-container *ngFor="let address of friendAddresses">
                <tr class="{{defaultDeliveryAddress?.id === address.id ? 'active' : ''}}">
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.alias' | cxTranslate }}
                    </div>
                    {{ address.companyName }}
                  </td>
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.name' | cxTranslate }}
                    </div>
                    {{ address.firstName }} {{ address.lastName }}
                  </td>
                  <td class="table-main-cell-left">
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.address' | cxTranslate }}
                    </div>
                    {{ address?.formattedAddress ? address.formattedAddress : formatAddressData(address)}}
                  </td>
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.phone' | cxTranslate }}
                    </div>
                    {{ address.phone }}
                  </td>
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.deliveryNote' | cxTranslate }}
                    </div>
                    {{ address.deliveryNoteObservations }}
                  </td>
                  <td class="table-main-actions">
                    <ng-container *ngIf="defaultDeliveryAddress?.id !== address.id; else selected">
                      <button type="button" class="btn btn-icon" (click)="changeAddress(address)">
                        <app-custom-icons class="icon-ticket"></app-custom-icons>
                      </button>
                    </ng-container>
                    <ng-template #selected>
                      <div class="btn btn-icon text-primary">
                        <app-custom-icons [type]="iconTypes.ACTIVE"></app-custom-icons>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>

      <div class="cx-checkout-btns row" *ngIf="!isAccountPayment && !isPickupStore && !addressFormOpened && tableData?.length">
        <ng-container [ngSwitch]="isGuestCheckout">
          <ng-container *ngSwitchCase="true">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <button class="btn btn-block btn-action" (click)="showNewAddressForm()">
                {{ 'checkoutAddress.addNewAddress' | cxTranslate }}
              </button>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <button class="btn btn-block btn-action" (click)="showNewAddressForm()">
                {{ 'checkoutAddress.addNewAddress' | cxTranslate }}
              </button>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <button
                *ngIf="!defaultDeliveryAddress?.length || (onBehalfForm.controls.onBehalfOf.value === 'me' && totalCards > 1) || (onBehalfForm.controls.onBehalfOf.value !== 'me' && onBehalfForm.controls.friend.value)"
                class="btn btn-block btn-action" (click)="toggleAddressList()">
                {{ (showAddressList ? 'checkoutAddress.hideAddressList' : 'checkoutAddress.showAddressList') | cxTranslate }}
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="cx-checkout-body row" *ngIf="!addressFormOpened && tableData?.length">
        <ng-container *ngIf="showAddressList && tableData">
          <div class="cx-shipping-address-card">
            <div *ngIf="addressList?.length > 1" class="custom-list-filter">
              <div class="form-group">
                <label>
                  <span class="label-content">
                    {{ 'addressBook.filter' | cxTranslate }}
                  </span>
                  <input class="form-control"
                    type="text"
                    [formControl]="filter"
                  />
                </label>
                <button
                  class="btn btn-default"
                  (click)="removeFilter()"
                  [disabled]="!filter.value">
                  {{ 'addressBook.filterRemove' | cxTranslate }}
                </button>
              </div>
            </div>
            <table class="table table-main"
              [class.table-scrollable]="tableData?.length > 6"
              *ngIf="(filteredAddresses$ | async)?.length"
            >
              <thead>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.alias' | cxTranslate }}</th>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.name' | cxTranslate }}</th>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.address' | cxTranslate }}</th>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.phone' | cxTranslate }}</th>
              <th scope="col">{{ 'checkoutAddress.onBehalfOf.table.deliveryNote' | cxTranslate }}</th>
              <th scope="col"></th>
              </thead>
              <tbody>
              <ng-container *ngFor="let address of filteredAddresses$ | async">
                <tr class="{{defaultDeliveryAddress?.id === address.id ? 'active' : ''}}">
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.alias' | cxTranslate }}
                    </div>
                    {{ address.companyName }}
                  </td>
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.name' | cxTranslate }}
                    </div>
                    {{ address.firstName }} {{ address.lastName }}
                  </td>
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.address' | cxTranslate }}
                    </div>
                    {{ formatAddressData(address) }}
                  </td>
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.phone' | cxTranslate }}
                    </div>
                    {{ address.phone }}
                  </td>
                  <td>
                    <div class="table-main-label">
                      {{ 'checkoutAddress.onBehalfOf.table.deliveryNote' | cxTranslate }}
                    </div>
                    {{ address.deliveryNoteObservations }}
                  </td>
                  <td class="table-main-actions">
                    <ng-container *ngIf="defaultDeliveryAddress?.id !== address.id; else selected">
                      <button type="button" class="btn btn-icon" (click)="changeAddress(address)">
                        <app-custom-icons class="icon-ticket"></app-custom-icons>
                      </button>
                    </ng-container>
                    <ng-template #selected>
                      <div class="btn btn-icon text-primary">
                        <app-custom-icons [type]="iconTypes.ACTIVE"></app-custom-icons>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
            <div *ngIf="(filteredAddresses$ | async)?.length === 0" class="custom-list-filter-no-order">
              {{ 'checkoutAddress.filterNoAddresses' | cxTranslate }}
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="handleBilling">
        <!-- BILLING -->
        <!-- (MLM), Whole payment section is not hidden for:
                    - Public users
                    - Private user and NO billing address in cart (at this point if is not set in cart, user don't have billing address)
                    - Private user and NO DNI. User must set DNI to continue checkout
        -->
        <!-- BILLING INFO COMPONENT (MLM), removed
        <ng-container *ngIf="billingAddressFromCart$ | async as billingAddress">
          <cx-card
            [border]="true"
            [showAsRadioOption]="true"
            [hideRadioButton]="true"
            [content]="getBillingAddressCardContent(billingAddress) | async"
          >
          </cx-card>
        </ng-container> -->
        <ng-container *ngIf="!(billingAddressFromCart$ | async)?.id
                             || !(user$ | async)?.documentIdentifier">
          <h3 class="cx-checkout-title" *ngIf="!addressFormOpened && tableData?.length">
            {{ "checkoutAddress.billing.title" | cxTranslate }}
          </h3>
          <div class="cx-payment-form-billing cx-checkout-body" *ngIf="!addressFormOpened && tableData?.length">
            <!-- (MLM), Request Billing button. Always hidden for Private Users) -->
            <ng-container *ngIf="!(billingAddressFromCart$ | async)?.id ">
              <div class="form-check">
                <label>
                  <input
                    class="scaled-input form-check-input"
                    type="checkbox"
                    [checked]="requestBilling"
                    (change)="toggleBilling()"
                  />
                  <span class="form-check-label">
                    {{ 'checkoutAddress.billing.label' | cxTranslate }}
                  </span>
                </label>
              </div>
            </ng-container>
            <!-- (MLM), This div contains all billing component. Must be displayed for:
                        - Private users. Since previous toogle billing button not exists, must add this condition
                        - Public users when request billing checkbox is marked
            -->
            <div *ngIf="requestBilling">
              <ng-container *ngIf="!(billingAddressFromCart$ | async)?.id">
                <div class="form-group">
                  <div class="form-check">
                    <label>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        [checked]="sameBillingAsShippingAddress"
                        (change)="toggleSameBillingAsShippingAddress()"
                      />
                      <span class="form-check-label">
                        {{ 'paymentForm.sameAsShippingAddress' | cxTranslate }}
                      </span>
                    </label>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="user$ | async as user">
                <ng-container [ngSwitch]="!!user.documentIdentifier">
                  <ng-container *ngSwitchCase="true">
                    <div class="form-group">
                      <label>
                        <span class="label-content required">
                          {{ 'paymentForm.documentIdentifier' | cxTranslate }}
                        </span>
                        <input
                          class="form-control"
                          type="text"
                          value="{{ user.documentIdentifier}}"
                          disabled
                        />
                      </label>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <div [formGroup]="documentIdentifierForm">
                      <div class="form-group">
                        <label>
                          <span class="label-content">
                            {{ 'paymentForm.documentIdentifier' | cxTranslate }}
                            <span style="color: red;">
                              *
                            </span>
                            <span *ngIf="cart?.totalPrice?.value >= 3000" style="color: red; text-transform: initial;">
                              {{ 'common.validateDocIdentifierAndTotalAmount' | cxTranslate}}
                            </span>
                          </span>
                          <input
                            class="form-control"
                            type="text"
                            formControlName="documentIdentifier"
                            (input)="resetShowMyInformation()"

                          />
                            <cx-form-errors [control]="documentIdentifierForm.get('documentIdentifier')"></cx-form-errors>
                            <p class="custom-error-message" *ngIf="showMyInformation">Nombre y Apellidos no coinciden con su NIF. Modifíquelos en la sección<a routerLink="/my-account/update-profile"> Mi información </a> y haga click en continuar nuevamente.</p>
                            <p class="custom-error-message" *ngIf="showMyInformationEn">Number and Surname do not coincide with your NIF. Changes in the <a routerLink="/my-account/update-profile">My information</a> section and click to continue again.</p>
                          </label>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <!-- BILLING INFO COMPONENT -->
              <ng-container *ngIf="billingAddressFromCart$ | async as billingAddress; else newBillingAddress">

              </ng-container>
              <ng-template #newBillingAddress>
                <div [formGroup]="billingAddressForm" *ngIf="billingAddressLoaded">
                  <div class="form-group">
                    <label>
                      <span class="label-content required">
                        {{ 'addressForm.firstName.label' | cxTranslate }}
                      </span>
                      <input
                        class="form-control"
                        type="text"
                        formControlName="firstName"
                      />
                      <cx-form-errors [control]="billingAddressForm.get('firstName')"></cx-form-errors>
                    </label>
                  </div>
                  <div class="form-group">
                    <label>
                      <span class="label-content required">
                        {{ 'addressForm.lastName.label' | cxTranslate }}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="lastName"
                      />
                      <cx-form-errors [control]="billingAddressForm.get('lastName')"></cx-form-errors>
                    </label>
                  </div>
                  <div class="form-group">
                    <label>
                      <span class="label-content required">
                        {{ 'addressForm.address1' | cxTranslate }}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="line1"
                      />
                      <cx-form-errors [control]="billingAddressForm.get('line1')"></cx-form-errors>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="line2"
                      />
                      <cx-form-errors [control]="billingAddressForm.get('line2')"></cx-form-errors>
                    </label>
                  </div>
                  <div class="form-group" formGroupName="country">
                    <ng-container *ngIf="countries$ | async as countries">
                      <div *ngIf="countries.length !== 0">
                        <label aria-required="true">
                          <span class="label-content required">
                            {{ 'addressForm.country' | cxTranslate }}
                          </span>
                          <ng-select
                            [searchable]="true"
                            [clearable]="false"
                            [items]="countries"
                            bindLabel="name"
                            bindValue="isocode"
                            placeholder="{{ 'addressForm.selectOne' | cxTranslate }}"
                            (change)="countrySelected($event)"
                            formControlName="isocode"
                          >
                          </ng-select>
                          <cx-form-errors [control]="billingAddressForm.get('country.isocode')"></cx-form-errors>
                        </label>
                      </div>
                    </ng-container>
                  </div>
                  <div class="form-group" formGroupName="region">
                    <ng-container *ngIf="regions$ | async as regions">
                      <div *ngIf="regions.length !== 0">
                        <label aria-required="true">
                          <span class="label-content required">
                            {{ "addressForm.state" | cxTranslate }}
                          </span>
                          <ng-select
                            class="region-select"
                            formControlName="isocode"
                            [searchable]="true"
                            [clearable]="false"
                            [items]="regions"
                            bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
                            bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
                            placeholder="{{ 'addressForm.pleaseSelectOne' | cxTranslate }}"
                            (change)="regionSelected($event)"
                          ></ng-select>
                          <cx-form-errors
                            [control]="billingAddressForm.get('region.isocode')"
                          ></cx-form-errors>
                        </label>
                      </div>
                    </ng-container>
                  </div>
                  <div class="form-group">
                    <label>
                      <span class="label-content required">
                        {{ 'addressForm.city.label' | cxTranslate }}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="town"
                      />
                      <cx-form-errors [control]="billingAddressForm.get('town')"></cx-form-errors>
                    </label>
                  </div>
                  <div class="form-group">
                    <label>
                      <span class="label-content required">
                        {{ 'addressForm.zipCode.label' | cxTranslate }}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="postalCode"
                      />
                      <cx-form-errors [control]="billingAddressForm.get('postalCode')"></cx-form-errors>
                    </label>
                  </div>
                  <div class="form-group">
                    <label>
                      <span class="label-content required">
                        {{ 'addressForm.phoneNumber.label' | cxTranslate }}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="phone"
                      />
                      <cx-form-errors
                        [control]="billingAddressForm.get('phone')"
                        [translationParams]="{
                            minLength: billingAddressForm.get('phone')?.errors?.minlength?.requiredLength,
                            maxLength: billingAddressForm.get('phone')?.errors?.maxlengthWithNumber?.requiredLength
                        }">
                      </cx-form-errors>
                    </label>
                  </div>
                  <div class="form-group">
                    <label>
                      <span class="label-content required">
                        {{ 'addressForm.email.label' | cxTranslate }}
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="email"
                      />
                      <cx-form-errors [control]="billingAddressForm.get('email')"></cx-form-errors>
                    </label>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <div class="cx-checkout-btns row" *ngIf="!addressFormOpened && tableData?.length">
          <div class="col-md-12 col-lg-5">
            <button class="cx-btn btn-block btn btn-default" (click)="back()">
              {{ backBtnText | cxTranslate }}
            </button>
          </div>
          <div class="col-md-12 col-lg-4">
            <button class="cx-btn btn-block btn btn-action"
                    [disabled]="!((selectedAddress$ | async)?.id && this.onBehalfForm.get('onBehalfOf').value === 'me' || !!this.selectedFriend?.uid)
                      || !(isStableCart$ | async)
                      || (isValidatingCart$ | async)
                      || (isSimulatingCart$ | async)
                      || disableNextButton"
                    (click)="next()">
              {{ 'common.continue' | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #newAddressForm>
    <app-custom-address-form
      *ngIf="(customTableData$ | async).length && !isGuestCheckout; else initialAddressForm"
      [showTitleCode]="false"
      (backToAddress)="hideNewAddressForm(false)"
      (submitAddress)="newAddress($event)"></app-custom-address-form>
    <ng-template #initialAddressForm>
      <app-custom-address-form
        [showTitleCode]="false"
        [setAsDefaultField]="!isGuestCheckout"
        [addressData]="selectedAddress"
        cancelBtnLabel="{{ (isGuestCheckout ? 'common.back': backBtnText) | cxTranslate }}"
        (backToAddress)="hideNewAddressForm(!isGuestCheckout)"
        (submitAddress)="newAddress($event)"></app-custom-address-form>
    </ng-template>
  </ng-template>
  <!--</ng-container>-->

  <ng-template #loading>
    <div class="cx-spinner" >
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>

  <ng-template #loading2>
    <div class="cx-spinner" >
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>




