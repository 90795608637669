import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpResponseStatus, GlobalMessageType, Priority, GlobalMessageService, AuthService, OccEndpointsService, ForbiddenHandler } from "@spartacus/core";

@Injectable({
  providedIn: 'root',
})
export class CustomForbiddenHandler extends ForbiddenHandler {
  responseStatus = HttpResponseStatus.FORBIDDEN;

  handleError(request: HttpRequest<any>) {
    if (
      request.url.endsWith(
        this.occEndpoints.buildUrl('user', {
          urlParams: { userId: 'current' },
        })
      )
    ) {
      this.authService.logout();
      // only shows message if necessary
      this.globalMessageService.add(
        { key: 'httpHandlers.forbidden' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  getPriority(): Priority {
    return Priority.HIGH;
  }

  constructor(
    protected globalMessageService: GlobalMessageService,
    protected authService: AuthService,
    protected occEndpoints: OccEndpointsService
  ) {
    super(globalMessageService, authService, occEndpoints);
  }
}
