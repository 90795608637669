<div class="row">
    <div class="col-md-4">
    <!--    <input class="form-control" placeholder="{{ 'myFriends.search' | cxTranslate }}" />
        <a> <app-custom-icons [type]="iconTypes.SEARCH"></app-custom-icons></a>-->
    </div>
    <div class="col-md-8">
      <div class="row justify-content-end">
        <div class="col-md-6" *ngIf="showEmailFeature">
          <button
            type="button"
            class="btn btn-action float-right btn-block"
            (click)="openEmailDialog()"
            [attr.title]="!selectedFriendsEmails?.length ? ('myFriends.sendEmailTooltip' | cxTranslate) : null"
            [disabled]="!selectedFriendsEmails?.length"
          >
            {{ 'myFriends.sendEmail' | cxTranslate }}
          </button>
        </div>
        <div class="col-md-6">
          <button type="button" class="btn btn-action float-right btn-block" (click)="addNewFriend()">
            {{ 'myFriends.add' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
</div>
