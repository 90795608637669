import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {CustomProductLabelsComponent} from "./custom-product-labels.component";
import {
  CustomProductLabelsItemsViewComponent
} from "./custom-product-labels-items-view/custom-product-labels-items-view.component";

@NgModule({
  declarations: [
    CustomProductLabelsComponent,
    CustomProductLabelsItemsViewComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductLabelsComponent: {
          component: CustomProductLabelsComponent
        }
      }
    } as CmsConfig)
  ],
  exports: [
    CustomProductLabelsItemsViewComponent
  ]
})
export class CustomProductLabelsModule { }
