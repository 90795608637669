<div
  *ngIf="data$ | async as data"
  class="container"
>
  <div class="content">
    <ng-container *ngIf="components$ | async as components">
      <ng-container *ngFor="let component of components">
        <app-custom-image-info
          [item]="component"
        ></app-custom-image-info>
      </ng-container>
    </ng-container>
  </div>
</div>
