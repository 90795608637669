import {CxEvent} from "@spartacus/core";
import {EcommerceViewItemList} from "./custom-category-page.model";

export class CustomCategoryPageEvent extends CxEvent {
  /**
   * Event's type
   */
  static readonly type = 'CustomCategoryPageEvent';
  event: string;
  ecommerce: EcommerceViewItemList;
}
