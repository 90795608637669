import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CustomFriendEmailsState, FRIEND_EMAIL_FEATURE, StateWithFriendsEmails } from '../custom-friends-email.state';
import { CustomerEmailModel } from '../../CustomerEmail.model';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';


export const guestFriendEmailsFeatureSelector: MemoizedSelector<StateWithFriendsEmails,
  CustomFriendEmailsState> = createFeatureSelector<CustomFriendEmailsState>(FRIEND_EMAIL_FEATURE);

export const getFriendEmailsState: MemoizedSelector<StateWithFriendsEmails, LoaderState<CustomerEmailModel[]>> = createSelector(
  guestFriendEmailsFeatureSelector,
  (state => state.entities),
);

export const getAllSelectedFriendEmailsLoading: MemoizedSelector<StateWithFriendsEmails, boolean> = createSelector(
  getFriendEmailsState,
  (state => state.loading),
);

export const getAllSelectedFriendEmailsSuccess: MemoizedSelector<StateWithFriendsEmails, boolean> = createSelector(
  getFriendEmailsState,
  (state => state.success),
);

export const getAllSelectedFriendEmailsFail: MemoizedSelector<StateWithFriendsEmails, boolean> = createSelector(
  getFriendEmailsState,
  (state => state.error),
);

export const getAllSelectedFriendEmailsValue: MemoizedSelector<StateWithFriendsEmails, CustomerEmailModel[]> = createSelector(
  getFriendEmailsState,
  (state => state.value),
);
