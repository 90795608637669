<h2>{{'myFriends.emails.form.header' | cxTranslate}}</h2>
<form [formGroup]="emailForm">
  <div class="row">
    <div class="col-lg-8">
      <div class="form-group">
        <label>
          <span class="d-md-flex">
            <span class="label-content required mr-2">{{ 'myFriends.emails.form.cc' | cxTranslate }}</span>
            <span class="email-address-container w-100 d-block">
              <ng-container *ngFor="let friendEmail  of friendsList; let i = index">
                <span class="chip">{{friendEmail.name}} - {{friendEmail.email}}
                  <span class="btn-remove" (click)="this.removeEmail(i)">×</span>
                </span>
              </ng-container>
            </span>
            <input type="text" formControlName="toCustomersUid" readonly hidden />
          </span>
          <cx-form-errors [control]="emailForm.get('toCustomersUid')"></cx-form-errors>
        </label>
      </div>

      <div class="form-group">
        <label>
          <span class="d-md-flex">
            <span class="label-content required mr-2">{{ 'myFriends.emails.form.subject' | cxTranslate }}</span>
            <input
              [maxLength]="60"
              class="form-control"
              type="text"
              formControlName="subject"
              [placeholder]="'myFriends.emails.form.subjectPlaceholder' | cxTranslate"
            />
          </span>
          <cx-form-errors [control]="emailForm.get('subject')"></cx-form-errors>
        </label>
      </div>
    </div>
    <div class="col-lg-8 col-md-7">
      <quill-editor
        classes="ql-editor-content"
        placeholder="{{'myFriends.emails.form.placeholder' | cxTranslate}}"
        formControlName="body"
        (onSelectionChanged)="updatePosition($event)"
        (onEditorChanged)="updatePosition($event)"
        (onEditorCreated)="setEditor($event)"
      ></quill-editor>
      <div class="form-group">
        <label>
          <cx-form-errors [control]="emailForm.get('body')"></cx-form-errors>
        </label>
      </div>

      <!-- BUTTON SECTION -->
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <button class="btn btn-block btn-default" (click)="goBack()">
            {{ 'myFriends.goBack' | cxTranslate }}
          </button>
        </div>

        <div class="col-md-12 col-lg-6">
          <button
            class="btn btn-block btn-action"
            type="submit"
            (click)="sendEmails()"
          >
            {{ 'myFriends.emails.form.submit' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-5">
      <div class="form-group">
        <label>
          <span>{{ 'myFriends.emails.form.productSearch' | cxTranslate }}</span>
          <app-custom-products-searchbox (appendProduct)="setAppendProduct($event)"></app-custom-products-searchbox>
        </label>
      </div>
    </div>
  </div>
</form>

