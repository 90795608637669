import { Injectable } from '@angular/core';
import { CanonicalUrlOptions, PageLinkService } from '@spartacus/core';
import { HrefLangMeta } from '../model/custom-page.model';

/**
 * Service to add links to the page meta data, such canonical URLs.
 */
@Injectable({ providedIn: 'root' })
export class CustomPageLinkService extends PageLinkService {

  /**
   * Returns hrefLang alternates for the page.
   *
   * Like the canonical url, hreflang urls are created by the help of the default `CanonicalUrlOptions` from
   * the pageMeta options. The options can be further adjusted by the options argument.
   */
  getHrefLangUrls(options?: CanonicalUrlOptions, urls?: HrefLangMeta[]): HrefLangMeta[] {
    const config: CanonicalUrlOptions = {
      ...this.pageMetaConfig?.pageMeta?.canonicalUrl,
      ...options,
    };
    return this.buildHrefLangUrls(
      urls ?? (this.winRef.location.href ? [{href: this.winRef.location.href, lang: 'x-default'}] : []),
      config
    );
  }

  get actualLocationHref(): string {
    return this.winRef.location.href ?? '';
  }

  protected buildHrefLangUrls(
    hrefLangMetas: HrefLangMeta[],
    options: CanonicalUrlOptions
  ): HrefLangMeta[] {
    hrefLangMetas.forEach(hrefLangMeta => {
      if (options.forceHttps) {
        hrefLangMeta.href = hrefLangMeta.href.replace(/^http(?!s):/i, 'https:');
      }
      if (options.forceWww) {
        // this will not allow for not adding wwww. in case of a subdomain
        hrefLangMeta.href = hrefLangMeta.href.replace(/^(https?:\/\/)(?!www\.)(.*)/i, '$1www.$2');
      }
      if (options.removeQueryParams) {
        hrefLangMeta.href = this.removeQueryParams(hrefLangMeta.href, options);
      }
      if (options.forceTrailingSlash) {
        hrefLangMeta.href = hrefLangMeta.href.replace(/^([^\?]+[^\/\?]$)$/i, '$1/');
      }
    });
    return hrefLangMetas;
  }
}
