import { Component, Input } from '@angular/core';
import { Card } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationService } from '@spartacus/core';
import { EntryGroup } from '../../../../../../../../model/entry-groups.model';
import { ConsignmentStatus } from '../../../../../../../../model/consignments.model';
import { OrderConsignedEntriesComponent } from '@spartacus/order/components';

@Component({
  selector: 'cx-order-consigned-entries',
  templateUrl: './custom-order-consigned-entries.component.html',
})
export class CustomOrderConsignedEntriesComponent extends OrderConsignedEntriesComponent {
  @Input() entryGroups: EntryGroup[];

  constructor(protected translation: TranslationService) {
    super();
  }

  getConsignmentStatus(status: string): Observable<Card> {
    return this.translation.translate('orderDetails.status').pipe(
      map((textTitle: string) => ({
        title: textTitle,
        text: [status],
      })),
    );
  }

  getConsignmentFailure(failure: string): Observable<Card> {
    return this.translation.translate('orderDetails.consignmentTracking.failure').pipe(
      map((textTitle: string) => ({
        title: textTitle,
        text: [failure],
      })),
    );
  }

  getConsignmentDeliveryDate(date: string, statusCode: string): Observable<Card> {
    return this.translation
      .translate((statusCode === ConsignmentStatus.DELIVERY_COMPLETED || statusCode === ConsignmentStatus.PICKUP_COMPLETE) ?
        'orderDetails.consignmentTracking.deliveryDate' :
        'orderDetails.consignmentTracking.estimatedDeliveryDate')
      .pipe(
        map((textTitle) => {
          return {
            title: textTitle,
            text: [date],
          };
        }),
      );
  }

  getConsignmentCode(code: string): Observable<Card> {
    return this.translation
      .translate('orderDetails.consignmentTracking.consignment')
      .pipe(
        map((textTitle) => {
          return {
            title: textTitle,
            text: [code],
          };
        }),
      );
  }

  getConsignmentReference(reference: string): Observable<Card> {
    return this.translation
      .translate('orderDetails.consignmentTracking.reference')
      .pipe(
        map((textTitle) => {
          return {
            title: textTitle,
            text: [reference],
          };
        }),
      );
  }
}
