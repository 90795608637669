import { Observable } from 'rxjs';
import { GiftCard } from '../model/custom-gift-card.model';



export abstract class CustomGiftCardAdapter {

    abstract loadAll(userId: string, cartId: string): Observable<GiftCard[]>;

    abstract load(userId: string, cartId: string): Observable<GiftCard>;

    abstract set(userid: string, cartId: string, code: string,message:string): Observable<GiftCard>;

    //abstract update(userId: string, cartId: string): Observable<{}>;

    abstract delete(userId: string, cartId: string): Observable<{}>;

}
