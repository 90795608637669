import { Injectable } from '@angular/core';
import { Country, CountryType, SiteConnector } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomSiteAdapter } from './custom-site.adapter';

@Injectable({
  providedIn: 'root',
})
export class CustomSiteConnector extends SiteConnector {
  constructor(protected adapter: CustomSiteAdapter) {
    super(adapter);
  }

  getCountriesAll(type?: CountryType): Observable<Country[]> {
    return this.adapter.loadCountriesAll(type);
  }
}
