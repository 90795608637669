<ng-container *ngIf="bills$ | async as bills">
  <div class="container">
    <!-- HEADER -->
    <div>
      <h2 class="title">
        {{ 'bills.title' | cxTranslate }}
      </h2>
    </div>
    <div class="custom-list-filter">
      <div class="form-group">
        <label>
          <span class="label-content">
            {{ 'bills.filterInvoiceNumber' | cxTranslate }}
          </span>
          <input class="form-control"
            type="text"
            [formControl]="filtersForm.get('invoiceCode')" />
        </label>
        <button
          class="btn btn-default"
          (click)="removeFilter(['invoiceCode'], false)"
          [disabled]="!filtersForm.get('invoiceCode').value">
          {{ 'bills.filterRemove' | cxTranslate }}
        </button>
      </div>
      <div class="form-group form-group-range filter-specific">
        <label>
          <span class="label-content">
            {{ 'bills.filterDateType' | cxTranslate }}
          </span>
          <cx-sorting
            [sortOptions]="sortFilterDateTypes"
            [sortLabels]="getDateTypeFilterLabels() | async"
            (sortListEvent)="updateSelectFilter('dateType',$event)"
            [control]="filtersForm.get('dateType')"
            selectedOption="date"
          ></cx-sorting>
        </label>
        <label>
          <span class="label-content">
            {{ 'bills.filterDateFrom' | cxTranslate }}
          </span>
          <cx-date-picker
            [control]="filtersForm.get('startCreationTime')"
            [max]="filtersForm.get('endCreationTime').value"
            (update)="updateDatepickerFilter('endCreationTime')"></cx-date-picker>
        </label>
        <label>
          <span class="label-content">
            {{ 'bills.filterDateTo' | cxTranslate }}
          </span>
          <cx-date-picker
            [control]="filtersForm.get('endCreationTime')"
            [min]="filtersForm.get('startCreationTime').value"
            (update)="updateDatepickerFilter('startCreationTime')"></cx-date-picker>
        </label>
        <button
          class="btn btn-default"
          (click)="removeFilter(['startCreationTime','endCreationTime'])"
          [disabled]="!filtersForm.get('startCreationTime').value &&
          !filtersForm.get('endCreationTime').value">
          {{ 'bills.filterRemove' | cxTranslate }}
        </button>
      </div>
    </div>
    <div class="custom-list-filter-header row">
      <div class="col-xs-12 col-lg-9 order-lg-1">
        <ng-container *ngIf="displayFriends$ | async">     
          <div class="form-check">
            <label>
              <input
                (change)="toggleCheckboxFilter('showFriends')"
                [control]="filtersForm.get('showFriends')"
                class="scaled-input form-check-input"
                type="checkbox"
              />
              <span class="form-check-label">
                {{ 'bills.filterInvoicesFriends' | cxTranslate }}
              </span>
            </label>
          </div>
        </ng-container>
      </div>
      <div class="custom-list-filter-header-sort form-group col-xs-12 col-lg-3">
        <label>
          <span class="label-content">
            {{ 'bills.sortByTitle' | cxTranslate }}
          </span>
          <cx-sorting
            [sortOptions]="bills.sorts"
            [sortLabels]="getSortLabels() | async"
            (sortListEvent)="changeSortCode($event)"
            [selectedOption]="bills.pagination.sort"
          ></cx-sorting>
        </label>
      </div>
    </div>
    <ng-container
          *ngIf="bills.pagination.totalResults > 0; else noBills"
        >
          <!-- TABLE -->
          <table class="table table-main">
            <thead>
              <th scope="col" class="table-main-cell-left">
                {{ 'bills.headerInvoiceCode' | cxTranslate }}
              </th>
              <th>
                {{ 'bills.headerInvoiceUser' | cxTranslate }}
              </th>
              <th scope="col">
                {{ 'bills.headerInvoiceDate' | cxTranslate }}
              </th>
              <th scope="col">{{ 'bills.headerInvoiceDueDate' | cxTranslate }}</th>
              <th scope="col">
                {{ 'bills.headerInvoicePayment' | cxTranslate }}
              </th>
              <th scope="col" class="table-main-cell-right">
                {{ 'bills.headerInvoiceAmount' | cxTranslate }}
              </th>
              <th scope="col">
                {{ 'bills.headerInvoicePending' | cxTranslate }}
              </th>
            </thead>
            <tbody>
              <tr
                *ngFor="let invoice of bills.invoiceList"
              >
                <td class="table-main-cell-left">
                  <div class="table-main-label">
                    {{ 'bills.headerInvoiceCode' | cxTranslate }}
                  </div>
                  <ng-container [ngSwitch]="!!invoice?.document?.downloadUrl">
                    <ng-container *ngSwitchCase="true">              
                      <a [href]="getDownloadUrl(invoice.document.downloadUrl)"
                      class="btn-link title"
                      target="_blank"
                      >
                        {{ invoice?.code }}
                      </a>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ invoice?.code }}
                    </ng-container>
                  </ng-container>
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'bills.headerInvoiceUser' | cxTranslate }}
                  </div>
                  {{ invoice?.user?.name }}
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'bills.headerInvoiceDate' | cxTranslate }}
                  </div>
                  {{ invoice?.date | cxDate: 'd/MM/yyyy' }}
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'bills.headerInvoiceDueDate' | cxTranslate }}
                  </div>
                  {{ invoice?.dueDate | cxDate: 'd/MM/yyyy' }}
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'bills.headerInvoicePayment' | cxTranslate }}
                  </div>
                    {{ invoice?.paymentMode?.name }}
                </td>
                <td class="table-main-cell-right">
                  <div class="table-main-label">
                    {{ 'bills.headerInvoiceAmount' | cxTranslate }}
                  </div>
                    {{ invoice?.totalPrice?.formattedValue }}
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'bills.headerInvoicePending' | cxTranslate }}
                  </div>
                    {{ invoice?.pendingAmount?.formattedValue }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Select Form and Pagination Bottom -->
          <div class="table-main-bottom row">
            <div
              *ngIf="bills.pagination.totalPages > 1"
              class="table-main-pagination"
            >
              <cx-pagination
                [pagination]="bills.pagination"
                (viewPageEvent)="pageChange($event)"
              ></cx-pagination>
            </div>
          </div>
        </ng-container>

        <!-- NO BILLS CONTAINER -->
        <ng-template #noBills>
          <div
            *ngIf="isLoaded$ | async"
            class="custom-list-filter-no-order"
          >
            {{ 'bills.notFound' | cxTranslate }}
          </div>
        </ng-template>
  </div>
</ng-container>
