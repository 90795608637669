import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { CSAgentLoginFormComponent } from '@spartacus/asm/components';

@Component({
  selector: 'cx-csagent-login-form',
  templateUrl: './custom-csagent-login-form.component.html',
  styleUrls: ['./custom-csagent-login-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCSAgentLoginFormComponent extends CSAgentLoginFormComponent {
}
