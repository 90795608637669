import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ProcessSelectors,
  StateWithProcess,
  UserIdService,
  Address
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { CustomMultiCartService } from './custom-multi-cart.service';
import { EntryGroup } from '../../../../../model/entry-groups.model';
import { CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID } from '../store/custom-cart-state';
import { select } from '@ngrx/store';
import { CustomCartActions } from '../store/actions';
import { getCartBillingAddressSelector } from '../store/selectors/custom-multi-cart.selectors';
import { CREATE_BILLING_ADDRESS_PROCESS_ID } from '../../checkout/store/custom-checkout-state';
import { ResetCreateBillingAddressProcess } from '../../checkout/store/actions/custom-checkout.action';
import { User } from '@spartacus/user/account/root';
import { ActiveCartService, StateWithMultiCart, getCartIdByUserId } from '@spartacus/cart/base/core';
import { CustomOrderEntry } from '../../../feature-libs/cart/root/models/cart.model';
import { CartType } from '@spartacus/cart/base/root';

@Injectable({
  providedIn: 'root',
})
export class CustomActiveCartService extends ActiveCartService {

  constructor(
    protected store: Store<StateWithMultiCart>,
    protected multiCartService: CustomMultiCartService,
    protected userIdService: UserIdService,
    protected storeMultiCart: Store<StateWithMultiCart | StateWithProcess<void>>,
  ) {
    super(multiCartService, userIdService);
  }

  /**
   * Add entry to active cart with added unit
   *
   * @param productCode
   * @param quantity
   * @param unitCode
   */
  addEntryUnit(productCode: string, quantity: number, unitCode: string): void {
    this.requireLoadedCart()
      .pipe(withLatestFrom(this.userIdService.getUserId()))
      .subscribe(([cartState, userId]) => {
        this.multiCartService.addEntryUnit(
          getCartIdByUserId(cartState, userId),
          userId,
          productCode,
          quantity,
          unitCode,
        );
      });
  }

  /**
   * Returns last cart entry for provided product code and unit code.
   * Needed to cover processes where multiple entries can share the same product code
   * (e.g. promotions or configurable products)
   *
   * @param productCode
   * @param unitCode
   */
  getLastEntryUnit(productCode: string, unitCode: string): Observable<CustomOrderEntry> {
    return this.multiCartService.getCartIdByType(CartType.ACTIVE).pipe(switchMap((uuid) => {
      return this.multiCartService.getLastEntryUnit(uuid, productCode, unitCode);
    }))
  }

  /**
   * Assign valued delivery note and delivery note observations to cart
   *
   * @param deliveryNoteObservations
   * @param valuedDeliveryNote
   */
  addDeliveryNotes(deliveryNoteObservations: string): void {
    this.addExtraData({ deliveryNoteObservations });
  }

  /**
   * Set document identifier to customer
   *
   * @param user
   */

  setDocumentIdentifier(user: User): Observable<any> {
    return this.getActiveCartId()
    .pipe(
      withLatestFrom(this.userIdService.getUserId()),
      take(1),
      switchMap(([cartId, userId]) => {
        return this.multiCartService.assignDocumentIdentifier(cartId, userId, user);
      })
    );
  }

  /**
   * Assign extra data to cart
   *
   * @param extraData
   */
  addExtraData(extraData: {}): void {
    this.getActiveCartId()
      .pipe(withLatestFrom(this.userIdService.getUserId()), take(1))
      .subscribe(([cartId, userId]) => {
        this.multiCartService.assignExtraData(cartId, userId, extraData);
      });
  }

  /**
   * Returns cart group entries
   */
  getOrderEntryGroups(): Observable<EntryGroup[]> {
    return this.getActiveCartId().pipe(
      switchMap((cartId) => this.multiCartService.getEntryGroups(cartId)),
      distinctUntilChanged(),
    );
  }

  /**
   * Add giftbox to cart
   *
   * @param giftBoxProductCode
   */
  addGiftbox(giftBoxProductCode: string): void {
    this.requireLoadedCart()
      .pipe(withLatestFrom(this.userIdService.getUserId()))
      .subscribe(([cartState, userId]) => {
        this.multiCartService.addGiftbox(
          userId,
          getCartIdByUserId(cartState, userId),
          giftBoxProductCode,
        );
      });
  }

  /**
   * Returns the Add Gift Box To Cart loading flag
   */
  getAddGiftboxToCartLoading(): Observable<boolean> {
    return (this.storeMultiCart as Store<StateWithProcess<void>>).pipe(
      select(ProcessSelectors.getProcessLoadingFactory(CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID))
    );
  }

  /**
   * Returns the Add Gift Box To Cart success flag
   */
  getAddGiftboxToCartSuccess(): Observable<boolean> {
    return (this.storeMultiCart as Store<StateWithProcess<void>>).pipe(
      select(ProcessSelectors.getProcessSuccessFactory(CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID))
    );
  }

  /**
   * Returns the Add Gift Box To Cart state
   */
  getAddGiftboxToCartState(): Observable<any> {
    return (this.storeMultiCart as Store<StateWithProcess<void>>).pipe(
      select(ProcessSelectors.getProcessStateFactory(CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID))
    );
  }

  /**
   * Returns the Add Gift Box To Cart error flag
   */
  getAddGiftboxToCartError(): Observable<boolean> {
    return (this.storeMultiCart as Store<StateWithProcess<void>>).pipe(
      select(ProcessSelectors.getProcessErrorFactory(CUSTOM_ADD_GIFTBOX_TO_CART_PROCESS_ID))
    );
  }

  /**
   * Clears the process state of performing a saved cart
   */
  clearAddGiftboxToCart(): void {
    this.store.dispatch(new CustomCartActions.ClearAddGiftboxToCart());
  }

  /**
   * Returns the Billing Address from cart
   */
  getBillingAddressFromCart(): Observable<Address> {
    let cartId;
    this.getActiveCartId()
      .subscribe((activeCartId) => (cartId = activeCartId))
      .unsubscribe();
    return this.store.pipe(select(getCartBillingAddressSelector(cartId)));
  }

  /**
   * Returns the Create Billing Address Success flag
   */
  getCreateBillingAddressSuccess(): Observable<boolean> {
    return (this.storeMultiCart as Store<StateWithProcess<void>>).pipe(
      select(ProcessSelectors.getProcessSuccessFactory(CREATE_BILLING_ADDRESS_PROCESS_ID)),
    );
  }

  /**
   * Clear the Create Billing Address Success flag
   */
  clearCreateBillingAddressProcess(): void {
    this.storeMultiCart.dispatch(new ResetCreateBillingAddressProcess());
  }

  validateDocIdentifierAndTotalAmount(user: User): Observable<any> {
    return this.getActiveCartId()
    .pipe(
      withLatestFrom(this.userIdService.getUserId()),
      take(1),
      switchMap(([cartId, userId]) => {
        return this.multiCartService.validateDocIdentifierAndTotalAmount(cartId, userId, user);
      })
    );
  }
}
