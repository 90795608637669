import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CategoryNavigationComponent, CmsComponentData, NavigationNode } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { CmsNavigationComponent } from '@spartacus/core';
import { CustomNavigationService } from '../navigation/custom-navigation.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cx-category-navigation',
  templateUrl: './category-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCategoryNavigationComponent extends CategoryNavigationComponent {

  component$: Observable<NavigationNode> = this.service.getNavigationNode(
    this.componentData.data$,
  );
  node$: Observable<any>;

  constructor(
    componentData: CmsComponentData<CmsNavigationComponent>,
    service: CustomNavigationService,
  ) {
    super(componentData, service);
    const list = { children: [] };
    this.component$.pipe(take(1))
    .subscribe((categoryMenuParent: any) => {
      categoryMenuParent.children.map((categoryMenuItem: any) => {
        const arrayGroup = [];
        let maxRows = 1;
        if (categoryMenuItem?.children?.length) {
          let column = [];
          let groups = [];
          categoryMenuItem.children.map((item, childIndex) => {
            if (item?.addWithPrevious && childIndex > 0) {
              groups = [...groups, item];
            } else {
              column = [...column, childIndex];
              groups = [item];
            }
            arrayGroup[(column.length - 1)] = groups;
            categoryMenuItem.group = arrayGroup.map(group => {
                maxRows = maxRows < group.length ? group.length : maxRows;
                categoryMenuItem.maxRows = maxRows;
                return {
                  children: group,
                  maxRows,
                };
              },
            );
          });
        }
        list.children = [...list.children, categoryMenuItem];
      });
      this.node$ = of(list);
    });
  }
}
