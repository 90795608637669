import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';

export enum CustomOrderActionTypes {
  ORDER_CANCELLATION = '[Order Cancellation] Load Order Cancellation',
  ORDER_CANCELLATION_SUCCESS = '[Order Cancellation] Load Order Cancellation Success',
  ORDER_CANCELLATION_FAIL = '[Order Cancellation] Load Order Cancellation Fail',
  ORDER_ACTIONS_RESET = '[Order Actions] Order Actions Reset',

  ORDER_RESTORATION = '[Order Restoration] Load Order Restoration',
  ORDER_RESTORATION_SUCCESS = '[Order Restoration] Load Order Restoration Success',
  ORDER_RESTORATION_FAIL = '[Order Restoration] Load Order Restoration Fail',

  ORDER_RESTORE_ONLY = '[Order Restoration] Load Order Restore Only',
  ORDER_RESTORE_ONLY_SUCCESS = '[Order Restoration] Load Order Restore Only Success',
  ORDER_RESTORE_ONLY_FAIL = '[Order Restoration] Load Order Restore Only Fail',

  ORDER_ACTION_REDIRECT = '[Order Actions Redirect] Load Order Actions Redirect',
}

export const ORDER_ACTIONS_ID = 'orderActions';

export class OrderCancellation extends StateUtils.EntityLoadAction{
  readonly type = CustomOrderActionTypes.ORDER_CANCELLATION;

  constructor(
    public payload: { userId: string, orderCode: string },
  ) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID);
  }
}

export class OrderCancellationFail  extends StateUtils.EntityFailAction{
  readonly type = CustomOrderActionTypes.ORDER_CANCELLATION_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID, payload);
  }
}

export class OrderCancellationSuccess extends StateUtils.EntitySuccessAction{
  readonly type = CustomOrderActionTypes.ORDER_CANCELLATION_SUCCESS;

  constructor(public url: string) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID, url);
  }
}

export class OrderRestoration extends StateUtils.EntityLoadAction{
  readonly type = CustomOrderActionTypes.ORDER_RESTORATION;

  constructor(
    public payload: { userId: string, orderCode: string },
  ) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID);
  }
}

export class OrderRestorationFail extends StateUtils.EntityFailAction{
  readonly type = CustomOrderActionTypes.ORDER_RESTORATION_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID, payload);
  }
}

export class OrderRestorationSuccess extends StateUtils.EntitySuccessAction{
  readonly type = CustomOrderActionTypes.ORDER_RESTORATION_SUCCESS;

  constructor(public url: string) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID, url);
  }
}

export class OrderRestoreOnly extends StateUtils.EntityLoadAction{
  readonly type = CustomOrderActionTypes.ORDER_RESTORE_ONLY;

  constructor(
    public payload: { userId: string, orderCode: string },
  ) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID);
  }
}

export class OrderRestoreOnlyFail extends StateUtils.EntityFailAction{
  readonly type = CustomOrderActionTypes.ORDER_RESTORE_ONLY_FAIL;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID, payload);
  }
}

export class OrderRestoreOnlySuccess extends StateUtils.EntitySuccessAction{
  readonly type = CustomOrderActionTypes.ORDER_RESTORE_ONLY_SUCCESS;

  constructor(public url: string) {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID, url);
  }
}

export class OrderActionsReset extends StateUtils.EntityLoaderResetAction {
  readonly type = CustomOrderActionTypes.ORDER_ACTIONS_RESET;
  constructor() {
    super(PROCESS_FEATURE, ORDER_ACTIONS_ID);
  }
}

// action types
export type CustomOrderCancellationActions =
  OrderCancellation
  | OrderCancellationFail
  | OrderCancellationSuccess
  | OrderActionsReset
  | OrderRestoration
  | OrderRestorationFail
  | OrderRestorationSuccess
  | OrderRestoreOnly
  | OrderRestoreOnlyFail
  | OrderRestoreOnlySuccess;
