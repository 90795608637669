import { LayoutConfig } from '@spartacus/storefront';
import { CustomAsmMainUiComponent } from './asm-main-ui/custom-asm-main-ui.component';

export const defaultAsmLayoutConfig: LayoutConfig = {
  launch: {
    ASM: {
      outlet: 'cx-storefront',
      component: CustomAsmMainUiComponent,
    },
  },
};
