import { StatisticsActions, StatisticsActionType, StatisticsProductActions } from '../actions/custom-statistics-actions';
import { StateStatistics, Statistics, StatisticsProduct } from '../../model/custom-statistics-model';
import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';

export const initialState: Statistics[] = [];
export const initialStateProducts: StatisticsProduct[] = [];

export function customStatisticsReducer(state = initialState, action: StatisticsActions): Statistics[] {
  switch (action.type) {
    case StatisticsActionType.LOAD_STATISTICS_FAIL: {
      return initialState;
    }
    case StatisticsActionType.LOAD_STATISTICS_SUCCESS: {
      return action.payload ? action.payload : initialState;
    }
  }
  return state;
}

export function customStatisticsProductsReducer(state = initialStateProducts, action: StatisticsProductActions): StatisticsProduct[] {
  switch (action.type) {
    case StatisticsActionType.LOAD_STATISTICS_PRODUCTS_FAIL: {
      return initialStateProducts;
    }

    case StatisticsActionType.LOAD_STATISTICS_PRODUCTS_SUCCESS: {
      return action.payload ? action.payload : initialStateProducts;
    }
  }
  return state;
}

export function getReducers(): ActionReducerMap<StateStatistics> {
  return {
    products: StateUtils.loaderReducer<StatisticsProduct[]>(StatisticsActionType.STATISTICS, customStatisticsProductsReducer),
    statistics: StateUtils.loaderReducer<Statistics[]>(StatisticsActionType.STATISTICS, customStatisticsReducer),
  };
}

export const reducerTokenStatistics:
  InjectionToken<ActionReducerMap<StateStatistics>> =
  new InjectionToken<ActionReducerMap<StateStatistics>>('StatisticsReducers');

export const reducerProviderStatistics: Provider = {
  provide: reducerTokenStatistics,
  useFactory: getReducers,
};
