import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Promotion } from '@spartacus/core';
import { PromotionsModule } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-promotions',
  templateUrl: './promotions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPromotionsComponent extends PromotionsModule {
  @Input()
  promotions: Promotion[];
  
  /** This mock is to test some promotions bugs */
  /*
  mockPromotions: Promotion[] = [
    {
    description: 'string',
    title: 'string'
    },
    {
      description: ' ',
      title: 'string'
    },
    {
      description: '',
      title: ''
    },
    {
      description: 'string',
      title: 'string '
    },
  ]
  */
 
  constructor() {
    super();
  }

  shouldHideDescription(promotionDescription: string): boolean {
    return !promotionDescription || promotionDescription.trim() === '';
  }
}
