import { ModuleWithProviders, NgModule } from '@angular/core';
import { CustomCreditCardStoreModule } from './custom-credit-card-store.module';

@NgModule({
  imports: [CustomCreditCardStoreModule],
})
export class CustomCreditCardModule {
  static forRoot(): ModuleWithProviders<CustomCreditCardStoreModule> {
    return {
      ngModule: CustomCreditCardStoreModule,
    };
  }
}

