import { Injectable } from '@angular/core';
import { EventService, ProductSearchPage, RoutingService, SearchboxService, TranslationService, WindowRef } from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SearchBoxConfig, SearchBoxProductSelectedEvent, SearchBoxSuggestionSelectedEvent, SearchResults } from '@spartacus/storefront';
import { Store } from '@ngrx/store';
import { CustomProductsSearchBoxFacets } from './custom-products-search-box-facets.enum';

const HAS_SEARCH_RESULT_CLASS = 'has-searchbox-results';

@Injectable({
  providedIn: 'root',
})
export class CustomProductsSearchBoxComponentService {
  constructor(
    searchService: SearchboxService,
    routingService: RoutingService,
    translationService: TranslationService,
    winRef: WindowRef,
  );
  /**
   * @deprecated since version 3.1
   * Use constructor(searchService: SearchboxService, routingService: RoutingService, translationService:
   * TranslationService, winRef: WindowRef, eventService?: EventService) instead
   */
  // TODO(#10988): Remove deprecated constructors
  constructor(
    searchService: SearchboxService,
    routingService: RoutingService,
    translationService: TranslationService,
    winRef: WindowRef,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    eventService?: EventService,
  );
  constructor(
    public searchService: SearchboxService,
    protected routingService: RoutingService,
    protected translationService: TranslationService,
    protected winRef: WindowRef,
    protected eventService?: EventService,
    protected store?: Store,
    protected searchboxService?: SearchboxService,
  ) {
  }

  /**
   * Executes the search for products and suggestions,
   * unless the configuration is setup to not search for
   * products or suggestions.
   */
  search(query: string, config: SearchBoxConfig): void {
    if (!query || query === '') {
      this.clearResults();
      return;
    }

    if (
      config.minCharactersBeforeRequest &&
      query.length < config.minCharactersBeforeRequest
    ) {
      return;
    }

    if (config.displayProducts) {
      this.searchService.search(query, {
        pageSize: config.maxProducts,
      });
    }

    if (config.displaySuggestions) {
      this.searchService.searchSuggestions(query, {
        pageSize: config.maxSuggestions,
      });
    }
  }

  /**
   * Returns an observable with the SearchResults. When there's any
   * result, the body tag will get a classname, so that specific style
   * rules can be applied.
   */
  getResults(config: SearchBoxConfig): Observable<SearchResults> {
    return combineLatest([
      this.getProductResults(config),
      this.getProductSuggestions(config),
      this.getSearchMessage(config),
      this.searchboxService.getResults(),
    ]).pipe(
      map(([productResults, suggestions, message, searchBoxResults]) => {
        let facets = [];
        if (searchBoxResults?.facets?.length) {
          Object.values(CustomProductsSearchBoxFacets).forEach(facetIndex => {
              const facetFound = searchBoxResults.facets.filter(facet => facet?.code.toLowerCase() === facetIndex);
              if (facetFound?.length) {
                facets = [...facets, facetFound[0]];
              }
            },
          );
        }
        return {
          products: productResults ? productResults.products : null,
          facets,
          message,
        };
      }),
      tap((results: SearchResults) => {
        return results;
      }),
    );
  }

  /**
   * Clears the searchbox results, so that old values are
   * no longer emited upon next search.
   */
  clearResults(): void {
    this.searchService.clearResults();
    // this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, false);
  }

  hasBodyClass(className: string): boolean {
    return this.winRef.document.body.classList.contains(className);
  }

  /**
   * Dispatches a searchbox event for product selected
   *
   * @param eventData data for the "SearchBoxProductSelectedEvent"
   */
  dispatchProductSelectedEvent(eventData: SearchBoxProductSelectedEvent): void {
    // TODO(#10988): for 4.0 remove "eventService" check
    if (this.eventService) {
      this.eventService.dispatch<SearchBoxProductSelectedEvent>(
        {
          freeText: eventData.freeText,
          productCode: eventData.productCode,
        },
        SearchBoxProductSelectedEvent,
      );
    }
  }

  /**
   * Dispatches a searchbox event for suggestion selected
   *
   * @param eventData data for the "SearchBoxSuggestionSelectedEvent"
   */
  dispatchSuggestionSelectedEvent(
    eventData: SearchBoxSuggestionSelectedEvent,
  ): void {
    // TODO(#10988): for 4.0 remove "eventService" check
    if (this.eventService) {
      this.eventService.dispatch<SearchBoxSuggestionSelectedEvent>(
        {
          freeText: eventData.freeText,
          selectedSuggestion: eventData.selectedSuggestion,
          searchSuggestions: eventData.searchSuggestions,
        },
        SearchBoxSuggestionSelectedEvent,
      );
    }
  }

  /**
   * Emits product search results in case when the config property `displayProducts` is true.
   * Otherwise it emits an empty object.
   */
  protected getProductResults(
    config: SearchBoxConfig,
  ): Observable<ProductSearchPage> {
    if (config.displayProducts) {
      return this.searchService.getResults();
    } else {
      return of({});
    }
  }

  /**
   * Loads suggestions from the backend. In case there's no suggestion
   * available, we try to get an exact match suggestion.
   */
  protected getProductSuggestions(
    config: SearchBoxConfig,
  ): Observable<string[]> {
    if (!config.displaySuggestions) {
      return of([]);
    } else {
      return this.searchService.getSuggestionResults().pipe(
        map((res) => res.map((suggestion) => suggestion.value)),
        switchMap((suggestions) => {
          if (suggestions.length === 0) {
            return this.getExactSuggestion(config).pipe(
              map((match) => (match ? [match] : [])),
            );
          } else {
            return of(suggestions);
          }
        }),
      );
    }
  }

  /**
   * Whenever there is at least 1 product, we simulate
   * a suggestion to provide easy access to the search result page
   */
  protected getExactSuggestion(config: SearchBoxConfig): Observable<string> {
    return this.getProductResults(config).pipe(
      switchMap((productResult) => {
        return productResult.products && productResult.products.length > 0
          ? this.fetchTranslation('searchBox.help.exactMatch', {
            term: productResult.freeTextSearch,
          })
          : of(null);
      }),
    );
  }

  /**
   * Emits a 'no match' message, in case the product search results and search suggestions are empty.
   * Otherwise it emits null.
   */
  protected getSearchMessage(config: SearchBoxConfig): Observable<string> {
    return combineLatest([
      this.getProductResults(config),
      this.getProductSuggestions(config),
    ]).pipe(
      switchMap(([productResult, suggestions]) => {
        if (
          productResult &&
          productResult.products &&
          productResult.products.length === 0 &&
          suggestions &&
          suggestions.length === 0
        ) {
          return this.fetchTranslation('searchBox.help.noMatch');
        } else {
          return of(null);
        }
      }),
    );
  }

  appendSearchQuery(query: string): string {
    return this.routingService.getFullUrl({
      cxRoute: 'search',
      params: { query },
    });
  }

  appendProductQuery(query: string): string {
    return this.routingService.getFullUrl({
      cxRoute: 'product',
      params: query,
    });
  }

  private fetchTranslation(
    translationKey: string,
    options?: any,
  ): Observable<string> {
    return this.translationService.translate(translationKey, options);
  }
}
