<form *ngIf="product?.unitPrices?.length"
      [formGroup]="addToCartForm"
      (submit)="addToCart(!displayDefaultUnitOnly)"
      [class.nostock]="outOfStock"
>
    <app-custom-unit-selector
      (selectionChanged)="selectUnitPrice($event)"
      [unitPrices]="productUnitPrices"
      [selectedUnitPrice]="selectedProductUnit"
      [showNostockText]="showNostockText">
    </app-custom-unit-selector>

    <div class="quantity" *ngIf="showQuantity">
        <cx-item-counter
        [max]="maxQuantity"
        [control]="addToCartForm.get('quantity')"
        ></cx-item-counter>
    </div>

    <button
        class="btn btn-primary btn-block btn-add-to-cart"
        type="submit"
        [disabled]="outOfStock"
    >
      <ng-container [ngSwitch]="displayDefaultUnitOnly">
        <span *ngSwitchCase="true">
          {{ 'addToCart.unitSelect' | cxTranslate }}
        </span>
        <span *ngSwitchDefault>
          <span class="btn-add-to-cart-text" *ngIf="showAddToCartText">
            {{ 'addToCart.addToCart' | cxTranslate }}
          </span>
          <i class="icon-cart1"></i>
        </span>
      </ng-container>
    </button>
</form>
