export enum ConsignmentStatus {
  CREATED = 'CREATED',
  WAITING = 'WAITING',
  PICKPACK = 'PICKPACK',
  READY = 'READY',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERING = 'DELIVERING',
  DELIVERY_NOT_CONFIRMED = 'DELIVERY_NOT_CONFIRMED',
  DELIVERY_PARTIAL = 'DELIVERY_PARTIAL',
  DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',
  PICKUP_COMPLETE = 'PICKUP_COMPLETE',
  DELIVERY_REJECTED = 'DELIVERY_REJECTED',
  CANCELLED = 'CANCELLED',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
}

