import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ForgotPasswordComponent } from '@spartacus/user/profile/components';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';

@Component({
  selector: 'app-custom-forgotten-password',
  templateUrl: './custom-forgotten-password.component.html',
  styleUrls: ['./custom-forgotten-password.component.scss']
})
export class CustomForgottenPasswordComponent extends ForgotPasswordComponent{

  constructor(
    protected service: ForgotPasswordComponentService
  ) {
    super(service)
  }
  form: FormGroup = this.service.form;
  isUpdating$ = this.service.isUpdating$;

  onSubmit(): void {
    this.service.requestEmail();
  }

}
