import { StateUtils } from '@spartacus/core';

export const CREDIT_CARD_FEATURE = 'creditCartList';
export const CREDIT_CARD_LIST = '[Credit Card List] Credit Card List';

export interface Payments {
  payments: Array<CreditCard>;
}

export interface CreditCard {
  accountHolderName: string;
  cardNumber: string;
  cardType: {
    code: string;
    name: string;
  };
  defaultPayment: boolean;
  expiryMonth: string;
  expiryYear: string;
  id: string;
  saved: boolean;
  subscriptionId: string;
}

export interface StateWithCreditCards {
  [CREDIT_CARD_FEATURE]: CustomCreditCardState;
}

export interface CustomCreditCardState {
  entities: StateUtils.LoaderState<Payments>;
  saveCreditCard: boolean;
  selected: CreditCard;
}

export enum StorageSaveCreditCard {
  SAVE_CREDIT_CARD = 'saveCreditCard'
}

