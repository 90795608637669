import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { OrderReturnRequestService } from '@spartacus/order/core';
import { Observable } from 'rxjs';
import { CustomUsersSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderReturnRequestService extends OrderReturnRequestService {
  /**
   * Get the order return request error flag
   */
  getReturnRequestError(): Observable<boolean> {
    return this.store.pipe(select(CustomUsersSelectors.getCustomOrderReturnRequestError));
  }
}
