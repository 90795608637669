import { StateUtils } from '@spartacus/core';
import { CustomNewsListModel, NewsFilter } from '../custom-news.model';

export const NEWS_LIST = '[News] News List';
export const LOAD_NEWS_LIST = '[News] Load News';
export const LOAD_NEWS_LIST_SUCCESS = '[News] Load News Success';
export const LOAD_NEWS_LIST_FAIL = '[News] Load News Fail';

export class LoadNewsList extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_NEWS_LIST;

  constructor(public payload: {
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    newsFilter?: NewsFilter
  }) {
    super(NEWS_LIST);
  }
}

export class LoadNewsListSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_NEWS_LIST_SUCCESS;

  constructor(public payload: CustomNewsListModel) {
    super(NEWS_LIST);
  }
}

export class LoadNewsListFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_NEWS_LIST_FAIL;

  constructor(public payload: any) {
    super(NEWS_LIST, payload);
  }
}

export type CustomNewsListActions =
  | LoadNewsList
  | LoadNewsListFail
  | LoadNewsListSuccess;
