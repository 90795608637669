import { CustomBaseStoreState } from '../custom-base-store.state';
import { CustomBaseStoreActions, CustomBaseStoreActionTypes } from '../actions/custom-base-store.actions';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { AuthActions } from '@spartacus/core';

export const initialState: CustomBaseStoreState = {
  baseStoreList: [],
  selected: null
};

export function customBaseStoreReducer(
  state = initialState,
  action: CustomBaseStoreActions
): CustomBaseStoreState {

  switch (action.type) {

    case CustomBaseStoreActionTypes.LOAD_BASE_STORE_LIST_SUCCESS: {
      return {
        ...state,
        baseStoreList: action.payload ? action.payload : initialState.baseStoreList
      };
    }

    case CustomBaseStoreActionTypes.UPDATE_BASE_STORE_SUCCESS: {
      return {
        ...state,
        selected: action.payload ? action.payload : initialState.selected
      };
    }

    case CustomBaseStoreActionTypes.UPDATE_BASE_STORE_FAIL: {
      return {
        ...state,
        selected: initialState.selected
      };
    }

    case CustomBaseStoreActionTypes.PURGE_BASE_STORE: {
      return initialState;
    }
  }
  return state;
}

export const reducerToken: InjectionToken<CustomBaseStoreState> = new InjectionToken<CustomBaseStoreState>('BaseStoreReducers');

export const customBaseStoreReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: customBaseStoreReducer,
};

export function clearCustomBaseStoreState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
      state = [];
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearCustomBaseStoreState];
