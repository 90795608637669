import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomBillHistoryComponent } from './custom-bill-history.component';
import { CmsConfig, ConfigModule, I18nModule, StateModule, UrlModule } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePickerModule, IconModule, ListNavigationModule } from '@spartacus/storefront';
import { CustomIconsModule } from 'src/app/spartacus/custom/cms-components/misc/icon/custom-icons.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CustomUserBillsEffect } from '../store/effects/custom-user-bills.effect';
import { BILLS_FEATURE } from '../store/selector/custom-user-bills.selectors';
import { reducerBillsHistoryProvider, reducerBillsToken } from '../store/reducer/custom-user-bills.reducer';
import { CustomUserBillAdapter } from '../connectors/custom-user-bill.adapter';
import { CustomOccUserBillAdapter } from '../connectors/custom-occ-user-bill.adapter';

@NgModule({
  declarations: [CustomBillHistoryComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountBillHistoryComponent: {
          component: CustomBillHistoryComponent,
        },
      },
    } as CmsConfig),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    IconModule,
    CustomIconsModule,
    DatePickerModule,
    StateModule,
    StoreModule.forFeature(BILLS_FEATURE, reducerBillsToken),
    EffectsModule.forFeature([CustomUserBillsEffect]),
  ],
  exports: [CustomBillHistoryComponent],
  providers: [
    reducerBillsHistoryProvider,
    {
      provide: CustomUserBillAdapter,
      useClass: CustomOccUserBillAdapter,
    },
  ],
})
export class CustomBillHistoryModule { }
