<div class="cx-dialog-header modal-header">
  <div class="cx-dialog-title modal-title">
    {{title}}
  </div>
  <ng-container *ngIf="showButtonClose">
    <button type="button" class="close" aria-label="Close" cxModal="dismiss" cxModalReason="Cross click" (click)="dismissModal('')">
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
    </button>
  </ng-container>
</div>

<!-- Modal Body -->
<div class="cx-dialog-body modal-body">
  <div class="cx-dialog-row">
    <p [innerHTML]="content | htmlSanitize: 'html'"></p>
  </div>
</div>

<!-- Actions -->
<div class="cx-dialog-buttons align-items-center justify-content-center">
  <ng-container *ngIf="showButtonClose">
    <div class="cx-dialog-actions col-sm-3">
      <button class="btn btn-outline-primary btn-block" (click)="dismissModal('')">
        {{ "common.close" | cxTranslate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="showButtonCancel">
    <div class="cx-dialog-actions col-sm-3">
      <button class="btn btn-secondary btn-block" (click)="dismissModal('')">
        {{ "common.cancel" | cxTranslate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="showButtonAccept">
    <div class="cx-dialog-actions col-sm-3">
      <button class="btn btn-primary btn-block" (click)="dismissModal('')">
        {{ "common.accept" | cxTranslate }}
      </button>
    </div>
  </ng-container>
</div>
