import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  IconModule,
  ItemCounterModule,
  MediaModule,
  OutletModule,
  PromotionsModule,
} from '@spartacus/storefront';
import { CartCouponModule, CartItemContextSource } from '@spartacus/cart/base/components';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CmsConfig,
  ConfigModule,
  FeaturesConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomCartItemListComponent } from './custom-cart-item-list/custom-cart-item-list.component';
import { CustomCartItemComponent } from './custom-cart-item/custom-cart-item.component';
import { CustomUnitDisplayModule } from 'src/app/custom/cms-components/content/custom-unit-display/custom-unit-display.module';
import { CustomOrderSummaryComponent } from './custom-order-summary/custom-order-summary.component';
import { CustomPromotionsModule } from '../../product/promotions/promotions.module';

@NgModule({
  declarations: [CustomCartItemListComponent, CustomCartItemComponent, CustomOrderSummaryComponent],
  imports: [
    CommonModule,
    RouterModule,
    CartCouponModule,
    ReactiveFormsModule,
    UrlModule,
    NgbModule,
    PromotionsModule,
    I18nModule,
    IconModule,
    MediaModule,
    ItemCounterModule,
    FeaturesConfigModule,
    OutletModule,
    CustomUnitDisplayModule,
    CustomPromotionsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CartItemListComponent: {
          component: CustomCartItemListComponent,
        },
        CartItemComponent: {
          component: CustomCartItemComponent,
        },
      },
    } as CmsConfig),
  ],
  providers: [
    CartItemContextSource
  ],
  exports: [CustomCartItemListComponent, CustomCartItemComponent, CustomOrderSummaryComponent],
  entryComponents: [CustomCartItemListComponent, CustomCartItemComponent, CustomOrderSummaryComponent],
})
export class CustomCartSharedModule {}
