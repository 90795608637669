import { CustomNewsListModel, CustomNewsSliderModel } from '../custom-news.model';
import {
  CustomNewsSliderActions,
  LOAD_NEWS_SLIDER_FAIL,
  LOAD_NEWS_SLIDER_SUCCESS,
} from '../actions/custom-news-slider.actions';

export const initialState: CustomNewsListModel = {
  newsList: [],
  pagination: {},
  sorts: [],
};

export function reducerNewsSlider(
  state = initialState,
  action: CustomNewsSliderActions,
): CustomNewsListModel {
  switch (action.type) {
    case LOAD_NEWS_SLIDER_SUCCESS: {
      return action.payload;
    }
    case LOAD_NEWS_SLIDER_FAIL: {
      return initialState;
    }
  }
  return state;
}


