import { Input, OnDestroy, Component, OnInit, HostBinding } from '@angular/core';
import { UserIdService, Product, RoutingService, EventService } from '@spartacus/core';
import { CartEvent } from '@spartacus/cart/base/root'
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomMultiCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-multi-cart.service';
import { CustomDeleteCartFailEvent, CustomDeleteCartSuccessEvent } from 'src/app/spartacus/features/tracking/custom-events/cart/custom-cart.events';
import { CustomAddGiftboxToCartDialogComponent } from '../custom-add-giftbox-to-cart-dialog/custom-add-giftbox-to-cart-dialog.component';
import { CustomGiftBoxService } from '../services/custom-giftbox.service';
import {LaunchDialogService} from "@spartacus/storefront";

@Component({
  selector: 'app-custom-giftbox-item',
  templateUrl: './custom-giftbox-item.component.html',
})
export class CustomGiftboxItemComponent implements OnInit, OnDestroy {
  @HostBinding('class') styleClasses = '';
  private subscription = new Subscription();
  @Input() item: Product;
  hasStock: boolean;

  constructor(
    protected giftboxService: CustomGiftBoxService,
    protected activeCartService: CustomActiveCartService,
    private routingService: RoutingService,
    protected launchDialogService: LaunchDialogService,
    protected eventService: EventService,
    protected userIdService?: UserIdService,
    protected multiCartService?: CustomMultiCartService,
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.activeCartService.getAddGiftboxToCartSuccess().subscribe((addGiftboxToCartSuccess) => {
        if (addGiftboxToCartSuccess) {
          /* this.placedOrder = this.launchDialogService.launch(
            LAUNCH_CALLER.PLACE_ORDER_SPINNER,
            this.vcr,
          ); */
          this.activeCartService.clearAddGiftboxToCart();
          this.subscription.add(
            this.eventService.get(CartEvent)
              .subscribe((event) => {
                if (event instanceof CustomDeleteCartSuccessEvent || event instanceof CustomDeleteCartFailEvent) {
                  this.routingService.go({ cxRoute: 'giftboxWizard' });
                }
              })
          );
        }
      }),
    );
    this.hasStock = this.item.stock && this.item.stock.stockLevelStatus !== 'outOfStock';
    if (!this.hasStock) {
      this.styleClasses += ' no-stock';
    }
  }

  // TODO: Adapt modal to the new structure used by spartacus
  // selectGiftBox(): void {
  //   this.activeCartService.getActive()
  //     .pipe(take(1))
  //     .subscribe((activeCart) => {
  //       if (activeCart.giftBoxProduct) { // Cart is giftbox
  //         if (activeCart.giftBoxProduct.code === this.item.code){ // Cart is already this giftbox
  //           this.routingService.go({ cxRoute: 'giftboxWizard' });
  //         } else { // Cart is other giftbox
  //           this.openModal(true);
  //         }
  //       } else if (activeCart.entries?.length){ // Cart is not a giftbox and has products
  //         this.openModal(false);
  //       } else { // Cart is not a giftbox and is empty
  //         this.addGiftboxToCart();
  //       }
  //     });
  // }

  private addGiftboxToCart(): void {
    this.activeCartService.addGiftbox(this.item.code);
  }

  // TODO: Adapt modal to the new structure used by spartacus
  // private openModal(isOtherGiftbox: boolean): void {
  //   let modalInstance: any;
  //   this.modalRef = this.modalService.open(CustomAddGiftboxToCartDialogComponent, {
  //     centered: true,
  //     size: 'lg',
  //   });
  //
  //   modalInstance = this.modalRef.componentInstance;
  //   modalInstance.isOtherGiftbox = isOtherGiftbox;
  //
  //   this.modalRef.result
  //     .then((isAccepted: boolean) => {
  //       if (isAccepted) {
  //         this.addGiftboxToCart();
  //       }
  //     })
  //     .catch(() => {});
  // }
}
