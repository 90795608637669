import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomPopupShippingService } from '../../user/popup-shipping/custom-popup-shipping.service';
import { Observable, Subscription } from 'rxjs';
import { CustomValidateCartService } from '../../../../services/cart/validate-cart.service';
import { WindowRef} from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService, PageLayoutService } from '@spartacus/storefront';
import { CustomBaseStoreModel } from './custom-base-store.model';

@Component({
  selector: 'app-base-store',
  templateUrl: './custom-base-store.component.html',
  styleUrls: ['./custom-base-store.component.scss'],
})
export class CustomBaseStoreComponent implements OnInit {
  currentBaseStore$: Observable<CustomBaseStoreModel>;
  @ViewChild('open') element: ElementRef;
  modalRef;
  private subscriptions = new Subscription();

  constructor(
    protected launchDialogService: LaunchDialogService,
    private customPopupShippingService: CustomPopupShippingService,
    protected customValidateCartService: CustomValidateCartService,
    protected vcr: ViewContainerRef,
    protected pageLayoutService: PageLayoutService,
    protected winRef?: WindowRef,
  ) {

    if (this.winRef?.localStorage?.getItem('currentbasestore')) {
      this.customPopupShippingService.updateSelectedBaseStore(JSON.parse(this.winRef?.localStorage.getItem('currentbasestore')));
    }
    this.currentBaseStore$ = this.customPopupShippingService.getSelectedBaseStore();
  }

  ngOnInit(): void {
    this.customValidateCartService.validateCart();
  }

  openModal(): void {
    //CustomPopupShippingComponent
    this.pageLayoutService.page$.subscribe((data)=> {
      if(data.template !== 'PreferenceCenterPageTemplate') {
        const dialog = this.launchDialogService.openDialog(
          LAUNCH_CALLER.HEADER_SHIPPING,
          undefined,
          this.vcr,
          {
            showButtonCancel: true,
          }
        );
  
        if (dialog) {
          this.subscriptions.add(dialog.subscribe());
        }
      }
    });
  }
}
