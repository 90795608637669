import { Injectable } from '@angular/core';
import { createSelector, MemoizedSelector, select, Store } from '@ngrx/store';
import { CheckoutState } from "@spartacus/checkout/base/root";
import {
  Command,
  CommandService,
  CommandStrategy,
  GlobalMessageActions,
  GlobalMessageType,
  ProcessSelectors,
  RoutingService,
  StateWithProcess,
  UserIdService
} from '@spartacus/core';
import { Cart, ActiveCartFacade } from '@spartacus/cart/base/root';
import { MultiCartState, StateWithMultiCart } from '@spartacus/cart/base/core';
import { combineLatest, from, Observable } from 'rxjs';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomValidateCart } from 'src/app/spartacus/custom/core/checkout/store/actions';
import { SIMULATE_CART_LOAD_ID, VALIDATE_CART_LOAD_ID } from 'src/app/spartacus/custom/core/checkout/store/custom-checkout-state';
import { getMultiCartState } from '../../spartacus/custom/core/cart/store/selectors/custom-multi-cart.selectors';
import {
  LoadSimulateCart,
  LoadValidateCart,
  ResetSimulateCart
} from '../../spartacus/custom/core/checkout/store/actions/custom-validate-cart.action';
import { map, switchMap, take } from 'rxjs/operators';
import { CustomCheckoutConnector } from 'src/app/spartacus/custom/core/checkout/connectors/custom-checkout.connector';
import { CustomCheckoutPaymentService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment.service';

export const getActiveCartId: MemoizedSelector<StateWithMultiCart,
  string> = createSelector(getMultiCartState, (state: MultiCartState) => state.index['active']);


@Injectable({
  providedIn: 'root',
})
export class CustomValidateCartService {
  cartId: string;
  userId: string;

  protected setSimulateOrderCommand: Command<
    { validate: boolean },
    unknown
  > = this.commandService.create<{
      validate: boolean;
    }>(
    ({ validate }) => this.checkoutPreconditions().pipe(
        switchMap(([userId, cartId]) =>
          this.customCheckoutConnector
            .simulateOrder(
              userId,
              cartId
            )
        )
      ),
    {
      strategy: CommandStrategy.CancelPrevious,
    }
  );

  constructor(
    protected activeCartService: CustomActiveCartService,
    protected userIdService: UserIdService,
    protected checkoutStore: Store<CheckoutState | StateWithProcess<void>>,
    protected store: Store<StateWithMultiCart | StateWithProcess<void>>,
    protected customCheckoutConnector: CustomCheckoutConnector,
    protected commandService: CommandService,
    protected checkoutPaymentService: CustomCheckoutPaymentService,
    protected activeCartFacade : ActiveCartFacade,
    protected routingService?: RoutingService,
  ) {

  }

  protected checkoutPreconditions(): Observable<[string, string]> {
    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartService.takeActiveCartId()
    ]).pipe(
      take(1),
      map(([userId, cartId]) => {
        return [userId, cartId];
      })
    );
  }

  getCartID(): void {
    this.store.pipe(select(getActiveCartId))
      .subscribe(cartId => {
        this.cartId = cartId;
      }).unsubscribe();
  }

  getUserID(): void {
    this.userIdService.takeUserId()
      .subscribe(userID => this.userId = userID)
      .unsubscribe();
  }

  /**
   * Returns the load Simulate cart loading flag
   */
  getValidateCartLoading(): Observable<boolean> {
    return this.checkoutStore.pipe(
      select(ProcessSelectors.getProcessLoadingFactory(VALIDATE_CART_LOAD_ID)),
    );
  }

  /**
   * Returns the load Simulate cart success flag
   */
  getValidateCartSuccess(): Observable<boolean> {
    return this.checkoutStore.pipe(
      select(ProcessSelectors.getProcessSuccessFactory(VALIDATE_CART_LOAD_ID)),
    );
  }

  /**
   * Returns the load Simulate cart state
   */
  getValidateCartState(): Observable<any> {
    return this.checkoutStore.pipe(
      select(ProcessSelectors.getProcessStateFactory(VALIDATE_CART_LOAD_ID)),
    );
  }

  /**
   * Returns the load Simulate cart error flag
   */
  getValidateCartError(): Observable<boolean> {
    return this.checkoutStore.pipe(
      select(ProcessSelectors.getProcessErrorFactory(VALIDATE_CART_LOAD_ID)),
    );
  }

  /**
   * Clear the Simulate cart result flag
   */
  clearValidateCart(): void {
    this.checkoutStore.dispatch(new CustomValidateCart.ResetValidateCart());
  }


  validateCart(): void {
    combineLatest([
      this.userIdService.getUserId(),
      this.activeCartFacade.getActiveCartId()
    ]).pipe(
      take(1),
      map(([userId, cartId]) => {
        if (userId && cartId) {
          this.checkoutStore.dispatch(new LoadValidateCart({ userId: userId, cartId: cartId }));
        }
      })
    ).subscribe();   
  }

  /**
   * Returns the load Simulate cart loading flag
   */
  getSimulateCartLoading(): Observable<boolean> {
    return this.checkoutStore.pipe(
      select(ProcessSelectors.getProcessLoadingFactory(SIMULATE_CART_LOAD_ID)),
    );
  }

  /**
   * Returns the load Simulate cart success flag
   */
  getSimulateCartSuccess(): Observable<boolean> {
    return this.checkoutStore.pipe(
      select(ProcessSelectors.getProcessSuccessFactory(SIMULATE_CART_LOAD_ID)),
    );
  }

  /**
   * Returns the load Simulate cart state
   */
  getSimulateCartState(): Observable<any> {
    return this.checkoutStore.pipe(
      select(ProcessSelectors.getProcessStateFactory(SIMULATE_CART_LOAD_ID)),
    );
  }

  /**
   * Returns the load Simulate cart error flag
   */
  getSimulateCartError(): Observable<boolean> {
    return this.checkoutStore.pipe(
      select(ProcessSelectors.getProcessErrorFactory(SIMULATE_CART_LOAD_ID)),
    );
  }

  /**
   * Clear the Simulate cart result flag
   */
  clearSimulateCart(): void {
    this.checkoutStore.dispatch(new ResetSimulateCart());
  }

  simulateOrder(validate): void {
    combineLatest([
      this.userIdService.getUserId(),
      this.activeCartFacade.takeActiveCartId()
    ]).pipe(
      take(1),
      map(([userId, cartId]) => {
        if (userId && cartId) {
          this.checkoutStore.dispatch(new LoadSimulateCart({ userId, cartId, validate }));
        }
      })
    ).subscribe();  
  }

  redirectToCartPage(): void {
    this.routingService.go({ cxRoute: 'cart' });
  }

  validateCartData(cart: Cart): boolean {
    return !!(cart?.deliveryAddress?.id.length
      && cart?.paymentMode?.code?.length
      && cart?.deliveryMode?.code?.length);
  }

  onSimulateOrderError(error): Observable<GlobalMessageActions.AddMessage>{
    this.redirectToCartPage();
    return from([
      new GlobalMessageActions.AddMessage({
        text: { key: 'cartDetails.missingInformation' },
        type: GlobalMessageType.MSG_TYPE_WARNING,
        timeout: 10000
      })
    ]);
  }
}
