import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { GenericLinkComponent, GenericLinkComponentService } from '@spartacus/storefront';

/**
 * This component navigates using [routerLink] attribute when input 'url' is a relative url. Otherwise (when it's absolute), [href] is used.
 */
@Component({
  selector: 'cx-generic-link',
  templateUrl: './custom-generic-link.component.html',
})
export class CustomGenericLinkComponent extends GenericLinkComponent {
  constructor(
    protected router: Router,
    protected semanticPathService: SemanticPathService,
    protected service: GenericLinkComponentService
  ) {
    super(router, service);
  }

  get routerLink(): any[] {
    if (this.isCategoryOrProduct()) {
      const routerUrl = this.routerUrl[0] as string;
      return this.semanticPathService.transform({
        cxRoute: (routerUrl.startsWith('/c/') || routerUrl.startsWith('/category/')) ? 'category' : 'product',
        params: {
          code: routerUrl.replace(/^\/(c|p|product|category)\//, ''),
        },
      });
    }
    return this.routerUrl;
  }

  private isCategoryOrProduct(): boolean {
    return this.routerUrl && Boolean((this.routerUrl[0] as string)?.match(/^\/(c|p|product|category)\//));
  }
}
