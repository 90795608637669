import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {filter, map} from 'rxjs/operators';
import { CustomActiveCartService } from '../../cart/facade/custom-active-cart.service';
import { CheckoutDeliveryAddressConnector, CheckoutDeliveryAddressService } from '@spartacus/checkout/base/core';
import { StateWithMultiCart } from '@spartacus/cart/base/core';
import { StateWithProcess, UserIdService, AddressValidation, CommandService, EventService } from '@spartacus/core';
import { StateWithCheckout } from '../../cart/store/checkout-state';
import { CheckoutQueryFacade } from '@spartacus/checkout/base/root';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutDeliveryService extends CheckoutDeliveryAddressService {

  constructor(
    protected checkoutStore: Store<StateWithCheckout>,
    protected processStateStore: Store<StateWithProcess<void>>,
    protected cartStore: Store<StateWithMultiCart>,
    protected activeCartService: CustomActiveCartService,
    protected userIdService: UserIdService,
    protected eventService: EventService,
    protected commandService: CommandService,
    protected checkoutDeliveryAddressConnector: CheckoutDeliveryAddressConnector,
    protected checkoutQueryFacade: CheckoutQueryFacade
  ) {
    super(activeCartService, userIdService, eventService, commandService, checkoutDeliveryAddressConnector, checkoutQueryFacade);
  }

  /**
   * Get delivery note observations
   */
  getDeliveryNoteObservations(): Observable<string> {
    return this.activeCartService.getActive()
      .pipe(
        map((cart) => cart?.deliveryNoteObservations?.length
          ? cart.deliveryNoteObservations
          : cart?.deliveryAddress?.deliveryNoteObservations),
      );
  }


  /**
   * Get default delivery address
   */
  getDefaultDeliveryAddress(): Observable<any> {
    return this.getDeliveryAddressState().pipe(
      filter((state) => state.data.defaultAddress),
      map((state) => state.data)
    );
  }
}
