import { CustomNewsListModel } from '../custom-news.model';
import { CustomNewsListActions, LOAD_NEWS_LIST_FAIL, LOAD_NEWS_LIST_SUCCESS } from '../actions/custom-news-list.actions';

export const initialState: CustomNewsListModel = {
  newsList: [],
  pagination: {},
  sorts: [],
};

export function reducerNewsList(
  state = initialState,
  action: CustomNewsListActions,
): CustomNewsListModel {
  switch (action.type) {
    case LOAD_NEWS_LIST_SUCCESS: {
      return action.payload;
    }
    case LOAD_NEWS_LIST_FAIL: {
      return initialState;
    }
  }
  return state;
}


