import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PromotionLocation, PromotionResult } from '@spartacus/cart/base/root';
import { EventService } from '@spartacus/core';
import { OrderConfirmationItemsComponent } from '@spartacus/order/components';
import { Order, OrderFacade, OrderHistoryFacade } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CustomCheckoutPaymentService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment.service';

@Component({
  selector: 'cx-order-confirmation-items',
  templateUrl: './custom-order-confirmation-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderConfirmationItemsComponent implements OnInit, OnDestroy {
  promotionLocation: PromotionLocation = PromotionLocation.Checkout;
  order$: Observable<Order>;
  orderPromotions$: Observable<PromotionResult[]>;
  displayOrderbyCode: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected orderFacade: OrderFacade,
    protected eventService: EventService,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected checkoutService: CustomCheckoutPaymentService
  ) {

    this.displayOrderbyCode = !!this.activatedRoute.snapshot.url[1]?.path;
  }

  ngOnInit(): void {
    if (this.displayOrderbyCode) {
      this.order$ = this.orderHistoryFacade.getOrderDetails();
    } else {
      this.order$ = this.orderHistoryFacade.getOrderDetails();
    }
    this.order$.subscribe(order => {
      this.orderPromotions$ = new Observable<PromotionResult[]>(obs => {obs.next(order.appliedOrderPromotions)});
    });
  }

  ngOnDestroy(): void {
    if (this.displayOrderbyCode) {
      this.orderHistoryFacade.clearOrderDetails();
    } else {
      this.checkoutService.clearCheckoutData();
    }
  }
}
