import { Component, OnInit } from '@angular/core';
import {
  CardComponent,
  ICON_TYPE,
} from '@spartacus/storefront';


@Component({
  selector: 'app-custom-row',
  templateUrl: './custom-row.component.html',
  styleUrls: ['./custom-row.component.scss'],
})
export class CustomRowComponent extends CardComponent {
  iconTypes = ICON_TYPE;

}
