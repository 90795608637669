import {Injectable} from '@angular/core';
import {isNotUndefined, ProductService, RoutingService, WindowRef,} from '@spartacus/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, skip, tap, withLatestFrom} from 'rxjs/operators';
import {CurrentProductService} from "@spartacus/storefront";
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CustomCurrentProductService extends CurrentProductService {

  protected currentProductCode: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    protected routing: RoutingService,
    protected product: ProductService,
    protected route: ActivatedRoute,
    protected windowRef?: WindowRef
  ) {
    super(routing, product);
  }

  protected getCode(): Observable<string> {
    return merge(
      this.routing.getRouterState()
        .pipe(
          withLatestFrom(this.route.paramMap.pipe(
            map((): string | undefined => this.windowRef?.nativeWindow?.history?.state?.productCode)
          )),
          map(([routerState, routeProductCode]) => {
            const state = routerState.state;
            const queryParams = state.queryParams;
            return ('item' in queryParams && queryParams.item) ? queryParams.item :
                   (routeProductCode) ? routeProductCode :
                   state.params['productCode'];
          }),
          distinctUntilChanged(),
          tap(code => this.currentProductCode.next(code))
        ),
      this.currentProductCode.pipe(
        skip(1)
      )
    ).pipe(
      filter(isNotUndefined)
    );
  }

  setProduct(code: string): void {
    this.currentProductCode.next(code);
  }
}
