import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomPaypalService } from './services/custom-paypal.service';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'app-custom-paypal-button',
  templateUrl: './custom-paypal-button.component.html',
})
export class CustomPaypalButtonComponent implements OnInit {
  @Input()disable: Observable<boolean>;

  constructor(
    protected customPaypalService: CustomPaypalService,
    protected winRef?: WindowRef,
  ) { }

  ngOnInit(): void {
    this.customPaypalService.loadButtons();
  }
}
