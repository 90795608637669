<div class="SiteLogo">
  <ng-container *ngIf="(siteLogo$ | async) as siteLogo">
    <ng-container *ngIf="siteLogo?.media">
      <cx-media [container]="siteLogo.media"></cx-media>
    </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="showDisabledPageMessage; else ageValidationMessage">
  <p [innerHTML]="'loginForm.ageStatus.invalidAge' | cxTranslate"></p>
</ng-container>
<ng-template #ageValidationMessage>
  <p class="modal-text" [innerHTML]="'loginForm.ageStatus.text' | cxTranslate"></p>
  <div class="wrapper-buttons">
    <button class="btn btn-send" (click)="overEighteen()">
      {{'loginForm.ageStatus.overEighteen' | cxTranslate}}
    </button>
    <button class="btn btn-send" (click)="denyAccess()">
      {{'loginForm.ageStatus.underEighteen' | cxTranslate}}
    </button>
  </div>
</ng-template>
