import {
  CustomNewsCategoriesModel,
  CustomNewsDetailsModel,
  CustomNewsListModel,
  CustomNewsSliderModel,
} from '../custom-news.model';
import { NEWS_LIST } from '../actions/custom-news-list.actions';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AuthActions, StateUtils } from '@spartacus/core';
import { InjectionToken, Provider } from '@angular/core';
import { reducerNewsList } from './custom-news-list.reducer';
import { reducerNewsDetails } from './custom-news-details.reducer';
import { CustomNewsState } from '../custom-news.state';
import { NEWS_DETAILS } from '../actions/custom-news-details.actions';
import { NEWS_SLIDER } from '../actions/custom-news-slider.actions';
import { reducerNewsSlider } from './custom-news-slider.reducer';
import { NEWS_CATEGORIES } from '../actions/custom-news-categories.actions';
import { reducerNewsCategories } from './custom-news-categories.reducer';

export function getNewsReducers(): ActionReducerMap<CustomNewsState> {
  return {
    list: StateUtils.loaderReducer<CustomNewsListModel>(NEWS_LIST, reducerNewsList),
    categories: StateUtils.loaderReducer<CustomNewsCategoriesModel>(NEWS_CATEGORIES, reducerNewsCategories),
    details: StateUtils.loaderReducer<CustomNewsDetailsModel>(NEWS_DETAILS, reducerNewsDetails),
    slider: StateUtils.loaderReducer<CustomNewsSliderModel>(NEWS_SLIDER, reducerNewsSlider),
  };
}

export const reducerNewsToken:
  InjectionToken<ActionReducerMap<CustomNewsState>> =
  new InjectionToken<ActionReducerMap<CustomNewsState>>('NewsReducers');

export const reducerNewsTokenProvider: Provider = {
  provide: reducerNewsToken,
  useFactory: getNewsReducers,
};


export function clearNewsListState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
      state = [];
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearNewsListState];

