import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {CustomCheckoutPaymentService} from "../../../../core/checkout/facade/custom-checkout-payment.service";
import { OrderFacade, OrderHistoryFacade } from '@spartacus/order/root';

@Component({
  selector: 'cx-order-confirmation-totals',
  templateUrl: './custom-order-confirmation-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderConfirmationTotalsComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  displayOrderbyCode: boolean;

  constructor(
    protected checkoutService: CustomCheckoutPaymentService,
    private activatedRoute: ActivatedRoute,
    private orderHistoryFacade: OrderHistoryFacade,
    protected orderFacade: OrderFacade
    ) {
      this.displayOrderbyCode = !!this.activatedRoute.snapshot.url[1]?.path;
    }

  ngOnInit(): void {
    if (this.displayOrderbyCode) {
      this.order$ = this.orderHistoryFacade.getOrderDetails();
    } else {
      this.order$ = this.orderFacade.getOrderDetails();
    }
  }

  ngOnDestroy(): void {
    if (this.displayOrderbyCode) {
      this.orderHistoryFacade.clearOrderDetails();
    } else {
      this.checkoutService.clearCheckoutData();
    }
  }
}
