import { Component, HostBinding } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsCustomCarouselBannerSliderComponent } from './custom-carousel-slider-banner.model';
import { CmsService, ContentSlotComponentData } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { CmsCustomCarouselBannerComponent } from '../custom-carousel-banner/custom-carousel-banner.model';

@Component({
  selector: 'app-custom-carousel-banner-slider',
  templateUrl: './custom-carousel-banner-slider.component.html',
  styleUrls: ['./custom-carousel-banner-slider.component.scss'],
})
export class CustomCarouselBannerSliderComponent {

  @HostBinding('class') theme = '';

  includeHeader: boolean;
  firstComponentCode: string;
  componentSlotName: string;

  private componentData$: Observable<CmsCustomCarouselBannerSliderComponent> =
    this.componentData.data$.pipe(
      filter(Boolean),
      tap((d: CmsCustomCarouselBannerSliderComponent) => {
        this.theme = `${d.uid}-theme`;
        this.includeHeader = d.includeHeader === 'true' || d.includeHeader === true;
        this.cmsService.getCurrentPage().pipe(
          take(1),
          tap(page => {
              for (const slot in page.slots) {
                if (page.slots[slot].components?.filter(component => component.uid === d.uid).length) {
                  this.componentSlotName = slot;
                  break;
                }
              }
            }
          )
        ).subscribe();
      }),
    );

  private items$: Observable<Observable<ContentSlotComponentData>[]> = this.componentData$.pipe(
    map((data: any) => {
      const bannerComponentCodes = data?.banners?.trim().split(' ');
      this.firstComponentCode = bannerComponentCodes[0] ?? '';
      return bannerComponentCodes;
    }),
    map(codes => codes.map(code => this.cmsService.getComponentData(code))),
  );

  constructor(
    protected componentData: CmsComponentData<CmsCustomCarouselBannerSliderComponent>,
    protected cmsService: CmsService,
  ) {
  }

 getItems(): Observable<Observable<ContentSlotComponentData>[]>  {
    return this.items$;
 }

 displayTitleAsHeader(component: CmsCustomCarouselBannerComponent): boolean {
  return this.includeHeader && component.uid === this.firstComponentCode;
 }
}
