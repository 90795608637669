import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { EmarsysContactInfo } from '../../../../../model/preference-center.model';
import { Observable } from 'rxjs';
import { CustomPreferenceCenterAdapter } from '../../core/connectors/custom-preference-center.adapter';

@Injectable()
export class CustomOccPreferenceCenterAdapter implements CustomPreferenceCenterAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {}

  loadPreferenceCenterDetails(
    uid: string, 
    storeId: string,
  ): Observable<EmarsysContactInfo> {
    const url = this.occEndpoints.buildUrl('preferenceCenter', {
      queryParams: { keyValue: uid, storeId: storeId },
    });

    return this.http.get<EmarsysContactInfo>(url);
  }

  updatePreferenceCenterDetails(
    uid: string,
    cid: string,
    llid: string,
    details: EmarsysContactInfo,
  ): Observable<EmarsysContactInfo> {
    const url = this.occEndpoints.buildUrl('updatePreference', {
      queryParams: { uid, cid, llid },
    });

    return this.http.put<EmarsysContactInfo>(url, details);
  }
}
