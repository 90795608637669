import { Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import {
  Address,
  CostCenter,
  GlobalMessageService,
  GlobalMessageType,
  PaymentDetails,
  TranslationService,
  UserAddressService,
  UserCostCenterService
} from '@spartacus/core';
import { Card, LaunchDialogService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomCheckoutPaymentTypeService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment-type.service';
import { EntryGroup } from '../../../../../../model/entry-groups.model';
import { CustomValidateCartService } from '../../../../../../services/cart/validate-cart.service';
import { CUSTOM_LAUNCH_CALLER } from '../../../../../spartacus-custom.module';
import { CustomCheckoutDeliveryService } from '../../../../core/checkout/facade/custom-checkout-delivery.service';
import { CustomFriendsCheckoutModel } from '../../../../core/custom-friends/custom-friends-checkout/facade/custom-friends-checkout.model';
import { CustomFriendsCheckoutService } from '../../../../core/custom-friends/custom-friends-checkout/facade/custom-friends-checkout.service';
import { CreditCard } from '../payment-method/custom-credit-card/store/credit-card.state';
import { PromotionResult, PaymentType } from '@spartacus/cart/base/root';
import { B2BPaymentTypeEnum, CheckoutCostCenterFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutReviewSubmitComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryModesFacade, CheckoutPaymentFacade } from '@spartacus/checkout/base/root';

@Component({
  selector: 'cx-review-submit',
  templateUrl: './custom-review-submit.component.html',
  styleUrls: ['./custom-review-submit.component.scss'],
})
export class CustomReviewSubmitComponent extends CheckoutReviewSubmitComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();
  orderEntryGroups$: Observable<EntryGroup[]>;
  validatingCart: void | Observable<ComponentRef<any>>;
  selectedCreditCard: CreditCard;

  constructor(
    protected checkoutDeliveryService: CustomCheckoutDeliveryService,
    protected checkoutPaymentService: CheckoutPaymentFacade,
    protected userAddressService: UserAddressService,
    protected activeCartService: CustomActiveCartService,
    protected translation: TranslationService,
    protected checkoutStepService: CheckoutStepService,
    protected paymentTypeService: CustomCheckoutPaymentTypeService,
    protected checkoutCostCenterService: CheckoutCostCenterFacade,
    protected userCostCenterService: UserCostCenterService,
    protected userAccountFacade: UserAccountFacade,
    protected customValidateCartService: CustomValidateCartService,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService,
    protected customFriendsCheckoutService: CustomFriendsCheckoutService,
    protected globalMessageService: GlobalMessageService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade
  ) {
    super(
      checkoutDeliveryService,
      checkoutPaymentService,
      activeCartService,
      translation,
      checkoutStepService,
      checkoutDeliveryModesFacade
    );
    this.orderEntryGroups$ = this.activeCartService.getOrderEntryGroups();
  }

  get orderPromotions$(): Observable<PromotionResult[]> {
    let promotions: Observable<PromotionResult[]>;
    this.cart$.subscribe(cart => {
      promotions = new Observable<PromotionResult[]>(obs => {
        obs.next(cart.potentialOrderPromotions);
      });
    });
    return promotions;
  }

  get orderAppliedPromotions$(): Observable<PromotionResult[]> {
    let promotions: Observable<PromotionResult[]>;
    this.cart$.subscribe(cart => {
      promotions = new Observable<PromotionResult[]>(obs => {
        obs.next(cart.appliedOrderPromotions);
      });
    });
    return promotions;
  }

  ngOnInit(): void {
    this.customValidateCartService.validateCart();
    this.subscription.add(
      combineLatest([
        this.customValidateCartService.getValidateCartLoading(),
        this.customValidateCartService.getValidateCartSuccess(),
        this.customValidateCartService.getValidateCartError(),
      ]).subscribe(([simulateCartLoading, simulateCartSuccess, simulateCartError]) => {
        if (simulateCartLoading) {
          if (!this.validatingCart) {
            this.validatingCart = this.launchDialogService.launch(
              CUSTOM_LAUNCH_CALLER.VALIDATE_CART_SPINNER,
              this.vcr,
            );
          }
        }
        if (simulateCartError) {
          this.customValidateCartService.redirectToCartPage();
        }

        if (simulateCartSuccess) {
          this.clearValidateActions();
        }
      }),
    );

    this.paymentTypeService.saveCreditCardDefaultState();

    this.subscription.add(
      this.checkoutDeliveryService.getDefaultDeliveryAddress()
        .pipe(
          take(1),
        ).subscribe((address) => {
          if (!address) {
            this.globalMessageService.add(
              { key: 'paymentTypes.missingAddress' },
              GlobalMessageType.MSG_TYPE_WARNING,
              10000,
            );
            this.customValidateCartService.redirectToCartPage();
          }
        }),
    );
  }

  clearValidateActions(): void {

    if (this.validatingCart) {
      this.validatingCart
        .subscribe((component) => {
          this.launchDialogService.clear(
            CUSTOM_LAUNCH_CALLER.VALIDATE_CART_SPINNER,
          );
          component.destroy();
        })
        .unsubscribe();
      this.customValidateCartService.clearValidateCart();
    }
  }

  getCostCenterCard(costCenter: CostCenter): Observable<Card> {
    return combineLatest([
      this.translation.translate('checkoutPO.costCenter'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: costCenter?.name,
          text: ['(' + costCenter?.unit.name + ')'],
        };
      }),
    );
  }

  getPaymentTypeCard(paymentTypeCode: string): Observable<Card> {
    let holderUser;
    if (paymentTypeCode === B2BPaymentTypeEnum.BANK_ACCOUNT_PAYMENT) {

      this.customFriendsCheckoutService.getSelectedOnBehalfOfFromCart()
        .subscribe((onBehalfOf: CustomFriendsCheckoutModel) => holderUser = onBehalfOf);

      if (!holderUser) {
        this.userAccountFacade.get()
          .subscribe((user) => holderUser = user)
          .unsubscribe();
      }
    }
    let selectedPaymentType: PaymentType;
    this.paymentTypeService.getPaymentTypeByCode(paymentTypeCode)
      .subscribe((paymentType) => selectedPaymentType = paymentType)
      .unsubscribe();
    return combineLatest([
      this.translation.translate('paymentTypes.title'),
      this.translation.translate('paymentTypes.paymentType', {
        context: paymentTypeCode, display: selectedPaymentType?.name, name: holderUser?.name,
      }),
      this.paymentTypeService.getPaymentDetails(),
    ]).pipe(
      map(([textTitle, paymentTypeTranslation, paymentDetails]) => {
        let cardData = [];

        if (paymentDetails?.cardNumber) {
          cardData = [
            ...cardData,
            paymentDetails.accountHolderName,
            paymentDetails.cardNumber,
            paymentDetails.expiryMonth + '/' + paymentDetails.expiryYear,
          ];
        }
        return {
          title: textTitle,
          textBold: paymentTypeTranslation,
          text: cardData,
        };
      }),
    );
  }

  getShippingAddressCard(
    deliveryAddress: Address,
    countryName: string,
  ): Observable<Card> {
    return combineLatest([
      this.translation.translate('addressCard.shipTo'),
    ]).pipe(
      map(([textTitle]) => {
        if (!countryName) {
          countryName = deliveryAddress?.country?.isocode;
        }

        let region = '';
        if (
          deliveryAddress &&
          deliveryAddress.region &&
          deliveryAddress.region.isocode
        ) {
          region = deliveryAddress.region.isocode + ', ';
        }

        return {
          title: textTitle,
          textBold: deliveryAddress.firstName + (deliveryAddress.lastName ? ' ' + deliveryAddress.lastName : ''),
          text: [
            deliveryAddress.line1,
            deliveryAddress.line2,
            deliveryAddress.town + ', ' + region + countryName,
            deliveryAddress.postalCode,
            deliveryAddress.phone,
          ],
        };
      }),
    );
  }

  getPaymentMethodCard(paymentDetails: PaymentDetails): Observable<Card> {
    return combineLatest([
      this.translation.translate('paymentForm.payment'),
      this.translation.translate('paymentCard.expires', {
        month: paymentDetails.expiryMonth,
        year: paymentDetails.expiryYear,
      }),
      this.translation.translate('paymentForm.billingAddress'),
    ]).pipe(
      map(([textTitle, textExpires, billingAddress]) => {
        const region = paymentDetails.billingAddress?.region?.isocode
          ? paymentDetails.billingAddress?.region?.isocode + ', '
          : '';
        return {
          title: textTitle,
          textBold: paymentDetails.accountHolderName,
          text: [paymentDetails.cardNumber, textExpires],
          paragraphs: [
            {
              title: billingAddress + ':',
              text: [
                paymentDetails.billingAddress?.firstName +
                (paymentDetails.billingAddress?.lastName ? ' ' + paymentDetails.billingAddress?.lastName : ''),
                paymentDetails.billingAddress?.line1,
                paymentDetails.billingAddress?.town +
                ', ' +
                region +
                paymentDetails.billingAddress?.country?.isocode,
                paymentDetails.billingAddress?.postalCode,
              ],
            },
          ],
        };
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
