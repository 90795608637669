<div>
  <h2 class="title">
    {{ 'wishlist.title' | cxTranslate }}
  </h2>
</div>
<ng-container *ngIf="wishListEntries$ | async as wishListEntries">
  <ng-container *ngIf="wishListEntries?.length > 0; else emptyWishList">
    <div
      *ngFor="let entry of wishListEntries;let i = index"
      class="cx-wish-list-item"
    >
      <cx-product-list-item
        [product]="entry.product"
        [index]="i"
        [category]="'wishlist'"
        class="cx-product-search-list"
      ></cx-product-list-item>
      <button
        class="btn btn-icon wish-list-item-delete"
        [title]="'wishlist.remove' | cxTranslate"
        (click)="removeEntry($event, entry.product.code)"
      >
        <app-custom-icons [type]="iconTypes.TRASH"></app-custom-icons>
      </button>
    </div>
  </ng-container>
</ng-container>
<ng-template #emptyWishList>
  <div class="custom-list-filter-no-order">
    {{ 'wishlist.empty' | cxTranslate }}
  </div>
</ng-template>
