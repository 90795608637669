<ng-container *ngIf="returnRequest$ | async as returnRequest">
  <div
    class="cx-item-list-row"
    *ngFor="let returnEntry of returnRequest.returnEntries; let i = index"
  >
    <div class="cx-item-list-items">
      <div class="row">
        <!-- Item Image -->
        <div class="cx-image-container">
          <a [routerLink]="{ cxRoute: 'product', params: returnEntry.orderEntry?.product } | cxUrl">
            <cx-media
              [container]="returnEntry.orderEntry?.product.images?.PRIMARY"
              format="thumbnail"
            ></cx-media>
          </a>
        </div>
        <!-- Item Information -->
        <div class="cx-info">
          <div class="cx-info-container">
            <!-- Item Description -->
            <div>
              <div *ngIf="returnEntry.orderEntry?.product.name" class="cx-name">
                <a [routerLink]="{ cxRoute: 'product', params: returnEntry.orderEntry?.product } | cxUrl">
                  {{ returnEntry.orderEntry?.product.name }}
                </a>
              </div>
              <!-- Variants -->
              <div
                *ngFor="
                  let variant of (returnEntry.orderEntry?.product
                    .baseOptions)[0]?.selected?.variantOptionQualifiers
                "
                class="cx-property"
              >
                <div class="cx-label" *ngIf="variant.name">
                  {{ variant.name }}:
                </div>
                <div class="cx-value" *ngIf="variant.value">
                  {{ variant.value }}
                </div>
              </div>
              <!-- Total Price -->
              <div class="cx-total">
                <div class="cx-value">
                  {{ returnEntry.refundAmount?.formattedValue }}
                </div>
              </div>
              <!-- Item Price -->
              <div
                *ngIf="returnEntry.orderEntry?.basePrice"
                class="cx-price"
              >
                <div class="cx-value">
                  {{ returnEntry.orderEntry?.basePrice?.formattedValue }} {{ 'addToCart.eachUnit' | cxTranslate }}
                </div>
              </div>
              <!-- return Quantity -->
              <div class="cx-quantity">
                <div class="cx-label">
                  {{ 'returnRequest.returnQty' | cxTranslate }}
                </div>
                <div class="cx-value">
                  <ng-container [ngSwitch]="returnEntry.expectedQuantity > 0">
                    <ng-container *ngSwitchCase="true">
                      {{ returnEntry.expectedQuantity }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ returnEntry.receivedQuantity }}
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
