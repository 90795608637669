import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
} from 'rxjs/operators';
import { GlobalMessageActions, GlobalMessageType, normalizeHttpError, SiteContextActions, withdrawOn } from '@spartacus/core';
import { CustomCartConnector } from '../../conectors/cart/custom-cart.connector';
import { CustomCartActions } from '../actions';
import { CartActions, getCartIdByUserId } from '@spartacus/cart/base/core';

@Injectable()
export class CustomCartEffects {
  private contextChange$ = this.actions$.pipe(
    ofType(
      SiteContextActions.CURRENCY_CHANGE,
      SiteContextActions.LANGUAGE_CHANGE
    )
  );

  setDocumentIdentifier$: Observable<
    | CustomCartActions.SetDocumentIdentifierSuccess
    | CustomCartActions.SetDocumentIdentifierFail
    | CartActions.LoadCartSuccess
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomCartActions.CUSTOM_SET_DOCUMENT_IDENTIFIER),
    map((action: CustomCartActions.SetDocumentIdentifier) => action.payload),
    mergeMap((payload) =>
      this.cartConnector
        .setDocumentIdentifier(payload.userId, payload.cartId, payload.user)
        .pipe(
          mergeMap((data) => {
            return [
              new CustomCartActions.SetDocumentIdentifierSuccess({
                ...payload,
              }),
            ];
          }),
          catchError((error) =>
            from([
              new CustomCartActions.SetDocumentIdentifierFail({
                ...payload,
                error: normalizeHttpError(error),
              }),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ])
          )
        )
    ),
    withdrawOn(this.contextChange$)
  ));

  addExtraData$: Observable<
    | CustomCartActions.AddExtraDataToCartSuccess
    | CustomCartActions.AddExtraDataToCartFail
    | CartActions.LoadCartSuccess
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomCartActions.CUSTOM_ADD_EXTRA_DATA_TO_CART),
    map((action: CustomCartActions.AddExtraDataToCart) => action.payload),
    mergeMap((payload) =>
      this.cartConnector
        .addExtraData(payload.userId, payload.cartId, payload.extraData)
        .pipe(
          mergeMap((data) => {
            return [
              new CustomCartActions.AddExtraDataToCartSuccess({
                ...payload,
              }),
              new CartActions.LoadCartSuccess({
                cart: data,
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ];
          }),
          catchError((error) =>
            from([
              new CustomCartActions.AddExtraDataToCartFail({
                ...payload,
                error: normalizeHttpError(error),
              }),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ])
          )
        )
    ),
    withdrawOn(this.contextChange$)
  ));

  addGiftbox$: Observable<
    | CustomCartActions.AddGiftboxToCartSuccess
    | CustomCartActions.AddGiftboxToCartFail
    | CartActions.LoadCart
    | CartActions.LoadCartSuccess
    | CartActions.SetActiveCartId
    | GlobalMessageActions.AddMessage
    | CartActions.DeleteCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomCartActions.CUSTOM_ADD_GIFTBOX_TO_CART),
    map((action: CustomCartActions.AddGiftboxToCart) => action.payload),
    mergeMap((payload) =>
      this.cartConnector
        .addGiftbox(payload.userId, payload.giftBoxProductCode)
        .pipe(
          mergeMap((data) => {
            const cartId = getCartIdByUserId(data, payload.userId);
            return [
              new CustomCartActions.AddGiftboxToCartSuccess({
                ...payload,
                cartId,
              }),
              new CartActions.SetActiveCartId(cartId),
              new CartActions.LoadCartSuccess({
                userId: payload.userId,
                cartId,
                cart: data,
              }),
              new CartActions.DeleteCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ];
          }),
          catchError((error) => {
            const actions: Array<
              | CustomCartActions.AddGiftboxToCartFail
              | CartActions.LoadCart
              | GlobalMessageActions.AddMessage
            > = [
              new CustomCartActions.AddGiftboxToCartFail({
                ...payload,
                error: normalizeHttpError(error),
              }),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ];
            error?.error?.errors?.forEach((err) => {
              if (err.message) {
                actions.push(
                  new GlobalMessageActions.AddMessage({
                    text: { raw: err.message },
                    type: GlobalMessageType.MSG_TYPE_ERROR,
                  })
                );
              }
            });
            return from(actions);
          }
          )
        )
    ),
    withdrawOn(this.contextChange$)
  ));

  validateDocIdentifierAndTotalAmount$: Observable<
    | CustomCartActions.SetDocumentIdentifierSuccess
    | CustomCartActions.SetDocumentIdentifierFail
    | CartActions.LoadCartSuccess
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(CustomCartActions.CUSTOM_SET_DOCUMENT_IDENTIFIER),
    map((action: CustomCartActions.SetDocumentIdentifier) => action.payload),
    mergeMap((payload) =>
      this.cartConnector
        .setDocumentIdentifier(payload.userId, payload.cartId, payload.user)
        .pipe(
          mergeMap((data) => {
            return [
              new CustomCartActions.SetDocumentIdentifierSuccess({
                ...payload,
              }),
            ];
          }),
          catchError((error) =>
            from([
              new CustomCartActions.SetDocumentIdentifierFail({
                ...payload,
                error: normalizeHttpError(error),
              }),
              new CartActions.LoadCart({
                userId: payload.userId,
                cartId: payload.cartId,
              }),
            ])
          )
        )
    ),
    withdrawOn(this.contextChange$)
  ));

  constructor(
    private actions$: Actions,
    private cartConnector: CustomCartConnector,
  ) {}
}
