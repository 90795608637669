import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CardModule, IconModule, PromotionsModule } from '@spartacus/storefront';
import { CustomCartSharedModule } from '../../../cart/cart-shared/custom-cart-shared.module';
import { CustomPlaceOrderModule } from '../custom-place-order/custom-place-order.module';
import { CustomReviewSubmitComponent } from './custom-review-submit.component';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { CustomGiftCardsDetailsModule } from 'src/app/custom/cms-components/cart/custom-gift-cards-details/custom-gift-cards-details.module';
import { CustomPromotionsModule } from '../../../product/promotions/promotions.module';

@NgModule({
  declarations: [CustomReviewSubmitComponent],
  exports: [CustomReviewSubmitComponent],
  imports: [
    CommonModule,
    CardModule,
    CustomCartSharedModule,
    I18nModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    CustomGiftCardsDetailsModule,
    IconModule,
    CustomPlaceOrderModule,
    CustomPromotionsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutReviewOrder: {
          component: CustomReviewSubmitComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig)
  ]
})
export class CustomReviewSubmitModule { }
