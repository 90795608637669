<ng-container *ngIf="item">
    <ng-template #genericLink>
        <cx-generic-link *ngIf="item.urlLink && item.urlLink.length" [url]="item.urlLink" [target]="getTarget(item)">{{item.buttonText}}</cx-generic-link>
        <cx-generic-link *ngIf="!item.urlLink" [routerLink]="{ cxRoute: 'category', params: item.category } | cxUrl" [target]="getTarget(item)">{{item.buttonText}}</cx-generic-link>
    </ng-template>
    
    <cx-media [container]="item.media"></cx-media>
    <ng-container *ngIf="item.description ; else linkOnly">
        <div class="wrapper-link">
            <ng-container *ngTemplateOutlet="genericLink; context: item"></ng-container>
        </div>
        <ng-container *ngIf="item.description">
            <div class="wrapper-text">
                <p>{{item.description}}</p>
            </div>
        </ng-container>
    </ng-container>
    
    <ng-template #linkOnly>
        <ng-container *ngTemplateOutlet="genericLink; context: item"></ng-container>
    </ng-template>
</ng-container>
