<div class="">
  <!-- Modal Header -->
  <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        <ng-container *ngIf="isOtherGiftbox; else notEmptyCartTitle">
          {{ 'giftbox.popupAddGiftboxToCart.titleOtherGiftbox' | cxTranslate }}
        </ng-container>
        <ng-template #notEmptyCartTitle>
          {{ 'giftbox.popupAddGiftboxToCart.titleCartNotEmpty' | cxTranslate }}
        </ng-template>
      </div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="close('Dismiss')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
  </div>
  <!-- Modal Body -->
  <div class="cx-dialog-body modal-body">
    <ng-container *ngIf="isOtherGiftbox; else notEmptyCartMessage">
      {{ 'giftbox.popupAddGiftboxToCart.messageOtherGiftbox' | cxTranslate }}
    </ng-container>
    <ng-template #notEmptyCartMessage>
      {{ 'giftbox.popupAddGiftboxToCart.messageCartNotEmpty' | cxTranslate }}
    </ng-template>
    <br>
    {{ 'giftbox.popupAddGiftboxToCart.confirmation' | cxTranslate }}
  </div>
  <div class="cx-dialog-buttons">
      <div class="row p-4">
          <div class="cx-dialog-actions offset-sm-6 col-sm-3">
              <button
              type="button"
              class="btn btn-block btn-send"
              (click)="close('Dismiss')">
                  {{ 'giftbox.popupAddGiftboxToCart.cancel' | cxTranslate }}
              </button>
          </div>
          <div class="cx-dialog-actions col-sm-3">
              <button
                cxAutoFocus
                type="button"
                class="btn btn-block btn-send"
                (click)="accept()"
              >
                  {{ 'giftbox.popupAddGiftboxToCart.continue' | cxTranslate }}
              </button>
          </div>
      </div>
  </div>
</div>
