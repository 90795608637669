<div class="container">
  <cx-carousel
  [items]="getItems() | async"
  [template]="template"
  itemWidth="100%"
  class="inline-navigation"
  ></cx-carousel>
</div>

<ng-template #template let-item="item">
  <ng-container [cxComponentWrapper]="{
      flexType: item?.typeCode,
      typeCode: item?.typeCode,
      uid: item?.uid
      }"></ng-container>
</ng-template>

