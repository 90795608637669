

<div *ngIf="hasHeader" class="d-md-block d-lg-block d-xl-block">
  <div class="cx-item-list-row" *ngFor="let item of items; let i = index">
    <div
      *ngIf="getControl(item) | async as control"
      class="cx-item-list-items"
      [class.is-changed]="!displayUnitSlim &&
        featureConfigService?.isLevel('3.1')
          ? control.get('quantity').disabled
          : control.get('quantity').dirty
      "
    >
      <cx-cart-item
        [item]="item"
        [quantityControl]="control.get('quantity')"
        [readonly]="readonly"
        [promotionLocation]="promotionLocation"
        [options]="options"
        [remove]="remove && item.updateable"
        [displayUnitSlim]="displayUnitSlim"
        [warehousesCount]="warehousesCount"
      >
      </cx-cart-item>
    </div>
  </div>
</div>
