import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
  provideConfig,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { FormErrorsModule, IconModule, PageSlotModule, SpinnerModule } from '@spartacus/storefront';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HtmlSanitizeModule } from '../../../util/html-sanitize/html-sanitize.module';
import { CustomNewsletterComponent } from './custom-newsletter/custom-newsletter.component';
import { CustomNewslettersComponent } from './custom-newsletters.component';
import { CustomNewsletterAdapter } from './connectors/custom-newsletter.adapter';
import { CustomOccNewsletterAdapter } from './connectors/custom-occ-newsletter.adapter';
import { CustomNewsletterDialogComponent } from './custom-newsletter-dialog/custom-newsletter-dialog.component';
import { CustomNewsletterEffects } from './store/effects/custom-newsletter.effect';
import { customNewslettersDialogModalLayoutConfig } from './custom-newsletters.config';
import { HttpClientModule } from '@angular/common/http';
import { CustomIconsModule } from 'src/app/spartacus/custom/cms-components/misc/icon/custom-icons.module';

@NgModule({
  declarations: [CustomNewslettersComponent, CustomNewsletterComponent, CustomNewsletterDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    SpinnerModule,
    UrlModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    IconModule,
    EffectsModule.forFeature([CustomNewsletterEffects]),
    HtmlSanitizeModule,
  ],
  entryComponents: [CustomNewslettersComponent, CustomNewsletterComponent, CustomNewsletterDialogComponent],
  exports: [CustomNewslettersComponent, CustomNewsletterComponent, CustomNewsletterDialogComponent],
  providers: [
    {
      provide: CustomNewsletterAdapter,
      useClass: CustomOccNewsletterAdapter,
    },
    provideConfig(customNewslettersDialogModalLayoutConfig),
    provideConfig({
      cmsComponents: {
        CmsCustomNewsletterComponent: {
          component: CustomNewslettersComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class CustomNewslettersModule {}
