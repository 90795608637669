import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomIconsComponent } from './custom-icons.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { IconConfig } from '@spartacus/storefront';




@NgModule({
  declarations: [CustomIconsComponent],
  exports: [CustomIconsComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          SEARCH: 'icon-magnifier',
          CARET_UP: 'icon-chevron-up',
          CARET_DOWN: 'icon-chevron-down',
          CARET_RIGHT: 'icon-chevron-right',
          CARET_LEFT: 'icon-chevron-left',
          PENCIL: 'icon-pencil',
          TRASH: 'icon-trash',
          STAR: 'icon-star',
        }
      }
    } as IconConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        IconComponent: {
          component: CustomIconsComponent,
       }
      }
    } as CmsConfig)

  ]
})
export class CustomIconsModule { }
