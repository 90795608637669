<ng-container *ngIf="giftCards$ | async as giftCards">
  <ng-container *ngIf="giftCards?.length">
    <form [formGroup]="giftCardForm" class="form-wrapper-cards">
        <div class="cx-spinner" [hidden]="!loadingGiftCard">
          <cx-spinner></cx-spinner>
        </div>
        <h4 class="cx-checkout-title">
            {{ 'checkoutShipping.presentCard' | cxTranslate }}
        </h4>
        <div class="wrapper-cards">
            <ng-container *ngFor="let giftCard of giftCards">
                <div [ngClass]="giftCard.code === selectedGiftCardCode ? 'selected gift-card' : 'gift-card'">
                    <a (click)="selectGiftCard(giftCard.code, giftCard.requiresMessage)">
                        <cx-media [container]="giftCard.images[2]"></cx-media>
                        <span>{{ giftCard.price.formattedValue }}</span>
                    </a>
                </div>
            </ng-container>
        </div>

        <div class="form-group" [hidden]="!requiresMessage">
            <textarea
                (blur)="selectGiftCard()"
                [maxLength]="350"
                placeholder="{{ 'checkoutShipping.presentCardPlaceholder' | cxTranslate }}"
                formControlName="messageGiftCard"
                class="form-control"
                rows="4"
            ></textarea>
          <cx-form-errors [control]="giftCardForm.get('messageGiftCard')"></cx-form-errors>
        </div>
    </form>
  </ng-container>
</ng-container>
