import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  EventService,
  FeatureConfigService,
  isNotUndefined,
  RoutingService,
  StateWithUser,
  TranslationService,
  UserIdService
} from '@spartacus/core';
import { ICON_TYPE, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { CustomUserOrderService } from 'src/app/spartacus/custom/core/user/facade/custom-user-order.service';
import { CustomOrderActionsService } from '../custom-order-actions/custom-order-actions.service';
import { Store } from '@ngrx/store';
import { StateWithProcess } from '@spartacus/core/src/process/store/process-state';
import { CustomExtraAppConfigService } from "../../../../../../custom/config/services/custom-extra-app-config.service";
import { CustomReorderEvent } from "../../../../../features/tracking/custom-events/order/custom-order.events";
import { EcommerceType } from "../../../../../features/tracking/custom-events/tag-manager-feature.model";
import { Order, OrderHistoryFacade, OrderHistoryList, ReplenishmentOrderHistoryFacade } from '@spartacus/order/root';

@Component({
  selector: 'app-custom-order-history',
  templateUrl: './custom-order-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderHistoryComponent implements OnInit, OnDestroy {
  protected subscription = new Subscription();
  @ViewChild('open') element: ElementRef;
  allowFriends: boolean;

  constructor(
    protected routing: RoutingService,
    protected userOrderService: CustomUserOrderService,
    protected translation: TranslationService,
    protected userReplenishmentOrderService: ReplenishmentOrderHistoryFacade,
    protected modalService: LaunchDialogService,
    protected store: Store<StateWithUser | StateWithProcess<void>>,
    protected userService: UserIdService,
    protected customOrderActionsService: CustomOrderActionsService,
    protected vcr: ViewContainerRef,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    protected eventService: EventService,
    protected orderHistoryFacade: OrderHistoryFacade,
    @Optional() protected featureConfigService?: FeatureConfigService
  ) {
    this.allowFriends = customExtraAppConfigService.allowFriends();
  }

  iconTypes = ICON_TYPE;
  private PAGE_SIZE = 5;
  sortType: string;

  orders$: Observable<OrderHistoryList> = this.userOrderService
    .getOrderHistoryList(this.PAGE_SIZE)
    .pipe(
      tap((orders: OrderHistoryList) => {
        if (orders.pagination) {
          this.sortType = orders.pagination.sort;
        }
      }),
    );

  hasReplenishmentOrder$: Observable<boolean> = this.userReplenishmentOrderService
    .getReplenishmentOrderDetails()
    .pipe(map((order) => order && Object.keys(order).length !== 0));

  isLoaded$: Observable<boolean> = this.userOrderService.getOrderHistoryListLoaded();

  /**
   * When "Order Return" feature is enabled, this component becomes one tab in
   * TabParagraphContainerComponent. This can be read from TabParagraphContainer.
   */
  tabTitleParam$: Observable<number> = this.orders$.pipe(
    map((order) => order.pagination?.totalResults),
    filter(isNotUndefined),
    take(1),
  );

  filtersForm: FormGroup = new FormGroup({
    orderCode: new FormControl(''),
    startCreationTime: new FormControl(),
    endCreationTime: new FormControl(''),
    showInvoiced: new FormControl(false),
    showFriends: new FormControl(false),
  });

  showOrderCancellationModal(order: Order): void {
    //CustomOrderCancellationComponent
    var dialog = this.modalService.openDialog(
      LAUNCH_CALLER.CUSTOM_ORDER_CANCELLATION,
      undefined,
      this.vcr,
      { order }
    );
    if (dialog) {
      this.subscription.add(dialog.subscribe());
    }
  }

  showOrderRestorationModal(order: Order): void {
    //CustomOrderRestorationComponent
    var dialog = this.modalService.openDialog(
      LAUNCH_CALLER.CUSTOM_ORDER_RESTORATION,
      undefined,
      this.vcr,
      { order }
    );
    if (dialog) {
      this.subscription.add(dialog.subscribe());
    }
  }

  showOrderRestoreOnlyModal(order: Order): void {
    //CustomOrderRestoreOnlyComponent
    var dialog = this.modalService.openDialog(
      LAUNCH_CALLER.CUSTOM_ORDER_RESTORE_ONLY,
      undefined,
      this.vcr,
      { order }
    );

    if (dialog) {
      this.subscription.add(dialog.subscribe());
    }
  }

  ngOnInit(): void {
    //this.customOrderActionsService.init(this.vcr);
    this.subscription.add(
      this.filtersForm.controls.orderCode.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
        )
        .subscribe(() => this.pageChange(0))
    );
  }

  toggleCheckboxFilter(filterKey: string): void {
    this.filtersForm.get(filterKey)?.patchValue(!this.filtersForm.get(filterKey)?.value);
    this.pageChange(0);
  }

  updateDatepickerFilter(filterKey: string): void {
    this.filtersForm.get(filterKey)?.updateValueAndValidity();
    this.pageChange(0);
  }

  removeFilter(filterKeys: string[], reload: boolean = true): void {
    filterKeys.forEach(filterKey => {
      this.filtersForm.get(filterKey)?.reset();
    });
    if (reload) {
      this.pageChange(0);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.userOrderService.clearOrderList();
  }

  changeSortCode(sortCode: string): void {
    const event: { sortCode: string; currentPage: number } = {
      sortCode,
      currentPage: 0,
    };
    this.sortType = sortCode;
    this.fetchOrders(event);
  }

  pageChange(page: number): void {
    const event: { sortCode: string; currentPage: number } = {
      sortCode: this.sortType,
      currentPage: page,
    };
    this.fetchOrders(event);
  }

  goToOrderDetail(order: Order): void {
    this.routing.go({
      cxRoute: 'orderDetails',
      params: order,
    });
  }

  getSortLabels(): Observable<{ byDate: string; byOrderNumber: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
    ]).pipe(
      map(([textByDate, textByOrderNumber]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
        };
      }),
    );
  }

  private fetchOrders(event: { sortCode: string; currentPage: number }): void {
    this.userOrderService.loadOrderList(
      this.PAGE_SIZE,
      event.currentPage,
      event.sortCode,
      this.filtersForm.value,
    );
  }

  dispatchReorderEvent(sourceBtnName: string): void {
    this.eventService.dispatch<CustomReorderEvent>(
      {
        event: EcommerceType.REORDER,
        button: sourceBtnName || ''
      },
      CustomReorderEvent)
  }
}
