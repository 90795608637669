import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFilesInfoComponent } from './custom-files-info.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { HtmlSanitizeModule } from '../../../../../util/html-sanitize/html-sanitize.module';


@NgModule({
  declarations: [CustomFilesInfoComponent],
  exports: [
    CustomFilesInfoComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomFilesInfoComponent: {
          component: CustomFilesInfoComponent,
        },
      },
    } as CmsConfig),
    RouterModule,
    HtmlSanitizeModule,
  ],
})
export class CustomFilesInfoModule {}
