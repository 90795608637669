import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { CREDIT_CARD_FEATURE, CreditCard, CustomCreditCardState, Payments, StateWithCreditCards } from '../credit-card.state';


export const getCreditCardListState: MemoizedSelector<StateWithCreditCards,
  CustomCreditCardState> = createFeatureSelector<CustomCreditCardState>(CREDIT_CARD_FEATURE);

export const getCreditCardListLoaderState: MemoizedSelector<StateWithCreditCards,
  LoaderState<Payments>> = createSelector(getCreditCardListState, (state: CustomCreditCardState) => state.entities);

export const getCreditCardList: MemoizedSelector<StateWithCreditCards,
  Payments> = createSelector(getCreditCardListLoaderState, (state: LoaderState<Payments>) =>
  StateUtils.loaderValueSelector(state),
);

export const getCreditCardListLoading: MemoizedSelector<StateWithCreditCards,
  boolean> = createSelector(getCreditCardListLoaderState, (state: LoaderState<Payments>) =>
  StateUtils.loaderLoadingSelector(state),
);

export const getCreditCardListError: MemoizedSelector<StateWithCreditCards,
  boolean> = createSelector(getCreditCardListLoaderState, (state: LoaderState<Payments>) =>
  StateUtils.loaderErrorSelector(state),
);

export const getCreditCardListLoadingSuccess: MemoizedSelector<StateWithCreditCards,
  boolean> = createSelector(
  getCreditCardListLoaderState,
  (state: LoaderState<Payments>) =>
    StateUtils.loaderSuccessSelector(state) &&
    !StateUtils.loaderLoadingSelector(state),
);

export const getSaveCreditCard: MemoizedSelector<StateWithCreditCards,
  boolean> = createSelector(getCreditCardListState, (state: CustomCreditCardState) =>
  state.saveCreditCard,
);


export const getSelectedCreditCard: MemoizedSelector<StateWithCreditCards,
  CreditCard> = createSelector(getCreditCardListState, (state: CustomCreditCardState) =>
  state.selected,
);
