import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { CarouselModule, MediaModule } from '@spartacus/storefront';
import { CustomGiftboxItemModule } from 'src/app/custom/cms-components/giftbox/custom-giftbox-item/custom-giftbox-item.module';
import { CustomCarouselModule } from 'src/app/spartacus/custom/cms-structure/shared/components/custom-carousel/custom-carousel.module';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';
import { CustomProductCarouselComponent } from './custom-product-carousel.component';

@NgModule({
  imports: [
    CommonModule,
    CustomCarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    I18nModule,
    CustomGiftboxItemModule,
    CustomGenericLinkModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        ProductCarouselComponent: {
          component: CustomProductCarouselComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomProductCarouselComponent],
  entryComponents: [CustomProductCarouselComponent],
  exports: [CustomProductCarouselComponent],
})
export class CustomProductCarouselModule {}
