<div
  *ngIf="data$ | async as data"
  class="container"
>
  <div class="row">
    <div class="col-md-5">
      <div class="content">
        <h2 class="title">
          {{ data.title }}    
        </h2>
        <h3 class="subtitle">
          {{ data.subtitle }}
        </h3>
      </div>
    </div>
    <div class="col-md-7">
      <ng-container *ngIf="images$ | async as images">
        <ng-container *ngFor="let rolloverImages$ of images">
          <app-custom-hover-aware-image
            *ngIf="rolloverImages$ | async as rolloverImages"
            [rolloverImages]="rolloverImages"
          ></app-custom-hover-aware-image>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
