<div *ngFor="let entryGroup of entryGroups; let i = index" class="cx-list">
    <div class="cx-order-summary">
      <div class="col-12">
        <div class="row pt-4 {{ entryGroup.status ? ' pb-0' : 'pb-4' }}">
          <div class="col-md-6">
            <h4 class="cx-card-title mb-0">
              {{ 'orderDetails.shipping' | cxTranslate }} {{ i + 1 }}/{{ entryGroups?.length }}
            </h4>
          </div>
          <ng-container *ngIf="entryGroup?.trackingUrl">
            <div class="col-md-6 text-right">
              <a target="_blank" class="btn btn-action" [href]="entryGroup.trackingUrl" tabindex="-1">{{ 'orderDetails.consignmentTracking.button.label' | cxTranslate }}</a>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="entryGroup.status">
        <div class="container">
          <div class="">
            <cx-card class="cx-order-summary-alt" [content]="getConsignmentStatus(entryGroup?.status) | async"></cx-card>
            <ng-container *ngIf="entryGroup?.failure">
              <cx-card class="cx-order-summary-alt" [content]="getConsignmentFailure(entryGroup?.failure) | async"></cx-card>
            </ng-container>
          </div>

          <cx-card class="cx-order-summary-alt" [content]="getConsignmentDeliveryDate(entryGroup?.targetArrivalDate | cxDate: 'd/MM/yyyy', entryGroup?.statusCode) | async"></cx-card>
          <cx-card class="cx-order-summary-alt" [content]="getConsignmentCode(entryGroup.deliveryNoteCode) | async"></cx-card>
          <cx-card class="cx-order-summary-alt" [content]="getConsignmentReference(entryGroup.code) | async"></cx-card>
        </div>
      </ng-container>
    </div>



<!--  <ng-template #unconsignedTemplate>-->
<!--    &lt;!&ndash; unconsigned entries OR any entries &ndash;&gt;-->
<!--    <div class="cx-list-header col-12">-->
<!--      <div class="cx-list-status">-->
<!--        <h4 class="cx-card-title mb-0">-->
<!--          {{ 'orderDetails.shipping' | cxTranslate }} {{ i + 1 }}/{{ entryGroups?.length }}-->
<!--        </h4>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-template>-->

  <div class="cx-list-item col-12">
    <cx-cart-item-list
      [items]="entryGroup.entries"
      [readonly]="true"
      [promotionLocation]="promotionLocation"
      [remove]="false"
      [displayUnitSlim]="true"
    ></cx-cart-item-list>
  </div>
</div>
<!--</div>-->

