import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,

  I18nModule,
  provideConfig,
  UrlModule
} from '@spartacus/core';
import { CustomCheckoutStepsSetGuard } from '../../guards/custom-checkout-steps-set.guard';
import { CustomCheckoutProgressMobileBottomComponent } from './checkout-progress-mobile-bottom/custom-checkout-progress-mobile-bottom.component';
import { CustomCheckoutProgressMobileTopComponent } from './checkout-progress-mobile-top/custom-checkout-progress-mobile-top.component';
import { CustomCheckoutProgressComponent } from './custom-checkout-progress.component';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';

@NgModule({
  declarations: [CustomCheckoutProgressComponent, CustomCheckoutProgressMobileBottomComponent, CustomCheckoutProgressMobileTopComponent],
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    RouterModule,
  ],
  entryComponents: [CustomCheckoutProgressComponent, CustomCheckoutProgressMobileBottomComponent, CustomCheckoutProgressMobileTopComponent],
  exports: [CustomCheckoutProgressComponent, CustomCheckoutProgressMobileBottomComponent, CustomCheckoutProgressMobileTopComponent],
  providers: [
    provideConfig({
      cmsComponents: {
        CheckoutProgress: {
          component: CustomCheckoutProgressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CustomCheckoutStepsSetGuard],
        },
        CheckoutProgressMobileTop: {
          component: CustomCheckoutProgressMobileTopComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CustomCheckoutStepsSetGuard],
        },
        CheckoutProgressMobileBottom: {
          component: CustomCheckoutProgressMobileBottomComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CustomCheckoutStepsSetGuard],
        },
      },
    } as CmsConfig),
  ],

})
export class CustomCheckoutProgressModule { }
