import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule, provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import { CmsPageGuard, DatePickerModule, IconModule, ListNavigationModule, PageLayoutComponent } from '@spartacus/storefront';
import { CustomIconsModule } from '../../../misc/icon/custom-icons.module';
import { CustomOrderHistoryComponent } from './custom-order-history.component';
import { customOrderActionsSpinnerLayoutConfig } from '../custom-order-actions/custom-order-actions-spinner-layout.config';
import { customOrderCancellationDialogModalLayoutConfig, customOrderRestorationDialogModalLayoutConfig, customOrderRestoreOnlyDialogModalLayoutConfig } from '../custom-order.config';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'orders' },
      },
    ]),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    IconModule,
    CustomIconsModule,
    DatePickerModule,
  ],
  declarations: [CustomOrderHistoryComponent],
  exports: [CustomOrderHistoryComponent],
  providers: [
    provideConfig(customOrderActionsSpinnerLayoutConfig),
    provideConfig(customOrderCancellationDialogModalLayoutConfig),
    provideConfig(customOrderRestorationDialogModalLayoutConfig),
    provideConfig(customOrderRestoreOnlyDialogModalLayoutConfig),
    provideConfig({
      cmsComponents: {
        AccountOrderHistoryComponent: {
          component: CustomOrderHistoryComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
  ],
  entryComponents: [CustomOrderHistoryComponent],
})
export class CustomOrderHistoryModule {}
