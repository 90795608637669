import { Provider } from '@angular/core';
import { CustomPreferenceCenterService } from './custom-preference-center.service';
import { CustomPreferenceCenterFacade } from '../../root/facade/custom-preference-center.facade';

export const customFacadeProviders: Provider[] = [
  CustomPreferenceCenterService,
  {
    provide: CustomPreferenceCenterFacade,
    useExisting: CustomPreferenceCenterService,
  },
];
