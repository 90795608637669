import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  Command,
  CommandService,
  CommandStrategy,
  EventService,
  PaymentDetails,
  Query,
  QueryNotifier,
  QueryService,
  QueryState,
  StateWithProcess,
  UserIdService, WindowRef
} from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  LoadCreditCardList,
  PurgeCreditCardList,
  RemoveSelectedCreditCard,
  SaveCreditCard,
  SelectedSavedCreditCard
} from '../../../cms-components/checkout/components/payment-method/custom-credit-card/store/actions/custom-credit-card.action';
import {
  Payments,
  StateWithCreditCards, StorageSaveCreditCard
} from '../../../cms-components/checkout/components/payment-method/custom-credit-card/store/credit-card.state';
import {
  getCreditCardList,
  getCreditCardListError,
  getCreditCardListLoading,
  getCreditCardListLoadingSuccess,
  getSaveCreditCard
} from '../../../cms-components/checkout/components/payment-method/custom-credit-card/store/selectors/credit-card.selectors';
import { getSavedCreditCardSelector } from '../../cart/store/selectors/custom-multi-cart.selectors';
import { CustomActiveCartService } from '../../cart/facade/custom-active-cart.service';
import { StateWithMultiCart } from '@spartacus/cart/base/core';
import { ActiveCartFacade, Cart, PaymentType } from '@spartacus/cart/base/root';
import { StateWithCheckout } from '../../cart/store/checkout-state';
import { CheckoutPaymentTypeService } from '@spartacus/checkout/b2b/core';
import { CheckoutPaymentFacade, CheckoutQueryFacade, CheckoutQueryReloadEvent, CheckoutQueryResetEvent, CheckoutState } from '@spartacus/checkout/base/root';
import { B2BPaymentTypeEnum, CheckoutPaymentTypeFacade, CheckoutPaymentTypeSetEvent } from '@spartacus/checkout/b2b/root';
import { CustomCheckoutPaymentTypeConnector } from '../connectors/payment-type/custom-checkout-payment-type.connector';
import { CustomCheckoutPaymentTypeSetEvent } from 'src/app/spartacus/features/checkout/b2b/root/events/custom-checkout.events';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutPaymentTypeService extends CheckoutPaymentTypeService {

  private cartData = new BehaviorSubject<Cart>(undefined);

  /**
   * Returns the reload events for the checkout query.
   */
  protected getCheckoutQueryReloadEvents(): QueryNotifier[] {
    return [CheckoutQueryReloadEvent];
  }
  /**
   * Returns the reset events for the checkout query.
   */
  protected getCheckoutQueryResetEvents(): QueryNotifier[] {
    return [CheckoutQueryResetEvent];
  }

  protected getPaymenTypesQuery: Query<PaymentType[]> = this.queryService.create(
    () => this.paymentTypeConnector.getPaymentTypes(),
    {
      reloadOn: this.getCheckoutPaymentTypesQueryReloadEvents(),
      resetOn: this.getCheckoutPaymentTypesQueryResetEvents(),
    }
  );

  protected checkoutQuery: Query<CheckoutState | undefined> =
    this.queryService.create<CheckoutState | undefined>(
      () =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) =>
            this.paymentTypeConnector.getCheckoutDetails(userId, cartId)
          )
        ),
      {
        reloadOn: this.getCheckoutQueryReloadEvents(),
        resetOn: this.getCheckoutQueryResetEvents(),
      }
    );

    protected setPaymentTypeCommand: Command<
    { paymentTypeCode: string; purchaseOrderNumber?: string },
    unknown
  > = this.commandService.create<{
    paymentTypeCode: string;
    purchaseOrderNumber?: string;
  }>(
    ({ paymentTypeCode, purchaseOrderNumber }) =>
      this.checkoutPreconditions().pipe(
        switchMap(([userId, cartId]) =>
          this.paymentTypeConnector
            .setPaymentType(
              userId,
              cartId,
              paymentTypeCode,
              purchaseOrderNumber
            )
            .pipe(
              tap(() =>
                this.eventService.dispatch(
                  {
                    userId,
                    cartId,
                    paymentTypeCode,
                    purchaseOrderNumber,
                  },
                  CheckoutPaymentTypeSetEvent
                )
              )
            )
        )
      ),
    {
      strategy: CommandStrategy.CancelPrevious,
    }
  );

  setPaymentType(
    paymentTypeCode: B2BPaymentTypeEnum,
    purchaseOrderNumber?: string
  ): Observable<unknown> {
    return this.setPaymentTypeCommand.execute({
      paymentTypeCode,
      purchaseOrderNumber,
    });
  }

  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected queryService: QueryService,
    protected commandService: CommandService,
    protected paymentTypeConnector: CustomCheckoutPaymentTypeConnector,
    protected eventService: EventService,
    protected checkoutQueryFacade: CheckoutQueryFacade,
    protected checkoutStore: Store<StateWithCheckout>,
    protected creditCardStore: Store<StateWithCreditCards | StateWithProcess<void>>,
    protected storeCart: Store<StateWithMultiCart | StateWithProcess<void>>,
    protected activeCartService: CustomActiveCartService,
    protected processStateStore: Store<StateWithProcess<void>>,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected winRef?: WindowRef,
  ) {
    super(activeCartFacade, userIdService, queryService, commandService, paymentTypeConnector, eventService, checkoutQueryFacade);
  }

  setCartData(cart: Cart): void {
    this.cartData.next(cart);
  }

  getCartData(): Observable<Cart> {
    return this.cartData.asObservable();
  }

  getPaymentTypes(): Observable<PaymentType[]> {
    return this.getPaymentTypesState().pipe(map((state) => state.data ?? []));
  }

  getPaymentTypesState(): Observable<QueryState<PaymentType[] | undefined>> {
    return this.getPaymenTypesQuery.getState();
  }

  getCheckoutDetailsState(): Observable<QueryState<CheckoutState | undefined>> {
    return this.checkoutQuery.getState()
  }

  getSelectedPaymentTypeState(): Observable<QueryState<PaymentType | undefined>> {
    return this
      .getCheckoutDetailsState()
      .pipe(map((state) => ({ ...state, data: state.data?.paymentType })));
  }

  /**
   * Get payment type by code
   */
  getPaymentTypeByCode(code: string): Observable<PaymentType> {
    return this.checkoutPaymentFacade.getPaymentCardTypes().pipe(
      map((paymentTypes) => paymentTypes.filter((paymentType) => paymentType.code === code)[0],
      ));
  }

  loadCreditCardList(): void {
    this.userIdService.takeUserId()
      .subscribe(userId => this.creditCardStore.dispatch(new LoadCreditCardList(userId)))
      .unsubscribe();
  }

  getCreditCardList(): Observable<Payments> {
    return this.creditCardStore.pipe(select(getCreditCardList));
  }

  getCreditCardLoadingState(): Observable<boolean> {
    return this.creditCardStore.pipe(select(getCreditCardListLoading));
  }

  clearCreditCardList(): void {
    this.creditCardStore.dispatch(new PurgeCreditCardList());
  }

  toggleSaveCreditCard(save): void {
    this.creditCardStore.dispatch(new SaveCreditCard({ save }));
  }

  getSaveCreditCardState(): Observable<boolean> {
    return this.creditCardStore.pipe(select(getSaveCreditCard));
  }

  getSaveCreditCardStateSuccess(): Observable<boolean> {
    return this.creditCardStore.pipe(select(getCreditCardListLoadingSuccess));
  }

  getSaveCreditCardStateError(): Observable<boolean> {
    return this.creditCardStore.pipe(select(getCreditCardListError));
  }


  getPaymentDetails(): Observable<PaymentDetails> {
    let cartId: string;
    this.activeCartService
      .getActiveCartId()
      .pipe(take(1))
      .subscribe((activeCartId) => (cartId = activeCartId));
    return this.storeCart.pipe(select(getSavedCreditCardSelector(cartId)));
  }

  selectCreditCard(paymentType, paymentDetailsId): void {
    let cartId: string;
    this.activeCartService
      .getActiveCartId()
      .pipe(take(1))
      .subscribe((activeCartId) => (cartId = activeCartId));

    this.userIdService.takeUserId()
      .subscribe(userId =>
        this.creditCardStore.dispatch(new SelectedSavedCreditCard({ userId, cartId, paymentType, paymentDetailsId })),
      ).unsubscribe();
  }

  removeSelectedCreditCard(paymentType, paymentDetailsId): void {
    let cartId: string;
    this.activeCartService
      .getActiveCartId()
      .pipe(take(1))
      .subscribe((activeCartId) => (cartId = activeCartId));

    this.userIdService.takeUserId()
      .subscribe(userId =>
        this.creditCardStore.dispatch(new RemoveSelectedCreditCard({ userId, cartId, paymentType, paymentDetailsId })),
      ).unsubscribe();
  }

  saveCreditCardDefaultState(): void {
    const defaultState = this.winRef?.localStorage?.getItem(StorageSaveCreditCard.SAVE_CREDIT_CARD);
    if (defaultState) {
      this.toggleSaveCreditCard(defaultState === 'true');
    }
  }

  isAccountPayment(): Observable<boolean> {
    return this.getSelectedPaymentTypeState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data?.code === B2BPaymentTypeEnum.BANK_ACCOUNT_PAYMENT)
    );
  }
}
