import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomFriendsCheckoutAdapter } from './custom-friends-checkout.adapters';
import { CustomFriendsCheckoutModel } from '../facade/custom-friends-checkout.model';

@Injectable({
  providedIn: 'root',
})
export class CustomFriendsCheckoutConnector {
  constructor(protected adapter: CustomFriendsCheckoutAdapter) {}

  getAll(userId: string, cartId: string): Observable<CustomFriendsCheckoutModel[]> {
    return this.adapter.loadAll(userId, cartId);
  }

  add(
    userId: string,
    cartId: string,
    friendsCheckoutUid: string
  ): Observable<CustomFriendsCheckoutModel> {
    return this.adapter.add(userId, cartId, friendsCheckoutUid);
  }

  get(userId: string, cartId: string): Observable<CustomFriendsCheckoutModel> {
    return this.adapter.load(userId, cartId);
  }

  delete(userId: string, cartId: string): Observable<{}> {
    return this.adapter.delete(userId, cartId);
  }

}
