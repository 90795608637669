import { Address } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomRedsysPayment } from '../models/custom-checkout.model';
import { CheckoutAdapter } from '@spartacus/checkout/base/core';

export abstract class CustomCheckoutAdapter extends CheckoutAdapter {
  /**
   * Abstract method used to load Redsys Payment.
   *
   * @param userId The `userId` for given user
   * @param cartId The `cartId` for cart used
   */
  abstract loadRedsysPayment(
    userId: string,
    cartId: string,
    saveCard: boolean,
  ): Observable<CustomRedsysPayment>;

  /**
   * Abstract method used to Simulate orders.
   *
   * @param userId The `userId` for given user
   * @param cartId The `cartId` for cart used
   */
  abstract simulateOrder(
    userId: string,
    cartId: string,
  ): Observable<any>;

  /**
   * Abstract method used to Validate cart.
   *
   * @param userId The `userId` for given used
   * @param cartId The `cartId` for cart used
   */
  abstract validateCart(
    userId: string,
    cartId: string,
  ): Observable<any>;

  /**
   * Abstract method used to create billing address and set in cart
   *
   * @param userId
   * @param cartId
   * @param billingAddress
   */
  abstract createBillingAddress(
    userId: string,
    cartId: string,
    billingAddress: Address,
  ): Observable<any>;

  /**
   * Abstract method used to set billing address in cart
   *
   * @param userId
   * @param cartId
   * @param billingAddressId
   */
  abstract setBillingAddress(
    userId: string,
    cartId: string,
    billingAddressId: string,
  ): Observable<any>;

  /**
   * Abstract method used to unset billing address from cart
   *
   * @param userId
   * @param cartId
   */
  abstract unsetBillingAddress(
    userId: string,
    cartId: string,
  ): Observable<any>;
}
