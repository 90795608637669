import { Injectable } from '@angular/core';
import { UserActions, UserAddressService } from '@spartacus/core';
import { CustomUserActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class CustomUserAddressService extends UserAddressService {
  /**
   * Retrieves delivery countries
   */
  loadDeliveryCountriesAll(): void {
    this.store.dispatch(new CustomUserActions.CustomLoadDeliveryCountriesAll());
  }

  /**
   * Clear countries and titles in store
   */
  clearUserMiscData(): void {
    this.store.dispatch(new UserActions.ClearUserMiscsData());
  }

  /**
   * Retrieves user's addresses from basestore only.
   * To get addresses from all basestores use 'loadAddresses' method instead.
   */
  loadAddressesBasestore(): void {
    let userId: string;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    this.store.dispatch(new CustomUserActions.CustomLoadUserAddressBasestore(userId));
  }
}
