import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';

@Injectable({
  providedIn: 'root',
})
export class CustomGiftBoxService {
  constructor(
    protected activeCartService: CustomActiveCartService,
  ) {}


  hasProductsCart(): boolean {
   return;
 }
}
