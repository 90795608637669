<ng-container *ngIf="siblingProduct || product as currentProduct">
  <app-custom-product-labels-items-view
    [labels]="currentProduct.labelProduct">
  </app-custom-product-labels-items-view>
  <a (click)="dispatchSelectEvent()"
    [routerLink]="{ cxRoute: 'product', params: this.product} | cxUrl"
    [queryParams]="getQueryParams()"
    [state]="(navigationExtras$ | async).state"
    tabindex="-1" class="cx-product-image-container"
  >
    <div class="cx-product-image-slots">
      <cx-media
        class="cx-product-image"
        [container]="currentProduct.images?.PRIMARY"
        format="currentProduct"
        [alt]="currentProduct.summary"
      ></cx-media>
      <ng-container *ngIf="isGiftboxWizard$ | async">
        <span
          *ngIf="currentProduct.numberOfSlots"
          class="cx-product-slots"
        >{{ 'productDetails.slots' | cxTranslate: { count: product.numberOfSlots } }}</span> </ng-container>
    </div>
    <h2
      class="cx-product-name"
      [innerHtml]="currentProduct.nameHtml || currentProduct.name">
    </h2>
  </a>
</ng-container>

<!-- <div class="cx-product-rating">
  <cx-star-rating
    *ngIf="product.averageRating"
    [rating]="product?.averageRating"
  ></cx-star-rating>
  <div *ngIf="!product.averageRating">
    {{ 'productDetails.noReviews' | cxTranslate }}
  </div>
</div>
<div class="cx-product-price-container">
  <div class="cx-product-price" aria-label="Product price">
    {{ product.price?.formattedValue }}
  </div>
</div>
<div class="cx-variant-style-icons" *ngIf="product.variantOptions">
  <cx-variant-style-icons
    [variants]="product.variantOptions"
  ></cx-variant-style-icons>
</div>-->

<ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
  <cx-add-to-cart
    [showQuantity]="false"
    [product]="product"
    [hideStockInfo]="true"
    [showNostockText]="true"
    [showAddToCartText]="true"
    [displayDefaultUnitOnly]="isGiftboxWizard$ | async"
    (unitChanged)="handleUnitChange($event)"
  ></cx-add-to-cart>
</ng-template>
