import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAgeStatusComponent } from './custom-age-status.component';
import { I18nModule } from '@spartacus/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AGE_STATUS_FEATURE } from './store/custom-age.state';
import { customAgeStatusReducerProvider, reducerToken } from './store/reducers/custom-age-status.reducer';
import { CustomAgeStatusEffect } from './store/effects/custom-age-status.effect';
import { MediaModule } from '@spartacus/storefront';


@NgModule({
  declarations: [CustomAgeStatusComponent],
  exports: [CustomAgeStatusComponent],
  imports: [
    CommonModule,
    I18nModule,
    StoreModule.forFeature(AGE_STATUS_FEATURE, reducerToken),
    EffectsModule.forFeature([CustomAgeStatusEffect]),
    MediaModule,
  ],
  providers: [customAgeStatusReducerProvider],
})
export class CustomAgeStatusModule {}
