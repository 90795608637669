<form
  (ngSubmit)="onSubmit()"
  [formGroup]="csAgentLoginForm"
  *ngIf="!csAgentTokenLoading"
>
  <label>
    <input
      type="text"
      formControlName="userId"
      placeholder="{{ 'asm.loginForm.userId.label' | cxTranslate }}"
    />
    <cx-form-errors [control]="csAgentLoginForm.get('userId')"></cx-form-errors>
  </label>

  <label>
    <input
      type="password"
      placeholder="{{ 'asm.loginForm.password.label' | cxTranslate }}"
      formControlName="password"
    />
    <cx-form-errors
      [control]="csAgentLoginForm.get('password')"
    ></cx-form-errors>
  </label>
  <button type="submit">
    {{ 'asm.loginForm.submit' | cxTranslate }}
  </button>
</form>

<div
  *ngIf="csAgentTokenLoading"
  class="spinner"
  aria-hidden="false"
  aria-label="Loading"
>
  <div></div>
  <div></div>
  <div></div>
</div>
