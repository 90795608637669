<ng-container *ngIf="form$ | async as form">
  <cx-amend-order-items *ngIf="entries$ | async as entries" [entries]="entries">
  </cx-amend-order-items>

  <cx-form-errors [control]="form.get('entries')"></cx-form-errors>

  <cx-amend-order-actions
    *ngIf="orderCode"
    [orderCode]="orderCode"
    [amendOrderForm]="form"
    backRoute="orderDetails"
    forwardRoute="orderReturnConfirmation"
  ></cx-amend-order-actions>
</ng-container>
