import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges,} from '@angular/core';
import {ProductListOutlets} from '@spartacus/storefront';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProductListItemContextSource} from '../model/product-list-item-context-source.model';
import {ProductListItemContext} from '../model/product-list-item-context.model';
import {ProductListComponentService} from '../container/product-list-component.service';
import {
  CustomProductDetailsEventService
} from "../../../../../features/tracking/custom-events/product/custom-product-details-event.service";
import {ProductUnitPrice} from "../../../../../../model/unit-price";
import {NavigationExtras} from "@angular/router";
import { Product } from '@spartacus/core';

@Component({
  selector: 'cx-product-list-item',
  templateUrl: './product-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class ProductListItemComponent implements OnChanges {
  readonly ProductListOutlets = ProductListOutlets;
  @Input() product: any;
  @Input() category?: string;
  @Input() index?: number;

  isGiftboxWizard$: Observable<boolean>;
  navigationExtras$: BehaviorSubject<NavigationExtras> = new BehaviorSubject<NavigationExtras>({queryParams: {}, state: {}});

  siblingProduct: Product | undefined;
  // TODO(#10946): make ProductListItemContextSource a required dependency
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  constructor(
    protected productListComponentService: ProductListComponentService,
    protected productListItemContextSource?: ProductListItemContextSource,
    protected customProductDetailsEventService?: CustomProductDetailsEventService,
    ){
      this.isGiftboxWizard$ = this.productListComponentService.isGiftboxWizard$;
    }

  ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.product) {
      this.productListItemContextSource?.product$.next(this.product);
    }
  }

  dispatchSelectEvent(): void {
    this.customProductDetailsEventService.dispatchProductDetailSelectEvent(this.product, 'pageList', this.category, this.index);
  }

  handleUnitChange(productUnit: ProductUnitPrice): void {
    if (productUnit) {
      const option = {
        state: {
          unit: productUnit.unitPrice.unit.code,
          productCode: this.product.code
        },
      }

      this.siblingProduct = (productUnit.product.code !== this.product.variantCode) && (productUnit.product.code !== this.product.code) ? productUnit.product : undefined;
      if(this.getQueryParams()){Object.assign(option, this.getQueryParams())}
      this.navigationExtras$.next(option);
    }
  }

  getQueryParams(): any {
    return this.siblingProduct ? {"item":this.siblingProduct.code} : undefined
  }
}
