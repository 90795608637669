import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {CmsService, isNotUndefined, Product, ProductScope, ProductService} from '@spartacus/core';
import {CmsComponentData, ICON_TYPE} from '@spartacus/storefront';
import {CustomBannerAndProductsGridModel} from './custom-banner-and-products-grid.model';
import {concatMap, filter, map, switchMap, take, tap, toArray, withLatestFrom} from 'rxjs/operators';
import {combineLatest, forkJoin, Observable, of, Subscription} from 'rxjs';
import {
  CustomCmsEventService
} from "../../../../spartacus/features/tracking/custom-events/cms/custom-cms-event.service";
import {
  CustomPromotionComponentModel
} from "../../../../spartacus/features/tracking/custom-events/cms/custom-cms.model";

@Component({
  selector: 'app-custom-banner-and-products-grid',
  templateUrl: './custom-banner-and-products-grid.component.html',
  styleUrls: ['./custom-banner-and-products-grid.component.scss'],
})
export class CustomBannerAndProductsGridComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  iconTypes = ICON_TYPE;
  loading = true;

  private subscriptions: Subscription = new Subscription();
  private isPromotional: boolean = false;
  private customPromotionComponentModel: CustomPromotionComponentModel = {
    name: '',
    slot: '',
    promotion: '',
    products: []
  };

  data$: Observable<CustomBannerAndProductsGridModel> = this.componentData.data$.pipe(
    tap((data) => {
      this.initComponent(data);
    }),
  );

  products$: Observable<Observable<Product | null>[]> =
    this.componentData.data$.pipe(
      map((data) =>
        data?.products?.trim().split(' ').map((codes) =>
          this.cmsService.getComponentData(codes).pipe(
            switchMap((product: any) => {
              return this.isProductLink(product?.url) ?
                this.productService.get(this.productCodeFromLink(product?.url), ProductScope.DETAILS) :
                of(null);
            }),
            filter(isNotUndefined),
          ),
        ),
      ),
    );

    private isProductLink(url: string): boolean {
      return Boolean(url?.match(/^\/?(p|product)\/./));
    }

    private productCodeFromLink(url: string): string {
      return url?.replace(/^\/?(p|product)\//, '');
    }

  constructor(
    protected componentData: CmsComponentData<CustomBannerAndProductsGridModel>,
    protected cmsService: CmsService,
    protected productService: ProductService,
    protected customCmsEventService: CustomCmsEventService
  ) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      combineLatest([this.data$, this.products$]).pipe(
        tap(([data, productObservables]) => {
          if (this.getPromotional(data)) {
            forkJoin(
              ...productObservables.map((productObservable) =>
                productObservable.pipe(
                  take(1),
                  concatMap((product) => of(product || null)),
                  toArray()
                )
              )
            ).pipe(
              withLatestFrom(this.cmsService.getCurrentPage().pipe(
                take(1),
                map((page) => {
                    for (const slot in page.slots) {
                      if (page.slots[slot].components?.filter(component => component.uid === data.uid).length) {
                        return slot;
                      }
                    }
                    return '';
                  }
                )
              ))
            ).subscribe(([products, slotName]) => {
              const flattenedProducts = products
                .filter(isNotUndefined)
                .flat()
                .filter(isNotUndefined);

              this.customPromotionComponentModel = {
                name: data?.uid,
                promotion: data?.name,
                slot: slotName || '',
                products: flattenedProducts || []
              }

              this.customCmsEventService.dispatchViewPromotionEvent(this.customPromotionComponentModel);
            });

            this.isPromotional = true;
          }
        })
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initComponent(item: CustomBannerAndProductsGridModel): void {
    this.styleClasses = item?.styleClasses ?? '';
    this.backgroundColor = item?.backgroundColorCode ?? '';
    if (item?.invertColumn === true || item?.invertColumn === 'true') {
      this.styleClasses += ' invert';
    }
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
  }

  handleClick(): void {
    if (this.isPromotional) this.customCmsEventService.dispatchSelectPromotionEvent(this.customPromotionComponentModel);
  }

  getPromotional(item: CustomBannerAndProductsGridModel): boolean {
    return (item.promotional === true || item.promotional === 'true')
  }
}
