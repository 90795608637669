import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import {CustomCheckoutPaymentService} from "../../../../core/checkout/facade/custom-checkout-payment.service";
import { OrderFacade, OrderHistoryFacade } from '@spartacus/order/root';
import { EventService, TranslationService } from '@spartacus/core';
import { OrderConfirmationShippingComponent, OrderDetailsService } from '@spartacus/order/components';
import { CustomCheckoutEventBuilder } from 'src/app/spartacus/features/tracking/custom-events/checkout/place-order/custom-checkout-event.builder';
import { filter, take } from 'rxjs/operators';
//import { OrderDetailShippingComponent, OrderDetailsService } from '@spartacus/order/components';

@Component({
  selector: 'cx-order-confirmation-overview',
  templateUrl: './custom-order-confirmation-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderConfirmationShippingComponent extends OrderConfirmationShippingComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  displayOrderbyCode: boolean;
  isOrderLoading$: Observable<boolean>;

  constructor(
    protected checkoutService: CustomCheckoutPaymentService,
    private activatedRoute: ActivatedRoute,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected eventService: EventService,
    protected orderDetailsService: OrderDetailsService,
    protected orderFacade: OrderFacade,
    protected translationService: TranslationService,
    protected cd: ChangeDetectorRef,
    private customCheckoutEventBuilder?: CustomCheckoutEventBuilder,
  ) {
    super(orderFacade, translationService, cd);

    this.displayOrderbyCode = !!this.activatedRoute.snapshot.url[1]?.path; 
  }

  ngOnInit(): void {
    this.order$ = this.orderDetailsService.getOrderDetails();

    this.isOrderLoading$ =
      typeof this.orderDetailsService.isOrderDetailsLoading === 'function'
        ? this.orderDetailsService.isOrderDetailsLoading()
        : of(false);

        this.subscription.add(
          this.order$.pipe(
            filter((order) => !!order))
            .subscribe(order => {
              if (order && Object.keys(order).length != 0) {
                this.customCheckoutEventBuilder.dispatchPurchaseModeCompleteEvent(order)
                  .pipe(take(1))
                  .subscribe(() => {
                    // console.log('Purchase do order confirmation event complete')
                  });
              }
            })
          )
        
  }

  ngOnDestroy(): void {
    if (this.displayOrderbyCode) {
      this.orderHistoryFacade.clearOrderDetails();
    } else {
      this.checkoutService.clearCheckoutData();
    }
  }
}
