import { NgModule } from '@angular/core';
import { RecaptchaComponent } from './recaptcha.component';
import { RecaptchaAdapters } from './connector/recaptcha-adapters';
import { OccRecaptchaAdapter } from './occ-recaptcha-adapter/recaptcha.adapter';
import { RecaptchaDirective } from './recaptcha.directive';


@NgModule({
  declarations: [RecaptchaComponent, RecaptchaDirective],
  exports: [RecaptchaComponent, RecaptchaDirective],
  providers: [
    {
      provide: RecaptchaAdapters,
      useClass: OccRecaptchaAdapter,
    },
  ],
})
export class RecaptchaModule {}
