import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GiftCard } from '../../checkout/custom-gift-card/model/custom-gift-card.model';
import {ActiveCartFacade} from "@spartacus/cart/base/root";
import {Cart} from "@spartacus/cart/base/root";

@Component({
  selector: 'app-custom-gift-cards-details',
  templateUrl: './custom-gift-cards-details.component.html',
  styleUrls: ['./custom-gift-cards-details.component.scss']
})
export class CustomGiftCardsDetailsComponent implements OnInit{

  @Input() giftCardProduct: GiftCard;
  cart$: Observable<Cart>;

  constructor(
    protected activeCartService: ActiveCartFacade
  ) {}

  ngOnInit(): void {
    this.cart$ = this.activeCartService.getActive();
  }

}
