import { Component, OnDestroy } from '@angular/core';
import { fadeIn, fadeOut, rotatedState } from 'src/app/custom/custom-animations';
import { CustomCheckoutPaymentTypeService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment-type.service';
import { CreditCard } from '../custom-credit-card/store/credit-card.state';
import { Observable, Subscription } from 'rxjs';
import { LaunchDialogService } from '@spartacus/storefront';


@Component({
  selector: 'cx-payment-type-modal',
  templateUrl: './custom-payment-type-modal.component.html',
  animations: [fadeIn, fadeOut, rotatedState],
})
export class CustomPaymentTypeModalComponent implements OnDestroy {
  subscriptions = new Subscription();
  creditCard: CreditCard;
  paymentType: string;
  isLoading$: Observable<boolean>;
  constructor(
    protected paymentTypeService: CustomCheckoutPaymentTypeService,
    private modalService: LaunchDialogService,
  ) {
    this.isLoading$ = this.paymentTypeService.getCreditCardLoadingState();

    this.subscriptions.add(
      this.modalService.data$.subscribe((data) => {
        this.creditCard = data.creditCard;
        this.paymentType = data.paymentType;
      })
    );
  }

  dismissModal(reason?): void {
    this.modalService.closeDialog(reason);
  }

  deleteCard(paymentDetailsId): void {
    this.paymentTypeService.removeSelectedCreditCard(this.paymentType, paymentDetailsId);
    this.dismissModal(this.paymentType);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
