import { LayoutConfig, DIALOG_TYPE } from "@spartacus/storefront";
import { CustomModalComponent } from "../custom-modal/custom-modal.component";

export const customDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        BOX_LINK_IMAGE_ITEM: {
          inline: true,
          component: CustomModalComponent,
          dialogType: DIALOG_TYPE.DIALOG,
       },
    },
  };