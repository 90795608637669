import { CustomOrderEntry } from "src/app/spartacus/custom/feature-libs/cart/root/models/cart.model";
import { GiftCard } from "../../../../../../custom/cms-components/checkout/custom-gift-card/model/custom-gift-card.model";
import { ProductItem } from "../../tag-manager-feature.model";
import { EntryGroup } from 'src/app/model/entry-groups.model';

export function getProductItems(entries: CustomOrderEntry[], giftCardProduct?: GiftCard): ProductItem[] {
  const entryList: ProductItem[] = entries?.map((entry: CustomOrderEntry): ProductItem => {
    return {
      item_name: entry.product.name || entry.product.code,
      item_id: entry.product.code,
      price: entry.basePrice.value.toString(),
      item_brand: entry.product.brands ? entry.product.brands[0].name : '',
      item_category: entry.product.categories ? entry.product.categories[0]?.name || '' : '',
      item_category2: entry.product.categories ? entry.product.categories[1]?.name || '' : '',
      item_category3: entry.product.categories ? entry.product.categories[2]?.name || '' : '',
      item_variant: entry.unit?.name ? entry.unit.name : '',
      quantity: entry.quantity
    };
  }) || [];

  if (giftCardProduct?.code) {
    entryList.push({
      item_name: giftCardProduct.name,
      item_id: giftCardProduct.code,
      price: giftCardProduct.price.value.toString(),
      item_brand: '',
      item_category: 'Gift Card',
      item_variant: '',
      quantity: 1,
    })
  }

  return entryList;
}

export function getOrderProductItems(entries: CustomOrderEntry[], giftCardProduct?: GiftCard): ProductItem[] {

  const nonPackProductEntryList: CustomOrderEntry[] = []
  const packProductEntryDict = {} as { [key: string]: CustomOrderEntry };
  const PACK_PRODUCT_ITEM_VARIANT = 'KIT';

  entries?.forEach((entry: CustomOrderEntry): void => {
    // group entries by packProduct code in packProductEntryDict if packProduct exists
    entry.packProduct?.code ? packProductEntryDict[entry.packProduct.code] = entry : nonPackProductEntryList.push(entry);
  });

  const packProductEntryList: ProductItem[] = Object.entries(packProductEntryDict).map(([key, entry]) => ({
    item_name: entry.packProduct.name || key,
    item_id: key,
    price: entry.packProduct.price.value.toString(),
    item_brand: entry.packProduct.brands ? entry.packProduct.brands[0].name : '',
    item_category: entry.product.categories ? entry.product.categories[0]?.name || '' : '',
    item_category2: entry.product.categories ? entry.product.categories[1]?.name || '' : '',
    item_category3: entry.product.categories ? entry.product.categories[2]?.name || '' : '',
    item_variant: PACK_PRODUCT_ITEM_VARIANT,
    quantity: entry.packProductReferenceQuantity
  }));

  return packProductEntryList.concat(getProductItems(nonPackProductEntryList, giftCardProduct));
}
