import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductIntroComponent } from './custom-product-intro.component';
import { ConfigModule, CmsConfig, I18nModule } from '@spartacus/core';
import { OutletModule, PromotionsModule, StarRatingModule } from '@spartacus/storefront';
import {CustomPaypalInstallmentsModule} from "../../../../../custom/cms-components/paypal/custom-paypal-installments/custom-paypal-installments.module";
import { CustomPromotionsModule } from '../promotions/promotions.module';

@NgModule({
  declarations: [CustomProductIntroComponent],
  imports: [
    CommonModule,
    I18nModule,
    OutletModule,
    StarRatingModule,
    PromotionsModule,
    CustomPaypalInstallmentsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductIntroComponent: {
          component: CustomProductIntroComponent
        }
      }
    } as CmsConfig),
    CustomPromotionsModule,
  ]
})
export class CustomProductIntroModule { }
