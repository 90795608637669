import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, OnDestroy, Renderer2, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HamburgerMenuService, ICON_TYPE, NavigationNode } from '@spartacus/storefront';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'cx-navigation-ui',
  templateUrl: './custom-category-navigation-ui.component.html',
})
export class CustomCategoryNavigationUiComponent implements OnInit, OnDestroy {
  /**
   * The navigation node to render.
   */
  @Input() node: NavigationNode;

  /**
   * The number of child nodes that must be wrapped.
   */
  @Input() wrapAfter: number;
  /**
   * the icon type that will be used for navigation nodes
   * with children.
   */
  iconType = ICON_TYPE;

  /**
   * Indicates whether the navigation should support flyout.
   * If flyout is set to true, the
   * nested child navigation nodes will only appear on hover or focus.
   */
  @Input() @HostBinding('class.flyout') flyout = true;

  @Input() @HostBinding('class.is-open') isOpen = false;

  private openNodes: HTMLElement[] = [];
  private subscriptions = new Subscription();
  private resize = new EventEmitter();

  @HostListener('window:resize')
  onResize(): void {
    this.resize.next();
  }

  constructor(
    private renderer: Renderer2,
    private elemRef: ElementRef,
    protected breakpointService?: BreakpointService,
    protected hamburgerMenuService?: HamburgerMenuService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.resize.pipe(debounceTime(50)).subscribe(() => {
        this.alignWrappersToRightIfStickOut();
      })
    );
  }

  toggleOpen(event: UIEvent): void {
    // Toggle only if is tablet or mobile where there is menu
    let isUpBreakpointLg: boolean;
    this.isUpBreakpointLg()
      .subscribe(
        data => isUpBreakpointLg = data
      )
      .unsubscribe();
    if (!isUpBreakpointLg) {
      if (event.type === 'keydown') {
        event.preventDefault();
      }
      const node = event.currentTarget as HTMLElement;
      if (this.openNodes.includes(node)) {
        if (event.type === 'keydown') {
          this.back();
        } else {
          this.openNodes = this.openNodes.filter((n) => n !== node);
          this.renderer.removeClass(node, 'is-open');
        }
      } else {
        this.openNodes.push(node);
      }
      this.updateClasses(this.isLink(event));
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }

  private isLink(event: UIEvent): any {
    return (event.target as HTMLElement).closest('a');
  }

  back(): void {
    if (this.openNodes[this.openNodes.length - 1]) {
      this.renderer.removeClass(
        this.openNodes[this.openNodes.length - 1],
        'is-open'
      );
      this.openNodes.pop();
      this.updateClasses();
    }
  }

  onMouseEnter(event: MouseEvent): void {
    this.alignWrapperToRightIfStickOut(event.currentTarget as HTMLElement);
    this.focusAfterPreviousClicked(event);
  }

  getTotalDepth(node: NavigationNode, depth = 0): number {
    if (node.children && node.children.length > 0) {
      return Math.max(
        ...node.children.map((n) => this.getTotalDepth(n, depth + 1))
      );
    } else {
      return depth;
    }
  }

  getColumnCount(length: number): number {
    return Math.round(length / (this.wrapAfter || length));
  }

  focusAfterPreviousClicked(event: MouseEvent): Document {
    const target: HTMLElement = (
      (event.target || event.relatedTarget)
    ) as HTMLElement;
    if (
      target.ownerDocument.activeElement.matches('nav[tabindex]') &&
      target.parentElement.matches('.flyout')
    ) {
      target.focus();
    }
    return target.ownerDocument;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  private alignWrapperToRightIfStickOut(node: HTMLElement): void {
    const wrapper = node.querySelector('.wrapper') as HTMLElement;
    const body = node.closest('body') as HTMLElement;
    if (wrapper) {
      this.renderer.removeStyle(wrapper, 'margin-left');
      if (
        wrapper.offsetLeft + wrapper.offsetWidth >
        body.offsetLeft + body.offsetWidth
      ) {
        this.renderer.setStyle(
          wrapper,
          'margin-left',
          `${node.offsetWidth - wrapper.offsetWidth}px`
        );
      }
    }
  }

  private alignWrappersToRightIfStickOut() {
    const navs = this.elemRef?.nativeElement?.childNodes as HTMLCollection;
    Array.from(navs)
      .filter((node) => node.tagName === 'NAV')
      .forEach((nav) => this.alignWrapperToRightIfStickOut(nav as HTMLElement));
  }

  private updateClasses(reset = false): void {
    this.openNodes.forEach((node, i) => {
      if (reset) {
        this.renderer.removeClass(node, 'is-opened');
        this.renderer.removeClass(node, 'is-open');
      } else if (i + 1 < this.openNodes.length) {
        this.renderer.addClass(node, 'is-opened');
        this.renderer.removeClass(node, 'is-open');
      } else {
        this.renderer.removeClass(node, 'is-opened');
        this.renderer.addClass(node, 'is-open');
      }
    });
    if (reset) {
      this.openNodes = [];
      this.hamburgerMenuService.toggle(true);
    }
    this.isOpen = this.openNodes.length > 0;
  }

   isUpBreakpointLg(): Observable<boolean> {
    return this.breakpointService?.isUp(BREAKPOINT.lg);
  }
}
