import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { ICON_TYPE } from '@spartacus/storefront';
import { Cart, PromotionResult, PromotionLocation} from '@spartacus/cart/base/root';

@Component({
  selector: 'app-custom-mini-cart-preview',
  templateUrl: './custom-mini-cart-preview.component.html',
})
export class CustomMiniCartPreviewComponent implements OnInit {
  /** Emits when the preview must be closed */
  @Output() closePreview = new EventEmitter();

  iconTypes = ICON_TYPE;
  cart$: Observable<Cart>;
  orderPromotions$: Observable<PromotionResult[]>;
  orderAppliedPromotions$: Observable<PromotionResult[]>;
  promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;

  constructor(
    protected activeCartService: CustomActiveCartService
  ) {}

  ngOnInit(): void {
    this.cart$ = this.activeCartService.getActive();
    this.cart$.subscribe(cart => {
      this.orderPromotions$ = new Observable<PromotionResult[]>(obs => {
        obs.next(cart.potentialOrderPromotions)
      });
      this.orderAppliedPromotions$ = new Observable<PromotionResult[]>(obs => {
        obs.next(cart.appliedOrderPromotions)
      });
    });
  }

  close(): void {
    this.closePreview.emit(false);
  }
}
