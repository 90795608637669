import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAddToCartComponent } from './custom-add-to-cart.component';
import { CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule, ItemCounterModule, KeyboardFocusModule, PromotionsModule, SpinnerModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CustomAddToCartUnitComponent } from './custom-add-to-cart-unit/custom-add-to-cart-unit.component';
import { CustomAddedToCartDialogComponent } from './custom-added-to-cart-dialog/custom-added-to-cart-dialog.component';
import { CustomCartSharedModule } from '../cart-shared/custom-cart-shared.module';
import { CustomUnitDisplayModule } from 'src/app/custom/cms-components/content/custom-unit-display/custom-unit-display.module';
import {
    CustomUnitSelectorModule
} from "../../../../../custom/cms-components/content/custom-unit-selector/custom-unit-selector.module";
import { customAddedToCartDialogModalLayoutConfig, customAddedToCartGiftBoxDialogModalLayoutConfig, customDeletePaymentTypeDialogModalLayoutConfig } from '../custom-cart.config';
import { CustomPromotionsModule } from '../../product/promotions/promotions.module';

@NgModule({
  declarations: [CustomAddToCartComponent, CustomAddToCartUnitComponent, CustomAddedToCartDialogComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CustomCartSharedModule,
        RouterModule,
        SpinnerModule,
        PromotionsModule,
        FeaturesConfigModule,
        UrlModule,
        IconModule,
        I18nModule,
        ItemCounterModule,
        KeyboardFocusModule,
        CustomUnitDisplayModule,
        CustomPromotionsModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ProductAddToCartComponent: {
                    component: CustomAddToCartComponent,
                }
            }
        } as CmsConfig),
        CustomUnitSelectorModule
    ],
    providers: [
        provideConfig(customAddedToCartDialogModalLayoutConfig),
        provideConfig(customAddedToCartGiftBoxDialogModalLayoutConfig),
        provideConfig(customDeletePaymentTypeDialogModalLayoutConfig)
    ],
  exports: [CustomAddToCartComponent, CustomAddToCartUnitComponent, CustomAddedToCartDialogComponent],
})
export class CustomAddToCartModule { }
