import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Config, ConfigModule, SemanticPathService, provideConfig } from '@spartacus/core';
import { CustomSemanticPathService } from './custom-semantic-path.service';
import { CustomI18nRoutingConfig } from './custom-i18n-routing.config';
import { generateRoutingConfig } from './custom-localized-routing.utils';
import { CustomUrlLocalizedService } from './custom-url-localized-service';

const i18nRoutingConfig: CustomI18nRoutingConfig = {
  i18nRouting: {
    product: {
      en: {
        paths: [
          'shop-online/product/:productCode/:slug',
          'shop-online/product/:productCode/:name',
          'shop-online/product/:productCode',
        ],
      },
      es: {
        paths: [
          'tienda-online/product/:productCode/:slug',
          'tienda-online/product/:productCode/:name',
          'tienda-online/product/:productCode',
        ],
      },
      fr: {
        paths: [
          'boutique-online/product/:productCode/:slug',
          'boutique-online/product/:productCode/:name',
          'boutique-online/product/:productCode',
        ],
      }
    },
    // plp routes
    search: {
      en: {
        paths: ['shop-online/search/:query']
      },
      es: {
        paths: ['tienda-online/search/:query']
      },
      fr: {
        paths: ['boutique-online/search/:query']
      },
    },
    category: {
      en: {
        paths: [
          'shop-online/category/:categoryCode',
          'shop-online/c/:categoryCode'
        ],
      },
      es: {
        paths: [
          'tienda-online/category/:categoryCode',
          'tienda-online/c/:categoryCode'
        ],
      },
      fr: {
        paths: [
          'boutique-online/category/:categoryCode',
          'boutique-online/c/:categoryCode'
        ],
      }
    },
    brand: {
      en: {
        paths: ['shop-online/Brands/:brandName/c/:brandCode']
      },
      es: {
        paths: ['tienda-online/Brands/:brandName/c/:brandCode']
      },
      fr: {
        paths: ['boutique-online/Brands/:brandName/c/:brandCode']
      },
    },
  },
};

@NgModule({
  declarations: [],
  providers: [
    {
      provide: CustomI18nRoutingConfig,
      useExisting: Config
    },
    {
      provide: SemanticPathService,
      useExisting: CustomSemanticPathService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (sps: CustomSemanticPathService) => () => sps.initialize(),
      deps: [CustomSemanticPathService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (uls: CustomUrlLocalizedService) => () => uls.initialize(),
      deps: [CustomUrlLocalizedService],
      multi: true,
    },
    provideConfig(generateRoutingConfig(i18nRoutingConfig))
  ],
})
export class CustomLocalizedRoutingModule {}
