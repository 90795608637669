import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Wishlist, WishlistModification } from 'src/app/model/wishlist.model';
import { CustomWishlistAdapter } from './custom-wishlist.adapter';

@Injectable({
  providedIn: 'root',
})
export class CustomWishlistConnector {
  constructor(protected adapter: CustomWishlistAdapter) { }

  public get(userId: string): Observable<Wishlist> {
    return this.adapter.load(userId);
  }

  public addToWishlist(
    userId: string,
    productCode: string,
  ): Observable<WishlistModification> {
    return this.adapter.addToWishlist(userId, productCode);
  }

  public removeFromWishlist(
    userId: string,
    productCode: string,
  ): Observable<WishlistModification> {
    return this.adapter.removeFromWishlist(userId, productCode);
  }
}
