<ng-container *ngIf="(data$ |async) as data">
  <div class="container">
    <ng-container *ngIf="data?.title">
      <h3 [innerHTML]="data.title" class="title"></h3>
    </ng-container>
    <ng-container *ngIf="data?.columnWithFileGroup">
      <ng-container *ngIf="columnWithFilesList$ | async as columnWithFiles">
        <ng-container *ngIf="columnWithFiles?.length">
          <div class="content">
            <div class="row">
              <ng-container *ngFor="let column of columnWithFiles">
                <ng-container *ngIf="column | async as columnWithFile">
                  <div [class]="'mb-4 col-12 col-md-'+columns.md+' col-lg-'+ columns.lg">
                    <app-custom-columns-with-file-list-group
                      [columnsWithFileListGroup]="columnWithFile"
                    >
                    </app-custom-columns-with-file-list-group>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
