<ng-container *ngIf="cart$ | async as cart">
  <cx-order-summary [cart]="cart"></cx-order-summary>
  <app-custom-paypal-installments [installmentsAmount]="cart.totalPrice?.value"></app-custom-paypal-installments>
  <button
    *ngIf="cart.entries.length"
    [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
    class="btn btn-primary btn-block"
    type="button"
  >
    {{ 'cartDetails.proceedToCheckout' | cxTranslate }}
  </button>
</ng-container>
