import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { SemanticPathService, StateWithProcess, StateWithUser } from '@spartacus/core';
import { Store } from '@ngrx/store';
import { CustomMultiCartService } from '../../../core/cart/facade/custom-multi-cart.service';
import { OrderFacade, OrderHistoryFacade } from '@spartacus/order/root';
import { OrderConfirmationGuard } from '@spartacus/order/components';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderConfirmationGuard extends OrderConfirmationGuard {
  constructor(
    router: Router,
    semanticPathService: SemanticPathService,
    private orderHistoryFacade: OrderHistoryFacade,
    protected userStore: Store<StateWithUser | StateWithProcess<void>>,
    protected multiCartService: CustomMultiCartService,
    protected orderFacade: OrderFacade,
  ) {
    super(orderFacade, router, semanticPathService);
  }

  canActivate(): Observable<boolean | UrlTree> {
    const router: any = this.router;
    const orderCode = router?.browserUrlTree?.root?.children?.primary?.segments[0]?.path === 'order-confirmation' ?
      router?.browserUrlTree?.root?.children?.primary?.segments[1]?.path : undefined;
    if (orderCode) {
      this.orderHistoryFacade.loadOrderDetails(orderCode);
      return this.orderFacade.getOrderDetails().pipe(
        map((orderDetails) => {
          if (orderDetails && Object.keys(orderDetails).length !== 0) {
            return true;
          } else {
            return this.router.parseUrl(
              this.semanticPathService.get('orders') ?? ''
            );
          }
        })
      );
    }
    return of(true);
  }
}
