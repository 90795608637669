import { NgModule } from '@angular/core';
import { CustomTabParagraphContainerModule } from './custom/cms-components/content/custom-tab-paragraph-container/custom-tab-paragraph-container.module';
import { BannerImageTextModule } from '../custom/cms-components/content/banner-image-text/banner-image-text.module';
import { BoxCategoriesModule } from '../custom/cms-components/content/box-category/box-categories.module';
import { CustomProductIntroModule } from './custom/cms-components/product/custom-product-intro/custom-product-intro.module';
import { CustomProductSummaryModule } from './custom/cms-components/product/custom-product-summary/custom-product-summary.module';
import { CustomMiniCartModule } from './custom/cms-components/cart/mini-cart/custom-mini-cart.module';
import { CustomIconsModule } from './custom/cms-components/misc/icon/custom-icons.module';
import { CustomSiteContextSelectorModule } from './custom/cms-components/misc/site-context-selector/custom-site-context-selector.module';
import { CustomNavigationModule } from './custom/cms-components/navigation/navigation/custom-navigation.module';
import { CustomLayoutModule } from './custom/cms-structure/layout/custom-layout.module';
import { CustomProductAttributesModule } from './custom/cms-components/product/product-tabs/custom-product-attributes/custom-product-attributes.module';
import { CustomLoginFormModule } from './custom/cms-components/user/login-form/custom-login-form.module';
import { CustomLoginModule } from './custom/cms-components/user/login/custom-login.module';
import { CustomLoginRegisterModule } from './custom/cms-components/user/login-register/custom-login-register.module';
import { CustomAddToCartModule } from './custom/cms-components/cart/custom-add-to-cart/custom-add-to-cart.module';
import { CustomCMSLinkComponentModule } from './custom/cms-components/navigation/custom-cms-link/custom-cms-link.module';
import { CustomForgottenPasswordModule } from './custom/cms-components/user/profile/custom-forgotten-password/custom-forgotten-password.module';
import { InfoItemModule } from '../custom/cms-components/content/info-item/info-item.module';
import { InfoModule } from '../custom/cms-components/content/info/info.module';
import { ProductFacetNavigationModule, ProductListModule } from './custom/cms-components/product/product-list';
import { CustomBaseStoreStoreModule } from '../custom/cms-components/header/base-store/store/custom-base-store-store.module';
import { CustomAddToWishListModule } from './custom/cms-components/cart/custom-add-to-wishlist/custom-add-to-wish-list.module';
import { CustomCartDetailsModule } from './custom/cms-components/cart/custom-cart-details/custom-cart-details.module';
import { CustomCartSharedModule } from './custom/cms-components/cart/cart-shared/custom-cart-shared.module';
import { BoxItemCategoriesModule } from '../custom/cms-components/content/box-item-categories/box-item-categories.module';
import { CustomBoxLinkImageModule } from '../custom/cms-components/content/custom-box-link-image/custom-box-link-image.module';
import { CustomBoxLinkImageItemModule } from '../custom/cms-components/content/custom-box-link-image-item/custom-box-link-image-item.module';
import { CustomDeliveryModeModule } from './custom/cms-components/checkout/components/delivery-mode/custom-delivery-mode.module';
import { CustomCheckoutProgressModule } from './custom/cms-components/checkout/components/checkout-progress/custom-checkout-progress.module';
import { CustomAddressFormModule } from './custom/cms-components/checkout/components/shipping-address/address-form/custom-address-form.module';
import { CustomCartTotalsModule } from './custom/cms-components/cart/custom-cart-totals/custom-cart-totals.module';
import { CustomOrderConfirmationModule } from './custom/cms-components/order-confirmation/custom-order-confirmation.module';
import { CustomPaymentMethodModule } from './custom/cms-components/checkout/components/payment-method/custom-payment-method.module';
import { CustomPaymentTypeModule } from './custom/cms-components/checkout/components/payment-method/payment-type/custom-payment-type.module';
import { CustomPaymentFormModule } from './custom/cms-components/checkout/components/payment-method/payment-form/custom-payment-form.module';
import { CustomReviewSubmitModule } from './custom/cms-components/checkout/components/review-submit/custom-review-submit.module';
import { CustomPlaceOrderModule } from './custom/cms-components/checkout/components/custom-place-order/custom-place-order.module';
import { CustomCardModule } from './custom/cms-structure/shared/components/custom-card/custom-card.module';
import { CustomCheckoutOrderSummaryModule } from './custom/cms-components/checkout/components/checkout-order-summary/custom-checkout-order-summary.module';
import { CustomShippingAddressModule } from './custom/cms-components/checkout/components/shipping-address/custom-shipping-address.module';
import { CustomUpdatePasswordModule } from './custom/cms-components/myaccount/custom-update-password.module';
import { CustomGiftCardModule } from '../custom/cms-components/checkout/custom-gift-card/custom-gift-card.module';
import { CustomAddressBookModule } from './custom/cms-components/myaccount/custom-address-book/custom-address-book.module';
import { CustomRowModule } from '../custom/cms-components/myaccount/tables/custom-row.module';
import { CustomHeaderTablesModule } from '../custom/cms-components/myaccount/custom-header-tables/custom-header-tables.module';
import { CustomGiftCardsDetailsModule } from '../custom/cms-components/cart/custom-gift-cards-details/custom-gift-cards-details.module';
import { CustomOrderHistoryModule } from './custom/cms-components/myaccount/order/custom-order-history/custom-order-history.module';
import { CustomOrderDetailsModule } from './custom/cms-components/myaccount/order/custom-order-details/custom-order-details.module';
import { CustomUpdateProfileModule } from './custom/feature-libs/user/profile/components/update-profile/custom-update-profile.module';
import { CustomAnonymousConsentManagementBannerModule } from './custom/cms-components/anonymous-consent-management';
import { CustomResetPasswordModule } from './custom/feature-libs/user/profile/components/custom-reset-password/custom-reset-password.module';
import { CustomProductCarouselModule } from './custom/cms-components/product/carousel/product-carousel/custom-product-carousel.module';
import { CustomWishListModule } from './custom/cms-components/custom-wishlist/custom-wish-list.module';
import { CustomNotificationPreferenceModule } from './custom/cms-components/myaccount/notification-preference/custom-notification-preference.module';
import { CustomVideoFullWidthModule } from '../custom/cms-components/content/custom-video-full-width/custom-video-full-width.module';
import { CustomCheckoutLoginModule } from './custom/cms-components/user/checkout-login/custom-checkout-login.module';
import { CustomRegisterComponentModule } from './custom/feature-libs/user/profile/components/register/custom-register.module';
import { CustomBannerAndProductsGridModule } from '../custom/cms-components/content/custom-banner-and-products-grid/custom-banner-and-products-grid.module';
import { CustomCategoryNavigationModule } from './custom/cms-components/navigation/category-navigation/custom-category-navigation.module';
import { CustomAgeStatusModule } from '../custom/cms-components/header/age-status/custom-age-status.module';
import { CustomFooterNavigationModule } from './custom/cms-components/navigation/footer-navigation/custom-footer-navigation.module';
import { TagManagerFeatureModule } from './features/tracking/tag-manager-feature.module';
import { CustomReturnRequestListModule } from './custom/cms-components/myaccount/custom-return-request-list/custom-order-return-request-list.module';
import { CustomReturnRequestDetailModule } from './custom/cms-components/myaccount/custom-return-request-detail/custom-return-request-detail.module';
import { CustomOrderReturnModule } from './custom/cms-components/myaccount/order/amend-order/returns/custom-order-return.module';
import {
  CustomProductLabelsModule
} from "../custom/cms-components/product/custom-product-labels/custom-product-labels.module";
import { CustomSearchBoxModule } from './custom/cms-components/navigation/search-box';
import { CustomMyInterestsModule } from './custom/cms-components/myaccount/custom-my-interests/custom-my-interests.module';
import { CustomPreferenceCenterModule } from '../custom/cms-components/preference-center/custom-preference-center.module';

@NgModule({
  declarations: [],
  imports: [
    CustomLoginModule,
    CustomMiniCartModule,
    CustomLayoutModule,
    CustomIconsModule,
    CustomNavigationModule,
    CustomSiteContextSelectorModule,
    CustomProductIntroModule,
    CustomTabParagraphContainerModule,
    CustomProductAttributesModule,
    CustomProductSummaryModule,
    CustomLoginFormModule,
    CustomLoginRegisterModule,
    CustomAddToCartModule,
    CustomBaseStoreStoreModule,
    CustomCMSLinkComponentModule,
    BoxCategoriesModule,
    BoxItemCategoriesModule,
    BannerImageTextModule,
    InfoModule,
    InfoItemModule,
    ProductListModule,
    CustomForgottenPasswordModule,
    CustomBoxLinkImageModule,
    CustomBoxLinkImageItemModule,
    CustomAddToWishListModule,
    CustomWishListModule,
    CustomCartDetailsModule,
    CustomCartSharedModule,
    CustomCheckoutProgressModule,
    CustomDeliveryModeModule,
    CustomCartTotalsModule,
    CustomCheckoutOrderSummaryModule,
    CustomPaymentMethodModule,
    CustomPaymentFormModule,
    CustomReviewSubmitModule,
    CustomPaymentTypeModule,
    CustomPlaceOrderModule,
    CustomOrderConfirmationModule,
    CustomShippingAddressModule,
    CustomAddressFormModule,
    CustomCardModule,
    CustomUpdatePasswordModule,
    CustomAddressBookModule,
    CustomRowModule,
    CustomHeaderTablesModule,
    CustomGiftCardModule,
    CustomGiftCardsDetailsModule,
    ProductFacetNavigationModule,
    CustomOrderHistoryModule,
    CustomOrderDetailsModule,
    CustomUpdateProfileModule,
    CustomAnonymousConsentManagementBannerModule,
    CustomResetPasswordModule,
    CustomProductCarouselModule,
    CustomNotificationPreferenceModule,
    CustomVideoFullWidthModule,
    CustomCheckoutLoginModule,
    CustomRegisterComponentModule,
    CustomBannerAndProductsGridModule,
    CustomCategoryNavigationModule,
    CustomFooterNavigationModule,
    CustomAgeStatusModule,
    TagManagerFeatureModule,
    CustomReturnRequestListModule,
    CustomReturnRequestDetailModule,
    CustomOrderReturnModule,
    CustomProductLabelsModule,
    CustomMyInterestsModule,
    CustomSearchBoxModule,
    CustomPreferenceCenterModule,
  ],
})
export class SpartacusCustomModule {}

export declare const enum CUSTOM_LAUNCH_CALLER {
  VALIDATE_CART_SPINNER = 'VALIDATE_CART_SPINNER',
  SIMULATE_CART_SPINNER = 'SIMULATE_CART_SPINNER',
  ORDER_ACTIONS_SPINNER = 'ORDER_ACTIONS_SPINNER'
}
