import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdatePasswordComponentService } from '@spartacus/user/profile/components';
import { CustomValidators } from '../../../shared/utils/validators/custom-validators';

@Injectable()
export class CustomUpdatePasswordComponentService extends UpdatePasswordComponentService {
  form: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [
        Validators.required,
        CustomValidators.passwordValidator,
      ]),
      newPasswordConfirm: new FormControl('', Validators.required),
    },
    {
      validators: CustomValidators.passwordsMustMatch(
        'newPassword',
        'newPasswordConfirm'
      ),
    }
  );
}
