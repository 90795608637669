import {
  Component,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ReturnRequestOverviewComponent } from '@spartacus/order/components';

@Component({
  selector: 'cx-return-request-overview',
  templateUrl: './custom-return-request-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReturnRequestOverviewComponent extends ReturnRequestOverviewComponent {
}
