import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NotificationPreferenceComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-notification-preference',
  templateUrl: './custom-notification-preference.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNotificationPreferenceComponent extends NotificationPreferenceComponent {
}
