
<div *ngIf="title" class="title">
  {{ title }}
</div>
<ul *ngIf="(mediaLinks$ | async) as mediaLinks">
  <ng-container *ngFor="let mediaLink of mediaLinks">
    <li>
      <cx-generic-link
          [class]="btnClass"
          [url]="mediaLink?.url"
          [target]="getTarget(mediaLink?.target)"
          [title]="mediaLink?.linkName"
      >
        <cx-media
          *ngIf="mediaLink?.media"
          [container]="mediaLink?.media"
        ></cx-media>
      </cx-generic-link>
    </li>
  </ng-container>
</ul>
