import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { CheckoutGuard, CheckoutConfigService, ExpressCheckoutService, CheckoutStepService } from "@spartacus/checkout/base/components";
import { CheckoutStepType } from "@spartacus/checkout/base/root";
import { RoutingConfigService } from "@spartacus/core";
import { Observable, Subscription, of } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutGuard extends CheckoutGuard {
  protected subscription: Subscription;
  private readonly firstStepbis$: Observable<UrlTree> =
    this.checkoutStepService.steps$.pipe(
      map((steps) => {
        return this.router.parseUrl(
          this.routingConfigService.getRouteConfig(steps[0].routeName)
            ?.paths?.[0] as string
        );
      })
    );

  constructor(
    protected router: Router,
    protected routingConfigService: RoutingConfigService,
    protected checkoutConfigService: CheckoutConfigService,
    protected expressCheckoutService: ExpressCheckoutService,
    protected activeCartFacade: ActiveCartFacade,
    protected checkoutStepService: CheckoutStepService
  ) {
    super(router, routingConfigService, checkoutConfigService, expressCheckoutService,activeCartFacade,checkoutStepService);
    this.subscription = this.activeCartFacade
      .hasDeliveryItems()
      .pipe(distinctUntilChanged())
      .subscribe((hasDeliveryItems) => {
        this.checkoutStepService.disableEnableStep(
          CheckoutStepType.DELIVERY_ADDRESS,
          !hasDeliveryItems
        );
        this.checkoutStepService.disableEnableStep(
          CheckoutStepType.DELIVERY_MODE,
          !hasDeliveryItems
        );

        this.setStepNameMultiLine(
          CheckoutStepType.PAYMENT_DETAILS,
          hasDeliveryItems
        );
        this.setStepNameMultiLine(
          CheckoutStepType.REVIEW_ORDER,
          hasDeliveryItems
        );
      });
  }

  canActivate(): Observable<boolean | UrlTree> {
    const expressCheckout$ = this.expressCheckoutService
      .trySetDefaultCheckoutDetails()
      .pipe(
        switchMap((expressCheckoutPossible) => {
          const reviewOrderRoute =
            this.checkoutStepService.getCheckoutStepRoute(
              CheckoutStepType.REVIEW_ORDER
            );
          return expressCheckoutPossible && reviewOrderRoute
            ? of(
                this.router.parseUrl(
                  this.routingConfigService.getRouteConfig(reviewOrderRoute)
                    ?.paths?.[0] as string
                )
              )
            : this.firstStepbis$;
        })
      );

    return this.activeCartFacade
      .isGuestCart()
      .pipe(
        switchMap((isGuestCart) =>
          this.checkoutConfigService.isExpressCheckout() && !isGuestCart
            ? expressCheckout$
            : this.firstStepbis$
        )
      );
  }

  protected setStepNameMultiLine(
    stepType: CheckoutStepType,
    value: boolean
  ): void {
    const step = this.checkoutStepService.getCheckoutStep(stepType);
    if (step) {
      step.nameMultiLine = value;
    }
  }
}
