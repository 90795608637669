import { Component } from '@angular/core';
import { ProductSummaryComponent } from '@spartacus/storefront';
import {CustomCurrentProductService} from "../../../core/product/services/custom-current-product.service";

@Component({
  selector: 'app-custom-product-summary',
  templateUrl: './custom-product-summary.component.html'
})
export class CustomProductSummaryComponent extends ProductSummaryComponent {
  constructor(protected currentProductService: CustomCurrentProductService) {
    super(currentProductService);
  }
}
