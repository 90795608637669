import { SiteContextComponentService } from '@spartacus/storefront';
import { Injectable, Injector, Optional } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import {
  CmsSiteContextSelectorComponent,
  ContextServiceMap,
} from '@spartacus/core';
import { take } from 'rxjs/operators';
import { SiteContextType } from '@spartacus/storefront';

@Injectable()
export class CustomSiteContextComponentService extends SiteContextComponentService {
  constructor(
    @Optional()
    protected componentData: CmsComponentData<CmsSiteContextSelectorComponent>,
    protected customContextServiceMap: ContextServiceMap,
    protected injector: Injector
  ) {super(componentData,customContextServiceMap,injector)}


  setActive(value: string, context?: SiteContextType): void {
    this.getService(context)
      .pipe(take(1))
      .subscribe((service) => {
        service.setActive(value);
      });
    window.location.reload();


  }
}
