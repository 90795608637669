import { Injectable } from '@angular/core';
import { Converter, Occ, Product } from '@spartacus/core';
import { prettyfyTextForUrl } from 'src/app/custom/util/filter-text';

@Injectable({
  providedIn: 'root'
})
export class CustomProductPrettyNameNormalizer implements Converter<Occ.Product, Product> {
  convert(source: Occ.Product, target?: Product): Product {
    if (source.name) {
      target.slug = prettyfyTextForUrl(source.name);
    }
    return target as Product;
  }
}
