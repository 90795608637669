import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, DeferLoadingStrategy, FeaturesConfigModule, I18nModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { customDefaultAnonymousConsentLayoutConfig } from './custom-default-anonymous-consent-layout.config';
import { CustomAnonymousConsentOpenDialogComponent } from './open-dialog/custom-anonymous-consent-open-dialog.component';
import { CustomAnonymousConsentManagementBannerComponent } from './banner/custom-anonymous-consent-management-banner.component';
import { KeyboardFocusModule } from '@spartacus/storefront';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    FeaturesConfigModule,
    KeyboardFocusModule,
  ],
  providers: [
    provideConfig(customDefaultAnonymousConsentLayoutConfig),
    provideConfig({
      cmsComponents: {
        AnonymousConsentManagementBannerComponent: {
          component: CustomAnonymousConsentManagementBannerComponent,
          deferLoading: DeferLoadingStrategy.INSTANT,
        },
        AnonymousConsentOpenDialogComponent: {
          component: CustomAnonymousConsentOpenDialogComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [
    CustomAnonymousConsentManagementBannerComponent,
    CustomAnonymousConsentOpenDialogComponent,
  ],
  exports: [
    CustomAnonymousConsentManagementBannerComponent,
    CustomAnonymousConsentOpenDialogComponent,
  ],
  entryComponents: [
    CustomAnonymousConsentManagementBannerComponent,
    CustomAnonymousConsentOpenDialogComponent,
  ],
})
export class CustomAnonymousConsentManagementBannerModule {}
