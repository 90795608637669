import { AuthActions, StateUtils } from '@spartacus/core';
import {
  CustomCreditCardActions,
  LOAD_CREDIT_CARD_LIST_FAIL,
  LOAD_CREDIT_CARD_LIST_SUCCESS,
  PURGE_CREDIT_CARD_LIST,
  REMOVE_SELECTED_CREDIT_CARD_FAIL,
  REMOVE_SELECTED_CREDIT_CARD_SUCCESS,
  SAVE_CREDIT_CARD_SUCCESS,
  SELECT_SAVED_CREDIT_CARD_FAIL,
  SELECT_SAVED_CREDIT_CARD_SUCCESS,
} from '../actions/custom-credit-card.action';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { CREDIT_CARD_LIST, CreditCard, CustomCreditCardState, Payments } from '../credit-card.state';

export const initialStateCreditCard: Payments = { payments: [] };
export const initialStateSaveCreditCard = false;
export const initialStateSelectedSaveCreditCard: CreditCard = null;

export function creditCardReducer(
  state = initialStateCreditCard,
  action: CustomCreditCardActions,
): Payments {
  switch (action.type) {
    case LOAD_CREDIT_CARD_LIST_SUCCESS: {
      return action.payload ? action.payload : initialStateCreditCard;
    }

    case LOAD_CREDIT_CARD_LIST_FAIL: {
      return initialStateCreditCard;
    }

    case PURGE_CREDIT_CARD_LIST: {
      return initialStateCreditCard;
    }
  }
  return state;
}

export function saveCreditCardReducer(
  state = initialStateSaveCreditCard,
  action: CustomCreditCardActions,
): boolean {
  switch (action.type) {
    case SAVE_CREDIT_CARD_SUCCESS: {
      return action.payload ? action.payload : initialStateSaveCreditCard;
    }

    case LOAD_CREDIT_CARD_LIST_FAIL: {
      return initialStateSaveCreditCard;
    }

    case PURGE_CREDIT_CARD_LIST: {
      return initialStateSaveCreditCard;
    }
  }
  return state;
}

export function selectedSaveCreditCardReducer(
  state = initialStateSelectedSaveCreditCard,
  action: CustomCreditCardActions,
): CreditCard {
  switch (action.type) {
    case SELECT_SAVED_CREDIT_CARD_SUCCESS: {
      return action.payload ? action.payload : initialStateSelectedSaveCreditCard;
    }

    case SELECT_SAVED_CREDIT_CARD_FAIL: {
      return initialStateSelectedSaveCreditCard;
    }

    case REMOVE_SELECTED_CREDIT_CARD_SUCCESS: {
      return action.payload ? action.payload : initialStateSelectedSaveCreditCard;
    }

    case REMOVE_SELECTED_CREDIT_CARD_FAIL: {
      return initialStateSelectedSaveCreditCard;
    }

    case PURGE_CREDIT_CARD_LIST: {
      return initialStateSelectedSaveCreditCard;
    }
  }
  return state;
}

export function getReducers(): ActionReducerMap<CustomCreditCardState> {
  return {
    entities: StateUtils.loaderReducer<Payments>(CREDIT_CARD_LIST, creditCardReducer),
    saveCreditCard: saveCreditCardReducer,
    selected: selectedSaveCreditCardReducer,
  };
}

export const creditCardReducerToken:
  InjectionToken<ActionReducerMap<CustomCreditCardState>>
  = new InjectionToken<ActionReducerMap<CustomCreditCardState>>('CreditCardReducers');

export const reducerCreditCardProvider: Provider = {
  provide: creditCardReducerToken,
  useFactory: getReducers,
};


export function clearCustomCreditCardState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
      state = [];
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearCustomCreditCardState];
