import { StateUtils } from '@spartacus/core';
import { CsTicketCause } from 'src/app/model/contact-form.model';

export const CUSTOM_CONTACT_FORM_FEATURE = 'customContactForm';
export const CUSTOM_SEND_CONTACT_FORM_PROCESS_ID = 'customSendContactForm';

export interface StateCustomContactForm {
  causes: StateUtils.LoaderState<CsTicketCause[]>;
}

export interface StateWithCustomContactForm {
  [CUSTOM_CONTACT_FORM_FEATURE]: StateCustomContactForm;
}
