
<h3 class="cx-checkout-title d-none d-lg-block d-xl-block">
  {{ 'paymentForm.payment' | cxTranslate }}
</h3>

<ng-container *ngIf="cards$ | async as cards">
  
  <ng-container *ngIf="!(isUpdating$ | async); else loading">
   
      <div class="cx-checkout-body row">
        <div
          class="cx-payment-card col-md-12 col-lg-6"
          *ngFor="let card of cards; let i = index; let fst = first;"
        >
          <div class="cx-payment-card-inner">
            
            <cx-card
              [border]="true"
              [fitToContainer]="true"
              [content]="card.content"
              [isDefault] = "fst"
              (sendCard)="selectPaymentMethod(card.paymentMethod)"
            ></cx-card>
          </div>
        </div>
      </div>

      <div class="row cx-checkout-btns">
        <div class="col-md-12 col-lg-3">
          <button class="btn btn-block btn-default" (click)="back()">
            {{ backBtnText | cxTranslate }}
          </button>
        </div>
        <div class="col-md-12 col-lg-4">
          <button
            class="btn btn-block btn-action"
            [disabled]="!(selectedMethod$ | async)?.id"
            (click)="next()"
          >
            {{ 'common.continue' | cxTranslate }}
          </button>
        </div>
      </div>

  </ng-container>

  <ng-template #loading>
    <div class="cx-spinner"><cx-spinner></cx-spinner></div>
  </ng-template>
</ng-container>