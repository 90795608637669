import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { StateWishlist, WishlistList } from 'src/app/model/wishlist.model';
import { UserWishlistActions } from '../actions';
import { USER_WISHLIST } from '../actions/custom-wishlist.action';

export const initialState: WishlistList = {
  wishlist: null,
};

export function reducer(
  state = initialState,
  action: UserWishlistActions.UserWishlistAction
): WishlistList {
  switch (action.type) {
    case UserWishlistActions.LOAD_USER_WISHLIST_SUCCESS: {
      return action.payload ? { wishlist: action.payload } : initialState;
    }
    case UserWishlistActions.LOAD_USER_WISHLIST_FAIL: {
      return initialState;
    }
  }

  return state;
}

export function getReducers(): ActionReducerMap<StateWishlist> {
  return {
    wishlist: StateUtils.loaderReducer<WishlistList>(USER_WISHLIST, reducer),
  };
}

export const reducerWishlistToken: InjectionToken<ActionReducerMap<StateWishlist>> = new InjectionToken<
  ActionReducerMap<StateWishlist>
>('WishlistReducers');

export const reducerWishlistProvider = {
  provide: reducerWishlistToken,
  useFactory: getReducers,
};
