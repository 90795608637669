import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  GlobalMessageActions,
  GlobalMessageType,
  normalizeHttpError,
} from '@spartacus/core';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CustomNewsletterConnector } from '../../connectors/custom-newsletter.connector';
import { CustomNewsletterActions } from '../actions';

@Injectable()
export class CustomNewsletterEffects {
  constructor(
    private actions$: Actions,
    private newsletterConnector: CustomNewsletterConnector,
  ) {
  }

  updateNewsletter$: Observable<| CustomNewsletterActions.UpdateNewsletterSuccess
    | GlobalMessageActions.AddMessage
    | CustomNewsletterActions.UpdateNewsletterFail> = createEffect(() => this.actions$.pipe(
    ofType(CustomNewsletterActions.UPDATE_NEWSLETTER),
    map((action: any) => action.payload),
    mergeMap(({ email, consentTemplateId, consentTemplateVersion, accept }) => {
      return this.newsletterConnector
        .updateNewsletter(email, consentTemplateId, consentTemplateVersion, accept)
        .pipe(
          switchMap((data) => [
            new CustomNewsletterActions.UpdateNewsletterSuccess(data),
            new GlobalMessageActions.AddMessage({
              text: { key: 'newsletter.updateSuccess' },
              type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
            }),
          ]),
          catchError((error) =>
            from([
              new GlobalMessageActions.AddMessage({
                text: { key: 'newsletter.updateError' },
                type: GlobalMessageType.MSG_TYPE_ERROR,
              }),
              new CustomNewsletterActions.UpdateNewsletterFail(
                normalizeHttpError(error)
              )
            ])
          ),
        );
    }),
  ));
}
