<cx-carousel
  [items]="items$ | async"
  [title]="title$ | async"
  [link]="link$ | async"
  [template]="carouselItem"
  itemWidth="285px"
  [bgColor]="bgColor$ | async"
  [class.container-product-carousel]="addContainer"
>
</cx-carousel>

<ng-template #carouselItem let-item="item">
  <ng-container [ngSwitch]="item.itemtype">
    <ng-container *ngSwitchCase="'GiftBoxProduct'">
      <app-custom-giftbox-item [item]="item"></app-custom-giftbox-item>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <a tabindex="0" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl">
        <cx-media [container]="item.images?.PRIMARY" format="product"></cx-media>
        <h4>
          {{ item.name }}
        </h4>
        <ng-container [ngSwitch]="!!item.unitPrices">
          <ng-container *ngSwitchCase="true">
            <ng-container *ngFor="let up of item.unitPrices">
              <ng-container *ngIf="up?.unit?.defaultUnit">
                <div class="price">
                  <span class="old" *ngIf="up?.oldPrice?.value">
                    {{ up?.oldPrice?.formattedValue }}
                  </span>
                  <span class="final">
                    {{ up?.price?.formattedValue }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="price">
              <span class="final">
                {{ item.price?.formattedValue }}
              </span>
            </div>
          </ng-container>
        </ng-container>
      </a>
      <cx-generic-link
        [class]="itemBtnClass"
        [url]="{ cxRoute: 'product', params: item } | cxUrl"
      >
      {{ 'carousel.product.btn'  | cxTranslate }}
      </cx-generic-link>
    </ng-container>
  </ng-container>
</ng-template>
