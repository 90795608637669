import { Injectable } from "@angular/core";
import { CheckoutPaymentTypeConnector } from "@spartacus/checkout/b2b/core";
import { Observable } from "rxjs";
import { CustomCheckoutPaymentTypeAdapter } from "./custom-checkout-payment-type.adapter";
import { CreditCard, Payments } from "src/app/spartacus/custom/cms-components/checkout/components/payment-method/custom-credit-card/store/credit-card.state";
import { CheckoutState } from "@spartacus/checkout/base/root";

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutPaymentTypeConnector extends CheckoutPaymentTypeConnector {

  constructor(
    protected adapter: CustomCheckoutPaymentTypeAdapter
  ) {
    super(adapter);
  }

  public creditCardList(
    userId: string,
  ): Observable<Payments> {
    return this.adapter.creditCardList(userId);
  }

  public getSelectedCreditCard(
    userId: string,
    creditCardDetail: CreditCard
  ): Observable<CreditCard> {
    return this.adapter.getSelectedCreditCard(userId, creditCardDetail.id);
  }

  public setSelectedCreditCard(
    userId: string,
    cartId: string,
    paymentDetailsId: string
  ): Observable<CreditCard> {
    return this.adapter.setSelectedCreditCard(userId, cartId, paymentDetailsId);
  }

  public removeSelectedCreditCard(
    userId: string,
    paymentDetailsId: string,
  ): Observable<CreditCard> {
    return this.adapter.removeSelectedCreditCard(userId, paymentDetailsId);
  }

  getCheckoutDetails(
    userId: string,
    cartId: string
  ): Observable<CheckoutState> {
    return this.adapter.getCheckoutDetails(userId, cartId);
  }

}