import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreadcrumbComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-breadcrumb',
  templateUrl: './custom-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomBreadcrumbComponent extends BreadcrumbComponent {
}
