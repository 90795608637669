import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, ScriptLoader, WindowRef } from '@spartacus/core';
import { RecaptchaConnector } from './connector/recaptcha.connector';
import { Observable, of } from 'rxjs';
import { CustomExtraAppConfigService } from '../../../custom/config/services/custom-extra-app-config.service';
import { RecaptchaVersion } from './recaptcha.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {

  private windowReference: any = this.winRef.nativeWindow;
  enabled: boolean;
  version: string;
  siteKey: string;

  constructor(
    protected scriptLoader: ScriptLoader,
    protected recaptchaConnector: RecaptchaConnector,
    protected winRef: WindowRef,
    protected globalMessageService: GlobalMessageService,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
  ) {
    this.getReCaptchaVersion();
  }

  getReCaptchaEnabled(): boolean {
    return this.enabled = Boolean(environment.reCaptcha.enabled && this.customExtraAppConfigService.reCatpcha()?.enabled);
  }

  /**
   * Get reCAPTCHA Version
   * @returns version, v2 if not set
   */
  getReCaptchaVersion(): string {
    return this.version = this.customExtraAppConfigService.reCatpcha()?.version ?? RecaptchaVersion.V2;
  }

  getRecaptchaSiteKey(): string {
    return this.siteKey = environment.reCaptcha[this.version].siteKey;
  }

  async getToken(widgetId = null): Promise<any> {
    switch (this.version) {
      case RecaptchaVersion.V2: {
        return await (this.windowReference).grecaptcha.getResponse(widgetId);
      }
      case RecaptchaVersion.V3: {
        return await (this.windowReference).grecaptcha.execute(this.siteKey, { action: 'submit' });
      }

      default:
        this.wrongRecaptchaVersion();
        return null;
    }
  }

  async validateRecaptchaToken(widgetId: string): Promise<Observable<any>> {
    const token = await this.getToken(widgetId);
    if (token) {
      switch (this.version) {
        case RecaptchaVersion.V2: {
          return of({ status: 200 });
        }

        case RecaptchaVersion.V3: {
          return this.recaptchaConnector.validateRecaptcha(token);
        }

        default:
          this.wrongRecaptchaVersion();
          return of(null);
      }
    } else {
      this.recaptchaError('recaptcha.recaptchaTokenV2Failed');
    }
  }

  resetRecaptcha(widgetId): void {
    this.windowReference.grecaptcha.reset(widgetId);
  }

  wrongRecaptchaVersion(): void {
    let available: Array<string> = [];
    Object.values(RecaptchaVersion).forEach(versions => available = [...available, versions]);
    this.globalMessageService.add({
        key: 'recaptcha.invalidVersion',
        params: {
          version: this.version,
          available: available.join(','),
        },
      },
      GlobalMessageType.MSG_TYPE_ERROR);
  }

  recaptchaError(message: string): void {
    this.showGlobalMessage(
      message,
      GlobalMessageType.MSG_TYPE_ERROR,
    );
  }

  showGlobalMessage(messageKey: string, messageType: GlobalMessageType): void {
    this.globalMessageService.add({ key: messageKey }, messageType);
  }
}
