<ng-container *ngIf="data$ | async as data">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>
        <h2 class="title">
          {{ data.title }}
        </h2>
        <p [innerHTML]="data.description | htmlSanitize: 'html'">
        </p>
        <ng-container *ngIf="ticketCauses$ | async as ticketCauses">
          <form
            (ngSubmit)="onSubmit()"
            *ngIf="ticketCauses.length"
            [formGroup]="contactForm"
          >
            <div class="form-group fade-in">
              <label>
                <ng-select
                  [searchable]="true"
                  [clearable]="false"
                  [items]="ticketCauses"
                  bindLabel="name"
                  bindValue="code"
                  [placeholder]="'contactForm.placeholders.ticketCauses' | cxTranslate"
                  [formControl]="contactForm.get('causeCode')"
                  (change)="onChangeTicketCause($event)"
                >
                </ng-select>
              </label>
            </div>
            <div
              class="form-group fade-in"
              [hidden]="selectedTicketCause.templates.length < 2"
              *ngIf="selectedTicketCause"
            >
              <label>
                <ng-select
                  [searchable]="true"
                  [clearable]="false"
                  [items]="selectedTicketCause.templates"
                  bindLabel="name"
                  bindValue="code"
                  [placeholder]="'contactForm.placeholders.ticketTemplates' | cxTranslate"
                  [formControl]="contactForm.get('templateCode')"
                  (change)="onChangeTicketCauseTemplate($event)"
                >
                </ng-select>
              </label>
            </div>
            <div
              *ngIf="contactForm.get('templateCode').value"
              class="fade-in"
            >
              <p
                class="subtitle"
                *ngIf="selectedTicketTemplate?.name"
              >
                {{ 'contactForm.formTemplateTitle' | cxTranslate: { formTitle: selectedTicketTemplate?.name } }}
              </p>
              <div class="form-group">
                <label>
                  <span class="label-content required"> {{ "contactForm.labels.email" | cxTranslate }}</span>
                  <span class="input-group">
                    <input type="email" class="form-control" formControlName="email" maxlength="60" />
                  </span>
                  <cx-form-errors [control]="contactForm.get('email')"></cx-form-errors>
                </label>
              </div>
              <div class="form-group">
                <label>
                  <span class="label-content required"> {{ "contactForm.labels.subject" | cxTranslate }}</span>
                  <span class="input-group">
                    <input type="text" class="form-control" formControlName="subject" />
                  </span>
                  <cx-form-errors [control]="contactForm.get('subject')"></cx-form-errors>
                </label>
              </div>
              <div
                class="form-group fade-in"
                *ngIf="requiredOrderCode"
              >
                <label>
                  <span class="label-content required"> {{ "contactForm.labels.orderCode" | cxTranslate }}</span>
                  <span class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="orderCode"
                    />
                  </span>
                  <cx-form-errors [control]="contactForm.get('orderCode')"></cx-form-errors>
                </label>
              </div>
              <div
                *ngIf="isReturnForQuality"
                class="fade-in"
              >
                <div
                  class="form-group"
                >
                  <label>
                    <span class="label-content required">
                      {{ 'contactForm.labels.qualityProblem' | cxTranslate }}

                    </span>
                    <ng-select
                      [searchable]="true"
                      [clearable]="false"
                      [items]="qualityProblems"
                      bindLabel="name"
                      bindValue="code"
                      [placeholder]="'contactForm.placeholders.qualityProblems' | cxTranslate"
                      [formControl]="contactForm.get('qualityProblem')"
                    >
                    </ng-select>
                    <cx-form-errors [control]="contactForm.get('qualityProblem')"></cx-form-errors>
                  </label>
                </div>
                <div class="form-group">
                  <label>
                    <span class="label-content">
                      {{ "contactForm.labels.requiredAction" | cxTranslate }}
                    </span>
                    <span class="input-group">
                      <input
                        [maxLength]="255"
                        type="text"
                        class="form-control"
                        formControlName="requiredAction"
                      />
                    </span>
                    <cx-form-errors [control]="contactForm.get('requiredAction')"></cx-form-errors>
                  </label>
                </div>
                <div>
                  <label class="label-content required">
                    {{ "contactForm.labels.productsAffected.label" | cxTranslate }}
                  </label>
                  <div class="cx-products-affected">
                    <ng-container *ngFor="let productAffected of productsAffectedControls; let i=index">
                      <div
                        [formGroup]="productAffected"
                        class="cx-product-affected"
                        [class.fade-in-place]="i > 0"
                      >
                        <div class="cx-product-affected-description">
                          <label>
                            <span class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                formControlName="productDescription"
                                [placeholder]="'contactForm.labels.productsAffected.productDescription' | cxTranslate"
                              />
                            </span>
                            <cx-form-errors [control]="productsAffectedControls[i].get('productDescription')"></cx-form-errors>
                          </label>
                        </div>
                        <div class="cx-product-affected-quantity">
                          <label>
                            <span class="input-group">
                              <input
                                type="number"
                                class="form-control"
                                formControlName="quantity"
                                [placeholder]="'contactForm.labels.productsAffected.quantity' | cxTranslate"
                              />
                            </span>
                            <cx-form-errors [control]="productsAffectedControls[i].get('quantity')"></cx-form-errors>
                          </label>
                        </div>
                        <div
                          *ngIf="i > 0"
                          class="cx-product-affected-remove"
                        >
                          <button
                            class="btn btn-icon"
                            type="button"
                            [title]="'contactForm.labels.productsAffected.remove' | cxTranslate"
                            (click)="removeProductAffected(i)"
                          > 
                            <cx-icon
                              [cxIcon]="iconTypes.TRASH"
                              [aria-label]="'contactForm.labels.productsAffected.remove' | cxTranslate"
                            ></cx-icon>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="form-actions">
                  <button
                    class="btn btn-primary"
                    (click)="addProductAffected()"
                    type="button"
                  > 
                    <cx-icon
                      [cxIcon]="iconTypes.EXPAND"
                      [aria-label]="'contactForm.labels.productsAffected.add' | cxTranslate"
                    ></cx-icon>
                    {{ "contactForm.labels.productsAffected.add" | cxTranslate }}
                  </button>
                </div>
              </div>
              <div class="form-group">
                <label>
                  <span class="label-content required">{{ 'contactForm.labels.message' | cxTranslate }}</span>
                  <textarea
                    [maxLength]="255"
                    class="form-control"
                    formControlName="message"
                    rows="4"
                  ></textarea>
                  <cx-form-errors [control]="contactForm.get('message')"></cx-form-errors>
                </label>
              </div>
              <div class="form-check">
                <label>
                  <input
                    formControlName="accept"
                    class="scaled-input form-check-input"
                    type="checkbox"
                  />
                  <span class="form-check-label">
                    {{ 'contactForm.labels.accept' | cxTranslate }}
                  </span>
                </label>
              </div>
              <cx-form-errors [control]="contactForm.get('accept')"></cx-form-errors>
              <div class="form-actions">
                <button
                  class="btn btn-send"
                  
                >
                  {{ 'contactForm.submit' | cxTranslate }}
                </button>
              </div>
              <div
                class="content"
                [class]="data.content.styleClasses"
                *ngIf="data?.content?.content"
                [innerHTML]="data.content.content | htmlSanitize: 'html'"
              ></div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
