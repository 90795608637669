<ng-container
  *ngIf="{
    orderHistory: orders$ | async,
    replenishmentOrder: hasReplenishmentOrder$ | async
  } as type"
>
  <ng-container *ngIf="type.orderHistory">
    <div [ngClass]="type.replenishmentOrder ? '' : 'container'">
      <!-- HEADER -->
      <div
        [ngClass]="
          type.replenishmentOrder
            ? 'cx-replenishment-details-order-history-header'
            : 'cx-order-history-header'
        "
      >
        <h3 *ngIf="type.replenishmentOrder">
          {{ 'orderHistory.replenishmentHistory' | cxTranslate }}
        </h3>
        <h2 class="title" *ngIf="!type.replenishmentOrder">
          {{ 'orderHistory.orderHistory' | cxTranslate }}
        </h2>
      </div>

      <!-- BODY -->
      <div class="cx-order-history-body">
        <div class="custom-list-filter">
          <div class="form-group">
            <label>
              <span class="label-content">
                {{ 'orderHistory.filterOrderNumber' | cxTranslate }}
              </span>
              <input class="form-control"
              type="text"
              [formControl]="filtersForm.get('orderCode')" />
            </label>
            <button
                class="btn btn-default"
                (click)="removeFilter(['orderCode'], false)"
                [disabled]="!filtersForm.get('orderCode').value">
              {{ 'orderHistory.filterRemove' | cxTranslate }}
            </button>
          </div>
          <div class="form-group form-group-range">
            <label>
              <span class="label-content">
                {{ 'orderHistory.filterDateFrom' | cxTranslate }}
              </span>
              <cx-date-picker
                [control]="filtersForm.get('startCreationTime')"
                [max]="filtersForm.get('endCreationTime').value"
                (update)="updateDatepickerFilter('endCreationTime')"
              ></cx-date-picker>
            </label>
            <label>
              <span class="label-content">
                {{ 'orderHistory.filterDateTo' | cxTranslate }}
              </span>
              <cx-date-picker
                [control]="filtersForm.get('endCreationTime')"
                [min]="filtersForm.get('startCreationTime').value"
                (update)="updateDatepickerFilter('startCreationTime')"
              ></cx-date-picker>
            </label>
            <button
                class="btn btn-default"
                (click)="removeFilter(['startCreationTime','endCreationTime'])"
                [disabled]="!filtersForm.get('startCreationTime').value && !filtersForm.get('endCreationTime').value">
              {{ 'orderHistory.filterRemove' | cxTranslate }}
            </button>
          </div>
<!--           <div class="form-group">
            <label>
              <span class="label-content">
                {{ 'orderHistory.filterOrderNumber' | cxTranslate }}
              </span>
              <input class="form-control" [readOnly]="edit" type="text" [formControl]="filtersForm.get('uid')" />
              <cx-form-errors [control]="filtersForm.get('uid')"></cx-form-errors>
            </label>
          </div>
          <div class="form-group">
            <label>
              <span class="label-content">
                {{ 'myFriends.myFriendsForm.email' | cxTranslate }}
              </span>
              <input class="form-control" [readOnly]="edit" type="text" [formControl]="filtersForm.get('uid')" />
              <cx-form-errors [control]="filtersForm.get('uid')"></cx-form-errors>
            </label>
          </div> -->
        </div>
        <!-- Select Form and Pagination Top -->
        <div class="cx-order-history-sort top row">
          <div class="col-xs-12 col-lg-9 order-lg-1">
            <div class="form-check">
              <label>
                <input
                  (change)="toggleCheckboxFilter('showInvoiced')"
                  [control]="filtersForm.get('showInvoiced')"
                  class="scaled-input form-check-input"
                  type="checkbox"
                  [value]="!filtersForm.get('showInvoiced').value"
                />
                <span class="form-check-label">
                  {{ 'orderHistory.filterOrdersInvoiced' | cxTranslate }}
                </span>
              </label>
            </div>
            <ng-container *ngIf="allowFriends">
              <div class="form-check">
                <label>
                  <input
                    (change)="toggleCheckboxFilter('showFriends')"
                    [control]="filtersForm.get('showFriends')"
                    class="scaled-input form-check-input"
                    type="checkbox"
                  />
                  <span class="form-check-label">
                  {{ 'orderHistory.filterOrdersFriends' | cxTranslate }}
                </span>
                </label>
              </div>
            </ng-container>
          </div>
          <div class="cx-order-history-form-group form-group col-xs-12 col-lg-3">
            <label>
              <span class="label-content">
                {{ 'orderHistory.sortByTitle' | cxTranslate }}
              </span>
              <cx-sorting
                [sortOptions]="type.orderHistory.sorts"
                [sortLabels]="getSortLabels() | async"
                (sortListEvent)="changeSortCode($event)"
                [selectedOption]="type.orderHistory.pagination.sort"
                placeholder="{{
                  'orderHistory.sortByMostRecent' | cxTranslate
                }}"
              ></cx-sorting>
            </label>
          </div>
        </div>
        <ng-container
          *ngIf="type.orderHistory.pagination.totalResults > 0; else noOrder"
        >
          <!-- TABLE -->
          <table class="table table-main">
            <thead>
              <th scope="col" class="table-main-cell-left">
                {{ 'orderHistory.orderId' | cxTranslate }}
              </th>
              <th scope="col">
                {{ 'orderHistory.invoicedTo' | cxTranslate }}
              </th>
              <th scope="col">
                {{ 'orderHistory.sendTo' | cxTranslate }}
              </th>
              <th scope="col">{{ 'orderHistory.date' | cxTranslate }}</th>
              <th scope="col">
                {{ 'orderHistory.status' | cxTranslate }}
              </th>
              <th scope="col" class="table-main-cell-right">
                {{ 'orderHistory.total' | cxTranslate }}
              </th>
              <!-- TODO uncomment next code on implement actions -->
              <!-- <th scope="col"></th> -->
            </thead>
            <tbody>
              <tr
                *ngFor="let order of type.orderHistory.orders"
              >
                <td class="table-main-cell-left cx-order-history-code">
                  <div class="table-main-label">
                    {{ 'orderHistory.orderId' | cxTranslate }}
                  </div>
                  <a
                    [routerLink]="
                      {
                        cxRoute: 'orderDetails',
                        params: order
                      } | cxUrl
                    "
                    class="cx-order-history-value"
                  >
                    {{ order?.code }}</a
                  >
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'orderHistory.invoicedTo' | cxTranslate }}
                  </div>
                  {{ order?.invoicedTo?.name }}
                </td>
                <td>
                  <div class="table-main-label">
                    {{ 'orderHistory.sendTo' | cxTranslate }}
                  </div>
                  {{ order?.sendTo }}
                </td>
                <td class="cx-order-history-placed">
                  <div class="table-main-label">
                    {{ 'orderHistory.date' | cxTranslate }}
                  </div>
                  {{ order?.placed | cxDate: 'd/MM/yyyy' }}
                </td>
                <td class="cx-order-history-status">
                  <div class="table-main-label">
                    {{ 'orderHistory.status' | cxTranslate }}
                  </div>
                    {{ order?.statusDisplay }}
                </td>
                <td class="table-main-cell-right cx-order-history-total">
                  <div class="table-main-label">
                    {{ 'orderHistory.total' | cxTranslate }}
                  </div>
                    {{ order?.total.formattedValue }}
                </td>
                <td class="table-main-actions">

                  <button
                    *ngIf="order?.restoreOnly"
                    type="button"
                    class="btn btn-icon"
                    [title]="'orderHistory.restoreOnlyOrder' | cxTranslate"
                    (click)="dispatchReorderEvent('refresh');showOrderRestoreOnlyModal(order)"
                    >
                    <app-custom-icons  class="icon-redo"></app-custom-icons>
                  </button>
                  <button
                    *ngIf="order?.restorable"
                    type="button"
                    class="btn btn-icon"
                    [title]="'orderHistory.editOrder' | cxTranslate"
                    (click)="showOrderRestorationModal(order)"
                    >
                    <app-custom-icons [type]="iconTypes.PENCIL"></app-custom-icons>
                  </button>
                  <button
                    *ngIf="order?.cancellable"
                    type="button"
                    class="btn btn-icon"
                    [title]="'orderHistory.cancelOrder' | cxTranslate"
                    (click)="showOrderCancellationModal(order)"
                    >
                      <app-custom-icons [type]="iconTypes.TRASH"></app-custom-icons>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Select Form and Pagination Bottom -->
          <div class="cx-order-history-sort bottom">
            <div
              *ngIf="type.orderHistory.pagination.totalPages > 1"
              class="cx-order-history-pagination"
            >
              <cx-pagination
                [pagination]="type.orderHistory.pagination"
                (viewPageEvent)="pageChange($event)"
              ></cx-pagination>
            </div>
          </div>
        </ng-container>

        <!-- NO ORDER CONTAINER -->
        <ng-template #noOrder>
          <div
            *ngIf="isLoaded$ | async"
            [ngClass]="
              type.replenishmentOrder
                ? 'cx-replenishment-details-order-history-no-order row'
                : 'cx-order-history-no-order row'
            "
          >
            <div class="col-sm-12">
              <ng-container *ngIf="type.replenishmentOrder; else otherOrder">
                <div>{{ 'orderHistory.notFound' | cxTranslate }}</div>
              </ng-container>

              <ng-template #otherOrder>
                <div>{{ 'orderHistory.noOrders' | cxTranslate }}</div>
                <a
                  [routerLink]="{ cxRoute: 'home' } | cxUrl"
                  routerLinkActive="active"
                  class="btn btn-primary"
                  >{{ 'orderHistory.startShopping' | cxTranslate }}</a
                >
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>

</ng-container>
