import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { CartCouponModule } from '@spartacus/cart/base/components';
import { CustomCartSharedModule } from 'src/app/spartacus/custom/cms-components/cart/cart-shared/custom-cart-shared.module';
import { CustomCartIsGiftboxGuard } from '../guards/custom-cart-is-giftbox.guard';
import { CustomGiftboxCartTotalsComponent } from './custom-giftbox-cart-totals.component';
import {CustomPaypalInstallmentsModule} from "../../paypal/custom-paypal-installments/custom-paypal-installments.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    CustomCartSharedModule,
    I18nModule,
    CartCouponModule,
    CustomPaypalInstallmentsModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CustomGiftboxCartTotalsComponent: {
          component: CustomGiftboxCartTotalsComponent,
          guards: [CustomCartIsGiftboxGuard],
        },
      }
    } as CmsConfig)
  ],
  declarations: [CustomGiftboxCartTotalsComponent],
  exports: [CustomGiftboxCartTotalsComponent],
  entryComponents: [CustomGiftboxCartTotalsComponent],
})
export class CustomGiftboxCartTotalsModule {}
