import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { WindowRef } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { CustomPopupShippingService } from './custom-popup-shipping.service';
import { CustomBaseStoreModel } from '../../header/base-store/custom-base-store.model';
import { CustomAgeStatusService } from '../../header/age-status/custom-age-status.service';
import { CustomAgeStatusModel } from '../../header/age-status/store/reducers/custom-age-status.reducer';
import { CustomExtraAppConfigService } from '../../../config/services/custom-extra-app-config.service';
import { filter, map, take } from 'rxjs/operators';
import { CustomValidateCartService } from '../../../../services/cart/validate-cart.service';

@Component({
  selector: 'app-custom-popup-shipping',
  templateUrl: './custom-popup-shipping.component.html',
  styleUrls: ['./custom-popup-shipping.component.scss'],
})
export class CustomPopupShippingComponent implements OnDestroy {
  shippingForm: FormGroup = this.fb.group({
    code: new FormControl(''),
    isoCode: new FormControl(''),
    pickupStore: new FormControl(false),
    name: new FormControl('')
  });

  showAgePopup = this.customExtraAppConfigService.showAgePopup();
  ageStatus$: Observable<CustomAgeStatusModel>;
  deliveryBaseStores: CustomBaseStoreModel[];
  pickupBaseStores: CustomBaseStoreModel[];
  country: string;
  baseStore: CustomBaseStoreModel;
  stores: CustomBaseStoreModel[];
  showModifications = false;
  showButtonCancel: any = this.launchDialogService.data$.pipe(
    filter(data => !!data), // Make sure order isn't undefined
    take(1), // make sure first result is accepted
    map((data: any) => {
      return data.showButtonCancel;
    })
  );
  private subscriptions = new Subscription();
  currentBaseStore = null;
  @ViewChild('content') content;

  baseStores$: Observable<CustomBaseStoreModel[]>;

  constructor(
    protected fb: FormBuilder,
    protected launchDialogService: LaunchDialogService,
    private customPopupShippingService: CustomPopupShippingService,
    protected customAgeStatusService: CustomAgeStatusService,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    protected customValidateCartService: CustomValidateCartService,
    protected winRef?: WindowRef,
  ) {
    this.baseStores$ = this.customPopupShippingService.getBaseStores();
    this.customPopupShippingService.loadBaseStores();
    this.loadBaseStoreList();
    this.customPopupShippingService.getSelectedBaseStore().subscribe(selected => {
      this.currentBaseStore = selected ? selected : JSON.parse(this.winRef?.localStorage?.getItem('currentbasestore'));
    });

    if (this.showAgePopup){
      this.ageStatus$ = this.customAgeStatusService.getAgeStatus();
    }
  }

  dismissModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  onItemChange($event): void {
    this.baseStore = $event;
    this.showModificationWarning($event);
  }

  showModificationWarning($event): void {
    if (this.currentBaseStore) {
      this.showModifications = this.currentBaseStore?.code !== $event.code;
    }
  }

  changeDeliveryMethod(): void {
    this.shippingForm.get('code').reset();
    this.selectFirstBaseStore(true);
    this.showModificationWarning({ code: this.shippingForm.get('code').value });
  }

  loadBaseStoreList(): void {
    this.subscriptions.add(
      this.baseStores$.subscribe((stores: CustomBaseStoreModel[]) => {
        if (stores?.length) {
          this.stores = stores;
          let deliveryBaseStore = [];
          let pickupBaseStore = [];
          this.stores.map((store: CustomBaseStoreModel) => {
              if (!store.pickupStore) {
                deliveryBaseStore = [...deliveryBaseStore, store];
              } else {
                pickupBaseStore = [...pickupBaseStore, store];
              }
            },
          );
          this.deliveryBaseStores = deliveryBaseStore;
          this.pickupBaseStores = pickupBaseStore;
          this.getDefaultBaseStore();
        }
      }),
    );
  }

  getDefaultBaseStore(): void {
    if (this.currentBaseStore) {
      this.baseStore = this.currentBaseStore;
      this.shippingForm.patchValue({
        code: this.currentBaseStore?.code,
        pickupStore: this.currentBaseStore?.pickupStore,
        name: this.currentBaseStore?.name,
      });
    } else {
      this.selectFirstBaseStore(!this.baseStore);
    }
  }

  selectFirstBaseStore(baseStore): void {
    if (this.stores && this.stores.length !== 0 && baseStore) {
      const stores = this.shippingForm.get('pickupStore').value ? this.pickupBaseStores : this.deliveryBaseStores;
      const firstStore = stores[0];
      this.shippingForm.patchValue({
        code: firstStore.code,
        pickupStore: firstStore.pickupStore,
        name: firstStore.name
      });
    }
  }

  submitFormStores(): void {
    if ((this.shippingForm.dirty && this.currentBaseStore?.code !== this.shippingForm.get('code').value) || !this.currentBaseStore) {
      const storeSelected = this.stores.find(item => item.code === this.shippingForm.value.code);
      this.customPopupShippingService.updateSelectedBaseStore(storeSelected);
      const shipping: CustomBaseStoreModel = {
        pickupStore: this.shippingForm.value.pickupStore,
        code: this.shippingForm.value.code,
        name: storeSelected.name
      };
      this.winRef?.localStorage?.removeItem('currentbasestore');
      this.winRef?.localStorage?.setItem('currentbasestore', JSON.stringify(shipping));
      this.dismissModal('');
      this.reloadPage().then(()=> {
        this.customValidateCartService.validateCart();
      });
    } else {
      this.dismissModal('');
    }
  }

  reloadPage(): Promise<void> {
    return new Promise<void>(() => {
      this.winRef?.location?.reload();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
