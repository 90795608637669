import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {CartItemComponent, CartItemContextSource} from '@spartacus/cart/base/components';
import {UnitPrice} from 'src/app/model/unit-price';
import {NavigationExtras} from "@angular/router";
import { CustomOrderEntry } from 'src/app/spartacus/spartacus-features.module';
import { Product, ProductScope, ProductService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ActiveCartFacade, Cart, PromotionResult } from '@spartacus/cart/base/root';

@Component({
  selector: 'cx-cart-item',
  templateUrl: './custom-cart-item.component.html',
  styleUrls: ['./custom-cart-item.component.scss']
})
export class CustomCartItemComponent extends CartItemComponent implements OnInit {
  @Input() displayUnitSlim = false;
  @Input() remove = true;
  @Input() displayPromotion: string = 'cart';
  @Input() warehousesCount?: number;
  unitPrice: UnitPrice;
  routerLinkParams: any = {};
  navigationExtras: NavigationExtras = {queryParams: {}, state: {}};
  customOrderEntry: CustomOrderEntry;
  appliedProductPromotions$: Observable<PromotionResult[]>;
  cart$: Observable<Cart> = this.activeCartFacade.getActive();
  cartId: string;

  constructor (
    private productService: ProductService,
    cartItemContextSource: CartItemContextSource,
    private cd: ChangeDetectorRef,
    private activeCartFacade: ActiveCartFacade,
  ) {
    super(cartItemContextSource);
  }


  ngOnInit(): void {
    // this.appliedProductPromotions$ = this.promotionService.getProductPromotionForEntry(
    //   this.customOrderEntry,
    //   this.promotionLocation
    // );

    this.customOrderEntry = this.item;
    this.unitPrice = this.customOrderEntry?.product?.unitPrices?.filter(unitPrice =>
      unitPrice?.unit?.code === this.customOrderEntry.unit?.code
    )[0];

    if (this.customOrderEntry.product.variantCode) {
      this.routerLinkParams.code = this.customOrderEntry.product.variantCode;
      this.navigationExtras = {
        state: {
          productCode: this.customOrderEntry.product.variantCode,
          unit: this.customOrderEntry.unit.code
        }
      }
      if(this.isCombinedProduct(this.customOrderEntry.product) && this.customOrderEntry.product.principalVariant){
        this.routerLinkParams.variantCode = this.customOrderEntry.product.code;
        const queryParams= {
          item: this.customOrderEntry.product.code
        }
        Object.assign(this.navigationExtras, {queryParams});
      }
    } else {
      this.routerLinkParams = {...this.customOrderEntry.product}
    }

    /** Populate the promotions by product */
    this.productService.get(this.item.product.code, ProductScope.DETAILS)
    .subscribe((product: any) => {
      if (product) {
        this.appliedProductPromotions$ = new Observable<PromotionResult[]>((obs) => {
          obs.next(product.potentialPromotions);
        });
        this.cd.detectChanges(); 
      }
    });
  }

  getMaxQty(): number {
    if (this.customOrderEntry?.product?.stock?.stockLevel === 0 || this.customOrderEntry?.product?.stock?.stockLevelStatus === 'outOfStock') {
      return 0;
    } else if (this.customOrderEntry?.product?.stock?.stockLevel) {
      let conversion = 1;
      if (this.customOrderEntry?.product?.unit?.conversion > 1) {
        conversion = this.customOrderEntry?.product?.unit.conversion;
      } else if (this.unitPrice?.unit?.conversion > 1) {
        conversion = this.unitPrice.unit.conversion;
      }
      return Math.floor(this.customOrderEntry?.product?.stock.stockLevel / conversion);
    }
    return 1000;
  }

  private isCombinedProduct(product:Product): boolean {
    return product.code !==product.variantCode;
  }
}
