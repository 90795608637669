import { OccConfig } from '@spartacus/core';

export const customDefaultOccPreferenceCenterConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        preferenceCenter: 'emarsys/get',
        updatePreference: 'emarsys/update',
      },
    },
  },
};
