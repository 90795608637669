import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Config, ProductSearchPage } from '@spartacus/core';
import { ICON_TYPE, PageLayoutService, ViewConfig } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import { ViewModes } from '../product-view/product-view.component';
import { ProductListComponentService } from './product-list-component.service';

@Component({
  selector: 'cx-product-list',
  templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit, OnDestroy {
  iconTypes = ICON_TYPE;
  private subscription = new Subscription();

  isInfiniteScroll: boolean;
  filterTextStarted = false;

  model$: Observable<ProductSearchPage> = this.productListComponentService
    .model$.pipe(tap((model) => {
      if (!this.filterTextStarted && model.freeTextSearch) {
        this.filtersForm.controls.filterText.setValue(model.freeTextSearch, {emitEvent: false});
      }
    }));

  viewMode$ = new BehaviorSubject<ViewModes>(ViewModes.Grid);
  ViewModes = ViewModes;

  filtersForm: FormGroup = new FormGroup({
    filterText: new FormControl(''),
  });

  constructor(
    private pageLayoutService: PageLayoutService,
    private productListComponentService: ProductListComponentService,
    public scrollConfig: ViewConfig
  ) {}

  ngOnInit(): void {   
    const view: any = this.scrollConfig.view;

    this.isInfiniteScroll = view.customViewConfig.infiniteScroll?.active; 
    // this.isInfiniteScroll = this.scrollConfig.view?.infiniteScroll?.active;

    this.subscription.add(
      this.pageLayoutService.templateName$
        .pipe(take(1))
        .subscribe((template) => {
          this.viewMode$.next(
            ['ProductGridPageTemplate', 'SearchResultsListPageTemplate'].includes(template)
              ? ViewModes.Grid
              : ViewModes.List
          );
        })
    );
    this.subscription.add(
      this.filtersForm.controls.filterText.valueChanges
      .pipe(
        tap(() => this.filterTextStarted = true),
        debounceTime(300),
      )
      .subscribe((filterValue) => this.productListComponentService.getPageItems(0, filterValue))
    );
  }

  removeSearchText(event: UIEvent): void {
    this.filtersForm.get('filterText').setValue('');
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  setViewMode(mode: ViewModes): void {
    this.viewMode$.next(mode);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
