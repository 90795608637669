<ng-container *ngIf="unitPrice; else unitTemplate">
    <div class="unit-info">
        <span class="unit-name">
            {{ unitPrice?.unit?.name }}
            {{ unitPrice?.measurementToSalesUnit ? (' ' +  unitPrice?.measurementToSalesUnit) : ''  }}
        </span>
        <span class="unit-discount" *ngIf="unitPrice?.discount">
            {{ unitPrice?.discount }}% {{ 'productDetails.discount' | cxTranslate }}
        </span>
    </div>
    <div class="unit-prices">
      <ng-container [ngSwitch]="showNostockText">
        <ng-container *ngSwitchCase="true">
          <span class="unit-price-nostock">
            {{ 'addToCart.outOfStockUnit' | cxTranslate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span class="unit-price-old" *ngIf="unitPrice?.oldPrice?.value">
              {{ unitPrice?.oldPrice?.formattedValue }}
          </span>
          <span class="unit-price-final">
              {{ unitPrice?.price?.formattedValue }}
          </span>
        </ng-container>
      </ng-container>
    </div>
</ng-container>
<ng-template #unitTemplate>
    <ng-container *ngIf="unit">
        <div class="unit-info">
            <span class="unit-name">
                {{ unit.name }}
            </span>
        </div>
        <div class="unit-prices">
            <span class="unit-price-final">
                {{ price?.formattedValue }}
            </span>
        </div>
    </ng-container>
</ng-template>
