import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import {
  CustomParagraphColumnWithExpandableInfoComponent,
} from './custom-paragraph-column-with-expandable-info.component';
import { IconModule } from '@spartacus/storefront';
import { CustomParagraphModule } from '../../../../spartacus/custom/cms-components/content/custom-paragraph/custom-paragraph.module';
import { HtmlSanitizeModule } from '../../../util/html-sanitize/html-sanitize.module';


@NgModule({
  declarations: [CustomParagraphColumnWithExpandableInfoComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomParagraphColumnWithExpandableInfoComponent: {
          component: CustomParagraphColumnWithExpandableInfoComponent,
        },
      },
    } as CmsConfig),
    I18nModule,
    IconModule,
    CustomParagraphModule,
    HtmlSanitizeModule,
  ],
  exports: [CustomParagraphColumnWithExpandableInfoComponent],
  entryComponents: [CustomParagraphColumnWithExpandableInfoComponent],
})
export class CustomParagraphColumnWithExpandableInfoModule {}
