import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserIdService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { GiftCard } from '../model/custom-gift-card.model';
import { StateWithGiftCards } from '../store/custom-gift-card-state';
import * as CustomGiftCardsListActions from '../store/actions/custom-gift-cards.action';
import { getGiftCardsListState, getSelectedGiftCard } from '../store/selectors/custom-gift-cards.selectors';
import { ActiveCartFacade } from '@spartacus/cart/base/root';


@Injectable({
  providedIn: 'root',
})
export class CustomGiftCardService {
  activeBaseSite: string;
  subscription: Subscription = new Subscription();
  GiftCardSelected: GiftCard;

  constructor(
    protected store: Store<StateWithGiftCards>,
    protected userIdService: UserIdService,
    protected activeCartService: ActiveCartFacade,
  ) {}

  loadGiftCards(userId: string, cartId: string): void {
    this.store.dispatch(
      new CustomGiftCardsListActions.LoadGiftCards({
        userId,
        cartId,
      }),
    );
  }

  loadGiftCard(userId: string, cartId: string): void {
    this.store.dispatch(
      new CustomGiftCardsListActions.LoadGiftCard({
        userId,
        cartId,
      }),
    );
  }

  getGiftCards(): Observable<GiftCard[]> {
    return this.store.pipe(select(getGiftCardsListState));
  }

  selectGiftCard(userId: string, cartId: string): void {
    this.store.dispatch(
      new CustomGiftCardsListActions.SelectGiftCard({
        userId,
        cartId,
      }),
    );
  }

  updateGiftCard(userId: string, cartId: string, message: string, code: string): void {
    this.store.dispatch(
      new CustomGiftCardsListActions.UpdateGiftCard({
        userId,
        cartId,
        giftCardsMessage: message,
        giftCardCode: code,
      }),
    );
  }

  getSelectedGiftCard(): Observable<GiftCard> {
    return this.store.pipe(select(getSelectedGiftCard));
  }

  removeGiftCard(userId: string, cartId: string): void {
    this.store.dispatch(
      new CustomGiftCardsListActions.RemoveGiftCard({
        userId,
        cartId,
      }),
    );
  }
}
