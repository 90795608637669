import { Injectable } from '@angular/core';
import { ContentPageMetaResolver } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { CustomBasePageMetaResolver } from './custom-base-page-meta.resolver';
import { PageHreflangResolver } from './custom-page.resolvers';
import { HrefLangMeta } from './model/custom-page.model';

/**
 * Resolves the page data for all Content Pages based on the `PageType.CONTENT_PAGE`.
 * More specific resolvers for content pages can be implemented by making them more
 * specific, for example by using the page template (see `CartPageMetaResolver`).
 *
 * The page title, and breadcrumbs are resolved in this implementation only.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomContentPageMetaResolver extends ContentPageMetaResolver implements PageHreflangResolver {
  constructor(
    protected basePageMetaResolver: CustomBasePageMetaResolver,
  ) {
    super(basePageMetaResolver);
  }

  resolveHrefLangs(): Observable<HrefLangMeta[]> {
    return this.basePageMetaResolver?.resolveHrefLangs() ?? of();
  }
}
