import {Component, Input} from "@angular/core";
import {ProductLabel} from "../../../../../model/product.model";

@Component({
  selector: 'app-custom-product-labels-items-view',
  templateUrl: './custom-product-labels-items-view.component.html'
})
export class CustomProductLabelsItemsViewComponent {

  @Input() labels?: ProductLabel[];
}
