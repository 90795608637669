import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  FeaturesConfigModule,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import {CardModule, FormErrorsModule, PageComponentModule, PromotionsModule, PwaModule} from '@spartacus/storefront';
import { CustomGenericLinkModule } from '../../shared/components/custom-generic-link/custom-generic-link.module';
import { CustomOrderOverviewModule } from '../../shared/components/order-overview/custom-order-overview.module';
import { CustomIconsModule } from '../misc/icon/custom-icons.module';
import { CustomGuestRegisterFormComponent } from './components/custom-guest-register-form/custom-guest-register-form.component';
import { CustomOrderConfirmationItemsComponent } from './components/custom-order-confirmation-items/custom-order-confirmation-items.component';
import { CustomOrderConfirmationShippingComponent } from './components/custom-order-confirmation-overview/custom-order-confirmation-overview.component';
// eslint-disable-next-line
import { CustomOrderConfirmationThankYouMessageComponent } from './components/custom-order-confirmation-thank-you-message/custom-order-confirmation-thank-you-message.component';
import { CustomOrderConfirmationTotalsComponent } from './components/custom-order-confirmation-totals/custom-order-confirmation-totals.component';
import { OrderDetailsService } from '@spartacus/order/components';
import { OrderFacade } from '@spartacus/order/root';
import { CustomOrderConfirmationGuard } from './guards/custom-order-confirmation.guard';
import { CustomPromotionsModule } from '../product/promotions/promotions.module';
import {
  CustomOrderDetailsModule
} from 'src/app/spartacus/custom/cms-components/myaccount/order/custom-order-details/custom-order-details.module';
import {
  CustomCartSharedModule
} from 'src/app/spartacus/custom/cms-components/cart/cart-shared/custom-cart-shared.module';

const CustomOrderConfirmationComponents = [
  CustomOrderConfirmationItemsComponent,
  CustomOrderConfirmationShippingComponent,
  CustomOrderConfirmationThankYouMessageComponent,
  CustomOrderConfirmationTotalsComponent,
  CustomGuestRegisterFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    PwaModule,
    PromotionsModule,
    I18nModule,
    ReactiveFormsModule,
    FeaturesConfigModule,
    FormErrorsModule,
    CustomOrderOverviewModule,
    CustomIconsModule,
    CustomGenericLinkModule,
    UrlModule,
    RouterModule,
    PageComponentModule,
    CustomPromotionsModule,
    CustomCartSharedModule,
    CustomOrderDetailsModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        OrderConfirmationThankMessageComponent: {
          component: CustomOrderConfirmationThankYouMessageComponent,
          guards: [CustomOrderConfirmationGuard],
        },
        OrderConfirmationItemsComponent: {
          component: CustomOrderConfirmationItemsComponent,
          guards: [CustomOrderConfirmationGuard],
        },
        OrderConfirmationTotalsComponent: {
          component: CustomOrderConfirmationTotalsComponent,
          guards: [CustomOrderConfirmationGuard],
        },
        OrderConfirmationGuestRegisterComponent: {
          component: CustomGuestRegisterFormComponent,
          guards: [CustomOrderConfirmationGuard],
        },
        OrderConfirmationOverviewComponent: {
          component: CustomOrderConfirmationShippingComponent,
          providers: [
            {
              provide: OrderDetailsService,
              useExisting: OrderFacade
            },
          ],
          guards: [CustomOrderConfirmationGuard],
        },
        OrderConfirmationShippingComponent: {
          component: CustomOrderConfirmationShippingComponent,
          guards: [CustomOrderConfirmationGuard],
        },
        OrderConfirmationContinueButtonComponent: {

        }
      },
    } as CmsConfig),
  ],
  declarations: [...CustomOrderConfirmationComponents],
  exports: [...CustomOrderConfirmationComponents],
  entryComponents: [...CustomOrderConfirmationComponents],
})
export class CustomOrderConfirmationModule {}
