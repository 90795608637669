import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerImageTextComponent } from './banner-image-text.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { MediaModule } from '@spartacus/storefront';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';



@NgModule({
  declarations: [BannerImageTextComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    MediaModule,
    ConfigModule.forRoot({
      cmsComponents: {
        BannerImageTextComponent: {
          component: BannerImageTextComponent
        },
      }
    } as CmsConfig)
  ]
})
export class BannerImageTextModule { }
