import { Observable } from 'rxjs';

export abstract class CustomNewsletterAdapter {
  /**
   * Abstract method used to subscribe to newsletter.
   *
   * @param email The `email` to add to newsletter
   * @param accept The `check` for accepted terms
   */
  abstract updateNewsletter(
    email: string,
    consentTemplateId: string,
    consentTemplateVersion: string,
    accept: boolean,
  ): Observable<any>;
}
