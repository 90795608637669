<ng-container *ngIf="data$ | async as data">
  <div class="container">
    <youtube-player
      *ngIf="data.videoId"
      #player
      [videoId]="data.videoId"
      [playerVars]="{'enablejsapi':1,'controls':0,'modestbranding':1,'playsinline':1,'showinfo':0,'rel':0}">
    </youtube-player>
    <div class="content">
      <h2 *ngIf="data.title" class="title">{{ data.title }}</h2>
      <h3 *ngIf="data.subtitle" class="subtitle">{{ data.subtitle }}</h3>
      <p *ngIf="data.description" class="description" [innerHTML]="data.description"></p>
      <div class="row custom-video-info-actions">
        <div *ngIf="showVideoButton" class="button-container mr-3">
          <button
            type="button"
            class="btn btn-send mb-3"
            (click)="playVideo()">
              {{ 'CustomVideoInfoComponent.buttonText' | cxTranslate }}
          </button>
        </div>
        <ng-container *ngIf="buttons$ | async as buttons">
            <div class="button-container mr-3" *ngFor="let button of buttons">
              <ng-container *ngIf="button">
                <ng-container [ngSwitch]="!!button?.media?.url">
                  <ng-container *ngSwitchCase="true">
                    <ng-container *ngTemplateOutlet="downloadMedia; context: { link: button }"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="btn; context: { link: button }"></ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #downloadMedia let-link="link">
  <ng-container *ngIf="link && link?.media?.url; else btn">
    <cx-generic-link
      [url]="getDownloadUrl(link.media.url)"
      [target]="'_blank'"
      [class]="btnClass"
    >
      {{link.linkName}}
    </cx-generic-link>
  </ng-container>
</ng-template>

<ng-template #btn let-link="link">
  <ng-container *ngIf="link && link.linkName !== ''">
    <cx-generic-link
      *ngIf="link.url"
      [url]="link.url"
      [target]="getTarget(link.target)"
      [class]="btnClass"
    >
      {{link.linkName}}
    </cx-generic-link>
    <cx-generic-link
      *ngIf="!link.url && link.category"
      [routerLink]="{ cxRoute: 'category', params: link.category } | cxUrl"
      [target]="getTarget(link.target)"
    >
      {{link.linkName}}
    </cx-generic-link>
    <cx-generic-link
      *ngIf="!link.url && !link.category && link.product"
      [routerLink]="{ cxRoute: 'product', params: link.product } | cxUrl"
      [target]="getTarget(link.target)"
    >
      {{link.linkName}}
    </cx-generic-link>
  </ng-container>
</ng-template>
