import {Injectable} from "@angular/core";
import {ActionsSubject, select, Store} from "@ngrx/store";
import {createFrom, EventService, StateWithProcess} from "@spartacus/core";
import {CustomSendContactFromSuccessEvent} from "./custom-contact.events";
import {Observable, of} from "rxjs";
import {ofType} from "@ngrx/effects";
import {map, switchMap, withLatestFrom} from "rxjs/operators";
import {EcommerceType} from "../tag-manager-feature.model";
import {ContactFormActions} from "../../../../../custom/cms-components/atc/custom-contact-form/store/actions";
import {CustomContactFormSelectors} from "../../../../../custom/cms-components/atc/custom-contact-form/store/selector";
import {
  StateWithCustomContactForm
} from "../../../../../custom/cms-components/atc/custom-contact-form/store/custom-contact-form-state";
import {CsTicketCause} from "../../../../../model/contact-form.model";

@Injectable({providedIn: 'root'})
export class CustomContactEventBuilder {
  constructor(
    protected actionsSubject: ActionsSubject,
    protected event: EventService,
    protected store: Store<StateWithCustomContactForm | StateWithProcess<void>>,
  ) {
    this.register();
  }

  protected register(): void {
    this.registerSendContactFromSuccessEvents();
  }

  protected registerSendContactFromSuccessEvents(): void {
    this.event.register(
      CustomSendContactFromSuccessEvent,
      this.registerSendContactFormSuccess(),
    );
  }

  protected registerSendContactFormSuccess(): Observable<CustomSendContactFromSuccessEvent> {
    return this.actionsSubject.pipe(
      ofType(ContactFormActions.CUSTOM_SEND_CONTACT_FORM_SUCCESS),
      map((action: any) => action.payload),
      withLatestFrom(this.store.pipe(
        select(CustomContactFormSelectors.getCustomContactFormLoaderState),
        map((customContactFormListState: any): CsTicketCause[] => customContactFormListState.value)
      )),
      switchMap(([action, csTicketCauseList]): Observable<string> => {
        let selectedOption = action.templateCode;

        csTicketCauseList.map(ticket =>
          ticket.templates.map(template => {
            if (template.code === action.templateCode) selectedOption = template.name;
          })
        );

        return of(selectedOption);
      })
    ).pipe(
      map((causeName) => {
        return createFrom(
          CustomSendContactFromSuccessEvent,
          {
            event: EcommerceType.FORM,
            form_option: causeName || ''
          })
      })
    );
  }
}
