import { Friend, FriendsFilter, MyFriendsList } from '../../facade/friends.model';
import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { FRIENDS } from '../../../store/custom-friends-checkout.state';

export enum FriendsActionTypes {
  FRIENDS = '[Friends]',
  LOAD_FRIENDS = '[Friends] Load Friends ',
  LOAD_FRIENDS_SUCCESS = '[Friends] Load Friends Success',
  LOAD_FRIENDS_FAIL = '[Friends] Load Friends Fail',

  LOAD_FRIEND = '[Friends] Load Friend ',
  LOAD_FRIEND_SUCCESS = '[Friends] Load Friend Success',
  LOAD_FRIEND_FAIL = '[Friends] Load Friend Fail',
  PURGE_FRIEND = '[Friends] Purge Friend',

  UPDATE_FRIEND = '[Friends] Update Friend ',
  UPDATE_FRIEND_SUCCESS = '[Friends] Update Friend Success',
  UPDATE_FRIEND_FAIL = '[Friends] Update Friend Fail',

  INVITED_FRIEND = '[Friends] Invited Friend ',
  INVITED_FRIEND_SUCCESS = '[Friends] Invited Friend Success',
  INVITED_FRIEND_FAIL = '[Friends] Invited Friend Fail',

  CREATE_FRIEND = '[Friends] Create/Edit Friend',
  CREATE_FRIEND_FAIL = '[Friends] Create/Edit Friend Fail',
  CREATE_FRIEND_SUCCESS = '[Friends] Create/Edit Friend Success',

  REMOVE_FRIEND = '[Friends] Remove Friend',
  REMOVE_FRIEND_FAIL = '[Friends] Remove Friend Fail',
  REMOVE_FRIEND_SUCCESS = '[Friends] Remove Friend Success',
}

export class LoadFriends implements Action {
  readonly type = FriendsActionTypes.LOAD_FRIENDS;

  constructor(public payload: {
    userId: string;
    pageSize?: number;
    currentPage?: number;
    friendsFilter?: FriendsFilter;
  }) {}
}

export class LoadFriendsFail implements Action {
  readonly type = FriendsActionTypes.LOAD_FRIENDS_FAIL;

  constructor(public payload: any) {}
}

export class LoadFriendsSuccess implements Action {
  readonly type = FriendsActionTypes.LOAD_FRIENDS_SUCCESS;

  constructor(public payload: MyFriendsList) {}
}

export class LoadFriend implements Action {
  readonly type = FriendsActionTypes.LOAD_FRIEND;

  constructor(public payload: { userId: string; friendId: string }) {}
}

export class LoadFriendFail implements Action {
  readonly type = FriendsActionTypes.LOAD_FRIEND_FAIL;

  constructor(public payload: any) {}
}

export class PurgeFriend implements Action {
  readonly type = FriendsActionTypes.PURGE_FRIEND;
}

export class LoadFriendSuccess implements Action {
  readonly type = FriendsActionTypes.LOAD_FRIEND_SUCCESS;

  constructor(public payload: Friend) {}
}

export class CreateFriend implements Action {
  readonly type = FriendsActionTypes.CREATE_FRIEND;

  constructor(public payload: { userId: string; friend: Friend, pagination: number }) {}
}

export class CreateFriendFail implements Action {
  readonly type = FriendsActionTypes.CREATE_FRIEND_FAIL;

  constructor(public payload: any) {}
}

export class CreateFriendSuccess implements Action {
  readonly type = FriendsActionTypes.CREATE_FRIEND_SUCCESS;

  constructor(public payload: { friend: Friend; status: boolean }) {}
}

export class RemoveFriend implements Action {
  readonly type = FriendsActionTypes.REMOVE_FRIEND;

  constructor(public payload: { userId: string; friendId: string }) {}
}

export class RemoveFriendFail implements Action {
  readonly type = FriendsActionTypes.REMOVE_FRIEND_FAIL;

  constructor(public payload: any) {}
}

export class RemoveFriendSuccess implements Action {
  readonly type = FriendsActionTypes.REMOVE_FRIEND_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateFriend implements Action {
  readonly type = FriendsActionTypes.UPDATE_FRIEND;

  constructor(public payload: { userId: string; friend: Friend }) {}
}

export class UpdateFriendSuccess implements Action {
  readonly type = FriendsActionTypes.UPDATE_FRIEND_SUCCESS;

  constructor(public payload: { friend: Friend; status: boolean }) {}
}

export class UpdateFriendFail implements Action {
  readonly type = FriendsActionTypes.UPDATE_FRIEND_FAIL;

  constructor(public payload: any) {}
}

export class InvitedFriend implements Action {
  readonly type = FriendsActionTypes.INVITED_FRIEND;

  constructor(public payload: { userId: string; friend: Friend }) {}
}

export class InvitedFriendSuccess implements Action {
  readonly type = FriendsActionTypes.INVITED_FRIEND_SUCCESS;

  constructor(public payload: any) {}
}

export class InvitedFriendFail implements Action {
  readonly type = FriendsActionTypes.INVITED_FRIEND_FAIL;

  constructor(public payload: any) {}
}

export type CustomFriendsActions =
  | LoadFriends
  | LoadFriendsFail
  | LoadFriendsSuccess
  | LoadFriend
  | PurgeFriend
  | LoadFriendFail
  | LoadFriendSuccess
  | CreateFriend
  | CreateFriendFail
  | CreateFriendSuccess
  | RemoveFriend
  | RemoveFriendFail
  | RemoveFriendSuccess
  | UpdateFriend
  | UpdateFriendSuccess
  | UpdateFriendFail
  | InvitedFriend
  | InvitedFriendSuccess
  | InvitedFriendFail;
