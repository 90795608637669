import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, UrlModule } from '@spartacus/core';
import { CustomContentPagesSliderBannerComponent } from './custom-content-pages-slider-banner.component';
import { RouterModule } from '@angular/router';
import { CarouselModule, CmsParagraphModule, MediaModule } from '@spartacus/storefront';
import { HtmlSanitizeModule } from '../../../util/html-sanitize/html-sanitize.module';
import { CustomGenericLinkModule } from 'src/app/spartacus/custom/shared/components/custom-generic-link/custom-generic-link.module';

@NgModule({
  declarations: [CustomContentPagesSliderBannerComponent],
  imports: [
    CommonModule,
    RouterModule,
    CustomGenericLinkModule,
    MediaModule,
    UrlModule,
    HtmlSanitizeModule,
    CmsParagraphModule,
    CarouselModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomContentPagesSliderBannerComponent: {
          component: CustomContentPagesSliderBannerComponent,
        },
      },
    } as CmsConfig),
  ],
  entryComponents: [CustomContentPagesSliderBannerComponent],
  exports: [CustomContentPagesSliderBannerComponent],
})
export class CustomContentPagesSliderBannerModule {}
