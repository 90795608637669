import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import { CustomNewsDetailsModel } from '../custom-news.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GlobalMessageActions, GlobalMessageType, normalizeHttpError } from '@spartacus/core';
import { CustomNewsConnector } from '../../connectors/custom-news.connector';
import { AddMessage } from '@spartacus/core/src/global-message/store/actions/global-message.actions';
import {
  LOAD_NEWS_DETAILS,
  LoadNewsDetails,
  LoadNewsDetailsFail,
  LoadNewsDetailsSuccess,
} from '../actions/custom-news-details.actions';

@Injectable()
export class CustomNewsDetailsEffects {

  loadNewsDetails$: Observable<LoadNewsDetailsSuccess | LoadNewsDetailsFail | AddMessage> =
    createEffect(() => this.actions$.pipe(
      ofType(LOAD_NEWS_DETAILS),
      map((action: LoadNewsDetails) => action.payload),
      switchMap((newsId) =>
        this.newsConnector.getNewsDetails(newsId)
        .pipe(
          map((response: CustomNewsDetailsModel) => {
            return new LoadNewsDetailsSuccess(response);
          }),
          catchError((error) => {
            const normalizedResponse = normalizeHttpError(error);
            const message = normalizedResponse.details[0].message;
            const text = {
              key: message,
              params: null,
            };

            return from([
              new LoadNewsDetailsFail(normalizedResponse),
              new GlobalMessageActions.AddMessage({
                text,
                type: GlobalMessageType.MSG_TYPE_ERROR,
              }),
            ]);
          }),
        ),
      ),
    ));

  constructor(
    private actions$: Actions,
    private newsConnector: CustomNewsConnector,
  ) {
  }
}
