export function filterText(arrayValues: Array<string>, compare: string): boolean {
  const normalizedValue = removeDiacriticFromChars(compare)?.toLocaleLowerCase();
  let found = false;
  for (const value of arrayValues) {
    if (removeDiacriticFromChars(value)?.toLocaleLowerCase().includes(normalizedValue)) {
      return found = true;
    }
  }
  return found;
}

/**
 * Remove diacritic from accentuated characters.
 */
export function removeDiacriticFromChars(value: string): string {
  return value?.normalize('NFD')?.replace(/\p{Diacritic}/gu, '');
}

/**
 * Sanitizes the name so that it does not contain html elements.
 */
export function removeHtmlElements(value: string): string {
  return value.replace(/<[^>]*>/g, '');
}

/**
 * Change special characters for url to hyphen.
 * Consider using this after removeDiacriticFromChars, so that characters with diacritic are not completely removed
 */
export function changeUrlCharsToHyphen(value: string): string {
  return value.replace(/[^\w\d\._~]/g, '-');
}

/**
 * Unify multiple hyphens in a row into one hyphen and remove them from text start and end.
 */
export function unifyHyphens(value: string): string {
  return value.replace(/-+/g, '-').replace(/^-|-$/g, '');
}

/**
 * Make text pretty for url.
 */
export function prettyfyTextForUrl(value: string): string {
  if (!value) {
    return value;
  }
  value = removeHtmlElements(value);
  value = removeDiacriticFromChars(value);
  value = changeUrlCharsToHyphen(value);
  value = unifyHyphens(value);
  return value.toLocaleLowerCase();
}
