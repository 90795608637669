import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AsmMainUiComponent } from '@spartacus/asm/components';
import { AsmService } from '@spartacus/asm/core';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { CustomCsAgentAuthService } from '../../root/services/custom-csagent-auth.service';
import { CustomAsmComponentService } from '../services/custom-asm-component.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'cx-asm-main-ui',
  templateUrl: './custom-asm-main-ui.component.html',
  styleUrls: ['./custom-asm-main-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAsmMainUiComponent extends AsmMainUiComponent implements OnInit {
  constructor(
    protected authService: AuthService,
    protected csAgentAuthService: CustomCsAgentAuthService,
    protected asmComponentService: CustomAsmComponentService,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    protected asmService: AsmService,
    protected userAccountFacade: UserAccountFacade,
    protected launchDialogService: LaunchDialogService
  ) {
    super(authService,
      csAgentAuthService,
      asmComponentService,
      globalMessageService,
      routingService,
      asmService,
      userAccountFacade,
      launchDialogService
      );
  }

  loginCustomerSupportAgent({
    userId,
    password,
  }: {
    userId: string;
    password: string;
  }): void {
    this.csAgentAuthService.setAsmAgentUser(userId);
    this.csAgentAuthService.authorizeCustomerSupportAgent(userId, password);
  }

  getAgent(): Observable<string> {
    return of(this.csAgentAuthService.getAsmAgentUser());
  }

  startCustomerEmulationSessionUid({ uid }: { uid?: string }): void {
    if (uid) {
      this.startingCustomerSession = true;
      this.csAgentAuthService.startCustomerEmulationSession(uid);
    } else {
      this.globalMessageService.add(
        { key: 'asm.error.noCustomerId' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }
}
