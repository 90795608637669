import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule, StateModule, UrlModule } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePickerModule, FormErrorsModule, IconModule, ListNavigationModule, SpinnerModule } from '@spartacus/storefront';
import { CustomIconsModule } from 'src/app/spartacus/custom/cms-components/misc/icon/custom-icons.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CustomContactFormComponent } from './custom-contact-form.component';
import { reducerCustomContactFormProvider, reducerCustomContactFormToken } from './store/reducer/custom-contact-form.reducer';
import { CustomContactFormEffect } from './store/effects/custom-contact-form.effect';
import { CustomContactFormAdapter } from './connectors/custom-contact-form.adapter';
import { CustomOccContactFormAdapter } from './connectors/custom-occ-contact-form.adapter';
import { HtmlSanitizeModule } from 'src/app/custom/util/html-sanitize/html-sanitize.module';
import { CUSTOM_CONTACT_FORM_FEATURE } from './store/custom-contact-form-state';

@NgModule({
  declarations: [CustomContactFormComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCustomContactFormComponent: {
          component: CustomContactFormComponent,
        },
      },
    } as CmsConfig),
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgSelectModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    IconModule,
    CustomIconsModule,
    DatePickerModule,
    StateModule,
    HtmlSanitizeModule,
    SpinnerModule,
    StoreModule.forFeature(CUSTOM_CONTACT_FORM_FEATURE, reducerCustomContactFormToken),
    EffectsModule.forFeature([CustomContactFormEffect]),
  ],
  exports: [CustomContactFormComponent],
  providers: [
    reducerCustomContactFormProvider,
    {
      provide: CustomContactFormAdapter,
      useClass: CustomOccContactFormAdapter,
    },
  ],
})
export class CustomContactFormModule { }
