import { Observable } from 'rxjs';
import { User } from '@spartacus/user/account/root';
import { CartAdapter } from '@spartacus/cart/base/core';
import { Cart } from '@spartacus/cart/base/root';

export abstract class CustomCartAdapter extends CartAdapter {
  /**
   * Abstract method to set document identifier to customer.
   * @param userId
   * @param cartId
   * @param user
   */
  abstract setDocumentIdentifier(
    userId: string,
    cartId: string,
    user: User,
  ): Observable<{}>;

  /**
   * Abstract method to assign an extra data to the cart.
   * @param userId
   * @param cartId
   * @param extraData
   */
  abstract addExtraData(
    userId: string,
    cartId: string,
    extraData: {},
  ): Observable<{}>;

  /**
   * Abstract method to add gift box to the cart.
   * @param giftBoxProductCode
   */
  abstract addGiftbox(
    userId: string, 
    giftBoxProductCode: string,
  ): Observable<Cart>;

  abstract validateDocIdentifierAndTotalAmount(
    userId: string,
    cartId: string,
    user: User,
  ): Observable<{}>;
}
