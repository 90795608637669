import { StateUtils } from '@spartacus/core';
import { CustomNewsCategoriesModel } from '../custom-news.model';

export const NEWS_CATEGORIES = '[News] News Categories';
export const LOAD_NEWS_CATEGORIES = '[News] Load News Categories';
export const LOAD_NEWS_CATEGORIES_SUCCESS = '[News] Load News Categories Success';
export const LOAD_NEWS_CATEGORIES_FAIL = '[News] Load News Categories Fail';

export class LoadNewsCategories extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_NEWS_CATEGORIES;

  constructor() {
    super(NEWS_CATEGORIES);
  }
}

export class LoadNewsCategoriesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_NEWS_CATEGORIES_SUCCESS;

  constructor(public payload: CustomNewsCategoriesModel) {
    super(NEWS_CATEGORIES);
  }
}

export class LoadNewsCategoriesFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_NEWS_CATEGORIES_FAIL;

  constructor(public payload: any) {
    super(NEWS_CATEGORIES, payload);
  }
}

export type CustomNewsCategoriesActions =
  | LoadNewsCategories
  | LoadNewsCategoriesSuccess
  | LoadNewsCategoriesFail;
