import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, normalizeHttpError, OccEndpointsService } from '@spartacus/core';
import { Media } from '@spartacus/storefront';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderTicketDownloadService {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    private globalMessageService?: GlobalMessageService,
  ) {}

  /**
   * Retrieve order ticket download url
   * @param code order id (guid for anonymous user or code for logged user)
   * @param userId
   * @returns 
   */
  getOrderTicketDownloadUrl(
    userId: string,
    code: string,
  ): Observable<string> {
    const endpointUrl = this.occEndpointsService.buildUrl('orderTicket', {
      urlParams: { userId, code }
    });
    return this.http.get<Media>(endpointUrl).pipe(
      map((response) => {
        if (!response.downloadUrl) {
          return '';
        }
        return this.occEndpointsService.getBaseUrl({
          baseUrl: true,
          prefix: false,
          baseSite: false,
        }) + response.downloadUrl;
      }),
      catchError((error) => {
        this.globalMessageService.add(
          error?.error?.errors[0]?.message ?? error.name,
          GlobalMessageType.MSG_TYPE_ERROR
        );
        return of('');
      }),
    );
  }
}
