import { AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import {
  AuthService,
  EventService,
  RoutingService,
  UserIdService,
} from '@spartacus/core';
import { 
  Cart,
  PromotionLocation, PromotionResult,
  CartPageEvent
} from '@spartacus/cart/base/root';
import { CartConfigService, getCartIdByUserId, MultiCartService, SelectiveCartService } from '@spartacus/cart/base/core';
import { CartDetailsComponent } from '@spartacus/cart/base/components';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { CustomValidateCartService } from 'src/app/services/cart/validate-cart.service';
import { CUSTOM_LAUNCH_CALLER } from '../../../../spartacus-custom.module';
import { EntryGroup } from '../../../../../model/entry-groups.model';
import { CustomActiveCartService } from '../../../core/cart/facade/custom-active-cart.service';
import { filter, map, tap } from 'rxjs/operators';
import { CustomOrderEntry } from '../../../feature-libs/cart/root/models/cart.model';

@Component({
  selector: 'app-custom-cart-details',
  templateUrl: './custom-cart-details.component.html',
  styleUrls: ['./custom-cart-details.component.scss'],
})
export class CustomCartDetailsComponent extends CartDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  cart$: Observable<Cart>;
  entries$: Observable<CustomOrderEntry[]>;
  entryGroups$: Observable<EntryGroup[]>;
  cartLoaded$: Observable<boolean>;
  loggedIn = false;
  entry?: string;
  orderPromotions$: Observable<PromotionResult[]>;
  orderAppliedPromotions$: Observable<PromotionResult[]>;
  promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;
  promotions$: Observable<PromotionResult[]>;
  selectiveCartEnabled: boolean;
  subscription: Subscription = new Subscription();
  validatingCart: void | Observable<ComponentRef<any>>;
  availableWarehouses: string[] = []; 
  iconTypes = ICON_TYPE;

  constructor(
    protected multiCartService: MultiCartService,
    protected activeCartService: CustomActiveCartService,
    protected selectiveCartService: SelectiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected customValidateCartService: CustomValidateCartService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected userIdService: UserIdService,
    protected eventService: EventService,
    protected cartConfig: CartConfigService
  ) {
    super(activeCartService, selectiveCartService, authService, routingService, cartConfig);
    this.entryGroups$ = this.activeCartService.getOrderEntryGroups();
  }

  ngOnInit(): void {
    this.eventService.dispatch(new CartPageEvent());
    this.cart$ = this.activeCartService.getActive();
    this.cart$.subscribe(cart => {
      this.promotions$ = new Observable<PromotionResult[]>(obs => {
        obs.next(cart.appliedProductPromotions);
      })
      this.orderPromotions$ = new Observable<PromotionResult[]>(obs => {
        obs.next(cart.potentialOrderPromotions)
      });
      this.orderAppliedPromotions$ = new Observable<PromotionResult[]>(obs => {
        obs.next(cart.appliedOrderPromotions)
      });
    });

    this.entries$ = this.activeCartService
      .getEntries()
      .pipe(
        filter((entries) => entries.length > 0),
        map((entries) => {
          this.availableWarehouses = [];
          return entries.map((entry: CustomOrderEntry) => {
            let warehouseIndex = this.availableWarehouses.findIndex(w => w === entry.initialWarehouse) + 1;
            if(warehouseIndex === 0) {
              this.availableWarehouses.push(entry.initialWarehouse);
              warehouseIndex = this.availableWarehouses.findIndex(w => w === entry.initialWarehouse) + 1;
            }
            // Assign a warehouse index to be able to easily display the split delivery message
            return ({ ...entry, warehouseIndex });
          })
        }
      ))

    this.selectiveCartEnabled =  this.cartConfig.isSelectiveCartEnabled();

    this.cartLoaded$ = combineLatest([
      this.activeCartService.isStable(),
      this.selectiveCartEnabled
        ? this.selectiveCartService.isStable()
        : of(false),
      this.authService.isUserLoggedIn(),
    ]).pipe(
      tap(([, , loggedIn]) => (this.loggedIn = loggedIn)),
      map(([cartLoaded, sflLoaded, loggedIn]) =>
        loggedIn && this.selectiveCartEnabled
          ? cartLoaded && sflLoaded
          : cartLoaded,
      ),
    );
  }

  ngAfterViewInit(): void {
    this.customValidateCartService.validateCart();
    this.subscription.add(
      combineLatest([
        this.customValidateCartService.getValidateCartLoading(),
        this.customValidateCartService.getValidateCartSuccess(),
        this.customValidateCartService.getValidateCartError(),
      ]).subscribe(([simulateCartLoading, simulateCartSuccess, simulateCartError]) => {
        if (simulateCartLoading) {
          if (!this.validatingCart) {
            this.validatingCart = this.launchDialogService.launch(
              CUSTOM_LAUNCH_CALLER.VALIDATE_CART_SPINNER,
              this.vcr,
            );
          }
        }
        if (simulateCartError) {
          this.customValidateCartService.redirectToCartPage();
        }

        if (simulateCartSuccess) {
          this.clearValidateActions();
        }
      }),
    );
  }

  deleteCart(cart: Cart): void {
    let userId;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    this.multiCartService.deleteCart(getCartIdByUserId(cart, userId), userId);
  }

  clearValidateActions(): void {

    if (this.validatingCart) {
      this.validatingCart
        .subscribe((component) => {
          this.launchDialogService.clear(
            CUSTOM_LAUNCH_CALLER.VALIDATE_CART_SPINNER,
          );
          component.destroy();
        })
        .unsubscribe();
      this.customValidateCartService.clearValidateCart();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.clearValidateActions();
  }
}
