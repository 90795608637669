import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReturnRequestItemsComponent } from '@spartacus/order/components';

@Component({
  selector: 'cx-return-request-items',
  templateUrl: './custom-return-request-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReturnRequestItemsComponent extends ReturnRequestItemsComponent {
}
