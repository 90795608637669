import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GlobalMessageService, GlobalMessageType, RoutingService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  CreateFriend,
  InvitedFriend,
  LoadFriend,
  LoadFriends,
  PurgeFriend,
  RemoveFriend,
  UpdateFriend,
} from '../store/actions/custom-friends-users.action';
import { Friend, FriendsFilter, MyFriendsList } from './friends.model';
import { getFriendSelected, getFriendsUserList, getStatus } from '../store/selectors/custom-friends-users.selectors';
import { StateWithFriends } from '../../store/custom-friends-checkout.state';
import { take } from 'rxjs/operators';
import {
  CreateGuestFriend,
  LoadGuestFriend,
  ResetGuestFriends,
} from '../../../../../../custom/cms-components/myaccount/custom-friends/components/custom-register-friend-form/store/actions/custom-friends-guest.action';
import { StateWithGuest } from '../../../../../../custom/cms-components/myaccount/custom-friends/components/custom-register-friend-form/store/custom-friends-guest.state';
import { getGuestFriendState } from '../../../../../../custom/cms-components/myaccount/custom-friends/components/custom-register-friend-form/store/selector/custom-friends-guest.selector';
import {
  ResetFriendsEmails,
  SelectAllFriends,
} from '../../../../../../custom/cms-components/myaccount/custom-friends/components/custom-friend-email-form/store/actions/custom-friends-email.action';

@Injectable({
  providedIn: 'root',
})
export class CustomFriendsService {
  userId: string;
  token: string;

  constructor(
    protected routing: RoutingService,
    protected store: Store<StateWithFriends>,
    protected userIdService: UserIdService,
    protected guestStore: Store<StateWithGuest>,
    private globalMessageService: GlobalMessageService
  ) {
    this.userIdService
      .takeUserId()
      .pipe(take(1))
      .subscribe((userID: string) => (this.userId = userID));
    // this.token = this.route.queryParams.value.token;
    this.routing
      .getRouterState()
      .subscribe((route) => {
        this.token = route.state.queryParams.token;
      })
      .unsubscribe();
  }

  clearGuestFriends(): void {
    this.store.dispatch(new ResetGuestFriends());
  }

  getAllFriends(): Observable<MyFriendsList> {
    return this.store.pipe(select(getFriendsUserList));
  }

  getStatus(): Observable<boolean> {
    return this.store.pipe(select(getStatus));
  }

  loadAllFriends(pageSize: number, currentPage?: number, friendsFilter?: FriendsFilter): void {
    this.store.dispatch(new LoadFriends({
      userId: this.userId,
      pageSize,
      currentPage,
      friendsFilter
    }));
  }

  loadFriend(): Observable<Friend> {
    return this.store.pipe(select(getFriendSelected));
  }

  getGuestFriend(): void {
    this.store.dispatch(new LoadGuestFriend(this.token));
  }

    getGuestSelectedFriends(): Observable<any> {
    return this.guestStore.pipe(select(getGuestFriendState));
  }

  getFriend(friendId: string): void {
    this.store.dispatch(new LoadFriend({ userId: this.userId, friendId }));
  }

  createFriend(friend: Friend, pagination: number): void {
    this.store.dispatch(new CreateFriend({ userId: this.userId, friend, pagination }));
  }

  removeFriend(friendId: string): void {
    this.store.dispatch(new RemoveFriend({ userId: this.userId, friendId }));
  }

    createGuestFriend(friendSignUp: Friend): void {
    if (this.token?.length){
        this.store.dispatch(new CreateGuestFriend({  friendSignUp, token: this.token }));
    }else{
      this.globalMessageService.add({ key: 'myFriends.invalidToken' }, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }

  invitedFriend(friend: Friend): void {
    this.store.dispatch(new InvitedFriend({ userId: this.userId, friend }));
  }

  updateFriend(friend: Friend): void {
    this.store.dispatch(new UpdateFriend({ userId: this.userId, friend }));
  }

  purgeFriend(): void {
    this.store.dispatch(new PurgeFriend());
  }

  selectAllFriends(pageSize: number, currentPage?: number, friendsFilter?: FriendsFilter): void {
    this.store.dispatch(new SelectAllFriends({
      userId: this.userId,
      pageSize,
      currentPage,
      friendsFilter
    }));
  }

  deselectAllFriends(): void {
    this.store.dispatch(new ResetFriendsEmails());
  }
}
