import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AuthService,
  isNotNullable,
  Product,
} from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CustomWishListService } from 'src/app/spartacus/custom/cms-components/custom-wishlist/facade/custom-wish-list.service';
import { WishlistEntry } from 'src/app/model/wishlist.model';
import { CustomCurrentProductService } from "../../../core/product/services/custom-current-product.service";
import { CustomOrderEntry } from '../../../feature-libs/cart/root/models/cart.model';

@Component({
  selector: 'cx-add-to-wishlist',
  templateUrl: './custom-add-to-wish-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAddToWishListComponent  {
  product$: Observable<Product> = this.currentProductService.getProduct().pipe(
    filter(isNotNullable),
    tap((product) => this.setStockInfo(product))
  );

  wishListEntries$: Observable<
    WishlistEntry[]
  > = this.wishListService.getWishList().pipe(
    filter((wishlist) => Boolean(wishlist)),
    map((wishlist) => wishlist.wishlist?.entries)
  );

  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  loading$: Observable<boolean> = this.wishListService.getWishListLoaded();

  hasStock = false;
  iconTypes = ICON_TYPE;

  constructor(
    protected wishListService: CustomWishListService,
    protected currentProductService: CustomCurrentProductService,
    protected authService: AuthService
  ) {}

  add(product: Product): void {
    this.wishListService.addToWishlist(product.code);
  }

  remove(product: Product): void {
    this.wishListService.removeFromWishlist(product.code);
  }

  getProductInWishList(product: Product, entries: CustomOrderEntry[]): CustomOrderEntry {
    const item = entries.find((entry) => entry.product.code === product.code);
    return item;
  }

  private setStockInfo(product: Product): void {
    this.hasStock =
      product.stock && product.stock.stockLevelStatus !== 'outOfStock';
  }
}
