import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CustomGuestFriendState, GUEST_FRIEND_FEATURE, StateWithGuest } from '../custom-friends-guest.state';
import { Friend } from '../../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';


const getGuestFriend = (state: CustomGuestFriendState) => state.guest;

export const guestFriendFeatureSelector: MemoizedSelector<StateWithGuest,
  CustomGuestFriendState
  > = createFeatureSelector<CustomGuestFriendState>(GUEST_FRIEND_FEATURE);

export const getGuestFriendState: MemoizedSelector<StateWithGuest, Friend> = createSelector(
  guestFriendFeatureSelector,
  getGuestFriend
);

