import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  ActionTypes,
  LoadFriendsCheckout,
  LoadFriendsCheckoutCandidates,
  LoadFriendsCheckoutCandidatesFail,
  LoadFriendsCheckoutCandidatesSuccess,
  LoadFriendsCheckoutFail,
  LoadFriendsCheckoutSuccess,
  RemoveFriendsCheckout,
  RemoveFriendsCheckoutFail,
  RemoveFriendsCheckoutSuccess,
  ResetLoadFriendsCheckoutCandidates,
  SelectFriendsCheckout,
  SelectFriendsCheckoutFail,
  SelectFriendsCheckoutSuccess,
  UpdateFriendsCheckout,
  UpdateFriendsCheckoutFail,
  UpdateFriendsCheckoutSuccess
} from '../actions/custom-friends-checkout.action';
import { CustomFriendsCheckoutModel } from '../../facade/custom-friends-checkout.model';
import { CustomFriendsCheckoutConnector } from '../../connectors/custom-friends-checkout.connector';
import { normalizeHttpError } from '@spartacus/core';


@Injectable()
export class CustomFriendsCheckoutEffects {

  constructor(
    private actions$: Actions,
    private friendsCheckoutConnector: CustomFriendsCheckoutConnector,
  ) {}

  loadFriendsCheckoutCandidates$: Observable<LoadFriendsCheckoutCandidatesSuccess
    | LoadFriendsCheckoutCandidatesFail
    | ResetLoadFriendsCheckoutCandidates
    | LoadFriendsCheckout> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LOAD_FRIENDS_CHECKOUT_CANDIDATES),
    map((action: LoadFriendsCheckoutCandidates) => action.payload),
    mergeMap((payload: { userId, cartId }) => {
      const userId = payload.userId;
      const cartId = payload.cartId;
      return this.friendsCheckoutConnector.getAll(userId, cartId).pipe(
        switchMap((FriendsCheckoutList: CustomFriendsCheckoutModel[]) =>
          [
            new LoadFriendsCheckoutCandidatesSuccess(FriendsCheckoutList),
            new ResetLoadFriendsCheckoutCandidates(),
            new LoadFriendsCheckout({userId, cartId}),
          ]
        ),
        catchError((error) =>
          of(new LoadFriendsCheckoutCandidatesFail(normalizeHttpError(error)))
        )
      );
    }),
  ));

  loadFriendsCheckout$: Observable<LoadFriendsCheckoutSuccess
    | LoadFriendsCheckoutFail> = createEffect(() => this.actions$.pipe(
    ofType(),
    map((action: LoadFriendsCheckout) => action.payload),
    mergeMap((payload: { userId, cartId }) => {
      return this.friendsCheckoutConnector.get(payload.userId, payload.cartId).pipe(
        map((FriendsCheckout: CustomFriendsCheckoutModel) =>
          new LoadFriendsCheckoutSuccess(FriendsCheckout)
        ),
        catchError((error) =>
          of(new LoadFriendsCheckoutFail(normalizeHttpError(error)))
        )
      );
    }),
  ));

  updateFriendsCheckout$: Observable<UpdateFriendsCheckoutSuccess
    | UpdateFriendsCheckoutFail
    | SelectFriendsCheckout
    | ResetLoadFriendsCheckoutCandidates> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UPDATE_FRIENDS_CHECKOUT),
    map((action: UpdateFriendsCheckout) => action.payload),
    mergeMap((payload: { userId, cartId, friendsCheckoutUid }) => {
      const userId = payload.userId;
      const cartId = payload.cartId;
      return this.friendsCheckoutConnector.add(userId, cartId, payload.friendsCheckoutUid).pipe(
        switchMap((response: CustomFriendsCheckoutModel) => [
            new SelectFriendsCheckout({userId, cartId}),
            new UpdateFriendsCheckoutSuccess(response),
            new ResetLoadFriendsCheckoutCandidates(),
          ]
        ),
        catchError((error) =>
          of(new UpdateFriendsCheckoutFail(normalizeHttpError(error)))
        )
      );
    }),
  ));

  selectFriendsCheckout$: Observable<SelectFriendsCheckoutSuccess
    | SelectFriendsCheckoutFail> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SELECT_FRIENDS_CHECKOUT),
    map((action: SelectFriendsCheckout) => action.payload),
    mergeMap((payload: { userId, cartId }) => {
      return this.friendsCheckoutConnector.get(payload.userId, payload.cartId).pipe(
        map((friendsCheckout: CustomFriendsCheckoutModel) =>
          new SelectFriendsCheckoutSuccess(friendsCheckout)
        ),
        catchError((error) =>
          of(new SelectFriendsCheckoutFail(normalizeHttpError(error)))
        )
      );
    }),
  ));

  removeFriendsCheckout$: Observable<RemoveFriendsCheckoutSuccess |
    RemoveFriendsCheckoutFail> = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.REMOVE_FRIENDS_CHECKOUT),
    map((action: RemoveFriendsCheckout) => action.payload),
    mergeMap((payload: { userId, cartId }) => {
      return this.friendsCheckoutConnector.delete(payload.userId, payload.cartId).pipe(
        map((response) =>
          new RemoveFriendsCheckoutSuccess(response)
        ),
        catchError((error) =>
          of(new RemoveFriendsCheckoutFail(normalizeHttpError(error)))
        )
      );
    }),
  ));
}

