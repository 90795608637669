import { Component, HostBinding, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CmsService } from '@spartacus/core';
import { CmsHoverAwareImagesContainerComponent } from './custom-hover-aware-images-container.model';
import { CmsHoverAwareImageComponent } from '../custom-hover-aware-image/custom-hover-aware-image.model';

@Component({
  selector: 'app-custom-hover-aware-images-container',
  templateUrl: './custom-hover-aware-images-container.component.html',
})
export class CustomHoverAwareImagesContainerComponent {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;

  data$: Observable<CmsHoverAwareImagesContainerComponent> = this.componentData.data$.pipe(
    tap(component => {
        return this.initComponent(component);
      },
    ),
  );

  images$: Observable<Observable<CmsHoverAwareImageComponent>[]> = this.data$.pipe(
    map((data: any) =>
      data?.images?.trim().split(' ').map((code) =>
        this.cmsService.getComponentData(code)
      )
    ),
  );

  constructor(
    protected componentData: CmsComponentData<CmsHoverAwareImagesContainerComponent>,
    protected cmsService: CmsService,
  ) {
  }

  initComponent(item: CmsHoverAwareImagesContainerComponent): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth.toString() === 'true') {
      this.styleClasses += ' full-page-width';
    }
    if (item.backgroundColorCode) {
      this.backgroundColor = item.backgroundColorCode ?? '';
    }
  }
}
