import { Component } from '@angular/core';
import { SiteContextSelectorComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-site-context-selector',
  templateUrl: './custom-site-context-selector.component.html'
})
export class CustomSiteContextSelectorComponent extends SiteContextSelectorComponent {

}
