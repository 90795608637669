import { ComponentRef, Injectable } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ProcessSelectors, RoutingService, UserIdService } from '@spartacus/core';
import { StateWithUser } from '@spartacus/core/src/user/store/user-state';
import { StateWithProcess } from '@spartacus/core/src/process/store/process-state';
import {
  ORDER_ACTIONS_ID,
  OrderActionsReset,
} from './store/actions/custom-order.actions';
import { CUSTOM_LAUNCH_CALLER } from '../../../../../spartacus-custom.module';
import { OrderHistoryFacade } from '@spartacus/order/root';
import { OrderHistoryService, StateWithOrder } from '@spartacus/order/core';

@Injectable({
  providedIn: 'root',
})
export class CustomOrderActionsService extends OrderHistoryService {

  subscriptions = new Subscription();
  modalRef;
  processingOrder: void | Observable<ComponentRef<any>>;

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected modalService: LaunchDialogService,
    protected store: Store<StateWithOrder>,
    protected processStateStore: Store<StateWithProcess<void>>,
    protected userIdService: UserIdService,
    protected routingService: RoutingService
  ) {
    super(store, processStateStore, userIdService, routingService);
  }

  init(vcr): void {
    this.subscriptions.add(combineLatest([
        this.getOrderActionsState(),
        this.getOrderActionsLoading(),
        this.getOrderActionsSuccess(),
        this.getOrderActionsError(),
      ]).subscribe(([
        orderActionsState,
        orderActionsLoading,
        orderActionsSuccess,
        orderActionsError,
      ]) => {

        if (orderActionsLoading && !this.processingOrder) {
          this.processingOrder = this.launchDialogService.launch(
            CUSTOM_LAUNCH_CALLER.ORDER_ACTIONS_SPINNER,
            vcr,
          );
          this.modalService.closeDialog('');
        }

        if (orderActionsError || orderActionsSuccess) {
          if (this.processingOrder) {
            this.processingOrder.subscribe((component) => {
              this.launchDialogService.clear(CUSTOM_LAUNCH_CALLER.ORDER_ACTIONS_SPINNER);
              component.destroy();
            }).unsubscribe();
            if (orderActionsState.value) {
              this.routingService.go({ cxRoute: orderActionsState.value });
              this.modalService.closeDialog('');
            }
            this.store.dispatch(new OrderActionsReset());
          }
        }
      }),
    );
  }

  /**
   * Returns the load Order Actions loading flag
   */
  getOrderActionsLoading(): Observable<boolean> {
    return;
    /*return this.store.pipe(
      select(ProcessSelectors.getProcessLoadingFactory(ORDER_ACTIONS_ID)),
    );*/
  }

  /**
   * Returns the load rder Actions success flag
   */
  getOrderActionsSuccess(): Observable<boolean> {
    return;
    /*return this.store.pipe(
      select(ProcessSelectors.getProcessSuccessFactory(ORDER_ACTIONS_ID)),
    );*/
  }

  /**
   * Returns the load Order Actions state
   */
  getOrderActionsState(): Observable<any> {
    return;
    /*return this.store.pipe(
      select(ProcessSelectors.getProcessStateFactory(ORDER_ACTIONS_ID)),
    );*/
  }

  /**
   * Returns the load Order Actions error flag
   */
  getOrderActionsError(): Observable<boolean> {
    return;
    /*return this.store.pipe(
      select(ProcessSelectors.getProcessErrorFactory(ORDER_ACTIONS_ID)),
    );*/
  }
}
