import { Injectable } from '@angular/core';
import { Country, CountryType, COUNTRY_NORMALIZER, Occ, OccSiteAdapter, SiteAdapter } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomOccSiteAdapter extends OccSiteAdapter implements SiteAdapter {
  loadCountriesAll(type?: CountryType): Observable<Country[]> {
    return this.http
      .get<Occ.CountryList>(
        this.occEndpointsService.buildUrl('countriesAll', {
          urlParams: { undefined, type }
        })
      )
      .pipe(
        map((countryList) => countryList.countries),
        this.converterService.pipeableMany(COUNTRY_NORMALIZER)
      );
  }
}
