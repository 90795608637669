import { createSelector, MemoizedSelector } from '@ngrx/store';
import { CustomFriendsCheckoutModel } from '../../facade/custom-friends-checkout.model';

import { CustomFriendsCheckoutState } from '../custom-friends-checkout.state';
import { CustomFriendsState, StateWithFriends } from '../../../store/custom-friends-checkout.state';
import { getFriendsState } from '../../../store/selectors/custom-friends.selectors';

const getCustomFriendsCheckoutMembersSelector = (state: CustomFriendsCheckoutState) => state.members;
const getCustomFriendsCheckoutSelectedSelector = (state: CustomFriendsCheckoutState) => state.selected;


export const getFriendsCheckoutState: MemoizedSelector<StateWithFriends,
  CustomFriendsCheckoutState> = createSelector(getFriendsState, (state: CustomFriendsState) => state.checkout);

export const getFriendsCheckoutMembers: MemoizedSelector<StateWithFriends,
  CustomFriendsCheckoutModel[]> = createSelector(getFriendsCheckoutState, getCustomFriendsCheckoutMembersSelector);

export const getFriendsCheckoutSelected: MemoizedSelector<StateWithFriends,
  CustomFriendsCheckoutModel> = createSelector(getFriendsCheckoutState, getCustomFriendsCheckoutSelectedSelector);

