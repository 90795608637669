import { createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { StateWithOrder, OrderState, OrderSelectors } from '@spartacus/order/core';

export const getOrderDetailsLoading: MemoizedSelector<
  StateWithOrder,
  boolean
> = createSelector(
  OrderSelectors.getOrderState,
  (state: OrderState) =>
    StateUtils.loaderLoadingSelector(state.orderDetail)
);
