import { Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { AuthService, WindowRef } from '@spartacus/core';
import { CustomValidateCartService } from 'src/app/services/cart/validate-cart.service';
import { CustomAgeStatusService } from '../../../../../custom/cms-components/header/age-status/custom-age-status.service';
import { CustomExtraAppConfigService } from '../../../../../custom/config/services/custom-extra-app-config.service';
import { CookieService } from '../../../../../custom/config/services/cookie.service';
import { LAUNCH_CALLER, LaunchDialogService, PageLayoutService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomPopupShippingService } from 'src/app/custom/cms-components/user/popup-shipping/custom-popup-shipping.service';
import { Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-login',
  templateUrl: './custom-login.component.html',
  styleUrls: ['./custom-login.component.scss'],
})
export class CustomLoginComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  user$: Observable<boolean>;
  showModal: boolean;
  modalRef;
  closeResult: string;
  showAgePopup = this.customExtraAppConfigService.showAgePopup();
  currentBaseStore = '';
  ageStatus = 0;

  @ViewChild('open') element: ElementRef;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let stickyStart = this.winRef?.document.documentElement.scrollTop > 1;
    this.winRef?.document.getElementsByTagName("header")[0]?.classList.toggle('sticky', stickyStart);
    const isRioFrio = this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.contains('revolution');
    if (isRioFrio) {
      this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.toggle('sticky-banner', stickyStart);
    }
  }
  constructor(
    protected modalService: LaunchDialogService,
    protected auth: AuthService,
    protected cartService: CustomValidateCartService,
    protected customAgeStatusService: CustomAgeStatusService,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    protected userAccount: UserAccountFacade,
    protected vcr: ViewContainerRef,
    protected customPopupShippingService: CustomPopupShippingService,
    protected pageLayoutService: PageLayoutService,
    protected winRef?: WindowRef,
    protected cookieService?: CookieService
  ) { }

  ngOnInit(): void {
    this.user$ = this.auth.isUserLoggedIn();
    this.showModal = true;
    this.currentBaseStore = this.winRef?.localStorage?.getItem('currentbasestore');

    this.subscriptions.add(this.pageLayoutService.page$
      .subscribe((page) => this.showModal = (page.template !== 'PreferenceCenterPageTemplate')));

    if (this.showAgePopup) {
      this.ageStatus =  +this.cookieService.get('agestatus');
      if (this.ageStatus >= 0) {
        this.customAgeStatusService.updateAgeStatus(this.ageStatus);
      }
    } else {
      this.ageStatus = 1;
    }

    if (this.currentBaseStore) {
      const actualBaseStore = JSON.parse(this.currentBaseStore);
      if (actualBaseStore?.code?.trim().length) {
        if (this.ageStatus) {
          this.showModal = false;
        }
      } else {
        this.customPopupShippingService.purgeBaseStores();
      }
    }

    if (this.showModal && !!this.winRef?.localStorage) {
      this.openModal();
      this.showModal = false;
    }
    const hasHeaderBanner = this.winRef?.document.getElementsByClassName("HeaderText")[0]?.classList.contains('has-components');
    const isRioFrio = this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.contains('revolution');
    if (hasHeaderBanner) {
     this.winRef?.document.getElementsByTagName("header")[0]?.classList.add('has-header-banner');
     if (isRioFrio) {
      this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.add('has-header-banner');
     }
    } else {
      this.winRef?.document.getElementsByTagName("header")[0]?.classList.remove('has-header-banner');
      if (isRioFrio) {
        this.winRef?.document.getElementsByTagName("app-root")[0]?.classList.remove('has-header-banner');
      }
    }
  }

  dismissModal(reason?: any): void {
    this.modalService.closeDialog(reason);
  }

  openModal(): void {
    //CustomPopupShippingComponent
    const dialog = this.modalService.openDialog(
      LAUNCH_CALLER.HEADER_SHIPPING,
      undefined,
      this.vcr,
      {
        showButtonCancel: false,
      }
    );

    if (dialog) {
      this.subscriptions.add(dialog.subscribe());
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
