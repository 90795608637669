import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { CustomOrderDetailsService } from '../custom-order-details.service';
import { Subscription } from 'rxjs';
import { CustomOrderActionsService } from '../../custom-order-actions/custom-order-actions.service';
import { Store } from '@ngrx/store';
import {
  AuthService,
  EventService,
  OccEndpointsService,
  RoutingService,
  StateWithUser,
  UserIdService
} from '@spartacus/core';
import { StateWithProcess } from '@spartacus/core/src/process/store/process-state';
import { CustomReorderEvent } from "../../../../../../features/tracking/custom-events/order/custom-order.events";
import { EcommerceType } from "../../../../../../features/tracking/custom-events/tag-manager-feature.model";
import { OrderDetailActionsComponent } from '@spartacus/order/components';
import { Order } from '@spartacus/order/root';
import { CustomUserOrderService } from 'src/app/spartacus/custom/core/user/facade/custom-user-order.service';

@Component({
  selector: 'cx-order-details-actions',
  templateUrl: './custom-order-detail-actions.component.html',
})
export class CustomOrderDetailActionsComponent extends OrderDetailActionsComponent implements OnInit {

  subscriptions = new Subscription();
  loggedIn$ = this.authService.isUserLoggedIn();
  @ViewChild('open') element: ElementRef;

  constructor(
    protected orderDetailsService: CustomOrderDetailsService,
    protected customOrderActionsService: CustomOrderActionsService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected modalService: LaunchDialogService,
    protected store: Store<StateWithUser | StateWithProcess<void>>,
    protected routingService: RoutingService,
    protected occEndpoints: OccEndpointsService,
    protected userIdService: UserIdService,
    protected authService: AuthService,
    protected eventService: EventService,
    protected userOrderService: CustomUserOrderService,
  ) {
    super(orderDetailsService);
  }

  showOrderCancellationModal(order: Order): void {
    //CustomOrderCancellationComponent
    var dialog = this.modalService.openDialog(
      LAUNCH_CALLER.CUSTOM_ORDER_CANCELLATION,
      undefined,
      this.vcr,
      { order, redirectUrl: 'orders' }
    );
    if (dialog) {
      this.subscriptions.add(dialog.subscribe());
    }
  }

  showOrderRestorationModal(order: Order): void {
    //CustomOrderRestorationComponent
    var dialog = this.modalService.openDialog(
      LAUNCH_CALLER.CUSTOM_ORDER_RESTORATION,
      undefined,
      this.vcr,
      { order }
    );
    if (dialog) {
      this.subscriptions.add(dialog.subscribe());
    }
  }

  showOrderRestoreOnlyModal(order: Order): void {
    //CustomOrderRestoreOnlyComponent
    var dialog = this.modalService.openDialog(
      LAUNCH_CALLER.CUSTOM_ORDER_RESTORE_ONLY,
      undefined,
      this.vcr,
      { order }
    );
    if (dialog) {
      this.subscriptions.add(dialog.subscribe());
    }
  }

  ngOnInit(): void {
    this.customOrderActionsService.init(this.vcr);
  }

  dispatchReorderEvent(sourceBtnName: string): void {
    this.eventService.dispatch<CustomReorderEvent>(
      {
        event: EcommerceType.REORDER,
        button: sourceBtnName || ''
      },
      CustomReorderEvent)
  }
}
