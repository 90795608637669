import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomGenericLinkComponent } from './custom-generic-link.component';

@NgModule({
  imports: [CommonModule,
    RouterModule,
  ],
  declarations: [CustomGenericLinkComponent],
  exports: [CustomGenericLinkComponent],
})
export class CustomGenericLinkModule {}
