import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutLoginComponent } from '@spartacus/checkout/base/components';
import { AuthRedirectService, EventService } from "@spartacus/core";
import { EcommerceType } from "../../../../features/tracking/custom-events/tag-manager-feature.model";
import { CustomUserLoginEvent } from "../../../../features/tracking/custom-events/user/custom-user.events";
import { CustomValidators } from '../../../shared/utils/validators/custom-validators';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cx-checkout-login',
  templateUrl: './custom-checkout-login.component.html',
})
export class CustomCheckoutLoginComponent extends CheckoutLoginComponent {
  
  

  checkoutLoginForm: FormGroup = this.formBuilder.group(
    {
      email: ['', [Validators.required, Validators.maxLength(60), CustomValidators.emailValidator]],
      emailConfirmation: ['', [Validators.required]],
    },
    {
      validators: CustomValidators.emailsMustMatch(
        'email',
        'emailConfirmation'
      ),
    }
  );

  constructor(protected formBuilder: FormBuilder,
    protected authRedirectService: AuthRedirectService,
    protected activeCartService: ActiveCartService,
    private router: Router,
    protected eventService?: EventService,) {
    super(formBuilder, authRedirectService, activeCartService);
  }

  onSubmit() {
    if (this.checkoutLoginForm.valid) {
      const email = this.checkoutLoginForm.get('email').value;
      this.activeCartService.addEmail(email);
  
      if (!this.sub) {
        this.sub = this.activeCartService.getAssignedUser()
          .pipe(
            take(1) // Utilizando o operador take(1) para cancelar a inscrição após a primeira emissão
          )
          .subscribe(() => {
            if (this.activeCartService.isGuestCart()) {
              this.eventService.dispatch<CustomUserLoginEvent>(
                {
                  event: EcommerceType.GUEST_USER,
                  method: 'email',
                  uid: email
                },
                CustomUserLoginEvent,
              );
  
              this.router.navigate(['/checkout']);
            }
          });
      }
    } else {
      this.checkoutLoginForm.markAllAsTouched();
    }
  }
}
