import { Injectable } from '@angular/core';
import { ProductSearchService, RoutingService, SearchPageMetaResolver, TranslationService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { CustomBasePageMetaResolver } from '../../cms/page/custom-base-page-meta.resolver';
import { PageHreflangResolver } from '../../cms/page/custom-page.resolvers';
import { HrefLangMeta } from '../../cms/page/model/custom-page.model';

/**
 * Resolves the page data for the Search Result Page based on the
 * `PageType.CATEGORY_PAGE` and the `SearchResultsListPageTemplate` template.
 *
 * Only the page title is resolved in the standard implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomSearchPageMetaResolver extends SearchPageMetaResolver implements PageHreflangResolver {
  constructor(
    protected routingService: RoutingService,
    protected productSearchService: ProductSearchService,
    protected translation: TranslationService,
    protected basePageMetaResolver: CustomBasePageMetaResolver,
  ) {
    super(routingService, productSearchService, translation, basePageMetaResolver);
  }

  resolveHrefLangs(): Observable<HrefLangMeta[]> {
    return this.basePageMetaResolver?.resolveHrefLangs() ?? of();
  }
}
