import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import {
  BasePageMetaResolver,
  CanonicalUrlOptions,
  CmsService,
  RoutingPageMetaResolver,
  TranslationService,
  RoutingService,
  Language,
  BaseSiteService,
  LanguageService,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { CustomUrlLocalizedService } from 'src/app/custom/custom-localized-routing/custom-url-localized-service';
import { PageHreflangResolver } from './custom-page.resolvers';
import { HrefLangLanguageInfo, HrefLangMeta } from './model/custom-page.model';
import { CustomPageLinkService } from './routing/custom-page-link.service';

@Injectable({
  providedIn: 'root',
})

export class CustomBasePageMetaResolver extends BasePageMetaResolver implements PageHreflangResolver {
  constructor(
    protected cmsService: CmsService,
    protected translation: TranslationService,
    protected routingPageMetaResolver: RoutingPageMetaResolver,
    protected router: Router,
    protected pageLinkService: CustomPageLinkService,
    private routingService: RoutingService,
    protected baseSiteService?: BaseSiteService,
    protected languageService?: LanguageService,
    protected customUrlLocalizedService?: CustomUrlLocalizedService,
  ) {
    super(cmsService, translation, routingPageMetaResolver, router, pageLinkService);
  }

  resolveHrefLangs(options?: CanonicalUrlOptions): Observable<HrefLangMeta[]> {
    return this.router && this.pageLinkService
      ? this.router.events.pipe(
          filter((ev) => ev instanceof NavigationEnd),
          startWith(null),
          map(() => {
            let params: Params;
            let cxRoute: string;
            this.routingService
              .getRouterState()
              .subscribe((state) => {
                cxRoute = state.state.semanticRoute;
                if (cxRoute) {
                  params = state.state.params;
                }
              })
              .unsubscribe();
            const hrefLanguageInfo = this.getHrefLanguageInfo();
            const hrefLangMetas = [];
            const mappedParams = cxRoute ? this.customUrlLocalizedService.getMappedParams(cxRoute, params) : params;
            hrefLanguageInfo.langs.forEach(lang => {
              this.addHrefLang(hrefLangMetas, mappedParams, lang.isocode, hrefLanguageInfo.activeLanguage, cxRoute);
              if (hrefLanguageInfo.defaultLang.isocode === lang.isocode) {
                this.addHrefLang(hrefLangMetas, mappedParams, lang.isocode, hrefLanguageInfo.activeLanguage, cxRoute, true);
              }
            });
            return this.pageLinkService?.getHrefLangUrls(options, hrefLangMetas);
          })
        )
      : of();
  }

  getHrefLanguageInfo(): HrefLangLanguageInfo {
    let langs: Language[];
    let defaultLang: Language = {
       isocode: 'en',
    };
    let activeLanguage: string;
    this.baseSiteService.get()
      .pipe(filter((data) => Boolean(data)))
      .subscribe((data) => {
        langs = data.baseStore?.languages;
        if (!langs?.some((lang) => lang.isocode === defaultLang.isocode)) {
          defaultLang = data.baseStore?.defaultLanguage;
        }
      })
      .unsubscribe();
    this.languageService.getActive()
      .subscribe((lang) => {
        activeLanguage = lang;
      })
      .unsubscribe();
    return {
      langs,
      defaultLang,
      activeLanguage,
    };
  }

  addHrefLang(
    urls: HrefLangMeta[],
    params: { [param: string]: any; },
    language: string,
    activeLanguage: string,
    cxRoute: string,
    setAsDefault = false,
  ): void {
    let href: string;
    if (cxRoute) {
      href = this.routingService.getFullUrl({
        cxRoute,
        params,
        language
      });
    } else {
      href = this.pageLinkService!.actualLocationHref;
    }
    urls.push({
      href: activeLanguage === language ? href : href.replace(`/${activeLanguage}/`, `/${language}/`),
      lang: setAsDefault ? 'x-default' : language,
    });
  }
}
