import { CustomNewsCategoriesModel } from '../custom-news.model';
import {
  CustomNewsCategoriesActions,
  LOAD_NEWS_CATEGORIES_FAIL,
  LOAD_NEWS_CATEGORIES_SUCCESS,
} from '../actions/custom-news-categories.actions';

export const initialState: CustomNewsCategoriesModel = {
  categories: null,
};

export function reducerNewsCategories(
  state = initialState,
  action: CustomNewsCategoriesActions,
): CustomNewsCategoriesModel {
  switch (action.type) {
    case LOAD_NEWS_CATEGORIES_SUCCESS: {
      return action.payload;
    }
    case LOAD_NEWS_CATEGORIES_FAIL: {
      return initialState;
    }
  }
  return state;
}


