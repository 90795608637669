import { StateUtils } from '@spartacus/core';
import { ORDERS } from '@spartacus/order/core';
import { OrdersFilter } from 'src/app/model/filters.model';

export type CustomUserOrdersAction =
  | CustomLoadUserOrders;

export const CUSTOM_LOAD_USER_ORDERS = '[User] Custom Load User Orders Filter';

export class CustomLoadUserOrders extends StateUtils.LoaderLoadAction {
  readonly type = CUSTOM_LOAD_USER_ORDERS;
  constructor(
    public payload: {
      userId: string;
      pageSize?: number;
      currentPage?: number;
      sort?: string;
      replenishmentOrderCode?: string;
      ordersFilter?: OrdersFilter;
    }
  ) {
    super(ORDERS);
  }
}
