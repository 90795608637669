<ng-template #showShippingForm>
  <form (ngSubmit)="submitFormStores()" [formGroup]="shippingForm">
    <h2>{{ "loginForm.chooseShipping" | cxTranslate }}</h2>

    <ng-container *ngIf="showModifications">
      <div class="cart-modifications">
        <p>{{ "loginForm.cartModifications" | cxTranslate}}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="deliveryBaseStores?.length">
      <div class="form-check">
        <input type="radio" [value]="false" formControlName="pickupStore" id="homeDelivery"
               (change)="changeDeliveryMethod()" />
        <label for="homeDelivery" class="form-check-label form-radio-label">
          {{"loginForm.formShipping.homeDelivery.label" | cxTranslate}}
        </label>
        <cx-form-errors
          [control]="shippingForm.get('deliveryMethod')"
        ></cx-form-errors>
      </div>
      <div class="form-group">
        <ng-container *ngIf="!shippingForm.get('pickupStore').value">
          <ng-select
            [placeholder]="'loginForm.formShipping.homeDelivery.placeholder' | cxTranslate"
            formControlName="code"
            [searchable]="false"
            [clearable]="false"
            [items]="deliveryBaseStores"
            bindLabel="name"
            bindValue="code"
            (change)="onItemChange($event)"
          ></ng-select>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="pickupBaseStores?.length">
      <div class="form-check">
        <input type="radio" [value]="true" formControlName="pickupStore" id="pickUpWarehouse"
               (change)="changeDeliveryMethod()">
        <label for="pickUpWarehouse" class="form-check-label form-radio-label">
          {{"loginForm.formShipping.pickUpWarehouse.label" | cxTranslate}}
        </label>
        <cx-form-errors
          [control]="shippingForm.get('deliveryMethod')"
        ></cx-form-errors>
      </div>

      <div class="form-group">
        <ng-container *ngIf="shippingForm.get('pickupStore').value">
          <ng-select
            [placeholder]="'loginForm.formShipping.pickUpWarehouse.label' | cxTranslate"
            formControlName="code"
            [searchable]="false"
            [clearable]="false"
            [items]="pickupBaseStores"
            bindLabel="name"
            bindValue="code"
            (change)="onItemChange($event)"
          ></ng-select>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!shippingForm.value.code">
      <div class="cx-spinner">
        <cx-spinner></cx-spinner>
      </div>
    </ng-container>
    <div class="wrapper-button">
      <ng-container *ngIf="showButtonCancel | async">
        <button class="btn btn-block btn-send" (click)="dismissModal('')">
          {{ "common.cancel" | cxTranslate }}
        </button>
      </ng-container>
      <button type="submit" class="btn btn-block btn-send" [disabled]="!shippingForm.value.code">
        {{ "loginForm.accept" | cxTranslate }}
      </button>
    </div>
  </form>
</ng-template>

<div role="document" class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content">
    <div class="modal-body">
      <ng-container *ngIf="showAgePopup; else deliveryBaseStoresPopup">
        
        <ng-container *ngIf="(ageStatus$ | async) as ageStatus">
          <ng-container *ngIf="!ageStatus.accepted; else deliveryBaseStoresPopup">
            <app-custom-age-status></app-custom-age-status>
          </ng-container>
        </ng-container>
    
      </ng-container>
    
      <ng-template #deliveryBaseStoresPopup>
        <ng-container *ngTemplateOutlet="showShippingForm;"></ng-container>
      </ng-template>
    </div>
  </div>
</div>
