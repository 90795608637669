import { Observable } from 'rxjs';
import { Wishlist, WishlistModification } from 'src/app/model/wishlist.model';

export abstract class CustomWishlistAdapter {
  /**
   * Abstract method used to load bill data.
   *
   * @param userId The `userId` for given user
   */
  abstract load(userId: string): Observable<Wishlist>;

  /**
   * Abstract method used to add product to wishlist
   *
   * @param userId
   * @param productCode
   */
  abstract addToWishlist(
    userId: string,
    productCode: string,
  ): Observable<WishlistModification>;

  /**
   * Abstract method used to remove product from wishlist
   *
   * @param userId
   * @param productCode
   */
  abstract removeFromWishlist(
    userId: string,
    productCode: string,
  ): Observable<WishlistModification>;
}
