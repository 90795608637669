import { CartEntryAdapter } from '@spartacus/cart/base/core';
import { CartModification } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';

export abstract class CustomCartEntryAdapter extends CartEntryAdapter {
  /**
   * Abstract method used to add entry to cart with unit
   *
   * @param userId
   * @param cartId
   * @param productCode
   * @param quantity
   * @param unitCode
   */
  abstract addUnit(
    userId: string,
    cartId: string,
    productCode: string,
    quantity?: number,
    unitCode?: string
  ): Observable<CartModification>;
}
