import { AGE_STATUS_FEATURE, CustomAgeStatusState, StateWithAgeStatus } from '../custom-age.state';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { CustomAgeStatusModel } from '../reducers/custom-age-status.reducer';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';

export const ageStatusFeatureSelector: MemoizedSelector<StateWithAgeStatus,
  CustomAgeStatusState> = createFeatureSelector<CustomAgeStatusState>(AGE_STATUS_FEATURE);

export const getAgeStatusState: MemoizedSelector<StateWithAgeStatus,
  LoaderState<CustomAgeStatusModel>> = createSelector(ageStatusFeatureSelector, (state) => state.popup);

export const getAgeStatusLoading: MemoizedSelector<StateWithAgeStatus,
  boolean> = createSelector(getAgeStatusState, (state) => StateUtils.loaderLoadingSelector(state));

export const getAgeStatusSuccess: MemoizedSelector<StateWithAgeStatus,
  boolean> = createSelector(getAgeStatusState, (state) => StateUtils.loaderSuccessSelector(state));

export const getAgeStatus: MemoizedSelector<StateWithAgeStatus,
  CustomAgeStatusModel> = createSelector(getAgeStatusState, (state) => StateUtils.loaderValueSelector(state));
