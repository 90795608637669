import { Observable } from 'rxjs';
import { CustomOcc } from '../../../../../spartacus/custom/core/occ/occ-models/custom-occ.models';
import { CustomBaseStoreModel } from '../custom-base-store.model';

export abstract class CustomBaseStoreAdapter {

  abstract loadAll({ userId }): Observable<CustomOcc.CustomBaseStoreOCCModel>;

  abstract load(baseSiteId): Observable<CustomBaseStoreModel>;

}
