import { NgModule } from '@angular/core';
import { asmTranslationChunksConfig, asmTranslations } from '@spartacus/asm/assets';
import { AsmRootModule, ASM_FEATURE, CsAgentAuthService } from '@spartacus/asm/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { defaultAsmLayoutConfig } from '../../custom/feature-libs/asm/components/custom-default-asm-layout.config';
import { CustomCsAgentAuthService } from '../../custom/feature-libs/asm/root/services/custom-csagent-auth.service';

@NgModule({
  declarations: [],
  imports: [
    AsmRootModule,
  ],
  providers: [
    provideConfig({
      featureModules: {
        [ASM_FEATURE]: {
          module: () =>
            import('../../custom/feature-libs/asm/custom-asm.module').then((m) => m.CustomAsmModule),
        },
      }
    } as CmsConfig),
    provideConfig({
      i18n: {
        resources: asmTranslations,
        chunks: asmTranslationChunksConfig,
      },
    } as I18nConfig),
    provideConfig(defaultAsmLayoutConfig),
    {
      provide: CsAgentAuthService,
      useClass: CustomCsAgentAuthService,
    },
  ]
})
export class AsmFeatureModule { }

declare module '@spartacus/asm/core' {
  interface CustomerSearchOptions {
    customerListId?: string;
    query?: string;
    pageSize?: string | number;
  }
}
