import {NgModule} from '@angular/core';
import {provideConfig} from '@spartacus/core';
import {BaseTmsModule, TmsConfig} from '@spartacus/tracking/tms/core';
import {GtmModule} from '@spartacus/tracking/tms/gtm';
import {CustomNavigationEventModule} from './custom-events/navigation/custom-navigation-event.module';
import {CustomNavigationEvent} from './custom-events/navigation/custom-navigation.event';
import {environment} from 'src/environments/environment';
import {CategoryPageResultsEvent, HomePageEvent, SearchPageResultsEvent} from '@spartacus/storefront';
import {CustomCartEventModule} from './custom-events/cart/custom-cart-event.module';
import {
  CustomCartAddEntrySuccessEvent,
  CustomCartRemoveEntrySuccessEvent,
  CustomCartUpdateEntrySuccessEvent,
  CustomNavigateToCartEvent,
} from './custom-events/cart/custom-cart.events';
import {CustomCheckoutEventModule} from './custom-events/checkout/place-order/custom-checkout-event.module';
import {CustomOrderPlacedEvent} from './custom-events/checkout/place-order/custom-custom-checkout.events';
import {
  CustomCheckoutDeliveryModeCompleteEvent,
  CustomCheckoutPaymentModeCompleteEvent,
  CustomCheckoutStepsEvent,
} from './custom-events/checkout/checkout-steps/custom-checkout-steps.events';
import {
  CustomCheckoutEffects
} from './custom-events/checkout/checkout-steps/store/effects/custom-checkout-steps-events.effect';
import {
  CustomProductDetailsPageEvent,
  CustomProductDetailsSelectEvent
} from './custom-events/product/custom-product-page.events';
import {CustomCleanEcommerceEvent} from './custom-events/common/custom-common.events';
import {CustomCategoryPageEvent} from "./custom-events/category/custom-category-page.events";
import {CustomCategoryPageEventModule} from "./custom-events/category/custom-category-page-event.module";
import {
  CustomCheckoutNavigationEventModule
} from "./custom-events/checkout/navigation/custom-checkout-navigation-event.module";
import {CustomNavigateToCheckoutEvent} from "./custom-events/checkout/navigation/custom-checkout-navigation.events";
import {CustomOrderRefundEvent, CustomReorderEvent} from "./custom-events/order/custom-order.events";
import {CustomOrderEventModule} from "./custom-events/order/custom-order-event.module";
import {
  CustomGuestFriendRegisterEvent,
  CustomUserAddProductInterestEvent,
  CustomUserLoginEvent
} from "./custom-events/user/custom-user.events";
import {CustomContactEventModule} from "./custom-events/contact/custom-contact-event.module";
import {CustomSendContactFromSuccessEvent} from "./custom-events/contact/custom-contact.events";
import {CustomUserEventModule} from "./custom-events/user/custom-user-event.module";
import {CustomPromotionEvent} from "./custom-events/cms/custom-cms.events";
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    BaseTmsModule.forRoot(),
    GtmModule,
    CustomNavigationEventModule,
    CustomCategoryPageEventModule,
    CustomCartEventModule,
    CustomCheckoutNavigationEventModule,
    CustomCheckoutEventModule,
    //CustomCheckoutStepsEventModule,
    //CustomProductPageEventModule,
    CustomOrderEventModule,
    CustomContactEventModule,
    CustomUserEventModule,
    EffectsModule.forFeature([
      CustomCheckoutEffects,
    ]),
  ],
  providers: [
    provideConfig({
      tagManager: {
        gtm: {
          gtmId: environment?.gtmId?.osbornestore,
          events: [
            /*Cleaner event*/
            CustomCleanEcommerceEvent,
            /*Pages Events*/
            HomePageEvent,
            CustomNavigationEvent,
            CustomCategoryPageEvent,
            // PageEvent,
            SearchPageResultsEvent,
            CategoryPageResultsEvent,
            CustomProductDetailsPageEvent,
            CustomProductDetailsSelectEvent,
            // SearchBoxSuggestionSelectedEvent,
            // SearchBoxProductSelectedEvent,
            /*Cart Events*/
            CustomNavigateToCartEvent,
            CustomCartUpdateEntrySuccessEvent,
            CustomCartAddEntrySuccessEvent,
            CustomCartRemoveEntrySuccessEvent,
            /* Checkout Steps Events */
            CustomNavigateToCheckoutEvent,
            CustomCheckoutStepsEvent,
            CustomCheckoutDeliveryModeCompleteEvent,
            CustomCheckoutPaymentModeCompleteEvent,
            /*Place order Events*/
            CustomOrderPlacedEvent,
            CustomOrderRefundEvent,
            CustomReorderEvent,
            /* User events */
            CustomUserLoginEvent,
            CustomGuestFriendRegisterEvent,
            CustomUserAddProductInterestEvent,
            /* Contact Form Events */
            CustomSendContactFromSuccessEvent,
            /* CMS events */
            CustomPromotionEvent,
          ],
          debug: !environment.production,
        },
      },
    } as TmsConfig),
  ],
})
export class TagManagerFeatureModule {
}
