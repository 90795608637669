<h2 class="page-title">
  {{ 'returnRequest.pageTitle' | cxTranslate }}
</h2>
<ng-container *ngIf="returnRequest$ | async as returnRequest">
  <div class="cx-header row">
    <div class="cx-detail col-sm-12 col-md-4">
      <div class="cx-detail-label">
        {{ 'returnRequest.returnRequestId' | cxTranslate }}
      </div>
      <div class="cx-detail-value">
        {{ returnRequest.rma }}
      </div>
    </div>
    <div class="cx-detail col-sm-12 col-md-4">
      <div class="cx-detail-label">
        {{ 'returnRequest.orderCode' | cxTranslate }}
      </div>
      <div class="cx-detail-value">
        {{ returnRequest.order?.code }}
      </div>
    </div>
    <div
      *ngIf="returnRequest.returnOrder?.code"
      class="cx-detail col-sm-12 col-md-4"
    >
      <div class="cx-detail-label">
        {{ 'returnRequest.orderReturnId' | cxTranslate }}
      </div>
      <div class="cx-detail-value">
        {{ returnRequest.returnOrder?.code }}
      </div>
    </div>
    <div
      *ngIf="returnRequest.status"
      class="cx-detail col-sm-12 col-md-4"
    >
      <div class="cx-detail-label">
        {{ 'returnRequest.status' | cxTranslate }}
      </div>
      <div class="cx-detail-value">
        {{
          'returnRequestList.statusDisplay'
            | cxTranslate: { context: returnRequest.status }
        }}
      </div>
    </div>
  </div>
</ng-container>
