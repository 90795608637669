import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { EmarsysContactInfo } from '../../../../../model/preference-center.model';
import { CUSTOM_PREFERENCE_CENTER_CORE_FEATURE } from '../custom-feature-name';

export function customPreferenceCenterFacadeFactory() {
  return facadeFactory({
    facade: CustomPreferenceCenterFacade,
    feature: CUSTOM_PREFERENCE_CENTER_CORE_FEATURE,
    methods: [
      'loadPreferenceCenterDetails',
      'updatePreferenceCenterDetails',
    ],
    async: true,
  });
}

@Injectable({
  providedIn: 'root',
  useFactory: customPreferenceCenterFacadeFactory,
})
export abstract class CustomPreferenceCenterFacade {
  abstract loadPreferenceCenterDetails(
    uid: string,
    storeId: string,
  ): Observable<EmarsysContactInfo>;

  abstract updatePreferenceCenterDetails(
    uid: string, 
    cid: string, 
    llid: string,
    details: EmarsysContactInfo,
  ): Observable<EmarsysContactInfo>;
}
