import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
  selector: 'recaptcha',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './recaptcha.component.html',
})
export class RecaptchaComponent {
  @Output() currentWidgetId = new EventEmitter<any>();
  widgetId: string;

  constructor() {
  }

  getWidgetItemId(value): void {
    this.widgetId = value;
    this.setWidgetId();
  }

  setWidgetId(): void {
    this.currentWidgetId.emit(this.widgetId);
  }
}
