import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  InterceptorUtil,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { Wishlist, WishlistModification } from 'src/app/model/wishlist.model';
import { CustomOcc } from 'src/app/spartacus/custom/core/occ/occ-models/custom-occ.models';
import { WISHLIST_MODIFICATION_NORMALIZER, WISHLIST_NORMALIZER } from './custom-wishlist-converters';
import { CustomWishlistAdapter } from './custom-wishlist.adapter';

@Injectable()
export class CustomOccWishlistAdapter implements CustomWishlistAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
  ) { }

  public load(userId: string): Observable<Wishlist> {
    const url = this.occEndpoints.buildUrl('wishlists', {
      urlParams: { userId }
    });

    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.http
      .put<CustomOcc.Wishlist>(url, { headers })
      .pipe(this.converter.pipeable(WISHLIST_NORMALIZER));
  }

  public addToWishlist(
    userId: string,
    productCode: string,
  ): Observable<WishlistModification> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpoints.buildUrl('wishlist', {
      urlParams: { userId }
    });
    const params: HttpParams = new HttpParams()
      .set('productCode', productCode);
    return this.http.post(url, {}, { headers, params })
    .pipe(this.converter.pipeable(WISHLIST_MODIFICATION_NORMALIZER));
  }

  public removeFromWishlist(
    userId: string,
    productCode: string,
  ): Observable<WishlistModification> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.occEndpoints.buildUrl('wishlist', {
      urlParams: { userId }
    });
    const params: HttpParams = new HttpParams()
      .set('productCode', productCode);
    return this.http.delete(url, { headers, params })
    .pipe(this.converter.pipeable(WISHLIST_MODIFICATION_NORMALIZER));
  }
}
