import { LayoutConfig, DIALOG_TYPE } from "@spartacus/storefront";
import { CustomAddedToCartDialogComponent } from "./custom-add-to-cart/custom-added-to-cart-dialog/custom-added-to-cart-dialog.component";
import { CustomAddProductToCartWithGiftboxDialogComponent } from "src/app/custom/cms-components/giftbox/custom-add-product-to-cart-with-giftbox-dialog/custom-add-product-to-cart-with-giftbox-dialog.component";
import { CustomPaymentTypeModalComponent } from "../checkout/components/payment-method/payment-type/custom-payment-type-modal.component";

export const customAddedToCartDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        CUSTOM_ADDED_TO_CART: {
            inlineRoot: true,
            component: CustomAddedToCartDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG
        },
    },
};

export const customAddedToCartGiftBoxDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        CUSTOM_ADDED_TO_CART_GIFT_BOX: {
            inlineRoot: true,
            component: CustomAddProductToCartWithGiftboxDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG
        },
    },
};

export const customDeletePaymentTypeDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        PAYMENT_TYPE: {
            inlineRoot: true,
            component: CustomPaymentTypeModalComponent,
            dialogType: DIALOG_TYPE.DIALOG
        },
    },
};