import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCategorySummaryComponent } from './custom-category-summary.component';
import { CmsConfig, PageMetaModule, ConfigModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomCategorySummaryComponent],
  imports: [
    CommonModule,
    PageMetaModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomCategorySummaryComponent: {
            component: CustomCategorySummaryComponent,
        }
      }
    } as CmsConfig)
  ],
})
export class CustomCategorySummaryModule { }
