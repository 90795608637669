<ng-container *ngIf="item.image">
  <cx-media [container]="item.image"></cx-media>
</ng-container>
<ng-container *ngIf="item.title">
  <div class="info-title">
    {{ item.title }}
  </div>
</ng-container>
<ng-container *ngIf="item.description">
  <cx-paragraph
    [componentData]="item.description"
  ></cx-paragraph>
</ng-container>
<cx-generic-link *ngIf="item.buttonText" [class]="btnClass" [url]="item.url">
  {{item.buttonText}}
</cx-generic-link>
