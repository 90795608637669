import { CustomNewsDetailsModel } from '../custom-news.model';
import {
  CLEAR_NEWS_DETAILS,
  CustomNewsDetailsActions,
  LOAD_NEWS_DETAILS_FAIL,
  LOAD_NEWS_DETAILS_SUCCESS,
} from '../actions/custom-news-details.actions';

export const initialState: CustomNewsDetailsModel = {
  coverImage: null,
  label: null,
  newsTitle: null,
  path: null,
  publishDate: null,
  thumbnailDescription: null,
  uid: null,
};

export function reducerNewsDetails(
  state = initialState,
  action: CustomNewsDetailsActions,
): CustomNewsDetailsModel {
  switch (action.type) {
    case LOAD_NEWS_DETAILS_SUCCESS: {
      return action.payload;
    }
    case LOAD_NEWS_DETAILS_FAIL || CLEAR_NEWS_DETAILS: {
      return initialState;
    }
  }
  return state;
}


