import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomImageInfoContainerComponent } from './custom-image-info-container.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomImageInfoModule } from '../custom-image-info/custom-image-info.module';

@NgModule({
  declarations: [CustomImageInfoContainerComponent],
  imports: [
    CommonModule,
    CustomImageInfoModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomImageInfoContainerComponent: {
          component: CustomImageInfoContainerComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [CustomImageInfoContainerComponent],
})
export class CustomImageInfoContainerModule { }
