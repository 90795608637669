<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
      <div class="cx-title-wrapper">
        <div class="title">{{ "cartDetails.cartName" | cxTranslate }}</div>
        <div class="delivery-message" *ngIf="availableWarehouses.length > 1">
          <cx-icon [cxIcon]="iconTypes.INFO" />
          <p>{{ "cartDetails.deliveryMessage" | cxTranslate }}</p>
        </div>
      </div>

      <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
        <!-- <cx-promotions [promotions]="orderPromotions"></cx-promotions> -->
        <app-custom-promotions [promotions]="orderPromotions"></app-custom-promotions>
      </ng-container>
      <ng-container *ngIf="orderAppliedPromotions$ | async as orderAppliedPromotions">
        <!-- <cx-promotions class="cx-promotions-applied" [promotions]="orderAppliedPromotions"></cx-promotions> -->
        <app-custom-promotions class="cx-promotions-applied" [promotions]="orderAppliedPromotions"></app-custom-promotions>
      </ng-container>
      <cx-cart-item-list
        [items]="entries"
        [cartIsLoading]="!(cartLoaded$ | async)"
        [promotionLocation]="promotionLocation"
        [options]="{
          isSaveForLater: false,
          optionalBtn: saveForLaterBtn
        }"
        [warehousesCount]="availableWarehouses.length"
      ></cx-cart-item-list>
    </div>
  </ng-container>

  <ng-template let-ctx #saveForLaterBtn>
    <div
      *ngIf="selectiveCartEnabled"
      class="col-md-3 col-lg-3 col-xl-3 cx-sfl-btn"
    >
      <button
        class="link cx-action-link"
        [disabled]="ctx.loading"
        (click)="saveForLater(ctx.item)"
        type="button"
      >
        {{ "saveForLaterItems.saveForLater" | cxTranslate }}
      </button>
    </div>
  </ng-template>

  <div class="wrapper-buttons">
    <a
      [routerLink]="{ cxRoute: 'home' } | cxUrl"
      class="btn btn-block btn-send"
    >
      {{ "cartDetails.continueShopping" | cxTranslate }}</a
    >
    <button class="btn btn-send" (click)="deleteCart(cart)">
      {{ "cartDetails.emptyCart" | cxTranslate }}
    </button>
  </div>
</ng-container>
