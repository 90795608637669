import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPreferenceCenterFormComponent } from './custom-preference-center-form.component';
import { CmsConfig, ConfigModule, CxDatePipe, I18nModule, provideConfig } from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule, IconModule, MediaModule, SpinnerModule } from '@spartacus/storefront';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgSelectModule,
    IconModule,
    MediaModule,
    SpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CustomPreferenceCenterFormComponent: {
          component: CustomPreferenceCenterFormComponent,
        },
      },
    }),
  ],
  providers: [
    CxDatePipe,
    provideConfig({
      context: {
        currency: ['EUR', 'USD'],
        language: ['es', 'en'],
        urlParameters: ['language', 'currency'],
      },
    }),
  ],
  declarations: [CustomPreferenceCenterFormComponent],
  exports: [CustomPreferenceCenterFormComponent],
})
export class CustomPreferenceCenterFormModule {}
