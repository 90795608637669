import { NgModule } from '@angular/core';
import { customFacadeProviders } from './facade/custom-facade-providers';
import { CustomPreferenceCenterConnector } from './connectors/custom-preference-center.connector';

@NgModule({
  providers: [
    ...customFacadeProviders,
    CustomPreferenceCenterConnector,
  ],
})
export class CustomPreferenceCenterCoreModule {}
