import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StateModule } from '@spartacus/core';
import { FriendsEffects } from './effects/custom-friend-users.effect';
import { FriendsReducerProvider } from '../../store/reducers/custom-friends.reducer';


@NgModule({
    imports: [CommonModule, StateModule, EffectsModule.forFeature(FriendsEffects), RouterModule],
    providers: [FriendsReducerProvider],
})
export class CustomFriendsUsersStoreModule {}
