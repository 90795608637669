<button
  class="btn cx-product-layout"
  [ngClass]="buttonClass"
  (click)="changeMode()"
>
  <cx-icon
    *ngIf="viewMode === iconTypes.GRID"
    [type]="iconTypes.GRID"
  ></cx-icon>
  <cx-icon
    *ngIf="viewMode === iconTypes.LIST"
    [type]="iconTypes.LIST"
  ></cx-icon>
</button>
