import {Component, HostBinding, Inject, OnInit, ViewChild} from '@angular/core';
import { CmsCustomFullWidthVideoComponent } from './custom-video-full-width.model';
import { Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { tap } from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-custom-video-full-width',
  templateUrl: './custom-video-full-width.component.html',
  styleUrls: ['./custom-video-full-width.component.scss']
})
export class CustomVideoFullWidthComponent implements OnInit {

  @HostBinding('class') styleClasses: string;
  @ViewChild('player') player: any;

  data$: Observable<CmsCustomFullWidthVideoComponent> = this.component.data$.pipe(
    tap(data => this.styleClasses = `full-page-width ${data?.styleClasses ? data.styleClasses : ''}`)
  );

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected component: CmsComponentData<CmsCustomFullWidthVideoComponent>,
  ) { }

  ngOnInit(): void {
    const youtubeApiId = 'youtubeIframeApi';
    if (!this.document.getElementById(youtubeApiId)) {
      const tag = this.document.createElement('script');
      tag.id = youtubeApiId;
      tag.src = 'https://www.youtube.com/iframe_api';
      this.document.body.appendChild(tag);
    }
  }

}
