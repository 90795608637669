import { Injectable } from '@angular/core';
import { CustomerEmailModel } from '../custom-friend-email-form/CustomerEmail.model';

@Injectable({
  providedIn: 'root',
})
export class CustomMyFriendListService {

  constructor() { }

  convertFriendToEmailData(friend): CustomerEmailModel {
    const firstNameLastName = friend.firstName + (friend?.lastName?.length ? ' ' + friend.lastName : '');
    return {
      name: firstNameLastName,
      email: friend.uid,
    };
  }

}
