import { StateUtils } from '@spartacus/core';
import { Friend } from '../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/friends.model';
import { CustomerEmailModel } from '../CustomerEmail.model';

export const FRIEND_EMAIL_FEATURE = 'friendsEmail';
export const FRIEND_EMAILS = '[Friend Emails] Friends Emails';

export interface CustomFriendEmailsState {
  entities: StateUtils.LoaderState<CustomerEmailModel[]>;
}

export interface StateWithFriendsEmails {
  [FRIEND_EMAIL_FEATURE]: CustomFriendEmailsState;
}

