import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { BASE_STORE_FEATURE, CustomBaseStoreState, StateWithBaseStore } from '../custom-base-store.state';
import { CustomBaseStoreModel } from '../../custom-base-store.model';

const getCustomBaseStoreList = (state: CustomBaseStoreState) => state.baseStoreList;
const getCustomBaseStoreSelected = (state: CustomBaseStoreState) => state.selected;


export const baseStoreFeatureSelector: MemoizedSelector<StateWithBaseStore,
  CustomBaseStoreState> = createFeatureSelector<CustomBaseStoreState>(BASE_STORE_FEATURE);

export const getBaseStoreList: MemoizedSelector<StateWithBaseStore,
  CustomBaseStoreModel[]> = createSelector(baseStoreFeatureSelector, getCustomBaseStoreList);

export const getBaseStoreSelected: MemoizedSelector<StateWithBaseStore,
  CustomBaseStoreModel> = createSelector(baseStoreFeatureSelector, getCustomBaseStoreSelected);

