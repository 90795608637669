import { Component } from '@angular/core';
import { CustomCheckoutStepsEventService } from '../../../../../features/tracking/custom-events/checkout/checkout-steps/custom-checkout-steps-event.service';
import { CheckoutProgressComponent, CheckoutStepService } from '@spartacus/checkout/base/components';

@Component({
  selector: 'cx-checkout-progress',
  templateUrl: './custom-checkout-progress.component.html',
})
export class CustomCheckoutProgressComponent extends CheckoutProgressComponent {
  constructor(
    checkoutStepService: CheckoutStepService,
    private customCheckoutStepsEventService: CustomCheckoutStepsEventService,
  ) {
    super(checkoutStepService);

    this.checkoutStepService.activeStepIndex$
      .subscribe(index => {
        this.customCheckoutStepsEventService.getCheckoutStep(index + 1
        )}).unsubscribe();
  }

}
