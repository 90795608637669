import { Component } from '@angular/core';
import {ICON_TYPE, LaunchDialogService} from '@spartacus/storefront';

@Component({
    selector: 'app-custom-add-product-to-cart-with-giftbox-dialog',
    templateUrl: './custom-add-product-to-cart-with-giftbox-dialog.component.html',
})
export class CustomAddProductToCartWithGiftboxDialogComponent {
    iconTypes = ICON_TYPE;
    constructor(private launchDialogService: LaunchDialogService) {}

    accept(isAccepted: boolean): void {
      this.launchDialogService.closeDialog(isAccepted);
    }

    close(reason: string): void {
      this.launchDialogService.closeDialog(reason);
    }
}
