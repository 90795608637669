import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';
import { CustomSocialMediaShareComponent } from './custom-social-media-share.component';

@NgModule({
  declarations: [CustomSocialMediaShareComponent],
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    MediaModule,
    I18nModule,
    ConfigModule.forRoot({
      cmsComponents: {
        CmsCustomSocialMediaShareComponent: {
          component: CustomSocialMediaShareComponent,
        },
      },
    } as CmsConfig),
  ],
  entryComponents: [CustomSocialMediaShareComponent],
  exports: [CustomSocialMediaShareComponent],
})
export class CustomSocialMediaShareModule {}
