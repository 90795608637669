import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomOrderActionsAdapter } from './custom-order-actions.adapter';
import { Cart } from '@spartacus/cart/base/root';
import { OrdersFilter } from 'src/app/model/filters.model';
import { OrderHistoryList } from '@spartacus/order/root';

@Injectable({
  providedIn: 'root',
})

export class CustomOrderActionsConnector {
  constructor(protected adapter: CustomOrderActionsAdapter) { }

  orderCancellation({ userId, orderCode }): Observable<any> {
    return this.adapter.orderCancellation({ userId, orderCode });
  }

  orderRestoration({ userId, orderCode }): Observable<Cart> {
    return this.adapter.orderRestoration({ userId, orderCode });
  }

  orderRestoreOnly({ userId, orderCode }): Observable<Cart> {
    return this.adapter.orderRestoreOnly({ userId, orderCode });
  }

  getHistoryFilter(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    ordersFilter?: OrdersFilter,
  ): Observable<OrderHistoryList> {
    return this.adapter.loadHistoryFilter(userId, pageSize, currentPage, sort, ordersFilter);
  }
}
