import {Product} from "@spartacus/core";

export function getProductDetailSelectedUnit(product: Product): string {
  let result: string = '';
  if (product && product.unitPrices?.length) {
    product.unitPrices.map(unit => {
      if (unit.price.value === product.price.value) {
        result = unit.unit.name;
      }
    })
  }
  return result;
}
