import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AsmAdapter,
  CustomerSearchOptions,
  CUSTOMER_SEARCH_PAGE_NORMALIZER,
} from '@spartacus/asm/core';
import { OccAsmAdapter } from '@spartacus/asm/occ';
import { CustomerListsPage, BindCartParams, AsmConfig, CustomerSearchPage } from '@spartacus/asm/root';
import {
  BaseSiteService,
  ConverterService,
  InterceptorUtil,
  OccEndpointsService,
  USE_CUSTOMER_SUPPORT_AGENT_TOKEN,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class CustomOccAsmAdapter extends OccAsmAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    protected config: AsmConfig,
    protected baseSiteService: BaseSiteService,
  ) {
    super(http, occEndpointsService, converterService, config, baseSiteService);
   }
  customerLists(): Observable<CustomerListsPage> {
    throw new Error('Method not implemented.');
  }
  bindCart(options: BindCartParams): Observable<unknown> {
    throw new Error('Method not implemented.');
  }

  customerSearch(
    options: CustomerSearchOptions
  ): Observable<CustomerSearchPage> {
    const headers = InterceptorUtil.createHeader(
      USE_CUSTOMER_SUPPORT_AGENT_TOKEN,
      true,
      new HttpHeaders()
    );
    let activeBaseSite: string;
    this.baseSiteService
      .getActive()
      .pipe(
        filter(value => Boolean(value))
      )
      .subscribe(value => activeBaseSite = value)
      .unsubscribe();
    let params: HttpParams = new HttpParams()
      .set('baseSite', activeBaseSite)
      .set('sort', 'byNameAsc');

    if (typeof options['query'] !== 'undefined') {
      params = params.set('query', '' + options.query);
    }

    if (typeof options['customerListId'] !== 'undefined') {
      params = params.set('customerListId', '' + options.customerListId);
    }

    if (typeof options['pageSize'] !== 'undefined') {
      params = params.set('pageSize', '' + options.pageSize);
    }

    const url = this.occEndpointsService.buildUrl(
      'asmCustomerSearch',
      {},
      {
        baseSite: false,
        prefix: false,
      }
    );

    return this.http
      .get<CustomerSearchPage>(url, { headers, params })
      .pipe(this.converterService.pipeable(CUSTOMER_SEARCH_PAGE_NORMALIZER));
  }
}
