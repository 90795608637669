import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRestoreCartGuard } from './custom-restore-cart.guard';
import { PageLayoutComponent, PageLayoutModule } from '@spartacus/storefront';
import { AuthGuard } from '@spartacus/core';

@NgModule({
  imports: [
    PageLayoutModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CustomRestoreCartGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'restoreCart' },
      },
    ]),
  ],
})
export class CustomRestoreCartModule {}
