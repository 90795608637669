import {Injectable} from '@angular/core';
import {EventService, Product} from '@spartacus/core';
import {CustomProductDetailsSelectEvent} from "./custom-product-page.events";
import {EcommerceType} from "../tag-manager-feature.model";
import {getProductDetailSelectedUnit} from "./custom-product-detail.utils";
import {CustomCleanEcommerceEvent} from "../common/custom-common.events";

@Injectable({
  providedIn: 'root',
})
export class CustomProductDetailsEventService {

  constructor(protected eventService: EventService) {}

  public dispatchProductDetailSelectEvent(product: Product, pageMethod?: string, listName?: string, index?: number): void {
    if (product) {

      if(pageMethod == 'pageList') {
        this.eventService.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent);
        this.eventService.dispatch(
          <CustomProductDetailsSelectEvent>{
            event: EcommerceType.SELECT_ITEM,
            ecommerce: {
              items: [{
                item_name: product.name || product.code,
                item_id: product.code,
                item_brand: product.brands ? product.brands[0].name : '',
                item_category: product.categories ? product.categories[0]?.name || '' : '',
                item_category2: product.categories ? product.categories[1]?.name || '' : '',
                item_category3: product.categories ? product.categories[2]?.name || '' : '',
                item_variant: getProductDetailSelectedUnit(product),
                item_list_name: listName || '',
                index: index || 0,
                quantity: 1,
                price: product.price?.value.toString() || ''
              }]
            }
          },
          CustomProductDetailsSelectEvent
        );
      } else if (pageMethod == 'productPage') {
        this.eventService.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent);
        this.eventService.dispatch(
          <CustomProductDetailsSelectEvent>{
            event: EcommerceType.VIEW_ITEM,
            ecommerce: {
              items: [{
                item_name: product.name || product.code,
                item_id: product.code,
                item_brand: product.brands ? product.brands[0].name : '',
                item_category: product.categories ? product.categories[0]?.name || '' : '',
                item_category2: product.categories ? product.categories[1]?.name || '' : '',
                item_category3: product.categories ? product.categories[2]?.name || '' : '',
                item_variant: getProductDetailSelectedUnit(product),
                item_list_name: listName || '',
                index: index || 0,
                quantity: 1,
                price: product.price?.value.toString() || ''
              }]
            }
          },
          CustomProductDetailsSelectEvent
        );
      }
    }
  }
}
