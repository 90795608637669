import { Directive, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GlobalMessageService, ScriptLoader, WindowRef } from '@spartacus/core';
import { DOCUMENT } from '@angular/common';
import { CustomExtraAppConfigService } from '../../../custom/config/services/custom-extra-app-config.service';
import { RecaptchaConfig, RecaptchaVersion } from './recaptcha.model';
import { RecaptchaService } from './recaptcha.service';

interface RenderConfig {
  sitekey: string;
  callback: string;
  'expired-callback': () => void;
  'error-callback': () => void;
}

@Directive({
  selector: '[appRecaptcha]',
})
export class RecaptchaDirective implements OnInit, OnDestroy {

  @Output() currentWidgetId = new EventEmitter<any>();
  private windowReference: any = this.winRef.nativeWindow;

  enabled: boolean;
  version: string;
  loaded = false;
  widgetId: number;
  callback = environment.reCaptcha.onloadCallbackName;
  config: RenderConfig;

  constructor(
    private winRef: WindowRef,
    private elementRef: ElementRef,
    protected scriptLoader: ScriptLoader,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    protected globalMessageService: GlobalMessageService,
    protected recaptchaService: RecaptchaService,
    @Inject(DOCUMENT) private readonly dom: Document,
  ) {
    this.config = {
      sitekey: this.recaptchaService.getRecaptchaSiteKey(),
      callback: this.callback,
      'expired-callback': () => this.recaptchaService.recaptchaError('recaptcha.expired'),
      'error-callback': () => this.recaptchaService.recaptchaError('recaptcha.fail'),
    };
  }

  ngOnInit(): void {
    if (this.recaptchaService.getReCaptchaEnabled() && this.config.sitekey) {
      this.initRecaptcha();
    }
  }

  ngOnDestroy(): void {
    this.widgetId = null;
  }

  initRecaptcha(): void {
    switch (this.recaptchaService.getReCaptchaVersion()) {

      case RecaptchaVersion.V2: {
        this.injectGoogleRecaptchaScriptV2();
        break;
      }
      case RecaptchaVersion.V3: {
        this.injectGoogleRecaptchaScriptV3();
        break;
      }

      default:
        this.recaptchaService.wrongRecaptchaVersion();
    }
  }

  private injectGoogleRecaptchaScriptV2(): void {
    this.windowReference[this.callback] = () => {
      console.log('✓');
    };
    this.setWidgetId(
      this.renderCaptcha(),
    );
  }

  injectGoogleRecaptchaScriptV3(): void {
    if (this.scriptLoader) {
      this.scriptLoader.embedScript({
        src: environment.reCaptcha.scriptSrc,
        params: {
          render: this.config.sitekey,
          onload: environment.reCaptcha.onloadCallbackName,
          trustedtypes: 'true',
        },
        attributes: { type: 'text/javascript' },
        callback: () => {
          this.loaded = true;
        },
        errorCallback: err => this.recaptchaService.recaptchaError('recaptcha.scriptFailedToLoad'),
      });
    }
  }

  private renderCaptcha(): number {
    return this.windowReference.grecaptcha.render(this.elementRef.nativeElement, this.config);
  }

  setWidgetId(widgetId): void {
    this.currentWidgetId.emit(widgetId);
  }
}
