import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomCartEntryAdapter } from '../../../cart/conectors/entry/custom-cart-entry.adapter';
import { OccCartEntryAdapter } from '@spartacus/cart/base/occ';
import { CartModification, CART_MODIFICATION_NORMALIZER } from '@spartacus/cart/base/root';

@Injectable()
export class CustomOccCartEntryAdapter extends OccCartEntryAdapter implements CustomCartEntryAdapter {
  public addUnit(
    userId: string,
    cartId: string,
    productCode: string,
    quantity: number = 1,
    unitCode: string
  ): Observable<CartModification> {
    const url = this.occEndpointsService.buildUrl('addEntries', {
      urlParams: { userId, cartId, quantity }
    });

    // Handle b2b case where the x-www-form-urlencoded is still used
    if (url.includes(`quantity=${quantity}`)) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      return this.http
        .post<CartModification>(
          url,
          {},
          { headers, params: { code: productCode } }
        )
        .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
    }

    const toAdd = {
      quantity,
      product: { code: productCode },
      unit: { code: unitCode }
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .post<CartModification>(url, toAdd, { headers })
      .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
  }
}
