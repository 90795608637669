import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {GlobalMessageService, GlobalMessageType, RoutingConfigService} from '@spartacus/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {CustomCheckoutPaymentTypeService} from '../../../core/checkout/facade/custom-checkout-payment-type.service';
import {CheckoutStepService, CheckoutStepsSetGuard} from '@spartacus/checkout/base/components';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
  CheckoutStep,
  CheckoutStepType
} from '@spartacus/checkout/base/root';
import {CustomCheckoutPaymentService} from '../../../core/checkout/facade/custom-checkout-payment.service';
import {ActiveCartFacade} from '@spartacus/cart/base/root';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutStepsSetGuard extends CheckoutStepsSetGuard {
  constructor(

    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected paymentTypeService: CustomCheckoutPaymentTypeService,
    protected checkoutStepService: CheckoutStepService,
    protected routingConfigService: RoutingConfigService,
    protected router: Router,
    protected globalMessageService: GlobalMessageService,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentFacade: CustomCheckoutPaymentService,
    protected activeCartFacade: ActiveCartFacade,
  ) {
    super(checkoutStepService, routingConfigService, checkoutDeliveryAddressFacade, checkoutPaymentFacade, checkoutDeliveryModesFacade, router, activeCartFacade);
  }

  protected isStepSet(
    step: CheckoutStep
  ): Observable<boolean | UrlTree> {
    if (step && !step.disabled) {
      switch (step.type[0]) {
        case CheckoutStepType.DELIVERY_ADDRESS: {
          return this.isDeliveryAddress(step);
        }
        case CheckoutStepType.DELIVERY_MODE: {
          return this.isDeliveryModeSet(step);
        }
        case CheckoutStepType.PAYMENT_TYPE: {
          return this.isPaymentTypeSet(step);
        }
        case CheckoutStepType.PAYMENT_DETAILS: {
          return this.isPaymentDetailsSet(step);
        }
        case CheckoutStepType.REVIEW_ORDER: {
          break;
        }
      }
    }
    return of(true);
  }

  protected isPaymentTypeSet(
    step: CheckoutStep
  ): Observable<boolean | UrlTree> {
    return combineLatest([
      this.checkoutDeliveryModesFacade.getSelectedDeliveryModeState(),
      this.paymentTypeService.getPaymentTypes(),
      this.paymentTypeService.getSelectedPaymentTypeState()
    ])
      .pipe(
        map(([, , paymentType]) => {
          if (Boolean(paymentType)) {
            return true;
          } else {
            return this.getUrl(step.routeName);
          }
        })
      );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    let currentIndex = -1;
    const currentRouteUrl = '/' + route.url.join('/');

    // check whether the previous step is set
    return this.checkoutStepService.steps$.pipe(
      take(1),
      switchMap((steps) => {
        currentIndex = steps.findIndex((step) => {
          const stepRouteUrl = `/${
            this.routingConfigService.getRouteConfig(step.routeName)?.paths?.[0]
          }`;
          return stepRouteUrl === currentRouteUrl;
        });
        // get current step
        let currentStep;
        if (currentIndex >= 0) {
          currentStep = steps[currentIndex];
        }
        if (Boolean(currentStep)) {
          return this.isStepSet(steps[currentIndex - 1]);
        } else {
          if (isDevMode()) {
            console.warn(
              `Missing step with route '${currentRouteUrl}' in checkout configuration or this step is disabled.`
            );
          }
          return of(this.getUrl('checkoutPaymentDetails'));
        }
      })
    );
  }

  protected getUrl(routeName: string): UrlTree {
    if (routeName !== 'cart') {
      this.globalMessageService.add(
        { key: `cartDetails.${routeName}` },
        GlobalMessageType.MSG_TYPE_WARNING,
        60000,
      );
    }
    return this.router.parseUrl(
      this.routingConfigService.getRouteConfig(routeName).paths[0]
    );
  }
}
