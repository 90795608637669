import { Component, ElementRef } from '@angular/core';
import { AddedToCartDialogComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade, PromotionResult } from '@spartacus/cart/base/root';
import { isNotUndefined, RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProductUnitPrice } from 'src/app/model/unit-price';
import { CustomValidateCartService } from 'src/app/services/cart/validate-cart.service';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';

export interface AddedCartDialogOptions {
  productCode: string;
  quantity: number;
  numberOfEntriesBeforeAdd: number;
  selectedProductUnit: ProductUnitPrice;
}

@Component({
  selector: 'cx-added-to-cart-dialog',
  templateUrl: './custom-added-to-cart-dialog.component.html',
})
export class CustomAddedToCartDialogComponent extends AddedToCartDialogComponent {
  subscriptions = new Subscription();
  quantity: number;
  promotions$: Observable<PromotionResult[]>;
  orderPromotions$: Observable<PromotionResult[]>;
  orderAppliedPromotions$: Observable<PromotionResult[]>;

  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected launchDialogService: LaunchDialogService,
    protected routingService: RoutingService,
    protected el: ElementRef<any>,
    protected cartService: CustomActiveCartService,
    protected customValidateCartService: CustomValidateCartService
  ) {
    super(activeCartFacade, launchDialogService, routingService, el);

    this.subscriptions.add(
      this.activeCartFacade.getActive().pipe(filter(isNotUndefined)).subscribe((cart) => {
        this.orderPromotions$ = new Observable<PromotionResult[]>((obs) => {
          obs.next(cart.potentialOrderPromotions);
        });
        this.orderAppliedPromotions$ = new Observable<PromotionResult[]>((obs) => {
          obs.next(cart.appliedOrderPromotions);
        });
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (dialogData: AddedCartDialogOptions) => {
          this.initModal(
            dialogData.productCode,
            dialogData.quantity,
            dialogData.numberOfEntriesBeforeAdd,
            dialogData.selectedProductUnit
          );
        }
      )
    );
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(filter((state) => !!state.nextState))
        .subscribe(() => this.dismissModal('dismiss'))
    );
  }

  initModal(
    productCode: string,
    quantity: number,
    numberOfEntriesBeforeAdd: number,
    selectedProductUnit: ProductUnitPrice
  ): void {
    this.entry$ = this.cartService.getLastEntryUnit(
      productCode,
      selectedProductUnit?.unitPrice?.unit?.code
    );
    this.quantity = quantity;
    this.addedEntryWasMerged$ = this.getAddedEntryWasMerged(numberOfEntriesBeforeAdd);
  }

  goToCheckout() {
    this.customValidateCartService.simulateOrder(false);
    this.routingService.go({ cxRoute: 'checkout' });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  dismissModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }
}
