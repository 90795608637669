import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Address, StateWithProcess, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomFriendsCheckoutModel } from './custom-friends-checkout.model';
import {
  LoadFriendsCheckoutCandidates,
  RemoveFriendsCheckout,
  SelectFriendsCheckout,
  UpdateFriendsCheckout
} from '../store/actions/custom-friends-checkout.action';
import { StateWithFriends } from '../../store/custom-friends-checkout.state';
import { getFriendsCheckoutMembers, getFriendsCheckoutSelected } from '../store/selectors/custom-friends_checkout.selectors';
import {
  getCartDefaultDeliveryAddressSelector,
  getCartOnBehalfOfSelectorFactory
} from '../../../cart/store/selectors/custom-multi-cart.selectors';
import { StateWithMultiCart, ActiveCartService } from '@spartacus/cart/base/core';

@Injectable({
  providedIn: 'root',
})
export class CustomFriendsCheckoutService {
  userId: string;
  cartId: string;

  constructor(
    protected store: Store<StateWithFriends | StateWithProcess<void>>,
    protected storeCart: Store<StateWithMultiCart>,
    protected userIdService: UserIdService,
    protected activeCartService: ActiveCartService,
  ) {
    this.activeCartService.getActiveCartId().subscribe(cartId => this.cartId = cartId);
    this.userIdService.getUserId().subscribe(userId => this.userId = userId);
  }

  getOnBehalfOfType(): Observable<any> {
    return new Observable(observer => {
      observer.next([
        {
          code: 'me',
          name: 'checkoutAddress.onBehalfOf.me',
          showList: false,
          showFriendList: false
        },
        {
          code: 'friend',
          name: 'checkoutAddress.onBehalfOf.friend',
          showList: true,
          showFriendList: true
        },
      ]);
      observer.complete();
    });
  }

  loadOnBehalfOfCandidates(): void {
    this.store.dispatch(new LoadFriendsCheckoutCandidates({userId: this.userId, cartId: this.cartId}));
  }

  getOnBehalfOfCandidates(): Observable<CustomFriendsCheckoutModel[]> {
    return this.store.pipe(select(getFriendsCheckoutMembers));
  }

  selectOnBehalfOf(): void {
    this.store.dispatch(
      new SelectFriendsCheckout({userId: this.userId, cartId: this.cartId})
    );
  }

  updateOnBehalfOf(friendsCheckoutUid: string): void {
    this.store.dispatch(
      new UpdateFriendsCheckout({userId: this.userId, cartId: this.cartId, friendsCheckoutUid})
    );
  }

  getSelectedOnBehalfOf(): Observable<CustomFriendsCheckoutModel> {
    return this.store.pipe(select(getFriendsCheckoutSelected));
  }

  getSelectedOnBehalfOfFromCart(): Observable<CustomFriendsCheckoutModel> {
    return this.storeCart.pipe(select(getCartOnBehalfOfSelectorFactory(this.cartId)));
  }

  getDefaultAddressFromCart(): Observable<Address> {
    return this.storeCart.pipe(select(getCartDefaultDeliveryAddressSelector(this.cartId)));
  }

  removeOnBehalfOf(): void {
    this.store.dispatch(
      new RemoveFriendsCheckout({userId: this.userId, cartId: this.cartId})
    );
  }
}
