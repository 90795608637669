import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { createFrom, EventService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, pairwise, tap, withLatestFrom } from 'rxjs/operators';
import { CustomActiveCartService } from "../../../../../custom/core/cart/facade/custom-active-cart.service";
import { CustomCheckoutPaymentTypeService } from "../../../../../custom/core/checkout/facade/custom-checkout-payment-type.service";
import {
  CUSTOM_SET_PAYMENT_TYPE_SUCCESS,
  SetPaymentTypeSuccess
} from '../../../../../custom/core/cart/store/actions/custom-payment-types.action';
import { CustomCleanEcommerceEvent } from "../../common/custom-common.events";
import { EcommerceType } from "../../tag-manager-feature.model";
import { TagManagerFeatureService } from '../../tag-manager-feature.service';
import { getProductItems } from "../common/custom-checkout.utils";
import {
  CustomCheckoutDeliveryModeCompleteEvent,
  CustomCheckoutDeliveryModeEvent,
  CustomCheckoutPaymentModeCompleteEvent,
  CustomCheckoutPaymentModeEvent,
  CustomCheckoutStepsEvent
} from './custom-checkout-steps.events';
import { LOAD_CHECKOUT_STEP_SUCCESS, PLACE_ORDER, SET_DELIVERY_MODE_SUCCESS } from './store/actions/custom-checkout.action';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';

@Injectable({
  providedIn: 'root',
})
export class CustomCheckoutStepsEventBuilder {
  constructor(
    protected actions: ActionsSubject,
    protected eventService: EventService,
    protected tagManagerFeatureService: TagManagerFeatureService,
    protected checkoutDeliveryService: CheckoutDeliveryModesFacade,
    protected paymentTypeService: CustomCheckoutPaymentTypeService,
    protected activeCartService: CustomActiveCartService
  ) {
    this.register();
  }

  /**
   * Registers checkout events
   */
  protected register(): void {
    this.eventService.register(CustomCheckoutStepsEvent, this.buildCheckoutStepsEvent());
    this.eventService.register(CustomCheckoutDeliveryModeEvent, this.buildDeliveryModeEvent());
    this.eventService.register(CustomCheckoutDeliveryModeCompleteEvent, this.buildDeliveryModeCompleteEvent());
    this.eventService.register(CustomCheckoutPaymentModeEvent, this.buildPaymentModeEvent());
  }


  /**
   * Builds the checkout events
   */
  protected buildCheckoutStepsEvent(): Observable<CustomCheckoutStepsEvent> {
    return this.actions.pipe(
      ofType(LOAD_CHECKOUT_STEP_SUCCESS),
      map((action: any) => action.payload),
      map(({ step, entries, optionInformation, giftCard }) => {
        return createFrom(CustomCheckoutStepsEvent, {
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: {
                step,
                option: optionInformation?.length ? optionInformation : '',
              },
              products: entries?.length ? this.tagManagerFeatureService.getOrderEntries(entries, giftCard) : [],
            },
          },
        });
      }),
    );
  }

  /**
   * Builds the delivery mode events
   */
  protected buildDeliveryModeEvent(): Observable<CustomCheckoutDeliveryModeEvent> {
    return this.actions.pipe(
      ofType(SET_DELIVERY_MODE_SUCCESS),
      map((action: any) => {
        return action.payload;
      }),
      map((selectedModeId: string) => {
        return createFrom(CustomCheckoutDeliveryModeEvent, {
          event: EcommerceType.ADD_SHIPPING_INFO,
          ecommerce: {
            checkout: {
              actionField: {
                step: 2,
                option: selectedModeId?.length ? selectedModeId : '',
              },
            },
          },
        });
      }),
    );
  }

  /**
   * Builds the delivery mode complete events
   */
  protected buildDeliveryModeCompleteEvent(): Observable<CustomCheckoutDeliveryModeCompleteEvent> {
    return this.actions.pipe(
      ofType(LOAD_CHECKOUT_STEP_SUCCESS),
      map((action: any) => action.payload),
      pairwise(),
      filter(([prev, curr]) => prev.step == 2 && curr.step == 3),
      withLatestFrom(
        this.checkoutDeliveryService.getSelectedDeliveryModeState(),
        this.activeCartService.getActive()),
    ).pipe(
      tap(() => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ ecommerce: null }, CustomCleanEcommerceEvent)),
      map(([, deliveryMode, cart]) => {
        return createFrom(CustomCheckoutDeliveryModeCompleteEvent, {
          event: EcommerceType.ADD_SHIPPING_INFO,
          ecommerce: {
            shipping_tier: deliveryMode.data.name,
            items: getProductItems(cart.entries, cart.giftCardProduct)
          },
        });
      })
    );
  }

  /**
   * Builds the payment mode events
   */
  protected buildPaymentModeEvent(): Observable<CustomCheckoutPaymentModeEvent> {
    return this.actions.pipe(
      ofType(CUSTOM_SET_PAYMENT_TYPE_SUCCESS),
      map((action: SetPaymentTypeSuccess) => action.payload),
      map((paymentType: string) => {
        return createFrom(CustomCheckoutPaymentModeEvent, {
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: {
                step: 3,
                option: paymentType?.length ? paymentType : '',
              },
            },
          },
        });
      }),
    );
  }

  protected buildPaymentModeCompleteEvent(): Observable<CustomCheckoutPaymentModeCompleteEvent> {
    return this.actions.pipe(
      ofType(PLACE_ORDER),
      withLatestFrom(
        this.activeCartService.takeActive()
      )
    ).pipe(
      tap(() => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ ecommerce: null }, CustomCleanEcommerceEvent)),
      map(([, cart]) => {
        return createFrom(CustomCheckoutPaymentModeCompleteEvent, {
          event: EcommerceType.ADD_PAYMENT_INFO,
          ecommerce: {
            payment_type: cart?.paymentMode.code,
            items: getProductItems(cart.entries, cart.giftCardProduct)
          },
        });
      })
    )
  }

  public dispatchPaymentModeCompleteEvent(): Observable<any> {
    return combineLatest([
      this.activeCartService.takeActive()
    ]).pipe(
      tap(([cart]) => {
        this.eventService.dispatch(<CustomCleanEcommerceEvent>{ ecommerce: null }, CustomCleanEcommerceEvent);
        this.eventService.dispatch(<CustomCheckoutPaymentModeCompleteEvent>{
          event: EcommerceType.ADD_PAYMENT_INFO,
          ecommerce: {
            payment_type: cart?.paymentMode,
            items: getProductItems(cart.entries, cart.giftCardProduct)
          },
        }, CustomCheckoutPaymentModeCompleteEvent)
      })
    )
  }

}
