import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Inject, Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  EventService,
  GlobalMessageService,
  RoutingService,
  StateWithProcess, WindowRef
} from '@spartacus/core';
import {
  LaunchDialogService, LAUNCH_CALLER
} from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { CustomCheckoutPaymentTypeService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment-type.service';
import { CustomRedsysPayment } from 'src/app/spartacus/custom/core/checkout/models/custom-checkout.model';
import { CustomValidateCartService } from '../../../../../../services/cart/validate-cart.service';
import {
  CustomCheckoutStepsEventBuilder
} from "../../../../../features/tracking/custom-events/checkout/checkout-steps/custom-checkout-steps-event.builder";
import { CustomActiveCartService } from '../../../../core/cart/facade/custom-active-cart.service';
import { CustomMultiCartService } from '../../../../core/cart/facade/custom-multi-cart.service';
import { PurgeFriendsCheckoutSuccess } from '../../../../core/custom-friends/custom-friends-checkout/store/actions/custom-friends-checkout.action';
import { StateWithFriends } from '../../../../core/custom-friends/store/custom-friends-checkout.state';
import { CreditCard, StateWithCreditCards } from '../payment-method/custom-credit-card/store/credit-card.state';
import { CustomCheckoutPaymentTypeComponent } from '../payment-method/payment-type/custom-payment-type.component';
import { CustomCheckoutPaymentService } from "../../../../core/checkout/facade/custom-checkout-payment.service";
import { CheckoutReplenishmentFormService } from '@spartacus/checkout/scheduled-replenishment/components';
import { CheckoutPlaceOrderComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { ORDER_TYPE, Order, OrderFacade, OrderHistoryFacade, ScheduleReplenishmentForm, ScheduledReplenishmentOrderFacade, recurrencePeriod } from '@spartacus/order/root';
import { Cart } from '@spartacus/cart/base/root';
import { B2BPaymentTypeEnum } from '@spartacus/checkout/b2b/root';
import { getSaveCreditCard } from '../payment-method/custom-credit-card/store/selectors/credit-card.selectors';
import { take } from 'rxjs/operators';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';

@Component({
  selector: 'cx-place-order',
  templateUrl: './custom-place-order.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPlaceOrderComponent extends CheckoutPlaceOrderComponent implements OnInit {

  cart$: Observable<Cart> = this.checkoutPaymentTypeService.getCartData();
  isPayPal$ = new BehaviorSubject<boolean>(false);
  isPaypalPaymentSelected = false;
  paypalButtonDisabled = true;
  isCreditCard$ = new BehaviorSubject<boolean>(false);
  isRedsysPaymentSelected = false;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected activeCartService: CustomActiveCartService,
    protected activatedRoute: ActivatedRoute,
    protected winRef: WindowRef,
    protected customPaymentTypeComponents: CustomCheckoutPaymentTypeComponent,
    protected checkoutPaymentService: CustomCheckoutPaymentService,
    protected routingService: RoutingService,
    protected fb: FormBuilder,
    protected checkoutReplenishmentFormService: CheckoutReplenishmentFormService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected orderFacade: OrderFacade,
    protected scheduledReplenishmentOrderFacade: ScheduledReplenishmentOrderFacade,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected checkoutPaymentTypeService?: CustomCheckoutPaymentTypeService,
    protected customValidateCartService?: CustomValidateCartService,
    protected friendsStore?: Store<StateWithFriends | StateWithProcess<void>>,
    protected creditCardStore?: Store<StateWithCreditCards | StateWithProcess<void>>,
    protected globalMessageService?: GlobalMessageService,
    protected customActiveCartService?: CustomActiveCartService,
    protected checkoutStepService?: CheckoutStepService,
    protected customMultiCartService?: CustomMultiCartService,
    protected eventService?: EventService,
    protected customCheckoutStepsEventBuilder?: CustomCheckoutStepsEventBuilder
  ) {
    super(orderFacade, routingService, fb, launchDialogService, vcr);
  }

  get termsAndConditionInvalid(): boolean {
    return this.checkoutSubmitForm.invalid;
  }
  @Input() isDisabled: boolean;
  private subscription: Subscription = new Subscription();
  currentOrderType: ORDER_TYPE;
  scheduleReplenishmentFormData: ScheduleReplenishmentForm;
  loadingStep: void | Observable<ComponentRef<any>>;
  simulatedSuccess = false;
  saveCard = null;
  daysOfWeekNotChecked$ = new BehaviorSubject<boolean>(false);
  selectedCreditCard: CreditCard;
  backBtnText = this.checkoutStepService.getBackBntText(this.activatedRoute);

  redsysForm: FormGroup = this.fb.group({
    Ds_MerchantParameters: ['', Validators.requiredTrue],
    Ds_SignatureVersion: ['', Validators.requiredTrue],
    Ds_Signature: ['', Validators.requiredTrue],
  });

  checkoutSubmitForm: FormGroup = this.fb.group({
    termsAndConditions: [false, Validators.requiredTrue],
  });

  back(): void {
    this.checkoutStepService.back(this.activatedRoute);
  }

  disableInputs(): void {
    const element = this.winRef.document.getElementById('termsAndConditions') as HTMLInputElement;
    const isChecked = element.checked;

    if (isChecked) {
      this.winRef.document.body.classList.add('block-input');
      if (this.isPaypalPaymentSelected) {
        this.customPaymentTypeComponents.next();
        this.paypalButtonDisabled = false;
        this.clearSimulate();
        this.customValidateCartService.simulateOrder(true);
      } else {
        this.clearLoadingStep();
        this.clearSimulate();
        this.orderFacade.clearPlacedOrder();
      }
    } else {
      this.paypalButtonDisabled = true;
      this.winRef.document.body.classList.remove('block-input');
    }
  }

  next(): void {
    this.customPaymentTypeComponents.next();
  }

  submitForm(): void {
    if (this.checkoutSubmitForm.valid && Boolean(this.currentOrderType)) {
      this.subscription.add(
        this.customCheckoutStepsEventBuilder.dispatchPaymentModeCompleteEvent()
          .pipe(take(1))
          .subscribe(() => {
            this.customValidateCartService.simulateOrder(true)
          })
      );
    } else {
      this.checkoutSubmitForm.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.creditCardStore.pipe(select(getSaveCreditCard)).subscribe(saveCard => this.saveCard = saveCard),
    );
    this.subscription.add(
      combineLatest([
        this.checkoutPaymentService.getPlaceOrderLoading(),
        this.checkoutPaymentService.getPlaceOrderSuccess(),
        this.checkoutPaymentService.getPlaceOrderError(),
        this.checkoutPaymentService.getRedsysPaymentLoading(),
        this.checkoutPaymentService.getRedsysPaymentSuccess(),
        this.checkoutPaymentService.getRedsysPaymentError(),
        this.customValidateCartService.getSimulateCartState(),
        this.customValidateCartService.getSimulateCartLoading(),
        this.customValidateCartService.getSimulateCartSuccess(),
        this.customValidateCartService.getSimulateCartError(),
      ]).subscribe(([
        orderLoading, orderSuccess, orderError, redsysLoading, redsysLoadingSuccess, redsysLoadingError,
        , simulateCartLoading, simulateCartSuccess, simulateCartError,
      ]) => {

        if (orderLoading || redsysLoading || simulateCartLoading) {
          this.showLoadingStep();
        }

        if (orderError || redsysLoadingError) {
          if (this.loadingStep) {
            this.clearLoadingStep();
            this.orderFacade.clearPlacedOrder();
          } else {
            this.clearSimulate();
          }
        }

        if (orderSuccess) {
          this.onSuccess();
        }

        if (redsysLoadingSuccess) {
          this.onRedsysLoadingSuccess(redsysLoadingSuccess);
        }

        if (simulateCartError) {
          this.customValidateCartService.redirectToCartPage();
        }

        if (simulateCartSuccess && !this.simulatedSuccess) {
          this.simulatedSuccess = true;
          switch (this.currentOrderType) {
            case ORDER_TYPE.PLACE_ORDER: {
              if (this.isRedsysPaymentSelected) {
                this.checkoutPaymentService.initRedsysPayment(this.saveCard);
              } else if (this.isPaypalPaymentSelected) {
                this.clearLoadingStep();
                this.paypalButtonDisabled = false;
              } else {
                this.orderFacade.placeOrder(this.checkoutSubmitForm.valid).subscribe({
                  error: () => {
                    if (!this.loadingStep) {
                      return;
                    }
                    this.clearLoadingStep();
                  },
                  next: (order) => {
                    this.onPlaceOrderSuccess(order)
                  },
                })
              }
              break;
            }
            case ORDER_TYPE.SCHEDULE_REPLENISHMENT_ORDER: {
              this.scheduledReplenishmentOrderFacade.scheduleReplenishmentOrder(
                this.scheduleReplenishmentFormData,
                this.checkoutSubmitForm.valid,
              );
              break;
            }
          }
        }
      }),
    );

    if (this.cart$) {
      this.subscription.add(
        this.cart$.subscribe(cart => {
          let paymentTypeCode = cart?.paymentMode.code;
  
          // PayPal selected
          this.isPaypalPaymentSelected = paymentTypeCode == B2BPaymentTypeEnum.PAYPAL_PAYMENT;
          this.isPayPal$.next(this.isPaypalPaymentSelected);
  
          // Credit Card or Bizum selected
          this.isRedsysPaymentSelected =
            paymentTypeCode == B2BPaymentTypeEnum.CREDIT_CARD_PAYMENT
              || paymentTypeCode == B2BPaymentTypeEnum.BIZUM_PAYMENT;
          this.isCreditCard$.next(this.isRedsysPaymentSelected);
        })
      );
    }

    this.subscription.add(
      this.checkoutReplenishmentFormService
        .getOrderType()
        .subscribe((orderType) => (this.currentOrderType = orderType)),
    );

    this.subscription.add(
      this.checkoutReplenishmentFormService
        .getScheduleReplenishmentFormData()
        .subscribe((data) => {
          this.scheduleReplenishmentFormData = data;

          this.daysOfWeekNotChecked$.next(
            data.daysOfWeek.length === 0 &&
            data.recurrencePeriod === recurrencePeriod.WEEKLY,
          );
        }),
    );
    let isPickupStore = JSON.parse(this.winRef?.localStorage.getItem('currentbasestore'))?.pickupStore;
    if (isPickupStore) {
      this.subscription.add(
        this.checkoutDeliveryModesFacade.setDeliveryMode('pickup').subscribe(),
      );
    }  
  }

  onSuccess(): void {
    this.friendsStore.dispatch(new PurgeFriendsCheckoutSuccess());
    switch (this.currentOrderType) {
      case ORDER_TYPE.PLACE_ORDER: {
        this.routingService.go({ cxRoute: 'orderConfirmation' });
        break;
      }

      case ORDER_TYPE.SCHEDULE_REPLENISHMENT_ORDER: {
        this.routingService.go({ cxRoute: 'replenishmentConfirmation' });
        break;
      }
    }
    this.checkoutReplenishmentFormService.resetScheduleReplenishmentFormData();
  }

  onPlaceOrderSuccess(order: Order): void {
    this.friendsStore.dispatch(new PurgeFriendsCheckoutSuccess());
    switch (this.currentOrderType) {
      case ORDER_TYPE.PLACE_ORDER: {
        this.routingService.go({ cxRoute: 'orderConfirmation', params: order });
        break;
      }

      case ORDER_TYPE.SCHEDULE_REPLENISHMENT_ORDER: {
        this.routingService.go({ cxRoute: 'replenishmentConfirmation' });
        break;
      }
    }
    this.checkoutReplenishmentFormService.resetScheduleReplenishmentFormData();
}

  onRedsysLoadingSuccess(isSuccess: boolean): void {
    if (isSuccess) {
      this.checkoutPaymentService.getRedsysPaymentState().subscribe(state => {
        const redsysPayment: CustomRedsysPayment = state.value;
        if (redsysPayment?.paymentSignature) {
          this.redsysForm.patchValue({
            Ds_MerchantParameters: redsysPayment.paymentParameters,
            Ds_SignatureVersion: redsysPayment.signatureVersion,
            Ds_Signature: redsysPayment.paymentSignature,
          });
          this.submitRedsysForm(redsysPayment.hopFormActionUrl);
        }
      });
    }
  }

  submitRedsysForm(redsysUrl: string): void {
    const form = this.document.createElement('form');
    form.style.display = 'none';
    form.method = 'POST';
    form.action = redsysUrl;
    let input;
    for (const [key, value] of Object.entries(this.redsysForm.value)) {
      input = this.document.createElement('input');
      input.name = key;
      input.value = value;
      input.type = 'hidden';
      form.appendChild(input);
    }
    this.document.body.appendChild(form);
    form.submit();
  }

  clearSimulate(): void {
    this.customValidateCartService.clearSimulateCart();
    this.simulatedSuccess = false;
  }

  clearLoadingStep(): void {
    if (this.loadingStep) {
      this.loadingStep.subscribe((component) => {
        this.launchDialogService.clear(
          LAUNCH_CALLER.PLACE_ORDER_SPINNER,
        );
        component.destroy();
        this.loadingStep = null;
      }).unsubscribe();
    }
  }

  showLoadingStep(): void {
    if (!this.loadingStep) {
      this.loadingStep = this.launchDialogService.launch(
        LAUNCH_CALLER.PLACE_ORDER_SPINNER,
        this.vcr,
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.clearLoadingStep();
    this.clearSimulate();
    this.orderFacade.clearPlacedOrder();
    this.winRef.document.body.classList.remove('block-input');
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }
}
