import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CustomTabParagraphContainerComponent } from './custom-tab-paragraph-container.component';
import { OutletModule, PageComponentModule } from '@spartacus/storefront';

@NgModule({
  declarations: [CustomTabParagraphContainerComponent],
  imports: [
    CommonModule,
    PageComponentModule,
    OutletModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSTabParagraphContainer: {
          component: CustomTabParagraphContainerComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomTabParagraphContainerModule { }
