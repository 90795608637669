<div class="container">
    <div class="row content">
        <div class="col-md-4 col-12 mb-4">
            <ng-container *ngIf="data$ | async as data">
                <ng-container *ngIf="data?.title">
                    <h3 class="title" [innerHTML]=data.title></h3>
                </ng-container>

                <ng-container *ngIf="data?.subtitle">
                    <h2 class="subtitle" [innerHTML]=data.subtitle></h2>
                </ng-container>

                <ng-container *ngIf="data?.paragraph?.content">
                    <cx-paragraph [innerHTML]="data.paragraph.content"></cx-paragraph>
                </ng-container>

                <ng-container *ngIf="data.buttons">
                    <ng-container *ngIf="buttons$ | async as buttons">
                        <ng-container *ngFor="let button of buttons">
                            <div class="cx-button" *ngIf="button">
                                <cx-generic-link *ngIf="button.url && button.url.length" [url]="button.url" [target]="getTarget(button.target)" [class]="'btn btn-send ' + button.styleClasses">
                                    {{button.linkName}}
                                </cx-generic-link>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <div class="col-md-8 col-12">
            <ng-container *ngIf="(contentPage$ | async) as items">
                <ng-container *ngIf="enableSlider; else showGrid">
                    <cx-carousel [items]="contentPage$ | async" [template]="carouselItem" itemWidth="50%" [hideIndicators]="true">
                    </cx-carousel>
                </ng-container>

                <ng-template #showGrid>
                    <ng-container *ngIf="items.length">
                        <div class="row">
                            <ng-container *ngFor="let item of items">
                                <ng-container *ngIf="(item | async) as contentPage">
                                    <div [class]="'col-sm-6 media-container ' + contentPage.styleClasses">
                                        <ng-container *ngTemplateOutlet="carouselItem; context: {item: contentPage}"></ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-container>

            <ng-template #carouselItem let-item="item">
                <ng-container *ngIf="item">
                    <ng-container [ngSwitch]="!!item.url">
                        <ng-container *ngSwitchCase="true">
                            <cx-generic-link [url]="item.url" [target]="getTarget(item.target)" class="item-link">
                                <cx-media class="item-media" [container]="item.media" [alt]="item.linkName"></cx-media>
                                <h3 class="item-title">
                                    {{item.linkName}}
                                </h3>
                            </cx-generic-link>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <cx-media class="item-media" [container]="item.media" [alt]="item.linkName ? item.linkName : item.uid"></cx-media>
                            <ng-container *ngIf="item.linkName">
                              <h3 class="item-title">
                                {{item.linkName}}
                              </h3>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-template>

        </div>
    </div>
</div>