import { Component, HostBinding } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsService, ContentSlotComponentData } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CmsCustomCarouselYoutubeInfoComponent } from './custom-video-carousel.model';

@Component({
  selector: 'app-custom-video-carousel',
  templateUrl: './custom-video-carousel.component.html',
  styleUrls: ['./custom-video-carousel.component.scss'],
})
export class CustomVideoCarouselComponent {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;

  private componentData$: Observable<CmsCustomCarouselYoutubeInfoComponent> = this.componentData.data$.pipe(
    filter(Boolean),
    tap((d: CmsCustomCarouselYoutubeInfoComponent) => (this.initComponent(d))),
  );

  private items$: Observable<Observable<ContentSlotComponentData>[]> = this.componentData$.pipe(
    map((data: any) => {
      return data?.banners?.trim().split(' ');
    }),
    map((codes) => {
      return codes.map((code) => this.cmsService.getComponentData(code));
    }),
  );

  constructor(
    protected componentData: CmsComponentData<CmsCustomCarouselYoutubeInfoComponent>,
    protected cmsService: CmsService) {
  }

  getItems(): Observable<Observable<ContentSlotComponentData>[]> {
    return this.items$;
  }

  initComponent(item: CmsCustomCarouselYoutubeInfoComponent): void {
    this.styleClasses = `${item.uid}-theme ${item.styleClasses}` ?? '';
    this.backgroundColor = item?.backgroundColorCode ?? '';
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
  }
}
