import { Observable } from 'rxjs';
import {
  CustomNewsCategoriesModel,
  CustomNewsDetailsModel,
  CustomNewsListModel,
  CustomNewsPaginationParams,
} from '../store/custom-news.model';
import { CustomNewsAdapter } from './custom-news.adapter';
import { CustomOcc } from '../../../../../spartacus/custom/core/occ/occ-models/custom-occ.models';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomOccNewsAdapter implements CustomNewsAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  public loadNewsList(newsParams: CustomNewsPaginationParams): Observable<CustomNewsListModel> {
    const { pageSize, currentPage, sort, newsFilter } = newsParams;

    const queryParams: { [key: string]: string } = {};

    if (pageSize) {
      queryParams['pageSize'] = pageSize.toString();
    }
    if (currentPage) {
      queryParams['currentPage'] = currentPage.toString();
    }
    if (sort) {
      queryParams['sort'] = sort;
    }
    if (newsFilter?.categoryCode) {
      queryParams['categoryCode'] = newsFilter.categoryCode;
    }
    if (newsFilter?.titleSearch) {
      queryParams['titleSearch'] = newsFilter.titleSearch;
    }

    const url = this.occEndpoints.buildUrl('news', { queryParams });

    return this.http.get<CustomOcc.NewsList>(url);
  }

  public loadNewsDetails(
    newsId: string,
  ): Observable<CustomNewsDetailsModel> {
    const url = this.occEndpoints.buildUrl('newsDetails', {
      urlParams: { newsId }
    });
    return this.http.get<CustomOcc.NewsDetails>(url);
  }

  public loadNewsCategories(): Observable<CustomNewsCategoriesModel> {
    const url = this.occEndpoints.buildUrl('newsCategories');
    return this.http.get<CustomNewsCategoriesModel>(url);
  }

}

