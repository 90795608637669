<div class="register-guest" *ngIf="order$ | async as order">
  <ng-container *ngIf="isGuestCustomer">
    <div class="col-md-8 col-lg-6 col-xl-4">
      <h3>{{ 'checkoutOrderConfirmation.createAccount' | cxTranslate }}</h3>
      <p>
        {{
        'checkoutOrderConfirmation.createAccountForNext'
        | cxTranslate: { email: email }
        }}
      </p>

      <form (ngSubmit)="submit()" [formGroup]="guestRegisterForm">
        <div class="form-group">
          <label>
            <span class="label-content">{{
              'register.password.label' | cxTranslate
              }}</span>
          <input
            class="form-control"
            type="password"
            name="password"
            placeholder="{{ 'register.password.placeholder' | cxTranslate }}"
            formControlName="password"
          />
          <cx-form-errors
            [control]="guestRegisterForm.get('password')"
          ></cx-form-errors>
          </label>
        </div>

        <div class="form-group">
          <label>
            <span class="label-content">{{
              'register.confirmPassword.label' | cxTranslate
              }}</span>
          <input
            class="form-control"
            type="password"
            name="passwordconf"
            placeholder="{{
                'register.confirmPassword.placeholder' | cxTranslate
            }}"
            formControlName="passwordconf"
          />
          <cx-form-errors
            [control]="guestRegisterForm.get('passwordconf')"
          ></cx-form-errors>
          </label>
        </div>

        <button type="submit" class="btn btn-block btn-primary">
          {{ 'common.submit' | cxTranslate }}
        </button>
      </form>
    </div>
  </ng-container>
</div>