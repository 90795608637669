import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomNewsTitleService } from './custom-news-title.service';
import { Observable, Subscription } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsService } from '@spartacus/core';
import { Page } from '@spartacus/core/src/cms/model/page.model';
import { CustomNewsDetailsModel } from '../../store/custom-news.model';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-custom-news-title',
  templateUrl: './custom-news-title.component.html',
  styleUrls: ['./custom-news-title.component.scss'],
})
export class CustomNewsTitleComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  newsDetail$: Observable<CustomNewsDetailsModel> = this.newsTitleServices.getNewsDetails();
  loadingDetail$: Observable<boolean> = this.newsTitleServices.getNewsDetailsLoading();

  constructor(
    protected component: CmsComponentData<any>,
    protected newsTitleServices: CustomNewsTitleService,
    protected cmsService: CmsService,
    private title: Title,
    private metaTagService: Meta
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.cmsService.getCurrentPage()
      .subscribe((page: Page) => {
        if (page.type === 'NewsPage') {
          this.newsTitleServices.fetchNewsDetails(page.pageId);
          
          this.title.setTitle(page.title);

          var pageRobotsString = page.robots.toString();
          this.metaTagService.updateTag({ name: 'og:title', content: page.title });  
          this.metaTagService.updateTag({ name: 'og:description', content: page.description });
          this.metaTagService.updateTag({ name: 'robots', content: pageRobotsString });
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.newsTitleServices.clearNewsDetails();
    this.subscriptions.unsubscribe();
  }

}
