import { Component, HostBinding, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { CmsComponentData } from '@spartacus/storefront';
import { CustomParagraphColumnInfoModel } from './custom-paragraph-column-info.model';
import { Observable, of } from 'rxjs';
import { CmsService } from '@spartacus/core';
import {
  CustomParagraphColumnWithExpandableInfoModel,
} from '../custom-paragraph-column-with-expandable-info/custom-paragraph-column-with-expanded-info';

@Component({
  selector: 'app-custom-paragraph-column-info',
  templateUrl: './custom-paragraph-column-info.component.html',
  styleUrls: ['./custom-paragraph-column-info.component.scss'],
})
export class CustomParagraphColumnInfoComponent implements OnInit {
  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;
  columns = {
    md: 12,
    lg: 12,
  };

  data$: Observable<CustomParagraphColumnInfoModel> = this.componentData.data$.pipe(
    tap(component => {
        return this.initComponent(component);
      },
    ),
  );

  columnInfoList$: Observable<Observable<CustomParagraphColumnWithExpandableInfoModel>[]> = this.data$.pipe(
    map((data: any) => {
      const codeList = data?.columnInfo?.trim().split(' ');
      if (codeList) {
        const codesLength = codeList.length;
        if (codesLength > 1) {
          this.columns.md = 6;
        }
        if (codesLength > 4) {
          this.columns.lg = 3;
        } else {
          this.columns.lg = 12 / codesLength;
        }
      }
      return codeList;
    }),
    map((codes) => {
      if (codes) {
        return codes.map((code) => this.cmsService.getComponentData(code));
      }
      return of(null);
    }),
  );

  constructor(
    protected componentData: CmsComponentData<CustomParagraphColumnInfoModel>,
    protected cmsService: CmsService,
  ) {
  }

  ngOnInit(): void {
  }

  initComponent(item: CustomParagraphColumnInfoModel): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth.toString() === 'true') {
      this.styleClasses += ' full-page-width';
    }
    if (item.backgroundColorCode) {
      this.backgroundColor = item.backgroundColorCode ?? '';
    }
  }

}
