import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import { CustomCheckoutLoginModule } from '../checkout-login/custom-checkout-login.module';
import { CustomLoginRegisterComponent } from './custom-login-register.component';
import { CheckoutCoreModule } from '@spartacus/checkout/base/core';

@NgModule({
  declarations: [CustomLoginRegisterComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    PageSlotModule,
    I18nModule,
    //CheckoutCoreModule,
    CustomCheckoutLoginModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturningCustomerRegisterComponent: {
          component: CustomLoginRegisterComponent,
        }
      }
    } as CmsConfig)
  ]
})
export class CustomLoginRegisterModule { }
