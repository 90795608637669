import {Injectable} from "@angular/core";
import {createFrom, EventService, Product} from "@spartacus/core";
import {ActionsSubject} from "@ngrx/store";
import {ofType} from "@ngrx/effects";
import {
  CustomOrderActionTypes
} from "../../../../custom/cms-components/myaccount/order/custom-order-actions/store/actions/custom-order.actions";
import {CustomOrderRefundEvent} from "./custom-order.events";
import {Observable, of} from "rxjs";
import {map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {EcommerceType, ProductItem} from "../tag-manager-feature.model";
import {CustomCleanEcommerceEvent} from "../common/custom-common.events";
import { ReturnRequest, ReturnRequestEntry } from "@spartacus/order/root";
import { OrderActions } from "@spartacus/order/core";


/**
 * Registers order events
 */
@Injectable({providedIn: 'root'})
export class CustomOrderEventBuilder {

  constructor(
    protected actionsSubject: ActionsSubject,
    protected eventService: EventService
  ) {
    this.register();
  }

  protected register() {
    this.registerOrderRefundEvents();
  }

  protected registerOrderRefundEvents() {
    this.eventService.register(
      CustomOrderRefundEvent,
      this.registerOrderRefund()
    )

    this.eventService.register(
      CustomOrderRefundEvent,
      this.registerOrderPartialRefund()
    )
  }

  protected registerOrderRefund(): Observable<CustomOrderRefundEvent> {
    return this.actionsSubject.pipe(
      ofType(CustomOrderActionTypes.ORDER_CANCELLATION_SUCCESS),
      withLatestFrom(
        this.actionsSubject.pipe<{ payload: { userId: string, orderCode: string } }>(
          ofType(CustomOrderActionTypes.ORDER_CANCELLATION)
        )
      ),
      tap(() => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      map(([, payload]) => {
        return createFrom(CustomOrderRefundEvent, {
          event: EcommerceType.REFUND,
          ecommerce: {
            transaction_id: payload.payload.orderCode
          }
        })
      })
    );
  }

  protected registerOrderPartialRefund(): Observable<CustomOrderRefundEvent> {
    return this.actionsSubject.pipe(
      ofType(OrderActions.CREATE_ORDER_RETURN_REQUEST_SUCCESS),
      switchMap((payload: any) => of<ReturnRequest>(payload.payload))
    ).pipe(
      tap(() => this.eventService.dispatch(<CustomCleanEcommerceEvent>{ecommerce: null}, CustomCleanEcommerceEvent)),
      map((payload) => {
        const data = createFrom(CustomOrderRefundEvent, {
          event: EcommerceType.REFUND,
          ecommerce: {
            transaction_id: payload.code
          }
        })

        if (payload.returnEntries?.length) {
          data['ecommerce'].items = payload.returnEntries.map((entry: ReturnRequestEntry): ProductItem => {
            const product: Product = entry.orderEntry.product;
            return {
              item_name: product.name || product.code,
              item_id: product.code,
              price: entry.orderEntry.basePrice.value.toString(),
              item_brand: product.brands ? product.brands[0].name : '',
              item_category: product.categories ? product.categories[0]?.name || '' : '',
              item_category2: product.categories ? product.categories[1]?.name || '' : '',
              item_category3: product.categories ? product.categories[2]?.name || '' : '',
              item_variant: this.getProductDetailUnit(product),
              quantity: entry.receivedQuantity
            };
          });
        }

        return data;
      })
    )
  }

  protected getProductDetailUnit(product: Product): string {
    let result: string = '';
    if (product && product.unitPrices?.length) {
      product.unitPrices.map((unit, index) => {
        result += index > 0 ? `,${unit.unit.name}` : unit.unit.name;
      })
    }
    return result;
  }
}
