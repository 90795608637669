import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import {
  UPDATE_NEWSLETTER_PROCESS_ID,
} from '../custom-newsletter-state';

export const UPDATE_NEWSLETTER = '[Newsletter] Update Newsletter';
export const UPDATE_NEWSLETTER_FAIL =
  '[Newsletter] Update Newsletter Fail';
export const UPDATE_NEWSLETTER_SUCCESS =
  '[Newsletter] Update Newsletter Success';
export const CLEAR_UPDATE_NEWSLETTER =
  '[Newsletter] Clear Update Newsletter';

export class UpdateNewsletter extends StateUtils.EntityLoadAction {
  readonly type = UPDATE_NEWSLETTER;
  constructor(public payload: { email: string; consentTemplateId: string; consentTemplateVersion: string; accept: boolean }) {
    super(PROCESS_FEATURE, UPDATE_NEWSLETTER_PROCESS_ID);
  }
}

export class UpdateNewsletterFail extends StateUtils.EntityFailAction {
  readonly type = UPDATE_NEWSLETTER_FAIL;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, UPDATE_NEWSLETTER_PROCESS_ID, payload);
  }
}

export class UpdateNewsletterSuccess extends StateUtils.EntitySuccessAction {
  readonly type = UPDATE_NEWSLETTER_SUCCESS;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, UPDATE_NEWSLETTER_PROCESS_ID, payload);
  }
}

export class ClearUpdateNewsletter extends StateUtils.EntityLoaderResetAction {
  readonly type = CLEAR_UPDATE_NEWSLETTER;
  constructor() {
    super(PROCESS_FEATURE, UPDATE_NEWSLETTER_PROCESS_ID);
  }
}

export type CustomNewsletterAction =
  | UpdateNewsletter
  | UpdateNewsletterFail
  | UpdateNewsletterSuccess
  | ClearUpdateNewsletter;
