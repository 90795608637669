import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AuthActions } from '@spartacus/core';
import * as friendsCheckout from '../../custom-friends-checkout/store/reducers/custom-friends-checkout.reducer';
import { InjectionToken, Provider } from '@angular/core';
import { CustomFriendsState } from '../custom-friends-checkout.state';
import { customFriendsReducer } from '../../custom-friends-users/store/reducers/custom-friends-users.reducer';

export function getReducers(): ActionReducerMap<CustomFriendsState> {
  return {
    checkout: friendsCheckout.reducer,
    user: customFriendsReducer,
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<CustomFriendsState>> = new InjectionToken<ActionReducerMap<CustomFriendsState>>('FriendsReducers');

export const FriendsReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearCustomFriendsState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
      state = [];
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearCustomFriendsState];
