import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade, PaymentDetails, PaymentType } from '@spartacus/cart/base/root';
import { CheckoutPaymentMethodComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryAddressFacade, CheckoutPaymentFacade } from '@spartacus/checkout/base/root';
import { UserPaymentService, TranslationService, GlobalMessageService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { fadeIn, fadeOut, rotatedState } from 'src/app/custom/custom-animations';
import { CustomCheckoutPaymentTypeService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment-type.service';

@Component({
  selector: 'app-custom-payment-method',
  templateUrl: './custom-payment-method.component.html',
  styleUrls: ['./custom-payment-method.component.scss'],
  animations: [fadeIn, fadeOut, rotatedState],
})
export class CustomPaymentMethodComponent extends CheckoutPaymentMethodComponent {

  constructor(
    protected userPaymentService: UserPaymentService,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected activatedRoute: ActivatedRoute,
    protected translationService: TranslationService,
    protected activeCartFacade: ActiveCartFacade,
    protected checkoutStepService: CheckoutStepService,
    protected globalMessageService: GlobalMessageService,
    protected checkoutPaymentTypeService: CustomCheckoutPaymentTypeService) {
    super(userPaymentService, checkoutDeliveryAddressFacade, checkoutPaymentFacade, activatedRoute, translationService, activeCartFacade, checkoutStepService, globalMessageService);
  
  }

}
