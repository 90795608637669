import {NgModule} from '@angular/core';
import {provideConfig} from '@spartacus/core';
import {BaseTmsModule, TmsConfig} from '@spartacus/tracking/tms/core';
import {GtmModule} from '@spartacus/tracking/tms/gtm';
import {environment} from 'src/environments/environment';
import {CategoryPageResultsEvent, HomePageEvent, SearchPageResultsEvent} from '@spartacus/storefront';
import {EffectsModule} from '@ngrx/effects';
import {
  CustomNavigationEventModule
} from 'src/app/spartacus/features/tracking/custom-events/navigation/custom-navigation-event.module';
import {CustomCartEventModule} from 'src/app/spartacus/features/tracking/custom-events/cart/custom-cart-event.module';
import {
  CustomCheckoutEventModule
} from 'src/app/spartacus/features/tracking/custom-events/checkout/place-order/custom-checkout-event.module';
import {
  CustomCheckoutStepsEventModule
} from 'src/app/spartacus/features/tracking/custom-events/checkout/checkout-steps/custom-checkout-steps-event.module';
import {
  CustomProductPageEventModule
} from 'src/app/spartacus/features/tracking/custom-events/product/custom-product-page-event.module';
import {
  CustomNavigationEvent
} from 'src/app/spartacus/features/tracking/custom-events/navigation/custom-navigation.event';
import {
  CustomProductDetailsPageEvent,
  CustomProductDetailsSelectEvent
} from 'src/app/spartacus/features/tracking/custom-events/product/custom-product-page.events';
import {
  CustomCartAddEntrySuccessEvent,
  CustomCartRemoveEntrySuccessEvent,
  CustomCartUpdateEntrySuccessEvent,
  CustomNavigateToCartEvent
} from 'src/app/spartacus/features/tracking/custom-events/cart/custom-cart.events';
import {
  CustomCheckoutDeliveryModeCompleteEvent,
  CustomCheckoutPaymentModeCompleteEvent,
  CustomCheckoutStepsEvent
} from 'src/app/spartacus/features/tracking/custom-events/checkout/checkout-steps/custom-checkout-steps.events';
import {
  CustomOrderPlacedEvent
} from 'src/app/spartacus/features/tracking/custom-events/checkout/place-order/custom-custom-checkout.events';
import {
  CustomCheckoutEffects
} from 'src/app/spartacus/features/tracking/custom-events/checkout/checkout-steps/store/effects/custom-checkout-steps-events.effect';
import {
  CustomCleanEcommerceEvent
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/common/custom-common.events";
import {
  CustomCategoryPageEvent
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/category/custom-category-page.events";
import {
  CustomCategoryPageEventModule
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/category/custom-category-page-event.module";
import {
  CustomNavigateToCheckoutEvent
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/checkout/navigation/custom-checkout-navigation.events";
import {
  CustomCheckoutNavigationEventModule
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/checkout/navigation/custom-checkout-navigation-event.module";
import {
  CustomOrderRefundEvent,
  CustomReorderEvent
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/order/custom-order.events";
import {
  CustomUserAddProductInterestEvent,
  CustomUserLoginEvent, CustomUserRegisterEvent
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/user/custom-user.events";
import {
  CustomSendContactFromSuccessEvent
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/contact/custom-contact.events";
import {
  CustomOrderEventModule
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/order/custom-order-event.module";
import {
  CustomContactEventModule
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/contact/custom-contact-event.module";
import {
  CustomUserEventModule
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/user/custom-user-event.module";
import {
  CustomUpdateNewsletterEvent
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/newsletter/custom-newsletter.events";
import {
  CustomNewsletterEventModule
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/newsletter/custom-newsletter-event.module";
import {
  CustomPromotionEvent
} from "../../../../../../../src/app/spartacus/features/tracking/custom-events/cms/custom-cms.events";

@NgModule({
  imports: [
    BaseTmsModule.forRoot(),
    GtmModule,
    CustomNavigationEventModule,
    CustomCategoryPageEventModule,
    CustomCartEventModule,
    CustomCheckoutNavigationEventModule,
    //CustomCheckoutEventModule,
    //CustomCheckoutStepsEventModule,
    CustomProductPageEventModule,
    CustomOrderEventModule,
    CustomContactEventModule,
    CustomUserEventModule,
    CustomNewsletterEventModule,
    EffectsModule.forFeature([
      CustomCheckoutEffects,
    ]),
  ],
  providers: [
    provideConfig({
      tagManager: {
        gtm: {
          gtmId: environment?.gtmId?.['osbornestore-public'],
          events: [
            /*Cleaner event*/
            CustomCleanEcommerceEvent,
            /* Pages Events */
            HomePageEvent,
            CustomNavigationEvent,
            CustomCategoryPageEvent,
            // PageEvent,
            SearchPageResultsEvent,
            CategoryPageResultsEvent,
            CustomProductDetailsPageEvent,
            CustomProductDetailsSelectEvent,
            // SearchBoxSuggestionSelectedEvent,
            // SearchBoxProductSelectedEvent,
            /* Cart Events */
            CustomNavigateToCartEvent,
            CustomCartUpdateEntrySuccessEvent,
            CustomCartAddEntrySuccessEvent,
            CustomCartRemoveEntrySuccessEvent,
            /* Checkout Steps Events */
            CustomNavigateToCheckoutEvent,
            CustomCheckoutStepsEvent,
            CustomCheckoutDeliveryModeCompleteEvent,
            CustomCheckoutPaymentModeCompleteEvent,
            /* Order Events */
            CustomOrderPlacedEvent,
            CustomOrderRefundEvent,
            CustomReorderEvent,
            /* User events */
            CustomUserLoginEvent,
            CustomUserRegisterEvent,
            CustomUserAddProductInterestEvent,
            /* Contact Form Events */
            CustomSendContactFromSuccessEvent,
            /* Newsletter */
            CustomUpdateNewsletterEvent,
            /* CMS events */
            CustomPromotionEvent,
          ],
          debug: !environment.production,
        },
      },
    } as TmsConfig),
  ],
})
export class TagManagerFeatureModule {
}
