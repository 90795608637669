<form (ngSubmit)="handleVerifyAddress()" [formGroup]="addressForm">
  <div class="form-group" *ngIf="showTitleCode">
    <ng-container *ngIf="titles$ | async as titles">
      <div *ngIf="titles.length !== 0">
        <label aria-required="true">
          <span class="label-content required">{{
            "addressForm.title" | cxTranslate
            }}</span>
          <ng-select
            formControlName="titleCode"
            [searchable]="true"
            [clearable]="false"
            [items]="titles"
            bindLabel="name"
            bindValue="code"
          ></ng-select>
        </label>
      </div>
    </ng-container>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content">{{ "addressForm.alias" | cxTranslate }}</span>
      <input class="form-control" type="text" formControlName="companyName" />
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required">{{
        "addressForm.firstName.label" | cxTranslate
        }}</span>
      <input class="form-control" type="text" formControlName="firstName" maxlength="35" />
      <cx-form-errors [control]="addressForm.get('firstName')"></cx-form-errors>
    </label>
  </div>
  <div class="form-group">
    <label>
      <span class="label-content required">{{ "addressForm.lastName.label" | cxTranslate }}</span>
      <input type="text" class="form-control" formControlName="lastName" maxlength="35" />
      <cx-form-errors [control]="addressForm.get('lastName')"></cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required">{{
        "addressForm.address1" | cxTranslate
        }}</span>
      <input type="text" class="form-control" formControlName="line1" maxlength="35" />
      <cx-form-errors [control]="addressForm.get('line1')"></cx-form-errors>
      <input type="text" class="form-control" formControlName="line2" maxlength="35" />
      <cx-form-errors [control]="addressForm.get('line2')"></cx-form-errors>
    </label>
  </div>

  <div class="form-group" formGroupName="country">
    <ng-container *ngIf="countries$ | async as countries">
      <div *ngIf="countries.length !== 0">
        <label aria-required="true">
          <span class="label-content required">
            {{ "addressForm.country" | cxTranslate }}</span
          >
          <ng-select
            class="country-select"
            formControlName="isocode"
            [searchable]="true"
            [clearable]="false"
            [items]="countries"
            bindLabel="name"
            bindValue="isocode"
            placeholder="{{ 'addressForm.pleaseSelectOne' | cxTranslate }}"
            (change)="countrySelected($event)"
          >
          </ng-select>
          <cx-form-errors
            [control]="addressForm.get('country.isocode')"
          ></cx-form-errors>
        </label>
      </div>
    </ng-container>
  </div>

  <div class="form-group" formGroupName="region">
    <ng-container *ngIf="regions$ | async as regions">
      <div *ngIf="regions.length !== 0">
        <label aria-required="true">
          <span class="label-content required">
            {{ "addressForm.state" | cxTranslate }}
          </span>
          <ng-select
            class="region-select"
            formControlName="isocode"
            [searchable]="true"
            [clearable]="false"
            [items]="regions"
            bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
            bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
            placeholder="{{ 'addressForm.pleaseSelectOne' | cxTranslate }}"
            (change)="regionSelected($event)"
          ></ng-select>
          <cx-form-errors
            [control]="addressForm.get('region.isocode')"
          ></cx-form-errors>
        </label>
      </div>
    </ng-container>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required">{{ "addressForm.city.label" | cxTranslate }}</span>
      <input
        [maxLength]="25"
        type="text"
        class="form-control"
        formControlName="town"
      />
      <cx-form-errors [control]="addressForm.get('town')"></cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required">
        {{ "addressForm.zipCode.label" | cxTranslate }}</span
      >
      <input
        [maxLength]="12"
        type="text"
        class="form-control"
        formControlName="postalCode"
      />
      <cx-form-errors
        [control]="addressForm.get('postalCode')"
      ></cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required">
        {{ "addressForm.phoneNumber.label" | cxTranslate }}
        <small>{{ "addressForm.phoneNumber.note" | cxTranslate }}</small></span
      >
      <input
        [maxLength]="20"
        type="tel"
        class="form-control"
        formControlName="phone"
      />
      <cx-form-errors
        [control]="addressForm.get('phone')"
        [translationParams]="{
          minLength: addressForm.get('phone')?.errors?.minlength?.requiredLength,
          maxLength: addressForm.get('phone')?.errors?.maxlengthWithNumber?.requiredLength
      }">
      </cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required"> {{ "addressForm.email.label" | cxTranslate }}
      <small>{{ "addressForm.email.note" | cxTranslate }}</small></span>
      <span class="input-group">
        <input type="email" class="form-control" formControlName="email" maxlength="60" />
      </span>
      <cx-form-errors [control]="addressForm.get('email')"></cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label><span class="label-content"> {{ "addressForm.deliveryNoteObservations" | cxTranslate }}</span></label>
    <textarea
      [maxLength]="deliveryNoteObservationsLength"
      class="form-control"
      formControlName="deliveryNoteObservations"
      rows="4"
    ></textarea>
    <cx-form-errors
      [control]="addressForm.get('deliveryNoteObservations')"
    ></cx-form-errors>
  </div>

  <div class="form-group" *ngIf="(addresses$ | async).length && setAsDefaultField" hidden>
    <div class="form-check">
      <label>
        <input type="checkbox" class="form-check-input" formControlName="defaultAddress"
               (change)="toggleDefaultAddress()" />
        <span class="form-check-label">{{ "addressForm.setAsDefault" | cxTranslate }}</span>
      </label>
    </div>
  </div>

  <div class="cx-checkout-btns row text-right">
    <div class="col-12">
      <ng-container *ngIf="showCancelBtn">
        <button class="btn btn-default" (click)="back()">
          {{ cancelBtnLabel || ("addressForm.back" | cxTranslate) }}
        </button>
      </ng-container>
      <button class="btn btn-action" type="submit">
        {{ actionBtnLabel || ("common.continue" | cxTranslate) }}
      </button>
    </div>
  </div>
</form>
