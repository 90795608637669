<div [formGroup]="mode">
  <div class="row">
    <div class="col-md-12 shipping-method">
      <h3 class="cx-checkout-title">
        {{ "checkoutProgress.deliveryMode" | cxTranslate }}
      </h3>

      <ng-container
        *ngIf="!(isUpdating$ | async) &&
         (supportedDeliveryModes$ | async) as supportedDeliveryModes;
          else loading">
        <h4 class="cx-checkout-title">
          {{ "checkoutShipping.chooseShippingMethod" | cxTranslate }}
        </h4>
        <div
          class="form-check"
          *ngFor="let mode of supportedDeliveryModes"
        >
          <input
            class="form-check-input"
            role="radio"
            type="radio"
            id="deliveryMode-{{ mode.code }}"
            aria-checked="true"
            (change)="changeMode(mode.code)"
            [value]="mode.code"
            formControlName="deliveryModeId"
          />
          <label
            class="cx-delivery-label shipping form-check-label form-radio-label"
            for="deliveryMode-{{ mode.code }}"
          >
            <div class="cx-delivery-mode">
              {{ mode.name }}
              <ng-container *ngIf="mode.deliveryCost">
                - {{ mode.deliveryCost.formattedValue }}
              </ng-container>
              <ng-container *ngIf="mode.description">
                - <span> {{ mode.description }}</span>
              </ng-container>
            </div>
          </label>
        </div>
      </ng-container>
      <h4 class="cx-checkout-title">
        {{ "checkoutShipping.deliveryNoteObservations" | cxTranslate }}
      </h4>
      <textarea
        [maxLength]="120"
        class="form-control"
        placeholder="{{
          'checkoutShipping.observationsDeliveryPlaceholder' | cxTranslate
        }}"
        formControlName="deliveryNoteObservations"
        rows="4"
      ></textarea>
      <app-custom-gift-card #giftCardComponent></app-custom-gift-card>
    </div>
  </div>

  <ng-container *ngIf="!continueButtonPressed; else loading">
    <div class="row cx-checkout-btns">
      <div class="col-md-12 col-lg-3">
        <button class="btn btn-block btn-default" (click)="back()">
          {{ backBtnText | cxTranslate }}
        </button>
      </div>
      <div class="col-md-12 col-lg-4">
        <button
          class="btn btn-block btn-action"
          [disabled]="deliveryModeInvalid"
          (click)="next()"
        >
          {{ "common.continue" | cxTranslate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
