import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { CustomNewsDetailsModel } from '../custom-news.model';
import { CustomNewsState, NEWS_FEATURE, StateWithNews } from '../custom-news.state';

export const getNewsDetailsState: MemoizedSelector<StateWithNews, CustomNewsState> =
  createFeatureSelector<CustomNewsState>(NEWS_FEATURE);

export const getNewsDetailsLoaderState: MemoizedSelector<StateWithNews, LoaderState<CustomNewsDetailsModel>> =
  createSelector(getNewsDetailsState, (state) => state.details);

export const getNewsDetails: MemoizedSelector<StateWithNews, CustomNewsDetailsModel> =
  createSelector(getNewsDetailsLoaderState, (state) => StateUtils.loaderValueSelector(state));

export const getNewsDetailsLoading: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsDetailsLoaderState, (state) => StateUtils.loaderLoadingSelector(state));

export const getNewsDetailsError: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsDetailsLoaderState, (state) => StateUtils.loaderErrorSelector(state));

export const getNewsDetailsSuccess: MemoizedSelector<StateWithNews, boolean> =
  createSelector(getNewsDetailsLoaderState, (state) => StateUtils.loaderSuccessSelector(state));
