import { Component } from '@angular/core';
import { CheckoutProgressMobileBottomComponent } from '@spartacus/checkout/base/components';

@Component({
  selector: 'cx-checkout-progress-mobile-bottom',
  templateUrl: './custom-checkout-progress-mobile-bottom.component.html',
})
export class CustomCheckoutProgressMobileBottomComponent extends CheckoutProgressMobileBottomComponent {

}
