import { Injectable, OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import { Store } from '@ngrx/store';
import {
  UserIdService,
  OCC_USER_ID_ANONYMOUS,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { WishlistList } from 'src/app/model/wishlist.model';
import { UserWishlistActions } from '../store/actions';
import { CustomWishlistSelectors } from '../store/selector';
import { StateWithWishlist } from '../store/selector/custom-wishlist.selectors';

@Injectable({
  providedIn: 'root',
})
export class CustomWishListService implements OnDestroy {
  subscription: Subscription = new Subscription();

  constructor(
    protected store: Store<StateWithWishlist>,
    protected userIdService: UserIdService,
    protected userAccountFacade: UserAccountFacade,
  ) { }

  /**
   * Add product to wishlist
   *
   * @param userId User ID
   * @param productCode Product code
   */
  addProductToWishlist(
    userId: string,
    productCode: string,
  ): void {
    this.store.dispatch(
      new UserWishlistActions.AddProductToWishlist({
        userId,
        productCode,
      }),
    );
  }

  /**
   * Remove product from wishlist
   *
   * @param userId User ID
   * @param productCode Product code
   */
  removeProductFromWishlist(
    userId: string,
    productCode: string,
  ): void {
    this.store.dispatch(
      new UserWishlistActions.RemoveProductFromWishlist({
        userId,
        productCode,
      }),
    );
  }

  addToWishlist(productCode: string): void {
    this.changeProductWishlist(productCode, true);
  }

  changeProductWishlist(productCode: string, add: boolean): void {
    this.subscription.add(
      this.getWishList()
        .pipe(
          distinctUntilChanged(),
          withLatestFrom(this.userIdService.getUserId()),
          tap(([wishList, userId]) => {
            if (
              !Boolean(wishList) &&
              Boolean(userId) &&
              userId !== OCC_USER_ID_ANONYMOUS
            ) {
              this.loadWishList();
            }
          }),
          filter(([wishList]) => Boolean(wishList)),
          take(1)
        )
        .subscribe(([, userId]) =>
          add ? this.addProductToWishlist(userId, productCode) : this.removeProductFromWishlist(userId, productCode)
        )
    );
  }

  removeFromWishlist(productCode: string): void {
    this.changeProductWishlist(productCode, false);
  }

  getWishList(): Observable<WishlistList> {
    return this.store.pipe(
      select(CustomWishlistSelectors.getWishlistLoaderState),
      tap((wishlistState) => {
        const attemptedLoad =
          wishlistState.loading ||
          wishlistState.success ||
          wishlistState.error;
        if (!attemptedLoad) {
          this.loadWishList();
        }
      }),
      map((wishlistState) => wishlistState.value)
    );
  }

  loadWishList(): void {
    let userId;
    this.userIdService
      .getUserId()
      .subscribe((occUserId) => (userId = occUserId))
      .unsubscribe();
    if (userId !== OCC_USER_ID_ANONYMOUS) {
      this.store.dispatch(
        new UserWishlistActions.LoadUserWishlist({
          userId,
        })
      );
    }
  }

  /**
   * Returns a loaded flag for wishlist
   */
   getWishListLoaded(): Observable<boolean> {
    return this.store.pipe(select(CustomWishlistSelectors.getWishlistLoaded));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
