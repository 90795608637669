import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginRegisterComponent } from '@spartacus/user/account/components';
import { CustomExtraAppConfigService } from 'src/app/custom/config/services/custom-extra-app-config.service';

@Component({
  selector: 'app-custom-login-register',
  templateUrl: './custom-login-register.component.html',
  styleUrls: ['./custom-login-register.component.scss']
})
export class CustomLoginRegisterComponent extends LoginRegisterComponent {
  isEnabledCustomerRegister: boolean;
  showExtendedLoginInfo: boolean;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected customExtraAppConfigService: CustomExtraAppConfigService,
  ) {
    super(activatedRoute);
    this.isEnabledCustomerRegister = this.customExtraAppConfigService.isEnabledCustomerRegister();
    this.showExtendedLoginInfo = this.customExtraAppConfigService.showExtendedLoginInfo();
  }
}
