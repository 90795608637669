import { Component, OnInit } from '@angular/core';
import { isNotNullable, PageMetaService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-custom-page-top-title',
  templateUrl: './custom-page-top-title.component.html',
})
export class CustomPageTopTitleComponent implements OnInit {
  title$: Observable<string>;

  constructor(
    protected pageMetaService: PageMetaService
  ) {}

  ngOnInit(): void {
    this.setTitle();
  }

  private setTitle(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(isNotNullable),
      map((meta) => (meta.heading || meta.title) ?? '')
    );
  }
}
