import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsCustomColumnsWithFileListGroupModel } from './custom-columns-with-file-list-group.model';
import { CmsService } from '@spartacus/core';
import { CmsCustomFilesInfoModel } from './custom-files-info/custom-files-info.model';

@Component({
  selector: 'app-custom-columns-with-file-list-group',
  templateUrl: './custom-columns-with-file-list-group.component.html',
})
export class CustomColumnsWithFileListGroupComponent implements OnInit {

  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;

  @Input() columnsWithFileListGroup: CmsCustomColumnsWithFileListGroupModel;
  @Input() columnClass: string;

  columnData$: Observable<CmsCustomColumnsWithFileListGroupModel>;
  fileGroup$: Observable<Observable<CmsCustomFilesInfoModel>[]>;

  constructor(
    protected componentData: CmsComponentData<CmsCustomColumnsWithFileListGroupModel>,
    protected cmsService: CmsService,
  ) {
  }

  ngOnInit(): void {
    if (this.columnsWithFileListGroup) {
      this.initComponent(this.columnsWithFileListGroup);
      this.columnData$ = of(this.columnsWithFileListGroup);
    } else {
      this.columnData$ = this.componentData.data$.pipe(
        tap(component => this.initComponent(component)),
      );
    }

    this.fileGroup$ = this.columnData$.pipe(
      map((data: any) => {
          const fileCode = data?.files?.trim()?.split(' ') || '';
          const pdfFileCode = data?.pdfFiles?.trim()?.split(' ') || '';
          const codeList = fileCode.concat(pdfFileCode);
          if (codeList) {
            return codeList.map(code => this.cmsService.getComponentData(code));
          }
          return of(null);
        },
      ),
    );
  }

  initComponent(component): void{
    this.styleClasses = component.styleClasses;
  }
}
