<ng-template #itemTemplate>
  <cx-media [container]="item.images?.PRIMARY" format="product"></cx-media>
  <h4>
    {{ 'giftbox.itemConfigure' | cxTranslate }} {{ item.name }}
  </h4>
  <button
    class="btn btn-primary"
    [disabled]="!hasStock"
  >
    {{ (hasStock ? 'giftbox.itemSelect' : 'giftbox.itemSelectNotAvailable') | cxTranslate }}
  </button>
</ng-template>
<ng-container [ngSwitch]="hasStock">
  <ng-container *ngSwitchCase="true">
    <a tabindex="0" (click)="selectGiftBox()">
      <ng-template [ngTemplateOutlet]="itemTemplate"></ng-template>
    </a>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <a href="#">
      <ng-template [ngTemplateOutlet]="itemTemplate"></ng-template>
    </a>
  </ng-container>
</ng-container>
