import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StateModule } from '@spartacus/core';
import { FriendsGuestEffects } from './effects/custom-friend-guest.effect';
import { reducerGuest, reducerGuestFriendsProvider } from './reducer/custom-friends-guest.reducer';
import { CustomUserFriendsAdapter } from '../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/adapter/custom-occ-friends.adapter';
import { StoreModule } from '@ngrx/store';
import { GUEST_FRIEND_FEATURE } from './custom-friends-guest.state';


@NgModule({
  imports: [CommonModule, StateModule, EffectsModule.forFeature(FriendsGuestEffects), RouterModule,   StoreModule.forFeature(GUEST_FRIEND_FEATURE, reducerGuest )],
  providers: [reducerGuestFriendsProvider, CustomUserFriendsAdapter],
})
export class CustomFriendsGuestStoreModule {
}
