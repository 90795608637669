import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostBinding, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {CmsService, PageMeta, PageMetaService, SERVER_REQUEST_URL, WindowRef} from '@spartacus/core';
import { CmsCustomSocialMediaShareComponent } from './custom-social-media-share.model';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-custom-social-media-share',
  templateUrl: './custom-social-media-share.component.html',
})
export class CustomSocialMediaShareComponent {
  @HostBinding('class') styleClasses: string;
  title: string;

  mediaLinks$: Observable<any[]> = this.component.data$.pipe(
    tap((data) => {
      this.title = data?.title;
      this.styleClasses = data?.styleClasses ?? '';
    }),
    switchMap(data =>
      combineLatest(
        data?.mediaLinks.trim().split(' ').map((component: any) =>
          this.cmsService.getComponentData<any>(component)
        )
      )
    )
  );

  constructor(
    private component: CmsComponentData<CmsCustomSocialMediaShareComponent>,
    private cmsService: CmsService,
    @Inject(PLATFORM_ID) protected platform: any,
    @Inject(DOCUMENT) protected document: any,
    @Optional() @Inject(SERVER_REQUEST_URL) protected serverRequestUrl?: string,
    private pageMetaService?: PageMetaService,
    protected winRef?: WindowRef,
  ) {}

  getTarget(target: string | boolean): string | null {
    return target === 'true' || target === true ? '_blank' : null;
  }

  share(socialMediaUrl: string): void {
    let pageMeta: PageMeta;
    this.pageMetaService.getMeta()
      .subscribe(data => pageMeta = data)
      .unsubscribe();
    let params = new HttpParams();
    const title = pageMeta.heading ?? pageMeta.title;
    if (socialMediaUrl.includes('facebook')) {
      params = params.set('t', title);
      params = params.set('u', this.currentUrl);
    } else if (socialMediaUrl.includes('twitter')) {
      params = params.set('text', title);
      params = params.set('url', this.currentUrl);
    } else if (socialMediaUrl.includes('pinterest')) {
      if (pageMeta.image) {
        params = params.set('media', pageMeta.image);
      }
      params = params.set('description', title);
      params = params.set('url', this.currentUrl);
    } else {
      params = params.set('url', this.currentUrl);
    }
    this.winRef?.nativeWindow?.open(
      socialMediaUrl + '?' + params.toString(),
      '',
      'width=640, height=480, left=0, top=0, location=no, status=yes, scrollbars=yes, resizable=yes'
      );
  }

  private get currentUrl(): string {
    if (isPlatformBrowser(this.platform)) {
      return this.document.location.href;
    }
    if (this.serverRequestUrl) {
      return this.serverRequestUrl;
    }
  }

  get btnClass(): string {
    return 'btn btn-icon btn-icon-fade';
  }
}
