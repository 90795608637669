<ng-container *ngIf="(columnData$ |async) as data">
  <ng-container *ngIf="data?.title">
    <h3 [innerHTML]="data.title" class="column-title"></h3>
  </ng-container>
  <ng-container *ngIf="data?.description?.content">
    <p [innerHTML]="data.description.content| htmlSanitize: 'html'" [class]="data.description.styleClasses + ' column-description'"></p>
  </ng-container>
  <ng-container *ngIf="data.files || data.pdfFiles">
    <ng-container *ngIf="(fileGroup$ | async) as fileGroup">
      <ng-container *ngIf="fileGroup?.length">
        <ng-container *ngFor="let file of fileGroup">
          <ng-container *ngIf="file">
            <app-custom-files-info-component
              [files]="file"
            >
            </app-custom-files-info-component>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
