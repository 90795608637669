import { LayoutConfig, DIALOG_TYPE } from "@spartacus/storefront";
import { CustomNewsletterDialogComponent } from "./custom-newsletter-dialog/custom-newsletter-dialog.component";
export const customNewslettersDialogModalLayoutConfig: LayoutConfig = {
    launch: {
        NEWSLETTERS: {
          inlineRoot: true,
          component: CustomNewsletterDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
          multi: true
       },
    },
  };