import { Component } from '@angular/core';
import { map, startWith, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RoutingService } from '@spartacus/core';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomExtraAppConfigService } from 'src/app/custom/config/services/custom-extra-app-config.service';
import { Cart } from '@spartacus/cart/base/root';
import { MiniCartComponent, MiniCartComponentService } from '@spartacus/cart/base/components/mini-cart';

@Component({
  selector: 'app-custom-mini-cart',
  templateUrl: './custom-mini-cart.component.html',
  styleUrls: ['./custom-mini-cart.component.scss'],
})
export class CustomMiniCartComponent extends MiniCartComponent {
  hasGiftBox = false;
  displayPreview = false;

  quantity$: Observable<number> = this.activeCartService.getActive().pipe(
    tap(cart => this.hasGiftBox = !!cart.giftBoxProduct),
    startWith({ deliveryItemsQuantity: 0 }),
    map((cart: Cart) => {
        return cart.totalItems || 0;
      },
    ),
  );

  constructor(
    protected activeCartService: CustomActiveCartService,
    protected miniCartComponentService: MiniCartComponentService,
    protected routingService?: RoutingService,
    protected customExtraAppConfigService?: CustomExtraAppConfigService
  ) {
    super(miniCartComponentService);
  }

  handleMiniCartClick(): void {
    if (this.hasGiftBox) {
      this.routingService.go({ cxRoute: 'giftboxWizard' });
    } else if (this.customExtraAppConfigService.miniCartPreview()) {
      this.togglePreview();
    } else {
      this.routingService.go({ cxRoute: 'cart' });
    }
  }

  togglePreview(display?: boolean): void {
    this.displayPreview = display !== undefined ? display : !this.displayPreview;
  }
}
