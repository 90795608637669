import { Component, HostBinding } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CMSBoxCategoriesComponentData as model } from './box-category.model';

@Component({
  selector: 'app-box-categories',
  templateUrl: './box-categories.component.html',
  styleUrls: ['./box-categories.component.scss'],
})

export class BoxCategoriesComponent {

  items$: Observable<any[]> = this.componentData.data$.pipe(
    map(data => {
      this.initComponent(data);
      return data;
    }),
    switchMap(data =>
      combineLatest(
        data.boxItem.trim().split(' ').map((codes: any) => this.cmsService.getComponentData(codes).pipe(
          map(tab => tab),
        ))),
    ),
  );

  /**
   * Adds a specific theme for the carousel. The effect can be
   * used in CSS customisations.
   */
  @HostBinding('class') theme = '';
  @HostBinding('class') styleClasses = '';
  @HostBinding('style.background') backgroundColor: string;

  constructor(
    private componentData: CmsComponentData<model>,
    private cmsService: CmsService,
  ) {
  }

  initComponent(item: model): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth === true || item?.fullPageWidth === 'true') {
      this.styleClasses += ' full-page-width';
    }
    this.backgroundColor = item?.backgroundColorCode ?? '';
  }
}
