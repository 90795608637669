import { InjectionToken } from '@angular/core';
import { Converter } from '@spartacus/core';
import { Wishlist, WishlistModification } from 'src/app/model/wishlist.model';

export const WISHLIST_NORMALIZER = new InjectionToken<Converter<any, Wishlist>>(
  'WishlistNormalizer'
);

export const WISHLIST_MODIFICATION_NORMALIZER = new InjectionToken<Converter<any, WishlistModification>>(
  'WishlistModificationNormalizer'
);
