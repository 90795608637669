<h2>{{ 'statistics.label' | cxTranslate }}</h2>

<div class="container-fluid mt-4 mb-4">
  <form [formGroup]="statisticsCompareForm">
    <div class="d-flex justify-content-end align-items-center w-100">
    <span>
        {{ 'statistics.compare.label' | cxTranslate }}
      </span>
      <div class="form-group radio-inline ml-3 mb-0">
        <span class="form-check mb-0">
        <label class="mb-0">
          <input
            class="form-check-input mr-2 mb-0"
            role="radio"
            type="radio"
            (change)="statisticsComparePreviousYear()"
            [value]="compareYears"
            aria-checked="true"
            formControlName="years"
          />
          {{ 'statistics.compare.yes' | cxTranslate }}
        </label>
      </span>
      </div>
      <div class="form-group radio-inline ml-3 mb-0">
      <span class="form-check mb-0">
        <label class="mb-0">
          <input
            class="form-check-input mr-2 mb-0"
            role="radio"
            type="radio"
            (change)="statisticsComparePreviousYear()"
            [value]="[currentYear]"
            aria-checked="false"
            formControlName="years"
          />
          {{ 'statistics.compare.no' | cxTranslate }}
        </label>
      </span>
      </div>
    </div>
  </form>
</div>

<div class="cx-section">
  <div class="row cx-address-deck" *ngIf="!(getStatisticsLoading$ | async) as statistics; else loading">
    <ngx-charts-bar-vertical-2d
      [view]="view"
      [barPadding]="0"
      [scheme]="colorScheme"
      [results]="getStatistics$ | async"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="'statistics.month' | cxTranslate"
      [yAxisLabel]="'statistics.euros' | cxTranslate"
      [legendTitle]="'statistics.years' | cxTranslate"
      [showDataLabel]="showDataLabel"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
    >
    </ngx-charts-bar-vertical-2d>
  </div>
  <ng-template #loading>
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</div>

<div class="cx-section">
  <div class="row" *ngIf="showCompareInformation">
    <div class="col mt-4 mb-4 text-md-right">
      <div class="d-inline-block pt-2 pb-2 pl-4 pr-4 bg-alternative ml-2">{{this.previousYear}}</div>
      <div class="d-inline-block pt-2 pb-2 pl-4 pr-4 bg-highlight ml-2">{{this.currentYear}}</div>
    </div>
  </div>

  <div class="row cx-address-deck" *ngIf="!(getStatisticsProductLoading$ | async); else loading">
    <div class="col">
      <ng-container *ngIf="getStatisticsProducts$ | async as statisticsProducts">
        <table
          class="table table-main compress {{showCompareInformation ? 'alternative' : ''}}">
          <thead class="">
          <th scope="col" class="table-main-cell-left">{{ 'statistics.table.article' | cxTranslate }}</th>
          <th scope="col" *ngFor="let monthHeader of monthHeaderArray">
            {{ 'statistics.table.' + monthHeader | cxTranslate }}
          </th>
          </thead>
          <tbody>
          <ng-container *ngFor="let product of statisticsProducts">
            <ng-container *ngFor="let year of product.years; let productYearsIndex = index">
              <tr>
                <td class="table-main-cell-left">
                  <ng-container *ngIf="productYearsIndex == 0">
                    <div class="table-main-label">
                      {{ 'statistics.table.article' | cxTranslate }}
                    </div>
                    {{ product.name }}
                  </ng-container>
                </td>
                <ng-container *ngFor="let yearData of year | keyvalue; let i = index">
                  <td *ngFor="let data of yearData.value">
                    <div class="table-main-label">
                      {{ 'statistics.table.' + monthHeaderArray[i] | cxTranslate }}
                    </div>
                    {{data}}
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
  <ng-template #loading>
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</div>

