import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  CustomAgeStatusActionTypes,
  LoadAgeStatus,
  LoadAgeStatusFail,
  LoadAgeStatusSuccess,
  UpdateAgeStatus,
  UpdateAgeStatusFail,
  UpdateAgeStatusSuccess,
} from '../actions/custom-age-status.actions';
import {CookieService} from '../../../../../config/services/cookie.service';

@Injectable()
export class CustomAgeStatusEffect {
  constructor(
    private actions$: Actions,
    private cookieService: CookieService
  ) {
  }

  loadAgeStatus$: Observable<LoadAgeStatus | LoadAgeStatusSuccess | LoadAgeStatusFail> =  createEffect(() => this.actions$.pipe(
    ofType(CustomAgeStatusActionTypes.LOAD_AGE_STATUS),
    map((action: LoadAgeStatus) => action.payload),
    map((status: number) =>
      new LoadAgeStatusSuccess({accepted : status === 1}),
    ),
  ));

  updateAgeStatus$: Observable<UpdateAgeStatus | UpdateAgeStatusSuccess | UpdateAgeStatusFail> =  createEffect(() => this.actions$.pipe(
    ofType(CustomAgeStatusActionTypes.UPDATE_AGE_STATUS),
    map((action: UpdateAgeStatus) => action.payload),
    map((status: number) => {
      this.cookieService.set('agestatus', status.toString(), 365);
      return new UpdateAgeStatusSuccess({accepted : status === 1});
    }),
  ));
}
