import { Component, ViewEncapsulation } from '@angular/core';
import { CustomerEmulationComponent } from '@spartacus/asm/components';

@Component({
  selector: 'cx-customer-emulation',
  templateUrl: './custom-customer-emulation.component.html',
  styleUrls: ['./custom-customer-emulation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomCustomerEmulationComponent extends CustomerEmulationComponent {
}
