import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import { CustomIconsModule } from '../../misc/icon/custom-icons.module';
import { CustomAddToWishListComponent } from './custom-add-to-wish-list.component';

@NgModule({
  imports: [CommonModule, I18nModule, CustomIconsModule, RouterModule, UrlModule],
  providers: [
    provideConfig({
      cmsComponents: {
        AddToWishListComponent: {
          component: CustomAddToWishListComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomAddToWishListComponent],
  entryComponents: [CustomAddToWishListComponent],
  exports: [CustomAddToWishListComponent],
})
export class CustomAddToWishListModule {}
