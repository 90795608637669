import { Injectable } from '@angular/core';
import { MediaService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomNewsTitleService } from 'src/app/custom/cms-components/content/custom-news/components/custom-news-title/custom-news-title.service';
import { CustomNewsDetailsModel } from 'src/app/custom/cms-components/content/custom-news/store/custom-news.model';

@Injectable({
  providedIn: 'root',
})
export class CustomNewsArticleSchemaBuilder implements SchemaBuilder {
  constructor(
    protected newsService: CustomNewsTitleService,
    protected mediaService: MediaService,
    ) {}

  build(): Observable<any> {
    return this.newsService
      .getNewsDetails()
      .pipe(map((newsDetails: CustomNewsDetailsModel) => {
        if (newsDetails) {
          return this.collect(newsDetails);
        }
        return of({});
      }));
  }

  protected collect(newsDetails: CustomNewsDetailsModel): any {
    if (!newsDetails || !newsDetails.newsTitle) {
      return {};
    }
    const result: any = {
      '@context': 'http://schema.org',
      '@type': 'NewsArticle',
      headline: newsDetails.newsTitle,
      dateModified: newsDetails.modifiedTime,
      description: newsDetails.thumbnailDescription,
    };
    if (newsDetails.coverImage?.url) {
      result.image = this.mediaService.getMedia(newsDetails.coverImage).src;
    }
    return result;
  }
}
