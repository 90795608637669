import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StateModule } from '@spartacus/core';
import { FriendsEmailsEffects } from './effects/custom-friend-email.effect';
import { metaReducers, reducerFriendEmailsToken, reducerFriendsEmailsProvider } from './reducer/custom-friends-email.reducer';
import { CustomUserFriendsAdapter } from '../../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/adapter/custom-occ-friends.adapter';
import { StoreModule } from '@ngrx/store';
import { FRIEND_EMAIL_FEATURE } from './custom-friends-email.state';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StateModule,
    EffectsModule.forFeature(FriendsEmailsEffects),
    StoreModule.forFeature(FRIEND_EMAIL_FEATURE, reducerFriendEmailsToken, { metaReducers }),
  ],
  providers: [reducerFriendsEmailsProvider, CustomUserFriendsAdapter],
})
export class CustomFriendsEmailStoreModule {
}
