import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderState } from '@spartacus/core/src/state/utils/loader';
import { BillHistoryList, StateBills } from 'src/app/model/bill.model';

export const BILLS_FEATURE = 'bills';

export interface StateWithBills {
  [BILLS_FEATURE]: StateBills;
}

export const getBillsState: MemoizedSelector<StateWithBills, StateBills> =
  createFeatureSelector<StateBills>(BILLS_FEATURE);

export const getBillsLoaderState: MemoizedSelector<
  StateWithBills,
  LoaderState<BillHistoryList>
> = createSelector(getBillsState, (state: StateBills) => state.bills);

export const getBills: MemoizedSelector<
  StateWithBills,
  BillHistoryList
> = createSelector(getBillsLoaderState, (state: LoaderState<BillHistoryList>) =>
  StateUtils.loaderValueSelector(state)
);

export const getBillsLoading: MemoizedSelector<
  StateWithBills,
  boolean
> = createSelector(getBillsLoaderState, (state: LoaderState<BillHistoryList>) =>
  StateUtils.loaderLoadingSelector(state)
);

export const getBillsLoaded: MemoizedSelector<
  StateWithBills,
  boolean
> = createSelector(getBillsLoaderState, (state: LoaderState<BillHistoryList>) =>
  StateUtils.loaderSuccessSelector(state)
);
