/*
INVITADO: Usuario "lite" que solo tiene nombre e mail

REGISTRADO :Usuario que ha recibido invitación y se ha registrado

NO INVITADO: Usuario que ha sido registrado por el Empleado

CADUCADO : La invitación ha caducado (el usuario 'lite' invitado ha pasado 1 mes y no se ha registrado)
*/

export enum InvitationStatus {
    GUEST = 'INVITED',
    REGISTERED = 'REGISTERED',
    NOT_INVITED = 'NOT_INVITED',
    EXPIRED = 'EXPIRED',
    UNKNOWN_EMAIL = 'UNKNOWN_EMAIL',
    INVITED = 'INVITED',
}
