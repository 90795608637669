import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResetPasswordComponent } from '@spartacus/user/profile/components';

@Component({
  selector: 'cx-reset-password',
  templateUrl: './custom-reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'user-form' },
})
export class CustomResetPasswordComponent extends ResetPasswordComponent {
}
