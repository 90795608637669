<div *ngIf="(activeStepIndex$ | async) !== undefined">
  <div *ngIf="cart$ | async as cart">
    <div class="cx-media">
      <div
        class="cx-list-media title"
        *ngIf="cart?.totalItems && cart?.subTotal"
      >
        <h1>{{ "checkoutReview.placeOrder" | cxTranslate }}</h1>
      </div>
      <div *ngFor="let step of steps$ | async; let i = index">
        <div class="cx-list-media" *ngIf="i < activeStepIndex">
          <div>{{ step.name | cxTranslate }}</div>
          <button
            class="btn btn-link"
            [routerLink]="{ cxRoute: step.routeName } | cxUrl"
          >
            <i class="icon-pencil"></i>
          </button>
        </div>
        <div class="cx-list-media is-active" *ngIf="i === activeStepIndex">
          <div>{{ step.name | cxTranslate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
