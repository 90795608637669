import { Component } from '@angular/core';
import {ICON_TYPE, LaunchDialogService} from '@spartacus/storefront';

@Component({
    selector: 'app-custom-add-giftbox-to-cart-dialog',
    templateUrl: './custom-add-giftbox-to-cart-dialog.component.html',
})
export class CustomAddGiftboxToCartDialogComponent {
    isOtherGiftbox: boolean;
    iconTypes = ICON_TYPE;
    constructor(private launchDialogService: LaunchDialogService) {}

    accept(): void {
      this.launchDialogService.closeDialog(true);
    }

    close(reason: string): void {
      this.launchDialogService.closeDialog(reason);
    }
}
