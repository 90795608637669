import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Address } from '@spartacus/core';
import { OrderOverviewComponent } from '@spartacus/order/components';
import { Order } from '@spartacus/order/root';
import { Card } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'cx-order-overview',
  templateUrl: './custom-order-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomOrderOverviewComponent extends OrderOverviewComponent {
  getModeOfPaymentCardContent(
    order: Order,
  ): Observable<Card> {
    return combineLatest([
      this.translation.translate('paymentTypes.title'),
      this.translation.translate('paymentTypes.paymentType', {
        context: order?.paymentMode?.code, display: order?.paymentMode?.name, name: order?.user?.name
      }),
    ]).pipe(
      map(([textTitle, paymentTypeTranslation]) => ({
        title: textTitle,
        text: [ paymentTypeTranslation ],
      }))
    );
  }

  getOrderCurrentDateCardContent(formattedDate?: string): Observable<Card> {
    return this.translation
      .translate('checkoutOrderConfirmation.placedOn')
      .pipe(
        map((textTitle) => {
          return {
            title: textTitle,
            text: [formattedDate],
          };
        })
      );
  }

  getOrderStatusCardContent(statusDisplay: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.status').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [statusDisplay],
      }))
    );
  }

  getAddressCardContent(deliveryAddress: Address): Observable<Card> {
    return this.translation.translate('addressCard.shipTo').pipe(
      filter(() => Boolean(deliveryAddress)),
      map((textTitle) => ({
        title: textTitle,
        textBold: deliveryAddress.firstName + (deliveryAddress.lastName ? ' ' + deliveryAddress.lastName : ''),
        text: [deliveryAddress.formattedAddress, deliveryAddress.country.name],
      }))
    );
  }

  getBillingAddressCardContent(billingAddress: Address): Observable<Card> {
    return this.translation.translate('paymentForm.billingAddress').pipe(
      filter(() => Boolean(billingAddress)),
      map((textTitle) => ({
        title: textTitle,
        textBold: billingAddress.firstName + (billingAddress.lastName ? ' ' + billingAddress.lastName : ''),
        text: [billingAddress.formattedAddress, billingAddress.country.name],
      }))
    );
  }
}
