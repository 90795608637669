import {Injectable, OnDestroy} from "@angular/core";
import {CmsService, EventService} from "@spartacus/core";
import {EcommerceType} from "../tag-manager-feature.model";
import {getProductDetailSelectedUnit} from "../product/custom-product-detail.utils";
import {CustomPromotionEvent} from "./custom-cms.events";
import {ProductItemSelect} from "../product/custom-product-events.model";
import {take, tap} from "rxjs/operators";
import {Subscription} from "rxjs";
import {CustomCleanEcommerceEvent} from "../common/custom-common.events";
import {CustomPromotionComponentModel} from "./custom-cms.model";

@Injectable({
  providedIn: 'root',
})
export class CustomCmsEventService implements OnDestroy {

  private subscriptions: Subscription = new Subscription();

  constructor(protected eventService: EventService,
              protected cmsService: CmsService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public dispatchSelectPromotionEvent(component: CustomPromotionComponentModel): void {
    this.dispatchPromotionEvent(component, EcommerceType.SELECT_PROMOTION);
  }

  public dispatchViewPromotionEvent(component: CustomPromotionComponentModel): void {
    this.dispatchPromotionEvent(component, EcommerceType.VIEW_PROMOTION);
  }

  private dispatchPromotionEvent(component: CustomPromotionComponentModel, event: EcommerceType): void {
    this.eventService.dispatch<CustomCleanEcommerceEvent>(
      <CustomCleanEcommerceEvent>{ecommerce: null},
      CustomCleanEcommerceEvent
    );

    if (!component?.slot) {
      this.subscriptions.add(
        this.cmsService.getCurrentPage().pipe(
          take(1),
          tap(page => {
            component.slot = '';

            for (const slot in page.slots) {
              if (page.slots[slot].components?.filter(data => data.uid === component.name).length) {
                component.slot = slot;
                break;
              }
            }

            this.eventService.dispatch<CustomPromotionEvent>(
              this.buildPromotionEventData<CustomPromotionEvent>(component, event),
              CustomPromotionEvent
            );
          })
        ).subscribe()
      )
    } else {
      this.eventService.dispatch<CustomPromotionEvent>(
        this.buildPromotionEventData<CustomPromotionEvent>(component, event),
        CustomPromotionEvent
      );
    }
  }

  private buildPromotionEventData<T>(component: CustomPromotionComponentModel, event: EcommerceType): T {
    const data: T = <any>{
      event: event,
      ecommerce: {
        creative_name: component.name,
        creative_slot: component.slot,
        promotion_name: component.promotion,
      }
    } as T;

    if (component.products?.length) {
      data['ecommerce'].items = component.products.map((product, index): ProductItemSelect => {
        return {
          item_name: product.name || product.code,
          item_id: product.code,
          item_brand: product.brands ? product.brands[0].name : '',
          item_category: product.categories ? product.categories[0]?.name || '' : '',
          item_category2: product.categories ? product.categories[1]?.name || '' : '',
          item_category3: product.categories ? product.categories[2]?.name || '' : '',
          item_variant: getProductDetailSelectedUnit(product),
          index: index,
          quantity: 1,
          price: product.price?.value.toString() || ''
        }
      })
    }

    return data;
  }
}
