import { Component, HostBinding, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsCustomColumnsWithFilesContainerModel } from './custom-columns-with-files-container.model';
import { CmsService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-columns-with-files-container',
  templateUrl: './custom-columns-with-files-container.component.html',
})
export class CustomColumnsWithFilesContainerComponent implements OnInit {

  @HostBinding('class') styleClasses: string;
  @HostBinding('style.background') backgroundColor: string;

  columns = {
    md: 12,
    lg: 12,
  };

  data$: Observable<CmsCustomColumnsWithFilesContainerModel> = this.componentData.data$.pipe(
    tap(component => {
      this.initComponent(component);
    }),
  );

  columnWithFilesList$: Observable<Observable<CmsCustomColumnsWithFilesContainerModel>[]> = this.data$.pipe(
    map((data: any) => {
      const codeList = data?.columnWithFileGroup?.trim().split(' ');
      if (codeList) {
        const codesLength = codeList.length;
        if (codesLength > 1) {
          this.columns.md = 6;
        }
        if (codesLength > 4) {
          this.columns.lg = 3;
        } else {
          this.columns.lg = 12 / codesLength;
        }
      }
      return codeList;
    }),
    map((codes) => {
      if (codes) {
        return codes.map((code) => this.cmsService.getComponentData(code));
      }
      return of(null);
    }),
  );

  constructor(
    protected componentData: CmsComponentData<CmsCustomColumnsWithFilesContainerModel>,
    protected cmsService: CmsService,
  ) {
  }

  ngOnInit(): void {
  }

  initComponent(item: CmsCustomColumnsWithFilesContainerModel): void {
    this.styleClasses = item.styleClasses ?? '';
    if (item?.fullPageWidth.toString() === 'true') {
      this.styleClasses += ' full-page-width';
    }
    if (item.backgroundColorCode) {
      this.backgroundColor = item.backgroundColorCode ?? '';
    }
  }
}
