import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReturnRequestTotalsComponent } from '@spartacus/order/components';

@Component({
  selector: 'cx-return-request-totals',
  templateUrl: './custom-return-request-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReturnRequestTotalsComponent extends ReturnRequestTotalsComponent {
  isCancelling$ = this.returnRequestService.isCancelling$;

  cancelReturn(returnRequestCode: string): void {
    this.returnRequestService.cancelReturnRequest(returnRequestCode);
  }
}
