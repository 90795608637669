import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@spartacus/core';
import { CustomPreferenceCenterAdapter } from '../core/connectors/custom-preference-center.adapter';
import { CustomOccPreferenceCenterAdapter } from './adapters/custom-occ-preference-center.adapter';
import { customDefaultOccPreferenceCenterConfig } from './config/custom-default-occ-preference-center-config';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideDefaultConfig(customDefaultOccPreferenceCenterConfig),
    {
      provide: CustomPreferenceCenterAdapter,
      useClass: CustomOccPreferenceCenterAdapter,
    },
  ],
})
export class CustomPreferenceCenterOccModule {}
