<ng-container *ngIf="order$ | async as order">
  <div class="cx-nav">
    <a
      *ngIf="order.returnable"
      [routerLink]="
        {
          cxRoute: 'orderReturn',
          params: order
        } | cxUrl
      "
      class="btn btn-action"
    >
      {{ 'orderDetails.cancellationAndReturn.returnAction' | cxTranslate }}
    </a>
    <!-- <a
      *ngIf="order.cancellable"
      [routerLink]="
        {
          cxRoute: 'orderCancel',
          params: order
        } | cxUrl
      "
      class="btn btn-action"
    >
      {{ 'orderDetails.cancellationAndReturn.cancelAction' | cxTranslate }}

    </a> -->
    <ng-container *ngIf="loggedIn$ | async">
      <button *ngIf="order?.restoreOnly" class="btn btn-action"
              (click)="dispatchReorderEvent('order-again');showOrderRestoreOnlyModal(order)">
        {{ 'orderDetails.actions.restoreOnly' | cxTranslate }}
      </button>
      <button *ngIf="order?.restorable" class="btn btn-action" (click)="showOrderRestorationModal(order)">
        {{ 'orderDetails.actions.restoration' | cxTranslate }}
      </button>
      <button *ngIf="order?.cancellable" class="btn btn-action" (click)="showOrderCancellationModal(order)">
        {{ 'orderDetails.actions.cancellable' | cxTranslate }}
      </button>
    </ng-container>
    <a
      [routerLink]="{ cxRoute: 'orders' } | cxUrl"
      class="btn btn-action"
    >
      {{ 'common.back' | cxTranslate }}
    </a>
  </div>
</ng-container>
